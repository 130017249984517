<template>
  <span>
    <BModal title="Edit assignees" modal-class="side-modal modal-lg" hide-footer v-model="modalShown">
      <TargetSearchSelect
        class="mt-3 mb-3"
        @target-selected="onTargetSelected"
        :types="{
          individual: {
            filters: agentFilters,
            disallowed: disallowedAgentIds,
          },
        }"
      />
      <div class="card-block recipients-list mb-3">
        <h3 class="recipients-list-title">{{ client.name }} assignees</h3>
        <template v-if="hasAssignees">
          <Label class="text-secondary text-small">PRIMARY ASSIGNEE</Label>
          <BoxGrid align-content="start" align-h="start" class="group-targets-holder">
            <BoxGridItem class="group-target-item-holder" v-if="primaryAssignee">
              <ClientAssigneeBlock
                class="group-target-item"
                :assigneeRef="primaryAssignee"
                @delete="deleteTarget(primaryAssignee)"
                @remove-primary="makePrimaryAssignee()"
              />
            </BoxGridItem>
            <div v-else class="ml-1">
              <InfoTooltip class="red-info-circle" style="color: red" /> &nbsp; select a user from the list below as the
              primary assignee for this client.
            </div>
          </BoxGrid>
          <template v-if="additionalAssignees.length > 0">
            <Label class="text-secondary text-small">ADDITIONAL ASSIGNEES</Label>
            <BoxGrid align-content="start" align-h="start" class="group-targets-holder">
              <BoxGridItem class="group-target-item-holder" :key="assignee.id" v-for="assignee in additionalAssignees">
                <ClientAssigneeBlock
                  class="group-target-item"
                  :assigneeRef="assignee"
                  @delete="deleteTarget(assignee)"
                  @set-primary="makePrimaryAssignee(assignee)"
                />
              </BoxGridItem>
            </BoxGrid>
          </template>
        </template>
        <p v-else class="text-secondary">No assignees added yet</p>
      </div>
      <div class="buttons text-md-left text-sm-center">
        <VButton class="btn-secondary mr-2" @click="cancel">Cancel</VButton>
        <VButton
          class="done-btn btn-success"
          :disabled="!isAssigneeListValid"
          :loading="requestManager.anyPending"
          @click="save"
          >Save Changes</VButton
        >
      </div>
    </BModal>
    <slot v-bind="{ show }" />
  </span>
</template>

<script lang="ts">
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import {
  Client,
  IndividualTargetSelection,
  IndividualReference,
  partnerUserIndividualTypes,
  AssigneeReference,
  RegistrationStatus,
} from 'ah-api-gateways';
import TargetSearchSelect from '@/app/components/user/TargetSearchSelect.vue';
import InfoTooltip from '../common/InfoTooltip.vue';
import ClientAssigneeBlock from './ClientAssigneeBlock.vue';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';

/**
 * Client Assignees View/Edit Modal
 * Allows user to see an editable list of a Client's Assignees
 *
 * Emits:
 * - saved-assignees - emitted when assignees are saved
 */
@Component({
  components: { TargetSearchSelect, ClientAssigneeBlock, InfoTooltip },
})
export default class ClientAssigneesModal extends Mixins(WithRequestManager) {
  @Prop({ required: true }) client!: Client;

  clientAssignees: AssigneeReference[] = [];

  savedClientAssignees: AssigneeReference[] = [];

  private modalShown = false;

  requestManagerConfig = {
    exposeToParent: true,
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'getAssignees') {
      this.loadAssignees();
    }
    if (k === 'setClientAssignees') {
      this.save();
    }
  }

  @Watch('client.id', { immediate: true })
  loadAssignees() {
    if (this.client) {
      this.requestManager
        .sameOrCancelAndNew('getAssignees', this.$services.client.getClientAssignees(this.client.id), this.client.id)
        .subscribe((clientAssignees) => {
          this.savedClientAssignees = [...clientAssignees];
          this.clientAssignees = [...clientAssignees];
        });
    }
  }

  get hasAssignees() {
    return !!this.clientAssignees.length;
  }

  get disallowedAgentIds() {
    return this.clientAssignees.map((r) => r.individual.id);
  }

  get agentFilters() {
    return {
      type: partnerUserIndividualTypes,
      status: [RegistrationStatus.PENDING, RegistrationStatus.APPROVED, RegistrationStatus.REJECTED],
    };
  }

  get primaryAssignee() {
    return this.clientAssignees.find((c) => c.primary === true);
  }

  get additionalAssignees() {
    return this.clientAssignees.filter((c) => c.primary === false);
  }

  get isAssigneeListValid() {
    if (this.clientAssignees.length === 0) {
      return true;
    }
    return !!this.primaryAssignee;
  }

  isInAssigneesList(target: IndividualReference) {
    return !!this.clientAssignees.find((i) => i.individual.id === target.id);
  }

  onTargetSelected(target: IndividualTargetSelection) {
    if (!this.isInAssigneesList(target.individual)) {
      this.clientAssignees.push({
        individual: target.individual,
        primary: false,
      });
    }
  }

  deleteTarget(target: AssigneeReference) {
    const index = this.clientAssignees.findIndex((i) => i.individual.id === target.individual.id);
    if (index > -1) {
      this.clientAssignees.splice(index, 1);
    }
  }

  makePrimaryAssignee(target?: AssigneeReference) {
    const currentPrimary = this.clientAssignees.find((i) => i.primary === true);
    if (currentPrimary) {
      currentPrimary.primary = false;
    }
    if (target) {
      target.primary = true;
    }
  }

  show() {
    this.modalShown = true;
  }

  hide() {
    this.modalShown = false;
  }

  save() {
    if (this.clientAssignees.length <= 0) {
      this.$toast.error('At least one of the assignees must be marked as primary');
      return;
    }
    this.requestManager
      .new(
        'setClientAssignees',
        this.$services.client.setClientAssignees(
          this.client.id,
          this.clientAssignees.map((assignee) => ({
            id: assignee.individual.id,
            primary: assignee.primary,
          }))
        )
      )
      .subscribe(() => {
        this.$emit('saved-assignees');
        this.$toast.success(`Saved assignees of the client ${this.client.name} successfully`);
        this.hide();
      });
  }

  cancel() {
    this.clientAssignees = [...this.savedClientAssignees];
    this.hide();
  }
}
</script>
<style lang="scss">
.recipients-list-title {
  margin-bottom: math.div($padded-space, 3);
}

.group-targets-holder {
  display: flex;
  margin-bottom: math.div($padded-space, 2);

  &:last-child {
    margin-bottom: 0;
  }

  .group-target-item-holder {
    flex-basis: 33%;
    flex-grow: 0;
    min-width: 200px;

    @include upToResolution($tabletResolution) {
      min-width: 100% !important;
    }

    .group-target-item {
      width: 100%;
    }
  }
}
.red-info-circle {
  color: red;
  font-size: 1.8em;
}
</style>
