<script lang="ts" setup>
import { ClientCollateralState, callTriggeredStates } from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { useTradeState } from '../..';
import { computed } from 'vue';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
const tradeState = useTradeState();

const onBehalfOfClient = useOnBehalfOf();

tradeState.store.useWalletsStore().loadCollaterals({ force: false, clientId: onBehalfOfClient.value?.id });

const collaterals = computed(() => {
  const clientId = onBehalfOfClient.value
    ? onBehalfOfClient.value.id
    : tradeState.store.useAuthStore().loggedInIdentity?.client?.id;

  if (clientId) {
    return tradeState.store.useWalletsStore().getCollaterals(clientId);
  }
  return null;
});

const isMarginCallTriggered = computed(
  () => collaterals.value && callTriggeredStates.includes(collaterals.value.state)
);

const recommendedRecapThresholdPercentage = computed(() =>
  ((collaterals.value?.recommendedRecapThreshold ?? 0) * 100).toFixed(1)
);

const effectiveCollateralUtilizationPercentage = computed(() =>
  ((collaterals.value?.effectiveCollateralUtilization ?? 0) * 100).toFixed(1)
);
</script>

<template>
  <div class="collateral-actions-help-text" v-if="collaterals">
    <h3 v-if="isMarginCallTriggered">
      <IconAlertCircle class="info-circle actions-required" /> You have a Margin Call.
    </h3>
    <h3 v-else>Actions</h3>
    <div class="messages">
      <template
        v-if="
          collaterals.state === ClientCollateralState.BELOW_RECOMMENDED_RECAP_THRESHOLD ||
          collaterals.state === ClientCollateralState.ABOVE_RECOMMENDED_RECAP_THRESHOLD
        "
      >
        <p>
          You can withdraw collateral up to the equivalent of {{ collaterals.currency }}
          {{ formatCurrencyValue(collaterals.unutilizedCollateral) }}.
        </p>
        <p>
          However, it is advisable that you maintain your current collateral balance to avoid margin calls and the risk
          of positions being closed in volatile markets. We generally recommend maintaining an Effective Collateral
          Utilisation below {{ recommendedRecapThresholdPercentage }}%
        </p>
      </template>
      <template
        v-else-if="
          collaterals.state === ClientCollateralState.ABOVE_UC_THRESHOLD ||
          collaterals.state === ClientCollateralState.ABOVE_RECAPITALIZATION_THRESHOLD
        "
      >
        <p>Your effective collateral utilisation is currently at {{ effectiveCollateralUtilizationPercentage }}%.</p>
        <p>
          We recommend that you post {{ collaterals.currency }}
          {{ formatCurrencyValue(collaterals.recommendedRecapitalizationAmount) }} to keep collateral utilization below
          {{ recommendedRecapThresholdPercentage }}% to avoid your positions being closed in volatile markets.
        </p>
      </template>
      <template v-else-if="isMarginCallTriggered">
        <p>Your effective collateral utilisation is currently at {{ effectiveCollateralUtilizationPercentage }}%.</p>
        <p>You MUST post collateral immediately to avoid your positions being closed.</p>
      </template>
      <div class="no-actions" v-else>
        <PieChartIcon class="icon" />
        <h3 class="message">No Action required</h3>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.no-actions {
  text-align: center;

  .icon {
    display: block;
    margin: auto;
    font-size: 3em;
    @include themedTextColor($color-text-secondary);
  }

  .message {
    margin-top: 0.5em;
  }
}
</style>
