<template>
  <div class="login">
    <div v-if="stage === 'login'">
      <ValidatedForm :fm="loginForm" :validation.sync="loginFV" @submit="submitLoginForm()" @form-event="onFormEvent" />
      <div class="form-actions text-center">
        <VButton :loading="loginState === 'pending'" label="Login" @click="submitLoginForm()" class="btn-primary" />
      </div>
    </div>
    <div v-else-if="stage === 'otp'">
      <OtpForm
        ref="otpForm"
        :otp-phone="otpPhone"
        :expiry="otpExpire"
        @cancel="backToLogin"
        @error="onOtpError"
        @success="onLoginSuccess"
      />
    </div>
    <div v-else-if="stage === 'verifyEmail'" class="email-verification">
      <EmailVerificationWarning :email="loginForm.email" />
      <div class="text-center mt-3">
        <VButton label="Back to login" @click="setStage('login')" class="btn-stroked mx-3" />
      </div>
    </div>
    <div v-else-if="stage === 'autologin' || stage === 'success'">
      <LoadingOverlay :loading="loginState === 'pending'" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import OtpForm from './OtpForm.vue';
import { makeFormModel, resetForm, submitForm, setState, getChildModel } from 'ah-common-lib/src/form/helpers';
import { emailField, passwordField } from 'ah-common-lib/src/form/models';
import { RequestState, manageVuePromiseState } from 'ah-requests';
import { FormValidation, FieldModel } from 'ah-common-lib/src/form/interfaces';
import { AuthenticationServiceErrorCodes, UserRole, UserStatus } from 'ah-api-gateways';
import EmailVerificationWarning from './EmailVerificationWarning.vue';
import { useAuthStore } from '@/app/store/authStore';
import { ExpiryTime } from 'ah-api-gateways/models/expiry';

const loginFM = () =>
  makeFormModel({
    name: 'loginForm',
    title: 'Login',
    fieldType: 'form',
    fields: [
      emailField('email', 'Email', { autocomplete: 'username', maxLength: 60 }),
      passwordField('password', 'Password', false, { allowShowPassword: true }),
    ],
    state: {
      showErrorsBeforeSubmit: false,
    },
  });

export type LoginStage = 'login' | 'otp' | 'autologin' | 'verifyEmail' | 'success';

const maxRegistrationRetries = 5;

@Component({
  components: {
    OtpForm,
    EmailVerificationWarning,
  },
})
export default class UserLogin extends Vue {
  @Prop({ default: null }) private autoLogin!: { email: string; password: string } | null;

  private loginForm = loginFM();

  private loginFV: FormValidation | null = null;

  private otpPhone = '';

  private otpExpire: ExpiryTime | null = null;

  loginState: RequestState = 'idle';

  stage: LoginStage = 'login';

  $refs!: {
    otpForm: OtpForm;
  };

  get authStore() {
    return useAuthStore();
  }

  get verifiedEmail() {
    return this.$route.query.verified;
  }

  setStage(stage: LoginStage) {
    this.stage = stage;
    this.$emit('stage-change', this.stage);
  }

  refreshOtpCode() {
    if (this.stage === 'otp' && this.$refs.otpForm) {
      this.$refs.otpForm.refreshOtp();
    }
  }

  @Watch('verifiedEmail', { immediate: true })
  onVerifiedEmailChange() {
    if (this.verifiedEmail && !this.autoLogin) {
      this.loginForm.email = this.verifiedEmail;
    }
  }

  created() {
    if (this.autoLogin) {
      this.setStage('autologin');
      this.login(this.autoLogin.email, this.autoLogin.password);
    } else {
      this.goToLogin();
    }
  }

  backToLogin() {
    this.goToLogin();
    this.loginForm = loginFM();
    if (this.loginFV) {
      resetForm(this.loginFV);
    }
  }

  onFormEvent() {
    setState(this.passwordField, 'errors', []);
  }

  async submitLoginForm() {
    if (this.loginFV) {
      submitForm(this.loginFV);
      if (this.loginFV.$invalid) {
        return;
      }
    } else {
      return;
    }

    setState(this.passwordField, 'errors', []);

    return this.login(this.loginForm.email, this.loginForm.password);
  }

  async login(email: string, password: string, retries = 0) {
    manageVuePromiseState(
      this,
      'loginState',
      this.authStore
        .login({
          email: email,
          password: password,
          silenceErrors: true,
        })
        .then((data) => {
          if (data?.role === UserRole.CLIENT_REGISTRATION && data?.status === UserStatus.TEMPORARY) {
            // Temporary Users do not need to check OTP via the login flow, but instead can be considered "logged in"
            // (the registration views will handle account verification)
            this.onLoginSuccess();
          } else if ((data?.role === UserRole.CLIENT_REGISTRATION && !data.individual) || data?.isOtpRequired) {
            return this.$services.auth
              .refreshOtp()
              .toPromise()
              .then((res) => {
                this.otpPhone = data.phoneNumber.substring(data.phoneNumber.length - 4);
                this.setStage('otp');
                if (res?.expiresIn) {
                  this.otpExpire = res;
                }
              });
          } else {
            this.onLoginSuccess();
          }
        })
        .catch((error) => {
          if (error === 'ahAdminNotAllowed') {
            this.$toast.error(
              'Admin users cannot login to the platform directly. Please impersonate a user of this platform through the admin panel.'
            );
          } else if (error === 'userNotRegistered') {
            this.$router.push('/register');
          } else if (error.response?.data?.code === AuthenticationServiceErrorCodes.BAD_CREDENTIALS) {
            setState(this.passwordField, 'errors', [
              {
                name: 'wrongLogin',
                error: 'Wrong email or password',
              },
            ]);
          } else if (error.response?.data?.code === AuthenticationServiceErrorCodes.UNVERIFIED_USER) {
            if (this.verifiedEmail && retries < maxRegistrationRetries) {
              this.login(email, password, retries + 1);
              return;
            }
            this.setStage('verifyEmail');
          } else {
            this.$toast.error('Something unexpected happened while trying to login');
            this.goToLogin();
          }
          this.$emit('login-failure', error);
        })
    );
  }

  goToLogin() {
    this.setStage('login');
    this.otpExpire = null;
  }

  onOtpError(error: any) {
    // We need to set a timeout so the toast doesn't get clear after a possible logout
    setTimeout(() => {
      if (error === 'userNotRegistered') {
        this.$router.push('/register');
      } else if (error === 'userWithoutIndividual') {
        this.$toast.error('Something unexpected happened while trying to login');
        this.goToLogin();
      }
      if (error === 'sessionNotFound') {
        this.$toast.error('It seems like we are still creating your user. Try again in a couple of minutes.');
        this.goToLogin();
      }
    });
  }

  onLoginSuccess() {
    this.setStage('success');
    this.$emit('login-success');
  }

  get passwordField() {
    return getChildModel(this.loginForm, 'password') as FieldModel;
  }
}
</script>
