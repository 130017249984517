<template>
  <div class="app-logo">
    <img :src="thumbnailLogoLink" alt="" v-if="thumbnailLogoLink" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { BrandingImageType } from 'ah-api-gateways';

@Component
export default class AppLogo extends Vue {
  get thumbnailLogoLink() {
    let out = this.$theme.val?.logos.find((l) => l.type === BrandingImageType.THUMBNAIL)?.link;
    return out;
  }
}
</script>
<style lang="scss" scoped>
.app-logo {
  position: relative;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
</style>
