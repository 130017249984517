<script setup lang="ts">
import { beneficiaryTypeLabels, Trade, PaymentReport } from 'ah-api-gateways';
import { countryNameFromCC } from 'ah-common-lib/src/helpers/countries';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { formatDate, SIMPLE_DATE_FORMAT } from 'ah-common-lib/src/helpers/time';
import { computed } from 'vue';

const props = defineProps<{
  trade: Trade;
  payment: PaymentReport;
}>();

const paymentDate = computed(() => props.payment.executionDate ?? props.trade.settlementDate!);
</script>

<template>
  <div>
    <DataRow label="Payment Amount" cols="6" class="mb-2">
      {{ payment.currency }} {{ formatCurrencyValue(payment.amount) }}
    </DataRow>
    <DataRow label="Payment Date" cols="6" class="mb-2">
      {{ formatDate(paymentDate, SIMPLE_DATE_FORMAT) }}
    </DataRow>
    <DataRow label="Name" cols="6" class="mb-2">
      {{ payment.beneficiaryName ?? '-' }}
    </DataRow>
    <DataRow label="Type" cols="6" class="mb-2">
      {{ beneficiaryTypeLabels[payment.beneficiaryType] }}
    </DataRow>
    <DataRow label="Country" cols="6" class="mb-2">
      {{ countryNameFromCC(payment.beneficiaryBankCountry) }}
    </DataRow>
    <DataRow label="Payment Reason" cols="6" class="mb-2">
      {{ payment.description ?? '-' }}
    </DataRow>
    <DataRow label="Reference" cols="6">
      {{ payment.reference ?? '-' }}
    </DataRow>
  </div>
</template>
