<template>
  <div class="rate-alerts-holder">
    <div class="rate-alerts-title">
      <h2>Rate alerts</h2>
      <p class="text-secondary mt-n4">Setup alerts for when currency pairs of your interest reach a certain rate.</p>
    </div>
    <RateAlertSettingsEditModal :rateAlert="tempRateAlert" v-slot="{ show }" @update:rateAlert="onRateAlertUpdate">
      <VButton @click="show" class="rate-alerts-add">Add rate alert</VButton>
    </RateAlertSettingsEditModal>
    <BoxGrid class="rate-alerts-table">
      <BoxGridBlock>
        <NotificationSettingsTable
          rateAlert
          :loading="loadingRateAlerts"
          v-slot="{ isStackedView }"
          :hideTargets="isIndividual"
        >
          <div class="notifications-block" v-if="rateAlerts.length > 0">
            <SingleNotificationSettingEditForm
              v-for="rateAlert in rateAlerts"
              :config="rateAlertConfig"
              :notification="rateAlert"
              :hideTargets="isIndividual"
              :showTitles="isStackedView"
              :key="rateAlert.id"
              deletable
              class="single-notification-editor sub-item"
              @update:notification="onRateAlertUpdate"
              @delete-notification="onRateAlertDelete"
            />
            <SingleNotificationSettingEditForm
              v-for="rateAlert in tempRateAlertSettings"
              :key="rateAlert.id"
              :config="rateAlertConfig"
              :notification="rateAlert"
              :hideTargets="isIndividual"
              :showTitles="isStackedView"
              readonly
              deletable
              class="single-notification-editor sub-item"
              @update:notification="onRateAlertUpdate"
              @delete-notification="onRateAlertDelete"
            />
          </div>
          <span v-else class="text-secondary">No rate alerts added yet</span>
        </NotificationSettingsTable>
      </BoxGridBlock>
    </BoxGrid>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import SingleNotificationSettingEditForm from '@/app/components/settings/notifications/SingleNotificationSettingEditForm.vue';
import {
  RateAlertSetting,
  RateAlertInequalityType,
  ClientType,
  NotificationSettingConfig,
  NotificationChannelType,
} from 'ah-api-gateways';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { generateTempUUID, isTempUUID } from 'ah-common-lib/src/helpers/uuid';
import { SECOND } from 'ah-common-lib/src/constants/time';
import NotificationSettingsTable from '@/app/components/settings/notifications/NotificationSettingsTable.vue';
import RateAlertSettingsEditModal from '@/app/components/settings/notifications/RateAlertSettingsEditModal.vue';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { useAuthStore } from '@/app/store/authStore';
import { useNotificationSettingsStore } from '@/app/store/notificationSettingsModule';

const NOTIFICATION_SAVE_DEBOUNCE_TIME = SECOND * 1.5;

@Component({
  components: { SingleNotificationSettingEditForm, NotificationSettingsTable, RateAlertSettingsEditModal },
})
export default class RateAlertsSettingsEditor extends Mixins(WithRequestManager) {
  requestManagerConfig = {
    exposeToParent: true,
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'loadRateAlertSettings' || k === 'loadNotificationsConfig' || k === 'getMidMarketLiveRate') {
      this.created();
    }
  }

  private tempRateAlertSettings: RateAlertSetting[] = [];

  private sporLiveRateValue: number = 0;

  created() {
    this.requestManager.currentOrNewPromise('loadNotificationsConfig', () =>
      this.notificationSettingsStore.loadNotificationConfig(true)
    );
    this.requestManager.currentOrNewPromise('loadRateSettings', () =>
      this.notificationSettingsStore.loadRateAlertSettings(true)
    );
    this.getCurrentMidMarketRate('GBPEUR');
  }

  get notificationSettingsStore() {
    return useNotificationSettingsStore();
  }

  onRateAlertUpdate(rateAlert: RateAlertSetting) {
    // Using timer->take as a debounce method, as RequestManager will cancel any previous request,
    // and the promise will never be called
    if (isTempUUID(rateAlert.id)) {
      this.tempRateAlertSettings.push(rateAlert);
    }
    this.requestManager
      .cancelAndNew(`saveRateAlert-${rateAlert.id}`, timer(NOTIFICATION_SAVE_DEBOUNCE_TIME).pipe(take(1)))
      .subscribe(() => {
        this.notificationSettingsStore.saveRateAlertSetting(rateAlert).then(() => {
          if (isTempUUID(rateAlert.id)) {
            const index = this.tempRateAlertSettings.findIndex((i) => i.id === rateAlert.id);
            if (index > -1) {
              this.tempRateAlertSettings.splice(index, 1);
            }
          }
        });
      });
  }

  onRateAlertDelete(rateAlert: RateAlertSetting) {
    this.requestManager.cancel(`saveRateAlert-${rateAlert.id}`);
    this.notificationSettingsStore.deleteRateAlertSetting(rateAlert);
  }

  get authStore() {
    return useAuthStore();
  }

  get tempRateAlert(): RateAlertSetting {
    return {
      channels: [],
      to: [],
      id: generateTempUUID(),
      rate: this.sporLiveRateValue,
      currencyPair: 'GBPEUR',
      inequality: RateAlertInequalityType.HIGHER_THAN,
    };
  }

  get rateAlertConfig(): NotificationSettingConfig {
    const entityType = this.authStore.isClientUser ? 'CLIENT' : 'PARTNER';
    return {
      type: 'RATE_ALERT',
      entityType: entityType,
      editable: true,
      multiple: true,
      timed: false,
      defaultTimeUnit: null,
      defaultTimeValue: null,
      sectionIndex: -1,
      sectionName: '',
      index: 0,
      name: '',
      description: '',
      multipleDescription: null,
      channels: [
        {
          channelType: NotificationChannelType.EMAIL,
          configurable: false,
          editable: true,
          defaultEnabledValue: true,
        },
        {
          channelType: NotificationChannelType.IN_APP,
          configurable: false,
          editable: true,
          defaultEnabledValue: true,
        },
        {
          channelType: NotificationChannelType.SMS,
          configurable: true,
          editable: false,
          defaultEnabledValue: true,
        },
      ],
    };
  }

  get client() {
    return this.authStore.loggedInIdentity?.client;
  }

  get isIndividual() {
    return this.client?.type === ClientType.INDIVIDUAL;
  }

  get rateAlerts() {
    return this.notificationSettingsStore.rateAlertSettings || [];
  }

  get loadingRateAlerts() {
    return this.rateAlerts.length === 0 && this.requestManager.requestStates.loadRateSettings === 'pending';
  }

  getCurrentMidMarketRate(currencyPair: string) {
    this.requestManager
      .sameOrCancelAndNew('getMidMarketLiveRate', this.$services.rates.spotLiveRate(currencyPair))
      .subscribe((response) => {
        this.sporLiveRateValue = response.spotRate;
      });
  }
}
</script>
<style lang="scss" scoped>
.rate-alerts-title {
  float: left;
}

.rate-alerts-add {
  float: right;
}

.rate-alerts-table {
  clear: both;
}
</style>
