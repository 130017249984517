import { Form } from '../../interfaces';
import { textField, phoneField, passwordField, checkboxField, emailField } from '../../models';
import { formOverride, makeFormModel } from '../../helpers';
import { validName, requiredIfStateValue } from 'ah-common-lib/src/form/validators';
import { personTitleField } from '../individual';

export const userDetailsForm = (
  options: { readonlyPhone?: boolean; includeEmail?: boolean },
  overrides: any = {}
): Form => {
  const fields = [
    textField(
      'firstName',
      'First name',
      { class: 'half-width left' },
      { required: requiredIfStateValue('firstName'), validName }
    ),
    textField(
      'lastName',
      'Last name',
      { class: 'half-width right' },
      { required: requiredIfStateValue('firstName'), validName }
    ),
    phoneField(
      'phoneNumber',
      'Telephone number',
      {
        readonly: options.readonlyPhone,
        hideErrors: options.readonlyPhone
      },
      options.readonlyPhone ? {} : undefined
    ),
    passwordField('password', 'Password', true, { allowShowPassword: true }),
    checkboxField('termsAndConditions', 'I have read and understood the Terms and Conditions.', true)
  ];

  if (options.includeEmail) {
    fields.unshift(emailField());
  }
  return formOverride(
    {
      name: 'userDetails',
      fieldType: 'form',
      state: {
        open: true,
        title: 'User Details'
      },
      fields: fields
    },
    overrides
  );
};

export const makeOwnerAccessFM = () =>
  makeFormModel({
    name: 'ownerAccess',
    fieldType: 'form',
    fields: [
      personTitleField({ fieldWrapperClass: 'col col-2', readonly: true }),
      textField(
        'firstName',
        'Name',
        { fieldWrapperClass: 'col col-5', readonly: true },
        { required: requiredIfStateValue('firstName'), validName }
      ),
      textField(
        'lastName',
        'Surname',
        { fieldWrapperClass: 'col col-5', readonly: true },
        { required: requiredIfStateValue('firstName'), validName }
      ),
      emailField('email', `Business email address`, {
        readonly: true
      }),
      passwordField('password', 'Create Password', true, {
        errorMessages: {
          passwordLength: '',
          passwordNumber: '',
          passwordSpecialChar: '',
          passwordUpper: '',
          passwordLower: ''
        },
        allowShowPassword: true,
        showRequiredMarkers: true
      }),
      phoneField('phoneNumber', 'Mobile Number', { showRequiredMarkers: true })
    ]
  });
