<template>
  <span>
    <BModal title="Edit markup" modal-class="side-modal modal-md" ref="modal" hide-footer>
      <div class="card-block mb-3" v-if="editedSettings">
        <VRow>
          <VCol>
            <h3>Percentage</h3>
            <ClientProfitMarginForm
              v-for="profitKey in profitKeys"
              :key="profitKey"
              :profitKey="profitKey"
              :model="editedSettings"
              :client="client"
              :title="labels[profitKey]"
              unit="percentage"
              :minValue="editedSettings.minProfit"
              :maxValue="editedSettings.maxProfit"
              @update:model="onUpdateMargins(profitKey, $event)"
              @update:validation="onUpdateValidation(profitKey, $event)"
            />
          </VCol>
          <VCol>
            <h3>Basis Points</h3>
            <ClientProfitMarginForm
              v-for="profitKey in profitKeys"
              :key="profitKey"
              :profitKey="profitKey"
              :model="editedSettings"
              :client="client"
              :title="labels[profitKey]"
              unit="basisPoints"
              :minValue="editedSettings.minProfit"
              :maxValue="editedSettings.maxProfit"
              @update:model="onUpdateMargins(profitKey, $event)"
              @update:validation="onUpdateValidation(profitKey, $event)"
            />
          </VCol>
        </VRow>
      </div>
      <div class="buttons mt-5 text-md-left text-sm-center">
        <VButton class="btn-secondary mr-2 mb-3" @click="cancel">Cancel</VButton>
        <VButton class="done-btn btn-success" :loading="requestManager.anyPending" @click="save">Save Changes</VButton>
      </div>
    </BModal>
    <slot v-bind="{ show }" />
  </span>
</template>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import { Validation } from '@vuelidate/core';
import {
  Client,
  FxMargins,
  HedgingInstrumentsSettingsProps,
  hedgingInstrumentSettingPropToHuman,
  FxMarginsConfiguration,
} from 'ah-api-gateways';
import { submitForm } from 'ah-common-lib/src/form/helpers';
import ClientProfitMarginForm from '@/app/components/clients/ClientProfitMarginForm.vue';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { FormValidation } from 'ah-common-lib/src/form/interfaces';

@Component({
  components: { ClientProfitMarginForm },
})
export default class ClientMarginEditModal extends Mixins(WithRequestManager) {
  $refs!: {
    modal: BModal;
  };

  @Prop({ required: true }) client!: Client;

  @Prop({ required: true }) settings!: FxMargins;

  private editedSettings: FxMargins = { ...this.settings };

  marginFormValidations: Partial<Record<HedgingInstrumentsSettingsProps, FormValidation>> = {};

  requestManagerConfig = {
    exposeToParent: true,
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'saveModel') {
      this.save();
    }
    if (k === 'getClientSettings') {
      this.loadSettings();
    }
  }

  get profitKeys() {
    return HedgingInstrumentsSettingsProps;
  }

  get labels() {
    return hedgingInstrumentSettingPropToHuman;
  }

  onUpdateMargins(key: HedgingInstrumentsSettingsProps, value: FxMarginsConfiguration) {
    if (this.editedSettings) {
      this.$set(this.editedSettings, key, value);
    }
  }

  onUpdateValidation(key: HedgingInstrumentsSettingsProps, value: Validation) {
    this.$set(this.marginFormValidations, key, value);
  }

  isFormInvalid(key: HedgingInstrumentsSettingsProps) {
    return this.marginFormValidations[key]?.$invalid;
  }

  isFormDirty(key: HedgingInstrumentsSettingsProps) {
    return this.marginFormValidations[key]?.$anyDirty;
  }

  testForm() {
    let valid = true;

    Object.keys(this.marginFormValidations).forEach((key) => {
      const validation = this.marginFormValidations[key as HedgingInstrumentsSettingsProps];
      if (validation) {
        submitForm(validation);
        if (validation.$invalid) {
          valid = false;
        }
      }
    });

    return valid;
  }

  save() {
    if (!this.testForm() || !this.editedSettings) {
      this.$toast.error('Form contains errors, please review values entered.');
      return false;
    }
    this.requestManager
      .cancelAndNew(
        'saveModel',
        this.$services.spreads.updateClientSpreads(this.client.id!, {
          ...(this.editedSettings as any),
        })
      )
      .subscribe((settings) => {
        this.$emit('update:client-settings', settings);
        this.$toast.success('Client Margin Settings updated.');
        this.$refs.modal.hide();
      });
  }

  loadSettings() {
    this.requestManager
      .sameOrCancelAndNew('getClientSettings', this.$services.spreads.getClientSpreads(this.client.id), this.client.id)
      .subscribe((settings) => {
        this.editedSettings = settings;
      });
  }

  show() {
    this.$refs.modal.show();
    this.loadSettings();
  }

  cancel() {
    this.$refs.modal.hide();
  }
}
</script>
<style lang="scss" scoped>
.form-title {
  .errors,
  .edited {
    font-style: italic;
    font-weight: 200;
  }

  .errors {
    @include themedTextColor($color-danger);
  }

  .edited {
    @include themedTextColor($color-text-secondary);
  }
}
</style>
