import get from 'lodash/get';
import { FieldError, FieldModel, FieldErrorMessage } from '../interfaces/form';
import countries from '../../constants/countries';
import { invalidCharactersRegex } from '../validators';
import { unref } from 'vue';
import { FormValidation } from '../interfaces';
import { getState } from '.';

// Error messages in order of importance
const errorMessages: { [key: string]: FieldErrorMessage } = {
  required: 'Required field',
  minLength: 'Must have at least {{field.minLength.$params.min}} letters',
  email: 'Must be a valid email address',
  minDate: 'Date must be on or after {{field.minDate.$params.minDate}}',
  date: 'Must be a valid date',
  password:
    'Password must be at least 8 characters in length and include at least 1 uppercase letter, one number and one special character (!@#$%^&*)',
  passwordLength: 'Password must be at least 8 characters in length',
  passwordLower: 'Password must include at least one lowercase letter',
  passwordUpper: 'Password must include at least one uppercase letter',
  passwordNumber: 'Password must include at least one number digit',
  passwordSpecialChar: 'Password must include at least one special character (!@#$%^&*)',
  passwordMatch: 'Passwords do not match',
  phone: 'Phone number is invalid',
  mustAccept: 'Must accept terms and conditions.',
  url: "Must be a valid URL ['http(s)://' optional]",
  fileType: 'File type not accepted!',
  fileSize: 'File is too large!',
  iban: 'Invalid IBAN',
  validName: (validation, model) =>
    `${model.$state.title || 'Field'} cannot include the character ${validation.$model.match(invalidCharactersRegex)}`,
  ibanCC: (_, model) => {
    const ibanAllowedCountries = model.$state.ibanAllowedCountries ?? [];
    if (ibanAllowedCountries.length === 0) {
      return 'Account country not allowed';
    }

    let out = 'Only accounts from ';

    for (let i = 0; i < ibanAllowedCountries.length; i++) {
      const cc = ibanAllowedCountries[i];
      out += countries.find((c) => c.value === cc)?.label || cc;

      if (i < ibanAllowedCountries.length - 2) {
        out += ', ';
      } else if (i === ibanAllowedCountries.length - 2) {
        out += ' or ';
      } else {
        out += ' allowed';
      }
    }

    return out;
  }
};

function errorMessage<T = any>(error: string, field: FormValidation<T>, model: FieldModel): string | null {
  const errorObj = field.$errors.find((e) => e.$validator === error);
  const message: FieldErrorMessage<T> | undefined =
    get(model, `$state.errorMessages.${error}`) ??
    (errorObj?.$params as any).errorMessage ??
    (unref(errorObj?.$message) || errorMessages[error]);

  if (message) {
    const errorString: string = typeof message === 'function' ? message(field, model) : message;

    return errorString.replace(/{{[a-zA-Z.$\-_0-9]*}}/g, (s) =>
      get({ model, field }, s.substring(2, s.length - 2), '')
    );
  }

  return null;
}

/**
 * Retrieves a FieldError (for rendering purposes) from a validation
 *
 * Error message is retrieved according to the following order of precedence:
 *  - Message in $state.errorMessages[errorName], if any
 *  - `errorMessage` property in validator params, if any (as set by helpers.withParams)
 *  - validator message, if any (as set by helpers.withMessage)
 *  - Generic error message in the defaults object, if any
 */
export function getFieldError<T = any>(name: string, field: FormValidation<T>, model: FieldModel): FieldError {
  return {
    name,
    error: errorMessage(name, field, model)
  };
}

export function getFieldErrorList<T = any>(field: FormValidation<T>, model: FieldModel): FieldError[] {
  const customErrors = getState(model, 'errors', []);
  const firstErrorMessageOnly = getState(model, 'firstErrorMessageOnly', false, true);

  const fieldErrorsShown = field && (field.$error || customErrors.length) && getShownFieldErrors(field, model);
  if (!fieldErrorsShown) {
    return [];
  }

  const errors = field.$errors.map((e) => e.$validator).filter((k) => field[k]?.$invalid !== false);

  if (firstErrorMessageOnly) {
    if (customErrors.length) {
      return [customErrors.value[0]];
    }
    return errors.length ? [fieldError(errors[0], field, model)] : [];
  }
  return [...customErrors, ...errors.map((i: string) => fieldError(i, field, model))];
}

export function getShownFieldErrors<T = any>(field: FormValidation<T>, model: FieldModel) {
  if (getState(model, 'hideErrors', false, true)) {
    return false;
  }
  const submitted = getState(model, 'submitted', false, true);
  const showErrorsBeforeSubmit = getState(model, 'showErrorsBeforeSubmit', true, true);
  return showErrorsBeforeSubmit || submitted;
}

export function fieldError<T = any>(error: string, field: FormValidation<T>, model: FieldModel) {
  return getFieldError(error, field, model);
}
