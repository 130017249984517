<template>
  <div class="email-verification">
    <h2>Verifying your email...</h2>
    <LoadingIcon class="loading-icon" />
  </div>
</template>

<script lang="ts">
import { storeSetupDone } from '@/app/store';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { useAuthStore } from '@/app/store/authStore';

@Component
export default class AccountEmailVerification extends Vue {
  @Prop({ default: '' }) token!: string;

  beforeMount() {
    if (!this.token) {
      this.$toast.error('Incorrect email token provided');
      this.$router.replace(`${this.settingsBase}/account`);
    } else {
      storeSetupDone
        .then(() => this.$services.account.verifyEmailChange(this.token, { errors: { silent: true } }).toPromise())
        .then(
          () => {
            this.authStore.refreshSession();
            this.$toast.success('Email verified successfully.');
            this.$router.replace(`${this.settingsBase}/account`);
          },
          () => {
            this.$toast.error('Email confirmation failed.');
            this.$router.replace(`${this.settingsBase}/account`);
          }
        );
    }
  }

  get authStore() {
    return useAuthStore();
  }

  get settingsBase() {
    if (this.authStore.isAgent) {
      return '/admin/settings';
    }
    return '/settings';
  }
}
</script>
<style lang="scss" scoped>
.email-verification {
  text-align: center;

  .loading-icon {
    margin-top: -0.5em;
    font-size: 4em;
  }
}
</style>
