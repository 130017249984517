import { render, staticRenderFns } from "./SingleNotificationChannelEditForm.vue?vue&type=template&id=e05cf84e&scoped=true"
import script from "./SingleNotificationChannelEditForm.vue?vue&type=script&lang=ts"
export * from "./SingleNotificationChannelEditForm.vue?vue&type=script&lang=ts"
import style0 from "./SingleNotificationChannelEditForm.vue?vue&type=style&index=0&id=e05cf84e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e05cf84e",
  null
  
)

export default component.exports