<template>
  <img v-bind="$attrs" :src="src" v-if="src" @load="onImageLoad" :class="['image', { visible }]" />
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class PrivateImage extends Vue {
  @Prop({ default: '' }) src!: string;

  private visible = false;

  @Watch('src', { immediate: true })
  onSrcChange() {
    this.visible = false;
  }

  onImageLoad() {
    setTimeout(() => {
      this.visible = true;
    });
  }
}
</script>
<style lang="scss" scoped>
.image {
  opacity: 0;

  &.visible {
    opacity: 1;
    transition: opacity 0.5s;
  }
}
</style>
