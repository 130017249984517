<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { getFieldError } from '../../helpers/formErrors';
import { getState } from '../../helpers/formHelpers';
import { FieldModel, FieldError } from '../../interfaces/form';
import BaseFormElement from '../BaseFormElement.vue';
import { getShownFieldErrors } from '../../helpers';

@Component
export default class BaseFormField<T = any> extends BaseFormElement<T> {
  @Prop({ required: true }) declare model: FieldModel;

  get fieldErrorsShown() {
    return this.field && (this.field.$error || this.customErrors.length) && this.showFieldErrors;
  }

  get unexpectedErrorsShown() {
    return this.field && this.unexpectedErrors;
  }

  fieldError(error: string) {
    return getFieldError(error, this.field, this.model);
  }

  get readonly() {
    return getState(this.model, 'readonly', false, (curr, parent) => !!(curr || parent));
  }

  get required() {
    const required = getState(this.model, 'required', false, true);

    if (typeof required === 'function') {
      return required(this.model);
    }
    return required;
  }

  get showRequired() {
    const showRequiredMarkers = getState(this.model, 'showRequiredMarkers', false, true);
    return showRequiredMarkers && this.required;
  }

  get customErrors(): FieldError[] {
    return getState(this.model, 'errors', []);
  }

  get unexpectedErrors(): FieldError[] {
    return getState(this.model, 'unexpectedError', false);
  }

  get showFieldErrors() {
    return getShownFieldErrors(this.field, this.model);
  }

  get htmlTitle() {
    return getState(this.model, 'htmlTitle');
  }

  get fieldTitle() {
    return getState(this.model, 'title');
  }

  get fieldTitleTooltip() {
    return getState(this.model, 'titleTooltip');
  }

  get fieldClass() {
    return getState(this.model, 'class');
  }

  get inputClass() {
    return getState(this.model, 'inputClass', 'form-control', true);
  }

  get inputName() {
    return getState(this.model, 'inputName', this.model.$name);
  }

  get tooltipComponent() {
    return getState(this.model, 'tooltipComponent');
  }

  /**
   * Max length of form field
   *
   * Defaults to 255, typical for API text values
   */
  get maxLength() {
    return getState(this.model, 'maxLength', 255);
  }

  get inputErrorClass() {
    return getState(this.model, 'inputErrorClass', 'is-invalid', true);
  }
}
</script>
