<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { makeFormModel, submitForm, batchSetState } from 'ah-common-lib/src/form/helpers';
import { dateField, selectField, formTitle, radioField } from 'ah-common-lib/src/form/models';
import { required, helpers } from '@vuelidate/validators';
import { afterDateField, date, maxDate } from 'ah-common-lib/src/form/validators/date';
import { optional, ifTest } from 'ah-common-lib/src/form/validators';
import {
  ExportListType,
  Beneficiary,
  beneficiaryName,
  Wallet,
  TransactionReason,
  AuthorityType,
} from 'ah-api-gateways';
import { BModal } from 'bootstrap-vue';
import { addMonths, addDays } from 'date-fns';
import { TransactionQuery } from 'ah-api-gateways';
import { FieldOptionObj, FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useWalletState } from '../..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const statementFormat = () =>
  radioField(
    'format',
    'Format',
    [
      { label: 'PDF', value: ExportListType.PDF },
      { label: 'CSV', value: ExportListType.CSV },
      { label: 'Excel', value: ExportListType.XLSX },
    ],
    {
      defaultValue: ExportListType.PDF,
      inline: true,
    }
  );

const dailyStatementFM = () =>
  makeFormModel({
    name: 'dailyStatement',
    fieldType: 'form',
    fields: [
      dateField(
        'date',
        'Date',
        {
          leftAlignPicker: true,
          errorMessages: {
            maxDate: 'Must be before today',
            date: 'Must be a valid date',
            required: 'Date is required',
          },
        },
        {
          required: ifTest(required, (a) => !(a instanceof Date)),
          maxDate: maxDate(),
          date: ifTest(date, (val) => val instanceof Date),
        }
      ),
      statementFormat(),
    ],
  });

const monthlyStatementFM = () =>
  makeFormModel({
    name: 'monthlyStatement',
    fieldType: 'form',
    fields: [
      formTitle('Date', {
        fieldWrapperClass: 'col col-12 mb-0',
      }),
      selectField(
        'month',
        '',
        [
          { value: 0, label: 'January' },
          { value: 1, label: 'February' },
          { value: 2, label: 'March' },
          { value: 3, label: 'April' },
          { value: 4, label: 'May' },
          { value: 5, label: 'June' },
          { value: 6, label: 'July' },
          { value: 7, label: 'August' },
          { value: 8, label: 'September' },
          { value: 9, label: 'October' },
          { value: 10, label: 'November' },
          { value: 11, label: 'December' },
        ],
        {
          placeholder: 'Month',
          fieldWrapperClass: 'col col-6',
          required: true,
          defaultValue: 0,
          errorMessages: {
            minMonth: 'Please select a month up to the current date',
          },
        },
        {
          minMonth: helpers.withParams({ type: 'minMonth' }, (value: number, vm: any) => {
            const currentMonth = new Date().getMonth();
            const currentYear = new Date().getFullYear();
            if (vm.year === currentYear) {
              return vm.month <= currentMonth;
            }

            return vm.year <= currentYear;
          }),
        }
      ),
      selectField('year', '', [], {
        placeholder: 'Year',
        fieldWrapperClass: 'col col-6',
        required: true,
      }),
      statementFormat(),
    ],
  });

const customStatementFM = () =>
  makeFormModel({
    name: 'customStatement',
    fieldType: 'form',
    fields: [
      dateField(
        'startDate',
        'Start date',
        {
          leftAlignPicker: true,
          errorMessages: {
            date: 'Must be a valid date',
            maxDate: 'Must be before today',
            required: 'Date is required',
          },
        },
        {
          date: ifTest(date, (val) => val instanceof Date),
          required: ifTest(required, (a) => !(a instanceof Date)),
          maxDate: maxDate(),
        }
      ),
      dateField(
        'endDate',
        'End date',
        {
          leftAlignPicker: true,
          errorMessages: {
            date: 'Must be a valid date',
            required: 'Date is required',
            validDateRange: 'End date must be after start date',
            maxDate: 'Must be before today',
          },
        },
        {
          date: ifTest(date, (val) => val instanceof Date),
          required: ifTest(required, (a) => !(a instanceof Date)),
          validDateRange: optional(afterDateField('startDate')),
          maxDate: maxDate(),
        }
      ),
      statementFormat(),
    ],
  });

const modal = ref<InstanceType<typeof BModal> | null>(null);

const onBehalfOfClient = useOnBehalfOf();

const walletState = useWalletState();

const props = withDefaults(
  defineProps<{
    wallet?: Wallet;
    beneficiary?: Beneficiary;
    feesStatements?: boolean | string;
  }>(),
  {
    feesStatements: false,
  }
);

const canDownloadStatement = computed(() =>
  walletState.store.useAuthStore().hasAuthorities(AuthorityType.EXPORT_TRANSACTIONS)
);

const statementDownloadKey = computed(() => `downloadStatement-${props.wallet?.id}`);

const requestManager = useRequestManager({
  exposeToParent: [statementDownloadKey.value],
  onRetryFromParentManager: (k: string) => {
    if (k === statementDownloadKey.value) {
      downloadStatement();
    }
  },
});

const statementFormDef = reactive<FormDefinition>({
  form: dailyStatementFM(),
  validation: null,
});

const state = reactive<{
  currentTab: number;
}>({
  currentTab: 0,
});

const modalTitle = computed(() => {
  if (props.wallet) {
    return `${props.wallet.currency} Statement`;
  } else if (props.beneficiary) {
    return `Statement for beneficiary ${beneficiaryName(props.beneficiary)}`;
  }
  return 'Statement';
});

function changeStatementFM() {
  const format = statementFormDef.form.format;
  statementFormDef.form = [dailyStatementFM, monthlyStatementFM, customStatementFM][state.currentTab]();
  statementFormDef.form.format = format;
  setDateLimits();
}

watch(() => state.currentTab, changeStatementFM);

const createdDate = computed(() => {
  if (props.beneficiary) {
    return new Date(props.beneficiary.createdAt);
  }
  if (props.wallet) {
    return new Date(props.wallet.createdAt);
  }
  return null;
});

function setDateLimits() {
  if (createdDate.value) {
    const startDate = createdDate.value;

    if (state.currentTab === 1) {
      const years: FieldOptionObj[] = [];
      let currentYear = new Date().getFullYear();

      while (currentYear >= startDate.getFullYear()) {
        years.push({ value: currentYear, label: currentYear.toString() });
        currentYear -= 1;
      }

      batchSetState(statementFormDef.form, 'options', {
        year: years,
      });

      if (years.length >= 1) {
        statementFormDef.form.year = years[0].value;
      }
    }
  }
}

watch(createdDate, setDateLimits);

function show() {
  changeStatementFM();
  modal.value?.show();
}

function cancel() {
  requestManager.manager.cancel(statementDownloadKey.value);
  modal.value?.hide();
}

const dates = computed(() => {
  if (statementFormDef.form.$name === 'dailyStatement') {
    const date = new Date(statementFormDef.form.date);
    return {
      startDate: date.toISOString(),
      endDate: addDays(date, 1).toISOString(),
    };
  } else if (statementFormDef.form.$name === 'monthlyStatement') {
    const date = new Date(statementFormDef.form.year, statementFormDef.form.month);
    return {
      startDate: date.toISOString(),
      endDate: addMonths(date, 1).toISOString(),
    };
  }
  return {
    startDate: new Date(statementFormDef.form.startDate).toISOString(),
    endDate: addDays(new Date(statementFormDef.form.endDate), 1).toISOString(),
  };
});

const statementDownloadState = computed(() => requestManager.manager.requestStates[statementDownloadKey.value]);

function downloadStatement() {
  submitForm(statementFormDef.validation!);
  if (statementFormDef.validation!.$invalid || (!props.wallet && !props.beneficiary)) {
    return;
  }

  const query: Partial<TransactionQuery> = {
    docCreatedAtFrom: dates.value.startDate,
    docCreatedAtTo: dates.value.endDate,
  };

  if (props.feesStatements !== false) {
    query.reason = [
      TransactionReason.DEPOSIT_CLIENT_TO_AH_FEE,
      TransactionReason.WITHDRAW_TO_BENEFICIARY_CLIENT_TO_AH_FEE,
      TransactionReason.WITHDRAW_TO_OWNER_CLIENT_TO_AH_FEE,
      TransactionReason.WITHDRAW_CLIENT_TO_AH_FEE,
      TransactionReason.WITHDRAW_AH_TO_PARTNER_FEE,
      TransactionReason.WITHDRAW_PARTNER_TO_AH_FEE,
      TransactionReason.DEPOSIT_PARTNER_TO_AH_FEE,
      TransactionReason.DEPOSIT_AH_TO_PARTNER_FEE,
    ];
  }

  const request = props.beneficiary
    ? walletState.services.wallet.downloadBeneficiaryTransactionDetails(
        props.beneficiary!.id,
        query,
        statementFormDef.form.format,
        `Beneficiary Transaction Statement - ${beneficiaryName(props.beneficiary)}`
      )
    : walletState.services.wallet.downloadWalletStatement(
        props.wallet?.id || '',
        query,
        statementFormDef.form.format,
        `Wallet Statement${onBehalfOfClient.value?.name ? ` for client ${onBehalfOfClient.value.name}` : ''}`
      );

  requestManager.manager.cancelAndNew(statementDownloadKey.value, request).subscribe((doc) => {
    walletState.store.useNotificationsStore().triggerFileExportRequestNotification(doc);
    modal.value?.hide();
  });
}
</script>

<template>
  <span>
    <BModal :title="modalTitle" modal-class="statements-modal side-modal" ref="modal" hide-footer>
      <BTabs content-class="tabs mt-3" v-model="state.currentTab">
        <BTab :title="!walletState.mediaQuery.is('smDown') ? 'Daily Statement' : 'Daily'" active />
        <BTab :title="!walletState.mediaQuery.is('smDown') ? 'Monthly Statement' : 'Monthly'" />
        <BTab :title="!walletState.mediaQuery.is('smDown') ? 'Custom Statement' : 'Custom'" />
      </BTabs>
      <div class="card-block">
        <ValidatedForm :fm="statementFormDef.form" :validation.sync="statementFormDef.validation" />
      </div>
      <div class="mt-4 text-sm-center text-md-left">
        <VButton @click="cancel" class="btn-secondary mr-2">Cancel</VButton>
        <VButton @click="downloadStatement" :loading="statementDownloadState === 'pending'">Download</VButton>
      </div>
    </BModal>
    <slot v-bind="{ show }">
      <VButton @click="show" v-if="canDownloadStatement">Statements</VButton>
    </slot>
  </span>
</template>

<style lang="scss">
.tabs {
  li {
    @include upToResolution($tabletResolution) {
      width: 33%;
    }
  }
}

.statements-modal .modal-dialog {
  width: 40rem;
  @include upToResolution($tabletResolution) {
    .nav-item {
      width: 33%;
    }
  }
}
</style>
