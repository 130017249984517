import { render, staticRenderFns } from "./UBOForm.vue?vue&type=template&id=5559a24a&scoped=true"
import script from "./UBOForm.vue?vue&type=script&lang=ts"
export * from "./UBOForm.vue?vue&type=script&lang=ts"
import style0 from "./UBOForm.vue?vue&type=style&index=0&id=5559a24a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5559a24a",
  null
  
)

export default component.exports