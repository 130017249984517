<script setup lang="ts">
import { computed, getCurrentInstance, ref } from 'vue';
import { useAuthStore } from '@/app/store/authStore';
import { BrandingImageType } from 'ah-api-gateways';
import UserLogin from '@/app/components/session/UserLogin.vue';
import { useRouter } from 'vue-router/composables';

const theme = getCurrentInstance()?.proxy.$theme;

const authStore = useAuthStore();

const router = useRouter();

const stage = ref<string | null>(null);

const splashLogoLink = computed(() => {
  return theme?.val?.logos.find((l) => l.type === BrandingImageType.SPLASH_IMAGE)?.link;
});

function onLoginSuccess() {
  let path = authStore.isClientUser ? '/dashboard' : '/admin';
  router.push({ path });
}
</script>

<template>
  <SimpleCenteredPage class="centered-page" fullHeight>
    <div class="image-container">
      <img class="registration-partner-logo" :src="splashLogoLink" alt="" v-if="splashLogoLink" />
    </div>
    <div class="text-container" v-if="stage === 'login'">
      <h2>Success!</h2>
      <p>
        Your application has been submitted. Our team of onboarding specialists will review your application, we may ask
        you for additional information and/or supporting documentation. We have emailed you a copy of your Terms and
        Conditions and Legal Power of Attorney (LPOA) agreement.
      </p>
      <p>You can log in to X-Hedge, but until approved, the platform will have limited functionality.</p>
    </div>
    <UserLogin
      id="user-login"
      class="user-login-wrapper"
      ref="userLogin"
      @login-success="onLoginSuccess"
      @stage-change="stage = $event"
    />
  </SimpleCenteredPage>
</template>

<style lang="scss" scoped>
.centered-page {
  .image-container {
    display: flex;
    align-items: center;
    margin: 3rem 0;
  }
  .text-container {
    @include themedTextColor($color-text-secondary, $color-dark-text-secondary);
    margin-bottom: 3rem;
  }

  ::v-deep {
    .main-area {
      max-width: 40rem !important;
    }
    .otp-form {
      color: getColor($color-text-secondary);
      margin-top: 10rem;
    }
  }
  .registration-partner-logo {
    width: 50%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    object-fit: contain;
  }
  .user-login-wrapper {
    margin-left: 3rem;
    margin-right: 3rem;

    ::v-deep {
      .btn {
        min-width: 10rem;
      }
    }
  }

  @include upToResolution($tabletResolution) {
    .registration-partner-logo {
      width: 80%;
    }

    .text-container {
      margin-bottom: 0rem;
    }

    .text-container p,
    ::v-deep .field-group-field-label span {
      font-size: $font-size-xs;
    }

    ::v-deep .main-area {
      max-width: 60vw !important;
    }

    .user-login-wrapper {
      margin: 0;

      ::v-deep {
        .btn {
          min-width: 100%;
        }
      }
    }
  }
}
</style>
