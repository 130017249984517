import { ToRefs, ExtractPropTypes, PropOptions, ref } from 'vue';
import { useVModel } from '@vueuse/core';

/**
 * Helper types to define a ManagedComposable
 *
 * This is a composable that:
 * - Exposes a known props method that can be used to augment the props of the component that uses the composable
 * - Exposes known props as options (via a refs object) which can override any props given
 * - Uses emit object to control any emit augmented
 */

export type ManagedComposableProps<T> = {
  [K in keyof T]: PropOptions<T[K]>;
};

export type ManagedComposableOptions<X extends ManagedComposableProps<any>> = {
  props?: Partial<ExtractPropTypes<X>>;
  refs?: Partial<ToRefs<ExtractPropTypes<X>>>;
  emit?: any;
  passiveVModel?: boolean;
};

/**
 * Build refs object from ManagedComponent Options
 *
 * Given a ManagedComposableOptions object, will create managed refs,
 * where any refs defined using options will override props
 *
 * This is meant for internal use, within a composable
 */
export function buildRefsFromManagedComponentOptions<T>(
  propsObject: ManagedComposableProps<T>,
  options: ManagedComposableOptions<ManagedComposableProps<T>>
): Required<ToRefs<ExtractPropTypes<ManagedComposableProps<T>>>> {
  const out: Required<ToRefs<ExtractPropTypes<ManagedComposableProps<T>>>> = {} as Required<
    ToRefs<ExtractPropTypes<ManagedComposableProps<T>>>
  >;

  if (options.props && !options.emit) {
    throw 'When using props must also provide emit';
  }

  Object.keys(propsObject).forEach((key) => {
    const typedKey = key as keyof ExtractPropTypes<ManagedComposableProps<T>>;
    if (options.refs && options.refs[typedKey]) {
      (out as any)[typedKey] = options.refs[typedKey];
    } else if (options.props && options.props.hasOwnProperty(typedKey)) {
      out[typedKey] = useVModel(options.props, typedKey, options.emit!, { passive: options.passiveVModel }) as any;
    } else if (propsObject[typedKey].required) {
      throw `'${String(typedKey)}' is a required value, and has not been provided in either 'props' or 'refs'`;
    } else {
      (out as any)[typedKey] = ref();
    }
  });

  return out;
}

/**
 * Helper function to expose only Props that are not already overriden by the given refs for a ManagedComposable
 */
export function computeExposedProps<T>(
  propsObject: ManagedComposableProps<T>,
  refs: Partial<ToRefs<ExtractPropTypes<ManagedComposableProps<T>>>>
): Partial<ManagedComposableProps<T>> {
  const out: Partial<ManagedComposableProps<T>> = { ...propsObject };

  Object.keys(propsObject).forEach((key) => {
    const typedKey = key as keyof ExtractPropTypes<ManagedComposableProps<T>>;
    if (refs && refs[typedKey]) {
      delete out[typedKey];
    }
  });

  return out;
}
