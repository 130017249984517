<script setup lang="ts">
import { BModal } from 'bootstrap-vue';
import { FeesInfo } from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { computed, ref } from 'vue';
import { useTradeState } from '../..';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

defineProps<{
  feesInfo: FeesInfo;
}>();

const settleFeesModal = ref<InstanceType<typeof BModal> | null>(null);

const tradeState = useTradeState();

const requestManager = useRequestManager();

const onBehalfOfClient = useOnBehalfOf();

const emit = defineEmits<{
  /**
   * Emits:
   * - fees-settled (no payload): trigger when fees were settled successfully
   */
  (e: 'fees-settled', value: void): void;
}>();

const clientId = computed(
  () => onBehalfOfClient.value?.id || tradeState.store.useAuthStore().loggedInIdentity!.client!.id
);

function settleFees() {
  requestManager.manager
    .cancelAndNew('settleFees', tradeState.services.wallet.settleFees(clientId.value, onBehalfOfClient.value?.id))
    .subscribe(() => {
      emit('fees-settled');
      // TODO composition API
      // this.$toast.success('Fees settled successfully');
      settleFeesModal.value?.hide();
    });
}

function show() {
  settleFeesModal.value?.show();
}

function hide() {
  requestManager.manager.cancel('settleFees');
  settleFeesModal.value?.hide();
}
</script>

<template>
  <span>
    <BModal modal-class="side-modal settle-fees-modal" ref="settleFeesModal" hide-footer>
      <h3 class="modal-title">Settle fees</h3>
      <BoxGridBlock>
        <DataRow label="Fees total" cols="3">
          {{ feesInfo.debtCurrency }} {{ formatCurrencyValue(feesInfo.debtCumulative) }}
        </DataRow>
      </BoxGridBlock>
      <div class="text-right mr-3">
        <VButton class="btn-secondary mr-3" @click="hide"> Cancel </VButton>
        <VButton :loading="requestManager.manager.anyPending" @click="settleFees"> Confirm </VButton>
      </div>
    </BModal>
    <slot v-bind="{ show }">
      <VButton @click="show">Settle fees</VButton>
    </slot>
  </span>
</template>

<style lang="scss" scoped>
::v-deep .settle-fees-modal .modal-dialog {
  width: 38em;
}
</style>
