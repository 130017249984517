<script lang="ts" setup>
import { ref, watch, computed } from 'vue';
import { VButton } from '../common/components';
import CalendarIcon from 'ah-common-lib/src/icons/components/CalendarIcon.vue';
import ArrowLeftIcon from 'ah-common-lib/src/icons/components/ArrowLeftIcon.vue';
import ArrowRightIcon from 'ah-common-lib/src/icons/components/ArrowRightIcon.vue';
import ChevronDownIcon from 'ah-common-lib/src/icons/components/ChevronDownIcon.vue';

/**
 * Month selector component
 *
 * Month selector component, managing UI state accross a flow of user inputs to select year and month
 */

const props = defineProps({
  value: {
    type: Date,
  },
  min: {
    type: Date,
  },
  max: {
    type: Date,
  },
});

const emit = defineEmits({
  cancel: () => true,
  'update:value': (_value: Date) => true,
});

const selectionHolder = ref<HTMLDivElement>();

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const yearVal = ref(new Date().getFullYear());
const monthVal = ref(new Date().getMonth());
const uiState = ref<'month-select' | 'year-select'>('month-select');

const yearOptions = computed(() => {
  const minYear = props.min ? props.min.getFullYear() : 1970;
  const maxYear = props.max ? props.max.getFullYear() : new Date().getFullYear();

  const out: number[] = [];

  for (let i = minYear; i <= maxYear; i++) {
    out.push(i);
  }
  return out;
});

function submitValue() {
  emit('update:value', new Date(yearVal.value, monthVal.value, 1));
}

function cancelValue() {
  emit('cancel');
}

function isSetValid(year: number, month: number) {
  const date = new Date(year, month, 1);

  if (props.max && props.max < date) {
    return false;
  }

  if (props.min && props.min > date) {
    return false;
  }

  return true;
}

function selectYear(year: number) {
  setYear(year);
  toggleYearSelection(false);
}

function setYear(year: number) {
  if (isSetValid(year, monthVal.value)) {
    yearVal.value = year;
    scrollToYear();
  }
}

function setMonth(month: number) {
  if (isSetValid(yearVal.value, month)) {
    monthVal.value = month;
  }
}

function toggleYearSelection(on: boolean) {
  uiState.value = on ? 'year-select' : 'month-select';
  if (on) {
    scrollToYear();
  }
}

function scrollToYear() {
  if (uiState.value === 'year-select') {
    setTimeout(() => {
      if (selectionHolder.value) {
        const selectedYear = selectionHolder.value.getElementsByClassName('selected').item(0);
        if (selectedYear) {
          selectionHolder.value.scrollTop = (selectedYear as HTMLDivElement).offsetTop;
        }
      }
    });
  }
}

watch(
  () => props.value,
  () => {
    if (props.value) {
      yearVal.value = props.value.getFullYear();
      monthVal.value = props.value.getMonth();
    }
  },
  {
    immediate: true,
  }
);
</script>
<template>
  <div class="card-block month-selector-component">
    <div class="year-holder">
      <div class="prev-year" @click="setYear(yearVal - 1)">
        <slot name="prev-year-icon">
          <ArrowLeftIcon />
        </slot>
      </div>
      <div class="year-display">
        <span class="year-display-title" v-if="uiState === 'month-select'" @click="toggleYearSelection(true)">
          {{ yearVal }}
          <slot name="year-select-icon">
            <ChevronDownIcon />
          </slot>
        </span>
        <span class="year-display-title" v-else @click="toggleYearSelection(false)">
          <slot name="year-back-icon">
            <CalendarIcon />
          </slot>
        </span>
      </div>
      <div class="next-year" @click="setYear(yearVal + 1)">
        <slot name="next-year-icon">
          <ArrowRightIcon />
        </slot>
      </div>
    </div>
    <div class="selection-holder" ref="selectionHolder">
      <template v-if="uiState === 'month-select'">
        <div class="month selection-item-holder" v-for="(monthLabel, index) in months" :key="monthLabel">
          <div
            :class="['selection-item', { selected: monthVal === index, disabled: !isSetValid(yearVal, index) }]"
            @click.stop="setMonth(index)"
          >
            <span class="label">
              {{ monthLabel }}
            </span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="year selection-item-holder" v-for="year in yearOptions" :key="year">
          <div
            :class="['selection-item', { selected: yearVal === year, disabled: !isSetValid(year, monthVal) }]"
            @click.stop="selectYear(year)"
          >
            <span class="label">
              {{ year }}
            </span>
          </div>
        </div>
      </template>
    </div>

    <div class="confirmation-buttons">
      <VButton class="btn-secondary cancel-selection" @click="cancelValue">Cancel</VButton>
      <VButton class="btn-primary submit-selection" @click="submitValue">Apply</VButton>
    </div>
  </div>
</template>
<style lang="scss">
// Styles are unscoped as to be easily overridable

.month-selector-component {
  width: 300px;
  user-select: none;

  .card-body {
    display: grid;
    grid-template-rows: 1fr 120px;
    grid-template-columns: 100%;
  }

  .year-holder {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    height: 30px;
    border-bottom: 1px solid #ccc;
    padding: 0 10px;
    align-items: center;

    .year-display {
      display: flex;
      font-weight: bold;
      justify-content: center;
      align-items: center;

      .year-display-title {
        cursor: pointer;
      }
    }

    .next-year,
    .prev-year {
      cursor: pointer;
    }
  }

  .selection-holder {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -4px;
    height: 120px;
    overflow-y: scroll;

    .selection-item-holder {
      flex: 0 0 25%;
      height: 40px;
      padding: 4px;

      .selection-item {
        height: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        cursor: pointer;

        &.disabled {
          pointer-events: none;
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }
  }

  .confirmation-buttons {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;

    .cancel-selection {
      flex-basis: 50%;
      margin-right: 5px;
    }

    .submit-selection {
      flex-basis: 50%;
      margin-left: 5px;
    }
  }
}
</style>
