<template>
  <div>
    <AccordionBox v-for="(group, index) in groupedNotifications" :key="index">
      <template #title>
        <NotificationGroupHeaderEditForm
          class="header my-4"
          :group="group"
          :showTitles="stacked"
          :channels="notificationChannels(group.notifications)"
          @update:change="atGroupSwitchChange($event, group.notifications)"
        />
      </template>
      <template v-for="config in group.notifications">
        <SingleNotificationSettingEditForm
          v-if="!config.timed"
          :key="config.id"
          :config="config"
          :hideTargets="isIndividualClient"
          :showTitles="stacked"
          class="single-notification-editor"
          :notification="getNotification(config.type)"
          @update:notification="onNotificationUpdate"
        />
        <div v-else-if="config.timed" :key="config.type">
          <VRow class="align-items-center">
            <VCol class="description-col">
              <h3 class="title">{{ config.name }}</h3>
              <p class="sub-title text-secondary">
                {{ config.description }}
              </p>
            </VCol>
          </VRow>
          <template v-if="getTimedNotifications(config.type).length > 0">
            <SingleNotificationSettingEditForm
              :config="config"
              :hideTargets="isIndividualClient"
              :showTitles="stacked"
              deletable
              v-for="(notification, index) in getTimedNotifications(config.type)"
              :key="index"
              class="single-notification-editor sub-item"
              :notification="notification"
              @update:notification="onNotificationUpdate"
              @delete-notification="onNotificationDelete"
            />
          </template>
          <p class="text-secondary no-notifications" v-else>No notifications set yet</p>
          <div class="text-md-left text-sm-center">
            <VButton class="add-notification" @click="addTempTimedNotification(config.type, group.notifications)"
              >Add new alert</VButton
            >
          </div>
        </div>
      </template>
    </AccordionBox>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Mixins } from 'vue-property-decorator';
import SingleNotificationSettingEditForm from '@/app/components/settings/notifications/SingleNotificationSettingEditForm.vue';
import SingleNotificationChannelEditForm from '@/app/components/settings/notifications/SingleNotificationChannelEditForm.vue';
import NotificationGroupHeaderEditForm from '@/app/components/settings/notifications/NotificationGroupHeaderEditForm.vue';
import {
  ClientReference,
  ClientType,
  NotificationChannel,
  NotificationChannelType,
  NotificationSetting,
  NotificationSettingConfig,
  NotificationTimeOffsetUnit,
  TimedNotificationSetting,
} from 'ah-api-gateways';
import AccordionBox from '@/app/components/common/AccordionBox.vue';
import { generateTempUUID, isTempUUID } from 'ah-common-lib/src/helpers/uuid';
import { timer } from 'rxjs';
import { SECOND } from 'ah-common-lib/src/constants/time';
import { take } from 'rxjs/operators';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { useAuthStore } from '@/app/store/authStore';
import { useNotificationSettingsStore } from '@/app/store/notificationSettingsModule';

const NOTIFICATION_SAVE_DEBOUNCE_TIME = SECOND;

@Component({
  components: {
    AccordionBox,
    SingleNotificationSettingEditForm,
    SingleNotificationChannelEditForm,
    NotificationGroupHeaderEditForm,
  },
})
export default class NotificationGroupSettingEditForm extends Mixins(WithRequestManager) {
  @Prop({ required: true }) settings!: NotificationSettingConfig[];

  @Prop({ default: false }) stacked!: boolean | string;

  requestManagerConfig = {
    exposeToParent: true,
  };

  private tempTimedNotificationSettings: Partial<{
    [key: string]: TimedNotificationSetting[];
  }> = {};

  get authStore() {
    return useAuthStore();
  }

  get notificationSettingsStore() {
    return useNotificationSettingsStore();
  }

  get groupedNotifications() {
    const out = groupBy(this.settings, 'sectionName');

    return Object.keys(out)
      .map((group) => ({
        name: group,
        index: out[group][0].sectionIndex,
        notifications: sortBy(out[group], 'index'),
      }))
      .filter((notification) => notification.index >= 0)
      .sort((a, b) => a.index - b.index);
  }

  onNotificationUpdate(notification: NotificationSetting) {
    if (this.isIndividualClient) {
      /**
       * If client is Individual, destination controls will be hidden,
       * and `to` is always set to the client
       */
      notification.to = [];
    }
    // Using timer->take as a debounce method, as RequestManager will cancel any previous request,
    // and the promise will never be called

    this.requestManager
      .cancelAndNew(`saveNotification-${notification.id}`, timer(NOTIFICATION_SAVE_DEBOUNCE_TIME).pipe(take(1)))
      .subscribe(() => {
        this.notificationSettingsStore.saveNotificationSetting(notification).then(() => {
          if (isTempUUID(notification.id)) {
            this.removeTempNotification(notification);
          }
        });
      });
  }

  removeTempNotification(notification: NotificationSetting) {
    const tempCollection = this.tempTimedNotificationSettings[notification.type];
    if (tempCollection) {
      const index = tempCollection.findIndex((n) => n.id === notification.id);
      if (index > -1) {
        tempCollection.splice(index, 1);
      }
    }
  }

  onNotificationDelete(notification: NotificationSetting) {
    this.requestManager.cancel(`saveNotification-${notification.id}`);
    if (isTempUUID(notification.id)) {
      this.removeTempNotification(notification);
    } else {
      this.notificationSettingsStore.deleteNotificationSetting(notification);
    }
  }

  addTempTimedNotification(type: string, group: NotificationSettingConfig[]) {
    if (!this.tempTimedNotificationSettings[type]) {
      Vue.set(this.tempTimedNotificationSettings, type, []);
    }
    const n = group.find((notification) => notification.type === type);
    const notification = {
      type,
      channels: this.notificationChannels(group),
      to: [],
      id: generateTempUUID(),
      timeUnit: n?.defaultTimeUnit || NotificationTimeOffsetUnit.DAYS,
      timeValue: n?.defaultTimeValue || 1,
    };

    this.tempTimedNotificationSettings[type]!.push(notification);
    this.onNotificationUpdate(notification);
  }

  getNotification(type: string): NotificationSetting {
    return (
      this.notifications.notifications[type] || {
        type,
        channels: [],
        to: [],
        id: generateTempUUID(),
      }
    );
  }

  getTimedNotifications(type: string): TimedNotificationSetting[] {
    return [
      ...(this.notifications.timedNotifications[type] || []),
      ...(this.tempTimedNotificationSettings[type] || []),
    ];
  }

  allSettingsEnabled(type: NotificationChannelType, group: NotificationSettingConfig[]) {
    if (group.filter((n) => !n.channels.find((c) => c.channelType === type)?.configurable).length === group.length) {
      /*
       * if all notifications in the group are not configurable,
       * return false if at least one is set to false
       */
      return group.findIndex((n) => n.channels.find((c) => c.channelType === type)?.defaultEnabledValue === false) < 0;
    }

    let out = true;
    group.forEach((n) => {
      const channelSpec = n.channels.find((c) => c.channelType === type);
      if (!n.timed) {
        const channel = this.getNotification(n.type).channels.find((c) => c.type === type);
        /*
         * if at least one notification in the group that is configurable is disabled,
         * then the header switch should be disabled.
         */
        if (channelSpec?.configurable && !channel?.enabled) {
          out = false;
        }
      } else {
        this.getTimedNotifications(n.type).forEach((timedNotification) => {
          const channel = timedNotification.channels.find((c) => c.type === type);
          if (channelSpec?.configurable && !channel?.enabled) {
            out = false;
          }
        });
      }
    });
    return out;
  }

  atGroupSwitchChange(channel: NotificationChannel, group: NotificationSettingConfig[]) {
    group.forEach((n) => {
      if (n.channels.find((c) => c.channelType === channel.type)!.configurable) {
        if (!n.timed) {
          const notification = this.getNotification(n.type);
          const notificationChannel = notification.channels.find((c) => c.type === channel.type);
          if (notificationChannel && notificationChannel.enabled !== channel.enabled) {
            notificationChannel.enabled = channel.enabled;
          } else if (channel.enabled) {
            notification.channels.push(channel);
          }
          this.onNotificationUpdate(notification);
        } else {
          const notifications = this.getTimedNotifications(n.type);
          notifications.forEach((notification) => {
            const notificationChannel = notification.channels.find((c) => c.type === channel.type);
            if (notificationChannel) {
              notificationChannel.enabled = channel.enabled;
            } else {
              notification.channels.push({
                type: channel.type,
                enabled: channel.enabled,
              });
            }
            this.onNotificationUpdate(notification);
          });
        }
      }
    });
  }

  get client(): ClientReference | null {
    return this.authStore.loggedInIdentity?.client ?? null;
  }

  get isIndividualClient() {
    return this.authStore.isClientUser && this.client!.type === ClientType.INDIVIDUAL;
  }

  get notifications() {
    return this.notificationSettingsStore.notificationSettings;
  }

  get notificationChannels() {
    return (group: NotificationSettingConfig[]) => {
      return [
        {
          type: NotificationChannelType.EMAIL,
          enabled: this.allSettingsEnabled(NotificationChannelType.EMAIL, group),
        },
        {
          type: NotificationChannelType.IN_APP,
          enabled: this.allSettingsEnabled(NotificationChannelType.IN_APP, group),
        },
        {
          type: NotificationChannelType.SMS,
          enabled: this.allSettingsEnabled(NotificationChannelType.SMS, group),
        },
      ];
    };
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .set-all-text {
    font-weight: $font-weight-semibold;
  }

  .disabled {
    &-on > label {
      &::before {
        @include themedBackgroundColor($color-primary, $color-primary, '', '!important');
      }
      &::after {
        @include themedBackgroundColor($color-main-bg, $color-main-bg, '', '!important');
      }
    }
    &-off > label {
      &::before {
        @include themedBackgroundColor($color-shadow, $color-shadow, '', '!important');
      }
    }
  }

  .lock {
    @include themedTextColor($color-dark-primary);
    font-size: $font-size-xs;
    position: absolute;
    @include phablet {
      left: 28px;
      bottom: 7px;
    }
    @include tablet {
      left: 8px;
      bottom: 7px;
    }
    @include desktop {
      left: 12px;
      top: 5px;
    }
  }

  .body {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}

.header {
  border-bottom: 1px solid;
  @include themedBorderColor($color-box-border);
  ::v-deep * {
    @include desktop {
      margin-bottom: -1rem;
    }
  }
}
</style>
