<template>
  <ResponsiveNav>
    <ul class="main">
      <RouterLink tag="li" class="menu-link" to="/dashboard" exact>
        <IconDashboard /> <span class="menu-link-text"> Dashboard </span>
      </RouterLink>
      <SideNavTradeLink v-if="canViewTrades" />
      <RouterLink tag="li" class="menu-link" to="/dashboard/payments">
        <IconWallet /> <span class="menu-link-text"> Payments</span>
      </RouterLink>
      <RouterLink tag="li" class="menu-link" to="/dashboard/wallets">
        <IconWallet /> <span class="menu-link-text"> Wallets</span>
      </RouterLink>
      <RouterLink tag="li" class="menu-link" to="/dashboard/beneficiaries">
        <IconAgenda /> <span class="menu-link-text"> Beneficiaries </span>
      </RouterLink>
    </ul>
    <template #bottom>
      <ul class="bottom">
        <SideNavSettingsLink />
      </ul>
    </template>
  </ResponsiveNav>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import InfoTooltip from '@/app/components/common/InfoTooltip.vue';
import { AuthorityType } from 'ah-api-gateways';
import TradesSidebarView from '@/app/views/dashboard/trades/TradesSidebarView.vue';
import SideNavTradeLink from './SideNavTradeLink.vue';
import SideNavSettingsLink from './SideNavSettingsLink.vue';
import { useAuthStore } from '@/app/store/authStore';

@Component({
  components: {
    InfoTooltip,
    TradesSidebarView,
    SideNavTradeLink,
    SideNavSettingsLink,
  },
})
export default class ClientResponsiveNav extends Vue {
  get authStore() {
    return useAuthStore();
  }

  get canViewTrades() {
    return this.authStore.hasAuthorities(AuthorityType.VIEW_TRADES);
  }
}
</script>
