<template>
  <div class="nav-list">
    <div class="main">
      <slot />
    </div>
    <div class="bottom">
      <slot name="bottom" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ResponsiveNav extends Vue {
  @Prop({ default: '' }) linkLists!: string;
}
</script>
<style lang="scss" scoped>
.nav-list {
  position: relative;
  display: grid;
  grid-template-rows: auto max-content;

  .main {
    max-width: 100%;
    overflow: hidden;
  }

  ul {
    list-style: none;
    padding: 0;

    > li {
      width: 100%;
      cursor: pointer;
    }
  }
}
</style>
