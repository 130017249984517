<script lang="ts" setup>
import { Beneficiary } from 'ah-api-gateways';
import { BModal } from 'bootstrap-vue';
import BankAccountEditor from './BankAccountEditor.vue';
import { computed, ref } from 'vue';

const bankAccountEditModal = ref<InstanceType<typeof BModal> | null>(null);

const emit = defineEmits<{
  /**
   * - update:beneficiary : payload with beneficiary
   */
  (e: 'update:beneficiary', value: Beneficiary | null): void;
}>();

const props = defineProps<{
  beneficiary?: Beneficiary;
}>();

const buttonTitle = computed(() => {
  return props.beneficiary ? 'Edit Bank Account' : 'Add Bank Account';
});

const title = computed(() => {
  return props.beneficiary ? 'Edit Bank Account' : 'Add Bank Account';
});

function showModal() {
  bankAccountEditModal.value!.show();
}

function hideModal() {
  bankAccountEditModal.value!.hide();
}

function onBankAccountSaved(beneficiary: Beneficiary) {
  emit('update:beneficiary', beneficiary);
  bankAccountEditModal.value!.hide();
}
</script>
<style lang="scss" scoped>
::v-deep .bank-account-modal .modal-dialog {
  width: 40em;
}

.beneficiary-name {
  display: inline-block;
}
</style>

<template>
  <span>
    <BModal
      modal-class="side-modal bank-account-modal"
      ref="bankAccountEditModal"
      hide-footer
      v-bind="$attrs"
      :title="title"
    >
      <BankAccountEditor @update:bankAccount="onBankAccountSaved" @cancel="hideModal()" v-bind="$attrs" />
    </BModal>
    <slot v-bind="{ showModal }">
      <VButton @click="showModal()">{{ buttonTitle }}</VButton>
    </slot>
  </span>
</template>
