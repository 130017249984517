<script setup lang="ts">
import { ComplianceStatus, OnboardingStatusLabel } from 'ah-api-gateways';
import StatusPill from 'ah-common-lib/src/common/components/StatusPill.vue';
import { computed } from 'vue';

defineProps<{
  status: OnboardingStatusLabel;
}>();

const statusColors = computed<Record<ComplianceStatus, string>>(() => {
  return {
    [ComplianceStatus.APPROVED]: 'success',
    [ComplianceStatus.DOCUMENTS_REQUIRED]: 'orange',
    [ComplianceStatus.TERMS_AND_CONDITIONS]: 'orange',
    [ComplianceStatus.SUBMITTED]: 'warning',
    [ComplianceStatus.PENDING]: 'blue',
    [ComplianceStatus.REJECTED]: 'danger',
    [ComplianceStatus.UPDATED_TERMS_AND_CONDITIONS]: 'primary',
    [ComplianceStatus.UNKNOWN]: 'grey',
    [ComplianceStatus.DEACTIVATED]: 'grey',
    [ComplianceStatus.SUSPENDED]: 'grey',
  };
});

const defaultStatusLabels = computed<Record<ComplianceStatus, string>>(() => {
  return {
    [ComplianceStatus.APPROVED]: 'Approved',
    [ComplianceStatus.DOCUMENTS_REQUIRED]: 'Incomplete',
    [ComplianceStatus.PENDING]: 'Pending',
    [ComplianceStatus.REJECTED]: 'Rejected',
    [ComplianceStatus.UPDATED_TERMS_AND_CONDITIONS]: 'T&Cs Updated',
    [ComplianceStatus.SUBMITTED]: 'Submitted',
    [ComplianceStatus.TERMS_AND_CONDITIONS]: 'Incomplete',
    [ComplianceStatus.UNKNOWN]: 'Unknown',
    [ComplianceStatus.DEACTIVATED]: 'Deactivated',
    [ComplianceStatus.SUSPENDED]: 'Suspended',
  };
});
</script>

<template>
  <StatusPill
    class="status-pill"
    :color="statusColors[status.status ?? ComplianceStatus.UNKNOWN]"
    :label="status.statusGroupedLabel || defaultStatusLabels[status.status ?? ComplianceStatus.UNKNOWN]"
  />
</template>

<style lang="scss" scoped>
.status-pill {
  white-space: nowrap;
  font-size: $font-size-sm;
}
</style>
