<script setup lang="ts">
import { computed } from 'vue';
import { Wallet } from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { FundsInTransitIcon } from 'ah-common-lib/src/icons/components';

const props = withDefaults(
  defineProps<{
    wallet: Wallet;
    titleTag?: string;
    titleClass?: string;
    subTitleTag?: string;
    subTitleClass?: string;
  }>(),
  {
    titleTag: 'h2',
    titleClass: '',
    subTitleTag: 'p',
    subTitleClass: 'text-secondary mt-n3',
  }
);

const sellTransit = computed(
  () => ` ${props.wallet.currency} ${formatCurrencyValue(props.wallet.tradeSellAmountInTransit)}`
);
const buyTransit = computed(
  () => ` ${props.wallet.currency} ${formatCurrencyValue(props.wallet.tradeBuyAmountInTransit)}`
);
</script>
<style lang="scss" scoped>
.funds-in-transit {
  font-size: 14px;
}
</style>

<template>
  <div>
    <Component :is="titleTag" :class="titleClass">
      {{ wallet.currency }}
      <span class="color-primary">{{ '&#9679;' }}</span>
      Balance: {{ wallet.currency }} {{ formatCurrencyValue(wallet.balance) }}
    </Component>
    <Component :is="subTitleTag" :class="subTitleClass" v-if="wallet.outgoingBalance || wallet.incomingBalance">
      <span v-if="wallet.outgoingBalance">
        Balance deducted &amp; reserved for pending conversion: {{ formatCurrencyValue(wallet.outgoingBalance) }}
      </span>
      <br v-if="wallet.outgoingBalance && wallet.incomingBalance" />
      <span v-if="wallet.incomingBalance">
        Balance includes pending incoming from conversion: {{ formatCurrencyValue(wallet.incomingBalance) }}
      </span>
    </Component>
    <VRow v-if="wallet.tradeBuyAmountInTransit > 0 || wallet.tradeSellAmountInTransit > 0">
      <VCol cols="2"
        ><div class="d-flex align-items-center justify-content-center w-100 h-100">
          <FundsInTransitIcon></FundsInTransitIcon></div
      ></VCol>
      <VCol cols="10">
        <p class="funds-in-transit mb-0">
          <label>Sell Transit Amount: </label><b>{{ sellTransit }}</b> <br />
          <label>Buy Transit Amount: </label><b>{{ buyTransit }}</b>
        </p>
      </VCol>
    </VRow>
  </div>
</template>
