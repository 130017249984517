<script setup lang="ts">
defineProps({
  removeButtonText: {
    type: String,
    required: true,
  },
});

const emit = defineEmits({
  remove: () => true,
});

const handleClick = () => {
  emit('remove');
};
</script>

<template>
  <div>
    <VButton @click="handleClick" class="remove-btn" blurOnClick
      ><TrashcanIcon /><span>{{ removeButtonText }}</span></VButton
    >
  </div>
</template>

<style lang="scss" scoped>
.remove-btn {
  border-radius: 0.375em;
  padding: 0.375em 0.75em;
  @include themedBackgroundColor($color-danger);
  color: $white;
  box-shadow: 0px 0px 0px 1px rgba(190, 18, 60, 0.8);

  &:hover,
  &:focus {
    background: getColorWithAlpha($color-danger, 0.8) !important;
  }
}
</style>
