<script setup lang="ts">
import { setState } from 'ah-common-lib/src/form/helpers';
import {
  OnboardingIndividualInfo,
  IndividualType,
  CompanyRegistrationModel,
  clearIndividual,
  checkAddressHistoryValidity,
} from 'ah-api-gateways';
import { CompoundValidation, FormDefinition, FormEvent, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { toDataModel } from 'ah-common-lib/src/form/helpers';
import { cloneDeep } from 'lodash';
import { computed, reactive, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { makeAdditionalUserFM, makeApplicantExtraInfoForm, makeUserTypeFM } from '@/app/helpers/registration/forms';
import ProposedUserPermissionsForm from './ProposedUserPermissionsForm.vue';
import { useAuthStore } from '@/app/store/authStore';
import { AdditionalUserFormObj, useRegistrationIndividuals } from './useRegistrationIndividuals';
import { useTheme } from 'ah-theme';
import { helpers } from '@vuelidate/validators';
import { useFEFeatureFlag } from 'ah-common-stores/src/stores/featureFlagStore';
import RegistrationAddressForm from '../common/RegistrationAddressForm.vue';
import PreviousAddressesForms from '../common/PreviousAddressesForms.vue';
import RemoveButton from '../common/RemoveButton.vue';
import { useToast } from 'ah-common-lib/src/toast';

const requestManager = useRequestManager().manager;
const authStore = useAuthStore();
const theme = useTheme();
const toast = useToast();

const props = defineProps<{ model: Partial<CompanyRegistrationModel> }>();

const primaryOwnerAddressValidation = ref<FormValidation>();
const primaryOwnerPreviousAddressesValidation = ref<CompoundValidation>();

const secondaryOwnerAddressValidation = ref<FormValidation>();
const secondaryOwnerPreviousAddressesValidation = ref<CompoundValidation>();

const showErrorMessage = ref(false);

const differentFromIndividuals = helpers.withParams(
  { type: 'differentFromIndividuals' },
  (val: any) => !registrationIndividuals.isDuplicatedEmail(val)
);

const differentFromApplicant = helpers.withParams(
  { type: 'differentFromApplicant' },
  (val: any) => registrationIndividuals.applicantEmail.value !== val
);

const differentFromOwner = helpers.withParams(
  { type: 'differentFromOwner' },
  (val: any) => registrationIndividuals.ownerEmail.value !== val
);

const differentFromSecondaryOwner = helpers.withParams(
  { type: 'differentFromSecondaryOwner' },
  (val: any) => registrationIndividuals.secondaryOwnerEmail.value !== val
);

function ownerEmailValidations() {
  return { differentFromApplicant, differentFromIndividuals, differentFromSecondaryOwner };
}

function secondaryOwnerEmailValidations() {
  return { differentFromApplicant, differentFromIndividuals, differentFromOwner };
}

function otherEmailValidations() {
  return { differentFromApplicant, differentFromIndividuals, differentFromSecondaryOwner, differentFromOwner };
}

const individuals = computed(
  () =>
    props.model.individuals || [
      {
        ...cloneDeep(authStore.userData?.individual),
        ...cloneDeep(props.model.applicant),
        applicant: true,
        owner: true,
        previousAddresses: [],
        currentAddress: { residingFrom: '' },
      } as OnboardingIndividualInfo,
    ]
);

const isPrimaryOwnerUKResident = computed(() => {
  const primaryOwner = registrationIndividuals.owner.value?.individual;
  return primaryOwner?.currentAddress?.countryCode === 'GB';
});

const isSecondaryOwnerUKResident = computed(() => {
  const secondaryOwner = registrationIndividuals.secondaryOwner.value?.individual;
  return secondaryOwner?.currentAddress?.countryCode === 'GB';
});

const areFormsInvalid = computed(() => {
  const isPrimaryOwnerInvalid =
    !!primaryOwnerAddressValidation.value?.$invalid || !!primaryOwnerPreviousAddressesValidation.value?.$invalid;

  let isSecondaryOwnerInvalid = false;
  if (registrationIndividuals.secondaryOwner.value) {
    isSecondaryOwnerInvalid =
      !!secondaryOwnerAddressValidation.value?.$invalid || !!secondaryOwnerPreviousAddressesValidation.value?.$invalid;
  }

  const isRegistrationIndividualsInvalid = !!registrationIndividuals.validation.value?.$invalid;

  return isPrimaryOwnerInvalid || isSecondaryOwnerInvalid || isRegistrationIndividualsInvalid;
});

function checkPrimaryOwnerAddressValidity() {
  const primaryOwner = registrationIndividuals.owner.value?.individual;
  if (!isPrimaryOwnerUKResident.value || !primaryOwner) {
    return true;
  }

  const addressHistoryValidity = checkAddressHistoryValidity({
    currentAddress: { ...primaryOwner?.currentAddress },
    previousAddresses: primaryOwner?.previousAddresses || [],
  });
  return addressHistoryValidity.age && addressHistoryValidity.gaps;
}

function checkSecondaryOwnerAddressValidity() {
  const secondaryOwner = registrationIndividuals.secondaryOwner.value?.individual;
  if (!isSecondaryOwnerUKResident.value || !secondaryOwner) {
    return true;
  }

  const addressHistoryValidity = checkAddressHistoryValidity({
    currentAddress: { ...secondaryOwner?.currentAddress },
    previousAddresses: secondaryOwner?.previousAddresses || [],
  });
  return addressHistoryValidity.age && addressHistoryValidity.gaps;
}

function checkAddressDateValidity() {
  const invalidPrimary = !checkPrimaryOwnerAddressValidity();
  const invalidSecondary = !checkSecondaryOwnerAddressValidity();
  if (invalidPrimary) {
    toast.error(
      'Please review Primary Signatory addresses. UK residents must provide addresses spanning back 3 years, with no gaps.'
    );
  }

  if (invalidSecondary) {
    toast.error(
      'Please review Secondary Signatory addresses. UK residents must provide addresses spanning back 3 years, with no gaps.'
    );
  }

  if (invalidPrimary || invalidSecondary) {
    return false;
  }

  return true;
}

const dobInOnboardingFeatureActive = useFEFeatureFlag('dobInOnboardingFeatureActive');

const registrationIndividuals = useRegistrationIndividuals({
  requestManager,
  individuals,
  userFormFactory: (individual) => {
    if (individual?.applicant && dobInOnboardingFeatureActive.value) {
      return makeApplicantExtraInfoForm();
    } else if (individual?.owner) {
      return makeAdditionalUserFM({
        emailValidations: ownerEmailValidations(),
        isSignatory: true,
        useDOB: dobInOnboardingFeatureActive.value,
      });
    } else if (individual?.secondaryOwner) {
      return makeAdditionalUserFM({
        emailValidations: secondaryOwnerEmailValidations(),
        useDOB: dobInOnboardingFeatureActive.value,
      });
    }
    return makeAdditionalUserFM({
      emailValidations: otherEmailValidations(),
    });
  },
});

const emit = defineEmits<{
  /**
   * 'proceed' - Emitted when valid form is submitted
   */
  (e: 'proceed', value: void): void;
  /**
   * 'update:model' - Emitted when any changes on `model` are done
   */
  (e: 'update:model', value: Partial<CompanyRegistrationModel>): void;
  /**
   * 'update:validation' - Emitted on any form validation changes
   */
  (e: 'update:validation', value: FormValidation<CompanyRegistrationModel>): void;
}>();

const signatoryFM = reactive<FormDefinition>({ form: makeUserTypeFM(), validation: null });

const partnerName = computed(() => theme?.val?.name || '');

function calculateModel(): CompanyRegistrationModel {
  return {
    ...props.model,
    owner: registrationIndividuals.owner.value
      ? clearIndividual({
          ...toDataModel(registrationIndividuals.owner.value.form),
          type: IndividualType.CLIENT_ADMIN,
          owner: true,
        })
      : undefined,
    individuals: registrationIndividuals.individualEntries.value.map((i) => clearIndividual(i.individual)),
  };
}

function onUserFormEvent(event: FormEvent, userForm: AdditionalUserFormObj) {
  if (event.event === 'form-field-set-value') {
    setState(userForm.form, 'errors', [], true);
    registrationIndividuals.updateEntry(
      {
        ...userForm.individual,
        ...toDataModel(userForm.form),
      },
      userForm
    );
  }
}

function removeSecondaryOwner() {
  if (registrationIndividuals.secondaryOwner.value?.individual.id) {
    registrationIndividuals.removeEntry(registrationIndividuals.secondaryOwner.value.individual.id);
  }
  secondaryOwnerPreviousAddressesValidation.value = undefined;
  secondaryOwnerAddressValidation.value = undefined;
}

function submit() {
  if (!checkAddressDateValidity()) {
    showErrorMessage.value = true;
    return;
  }
  showErrorMessage.value = false;

  registrationIndividuals.saveEntries().subscribe(() => {
    emit('update:model', calculateModel());
    emit('proceed');
  });
}

function onOwnerFormEvent(event: FormEvent) {
  if (event.event === 'form-field-set-value') {
    const applicantIsOwner = signatoryFM.form.userTypes === 'owner';
    if (applicantIsOwner) {
      if (!registrationIndividuals.isApplicantOwner.value && registrationIndividuals.owner.value?.individual.id) {
        registrationIndividuals.removeEntry(registrationIndividuals.owner.value.individual.id);
      }
      registrationIndividuals.updateEntry(
        { owner: true, type: IndividualType.CLIENT_ADMIN },
        registrationIndividuals.applicant.value!
      );
    } else if (registrationIndividuals.isApplicantOwner.value) {
      const owner = props.model.owner || props.model.individuals?.find((i) => i.owner && !i.applicant);
      registrationIndividuals.addEntry({ ...owner, owner: true, applicant: false, type: IndividualType.CLIENT_ADMIN });
      registrationIndividuals.updateEntry(
        { owner: false, type: IndividualType.CLIENT_INDIVIDUAL },
        registrationIndividuals.applicant.value!
      );
    }
  }
}

function addSecondaryOwnerObject() {
  registrationIndividuals.addEntry({
    secondaryOwner: true,
    currentAddress: {
      residingFrom: '',
    },
    previousAddresses: [],
  });
}

watch(
  registrationIndividuals.isApplicantOwner,
  () => {
    signatoryFM.form.userTypes = !registrationIndividuals.isApplicantOwner.value ? 'user' : 'owner';
    if (
      dobInOnboardingFeatureActive.value &&
      !registrationIndividuals.isApplicantOwner.value &&
      registrationIndividuals.applicant.value
    ) {
      registrationIndividuals.applicant.value.validation = null;
    }
  },
  { immediate: true }
);

watch(
  registrationIndividuals.validation,
  () =>
    emit('update:validation', {
      ...registrationIndividuals.validation.value,
      $model: calculateModel(),
    } as FormValidation<CompanyRegistrationModel>),
  {
    immediate: true,
  }
);
</script>

<template>
  <div x-test-name="account-ownership-form">
    <div>
      <h2>Authorised Signatory</h2>
      <p class="text-secondary">
        Please note that we need an Authorised Signatory to approve the application. This will be sent upon completion.
      </p>

      <div class="card-block">
        <ValidatedForm :fm="signatoryFM.form" :validation.sync="signatoryFM.validation" @form-event="onOwnerFormEvent">
          <template #signatoryForm.userTypes:option="{ option }">
            <p class="mb-0">{{ option.label }}</p>
            <p class="mb-0 text-muted text-small">
              i.e. {{ option.value === 'owner' ? 'Listed Director/Board Member' : 'Company Representative' }}
            </p>
          </template>
          <ExpandTransition>
            <ProposedUserPermissionsForm
              v-if="registrationIndividuals.applicant.value && !registrationIndividuals.isApplicantOwner.value"
              :individual="registrationIndividuals.applicant.value.individual"
              :authorities="registrationIndividuals.authorities.value"
              @update:individual="registrationIndividuals.updateEntry($event, registrationIndividuals.applicant.value)"
            />
            <ValidatedForm
              v-else-if="dobInOnboardingFeatureActive && registrationIndividuals.applicant.value"
              :fm="registrationIndividuals.applicant.value.form"
              :validation.sync="registrationIndividuals.applicant.value.validation"
              @form-event="onUserFormEvent($event, registrationIndividuals.applicant.value)"
            >
              <div class="mt-4">
                <h3 class="account-ownership-heading">Current Address</h3>
                <RegistrationAddressForm
                  :address.sync="registrationIndividuals.applicant.value.individual.currentAddress"
                  :validation.sync="primaryOwnerAddressValidation"
                  :isCountryEditable="true"
                />
              </div>
              <PreviousAddressesForms
                v-if="isPrimaryOwnerUKResident && registrationIndividuals.applicant.value.individual.currentAddress"
                :previousAddresses.sync="registrationIndividuals.applicant.value.individual.previousAddresses"
                :validation.sync="primaryOwnerPreviousAddressesValidation"
                :currentAddressResidenceDate.sync="
                  registrationIndividuals.applicant.value.individual.currentAddress.residingFrom
                "
                :showErrorMessage="showErrorMessage"
              />
            </ValidatedForm>
          </ExpandTransition>
        </ValidatedForm>
      </div>

      <ExpandTransition>
        <div
          v-if="!registrationIndividuals.isApplicantOwner.value && registrationIndividuals.owner.value"
          class="mt-3 card-block"
        >
          <h3 class="account-ownership-heading">Primary Authorised Signatory</h3>
          <p class="text-secondary">
            Please enter the contact details of a Director or Board Member who will sign the Terms and Conditions and
            review the form.
          </p>
          <ValidatedForm
            :fm="registrationIndividuals.owner.value.form"
            :validation.sync="registrationIndividuals.owner.value.validation"
            @form-event="onUserFormEvent($event, registrationIndividuals.owner.value)"
          >
            <template #additionalUserForm.email:before>
              <h3 class="account-ownership-heading">Current Address</h3>
              <RegistrationAddressForm
                :address.sync="registrationIndividuals.owner.value.individual.currentAddress"
                :validation.sync="primaryOwnerAddressValidation"
                :isCountryEditable="true"
              />
              <PreviousAddressesForms
                v-if="isPrimaryOwnerUKResident && registrationIndividuals.owner.value.individual.currentAddress"
                :previousAddresses.sync="registrationIndividuals.owner.value.individual.previousAddresses"
                :validation.sync="primaryOwnerPreviousAddressesValidation"
                :currentAddressResidenceDate.sync="
                  registrationIndividuals.owner.value.individual.currentAddress.residingFrom
                "
                :showErrorMessage="showErrorMessage"
              />
            </template>
            <template #additionalUserForm.phoneNumber:after>
              <span class="text-muted">
                <ul class="text-small pl-4 my-1">
                  <li>Must include country code</li>
                </ul>
              </span>
            </template>
          </ValidatedForm>
        </div>
      </ExpandTransition>

      <div class="buttons-holder px-0 py-1 my-3 text-center">
        <VButton
          @click="addSecondaryOwnerObject"
          v-if="!registrationIndividuals.secondaryOwner.value"
          class="btn-stroked"
          blurOnClick
        >
          Add a Secondary Authorised Signatory
        </VButton>
      </div>
      <ExpandTransition>
        <div v-if="registrationIndividuals.secondaryOwner.value" class="mt-3 card-block">
          <div class="d-flex justify-content-between mb-4">
            <h3 class="account-ownership-heading my-0">Secondary Authorised Signatory</h3>
            <RemoveButton :removeButtonText="'Remove this Signatory'" @remove="removeSecondaryOwner" />
          </div>
          <ValidatedForm
            :fm="registrationIndividuals.secondaryOwner.value.form"
            :validation.sync="registrationIndividuals.secondaryOwner.value.validation"
            @form-event="onUserFormEvent($event, registrationIndividuals.secondaryOwner.value)"
          >
            <template #additionalUserForm.email:before>
              <h3 class="account-ownership-heading">Current Address</h3>
              <RegistrationAddressForm
                :address.sync="registrationIndividuals.secondaryOwner.value.individual.currentAddress"
                :validation.sync="secondaryOwnerAddressValidation"
                :isCountryEditable="true"
              />
              <PreviousAddressesForms
                v-if="
                  isSecondaryOwnerUKResident && registrationIndividuals.secondaryOwner.value.individual.currentAddress
                "
                :previousAddresses.sync="registrationIndividuals.secondaryOwner.value.individual.previousAddresses"
                :validation.sync="secondaryOwnerPreviousAddressesValidation"
                :currentAddressResidenceDate.sync="
                  registrationIndividuals.secondaryOwner.value.individual.currentAddress.residingFrom
                "
                :showErrorMessage="showErrorMessage"
                :isSecondarySignatoryLabel="true"
              />
            </template>
            <template #additionalUserForm.phoneNumber:after>
              <span class="text-muted">
                <ul class="text-small pl-4 my-1">
                  <li>Must include country code</li>
                </ul>
              </span>
            </template>
          </ValidatedForm>
        </div>
      </ExpandTransition>
    </div>

    <div class="proposed-wrapper">
      <h2>Proposed Additional Users</h2>
      <div>
        <p class="text-secondary">
          Please note that additional users will not be sent registration links until the approved conclusion of your
          company’s registration process. Only three proposed additional users can be created using this form. To add
          more users please contact the ALT 21 support team.
        </p>

        <LoadingOverlay
          :state="requestManager.requestStates.loadRole"
          show-retry
          @retry="registrationIndividuals.loadInformation"
        >
          <div
            v-for="formObj in registrationIndividuals.additionalUsers.value"
            class="user-holder"
            :key="`individual-${formObj.individual.id}`"
          >
            <div class="card-block my-3">
              <div class="d-flex justify-content-between mb-4">
                <h3 class="account-ownership-heading my-0">Additional User</h3>
                <RemoveButton
                  :removeButtonText="'Remove this User'"
                  @remove="formObj.individual.id && registrationIndividuals.removeEntry(formObj.individual.id)"
                />
              </div>
              <ValidatedForm
                :fm="formObj.form"
                :validation.sync="formObj.validation"
                @form-event="onUserFormEvent($event, formObj)"
              >
                <template #additionalUserForm.phoneNumber:after>
                  <span class="text-muted">
                    <ul class="text-small pl-4 my-1">
                      <li>Must include country code</li>
                    </ul>
                  </span>
                </template>
              </ValidatedForm>

              <ProposedUserPermissionsForm
                :individual="formObj.individual"
                :authorities="registrationIndividuals.authorities.value"
                @update:individual="registrationIndividuals.updateEntry($event, formObj)"
              />
            </div>
          </div>

          <div class="buttons-holder px-0 text-center">
            <VButton
              @click="registrationIndividuals.addEntry"
              :disabled="registrationIndividuals.maxUsersAllowed.value"
              class="btn-stroked"
              blurOnClick
            >
              Add another User
            </VButton>

            <div v-if="registrationIndividuals.maxUsersAllowed.value" class="info-text text-muted text-small mt-2">
              *Only three proposed additional users can be created using this form. To add more users please contact
              {{ partnerName ? `the ${partnerName}` : 'our' }} support team.
            </div>
          </div>
        </LoadingOverlay>
      </div>
    </div>

    <div class="buttons-holder">
      <VButton @click="submit" :loading="requestManager.anyPending" :disabled="areFormsInvalid"> Continue </VButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.close-button {
  position: absolute;
  font-size: 0.8em;
  top: 0.8em;
  right: 1em;
  z-index: 2;
  ::v-deep path {
    fill: getColor($color-primary);
  }
  cursor: pointer;
}

.proposed-wrapper {
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid;
  @include themedBorderColor($color-primary, $color-dark-primary);
}

.buttons-holder {
  margin: 2rem 0 5rem;
  padding: 0 10%;
  .btn {
    width: 100%;
  }
}

.account-ownership-heading {
  font-size: $base-font-size;
  font-weight: $font-weight-semibold;
  @include themedTextColor($color-primary);
}
</style>
