<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { BaseFormModel, FormValidation } from '../interfaces';
import { getState } from '../helpers';
import { FormEvent } from '../interfaces';

@Component
export default class BaseFormElement<T = any> extends Vue {
  @Prop({ required: true }) field!: FormValidation<T> & { [key: string]: boolean };

  @Prop({ required: true }) model!: BaseFormModel;

  get fieldType() {
    return getState(this.model, 'fieldType');
  }

  /**
   * Returns matching slot name to file path, if one exists
   * If defaultToExact is true, defaults to exact path name (set to false for v-if checks)
   *
   * Allows wildcards in path:
   *    form.model.*:before will match form.model.foo:before and form.model.bar:before
   */
  getSlotName(name?: string, defaultToExact = true) {
    // Check for path using * as wild
    const pathSegments = this.model.$path.split('.');
    const found = Object.keys(this.$scopedSlots).find((k: string) => {
      const [slotParts, slotInnerSlotName] = k.split(':');
      const slotSegments = slotParts.split('.');

      if (slotSegments.length !== pathSegments.length || name !== slotInnerSlotName) {
        return false;
      }
      for (let i = 0; i < slotSegments.length; i++) {
        if (slotSegments[i] !== '*' && pathSegments[i] !== slotSegments[i]) {
          return false;
        }
      }
      return true;
    });

    if (found) {
      return found;
    }

    // Check for exact match, return if found
    const exactName = `${this.model.$path}${name ? ':' + name : ''}`;
    if (this.$scopedSlots[exactName] || defaultToExact) {
      return exactName;
    }
  }

  emitFormEvent<T = any>(event: FormEvent<T>) {
    this.$emit('form-event', event);
  }
}
</script>
