import { TimeFrames } from '../../models/common/timeframes';
import { AmountType } from '../../models/amountType';
import { HedgingInstruments } from './hedgingInstrument';
import { TimeFrameDate } from 'ah-trades/src/models/date';
import { OptionType } from '../currency';

export interface AmendQuotePriceRequest {
  hedgingProduct: string;
  originalPriceId: string;
  clientRate: number;
  clientRateMarkup?: number;
}

export interface CreateVanillaOptionsPriceRequest {
  clientId?: string;
  sellCurrency: string;
  buyCurrency: string;
  amount: number;
  amountType: AmountType;
  settlementDate?: string;
  strikeRate?: number;
}

export interface SpotQuotePriceRequest {
  clientId: string;
  sellCurrency: string;
  buyCurrency: string;
  amount: number;
  amountType: AmountType;
  clientRate?: number;
  clientRateMarkup?: number;
  targetTimeFrame: TimeFrames;
  targetDate?: string;
}

export interface ForwardQuotePriceRequest extends SpotQuotePriceRequest {
  targetDate: string;
  targetTimeFrame: TimeFrames;
}

export interface WindowForwardQuotePriceRequest extends ForwardQuotePriceRequest {
  windowStartDate: string;
}

export interface OptionsCurrencyData {
  selectedStrikeRate: number;
  optionType: OptionType;
  forwardPoints: number;
  forwardRate: number;
  spotRate: number;
  lpPremium: number;
  markupPremium: number;
  creditPremium: number;
  totalPremium: number;
  totalGbpPremium: number;
  premium: number;
  premiumPercentage: number;
  defaultStrikeRates: number[];
  startStrike: number;
  minStrikeInStrikeRange: number;
  maxStrikeInStrikeRange: number;
}

export interface PricingCurrency {
  currency: string;
  amountType: AmountType;
  isFixedSide: boolean;
  isPrimaryRate: boolean;
  currencyDecimalPlaces: number;
  lpHedgeAmount: number;
  coreAmount: number;
  clientAmount: number;
  productCoreRate: number;
  coreFwdRate: number;
  lpClearingFxRate: number;
  lpFwdRate: number;
  productLpRate?: number;
  clientRate: number;
  toGbpSpotRate: number;
  initialMarginAmount: number;
  // partner only info
  profitData?: PartnerProfitPriceData;
  rate?: number;
  optionsCurrencyData: OptionsCurrencyData;
  clientCost: number;
  clientCostBasisPoints: number;
}

export interface PartnerProfitPriceData {
  partnerProfit: PartnerProfitValues;
  ahProfit?: PartnerProfitValues;
  totalProfit?: number;
  gbpPartnerProfit: PartnerProfitValues;
  internalMarkupAmount?: number;
  lpInternalMarkupInvoiceAmount?: number;
  partnerInitialMarginCreditChargeAmount?: number;
  ahInitialMarginCreditChargeAmount?: number;
  totalInitialMarginCreditChargeAmount?: number;
  totalSwapPnl?: number;
  ahSwapPnl?: PartnerProfitValues;
  partnerSwapPnl: PartnerProfitValues;
}

export interface PartnerProfitValues {
  noInitialMarginValue: number;
  collateralValue: number;
  creditValue: number;
}

export interface VanillaPriceResponse {
  id: string;
  hedgingProduct: HedgingInstruments;
  clientId: string;
  ccy1: PricingCurrency;
  ccy2: PricingCurrency;
  currencyPair: string;
  ahMarkupBasisPoints: PartnerProfitValues;
  partnerMarkupBasisPoints?: PartnerProfitValues;
  totalMarkupBasisPoints?: number;
  profitSharingModel: string;
  partnerCommissionPercentage: PartnerProfitValues;
  internalMarkupBasisPoints: number;
  lpInternalMarkupBasisPoints: number;
  homeCurrencyInitialMarginAmount: number;
  gbpInitialMarginAmount: number;
  homeCurrency: string;
  initialMarginCreditChargeRateBasisPoints: number;
  initialMarginPercentage: number;
  settlementDate: string;
  priceRequestedTimestamp: string;
  priceExpirationTimestamp: string;
  expiryDate: string;
  expiryTime: string;
  premiumDate: string;
  premiumCurrency?: string;
}

export interface QuotePriceResponse {
  id: string;
  clientId: string;
  ccy1: PricingCurrency;
  ccy2: PricingCurrency;
  hedgingProduct: HedgingInstruments;
  totalMarkupBasisPoints?: number;
  partnerMarkupBasisPoints?: PartnerProfitValues;
  homeCurrency: string;
  homeCurrencyInitialMarginAmount: number;
  gbpInitialMarginAmount: number;
  initialMarginPercentage: number;
  settlementDate: string;
  priceRequestedTimestamp: string;
  priceExpirationTimestamp: string;
  partnerCommissionPercentage: PartnerProfitValues;
}

export interface DrawdownQuotePriceResponse extends QuotePriceResponse {
  parentTradeID?: string;
  drawdownPnlCurrency?: string;
  clientDrawdownPnl?: number;
  partnerDrawdownPnl?: PartnerProfitValues;
  drawdownRatio?: number;
}

export interface DrawdownDetails {
  amountType: AmountType;
  amount: number;
  buyCurrency: string;
  sellCurrency: string;
  timeFrame?: TimeFrameDate;
}

export function isPriceExpired(price: QuotePriceResponse, offsetMillis = 0) {
  return (
    !price.priceExpirationTimestamp ||
    new Date(price.priceExpirationTimestamp).valueOf() + offsetMillis < new Date().valueOf()
  );
}
