import { submitForm, updateModel } from 'ah-common-lib/src/form/helpers';
import {
  OnboardingIndividualInfo,
  IndividualType,
  clearIndividual,
  Authority,
  Permission,
  EntityTypeAddress,
  CurrentAddressHistoryItem,
  AddressHistoryItem,
  getAddressHistoryError,
} from 'ah-api-gateways';
import { FormDefinition, FormModel, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { generateUUID } from 'ah-common-lib/src/helpers/uuid';
import { Ref, computed, onBeforeMount, ref, watch } from 'vue';
import { HttpError, RequestManager } from 'ah-requests';
import { getServices } from '@/app/services';
import { useAuthStore } from '@/app/store/authStore';
import { catchError, defaultIfEmpty, mergeMap, tap } from 'rxjs/operators';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { cloneDeep, isEqual } from 'lodash';
import { useToast } from 'ah-common-lib/src/toast';

export type AdditionalUserFormObj = FormDefinition & {
  individual: OnboardingIndividualInfo;
};

const MAX_USERS_ALLOWED = 3;

/**
 * Manage and persist an individual list in an onboarding context
 *
 * Given a ref representing the individuals list, this will produce a managed list of individuals and related forms
 */
export function useRegistrationIndividuals(opts: {
  requestManager: RequestManager;
  individuals: Ref<OnboardingIndividualInfo[]>;
  userFormFactory: (individual?: Partial<OnboardingIndividualInfo>) => FormModel;
}) {
  const individualEntries = ref<AdditionalUserFormObj[]>([]);

  const authorities = ref<Authority[]>([]);
  const permissions = ref<Permission[]>([]);

  const services = getServices();
  const authStore = useAuthStore();
  const toast = useToast();

  const additionalUsers = computed(() =>
    individualEntries.value.filter(
      (e) => !e.individual.applicant && !e.individual.owner && !e.individual.secondaryOwner
    )
  );

  const owner = computed(() => individualEntries.value.find((e) => e.individual.owner));

  const secondaryOwner = computed(() => individualEntries.value.find((e) => e.individual.secondaryOwner));

  const applicant = computed(() => individualEntries.value.find((e) => e.individual.applicant));

  const isApplicantOwner = computed(() => applicant.value && applicant.value.individual.owner);

  const maxUsersAllowed = computed(() => additionalUsers.value.length >= MAX_USERS_ALLOWED);

  const applicantEmail = computed(() => applicant.value?.individual.email || 'Not set');

  const secondaryOwnerEmail = computed(() => secondaryOwner.value?.individual.email || 'Not set');

  const ownerEmail = computed(() => (owner.value || applicant.value)?.individual.email || 'Not set');

  const validation = computed(() => {
    return {
      $model: individualEntries.value.map((i) => clearIndividual(i.individual)),
      $invalid: !!individualEntries.value?.find(
        (val) => val.validation?.$invalid || !hasValidPermissions(val.individual)
      ),
      $dirty: !!individualEntries.value?.find((val) => val.validation?.$dirty),
    } as FormValidation<OnboardingIndividualInfo[]>;
  });

  function makeAdditionalUser(individual?: Partial<OnboardingIndividualInfo>): AdditionalUserFormObj {
    const out = {
      individual: generateEmptyIndividual(individual),
      form: opts.userFormFactory(individual),
      validation: null,
    };

    updateModel(out.form, out.individual);

    return out;
  }

  /**
   * Validate an individual for permissions
   *
   * For registration purposes, we require an non admin individual to have at least one permission set to true
   */
  function hasValidPermissions(individual: OnboardingIndividualInfo) {
    return (
      individual.type === IndividualType.CLIENT_ADMIN ||
      individual.owner ||
      individual.secondaryOwner ||
      !!individual.proposedPermissions?.find((i) => i.allow === true)
    );
  }

  function isDuplicatedEmail(email: string) {
    return individualEntries.value.filter((ind) => ind.individual.email === email).length > 1;
  }

  function removeEntry(id: string) {
    const index = individualEntries.value.findIndex((i) => i.individual.id === id);
    individualEntries.value.splice(index, 1);
  }

  function addEntry(individual?: Partial<OnboardingIndividualInfo>) {
    individualEntries.value.push(makeAdditionalUser(individual) as any);
  }

  function submitForms(forms = individualEntries.value) {
    let valid = true;
    forms.forEach((f) => {
      if (f.validation) {
        submitForm(f.validation);
        valid = valid && !f.validation.$invalid;
      }
    });
    return valid;
  }

  function generateEmptyIndividual(data?: Partial<OnboardingIndividualInfo>): OnboardingIndividualInfo {
    return {
      id: `tempId-${generateUUID()}`,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      owner: false,
      secondaryOwner: false,
      applicant: false,
      type: IndividualType.CLIENT_INDIVIDUAL,
      proposedPermissions: [...permissions.value],
      ...data,
    };
  }

  function updateEntry(newIndividual: Partial<OnboardingIndividualInfo>, formObj: AdditionalUserFormObj) {
    formObj.individual = { ...formObj.individual, ...newIndividual };
  }

  function loadInformation() {
    opts.requestManager.cancelAndNew('loadRole', services.authz.getPublicAuthorities()).subscribe((auths) => {
      authorities.value = auths ?? [];
      permissions.value = auths.map((a) => ({ authority: a.id, allow: false }));
    });
  }

  function cleanupForSave(individual: OnboardingIndividualInfo) {
    const out = {
      ...individual,
      proposedPermissions: individual.type === IndividualType.CLIENT_ADMIN ? [] : individual.proposedPermissions,
    };

    if (out.currentAddress) {
      (out as any).address = out.currentAddress;
    }
    delete out.currentAddress;
    delete out.previousAddresses;

    if (out.id?.startsWith('tempId')) {
      delete out.id;
    }

    return out;
  }

  function saveRequest(individual: OnboardingIndividualInfo) {
    const clientId = authStore.loggedInIdentity!.client!.id;
    const request = individual.id?.startsWith('tempId')
      ? services.registration.proposeUser(clientId, cleanupForSave(individual)).pipe(
          tap((r) => {
            const entry = individualEntries.value.find((i) => i.individual.email === individual.email);
            if (entry) {
              entry.individual.id = r.id;
            }
          })
        )
      : services.registration.editProposedUser(individual.id!, clientId, cleanupForSave(individual));

    return request.pipe(
      mergeMap((r) => {
        if (individual.currentAddress) {
          return services.compliance.createAddressHistory({
            entityId: r.id,
            type: EntityTypeAddress.INDIVIDUAL,
            currentAddress: individual.currentAddress as CurrentAddressHistoryItem,
            previousAddresses: individual.previousAddresses as AddressHistoryItem[],
          });
        }
        return of(null);
      }),
      catchError((error: HttpError) => {
        const errorMessage = getAddressHistoryError(error);

        if (errorMessage) {
          toast.error(errorMessage);
        }

        return throwError(error);
      })
    );
  }

  /**
   * Save all entries that have been updated
   */
  function saveEntries() {
    const clientId = authStore.loggedInIdentity!.client!.id;

    const deleteRequests: Observable<any>[] = [];
    const editRequests: Observable<any>[] = [];

    let requestChain: Observable<any> = of(null);

    function checkRequest(entry: OnboardingIndividualInfo) {
      const oldEntry = opts.individuals.value.find((i) => i.id === entry.id);
      if (!oldEntry || !isEqual(entry, clearIndividual(oldEntry))) {
        return saveRequest(entry);
      }
    }

    const ownerRequest = checkRequest(clearIndividual(owner.value!.individual));
    if (ownerRequest) {
      requestChain = requestChain.pipe(mergeMap(() => ownerRequest));
    }

    if (secondaryOwner.value) {
      const secondaryOwnerRequest = checkRequest(clearIndividual(secondaryOwner.value.individual));
      if (secondaryOwnerRequest) {
        requestChain = requestChain.pipe(mergeMap(() => secondaryOwnerRequest));
      }
    }

    individualEntries.value
      .map((i) => clearIndividual(i.individual))
      .filter((i) => !i.owner && !i.secondaryOwner)
      .forEach((ind) => {
        const req = checkRequest(ind);
        if (req) {
          editRequests.push(req);
        }
      });

    opts.individuals.value.forEach((i) => {
      const current = individualEntries.value.find((e) => e.individual.id === i.id);
      if (!current) {
        deleteRequests.push(services.registration.deleteProposedUser(i.id!, clientId));
      }
    });

    return opts.requestManager.currentOrNew(
      'submitIndividuals',
      // Save owner (applicant or new owner) first
      requestChain.pipe(
        // Delete any entries removed after
        mergeMap(() => forkJoin(deleteRequests).pipe(defaultIfEmpty([] as any[]))),
        // Save all other entries (applicant included, if not owner)
        mergeMap(() => forkJoin(editRequests).pipe(defaultIfEmpty([] as any[])))
      )
    );
  }

  function resetEntries() {
    const out: AdditionalUserFormObj[] = [];
    if (!!opts.individuals.value.length) {
      opts.individuals.value.forEach((i) => {
        const formObj = individualEntries.value.find(
          (obj) =>
            (obj.individual.id && obj.individual.id === i.id) ||
            (obj.individual.email && obj.individual.email === i.email)
        );
        if (formObj) {
          formObj.individual = cloneDeep(i);
          updateModel(formObj.form, formObj.individual);
        }
        out.push(formObj ?? makeAdditionalUser(i));
      });
    }
    individualEntries.value = out as any;
  }

  /**
   * The onboarding model will either have a loaded list of individuals, or information on an applicant (if coming from a previous step)
   * If no list of individuals is available, applicant is the owner by default
   */
  watch(opts.individuals, resetEntries, { immediate: true });

  onBeforeMount(loadInformation);

  return {
    individualEntries,
    additionalUsers,
    owner,
    secondaryOwner,
    applicant,
    authorities,
    permissions,
    applicantEmail,
    secondaryOwnerEmail,
    ownerEmail,
    isApplicantOwner,
    maxUsersAllowed,
    validation,
    resetEntries,
    submitForms,
    loadInformation,
    makeAdditionalUser,
    isDuplicatedEmail,
    removeEntry,
    addEntry,
    updateEntry,
    saveEntries,
  };
}
