import { AmountType } from '../amountType';
import { TradeState } from './tradeState';
import { TradeCurrencyData } from '../currency';
import { HedgingInstruments } from '../quote/hedgingInstrument';
import { DrawdownQuotePriceResponse } from '../quote/pricing';
import { QuotePriceResponse, PricingCurrency } from '../quote';
import { VersionedObject } from '../rest';
import { TradeStatus, BHTradeStatus } from './tradeStatus';
import { FundingType } from '../collateral';
import { FeeChargeType } from 'ah-api-gateways/models/feesAndCharges/fundingCharge';
import { LiquidityProviderType } from '../liquidityProviders';

/**
 * Base model for trade (visible by all admins)
 */
export interface AdminTrade extends PartnerTrade {
  totalMarkupBasisPoints: number;
  deltaDealId: string;
  notionalAmount: number;
  notionalCurrency: string;
  expiryDate: string;
  strikeRate: number;
}

/**
 * Base model for trade (visible by all users)
 */
export interface Trade extends VersionedObject {
  id: string;
  parentTradeId?: string;
  amendedTradeId?: string;
  partnerId?: string;
  clientId: string;
  assigneeId: string;
  beneficiaryId?: string;
  priceId: string;
  referenceNumber: string;
  hedgingProduct: HedgingInstruments;
  /**
   * @deprecated in favour of `status`
   */
  state: TradeState;
  status: TradeStatus;
  bankDirectTradeStatus: BHTradeStatus;
  clientName: string;
  partnerName?: string;
  assigneeName: string;
  tradeDirection?: string;
  syncPendingFunds?: boolean;
  partnerMarkupBasisPoints: number;
  partnerCommissionPercentage?: number;
  initialMarginPercentage: number;
  gbpAmount: number;
  gbpInitialMarginAmount: number;
  gbpPartnerProfit: number;
  gbpPartnerSwapPnl: number;
  gbpPartnerDrawdownPnl: number;
  partnerDrawdownPnl: number;
  drawdownPnlCurrency: string;
  gbpVmm?: number;
  ccy1: TradeCurrencyData;
  ccy2: TradeCurrencyData;
  reference?: string;
  originalSettlementDate: string;

  initialMarginFundingType: FundingType;
  initialMarginCollateralFundingAmount: number;
  initialMarginCreditFundingAmount: number;
  initialMarginFundingCurrency: string;

  isAmendable: boolean;
  isCancellable: boolean;
  isDrawdownEligible: boolean;
  isRollForwardable: boolean;
  hasDrawdowns?: boolean;
  isDrawdown?: boolean;
  isFullyDrawdown?: boolean;

  windowStartDate: string;
  settlementDate?: string;
  commissionDate?: string;

  createdBy: string;
  createdByName?: string;
  createdByIndividualId?: string;
  createdAt: string;
  updatedAt: string;

  oboCreatedBy?: string;
  oboCreatedByName?: string;
  oboCreatedByEmail?: string;
  oboCreatedByIndividualId?: string;
  claimType?: string;
  isPremiumPaid?: boolean;
  premiumAmount?: number;
  premiumCurrency?: string;
  premiumDate?: string;
  spotRef?: number;

  liquidityProvider?: LiquidityProviderType;

  // FIXME: this is not yet implemented by the API
  executedBy: string;
}

/**
 * Partner model for trade (visible only by partners)
 */
export interface PartnerTrade extends Trade {
  /**
   * Partner commission in percentage points (values between 0-1)
   */
  partnerCommissionPercentage: number;
  buyCurrency: string;
  clientRate: number;
  commissionsDate: string;
  currencyPair: string;
  currencyPairDirection: string;
  executedByName: string;
  gbpPartnerCommissions: number;
  gbpPartnerInitialMarginCreditChargeAmount: number;
  gbpPartnerNetCommission: number;
  gbpPartnerProfit: number;
  homeCurrency: string;
  initialMarginCreditChargeAmountCurrency: string;
  lpClearingFxRate: number;
  sellAmount: number;
  buyAmount: number;

  partnerInitialMarginCreditChargeAmount: number;
  partnerProfit: number;
  partnerSwapPnl: number;
  profitCurrency: string;
  purposeType: string;

  remainingBuyAmount: number;
  remainingGbpPartnerCommissions: number;
  remainingGbpPartnerDrawdownPnl: number;
  remainingGbpPartnerInitialMarginCreditChargeAmount: number;
  remainingGbpPartnerNetCommission: number;
  remainingGbpPartnerProfit: number;
  remainingGbpPartnerSwapPnl: number;
  remainingPartnerDrawdownPnl: number;
  remainingPartnerInitialMarginCreditChargeAmount: number;
  remainingPartnerProfit: number;
  remainingPartnerSwapPnl: number;
  remainingSellAmount: number;

  executionMethod: TradeExecutionMethod;
  sellCurrency: string;
  swapPnlCurrency: string;
  tradeId: string;
  composedReferenceNumber: string;
}

export enum TradeExecutionMethod {
  CLIENT_DIRECT = 'CLIENT_DIRECT',
  PARTNER_OBO = 'PARTNER_OBO',
  TRADER_OBO = 'TRADER_OBO',
}

export const tradeExecutionMethodLabels: Record<TradeExecutionMethod, string> = {
  [TradeExecutionMethod.CLIENT_DIRECT]: 'Directly by Client',
  [TradeExecutionMethod.PARTNER_OBO]: 'By Partner through On-Behalf-Of',
  [TradeExecutionMethod.TRADER_OBO]: 'By AH Trader through On-Behalf-Of',
};

/**
 * Partner model for trade (visible only by partners)
 */
export interface AdminTrade extends PartnerTrade {
  gbpAhProfit: number;
  gbpAhSwapPnl: number;
  gbpAhDrawdownPnl: number;
}

export interface CreateTradeRequest extends Partial<TradeFundingDetails> {
  priceId: string;
  syncPendingFunds?: boolean;
  beneficiaryId?: string;
  hedgingProduct: HedgingInstruments;
  reference?: string;
  chargeType?: string;
  description?: string;
  premiumCurrency?: string;
}

export enum IncurCostsType {
  AH = 'AH',
  PARTNER = 'PARTNER',
  CLIENT = 'CLIENT',
}

export const incurCostsTypeLabels: Record<IncurCostsType, string> = {
  [IncurCostsType.AH]: 'ALT 21',
  [IncurCostsType.PARTNER]: 'Partner',
  [IncurCostsType.CLIENT]: 'Client',
};

export interface TradeFundingDetails {
  initialMarginFundingType: FundingType;
  initialMarginCollateralFundingAmount?: number;
  initialMarginCreditFundingAmount?: number;
  initialMarginFundingCurrency: string;
  valid?: boolean;
}

export interface CancelTrade {
  lpCancelPnlAmount: number;
  cancelFeeAmount: number;
  cancelFeeGbpAmount: number;
  currency: string;
}

export interface RollForwardTrade {
  lpRollForwardPnlAmount: number;
  rollForwardFeeAmount: number;
  rollForwardFeeGbpAmount: number;
  currency: string;
}

export interface DrawdownTradeRequest {
  priceId: string;
  beneficiaryId?: string;
  chargeType?: FeeChargeType;
  description?: string;
  reference?: string;
}

export interface CancelAdminTrade {
  cancelPnl: number;
  cancelCurrency: string;
}

type TradeOrQuoteCCY<T extends Trade | QuotePriceResponse | DrawdownQuotePriceResponse> = T extends Trade
  ? TradeCurrencyData
  : PricingCurrency;

export function getCcyByAmountType<T extends Trade | QuotePriceResponse | DrawdownQuotePriceResponse>(
  trade: T,
  side: AmountType
): TradeOrQuoteCCY<T> {
  return (trade.ccy1.amountType === side ? trade.ccy1 : trade.ccy2) as TradeOrQuoteCCY<T>;
}

export function getNonFixedSideCcy<T extends Trade | QuotePriceResponse | DrawdownQuotePriceResponse>(
  trade: T
): TradeOrQuoteCCY<T> {
  return (trade.ccy1.isFixedSide ? trade.ccy2 : trade.ccy1) as TradeOrQuoteCCY<T>;
}

export function getFixedSideCcy<T extends Trade | QuotePriceResponse | DrawdownQuotePriceResponse>(
  trade: T
): TradeOrQuoteCCY<T> {
  return (trade.ccy1.isFixedSide ? trade.ccy1 : trade.ccy2) as TradeOrQuoteCCY<T>;
}

export function getPrimaryCcy<T extends Trade | QuotePriceResponse | DrawdownQuotePriceResponse>(
  trade: T
): TradeOrQuoteCCY<T> {
  return (trade.ccy1.isPrimaryRate ? trade.ccy1 : trade.ccy2) as TradeOrQuoteCCY<T>;
}

export function getCurrencyPair(trade: Trade | QuotePriceResponse | DrawdownQuotePriceResponse) {
  return `${getPrimaryCcy(trade).currency}${getSecondaryCcy(trade).currency}`;
}

export function getSecondaryCcy<T extends Trade | QuotePriceResponse | DrawdownQuotePriceResponse>(
  trade: T
): TradeOrQuoteCCY<T> {
  return (!trade.ccy1.isPrimaryRate ? trade.ccy1 : trade.ccy2) as TradeOrQuoteCCY<T>;
}

export function getCcyByCurrency<T extends Trade | QuotePriceResponse | DrawdownQuotePriceResponse>(
  trade: T,
  currency: string
): TradeOrQuoteCCY<T> {
  return (trade.ccy1.currency === currency ? trade.ccy1 : trade.ccy2) as TradeOrQuoteCCY<T>;
}

export function getBuyCcy<T extends Trade | QuotePriceResponse | DrawdownQuotePriceResponse>(
  trade: T
): TradeOrQuoteCCY<T> {
  return getCcyByAmountType(trade, AmountType.BUY) as TradeOrQuoteCCY<T>;
}

export function getSellCcy<T extends Trade | QuotePriceResponse | DrawdownQuotePriceResponse>(
  trade: T
): TradeOrQuoteCCY<T> {
  return getCcyByAmountType(trade, AmountType.SELL) as TradeOrQuoteCCY<T>;
}

export enum LedgerState {
  REQUESTED = 'REQUESTED',
  REQUEST_ERROR = 'REQUEST_ERROR',
  REGISTERED = 'REGISTERED',
  CONFIRMED = 'CONFIRMED',
  FAILED = 'FAILED',
}

export enum WalletUpdateState {
  PENDING = 'PENDING',
  UPDATED = 'UPDATED',
}

export enum CollateralTransactionState {
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export enum CollateralTransactionType {
  POST_COLLATERAL = 'POST_COLLATERAL',
  WITHDRAW_COLLATERAL = 'WITHDRAW_COLLATERAL',
}

export interface CollateralTransaction {
  id: string;
  partnerId: string;
  clientId: string;
  walletId: string;
  trackingId: string;
  state: CollateralTransactionState;
  ledgerState: LedgerState;
  walletUpdateState: WalletUpdateState;
  amount: number;
  currency: string;
  description: string;
  type: CollateralTransactionType;
  createdAt: string;
  updatedAt: string;
}
