<script setup lang="ts">
import { ref, PropType } from 'vue';
import ListDownloadButton from './ListDownloadButton.vue';
import { PaginatedResponse, PaginatedQuery } from 'ah-api-gateways';
import { RequestState } from 'ah-requests';
import PaginationControls from './PaginationControls.vue';
/**
 * Importing table component as BaseDynamicTable so the <DynamicTable> component in template loads the globally defined component
 * (which will be the app-specific table component)
 */
import BaseDynamicTable, { commonTableProps } from './DynamicTable.vue';

const table = ref<InstanceType<typeof BaseDynamicTable>>();

const props = defineProps({
  data: {
    type: Object as PropType<PaginatedResponse<{ [key: string]: any }> | null>,
    default: null,
  },
  query: {
    type: Object as PropType<PaginatedQuery | null>,
    default: null,
  },
  dataDownloadState: {
    type: String as PropType<RequestState>,
    default: 'idle',
  },
  showExport: {
    type: [Boolean, String],
    default: false,
  },
  showTableFooterTotals: {
    type: [Boolean, String],
    default: true,
  },
  hidePagination: {
    type: [Boolean, String],
    default: false,
  },
  ...commonTableProps,
});

const emit = defineEmits({
  sort: (_model: PaginatedQuery) => true,
});

function setPage(pageNumber: any) {
  emit('sort', {
    ...props.query,
    pageNumber,
  } as PaginatedQuery);
}

defineExpose({ table: table });
</script>
<template>
  <DynamicTable
    class="paginated-table"
    ref="table"
    v-bind="$attrs"
    v-on="$listeners"
    :data="data"
    :primaryKey="primaryKey"
    :dataLoadState="dataLoadState"
    :dataDownloadState="dataDownloadState"
    :itemsLabel="itemsLabel"
    :selectedItems="selectedItems"
    :customSearch="customSearch"
  >
    <template slot="after-table">
      <div class="table-actions clearfix">
        <ListDownloadButton
          v-if="showExport !== false"
          v-bind="$attrs"
          :loading="dataDownloadState === 'pending'"
          class="download-button"
          @download="$emit('download', $event)"
        />
        <PaginationControls
          v-if="hidePagination === false"
          :data="data"
          :showTotals="showTableFooterTotals"
          @page-changed="setPage($event)"
          class="pagination-wrapper mt-2"
        />
      </div>
    </template>
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="scope">
      <slot :name="name" v-bind="scope" />
    </template>
  </DynamicTable>
</template>
<style lang="scss" scoped>
.item-number-label {
  text-align: center;
}

::v-deep {
  .pagination {
    @include phablet {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
</style>
