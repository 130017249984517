<template>
  <span>
    <div tag="li" class="menu-link" :class="{ 'router-link-active': inSettings }" v-if="$mediaQuery.is('smOnly')">
      <!-- Visible only on phablets displays -->
      <IconSettings /> <span class="menu-link-text"> Settings </span>
      <span>
        <span @click="toggleSettings()"><IconChevronDown class="toggle-icon" :class="{ open: settingsOpen }" /></span>
        <ExpandTransition appear>
          <PartnerSettingsSidebarView
            v-if="settingsOpen && isAgent"
            :path="basePath"
            class="router-view"
            name="sidebar"
          />
          <SettingsSidebarView v-if="settingsOpen && !isAgent" :path="basePath" class="router-view" name="sidebar" />
        </ExpandTransition>
      </span>
    </div>

    <RouterLink tag="li" class="menu-link" :to="basePath" v-else>
      <IconSettings />
    </RouterLink>
  </span>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SettingsSidebarView from '@/app/views/settings/SettingsSidebarView.vue';
import PartnerSettingsSidebarView from '@/app/views/admin/settings/PartnerSettingsSidebarView.vue';
import { useAuthStore } from '@/app/store/authStore';

@Component({
  components: {
    SettingsSidebarView,
    PartnerSettingsSidebarView,
  },
})
export default class SideNavSettingsLink extends Vue {
  @Prop({ default: '/settings' }) basePath!: string;

  private settingsOpen: boolean = false;

  get authStore() {
    return useAuthStore();
  }

  get inSettings() {
    return this.$route.fullPath.includes('/settings');
  }

  get isAgent() {
    return this.authStore.isAgent;
  }

  @Watch('$route.fullPath', { immediate: true })
  onRouteChange() {
    this.settingsOpen = this.inSettings;
  }

  toggleSettings() {
    this.settingsOpen = !this.settingsOpen;
  }
}
</script>
<style lang="scss" scoped>
.toggle-icon {
  float: right;
  transition: transform 0.5s;
  font-size: 1.5em;

  &.open {
    transform: rotate(180deg);
  }
}
</style>
