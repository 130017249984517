<script setup lang="ts">
import { computed, reactive, ref, PropType, watch } from 'vue';
import { ClientRiskCollateralProfile } from 'ah-api-gateways';
import ManageCollateralEditor from './ManageCollateralEditor.vue';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useTradeState } from 'ah-trades';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { ManageCollateralCurrenciesState } from '../interfaces';
import ManageCollateralInsufficientFunds from './ManageCollateralInsufficientFunds.vue';
import { ManageCollateralActionType } from '../interfaces';
import MarginCollateralActiveMarginCall from './MarginCollateralActiveMarginCall.vue';

const props = defineProps({
  collateralProfileData: { type: Object as PropType<ClientRiskCollateralProfile>, required: true },
});

const onBehalfOfClient = useOnBehalfOf();

const tradeState = useTradeState();

const requestManager = useRequestManager().manager;

const state = reactive<ManageCollateralCurrenciesState>({
  currencies: [
    { currency: 'EUR', model: null as any, validation: null as any },
    { currency: 'GBP', model: null as any, validation: null as any },
    { currency: 'USD', model: null as any, validation: null as any },
  ],
  rates: {
    eur: props.collateralProfileData.fromEurConversionRate,
    gbp: props.collateralProfileData.fromGbpConversionRate,
    usd: props.collateralProfileData.fromUsdConversionRate,
  },
});

const actionType = ref<ManageCollateralActionType>('withdraw');

const isPartnerUser = computed(() => !onBehalfOfClient.value && !tradeState.store.useAuthStore().isClientUser);

const ownerId = computed(() => {
  if (onBehalfOfClient.value) {
    return onBehalfOfClient.value.id;
  }

  const loggedInIdentity = tradeState.store.useAuthStore().loggedInIdentity;

  return loggedInIdentity?.client?.id! || loggedInIdentity?.partner?.id!;
});

const notEnoughFunds = computed(() => {
  const totalBalance = state.currencies.reduce((total, currencyObj) => {
    const wallet = tradeState.store
      .useWalletsStore()
      .getWallet(currencyObj.currency, { isPartner: isPartnerUser.value, id: ownerId.value });

    return wallet?.id ? total + wallet.availableBalance : total;
  }, 0);

  return totalBalance === 0;
});

const disablePostCollateral = computed(() => notEnoughFunds.value && actionType.value !== 'withdraw');

const isCreditProfileUtilisationExceeded = computed(() => {
  return props.collateralProfileData.creditUsage > props.collateralProfileData.maxPostedCollateralWithdrawable;
});

function loadClientRiskProfileReport() {
  // FeatureFlag.VARIATION_MARGIN_AND_LIMITS_FEATURE is assumed to be active, as this component only displays if it is
  if (!ownerId.value) {
    throw 'Cannot load client risk profile report with incomplete data';
  }

  return requestManager.newPromise(
    'loadClientRiskProfileReport',
    tradeState.store.useWalletsStore().loadClientRiskProfile({ clientId: ownerId.value })
  );
}

watch(ownerId, () => ownerId.value && loadClientRiskProfileReport(), { immediate: true });

function loadWalletData() {
  state.currencies.forEach((currencyObj) => {
    requestManager.currentOrNewPromise(`loadWallet-${currencyObj.currency}`, () =>
      tradeState.store.useWalletsStore().loadCurrencyWallet({
        currency: currencyObj.currency,
        force: true,
        owner: { isPartner: isPartnerUser.value, id: ownerId.value },
      })
    );
  });
}

function actionTypeChanged(action: ManageCollateralActionType) {
  actionType.value = action;
  loadWalletData();
}
</script>

<template>
  <div class="manage-collateral-section">
    <MarginCollateralActiveMarginCall
      v-if="collateralProfileData.activeMarginCall"
      :collateralProfileData="collateralProfileData"
    />
    <ManageCollateralInsufficientFunds v-if="disablePostCollateral" />
    <ManageCollateralEditor
      v-on="{ ...$listeners, 'action-changed': actionTypeChanged }"
      :currenciesState="state"
      :isPartnerUser="isPartnerUser"
      :ownerId="ownerId"
      :disablePostCollateral="disablePostCollateral"
      :availableCollateral="collateralProfileData.availableCollateral"
      :activeMarginCall="collateralProfileData.activeMarginCall"
      :isCreditProfileUtilisationExceeded="isCreditProfileUtilisationExceeded"
    />
  </div>
</template>

<style lang="scss" scoped>
.manage-collateral-section {
  padding: 1rem;
}
</style>
