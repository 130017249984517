<script setup lang="ts">
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { computed, inject, reactive, watch } from 'vue';
import { ON_BEHALF_OF_CLIENT_INJECT_KEY } from 'ah-common-lib/src/onBehalfOf/constants';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { Client } from 'ah-api-gateways';
import { useWalletState } from '..';

const walletState = useWalletState();

const requestManager = useRequestManager({
  exposeToParent: ['loadWallet'],
  onRetryFromParentManager: onRetryFromParentManager,
});

const state = reactive<{
  onBehalfOfClient: Client | null;
}>({
  onBehalfOfClient: inject(ON_BEHALF_OF_CLIENT_INJECT_KEY, null),
});

const props = withDefaults(
  defineProps<{
    currency: string;
    showAvailable?: string | boolean;
  }>(),
  {
    showAvailable: false,
  }
);

function onRetryFromParentManager(k: string) {
  if (k === 'loadWallet') {
    loadWallet();
  }
}

const shouldShowAvailable = computed(() => props.showAvailable !== false);

const balance = computed(
  () => (shouldShowAvailable.value ? wallet.value?.availableBalance : wallet.value?.balance) || 0
);

const wallet = computed(() => {
  return walletState.store.useWalletsStore().getWallet(props.currency, {
    isPartner: isPartnerUser.value,
    id: ownerId.value,
  });
});

const walletQuery = computed(() => {
  return {
    currency: props.currency,
    owner: { isPartner: isPartnerUser.value, id: ownerId.value },
  };
});

const isPartnerUser = computed(() => !state.onBehalfOfClient && !walletState.store.useAuthStore().isClientUser);

const ownerId = computed(() => {
  if (state.onBehalfOfClient) {
    return state.onBehalfOfClient.id;
  }
  return (
    walletState.store.useAuthStore().loggedInIdentity?.client?.id ||
    walletState.store.useAuthStore().loggedInIdentity?.partner?.id
  );
});

function loadWallet() {
  requestManager.manager.newPromise(
    'loadWallet',
    walletState.store.useWalletsStore().loadCurrencyWallet(walletQuery.value)
  );
}

watch(
  walletQuery,
  () => {
    loadWallet();
  },
  { immediate: true }
);
</script>

<template>
  <span class="wallet-currency" v-if="!requestManager.manager.anyPending">
    {{ shouldShowAvailable ? 'Available balance' : 'Balance' }}: {{ currency }} {{ formatCurrencyValue(balance) }}
  </span>
</template>
