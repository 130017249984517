<template>
  <div class="simple-centered-page">
    <div
      :class="[
        'main-area',
        {
          narrow: narrow !== false,
          padded: padded !== false,
          'full-width': fullWidth !== false,
          'full-height': fullHeight !== false,
        },
      ]"
    >
      <slot />
    </div>
    <div :class="['footer', footerClass]" v-if="$scopedSlots.footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class SimpleCenteredPage extends Vue {
  @Prop({ default: false }) narrow!: string | boolean;

  @Prop({ default: false }) fullWidth!: string | boolean;

  @Prop({ default: false }) fullHeight!: string | boolean;

  @Prop({ default: false }) padded!: string | boolean;

  @Prop({ default: 'pb-5 pt-3' }) footerClass!: string;
}
</script>
<style lang="scss" scoped>
.simple-centered-page {
  height: 100%;
  padding: $padded-space;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-rows: 1fr min-content;
  grid-template-areas:
    'main'
    'footer';

  > .main-area {
    grid-area: main;
    width: 100%;
    max-width: $wide-block-max-width;

    &.full-width {
      justify-self: stretch;
      max-width: none;
    }

    &.narrow {
      max-width: $narrow-block-max-width;
    }

    &.full-height {
      align-self: stretch;
    }

    &.padded {
      padding-top: 2 * $padded-space;
      padding-bottom: 1 * $padded-space;

      @include hd {
        padding-top: 4 * $padded-space;
        padding-bottom: 1 * $padded-space;
      }
    }
  }

  > .footer {
    width: 100%;
    text-align: center;
    grid-area: footer;
  }
}
</style>
