<template>
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.57983 1.30733C5.91472 0.110896 8.73425 0.838526 10.2238 2.90092L8.69822 2.55343C8.50118 2.50899 8.30723 2.62976 8.26279 2.8268C8.21974 3.01865 8.33601 3.20861 8.52316 3.25875C8.52836 3.26015 8.53096 3.26084 8.53616 3.26224L10.8778 3.79774C11.0697 3.84079 11.2589 3.72713 11.3098 3.53737L11.3167 3.51137C11.3181 3.50618 11.3188 3.50358 11.3202 3.49838L11.3209 3.49578L11.8495 1.18011C11.8939 0.983068 11.7705 0.788422 11.5761 0.744679C11.379 0.700239 11.1851 0.821005 11.1407 1.01805L10.8101 2.47015C10.0733 1.44529 9.03121 0.686854 7.80541 0.291536C6.28745 -0.19878 4.66699 -0.0674139 3.24864 0.661384C1.29761 1.66257 0.0526605 3.64697 0.000111562 5.83943C-0.00408089 6.01105 0.11033 6.15593 0.268894 6.19842C0.297487 6.20608 0.324178 6.21044 0.354861 6.21031C0.554556 6.21367 0.722374 6.05525 0.725734 5.85556C0.773804 3.92935 1.86522 2.1852 3.57983 1.30733Z"
      fill="currentColor"
    />
    <path
      d="M12 6.21157C12.0034 6.01355 11.8449 5.84713 11.6452 5.8438C11.4455 5.84047 11.2777 5.99756 11.2744 6.19558C11.2289 8.10635 10.1349 9.8352 8.42027 10.7057C7.21597 11.3168 5.88382 11.4214 4.6725 11.0996C3.51576 10.7922 2.46679 10.0963 1.74376 9.08092L3.30913 9.35043C3.50636 9.38349 3.69461 9.25393 3.72865 9.05578C3.76269 8.85762 3.63134 8.67353 3.43151 8.63977L1.06491 8.23197C0.867679 8.19891 0.679439 8.32847 0.645397 8.52662L0.234152 10.8734C0.200992 11.058 0.317443 11.2353 0.496802 11.283C0.5072 11.2858 0.517597 11.2885 0.528692 11.2887C0.725924 11.3218 0.914164 11.1922 0.948206 10.9941L1.20025 9.56641C1.93906 10.575 2.97669 11.3231 4.1947 11.713C4.29208 11.7444 4.39016 11.7732 4.48634 11.7988C5.91862 12.1794 7.4214 12.0206 8.75146 11.3463C10.7025 10.3535 11.9467 8.38825 12 6.21157Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
