<script setup lang="ts">
import { VCol, VRow } from 'ah-common-lib/src/common/components';
import { ErrorExclamationIcon } from 'ah-common-lib/src/icons/components';
import FormattedCurrency from '../FormattedCurrency.vue';
import { computed, PropType } from 'vue';
import { ClientRiskCollateralProfile } from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';

// FIXME BoxgridItem used in this component is expected to be setup globally
// Should become an explicit import once BoxGrid family of components is moved to ah-common-lib

const props = defineProps({
  collateralProfileData: { type: Object as PropType<ClientRiskCollateralProfile>, required: true },
});

const {
  currency,
  totalMarkToMarket,
  variationMarginAmount,
  variationMarginAmountRequired,
  variationMarginExcessAmount,
  variationMarginIncrementPercentage: originalVariationMarginIncrementPercentage,
  variationMarginIncrementAmount,
  variationMarginIncrementsRequired,
  variationMarginPercentage: originalVariationMarginPercentage,
} = props.collateralProfileData;

const variationMarginPercentage = computed(() => originalVariationMarginPercentage * 100);

const variationMarginIncrementPercentage = computed(() => originalVariationMarginIncrementPercentage * 100);

const marginCallDataFirstColumn = computed(() => [
  { currency, label: 'Your MTM Position', value: totalMarkToMarket },
  { currency, label: `Your VM Limit (${variationMarginPercentage.value}%)`, value: variationMarginAmount },
  { currency, label: 'VM Limit Deficit', value: variationMarginExcessAmount },
]);
</script>

<template>
  <BoxGridItem cols="12" class="active-margin-call">
    <VRow class="mb-4">
      <VCol cols="1" alignSelf="center" class="text-center">
        <ErrorExclamationIcon class="icon-size" />
      </VCol>
      <VCol cols="11">
        Your current Mark-to-market (MTM) position of all open contracts is currently
        <strong>{{ `${currency} ${formatCurrencyValue(totalMarkToMarket)}` }}</strong
        >, and is in excess of your VM limit of
        <strong>
          {{ `${currency} ${formatCurrencyValue(variationMarginAmount)} (${variationMarginPercentage}%)` }}</strong
        >. A Margin Call has been issued on your account. Please see below for the VM increment required.
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="0" lg="1" md="0" />
      <VCol class="mb-sm-4" lg="4" md="6" sm="12">
        <div class="mb-2 custom-padding-sm" v-for="item in marginCallDataFirstColumn" :key="item.label">
          {{ item.label }}:
          <FormattedCurrency
            class="bold"
            :prefixLabel="item.currency"
            :mainFontSize="18"
            :decimalFontSize="14"
            :value="item.value"
          />
        </div>
      </VCol>
      <VCol class="mb-md-4 mb-sm-4" lg="4" md="6" sm="12">
        <div class="mb-2">
          <div class="with-border">
            VM Amount Required:
            <FormattedCurrency
              class="bold"
              :prefixLabel="currency"
              :mainFontSize="18"
              :decimalFontSize="14"
              :value="variationMarginAmountRequired"
            />
          </div>
        </div>
        <div class="mb-2 custom-padding">
          {{ `VM Increment (${variationMarginIncrementPercentage}%)` }}:
          <FormattedCurrency
            class="bold"
            :prefixLabel="currency"
            :mainFontSize="18"
            :decimalFontSize="14"
            :value="variationMarginIncrementAmount"
          />
        </div>
        <div class="mb-2 custom-padding">
          VM Increment(s) Required: <strong class="font-size">{{ variationMarginIncrementsRequired }}</strong>
        </div>
      </VCol>
      <VCol class="mb-2 custom-padding-sm" lg="3" md="12">
        Please do not hesitate to contact
        <a class="plain-color-link" href="mailto:sales@alt21.com"> sales@alt21.com </a> for any queries regarding Margin
        Call payments.
      </VCol>
    </VRow>
  </BoxGridItem>
</template>

<style lang="scss" scoped>
.bold {
  font-weight: $font-weight-bold;
}

.font-size {
  font-size: 18px;
}

.icon-size {
  width: 28px;
  height: 28px;
}

.custom-padding {
  padding-left: 0.5rem;
}

.with-border {
  display: inline-block;
  border: 2px solid;
  @include themedBorderColor($color-box-border-dark-green);
  border-radius: 5px;
  padding: 0.5rem;
}

.active-margin-call {
  padding: 1em;
  border: 2px solid;
  @include themedBorderColor($color-box-border-dark-red);
  border-radius: 5px;
  margin-bottom: 3em;
}

@include upToResolution($tabletResolution) {
  .custom-padding-sm {
    padding-left: 0.7rem;
  }
}
</style>
