import { render, staticRenderFns } from "./BackButton.vue?vue&type=template&id=361e81e7&scoped=true"
import script from "./BackButton.vue?vue&type=script&lang=ts"
export * from "./BackButton.vue?vue&type=script&lang=ts"
import style0 from "./BackButton.vue?vue&type=style&index=0&id=361e81e7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "361e81e7",
  null
  
)

export default component.exports