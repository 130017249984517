<script lang="ts">
import { PropType, PropOptions } from 'vue';
import { omit } from 'lodash';
import { doughnutChartProps } from './DoughnutChart.vue';

/**
 * Streamlined Doughnut Chart, receiving a list of values and corresponding colors
 */

export const simpleDoughnutChartProps = {
  /**
   * Values in order of presentation
   */
  fractions: { type: Array as PropType<number[]>, required: true },
  /**
   * Color names for each value. Must have the same lenght as props.fractions
   */
  colors: { type: Array as PropType<FilledChartColorOptions[]>, required: true },
  ...omit(doughnutChartProps, ['data', 'options']),
} satisfies Record<string, PropOptions>;
</script>

<script setup lang="ts">
import { FilledChartColorOptions, makeColoredChartDataset, makeDoughnutChartOptions } from 'ah-reports';
import DoughnutChart from './DoughnutChart.vue';
import { computed } from 'vue';

const props = defineProps(simpleDoughnutChartProps);

const doughnutProps = computed(() => {
  const { colors, fractions, ...out } = props;
  return out;
});

const chartOptions = makeDoughnutChartOptions({ cutout: '75%', events: [] });
const chartData = computed(() =>
  makeColoredChartDataset({
    labels: [],
    colors: props.colors,
    /**
     *  If the fractions are all zero then we need a value different than zero so the chart renders
     *  otherwise the chart will not render
     */
    data: props.fractions.some((value) => value !== 0) ? props.fractions : [1],
  })
);
</script>

<template>
  <DoughnutChart :data="chartData" :options="chartOptions" v-bind="doughnutProps">
    <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </DoughnutChart>
</template>
