import { RouterMode } from 'vue-router';
const baseUrl = 'https://dev.api.assurehedge.com/v3.2/';

export function APIGatewayURls(baseUrl: string) {
  return {
    customerAPIGatewayUrl: `${baseUrl}customer-api/`,
    bankingAPIGatewayUrl: `${baseUrl}banking-api/`,
    fxAPIGatewayUrl: `${baseUrl}fx-api/`,
    analyticsUrl: `${baseUrl}analytics-api/`,
    customerAPIGatewayAdminUrl: `${baseUrl}customer-admin-api/`,
    bankingAPIGatewayAdminUrl: `${baseUrl}banking-admin-api/`,
    fxAPIGatewayAdminUrl: `${baseUrl}fx-admin-api/`,
    analyticsAdminUrl: `${baseUrl}analytics-admin-api/`,
    settlementsAdminUrl: `${baseUrl}settlements-admin-api/`,

    // To be deprecated
    baseUrl,
  };
}

/**
 * Base configuration, serving as a default to all environments
 *
 * The configurations should default to their most secure/closed values
 * (typically, this means the defaults relate to the prod environment first)
 *
 * Any deviations from these (hence, any cases where they are less secure)
 * are therefore explicit in the environment config files there they exist
 * (e.g. `showDevTools` is false by default, and explicitly set to true in config.dev/config.sit2)
 */
export const baseConfigKeys = {
  isProduction: process.env.NODE_ENV === 'production',
  routerMode: 'history' as RouterMode,
  staticOTP: false,
  showDevTools: false,
  showProductsBankHedge: false,
  allowMockedFunding: false,
  useAzureAD: true,
  themeColorCSSPrefix: '--ah-color-',
  altReferer: 'https://dev.x-hedge.alt21.com/',
};

export const feFeatureFlags = {
  /**
   * Feature flags
   */
  dobInOnboardingFeatureActive: true,
};

export type FEFeatureFlags = typeof feFeatureFlags;

export const baseConfig = {
  ...baseConfigKeys,
  ...feFeatureFlags,
};

export type AppConfig = typeof baseConfig & ReturnType<typeof APIGatewayURls>;
