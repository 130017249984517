import { Observable, of, from, throwError, timer } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { HttpError } from '..';

export interface WaitOptions {
  /**
   * Total number of attempts to make
   */
  retries: number;
  /**
   * Total number after which a the continue process promise will be trigger (see `continuePromise`)
   */
  continueTrigger: number;
  /**
   * Delay between attempts
   */
  delay: number;
  /**
   * If true, 404s will not throw errors.
   *
   * This is useful when creating entities
   */
  ignoreErrors?: (e: any) => boolean;
  /**
   * Potential restart process promise
   *
   * Triggered when retries run out
   * If successful (promise resolving to `true`) the process will restart
   */
  restartPromise?: () => Promise<boolean>;
  /**
   * Potential continue process promise
   *
   * Triggered when retries reach `continueTrigger`
   * If unsuccessful (promise rejecting or returning `false`) the process will be cancelled with the rejection message
   */
  continuePromise?: () => Promise<boolean>;
}

let optionDefaults: WaitOptions = {
  retries: 15,
  continueTrigger: -1,
  delay: 1500,
};

export function setWaitDefaults(options?: Partial<WaitOptions>) {
  optionDefaults = {
    ...optionDefaults,
    ...options,
  };
}

/**
 * Waits for an entity to change (performig multiple GET requests on that entity), and returns that entity when changed
 */
export function waitForEntityChange<T>(
  requestFactory: () => Observable<T>,
  changeTest: (payload: T) => boolean,
  options?: Partial<WaitOptions>
): Observable<T> {
  return new Observable((sub) => {
    const opts: WaitOptions = {
      ...optionDefaults,
      ...options,
    };

    let totalTries = 0;

    function process(currOpts: WaitOptions): Observable<T> {
      return requestFactory().pipe(
        map((r) => (changeTest(r) ? r : false)),
        catchError((e) => {
          if (currOpts.ignoreErrors && currOpts.ignoreErrors(e)) {
            return of(false);
          }
          return throwError(e);
        }),
        mergeMap((r) => {
          if (typeof r !== 'boolean') {
            return of(r);
          }
          if (currOpts.retries > 0) {
            totalTries = totalTries + 1;
            if (currOpts.continueTrigger === totalTries && currOpts.continuePromise) {
              currOpts.continuePromise().then(
                (result) => {
                  if (!result) {
                    sub.error('Continue denied');
                  }
                },
                (e) => {
                  sub.error(e);
                }
              );
            }

            return timer(currOpts.delay).pipe(
              take(1),
              mergeMap(() => process({ ...currOpts, retries: currOpts.retries - 1 }))
            );
          } else if (currOpts.restartPromise) {
            return from(currOpts.restartPromise()).pipe(
              take(1),
              mergeMap((val) => {
                if (val) {
                  return process(opts);
                }
                return throwError('Restart denied');
              })
            );
          }
          return throwError('Change not detected after all delays expired');
        })
      );
    }

    process(opts).subscribe(sub);
  });
}

/**
 * Waits for an entity to be created (performig multiple GET requests on that entity), and returns that entity when created
 */
export function waitForEntityCreation<T>(
  requestFactory: () => Observable<T>,
  options?: Partial<WaitOptions>
): Observable<T> {
  return waitForEntityChange(requestFactory, () => true, {
    ...options,
    ignoreErrors(e: HttpError) {
      return (options?.ignoreErrors && options?.ignoreErrors(e)) || e.response?.status === 404;
    },
  });
}

/**
 * Waits for an entity to be created (performig multiple GET requests on that entity), and returns that entity when created
 */
export function waitForCQRSEntityChange<T extends { version: number }>(
  entityToCompare: { version: number },
  requestFactory: () => Observable<T>,
  options?: Partial<WaitOptions>
): Observable<T> {
  return waitForEntityChange(requestFactory, (item: T) => item.version >= entityToCompare.version, {
    ...options,
  });
}

/**
 * Waits for an entity to be deleted (performig multiple GET requests on that entity), and true when that happens
 */
export function waitForEntityDeletion<T>(
  requestFactory: () => Observable<T>,
  options?: Partial<WaitOptions>
): Observable<boolean> {
  return waitForEntityChange(
    requestFactory,
    (item) => {
      return (item as any)?.deleted || (item as any).deletedAt;
    },
    {
      ...options,
      ignoreErrors(e: HttpError) {
        // Ignore the 500 error because of CQRS weirdness until we get a 404
        return (options?.ignoreErrors && options?.ignoreErrors(e)) || e.response?.status === 500;
      },
    }
  ).pipe(
    catchError((e) => {
      if ((e as HttpError).response?.status === 404) {
        return of(true);
      }
      throw e;
    }),
    map((i) => {
      if (typeof i !== 'boolean') {
        return false;
      }
      return i;
    })
  );
}
