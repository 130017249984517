<template>
  <div class="otp-form">
    <div class="mb-5">
      <p class="resend-code-text">The security of your account is important to us.</p>
      <p class="resend-code-text" v-html="otpMessage(otpPhone, minutesToExpire ?? undefined)" />
    </div>
    <p v-if="staticOTP" class="error text-center text-secondary">For testing purposes, code is always 123456</p>
    <DynamicForm ref="form" :form="otpFV" @form-event="onFormEvent" />
    <div class="form-actions pt-5 text-center">
      <VButton
        v-if="expiredOtp"
        :loading="requestManager.anyPending"
        label="Rerequest OTP code"
        class="btn-secondary"
        @click="refreshOtp"
      />
      <VButton
        v-else
        :disabled="otpFV.$invalid"
        :loading="requestManager.anyPending"
        label="Verify and login"
        class="btn-success"
        @click="otpContinue"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import config from '@/config';
import { DynamicForm } from 'ah-common-lib/src/form/components';
import useVuelidate, { Validation } from '@vuelidate/core';
import {
  makeFormModel,
  makeFormValidation,
  toDataModel,
  setState,
  getChildModel,
} from 'ah-common-lib/src/form/helpers';
import { otpField } from 'ah-common-lib/src/form/models';
import { FormValidation, FormEvent } from 'ah-common-lib/src/form/interfaces';
import { AuthErrorCodes, SecurityErrorCodes } from 'ah-api-gateways';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { useAuthStore } from '@/app/store/authStore';
import { otpMessage } from 'ah-common-lib/src/helpers/otp';
import { ExpiryTime } from 'ah-api-gateways/models/expiry';

const otpFM = () =>
  makeFormModel({
    name: 'otpForm',
    title: 'OTP',
    fieldType: 'form',
    fields: [otpField('otp', '', { hideErrors: true })],
  });

@Component({
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations: {
    otpForm: makeFormValidation(otpFM()),
  },
})
export default class OtpForm extends Mixins(WithRequestManager) {
  @Prop({ required: true }) otpPhone!: string;

  @Prop({ required: false }) expiry?: ExpiryTime;

  private v$!: Validation;

  minutesToExpire: number | null = null;

  requestManagerConfig = {
    exposeToParent: false,
  };

  private otpForm = otpFM();

  otpMessage = otpMessage;

  expiredOtp = false;

  @Prop({ default: true }) showBackToLogin!: boolean;

  get authStore() {
    return useAuthStore();
  }

  @Watch('expiredOtp')
  onExpiredOtpChange() {
    this.otpForm.otp = [];
    const otpField = getChildModel(this.otpForm, 'otp');
    if (otpField) {
      setState(otpField, 'readonly', this.expiredOtp);
    }
  }

  @Watch('expiry', { immediate: true })
  onExpiryChange() {
    if (this.expiry?.expiresIn) {
      this.minutesToExpire = this.expiry.expiresIn / 60;
    }
  }

  mounted() {
    (this.$refs.form as DynamicForm).triggerFieldAction('otpForm.otp', 'focus');
  }

  otpContinue() {
    if (this.otpFV.$invalid) {
      return;
    }
    this.otp();
  }

  onFormEvent(event: FormEvent) {
    if (event.event === 'form-field-submit') {
      this.otpContinue();
    }
  }

  otp() {
    this.requestManager.currentOrNewPromise('sendOtp', () =>
      this.authStore.otp(toDataModel(this.otpForm).otp).then(
        () => this.onOtpSuccess(),
        (error: any) => {
          if (error.response) {
            if (error.response?.data?.code === AuthErrorCodes.OTP_ATTEMPTS_EXCEEDED) {
              this.$toast.error(error.response?.data?.message);
              this.backToLogin();
            } else if (error.response?.data?.code === SecurityErrorCodes.INVALID_OTP) {
              this.$toast.error('Incorrect access code sent');
            } else if (error.response?.data?.code === SecurityErrorCodes.EXPIRED_OTP) {
              this.$toast.error('Expired OTP access code. Please resend code.');
              this.expiredOtp = true;
            } else {
              this.$toast.error('Oops, there was an error while trying to validate your OTP');
            }
          }
          this.$emit('error', error);
        }
      )
    );
  }

  refreshOtp() {
    this.requestManager.currentOrNew('refreshOtp', this.$services.auth.refreshOtp()).subscribe(
      (res) => {
        // clear otp, would be nice to focus on first input of the form
        this.otpForm.otp = [];
        this.expiredOtp = false;
        this.$toast.success('Two-step authentication code resent');
        if (res?.expiresIn) {
          this.minutesToExpire = res.expiresIn / 60;
        }
      },
      (error) => {
        if (error.response) {
          if (error.response?.data?.code === SecurityErrorCodes.INVALID_OTP) {
            this.$toast.error('Incorrect access code sent');
          } else if (error.response?.data?.code === SecurityErrorCodes.EXPIRED_OTP) {
            this.$toast.error('Expired OTP access code. Please resend code.');
            this.expiredOtp = true;
          } else {
            this.$toast.error('Oops, there was an error while trying to validate your OTP');
          }
        }
      }
    );
  }

  onOtpSuccess() {
    this.$emit('success');
  }

  /**
   * called when it is needed to go back and change the email; emits cancel
   */
  backToLogin() {
    this.$emit('cancel');
  }

  get otpFV(): FormValidation {
    return this.v$.otpForm as FormValidation;
  }

  get staticOTP() {
    return config.staticOTP;
  }
}
</script>
<style lang="scss" scoped>
.otp-form {
  .resend-code-text {
    margin-bottom: 1em;
  }

  .resend-link {
    margin-bottom: math.div($padded-space, 8);
  }

  .resend-code-link {
    display: block;
    margin-bottom: math.div($padded-space, 2);
    margin-top: math.div($padded-space, 2);
  }
}
</style>
