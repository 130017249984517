<script setup lang="ts">
import { computed, onBeforeMount, onBeforeUnmount, reactive, watch } from 'vue';
import { DEFAULT_OTP_EXPIRY_TIME, otpMessage } from 'ah-common-lib/src/helpers/otp';
import { FormDefinition, FormEvent } from 'ah-common-lib/src/form/interfaces';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getChildModel, makeFormModel, setState } from 'ah-common-lib/src/form/helpers';
import { otpField } from 'ah-common-lib/src/form/models';
import { SECOND } from 'ah-common-lib/src/constants/time';
import { MFARequest, MFAResponse, MFAState, MFAType } from 'ah-api-gateways';
import { AuthErrorCodes } from 'ah-requests';
import { getServices } from '@/app/services';
import { tap } from 'rxjs/operators';
import { debounce, DebouncedFunc } from 'lodash';

const emit = defineEmits<{
  (e: 'update:value', value: string): void;
  (e: 'update:status', value: MFAState): void;
}>();

const props = withDefaults(
  defineProps<{
    staticMFA?: boolean | string;
    destination?: string;
    autoRequest?: string | boolean;
    value?: string;
    mfa: MFARequest;
  }>(),
  { staticMFA: false, autoRequest: false }
);

const mfaForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'mfaForm',
    fieldType: 'form',
    fields: [otpField('code', '', { hideErrors: true, fieldWrapperClass: 'col col-12 mb-0' })],
  }),
  validation: null,
});

const state = reactive<{
  mfaResponse?: MFAResponse;
  minutesToExpire: number;
  codeExpiryTimeout: number | null;
  status: MFAState;
  value: string;
}>({
  minutesToExpire: DEFAULT_OTP_EXPIRY_TIME,
  codeExpiryTimeout: null,
  status: MFAState.NOT_REQUESTED,
  value: '',
});

const debouncedSubmit: DebouncedFunc<(value: string) => void> = debounce(submitCode, 300);

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === `refreshMFA-${props.mfa.type}`) {
      refreshMFA();
    }
  },
}).manager;

const services = getServices();

const isEmail = computed(() => props.mfa.type === MFAType.EMAIL_OTP);

const showResendCode = computed(
  () => state.status !== MFAState.SUCCESS && requestManager.requestStates[`submitMFA-${props.mfa.type}`] !== 'pending'
);

function mfaRequest() {
  return services.auth.refreshMfa(props.mfa).pipe(tap(setResponse));
}

function checkMfaRequest(value: string) {
  return services.auth.mfa(value, props.mfa).pipe(tap(setResponse));
}

function setResponse(res: MFAResponse) {
  state.mfaResponse = { ...res };
  setStatus(res.state);
  clearExpiryTimeout();
  // FIXME handling API transition, to be removed after change
  const expiry = (res as any).expiresIn || res.expiresSeconds;
  if (expiry) {
    state.codeExpiryTimeout = window.setTimeout(() => {
      if (state.status !== MFAState.SUCCESS) {
        setStatus(MFAState.EXPIRED);
      }
    }, expiry * SECOND);
    state.minutesToExpire = expiry / 60;
  }
}

function setStatus(status: MFAState) {
  state.status = status;
}

function setError(e: any) {
  if ([AuthErrorCodes.FAILED_MFA_VALUE].includes(e.response?.data?.code)) {
    setStatus(MFAState.INVALID);
  } else {
    setStatus(MFAState.EXPIRED);
  }
}

function submitCode(value: string) {
  requestManager.currentOrNew(`submitMFA-${props.mfa.type}`, checkMfaRequest(value)).subscribe(setResponse, setError);
}

function refreshMFA() {
  if (state.status === MFAState.SUCCESS) {
    return;
  }
  mfaForm.form.code = [];
  requestManager.currentOrNew(`refreshMFA-${props.mfa.type}`, mfaRequest()).subscribe(setResponse, setError);
}

function onFormEvent(event: FormEvent) {
  if (event.event === 'form-field-set-value') {
    state.value = (mfaForm.form.code ?? []).join('');
    emit('update:value', state.value);
  }
}

function clearExpiryTimeout() {
  if (state.codeExpiryTimeout !== null) {
    clearTimeout(state.codeExpiryTimeout);
    state.codeExpiryTimeout = null;
  }
}

watch(
  () => state.status,
  () => {
    setState(getChildModel(mfaForm.form, 'code')!, 'inputClass', `form-control ${state.status.toLowerCase()}`);
    emit('update:status', state.status);
    if ([MFAState.SUCCESS, MFAState.EXPIRED, MFAState.NOT_REQUESTED].includes(state.status)) {
      setState(mfaForm.form, 'readonly', true);
      clearExpiryTimeout();
    } else {
      setState(mfaForm.form, 'readonly', false);
    }
  }
);

watch(
  () => state.value,
  (newValue: string, oldValue: string) => {
    if (newValue !== oldValue && state.value.length >= 6 && props.autoRequest !== false) {
      debouncedSubmit(state.value);
    }
  }
);

watch(
  () => props.value,
  () => (state.value = props.value ?? '')
);

onBeforeMount(refreshMFA);
onBeforeUnmount(() => {
  debouncedSubmit.cancel();
  clearExpiryTimeout();
});

defineExpose({ setStatus, refreshMFA, setError });
</script>

<template>
  <div>
    <div v-if="state.status === MFAState.NOT_REQUESTED">
      <p class="text-center">
        To proceed, you must verify your {{ isEmail ? 'email address' : 'phone number' }}
        <span v-if="destination"> ending in {{ destination }}</span
        >.
      </p>
      <p class="text-center">
        <VButton
          :loading="requestManager.requestStates[`refreshMFA-${props.mfa.type}`] === 'pending'"
          @click="refreshMFA"
        >
          Send {{ isEmail ? 'Email' : 'SMS code' }}
        </VButton>
      </p>
    </div>
    <div v-else-if="requestManager.requestStates[`refreshMFA-${props.mfa.type}`] === 'pending'" class="loading-wrapper">
      <LoadingIcon class="loading-icon" />
    </div>
    <div v-else>
      <p
        class="resend-code-text text-center"
        v-html="otpMessage(destination, state.minutesToExpire ?? undefined, isEmail)"
      />
      <p v-if="staticMFA" class="text-secondary text-center">For testing purposes, code is always 123456</p>
      <ValidatedForm :fm="mfaForm.form" :validation.sync="mfaForm.validation" @form-event="onFormEvent" />
      <p v-if="state.status === MFAState.INVALID || state.status === MFAState.EXPIRED" class="text-error text-center">
        {{ state.status === MFAState.INVALID ? 'Invalid code.' : 'Code expired' }}
        <a href="" @click.prevent="refreshMFA" :disabled="requestManager.requestStates.refreshOtp === 'pending'">
          {{
            requestManager.requestStates[`refreshMFA-${props.mfa.type}`] !== 'pending' ? 'Resend code?' : 'Resending...'
          }}
        </a>
      </p>
      <div class="text-center" v-else-if="showResendCode">
        Didn’t receive the message?
        <a href="#" @click.prevent="refreshMFA">Resend code.</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading-wrapper {
  text-align: center;
  font-size: 3em;
}
</style>
