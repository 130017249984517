<template>
  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.7922 0.666992C12.7846 0.666992 13.5969 1.43796 13.6629 2.41362L13.6672 2.54199V15.457C13.6672 16.4494 12.8962 17.2617 11.9206 17.3277L11.7922 17.332H2.20898C1.2166 17.332 0.40428 16.5611 0.33831 15.5854L0.333984 15.457V2.54199C0.333984 1.54961 1.10495 0.737288 2.08061 0.671318L2.20898 0.666992H11.7922ZM11.7922 1.91699H2.20898C1.89257 1.91699 1.63108 2.15212 1.58969 2.45718L1.58398 2.54199V15.457C1.58398 15.7735 1.81911 16.035 2.12418 16.0763L2.20898 16.082H11.7922C12.1086 16.082 12.3701 15.8469 12.4115 15.5419L12.4172 15.457V2.54199C12.4172 2.22558 12.1821 1.96408 11.877 1.9227L11.7922 1.91699ZM7.20737 9.8306C7.55255 9.8306 7.83237 10.1104 7.83237 10.4556C7.83237 10.772 7.59724 11.0335 7.29218 11.0749L7.20737 11.0806H3.45893C3.11375 11.0806 2.83393 10.8008 2.83393 10.4556C2.83393 10.1392 3.06906 9.87769 3.37412 9.83631L3.45893 9.8306H7.20737ZM10.5423 7.33173C10.8874 7.33173 11.1673 7.61155 11.1673 7.95673C11.1673 8.27314 10.9321 8.53464 10.6271 8.57602L10.5423 8.58173H3.45893C3.11375 8.58173 2.83393 8.3019 2.83393 7.95673C2.83393 7.64031 3.06906 7.37882 3.37412 7.33743L3.45893 7.33173H10.5423ZM10.5423 4.83285C10.8874 4.83285 11.1673 5.11267 11.1673 5.45785C11.1673 5.77427 10.9321 6.03576 10.6271 6.07715L10.5423 6.08285H3.45893C3.11375 6.08285 2.83393 5.80303 2.83393 5.45785C2.83393 5.14144 3.06906 4.87994 3.37412 4.83856L3.45893 4.83285H10.5423Z"
      fill="#465161"
    />
  </svg>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
