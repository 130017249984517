<script setup lang="ts">
import { Wallet } from 'ah-api-gateways';
import { commonCurrencies } from 'ah-common-lib/src/helpers/currency';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useWalletState } from 'ah-wallets';
import { ref } from 'vue';
import { RawLocation } from 'vue-router';
import { useRouter } from 'vue-router/composables';
import AmountDisplay from '@/app/components/wallets/AmountDisplay.vue';

const props = defineProps<{
  redirectTo: RawLocation;
}>();

const router = useRouter();

const onBehalfOfClient = useOnBehalfOf();

const wallets = ref<{ currency: string; label: string; wallet: Wallet | null }[]>([
  {
    currency: 'GBP',
    label: commonCurrencies['GBP'],
    wallet: null,
  },
  {
    currency: 'EUR',
    label: commonCurrencies['EUR'],
    wallet: null,
  },
  {
    currency: 'USD',
    label: commonCurrencies['USD'],
    wallet: null,
  },
]);
function loadWallets() {
  wallets.value.forEach((walletObj) => {
    useWalletState()
      .store.useWalletsStore()
      .loadCurrencyWallet({
        currency: walletObj.currency,
        force: true,
        owner: onBehalfOfClient.value ? { isPartner: false, id: onBehalfOfClient.value.id } : undefined,
      })
      .then((res) => (walletObj.wallet = res));
  });
}
loadWallets();

function goToWallets() {
  router.push(props.redirectTo);
}
</script>

<template>
  <div>
    <AmountDisplay
      v-for="wallet in wallets"
      :currency="wallet.currency"
      :value="wallet.wallet?.availableBalance"
      :label="wallet.label"
      :loading="!wallet.wallet"
      Ja
      :key="wallet.currency"
    />
    <DashboardViewAllButton @click="goToWallets" v-if="redirectTo" />
  </div>
</template>
