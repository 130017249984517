<script lang="ts" setup>
import { Trade, AdminTrade, AmountType } from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { computed } from 'vue';

const props = defineProps<{
  /**
   * Trade focused and currently showing the details off
   *
   */
  trade: Trade | AdminTrade;
}>();

const sellCurrency = computed(() => {
  if (props.trade.ccy1.amountType === AmountType.SELL) {
    return props.trade.ccy1.currency;
  }
  return props.trade.ccy2.currency;
});

const buyCurrency = computed(() => {
  if (props.trade.ccy1.amountType === AmountType.BUY) {
    return props.trade.ccy1.currency;
  }
  return props.trade.ccy2.currency;
});

const sellAmount = computed(() => {
  if (props.trade.ccy1.amountType === AmountType.SELL) {
    return props.trade.ccy1.clientAmount;
  }
  return props.trade.ccy2.clientAmount;
});

const buyAmount = computed(() => {
  if (props.trade.ccy1.amountType === AmountType.BUY) {
    return props.trade.ccy1.clientAmount;
  }
  return props.trade.ccy2.clientAmount;
});

const premiumSettled = computed(() => {
  return props.trade.isPremiumPaid ? 'Yes' : 'No';
});
</script>

<template>
  <div>
    <BoxGrid :alignH="$ahTradesState.mediaQuery.is('smDown') ? 'center' : 'start'">
      <BoxGridItem class="info-block borderless px-sm-2 offset-lg-1" sm="12" md="12" lg="3" align-self="stretch">
        <DataRow class="mb-2" label="Selling" cols="4">
          {{ sellCurrency }} {{ formatCurrencyValue(sellAmount) }}
        </DataRow>
        <DataRow class="mb-2" label="Buying" cols="4"> {{ buyCurrency }} {{ formatCurrencyValue(buyAmount) }} </DataRow>
      </BoxGridItem>
      <BoxGridItem class="info-block borderless px-sm-2" sm="12" md="12" lg="4" align-self="stretch">
        <DataRow class="mb-2" label="Premium Amount" cols="6">
          <div v-if="trade.premiumAmount">
            {{ trade.premiumCurrency + ' ' + formatCurrencyValue(trade.premiumAmount) }}
          </div>
          <div v-else>-</div>
        </DataRow>
        <DataRow class="mb-2" label="Premium Settlement Date" cols="6">
          {{ formatDate(trade.premiumDate, 'MMMM dd, yyyy') }}
        </DataRow>
        <DataRow class="mb-2" label="Premium Settled" cols="6">
          {{ premiumSettled }}
        </DataRow>
      </BoxGridItem>

      <BoxGridItem class="info-block borderless px-sm-2" sm="12" md="12" lg="4" align-self="stretch">
        <DataRow class="mb-2" label="Spot Ref" cols="4"> {{ trade.spotRef ?? '-' }} </DataRow>
        <DataRow class="mb-2" label="Trade Created By" cols="4"> {{ trade.createdByName ?? '-' }} </DataRow>
      </BoxGridItem>
    </BoxGrid>
  </div>
</template>

<style lang="scss" scoped>
.info-block {
  ::v-deep {
    .box-grid-item {
      display: flex;
      flex-direction: column;
      padding-left: 2rem;
    }
  }
  @include upToResolution($tabletResolution) {
    font-size: 0.875rem;
  }

  @include upToResolution($desktopResolution) {
    padding: 1rem 0;
    &:not(.borderless) {
      border-bottom-style: solid;
      border-bottom-width: 1px;
      @include themedPropColor('border-bottom-color', $color-input-border);
    }
  }

  @include desktop {
    padding-left: 2rem;
    &:not(.borderless) {
      border-right-style: solid;
      border-right-width: 1px;
      @include themedPropColor('border-right-color', $color-input-border);
      padding-right: math.div($padded-space, 2);

      @include desktop {
        padding-right: $padded-space;
      }
    }

    &:not(:first-child) {
      padding-left: math.div($padded-space, 2);

      @include desktop {
        padding-left: $padded-space;
      }
    }
  }
}
</style>
