export enum TenorLimits {
  ONE_MONTH = 'ONE_MONTH',
  TWO_MONTHS = 'TWO_MONTHS',
  THREE_MONTHS = 'THREE_MONTHS',
  SIX_MONTHS = 'SIX_MONTHS',
  NINE_MONTHS = 'NINE_MONTHS',
  TWELVE_MONTHS = 'TWELVE_MONTHS',
}

export const tenorLimitsToMonthCount: Record<TenorLimits, number> = {
  [TenorLimits.ONE_MONTH]: 1,
  [TenorLimits.TWO_MONTHS]: 2,
  [TenorLimits.THREE_MONTHS]: 3,
  [TenorLimits.SIX_MONTHS]: 6,
  [TenorLimits.NINE_MONTHS]: 9,
  [TenorLimits.TWELVE_MONTHS]: 12,
};

export const tenorLimitsToDescription: Record<TenorLimits, string> = {
  [TenorLimits.ONE_MONTH]: 'one month',
  [TenorLimits.TWO_MONTHS]: 'two months',
  [TenorLimits.THREE_MONTHS]: 'three months',
  [TenorLimits.SIX_MONTHS]: 'six months',
  [TenorLimits.NINE_MONTHS]: 'nine months',
  [TenorLimits.TWELVE_MONTHS]: 'one year',
};
