<script setup lang="ts">
import OnboardingListing from '@/app/components/onboarding/report/OnboardingListing.vue';
import OnboardingClientsListFilters from '@/app/components/onboarding/report/OnboardingClientsListFilters.vue';
import ListBlock from 'ah-common-lib/src/common/components/listing/ListBlock.vue';
import ListDownloadButton from 'ah-common-lib/src/common/components/listing/ListDownloadButton.vue';
import { ExportListType, PaginatedParams } from 'ah-api-gateways';
import { computed, reactive, ref } from 'vue';
import { clientOnboardingTableFields } from './tableFields/onboardingTableFields';
import { RequestState } from 'ah-requests';

withDefaults(
  defineProps<{
    /**
     * Whether to displayed export
     */
    showExport: boolean | string;
  }>(),
  {
    showExport: true,
  }
);

const onboardingListing = ref<InstanceType<typeof OnboardingListing> | null>(null);

const state = reactive<{
  // Automatically syncronised via .sync
  dataDownloadState: RequestState;
  pageAndSortParams: Partial<PaginatedParams>;
}>({
  dataDownloadState: 'idle',
  pageAndSortParams: { sort: 'name', sortDirection: 'ASC' },
});

const config = computed(() => {
  return {
    tableFields: clientOnboardingTableFields,
  };
});

function downloadList(format: ExportListType) {
  onboardingListing.value?.downloadData(format);
}
</script>

<template>
  <BoxGrid alignH="stretch" alignV="start">
    <BoxGridBlock cols="12">
      <ListBlock>
        <template #filters="{ onFilterChange, filter }">
          <OnboardingClientsListFilters :filters="filter" @update:filters="onFilterChange" />
        </template>
        <template #list="{ filter, setFilter }">
          <OnboardingListing
            ref="onboardingListing"
            :config="config"
            :dataDownloadState.sync="state.dataDownloadState"
            :filter="filter"
            :sortAndPageParams.sync="state.pageAndSortParams"
            :showExport="false"
            @update:filter="setFilter"
            fixed
            v-on="$listeners"
            v-bind="$attrs"
          >
            <template #row-details="row">
              <slot name="row-details" v-bind="row" />
            </template>
          </OnboardingListing>
        </template>
      </ListBlock>
    </BoxGridBlock>

    <BoxGridItem cols="12" v-if="showExport !== false">
      <ListDownloadButton
        :loading="state.dataDownloadState === 'pending'"
        class="download-button"
        @download="downloadList"
      />
    </BoxGridItem>
  </BoxGrid>
</template>
