<template>
  <div :class="['box-grid', { 'stretch-v': bRowAttrs['align-v'] === 'stretch' }]">
    <div class="header" v-if="$scopedSlots.header || title">
      <slot name="header">
        <h2>{{ title }}</h2>
      </slot>
    </div>
    <div class="box-grid-row">
      <VRow v-bind="bRowAttrs">
        <slot />
      </VRow>
    </div>
    <div class="footer" v-if="$scopedSlots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import kebabCase from 'lodash/kebabCase';
import mapKeys from 'lodash/mapKeys';

@Component
export default class BoxGrid extends Vue {
  @Prop({ default: '' }) title!: string;

  get bRowAttrs() {
    return {
      'align-v': 'stretch',
      'align-h': 'center',
      'align-content': 'stretch',
      cols: 12,
      ...mapKeys(this.$attrs, (v, k) => kebabCase(k)),
    };
  }
}
</script>
<style lang="scss" scoped>
.box-grid {
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  grid-template-areas:
    'header'
    'row'
    'footer';

  .header {
    grid-area: header;
  }

  .footer {
    @include responsiveGutter('padding-top');
  }

  .row {
    grid-area: row;
    min-width: 1rem;
  }

  .footer {
    grid-area: footer;
  }

  &.stretch-v {
    height: 100%;
  }
}
</style>
