<script setup lang="ts">
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { computed } from 'vue';

const props = defineProps({
  value: { type: [Number, String], required: true },
  mainFontSize: { type: Number, default: 24 },
  decimalFontSize: { type: Number, default: 16 },
  prefixLabel: { type: String, default: '' },
});

const formatter = new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 });

const formattedCurrency = computed(() => {
  if (typeof props.value === 'string') {
    return '-';
  }
  const parts = formatCurrencyValue(props.value, '-', formatter).split('.');
  return `${props.prefixLabel} ${parts[0]}<span style="font-size: ${props.decimalFontSize}px">.${parts[1]}</span>`.trim();
});
</script>

<template>
  <span v-html="formattedCurrency" :style="{ fontSize: `${mainFontSize}px` }"></span>
</template>
