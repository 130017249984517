import { AmountType } from '..';

export enum OptionType {
  CALL = 'CALL',
  PUT = 'PUT',
}

export const optionTypeActionsToHuman: Record<OptionType, string> = {
  CALL: 'Call',
  PUT: 'Put',
};

export const optionTypeToHuman = (optionType: OptionType) => {
  return optionTypeActionsToHuman[optionType] ?? optionType ?? '';
};

/**
 * Trade Currency data (seen by all users)
 */
export interface TradeCurrencyData {
  currency: string;
  currencyDecimalPlaces: number;
  amountType: AmountType;
  clientAmount: number;
  remainingClientAmount: number;
  clientRate: number;
  partnerProfit: number;
  partnerSwapPnl: number;
  isPrimaryRate: boolean;
  isFixedSide: boolean;
  optionType?: string;
  productCoreRate?: number;
  clientPremiumAmount: number;
  lpPremiumAmount: number;
}
