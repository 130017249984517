<template>
  <div>
    <div class="fees-info-block">
      <h4>Fees</h4>
      <div class="fee-words-wrap mb-3 mt-2">
        Some transactions such as funding and payments, incur fees. You can choose the corresponding amounts to be paid
        by the client
      </div>
    </div>
    <div class="text-center mb-2">
      <BModal
        modal-class="side-modal payment-fee-modal"
        ref="paymentFeesEditModal"
        title="Payment Fees"
        hide-footer
        v-bind="$attrs"
      >
        <FeesListView :paymentType="feePaymentType.PAYMENT" :clientId="client.id" :clientName="client.name" inModal />
      </BModal>
      <slot v-bind="{ showPaymentFeeModal }">
        <VButton @click="$refs.paymentFeesEditModal.show()" class="btn-secondary">Edit Payment Fees</VButton>
      </slot>
    </div>
    <div class="text-center mb-1">
      <BModal
        modal-class="side-modal payment-fee-modal"
        ref="fundingFeesEditModal"
        title="Funding Fees"
        hide-footer
        v-bind="$attrs"
      >
        <FeesListView :paymentType="feePaymentType.RECEIPT" :clientId="client.id" :clientName="client.name" inModal />
      </BModal>
      <slot v-bind="{ showFundingFeeModal }">
        <VButton @click="$refs.fundingFeesEditModal.show()" class="funding-fee-button btn-secondary"
          >Edit Funding Fees
        </VButton>
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import FeesListView from '@/app/views/settings/FeesListView.vue';
import { Client, FeePaymentType } from 'ah-api-gateways';
import { BModal } from 'bootstrap-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    FeesListView,
  },
})
export default class ClientFeeInfo extends Vue {
  $refs!: {
    paymentFeesEditModal: BModal;
    fundingFeesEditModal: BModal;
  };

  @Prop({ required: true }) client!: Client;

  get feePaymentType() {
    return FeePaymentType;
  }

  showPaymentFeeModal() {
    this.$refs.paymentFeesEditModal.show();
  }

  showFundingFeeModal() {
    this.$refs.fundingFeesEditModal.show();
  }
}
</script>
<style lang="scss" scoped>
.fee-words-wrap {
  white-space: pre-line;
  @include upToResolution($tabletResolution) {
    font-size: 13px;
    font-weight: $font-weight-regular;
  }
}

.funding-fee-button {
  width: 10.3rem;
}

::v-deep .payment-fee-modal .modal-dialog {
  width: 45rem;
}

.fees-info-block {
  @include tablet {
    min-height: 10rem;
  }
}
</style>
