var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PaginatedTable',_vm._g(_vm._b({class:[
    'wallets-table',
    {
      'hide-headers': _vm.hideHeaders !== false,
      'hide-pagination': _vm.hidePagination !== false,
    },
  ],attrs:{"fixed":"","hover":"","nowrap":"","animateCols":"","items-label":"wallets","tbody-tr-class":_setup.rowClass,"stacked":_vm.$ahWalletState.mediaQuery.is('smDown')},scopedSlots:_vm._u([{key:"cell(currency)",fn:function({ item }){return [_c(_setup.CurrencyTableCell,{attrs:{"currency":item.currency,"showLabels":_vm.narrow === false}})]}},{key:"cell(inTransit)",fn:function({ item }){return [(item.tradeBuyAmountInTransit > 0 || item.tradeSellAmountInTransit > 0)?_c(_setup.FundsInTransitIcon,{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Amount in Transit'),expression:"'Amount in Transit'"}],staticClass:"d-block ml-auto mr-auto"}):_vm._e()]}},{key:"cell(balance)",fn:function({ item }){return [_vm._v(" "+_vm._s(_setup.formatCurrencyValue(item.balance))+" ")]}},{key:"cell(add-money)",fn:function({ item }){return [_c('div',{staticClass:"add-money"},[(_setup.canSeeFundingDetails)?_c(_setup.AddMoneyModal,{attrs:{"clientId":_setup.ownerId,"wallet":item},scopedSlots:_vm._u([{key:"default",fn:function({ show }){return [_c('a',{staticClass:"add-money-link link",on:{"click":show}},[_vm._v("Add money "),_c('InfoTooltip',{attrs:{"text":_vm.$ahWalletState.i18n.t('tooltips.addMoney')}}),_c('IconArrowRight')],1)]}}],null,true)}):_vm._e()],1)]}},{key:"headInner(feeWarning)",fn:function(){return [_vm._v(" "+_vm._s(null))]},proxy:true},{key:"cell(feeWarning)",fn:function({ item }){return [(
        _setup.withOutstandingFees &&
        _setup.feesInfo &&
        item.currency === _setup.feesInfo.debtCurrency &&
        !_vm.$ahWalletState.mediaQuery.is('smDown')
      )?_c('div',{staticClass:"text-content"},[_c('IconInfoCircle',{staticClass:"status-icon danger"})],1):_vm._e()]}}])},'PaginatedTable',{ ..._setup.managedListing.bindings, ..._vm.$attrs },false),{ ..._setup.managedListing.listeners, ..._vm.$listeners }))
}
var staticRenderFns = []

export { render, staticRenderFns }