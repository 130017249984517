<script setup lang="ts">
import { QuotePriceResponse, TradeFundingDetails } from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import OldTradeFundForm from '../../forms/tradeFundForm/OldTradeFundForm.vue';
import { useTradeState } from '../../..';
import { computed, watch, onBeforeMount } from 'vue';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

/**
 * FIXME - This component serves the purpose to render the old flow of forward trades (US's PT-1828 and PT-1829)
 * This will exist until we remove the VARIATION_MARGIN_AND_LIMITS_FEATURE feature flag
 * After that, and since this component is only used here, we should remove it
 * */

const tradeState = useTradeState();

const onBehalfOfClient = useOnBehalfOf();

const emit = defineEmits<{
  (e: 'update:sufficientCollateral', value: boolean): void;
  (e: 'update:tradeFundingDetails', value: Partial<TradeFundingDetails>): void;
}>();

const props = withDefaults(
  defineProps<{
    tradePriceResponse: QuotePriceResponse;
    tradeFundingDetails?: Partial<TradeFundingDetails>;
    hideFundingForm?: boolean | false;
  }>(),
  {
    hideFundingForm: false,
  }
);

const collaterals = computed(() => {
  const clientId = onBehalfOfClient.value
    ? onBehalfOfClient.value.id
    : tradeState.store.useAuthStore().loggedInIdentity?.client?.id;

  if (clientId) {
    return tradeState.store.useWalletsStore().getCollaterals(clientId);
  }
  return null;
});

const shouldHideIMFundingForm = computed(() => props.hideFundingForm !== false);

const areCollateralsLoading = computed(() => {
  return tradeState.store.useWalletsStore().areCollateralsLoading(onBehalfOfClient.value?.id);
});

const sufficientCollateral = computed(() => {
  if (!collaterals.value || areCollateralsLoading.value) {
    return false;
  }

  return (
    props.tradePriceResponse.homeCurrencyInitialMarginAmount <= 0 ||
    collaterals.value.marginCollateralAvailable >= props.tradePriceResponse.homeCurrencyInitialMarginAmount
  );
});

onBeforeMount(() => {
  tradeState.store.useWalletsStore().loadCollaterals({ force: false, clientId: onBehalfOfClient.value?.id });
});

/**
 * Reset tradeFundingDetails whenever Initial Margin is not required
 */
watch(
  () => props.tradePriceResponse.homeCurrencyInitialMarginAmount > 0,
  (imRequired) => {
    if (!imRequired) {
      emit('update:tradeFundingDetails', {});
    }
  },
  { immediate: true }
);

watch(
  () => props.tradePriceResponse,
  () => {
    tradeState.store.useWalletsStore().loadCollaterals({ force: true, clientId: onBehalfOfClient.value?.id });
  },
  { immediate: true }
);

watch(
  sufficientCollateral,
  () => {
    emit('update:sufficientCollateral', sufficientCollateral.value);
  },
  { immediate: true }
);
</script>

<template>
  <div v-if="tradePriceResponse.homeCurrencyInitialMarginAmount > 0">
    <p class="text-secondary">There is an initial margin requirement on this trade:</p>

    <LoadingOverlay :loading="areCollateralsLoading" variant="box" opacity="0.4">
      <template v-if="collaterals">
        <DataRow cols="6" label="Initial Margin" class="mb-2 trade-initial-margin">
          {{ collaterals.currency }}
          {{ formatCurrencyValue(tradePriceResponse.homeCurrencyInitialMarginAmount) }}
          ({{ tradePriceResponse.initialMarginPercentage * 100 }} %)<InfoTooltip
            :text="$ahTradesState.i18n.t('tooltips.initialMargin')"
          />
        </DataRow>
        <DataRow cols="6" class="mb-3 margin-collateral-available" label="Margin collateral available (to trade)">
          {{ collaterals.currency }}
          {{ formatCurrencyValue(collaterals.marginCollateralAvailable) }}
        </DataRow>
        <OldTradeFundForm
          :tradeFundingDetails="tradeFundingDetails"
          :tradePriceResponse="tradePriceResponse"
          v-if="!shouldHideIMFundingForm"
          @update:tradeFundingDetails="$emit('update:tradeFundingDetails', $event)"
        />
      </template>
    </LoadingOverlay>
  </div>
  <div v-else>
    <p class="text-secondary">There is no initial margin requirement on this trade.</p>
  </div>
</template>

<style lang="scss" scoped>
.info-circle {
  font-size: 1.5em;
  margin-right: 0.1em;
  @include themedTextColor($color-danger);
  position: relative;
  bottom: 0.05em;
}
</style>
