<script setup lang="ts">
import Vue, { computed, getCurrentInstance, reactive, ref, watch } from 'vue';
import InfoBlock, { InformationBlock } from 'ah-common-lib/src/common/components/InfoBlock.vue';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { toDataModel, updateModel } from 'ah-common-lib/src/form/helpers';
import {
  BaseIndividual,
  Client,
  ClientFileCategories,
  FileCategory,
  UnsubmittedUbo,
  UnsubmittedUboFile,
  UploadedFile,
} from 'ah-api-gateways';
import { makeMemberForm } from './reviewForms';
import DocumentsUploader from '@/app/components/settings/client/DocumentsUploader.vue';
import { cloneDeep } from 'lodash';
import UboDocumentsEditor from '../../ubos/UboDocumentsEditor.vue';
import AccordionBox from '@/app/components/common/AccordionBox.vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useAuthStore } from '@/app/store/authStore';
import { getServices } from '@/app/services';
import { forkJoin, from, of } from 'rxjs';
import { mergeMap, defaultIfEmpty } from 'rxjs/operators';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';

type UboForm = FormDefinition & { id: string; ubo: UnsubmittedUbo };

const requestManager = useRequestManager().manager;

const services = getServices();

const toast = getCurrentInstance()?.proxy.$toast;

const authStore = useAuthStore();

const individualStore = useIndividualSettingsStore();

const emit = defineEmits<{ (e: 'update:ubos', ubos: BaseIndividual[]): void }>();

const props = withDefaults(
  defineProps<{
    client: Client;
    documents?: UploadedFile[];
    ubos?: BaseIndividual[];
    editable?: boolean;
  }>(),
  {
    documents: () => [],
    ubos: () => [],
    editable: true,
  }
);

const state = reactive<{
  editing: boolean;
  uboForms: UboForm[];
}>({
  editing: false,
  uboForms: [] as UboForm[],
});

function includesFile(category: FileCategory) {
  return props.documents.find((doc) => doc.category === category) ? 'Uploaded' : 'Upload Later';
}

const signatoryDocsBlock = computed<InformationBlock[]>(() => [
  { label: 'Proof of ID', key: 'poi', value: includesFile(ClientFileCategories.PHOTO_ID), cols: 4 },
  { label: 'Proof of Address', key: 'poa', value: includesFile(ClientFileCategories.PROOF_OF_ADDRESS), cols: 4 },
]);

const companyDocsBlock = computed<InformationBlock[]>(() => [
  {
    label: 'Certificate of incorporation',
    key: 'incCertificate',
    value: includesFile(ClientFileCategories.INCORPORATED_DOCUMENT),
    cols: 4,
  },
  {
    label: 'Audited financial statements/latest accounts',
    key: 'financialStatements',
    value: includesFile(ClientFileCategories.FINANCIAL_STATEMENT),
    cols: 4,
  },
  {
    label: 'Sample Invoice/Proof of Business Activity',
    key: 'pba',
    value: includesFile(ClientFileCategories.SAMPLE_INVOICE),
    cols: 4,
  },
]);

const uboInfoBlock = ref<InformationBlock[]>([
  { label: 'Are there any UBOs?', key: 'any', value: props.ubos.length > 0 ? 'Yes' : 'No', cols: 4 },
]);

const uboBlock = ref<InformationBlock[]>([
  { label: 'Title', key: 'title', cols: 4 },
  { label: 'Name', key: 'firstName', cols: 4 },
  { label: 'Surname', key: 'lastName', cols: 4 },
  { label: 'Business email address', key: 'email', cols: 4 },
  { label: 'Mobile Number', key: 'phoneNumber', cols: 8 },
  { label: 'Proof of Address', key: 'poaDoc', cols: 4 },
  { label: 'Proof of Identification', key: 'poIdentification', cols: 4 },
]);

const isEditable = computed(() => props.editable !== false);

function uboSaveRequests() {
  const clientId = authStore.loggedInIdentity!.client!.id;
  return state.uboForms.map((uboForm, index) => {
    return services.compliance
      .updateUboUser(clientId, uboForm.ubo.id!, toDataModel(state.uboForms[index].form) as BaseIndividual)
      .pipe(
        mergeMap(() =>
          forkJoin(
            (uboForm.ubo.readyToUpload || []).map((file) => {
              if (file.file) {
                return services.compliance.submitUboDocument(clientId, uboForm.ubo.id!, file.category, file.file!);
              } else {
                const toBeRemoved = uboForm.ubo.documents.find((doc) => doc.category === file.category);
                if (toBeRemoved) {
                  return services.compliance.removeUboDocument(clientId, uboForm.ubo.id!, toBeRemoved.id);
                }
                return of(null);
              }
            })
          ).pipe(defaultIfEmpty([] as any))
        )
      );
  });
}

function save() {
  requestManager
    .currentOrNew('saveUbos', forkJoin([...uboSaveRequests(), from(individualStore.loadClientDocuments(true))]))
    .subscribe(() => {
      toast?.success('Changes applied successfully');
      emit(
        'update:ubos',
        state.uboForms.map((uboForm) => ({
          ...uboForm.ubo,
          ...toDataModel(uboForm.form),
        }))
      );
      setTimeout(() => {
        state.editing = false;
      });
    });
}

function uboContainsFile(ubo: UnsubmittedUbo, category: FileCategory) {
  return !!ubo.documents?.find((f) => f.category === category);
}

function resetUbos(loadFiles: boolean = false) {
  const ubos = cloneDeep(props.ubos) as UnsubmittedUbo[];
  const uboForms: UboForm[] = [];
  ubos.forEach((ubo) => {
    const uboForm = state.uboForms.find((f) => f.id === ubo.id);
    uboForms.push({
      ...uboForm,
      id: ubo.id!,
      form: makeMemberForm(true),
      validation: null,
      ubo,
    });
    updateModel(uboForms[uboForms.length - 1].form, ubo);
    if (loadFiles) {
      requestManager
        .sameOrCancelAndNew(
          'getUboDocuments',
          services.compliance.getUboDocuments(authStore.loggedInIdentity!.client!.id, ubo.id!, {
            errors: { silent: true },
          })
        )
        .subscribe((response) => {
          Vue.set(ubo, 'documents', response);
        });
    }
  });
  state.uboForms = uboForms as any;
}

function onFileSelected(ubo: UnsubmittedUbo, fileData: UnsubmittedUboFile) {
  let readyToUpload = ubo.readyToUpload;
  if (!readyToUpload) {
    Vue.set(ubo, 'readyToUpload', []);
    readyToUpload = ubo.readyToUpload!;
  }
  let uploadFile = readyToUpload.find((i) => i.category === fileData.category);

  if (!uploadFile) {
    uploadFile = { ...fileData };
    readyToUpload.push(uploadFile);
  } else {
    uploadFile.file = fileData.file;
  }
}

watch(
  () => props.ubos,
  () => resetUbos(true),
  { immediate: true }
);

watch(
  () => state.editing,
  () => {
    if (state.editing) {
      resetUbos();
    }
  }
);
</script>

<template>
  <div class="card-block" x-test-name="documents-review">
    <div class="card-review-header">
      <h2>Documents</h2>
      <div class="button-holder" v-if="isEditable">
        <VButton blurOnClick @click="state.editing = !state.editing" class="btn-stroked">
          {{ state.editing ? 'Cancel' : 'Edit' }}
        </VButton>
        <VButton blurOnClick @click="save" v-if="state.editing" :loading="requestManager.anyPending" class="ml-3">
          Save
        </VButton>
      </div>
    </div>

    <div v-show="!state.editing">
      <h3 class="mb-3">Verification of Authorised Signatory</h3>
      <InfoBlock :info="signatoryDocsBlock" />
      <h3 class="my-3">Company Documents</h3>
      <InfoBlock :info="companyDocsBlock" />
      <h3 class="my-3">Ultimate Beneficial Owner Information</h3>
      <InfoBlock :info="uboInfoBlock" />
      <div v-for="(uboForm, index) in state.uboForms" :key="uboForm.id">
        <h3 class="my-3">UBO {{ index + 1 }}</h3>
        <InfoBlock :model="uboForm.ubo" :info="uboBlock">
          <template #poaDoc-value>
            {{ uboContainsFile(uboForm.ubo, ClientFileCategories.PROOF_OF_ADDRESS) ? 'Uploaded' : 'Not Uploaded' }}
          </template>
          <template #poIdentification-value>
            {{ uboContainsFile(uboForm.ubo, ClientFileCategories.PHOTO_ID) ? 'Uploaded' : 'Not Uploaded' }}
          </template>
        </InfoBlock>
      </div>
    </div>

    <div v-show="state.editing">
      <DocumentsUploader :withBoxes="false" tooltip class="documents-wrapper" fileClass="col col-6">
        <template #title> Verification of Authorised Signatory </template>
      </DocumentsUploader>
      <AccordionBox
        class="ubo-wrapper"
        v-for="(uboForm, index) in state.uboForms"
        :key="`${uboForm.id}`"
        :title="`UBO ${index + 1}`"
        :open="index === 0"
      >
        <ValidatedForm :fm="state.uboForms[index].form" :validation.sync="state.uboForms[index].validation" />
        <UboDocumentsEditor
          :ubo="uboForm.ubo"
          :files="uboForm.ubo.documents"
          @file-selected="onFileSelected(uboForm.ubo, $event)"
          :auto-upload="false"
        />
      </AccordionBox>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-review-header {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;

  h2 {
    font-size: $h3-font-size;
    margin-bottom: 0;
  }

  .button-holder {
    margin-left: auto;
    display: inline-flex;
  }
  .btn {
    min-width: 7rem;
  }
}

::v-deep {
  .documents-wrapper {
    .company-documents {
      margin: 2rem 0 !important;
    }
    h3 {
      font-size: 1rem;
      font-weight: $font-weight-semibold;
      margin-top: 0 !important;
    }
    .files-list-element {
      margin-top: 0 !important;
    }
  }

  .ubo-wrapper {
    margin-bottom: calc($padded-space / 1.5);
    &:not(:last-child) {
      border-bottom: 1px solid;
      @include themedBorderColor($color-primary);
    }

    .header,
    .body {
      margin-bottom: calc($padded-space / 1.5);
      font-weight: $font-weight-semibold;
    }
  }
}

h4 {
  font-weight: $font-weight-semibold;
}
</style>
