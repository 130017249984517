<script setup lang="ts">
import { BoxGridItemProps } from './interfaces';
import BoxGridItem from './BoxGridItem.vue';
import { computed } from 'vue';

const props = defineProps({
  ...BoxGridItemProps,
  itemClass: {
    type: String,
    default: 'tile-card',
  },
  tileTitle: {
    type: String,
    required: false,
  },
});

const boxGridItemProps = computed(() => {
  const { tileTitle, ...out } = props;
  return out;
});
</script>

<template>
  <BoxGridItem v-on="$listeners" v-bind="{ ...boxGridItemProps, ...$attrs }">
    <template #before-content>
      <div class="tile-title">
        <span>
          <slot name="title">
            {{ tileTitle }}
          </slot>
        </span>
      </div>
    </template>
    <template #default="slotScope">
      <slot v-bind="slotScope" />
    </template>
  </BoxGridItem>
</template>

<style lang="scss" scoped>
::v-deep .tile-card {
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-radius: $box-border-radius;
  border-top: none;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding: 1.5rem;
  margin-top: 28px;

  .tile-title {
    position: absolute;
    top: -4px;
    left: 5px;
    right: 5px;
    overflow: hidden;
    transform: translate(0, -13px);
  }

  .tile-title > span {
    font-size: 20px;
    font-weight: 700;
    float: left;
    padding: 0px 10px;
    line-height: 24px;
  }

  .tile-title::before,
  .tile-title::after {
    content: '';
    height: $box-border-radius;
  }

  .tile-title::before {
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-top-left-radius: $box-border-radius;
    float: left;
    margin: 10px 0 0;
    width: 30px;
  }

  .tile-title::after {
    border-top: 1px solid black;
    border-right: 1px solid black;
    border-top-right-radius: $box-border-radius;
    position: relative;
    display: block;
    left: 0;
    top: 10px;
    overflow: hidden;
  }
}
</style>
