<script lang="ts" setup>
import {
  Trade,
  closedTradeStatuses,
  AuthorityType,
  AdminTrade,
  PaymentReport,
  HedgingInstruments,
} from 'ah-api-gateways';
import TradeInfo from 'ah-trades/src/components/info/TradeInfo.vue';
import TradeMarginInfo from 'ah-trades/src/components/info/TradeMarginInfo.vue';
import TradeProfitInfo from 'ah-trades/src/components/info/TradeProfitInfo.vue';
import TradeOnBehalfOfInfo from 'ah-trades/src/components/info/TradeOnBehalfOfInfo.vue';
import DrawdownModal from 'ah-trades/src/components/modals/DrawdownModal.vue';
import ResendTradeConfEmailModal from 'ah-trades/src/components/modals/ResendTradeConfEmailModal.vue';
import CancelTradeModal from 'ah-trades/src/components/modals/CancelTradeModal.vue';
import RollForwardModal from 'ah-trades/src/components/modals/RollForwardModal.vue';
import { TradeStatus } from 'ah-api-gateways';
import TradeDetailsModal from '../info/TradeDetailsModal.vue';
import TradeTransactionDetailsModal from 'ah-wallets/src/components/TradeTransactionDetailsModal.vue';
import { computed, watch, reactive } from 'vue';
import { useTradeState } from '../..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

const props = withDefaults(
  defineProps<{
    /**
     * Trade focused and currently showing the details off
     *
     */
    trade: Trade | AdminTrade;
    readonly?: boolean | string;
  }>(),
  {
    readonly: false,
  }
);

const emit = defineEmits<{
  (e: 'amend', value: Trade | AdminTrade): void;
}>();

const isReadonly = computed(() => props.readonly !== false);

const tradeState = useTradeState();

const onBehalfOfClient = useOnBehalfOf();

const requestManager = useRequestManager({
  exposeToParent: true,
}).manager;

const state = reactive<{
  payment: PaymentReport | null;
}>({
  payment: null,
});

const canResendNotifications = computed(() => {
  return onBehalfOfClient.value || tradeState.store.useAuthStore().hasAuthorities(AuthorityType.RESEND_NOTIFICATION);
});

const isAHUser = computed(() => tradeState.store.useAuthStore().isAHUser);

const canRequestPrices = computed(() => {
  return onBehalfOfClient.value || tradeState.store.useAuthStore().hasAuthorities(AuthorityType.REQUEST_PRICES);
});

const tradeIsClosed = computed(() => {
  return closedTradeStatuses.includes(props.trade.status);
});

const isClientUser = computed(() => {
  return tradeState.store.useAuthStore().isClientUser;
});

/**
 * Only Partners/Traders can cancel trades via OBO
 */
const canCancelTrade = computed(() => {
  return (
    tradeState.store.useAuthStore().hasAuthorities(AuthorityType.CANCEL_TRADE) &&
    !!onBehalfOfClient.value &&
    isAHUser.value &&
    props.trade.isCancellable
  );
});

/**
 * Only Partners/Traders can roll forward trades via OBO
 */
const canRollForward = computed(() => {
  return (
    tradeState.store.useAuthStore().hasAuthorities(AuthorityType.ROLLFORWARD_TRADE) &&
    !!onBehalfOfClient.value &&
    isAHUser.value &&
    props.trade.isRollForwardable
  );
});

/**
 * Only Partners/Traders can roll forward trades via OBO
 */
const canAmend = computed(() => {
  return (
    tradeState.store.useAuthStore().hasAuthorities(AuthorityType.AMEND_TRADES) &&
    !!onBehalfOfClient.value &&
    isAHUser.value &&
    props.trade.isAmendable
  );
});

/**
 * Partners/Traders can drawdown via OBO, as can Client users with the correct authority
 */
const drawdownAllowed = computed(() => {
  return (
    (onBehalfOfClient.value || tradeState.store.useAuthStore().hasAuthorities(AuthorityType.DRAWDOWN_TRADE)) &&
    !isReadonly.value
  );
});

const adminTrade = computed(() => props.trade as AdminTrade);

const isVanilla = computed(() => props.trade.hedgingProduct === HedgingInstruments.VANILLA_OPTIONS);

const canViewPayment = computed(() => tradeState.store.useAuthStore().hasAuthorities(AuthorityType.VIEW_PAYMENTS));

function tradeIsAmended(status: TradeStatus) {
  return status === TradeStatus.AMENDED;
}

function amendTrade() {
  emit('amend', props.trade);
}

function loadLinkedPayments() {
  requestManager
    .sameOrCancelAndNew(
      'getLinkedPayments',
      tradeState.services.payments.listPayments({
        tradeId: props.trade.id,
      })
    )
    .subscribe((res) => {
      state.payment = res.list[0];
    });
}

watch(
  () => props.trade,
  () => {
    if (props.trade.referenceNumber) {
      loadLinkedPayments();
    }
  },
  { immediate: true }
);
</script>

<template>
  <div>
    <BoxGrid :alignH="$ahTradesState.mediaQuery.is('smDown') ? 'center' : 'start'">
      <BoxGridItem class="info-block pl-sm-2" cols="12" sm="10" md="12" lg="4" align-self="stretch">
        <!-- Information Section -->
        <div>
          <TradeInfo :trade="trade" />
          <p class="font-italic text-muted" v-if="trade.amendedTradeId && isAHUser">
            View the original trade
            <TradeDetailsModal :tradeId="trade.amendedTradeId" title="Original trade details" />.
          </p>
          <p class="font-italic text-muted" v-else-if="tradeIsAmended(trade.status) && isAHUser">
            View the new amended trade
            <TradeDetailsModal :tradeId="trade.id" title="Amended trade details" show-amended />.
          </p>
        </div>
        <!-- Actions Section -->
        <div class="action-section" v-if="canAmend">
          <div class="text-center">
            <VButton class="amend-button" @click="amendTrade"> Amend Trade </VButton>
          </div>
        </div>
      </BoxGridItem>

      <BoxGridItem class="info-block px-sm-2" cols="12" sm="10" md="12" lg="4" align-self="stretch">
        <!-- Information Section -->
        <TradeMarginInfo :trade="trade" />
        <!-- Actions Section -->
        <div class="action-section">
          <div class="cancel-button" v-if="canCancelTrade">
            <CancelTradeModal :trade="trade" v-on="$listeners" />
          </div>
          <div class="cancel-button my-5 text-center" v-if="canRollForward">
            <RollForwardModal :trade="trade" v-on="$listeners" />
          </div>
        </div>
      </BoxGridItem>

      <BoxGridItem class="info-block borderless px-sm-2" cols="12" sm="10" md="12" lg="4" align-self="stretch">
        <!-- Information Section -->
        <div>
          <p v-if="!isClientUser && !trade.oboCreatedBy" class="text-secondary pb-2">Trade executed by the Client.</p>
          <TradeOnBehalfOfInfo :trade="trade" v-else />
          <TradeProfitInfo :trade="adminTrade" />
        </div>
        <!-- Actions Section -->
        <div class="action-section">
          <div class="transaction-button mb-2" v-if="canViewPayment && state.payment && !isVanilla">
            <TradeTransactionDetailsModal :trade="trade" :payment="state.payment" />
          </div>
          <div
            class="confirmation-button mb-2"
            v-if="!tradeIsClosed && !isReadonly && canResendNotifications && !isVanilla"
          >
            <ResendTradeConfEmailModal :trade="trade" />
          </div>
          <div class="drawdown-button" v-if="trade.isDrawdownEligible && drawdownAllowed && canRequestPrices">
            <DrawdownModal @update:trade="$emit('update:trade', $event)" :trade="trade" />
          </div>
        </div>
      </BoxGridItem>
    </BoxGrid>
  </div>
</template>

<style lang="scss" scoped>
::v-deep .confirmation-button .btn {
  width: 15rem;
}

.amend-button,
::v-deep .transaction-button .btn,
::v-deep .drawdown-button .btn,
::v-deep .cancel-button .btn {
  width: 14rem;
}

.info-block {
  ::v-deep {
    .box-grid-item {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    .transaction-button button,
    .drawdown-button button,
    .confirmation-button button,
    .cancel-button button {
      min-width: 15rem;

      @media only screen and (max-width: 480px) {
        min-width: unset;
        width: 100%;
      }
    }
  }

  .action-section {
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  @include upToResolution($tabletResolution) {
    font-size: 0.875rem;
  }

  @include upToResolution($desktopResolution) {
    padding: 1rem 0;
    &:not(.borderless) {
      border-bottom-style: solid;
      border-bottom-width: 1px;
      @include themedPropColor('border-bottom-color', $color-input-border);
    }
  }

  @include desktop {
    &:not(.borderless) {
      border-right-style: solid;
      border-right-width: 1px;
      @include themedPropColor('border-right-color', $color-input-border);
      padding-right: math.div($padded-space, 2);

      @include desktop {
        padding-right: $padded-space;
      }
    }

    &:not(:first-child) {
      padding-left: math.div($padded-space, 2);

      @include desktop {
        padding-left: $padded-space;
      }
    }
  }
}
</style>
