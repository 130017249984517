import { HttpOptions, HttpBasedService, HttpService, uploadFileWithUpdates, HttpRequestOptions } from 'ah-requests';
import {
  Client,
  ClientCreateRequest,
  ClientUpdateRequest,
  PaginatedQuery,
  PaginatedResponse,
  ClientSettings,
  standardiseQuerySort,
  ListQuery,
  ExportListType,
  UploadedFile,
  FileUploadRequest,
  ClientActivityReport,
  ClientFileCategories,
  ClientOnboardingReport,
  ClientActivityReportRequest,
  ClientOnboardingReportFilters,
  VersionedObject,
  ClientListResponse,
  DocumentExport,
  ClientOnboardingStatusReport,
  CompanyDetails,
  OwnerUpdateRequest,
  ComplianceStatus,
} from '../models';
import { map } from 'rxjs/operators';
import { downloadFile } from 'ah-requests/helpers/download';
import { Observable } from 'rxjs';
import { AssigneeReference } from '../models';

export class ClientService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'clientService' } },
      },
    });
  }

  public getClient(
    id: string,
    withIndividuals = false,
    options?: Partial<HttpOptions<Client>>,
    override?: HttpRequestOptions
  ) {
    const params = withIndividuals ? { withIndividuals } : {};

    return this.get<Client>(`${this.baseUrl}clients/${id}`, {
      axiosConfig: {
        params,
      },
      options: {
        ...options,
      },
      ...override,
    });
  }

  public createClient(data: Partial<ClientCreateRequest>) {
    return this.post<VersionedObject>(`${this.baseUrl}clients`, data);
  }

  public deleteClient(id: string) {
    return this.delete(`${this.baseUrl}clients/${id}`);
  }

  public listClients(
    query: PaginatedQuery<{
      withReport: boolean;
      withAssignees: boolean;
      withIndividuals: boolean;
      withDirectors: boolean;
      withSettings: boolean;
    }>
  ): Observable<PaginatedResponse<ClientListResponse>> {
    query = standardiseQuerySort(query);
    return this.get<PaginatedResponse<ClientListResponse>>(`${this.baseUrl}clients`, {
      axiosConfig: {
        params: query,
      },
    });
  }

  public downloadClientList(query: ListQuery, fileFormat: ExportListType, documentTitle = 'Clients List') {
    query = { ...query };
    if (query.sort === 'name') {
      const sortDirection = query.sortDirection ? query.sortDirection.toLowerCase() : 'desc';
      query.sort = [`firstName,${sortDirection}`, `lastName,${sortDirection}`];
    } else {
      query = standardiseQuerySort(query);
    }

    return this.get<DocumentExport>(`${this.baseUrl}clients/export`, {
      axiosConfig: {
        params: {
          ...query,
          fileFormat,
          documentTitle,
        },
      },
    });
  }

  public updateClient(id: string, model: Partial<ClientUpdateRequest> | Partial<CompanyDetails>) {
    return this.put<VersionedObject>(`${this.baseUrl}clients/${id}`, model, {
      options: {
        cache: {
          type: 'delete',
          cacheKey: 'client',
          itemKey: id,
        },
      },
    });
  }

  public getClientSettings(id: string): Observable<ClientSettings> {
    return this.get<ClientSettings>(`${this.baseUrl}clients/${id}/settings`, {
      options: {
        cache: {
          type: 'use',
          cacheKey: 'ClientSettings',
          itemKey: id,
        },
      },
    });
  }

  public updateClientSettings(id: string, data: Partial<ClientSettings>) {
    return this.put<ClientSettings>(`${this.baseUrl}clients/${id}/settings`, data, {
      options: {
        cache: { type: 'override', cacheKey: 'ClientSettings', itemKey: id },
      },
    });
  }

  public getClientAssignees(clientId: string, options?: Partial<HttpOptions<AssigneeReference[]>>) {
    return this.get<AssigneeReference[]>(`${this.baseUrl}clients/${clientId}/assignees`, { options });
  }

  public setClientAssignees(
    clientId: string,
    assignees: { id: string; primary: boolean }[],
    options?: Partial<HttpOptions<AssigneeReference[]>>
  ) {
    return this.put<AssigneeReference[]>(`${this.baseUrl}clients/${clientId}/assignees`, assignees, { options });
  }

  public getDocuments(id: string, options?: Partial<HttpOptions<UploadedFile[]>>) {
    return this.get<UploadedFile[]>(`${this.baseUrl}clients/${id}/documents`, { options });
  }

  public deleteDocument(clientId: string, fileId: string) {
    return this.delete<{}>(`${this.baseUrl}clients/${clientId}/documents/${fileId}`);
  }

  public downloadSyncDocument(clientId: string, file: UploadedFile) {
    return this.rawRequest<any>({
      axiosConfig: {
        method: 'get',
        responseType: 'arraybuffer',
        url: file.link ?? `${this.baseUrl}clients/${clientId}/documents/${file.id}`,
      },
    }).pipe(
      map((response) => {
        downloadFile(response);
      })
    );
  }

  public uploadDocument(clientId: string, category: ClientFileCategories, file: File) {
    const formData = new FormData();

    formData.append(
      'model',
      JSON.stringify(<FileUploadRequest>{
        category,
        name: file.name,
      })
    );

    formData.append('file', file);

    return uploadFileWithUpdates<VersionedObject>(
      {
        axiosConfig: {
          url: `${this.baseUrl}clients/${clientId}/documents`,
          method: 'POST',
          data: formData,
        },
      },
      (c) => this.request(c)
    );
  }

  public getClientActivityReport(params: ClientActivityReportRequest = {}) {
    if (params.to) {
      params.to = params.to.toISOString() as any;
    }
    if (params.from) {
      params.from = params.from.toISOString() as any;
    }

    return this.get<ClientActivityReport>(`${this.baseUrl}clients/reports/activity`, {
      axiosConfig: {
        params,
      },
    });
  }

  public listClientOnboardingReport(params: PaginatedQuery<ClientOnboardingReportFilters> = {}) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<ClientOnboardingReport>>(`${this.baseUrl}clients/onboarding`, {
      axiosConfig: {
        params,
      },
    });
  }

  public downloadClientOnboardingReport(
    query: ListQuery<ClientOnboardingReportFilters>,
    fileFormat: ExportListType,
    documentTitle = 'Onboarding List'
  ) {
    return this.get<DocumentExport>(`${this.baseUrl}clients/onboarding/export`, {
      axiosConfig: {
        params: {
          ...query,
          fileFormat,
          documentTitle,
        },
      },
    });
  }

  public getClientOnboardingReportStatus(params: ClientOnboardingReportFilters = {}) {
    return this.get<ClientOnboardingStatusReport>(`${this.baseUrl}clients/reports/onboarding-status-summary`, {
      axiosConfig: {
        params,
      },
    });
  }

  public createOwner(id: string, data: Partial<OwnerUpdateRequest>) {
    return this.post<VersionedObject>(`${this.baseUrl}clients/${id}/owner`, data);
  }

  public changeOwner(id: string, individualId: string) {
    return this.post<VersionedObject>(`${this.baseUrl}clients/${id}/owner/${individualId}`);
  }

  public resendTermsAndConditions(id: string, customEmail?: string) {
    return this.post<VersionedObject>(`${this.baseUrl}clients/${id}/terms-and-conditions/reset`, { customEmail });
  }

  public rejectTermsAndConditions(clientId: string, httpOptions?: Partial<HttpRequestOptions<VersionedObject>>) {
    return this.post<VersionedObject>(
      `${this.baseUrl}clients/${clientId}/terms-and-conditions/reject`,
      undefined,
      httpOptions
    );
  }

  public acceptTermsAndConditions(clientId: string, httpOptions?: Partial<HttpRequestOptions<VersionedObject>>) {
    return this.post<VersionedObject>(
      `${this.baseUrl}clients/${clientId}/terms-and-conditions/approve`,
      undefined,
      httpOptions
    );
  }

  public updateClientOnboardingStatus(clientId: string, newStatus: ComplianceStatus) {
    return this.put<VersionedObject>(`${this.baseUrl}clients/cases/lcp/${clientId}`, { status: newStatus });
  }
}
