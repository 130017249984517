import {
  CreditData,
  CreditProfile,
  MarginCreditAudit,
  ClientMarginCreditUsageReport,
  PaginatedQuery,
  PaginatedResponse,
  standardiseQuerySort,
  VersionedObject,
  CreditMarginCallAgreement,
} from '../models';
import { HttpBasedService, HttpService, HttpOptions } from 'ah-requests';

export class CreditService extends HttpBasedService {
  constructor(http: HttpService, protected baseUrl: string, group = 'creditService') {
    super(http, {
      options: {
        errors: { messageDefaults: { group } },
      },
    });
  }

  public listCreditProfiles(query: PaginatedQuery, options?: HttpOptions<PaginatedResponse<CreditProfile>>) {
    return this.get<PaginatedResponse<CreditProfile>>(`${this.baseUrl}credit/profiles`, {
      axiosConfig: {
        params: standardiseQuerySort(query),
      },
      options,
    });
  }

  public getCreditProfile(clientId: string, options?: HttpOptions<CreditProfile>) {
    return this.get<CreditProfile>(`${this.baseUrl}credit/profiles/${clientId}`, {
      options,
    });
  }

  public getClientMarginCreditGlobalUsageReport(
    partnerId: string,
    options?: HttpOptions<ClientMarginCreditUsageReport>
  ) {
    return this.get<ClientMarginCreditUsageReport>(`${this.baseUrl}credit/usage-reports/${partnerId}`, { options });
  }

  public createCreditProfile(profileData: Partial<CreditData>) {
    return this.post<VersionedObject>(`${this.baseUrl}credit/profiles`, profileData);
  }

  public createCreditMarginCallAgreement(marginCallAgreementData: CreditMarginCallAgreement) {
    return this.post<CreditMarginCallAgreement>(`${this.baseUrl}credit/margin-call-agreement`, marginCallAgreementData);
  }

  public updateCreditProfile(profileData: Partial<CreditData>) {
    return this.put<VersionedObject>(`${this.baseUrl}credit/profiles/${profileData.clientId}`, profileData);
  }

  public updateMarginCallAgreement(agreementId: string) {
    return this.put<VersionedObject>(`${this.baseUrl}credit/margin-call-agreement/${agreementId}/sign`);
  }

  public listMarginCreditAuditRecords(
    params: PaginatedQuery,
    options?: HttpOptions<PaginatedResponse<MarginCreditAudit>>
  ) {
    return this.get<PaginatedResponse<MarginCreditAudit>>(`${this.baseUrl}credit/audit-records`, {
      axiosConfig: {
        params: standardiseQuerySort(params),
      },
      options,
    });
  }
}
