<template>
  <DynamicForm :form="v$.timeFrameForm" @form-event="onDateChange" />
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { minDate, maxDate, date, ifTest, requiredIfWithContext } from 'ah-common-lib/src/form/validators';
import { Validators } from 'ah-common-lib/src/form';
import { oneYearFromNow } from 'ah-common-lib/src/helpers/time';
import { timeFramesTranslations, TimeFrames } from 'ah-common-lib/src/constants/timeframes';
import { makeFormValidation, makeFormModel, setState, updateModel } from 'ah-common-lib/src/form/helpers';
import { selectField, dateField } from 'ah-common-lib/src/form/models';
import { HedgingInstruments, NonTradeableDays } from 'ah-api-gateways';
import { FormEvent } from 'ah-common-lib/src/form/interfaces';
import { TimeFrameDate } from 'ah-trades/src/models/date';
import useVuelidate, { Validation } from '@vuelidate/core';

const settlementDateFM = () =>
  makeFormModel({
    name: 'timeFrameForm',
    title: '',
    fieldType: 'form',
    fields: [
      selectField('targetTimeFrame', '', timeFramesTranslations, {
        placeholder: 'Execute immediately',
        clearable: true,
        required: false,
      }),
      dateField(
        'targetDate',
        '',
        {
          fieldType: 'date',
          hidden: (model: any) => model.$parent().targetTimeFrame !== TimeFrames.OTHER,
          defaultValue: null,
        },
        {
          date: ifTest(date, (val) => val instanceof Date),
          required: ifTest(
            requiredIfWithContext((form) => form.targetTimeFrame === TimeFrames.OTHER),
            (a) => !(a instanceof Date)
          ),
          minDate: minDate(),
          maxDate: maxDate(oneYearFromNow()),
          disallowedDate: Validators.disallowStateDates('targetDate'),
        }
      ),
    ],
  });

@Component({
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations: {
    timeFrameForm: makeFormValidation(settlementDateFM()),
  },
})
export default class TimeFrameInput extends Vue {
  @Prop({ default: 'FX_FORWARD' }) hedgingInstrument!: HedgingInstruments;

  @Prop({ default: null }) tradeDirection?: string | null;

  private v$!: Validation;

  timeFrameForm = settlementDateFM();

  @Prop({ default: null }) timeFrame?: TimeFrameDate | null;

  @Watch('timeFrame', { immediate: true })
  onTimeFrameChange() {
    if (this.timeFrame) {
      updateModel(this.timeFrameForm, this.timeFrame);
    }
  }

  @Watch('tradeDirection')
  getNonTradeableDays() {
    if (this.tradeDirection) {
      this.$services.pricingEngine
        .getNonTradeableDays(this.hedgingInstrument, this.tradeDirection)
        .subscribe((result: NonTradeableDays) => {
          const targetDate = this.timeFrameForm.$fields.find((f) => f.$name === 'targetDate');
          setState(targetDate!, 'disallowedDates', [...result.holidays, ...result.weekend]);
        });
    }
  }

  onDateChange(formEvent: FormEvent) {
    if (formEvent.event === 'form-field-set-value') {
      this.$emit('update:timeFrame', {
        targetDate: this.timeFrameForm.targetDate,
        targetTimeFrame: this.timeFrameForm.targetTimeFrame,
      });
    }
  }
}
</script>
<style lang="scss" scoped></style>
