<template>
  <div class="app-help-button-holder">
    <VButton
      blurOnClick
      @click="goToHelpLink"
      v-if="helpLink"
      class="btn-stroked need-help"
      :id="`${uid}-app-help-button`"
    >
      NEED HELP?
    </VButton>
    <BTooltip :target="`${uid}-app-help-button`" triggers="hover" boundary="window" custom-class="info-tooltip">
      <p class="mb-0 tooltip-text">
        Click on the button to see our FAQs page.<br />
        <template v-if="helpEmail">
          If you don’t find what you’re looking for please
          <a target="_blank" :href="`mailto:${helpEmail}`">send us an e-mail</a> and we’ll get back to you as soon as
          possible.
        </template>
      </p>
    </BTooltip>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { generateUID } from 'ah-common-lib/src/helpers/uuid';

@Component
export default class NeedHelpButton extends Vue {
  private uid = generateUID(6);

  get helpLink() {
    return this.$theme.val?.helpURL;
  }

  get helpEmail() {
    return this.$theme.val?.helpEmail;
  }

  goToHelpLink() {
    window.open(this.helpLink, '_blank');
  }
}
</script>
<style lang="scss" scoped>
.app-help-button-holder {
  display: flex;
  align-items: center;
}

.need-help {
  display: inline-block;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 0.9em;
  height: 3em;
  font-weight: $font-weight-bold;
  font-size: $font-size-xxs;
  border-radius: 2.2em;
  margin: 0.7em;

  @include themedTextColor($color-primary, $color-primary);
}

.tooltip-text {
  max-width: 20rem;
}
</style>
