export interface Wallet {
  id: string;
  /**
   * Client ID of Wallet. Only visible in client wallets - its absence signifies this is a Partner's wallet.
   */
  clientId?: string;
  /**
   * Partner ID of Wallet. Only visible in admin endpoints.
   * It signifies either the Partner who owns the Wallet, or the Partner parent of the Client who owns the wallet, if clientId is set.
   */
  partnerId?: string;
  /**
   * Wallet currency
   */
  currency: string;
  /**
   * Calculated balance for the wallet
   *
   * This will EXCLUDE all pending/in-transit outgoing funds, and INCLUDE any incoming funds
   */
  balance: number;
  /**
   * Calculated "movable" balance for the wallet (available for trades)
   *
   * This will EXCLUDE all pending/in-transit outgoing funds, but NOT INCLUDE any incoming funds
   */
  availableBalance: number;
  /**
   * How much of the wallet is in collateral. Not counted for balances.
   */
  collateralBalance: number;
  /**
   * In-transit outgoing funds
   */
  debitInTransit: number;
  /**
   * In-transit incoming funds
   */
  creditInTransit: number;
  /**
   * Pending incoming funds
   */
  pendingCredit: number;
  /**
   * Pending outgoing funds
   */
  pendingDebit: number;
  /**
   * Sum of outgoing funds (`debitInTransit` + `pendingDebit`)
   */
  outgoingBalance: number;
  /**
   * Sum of incoming funds (`creditInTransit` + `pendingCredit`)
   */
  incomingBalance: number;
  /**
   * Wallet type
   */
  walletType: WalletType;
  name: string;
  updatedAt: string;
  updatedBy: string;
  createdAt: string;
  createdBy: string;
  tradeBuyAmountInTransit: number;
  tradeSellAmountInTransit: number;
}

export enum WalletType {
  TRADING = 'TRADING',
  BANKING = 'BANKING',
}

export enum WalletFundingPaymentType {
  PRIORITY = 'PRIORITY',
  REGULAR = 'REGULAR',
}

export interface WalletFundingDetails {
  iban?: string;
  accountNumber?: string;
  accountHolderName: string;
  bankName: string;
  bankAddress: string;
  bankCountry: string;
  paymentType: WalletFundingPaymentType;
  paymentRail: string;
  routingCode: string;
  routingCodeType: string;
}
