<script setup lang="ts">
import FormattedCurrency from './FormattedCurrency.vue';
import SimpleDoughnutChart from 'ah-reports/src/components/SimpleDoughnutChart.vue';
import { ClientRiskCollateralProfile } from 'ah-api-gateways';
import { FilledChartColorOptions } from 'ah-reports';
import { PostedCollateralNotionalDataType } from './interfaces';
import { VCol, VRow } from 'ah-common-lib/src/common/components';
import { computed, PropType } from 'vue';
import { darkGreen, lightGreen } from 'ah-trades/src/helpers/chartConfig';

const props = defineProps({
  collateralProfileData: { type: Object as PropType<ClientRiskCollateralProfile>, required: true },
});

const notionalData = computed<PostedCollateralNotionalDataType>(() => ({
  eur: props.collateralProfileData.eurPostedCollateral,
  eurGbpEquivalent: props.collateralProfileData.eurPostedCollateralCurrencyEquivalent,
  gbp: props.collateralProfileData.gbpPostedCollateral,
  gbpGbpEquivalent: props.collateralProfileData.gbpPostedCollateralCurrencyEquivalent,
  usd: props.collateralProfileData.usdPostedCollateral,
  usdGbpEquivalent: props.collateralProfileData.usdPostedCollateralCurrencyEquivalent,
  totalGbpEquivalent: props.collateralProfileData.totalPostedCollateral,
}));

const chartColors: FilledChartColorOptions[] = [darkGreen, lightGreen].map((color) => ({
  backgroundColor: color,
  borderColor: color,
  hoverBackgroundColor: color,
  hoverBorderColor: color,
}));

const usageInfo = computed(() => [
  { label: 'IM Requirement', value: props.collateralProfileData.totalInitialMarginFunding },
  { label: 'VM Requirement', value: props.collateralProfileData.variationMarginAmountRequired },
  { label: 'Total', value: props.collateralProfileData.totalCollateralInUse },
]);

const totalAvailableCollateral = computed<number>(() => props.collateralProfileData.availableCollateral ?? '');
</script>

<template>
  <div class="posted-collateral-section">
    <VRow>
      <VCol class="section-margin" lg="4" sm="12">
        <VRow class="notional-headers">
          <VCol lg="2" sm="2" />
          <VCol lg="5" sm="4">Notional Amount</VCol>
          <VCol lg="5" sm="4">GBP Equivalent</VCol>
        </VRow>
        <VRow alignV="center">
          <VCol class="bold" lg="2" sm="2">EUR</VCol>
          <VCol class="themed" lg="5" sm="4"><FormattedCurrency :value="notionalData.eur" /></VCol>
          <VCol class="themed" lg="5" sm="4"><FormattedCurrency :value="notionalData.eurGbpEquivalent" /></VCol>
        </VRow>
        <VRow alignV="center">
          <VCol class="bold" lg="2" sm="2">GBP</VCol>
          <VCol class="themed" lg="5" sm="4"><FormattedCurrency :value="notionalData.gbp" /></VCol>
          <VCol class="themed" lg="5" sm="4"><FormattedCurrency :value="notionalData.gbpGbpEquivalent" /></VCol>
        </VRow>
        <VRow alignV="center">
          <VCol class="bold" lg="2" sm="2">USD</VCol>
          <VCol class="themed" lg="5" sm="4"><FormattedCurrency :value="notionalData.usd" /></VCol>
          <VCol class="themed" lg="5" sm="4"><FormattedCurrency :value="notionalData.usdGbpEquivalent" /></VCol>
        </VRow>
        <VRow alignV="center">
          <VCol class="text-center bold" lg="7" sm="6">GBP Equivalent Total</VCol>
          <VCol class="themed" lg="5" sm="4"><FormattedCurrency :value="notionalData.totalGbpEquivalent" /></VCol>
        </VRow>
      </VCol>
      <VCol class="section-margin" lg="5" sm="12">
        <div class="d-flex chart-section">
          <SimpleDoughnutChart
            :colors="chartColors"
            :fractions="[
              collateralProfileData.totalCollateralInUse,
              collateralProfileData.totalPostedCollateralRemaining,
            ]"
          />
          <div class="chart-info">
            <h1>Usage</h1>
            <p v-for="item in usageInfo" :key="item.label">
              {{ item.label }}:
              <strong>
                {{ collateralProfileData.currency }}
                <FormattedCurrency :value="item.value" :mainFontSize="16" :decimalFontSize="14" />
              </strong>
            </p>
          </div>
        </div>
      </VCol>
      <VCol lg="3" sm="12">
        <h1 class="mb-0">Available Collateral</h1>
        <div class="bold">
          <FormattedCurrency :value="totalAvailableCollateral" :mainFontSize="44" :decimalFontSize="28" />
        </div>
        <div class="themed">GBP Equivalent Total</div>
      </VCol>
    </VRow>
  </div>
</template>

<style lang="scss" scoped>
.posted-collateral-section {
  padding: 1rem;

  h1 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  .notional-headers,
  .themed,
  .bold {
    font-weight: $font-weight-bold;
  }

  .notional-headers {
    padding-bottom: 0.5rem;
  }

  .themed {
    @include themedTextColor($color-primary, $color-dark-primary);
  }

  .chart-section {
    h1 {
      margin-bottom: 1rem;
    }
    .chart-info {
      margin-left: 2rem;
      p {
        margin-bottom: 0.5rem;
      }
    }
  }

  @include upToResolution(1449px) {
    .section-margin {
      margin-bottom: 3em;
    }
  }

  ::v-deep {
    .doughnut-chart {
      width: 160px;
      height: 160px;
    }
  }
}
</style>
