<template>
  <CommonUpdatedDate v-bind="$attrs" v-on="$listeners" />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import CommonUpdatedDate from 'ah-common-lib/src/common/components/time/UpdatedDate.vue';

@Component({
  components: { CommonUpdatedDate },
})
export default class UpdatedDate extends Vue {}
</script>
<style lang="scss" scoped>
::v-deep .refresh-icon {
  @include themedTextColor($color-primary);
}
</style>
