<template>
  <BoxGridBlock :loadingOverlayProps="{ loading: requestManager.anyPending }" useLoadingOverlay overlayType="simple">
    <template v-if="individual">
      <div class="block-header text-right">
        <VButton
          blurOnClick
          v-if="(!individual.user || !individual.user.id) && isApproved"
          @click="reinviteIndividual"
          :loading="requestManager.requestStates.reinviteIndividual === 'pending'"
          class="btn-stroked"
        >
          Resend Invitation
        </VButton>
        <ConfirmModal modalText="Permanently delete this team member?" @ok="deleteIndividual" v-slot="{ showModal }">
          <VButton blurOnClick class="delete-button btn-danger-secondary" @click="showModal">Delete</VButton>
        </ConfirmModal>
      </div>
      <div class="d-flex justify-content-between">
        <h3>{{ individual.firstName }} {{ individual.lastName }}</h3>
      </div>
      <div class="info">
        <DataRow class="mb-3 individual-groups" label="Groups">
          <span class="text-secondary" v-if="requestManager.requestStates.loadIndividualGroups === 'pending'">
            Loading...
          </span>
          <span class="text-secondary" v-else-if="individualGroups.length === 0">Not a member of any groups</span>
          <span v-else>
            <span v-for="(group, index) in individualGroups" :key="group.id">
              {{ group.name }}<span v-if="index < individualGroups.length - 1">,</span>
            </span>
          </span>
        </DataRow>
        <DataRow class="mb-3 individual-email" label="Email">
          {{ individual.email }}
        </DataRow>
        <DataRow class="mb-3 individual-phone" label="Mobile Number" v-if="individual.phoneNumber">
          {{ individual.phoneNumber }}
        </DataRow>
      </div>
      <PermissionsEditor
        v-if="individual.user.id"
        targetType="user"
        :targetId="individual.user.id"
        :role="individual.type"
      >
        <div class="d-flex justify-content-between" v-if="isUser">
          <div class="permission-description">
            <h4>Admin</h4>
            <p class="text-secondary">
              Admins can add, delete and change permissions of other members and admins.<br /><span v-if="isLoggedUser">
                <b>This cannot be edited for your own Individual.</b>
              </span>
            </p>
          </div>
          <div class="permission-switch">
            <BFormCheckbox
              :checked="isAdmin"
              name="check-button"
              :disabled="isLoggedUser || !individual.user || !individual.user.id"
              switch
              @change="onAdminChange"
            />
          </div>
        </div>
      </PermissionsEditor>
    </template>
  </BoxGridBlock>
</template>

<script lang="ts">
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import {
  Individual,
  IndividualGroup,
  IndividualType,
  clientUserIndividualTypes,
  partnerUserIndividualTypes,
  ComplianceStatus,
} from 'ah-api-gateways';
import PermissionsEditor from '../settings/PermissionsEditor.vue';
import { mergeMap } from 'rxjs/operators';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { waitForEntityDeletion, waitForCQRSEntityChange } from 'ah-requests';
import { useAuthStore } from '@/app/store/authStore';

/**
 * Information and actions on an individual
 *
 * Emits:
 * - individual-deleted, with individual id
 * - individual-reinvited, with individual id
 * - individual-updated, with individual object
 */
@Component({
  components: {
    PermissionsEditor,
  },
})
export default class IndividualInfo extends Mixins(WithRequestManager) {
  @Prop({ required: true }) individualId!: string;

  private individual: Individual | null = null;

  private individualGroups: IndividualGroup[] = [];

  beforeMount() {
    this.authStore.loadComplianceCase();
  }

  requestManagerConfig = {
    exposeToParent: ['loadIndividual', 'loadIndividualGroups', 'updateIndividual', 'reinviteIndividual'],
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'loadIndividual') {
      this.onIndividualIdChange();
    } else if (k === 'loadIndividualGroups') {
      this.onIndividualChange();
    } else if (k === 'reinviteIndividual') {
      this.reinviteIndividual();
    } else if (k === `deleteIndividual-${this.individualId}`) {
      this.deleteIndividual();
    }
  }

  get authStore() {
    return useAuthStore();
  }

  @Watch('individualId', { immediate: true })
  onIndividualIdChange() {
    this.requestManager
      .cancelAndNew('loadIndividual', this.$services.individual.getIndividual(this.individualId))
      .subscribe((individual) => {
        this.individual = individual;
      });
  }

  @Watch('individual', { immediate: true })
  onIndividualChange() {
    if (this.individual) {
      this.requestManager
        .cancelAndNew(
          'loadIndividualGroups',
          this.groupService.listGroups({ individualId: [this.individualId], size: 200 }, this.groupParentId)
        )
        .subscribe((groups) => {
          this.individualGroups = groups.list;
        });
    }
  }

  onAdminChange(value: boolean) {
    if (this.individual) {
      if (this.individual.client) {
        this.individual.type = value ? IndividualType.CLIENT_ADMIN : IndividualType.CLIENT_INDIVIDUAL;
      } else {
        this.individual.type = value ? IndividualType.PARTNER_ADMIN : IndividualType.PARTNER_AGENT;
      }
      this.saveIndividualType(this.individual.type);
    }
  }

  get isApproved() {
    return this.authStore.complianceStatus === ComplianceStatus.APPROVED;
  }

  get isClientUser() {
    return this.individual && clientUserIndividualTypes.includes(this.individual.type);
  }

  get isAdmin() {
    return (
      this.individual && [IndividualType.PARTNER_ADMIN, IndividualType.CLIENT_ADMIN].includes(this.individual.type)
    );
  }

  get isUser() {
    return (
      this.individual?.type &&
      [...clientUserIndividualTypes, ...partnerUserIndividualTypes].includes(this.individual?.type)
    );
  }

  get groupService() {
    if (this.individual?.client) {
      return this.$services.clientGroup;
    } else {
      return this.$services.partnerGroup;
    }
  }

  get groupParentId() {
    if (this.individual?.client) {
      return this.individual!.client!.id;
    } else {
      // Partner group endpoints do not use id in path, when called from a partner user
      return undefined;
    }
  }

  get isLoggedUser() {
    return this.individual?.id === this.authStore.loggedInIdentity?.id;
  }

  saveIndividualType(type: IndividualType) {
    this.requestManager
      .new(
        'updateIndividualType',
        this.$services.individual
          .updateIndividualType(this.individualId, type)
          .pipe(
            mergeMap((idEntity) =>
              waitForCQRSEntityChange(idEntity, () => this.$services.individual.getIndividual(idEntity.id))
            )
          )
      )
      .subscribe((individual) => {
        this.individual = individual;
        this.$emit('individual-updated', this.individual);
      });
  }

  reinviteIndividual() {
    this.requestManager
      .currentOrNew(`reinviteIndividual`, this.$services.individual.reinviteIndividual(this.individualId))
      .subscribe(() => {
        this.$emit('individual-reinvited', this.individualId);
        this.$toast.success('User reinvited. They will receive a new email to create their account.');
      });
  }

  deleteIndividual() {
    this.requestManager
      .currentOrNew(
        `deleteIndividual-${this.individualId}`,
        this.$services.individual
          .deleteIndividual(this.individualId, { errors: { silent: true } })
          .pipe(
            mergeMap(() =>
              waitForEntityDeletion(() =>
                this.$services.individual.getIndividual(this.individualId, { errors: { silent: true } })
              )
            )
          )
      )
      .subscribe(
        () => {
          this.$emit('individual-deleted', this.individualId);
          this.$toast.success('User deleted.');
        },
        (e) => {
          if (e.response?.status === 409 || e.response?.status === 400) {
            this.$toast.info(e.response.data.message);
          } else {
            this.$toast.error('An unexpected problem has occurred. Please try again later.');
          }
        }
      );
  }
}
</script>
<style lang="scss" scoped>
.block-header {
  height: 4rem;
}

.delete-button {
  margin-left: 1rem;
}
</style>
