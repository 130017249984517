<template>
  <svg class="loading-icon" width="22" height="4" viewBox="0 0 22 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle class="circle-1" cx="3" cy="2" r="2" fill="white" />
    <circle class="circle-2" cx="11" cy="2" r="2" fill="white" />
    <circle class="circle-3" cx="19" cy="2" r="2" fill="white" />
  </svg>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
