<template>
  <div x-test-name="personal-information-form">
    <h2>Personal Details</h2>
    <ValidatedForm :fm="accountAccessFM" :validation.sync="accountAccessValidation" @form-event="onFormEvent">
      <template #accountAccess.phoneNumber:before>
        <h3 class="current-account-heading">Current Address</h3>
        <RegistrationAddressForm
          :address="currentAddress"
          @update:address="onAddressChange"
          :validation.sync="addressValidation"
          :isCountryEditable="false"
          :includeNationality="true"
        />
        <PreviousAddressesForms
          v-if="isUKResident"
          :currentAddressResidenceDate.sync="currentAddress.residingFrom"
          :previousAddresses.sync="previousAddresses"
          :validation.sync="addressHistoryValidation"
          :showErrorMessage="showErrorMessage"
        />
      </template>
      <template #accountAccess.phoneNumber:after>
        <span class="text-muted">
          <ul class="text-small pl-4 my-1">
            <li>Must include country code</li>
          </ul>
          <span>You will need to confirm this number before completing registration.</span>
        </span>
      </template>
      <template #accountAccess.email:after>
        <span class="text-muted">
          <span>You will need to confirm this email before completing registration.</span>
        </span>
      </template>
      <template #accountAccess.password:after>
        <div v-if="accountAccessValidation">
          <SecurePasswordChecker :validator="accountAccessValidation.password" />
        </div>
      </template>
      <template #accountAccess.referenceSelection:label>
        <div class="d-inline-flex w-100">
          Where did you hear about us?
          <div class="ml-auto text-secondary">(Not mandatory)</div>
        </div>
      </template>
    </ValidatedForm>

    <div class="buttons-holder">
      <VButton @click="submit" :loading="requestManager.anyPending" :disabled="isFormInvalid"> Continue </VButton>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Watch, Prop } from 'vue-property-decorator';
import { toDataModel, setState, getChildModel, updateModel } from 'ah-common-lib/src/form/helpers';
import {
  AboutUsReference,
  ClientType,
  UserCreationRequest,
  BaseRegistrationModel,
  aboutUsReferenceOptions,
  AddressHistoryItem,
  CurrentAddressHistoryItem,
  EntityAddressHistory,
  checkAddressHistoryValidity,
} from 'ah-api-gateways';
import { SecurePasswordChecker } from 'ah-common-lib/src/form/components';
import { FormEvent, FormValidation, CompoundValidation } from 'ah-common-lib/src/form/interfaces';
import { useSettingsStore } from '@/app/store/settingsModule';
import { getServices } from '@/app/services';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import RegistrationAddressForm from '../common/RegistrationAddressForm.vue';
import PreviousAddressesForms from '../common/PreviousAddressesForms.vue';
import { accountAccessFM } from '@/app/helpers/registration/forms';
import { cloneDeep } from 'lodash';
import { submitUser } from '@/app/helpers/registration/requests';
import { useFEFeatureFlag } from 'ah-common-stores';
import { useAuthStore } from '@/app/store/authStore';

/**
 * Representative account access form
 *
 * Emits update:historyAddresses event, as Address history must be sent AFTER this component has been used
 * (after account verification)
 */
@Component({
  components: { SecurePasswordChecker, RegistrationAddressForm, PreviousAddressesForms },
  setup() {
    const dobInOnboardingFeatureActive = useFEFeatureFlag('dobInOnboardingFeatureActive');

    return {
      dobInOnboardingFeatureActive,
    };
  },
})
export default class PersonalInformationForm extends WithRequestManager {
  @Prop({ required: true }) model!: Partial<BaseRegistrationModel>;

  private dobInOnboardingFeatureActive!: boolean;

  private accountAccessFM = accountAccessFM({
    isCompanyApplicant: false,
    useDOB: this.dobInOnboardingFeatureActive,
  });

  private accountAccessValidation: FormValidation | null = null;

  // We set `residingFrom` to ensure it is set as reactive from component setup
  private currentAddress: CurrentAddressHistoryItem = { residingFrom: '' } as any as CurrentAddressHistoryItem;

  private countryValidation: FormValidation | null = null;

  private addressValidation: FormValidation | null = null;

  private addressHistoryValidation: CompoundValidation | null = null;

  private previousAddresses: AddressHistoryItem[] = [];

  private showErrorMessage = false;

  get settingsStore() {
    return useSettingsStore();
  }

  get authStore() {
    return useAuthStore();
  }

  get getServices() {
    return getServices();
  }

  get featureFlagStore() {
    return useSettingsStore();
  }

  get isUKResident() {
    return this.model.address?.countryCode === 'GB';
  }

  checkAddressHistoryValidity() {
    if (!this.isUKResident) {
      return true;
    }
    const addressHistoryValidity = checkAddressHistoryValidity({
      currentAddress: this.currentAddress || {},
      previousAddresses: this.previousAddresses,
    });

    return addressHistoryValidity.age && addressHistoryValidity.gaps;
  }

  get calculatedModel(): Partial<BaseRegistrationModel> {
    const { referenceDescription, referenceSelection, ...applicant } = toDataModel(this.accountAccessFM);
    return {
      ...this.model,
      reference: referenceSelection === AboutUsReference.OTHER ? referenceDescription : referenceSelection,
      applicant: {
        ...this.model.applicant,
        ...applicant,
      },
      address: { ...this.model.address!, ...this.currentAddress },
    };
  }

  get historyAddresses(): Partial<EntityAddressHistory> {
    return {
      currentAddress: this.currentAddress,
      previousAddresses: this.previousAddresses,
    };
  }

  get userCreationRequest(): UserCreationRequest | null {
    if (!this.currentAddress) return null;
    let { ...address } = this.currentAddress;

    return {
      applicant: this.calculatedModel.applicant!,
      registrationData: {
        ...(this.dobInOnboardingFeatureActive ? { birthDate: this.calculatedModel.applicant!.birthDate } : {}),
        questionnaireAnswer: this.calculatedModel.reference?.length ? this.calculatedModel.reference : undefined,
        address,
        nationality: this.calculatedModel.address!.nationality!,
      },
    };
  }

  get isFormInvalid() {
    return (
      !!this.accountAccessValidation?.$invalid ||
      !!this.countryValidation?.$invalid ||
      !!this.addressValidation?.$invalid ||
      !!this.addressHistoryValidation?.$invalid
    );
  }

  emitUpdate() {
    this.$emit('update:model', this.calculatedModel);
  }

  onAddressChange(address: CurrentAddressHistoryItem) {
    this.currentAddress = {
      ...this.model.address,
      ...address,
      countryCode: this.model.address?.countryCode!,
    };
    delete (this.currentAddress as any).countryName;
    this.emitUpdate();
  }

  onFormEvent(formEvent: FormEvent) {
    if (formEvent.event === 'form-field-set-value') {
      setState(this.accountAccessFM, 'errors', [], true);

      if (formEvent.model.$name === 'referenceSelection') {
        this.checkReferenceDescriptionDisplay();
        this.accountAccessFM.referenceDescription = '';
        if (this.accountAccessValidation?.reference) {
          this.accountAccessValidation.reference.$reset();
        }
      }
      this.emitUpdate();
    }
  }

  checkReferenceDescriptionDisplay() {
    const field = getChildModel(this.accountAccessFM, 'referenceDescription')!;
    setState(field, 'hidden', this.accountAccessFM.referenceSelection !== AboutUsReference.OTHER);
    setState(field, 'required', this.accountAccessFM.referenceSelection === AboutUsReference.OTHER);
  }

  submit() {
    if (!this.checkAddressHistoryValidity()) {
      this.$toast.error(
        'Please review address information. UK residents must provide addresses spanning back 3 years, with no gaps.'
      );
      this.showErrorMessage = true;
      return;
    }
    this.showErrorMessage = false;

    this.requestManager
      .currentOrNew(
        'submitPersonalDetails',
        submitUser(this.model.clientType ?? ClientType.INDIVIDUAL, this.userCreationRequest!, this.accountAccessFM)
      )
      .subscribe(
        () => {
          this.$emit('update:historyAddresses', this.historyAddresses);
          this.$emit('proceed');
        },
        () => {
          this.$emit('failure');
        }
      );
  }

  @Watch('dobInOnboardingFeatureActive')
  @Watch('model', { immediate: true })
  onModelChange() {
    this.accountAccessFM = accountAccessFM({ isCompanyApplicant: false, useDOB: this.dobInOnboardingFeatureActive });
    updateModel(this.accountAccessFM, { ...this.model?.applicant });
    this.currentAddress = cloneDeep(this.model?.address ?? ({ residingFrom: '' } as any));

    if (typeof this.model?.reference === 'string') {
      if (
        aboutUsReferenceOptions
          .map((obj) => obj.value)
          .filter((v) => v !== AboutUsReference.OTHER)
          .includes(this.model.reference)
      ) {
        this.accountAccessFM.referenceSelection = this.model.reference;
      } else {
        this.accountAccessFM.referenceSelection = AboutUsReference.OTHER;
        this.accountAccessFM.referenceDescription = this.model.reference;
      }
      this.checkReferenceDescriptionDisplay();
    }
  }
}
</script>
<style lang="scss" scoped>
.buttons-holder {
  margin: 2rem 0 5rem;
  padding: 0 10%;
  .btn {
    width: 100%;
  }
}

::v-deep.emir-classification-tooltip {
  .tooltip-inner {
    max-width: 400px;
    @include themedBackgroundColor($color-primary);
  }
  .arrow::before {
    @include themedPropColor('border-top-color', $color-primary);
  }
}
.current-account-heading {
  font-size: $base-font-size;
  font-weight: $font-weight-semibold;
  @include themedTextColor($color-primary);
}
</style>
