<script lang="ts" setup>
import { BModal } from 'bootstrap-vue';
import { VanillaPriceResponse } from 'ah-api-gateways';
import OptionTermsheetViewer from 'ah-trades/src/components/OptionTermsheetViewer.vue';
import { watch, ref } from 'vue';

const modal = ref<InstanceType<typeof BModal> | null>(null);

defineProps<{ solution: VanillaPriceResponse }>();

const modalShown = ref<boolean>(false);

const emit = defineEmits<{
  (e: 'update:pause', value: boolean): void;
}>();

function show() {
  modalShown.value = true;
}

watch(modalShown, () => emit('update:pause', modalShown.value), { immediate: true });

defineExpose({ show });
</script>

<template>
  <BModal
    ref="modal"
    size="xl"
    modal-class="modal-full-height modal-preview-termsheet px-sm-3 px-md-0"
    centered
    title="Preview termsheet"
    v-on="$listeners"
    v-model="modalShown"
  >
    <OptionTermsheetViewer :solution="solution" />
    <template v-slot:modal-footer="{ cancel }">
      <div class="buttons">
        <VButton class="btn-secondary" @click="cancel()"> Close </VButton>
      </div>
    </template>
    <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </BModal>
</template>

<style lang="scss" scoped>
.buttons {
  text-align: right;
  width: 100%;
}

::v-deep .modal-preview-termsheet .modal-body {
  // Fix for safari bug with height calculation of <object> height
  height: 100%;
}
</style>
