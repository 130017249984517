<template>
  <div class="form-item-wrapper mb-3">
    <ValidatedForm
      :class="unit === 'basisPoints' ? 'no-title' : ''"
      :fm="formModel"
      @update:validation="$emit('update:validation', $event)"
    />
    <span v-if="unit === 'percentage'" class="text-small text-secondary"> min: {{ min }}%, max: {{ max }}%</span>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { makeFormModel, toDataModel, updateModel, batchSetState } from 'ah-common-lib/src/form/helpers';
import { FxMargins, Client, HedgingInstrumentsSettingsProps } from 'ah-api-gateways';
import { basisPointsField } from 'ah-common-lib/src/form/models';
import { FormModel } from 'ah-common-lib/src/form/interfaces';

@Component
export default class ClientProfitMarginForm extends Vue {
  @Prop({ required: true }) client!: Client;

  @Prop({ required: true }) model!: FxMargins;

  @Prop({ required: true }) profitKey!: HedgingInstrumentsSettingsProps;

  @Prop({ required: true }) unit!: string;

  @Prop({ required: false }) title?: string;

  @Prop({ required: true }) minValue!: number;

  @Prop({ required: true }) maxValue!: number;

  private formModel: FormModel | null = null;

  created() {
    this.formModel = makeFormModel({
      name: 'profits',
      fieldType: 'form',
      fields: [basisPointsField('profitMargin', 'Profit Markup', { minValue: this.minValue, maxValue: this.maxValue })],
    });

    this.updateForm();
    this.onProfitMarginChange();
  }

  mounted() {
    if (this.formModel) {
      batchSetState(this.formModel, 'unit', ['profitMargin'], this.unit);
      batchSetState(this.formModel, 'title', ['profitMargin'], this.title ? this.title : '');
    }
  }

  @Watch('model', { deep: true })
  updateForm() {
    if (this.formModel && this.model) {
      updateModel(this.formModel, this.model[this.profitKey]);
      batchSetState(this.formModel, 'minValue', ['profitMargin'], this.model.minProfit);
      batchSetState(this.formModel, 'maxValue', ['profitMargin'], this.model.maxProfit);
    }
  }

  @Watch('formModel', { deep: true })
  onProfitMarginChange() {
    if (this.formModel) {
      this.$emit('update:model', toDataModel(this.formModel));
    }
  }

  get existsTitle() {
    return this.title !== undefined;
  }

  get min() {
    return this.minValue ? this.minValue / 100 : 0;
  }

  get max() {
    return this.maxValue ? this.maxValue / 100 : 0;
  }
}
</script>
<style lang="scss" scoped>
.form-item-wrapper {
  position: relative;
  .no-title {
    ::v-deep label {
      opacity: 0;
    }
  }
  .text-small {
    bottom: -0.5rem;
    left: 0;
    position: absolute;
  }
}
</style>
