<script setup lang="ts">
import { computed } from 'vue';
import { Notification, NotificationType, NotificationAlertType } from 'ah-api-gateways';
import NotificationCard from './NotificationCard.vue';
import { formatDistance, isSameDay, isToday, isYesterday } from 'date-fns';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useNotificationState } from '../..';

const props = withDefaults(
  defineProps<{
    /**
     * Max items to show in list
     *
     * If 0, no maximum is applied
     */
    maxItems?: number;
    withUpdatesNotification?: boolean | string;
    simple?: boolean | string;
  }>(),
  {
    maxItems: 0,
    withUpdatesNotification: false,
    simple: false,
  }
);

const notificationState = useNotificationState();

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: onRetryFromParentManager,
});

const showNewUpdatesNotification = computed(() => {
  return (
    props.withUpdatesNotification !== false && notificationState.store.useNotificationsStore().updateUnreadCount > 0
  );
});

const hasMoreNotifications = computed(() => {
  return (
    notificationState.store.useNotificationsStore().allCount > notifications.value.length &&
    (props.maxItems === 0 || props.maxItems > notifications.value.length)
  );
});

const notifications = computed(() => {
  const out: Notification[] = [];

  if (showNewUpdatesNotification.value) {
    out.push({
      id: 'updateNotification',
      title: 'There are have been updates to X-Hedge',
      message: 'We have updated X-Hedge to improve your experience.',
      type: NotificationType.SOFTWARE_IMPROVEMENTS,
      read: true,
      createdAt: new Date().toISOString(),
      alertType: NotificationAlertType.REGULAR,
    });
  }

  out.push(
    ...(props.maxItems > 0
      ? notificationState.store
          .useNotificationsStore()
          .notifications.slice(0, showNewUpdatesNotification.value ? props.maxItems - 1 : props.maxItems)
      : notificationState.store.useNotificationsStore().notifications)
  );

  return out;
});

const noNotifications = computed(() => !notifications.value || notifications.value.length === 0);

function onRetryFromParentManager(k: string) {
  if (k === 'loadNotifications') {
    loadMoreItems();
  }
}

function viewResource(item: Notification) {
  notificationState.viewNotificationResource && notificationState.viewNotificationResource(item);
}

function onInfiniteLoad(intersection: IntersectionObserverEntry) {
  if (intersection.isIntersecting) {
    loadMoreItems();
  }
}

function isSameDayAs(a: Notification, b: Notification) {
  return isSameDay(new Date(a.createdAt), new Date(b.createdAt));
}

function loadMoreItems() {
  requestManager.manager.newPromise(
    'loadNotifications',
    notificationState.store.useNotificationsStore().loadSidebarNotifications()
  );
}

function markAsRead(notification: Notification) {
  notificationState.store.useNotificationsStore().markAsRead(notification);
}

function formatDate(date: string) {
  if (isToday(new Date(date))) {
    return 'TODAY';
  } else if (isYesterday(new Date(date))) {
    return 'YESTERDAY';
  }

  return formatDistance(new Date(date), new Date(), { addSuffix: true });
}

if (notifications.value.length === 0) {
  loadMoreItems();
}

defineExpose({ noNotifications });
</script>

<template>
  <div class="notifications-list">
    <template v-if="simple !== false">
      <LoadingIcon v-if="noNotifications && requestManager.manager.anyPending" class="loading-icon" />
      <div v-else-if="noNotifications" class="notification-card text-center">
        <slot name="noResults"> No notifications found. </slot>
      </div>
      <ul class="notification-message-box">
        <li class="notification-simple-message" v-for="item in notifications" :key="item.id">
          {{ item.message }}
        </li>
      </ul>
    </template>
    <template v-else>
      <div v-for="(item, index) in notifications" :key="item.id">
        <p v-if="index === 0 || !isSameDayAs(notifications[index - 1], item)" class="date-label text-secondary d-block">
          {{ formatDate(item.createdAt) }}
        </p>
        <NotificationCard :notification="item" @mark-as-read="markAsRead(item)" @view-resource="viewResource">
          <template #actions><slot name="notification-actions" v-bind="{ item }" /></template>
        </NotificationCard>
      </div>
      <div
        v-if="!noNotifications && hasMoreNotifications"
        v-on-intersect="onInfiniteLoad"
        class="loader-wrapper d-flex justify-content-center"
      >
        <LoadingIcon v-if="requestManager.manager.anyPending" class="loading-icon" />
      </div>
      <LoadingIcon v-if="noNotifications && requestManager.manager.anyPending" class="loading-icon" />
      <div v-else-if="noNotifications" class="notification-card card-block text-center">
        <slot name="noResults"> No notifications found. </slot>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.loading-label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4em;
}

.notification-message-box {
  padding-left: 1em;
  .notification-simple-message {
    text-transform: uppercase;
    margin-bottom: 0.6em;
    font-size: $font-size-sm;
  }
}

.loader-wrapper {
  min-height: 5px;
}

.loading-icon {
  width: 1.5em;
  height: 1.5em;
}

.all-read {
  @include themedTextColor($color-primary-light);
  font-size: 0.7em;
  display: inline-flex;
  align-items: center;
  font-weight: 700;
}

.date-label {
  font-size: 0.7em;
  font-weight: 700;
}
</style>
