<template>
  <span>
    <BModal modal-class="side-modal modal-lg" ref="modal" hide-footer>
      <h5 class="modal-title">{{ modalTitle }}</h5>
      <VButton class="btn-danger-secondary remove-alert" @click="removeAlert" v-if="!isNewAlert">Remove Alert</VButton>
      <div class="card-block mb-3">
        <div class="row mt-4">
          <div class="col">
            <label>Currency pair</label>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <CurrencySelectInput :currency="ccy1" @update:currency="onCurrencyChange('ccy1', $event)" />
          </div>
          <div class="col col-trade-direction">
            <span class="trade-direction" @click="swapCurrencies"><IconTrade /></span>
          </div>
          <div class="col-4">
            <CurrencySelectInput :currency="ccy2" @update:currency="onCurrencyChange('ccy2', $event)" />
          </div>
        </div>
        <ValidatedForm :fm="rateAlertForm" class="mt-3">
          <template #rateAlertForm.rateDescription>
            <div class="rate-description">
              <span class="currency-pair">{{ editedRateAlert.currencyPair }}</span>
              <span class="currency-pair-inverted text-secondary" v-if="invertedRate">
                ({{ invertedRate }} {{ ccy2 }}{{ ccy1 }})
              </span>
            </div>
          </template>
        </ValidatedForm>
      </div>
      <div class="chart-box mt-4 mb-4">
        <RateAlertChart :alertRate="rateAlertForm.rate" :currencyPair="editedRateAlert.currencyPair" />
      </div>
      <div class="buttons mt-3">
        <VButton class="btn-secondary mr-2" @click="cancel">Cancel</VButton>
        <VButton class="done-btn" @click="save">Done</VButton>
      </div>
    </BModal>
    <slot v-bind="{ show }" />
  </span>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { selectField, textField, formTitle, nullField } from 'ah-common-lib/src/form/models';
import { RateAlertSetting, RateAlertInequalityType } from 'ah-api-gateways';
import cloneDeep from 'lodash/cloneDeep';
import { isTempUUID } from 'ah-common-lib/src/helpers/uuid';
import { RateAlertChart } from 'ah-reports';
import CurrencySelectInput from 'ah-trades/src/components/currency/CurrencySelectInput.vue';

const rateAlertFM = () =>
  makeFormModel({
    name: 'rateAlertForm',
    fieldType: 'form',
    fields: [
      formTitle('Alert rate', {
        fieldWrapperClass: 'field-group-wrapper col col-12 mb-0',
      }),
      selectField(
        'inequality',
        '',
        [
          {
            label: 'Higher than',
            value: RateAlertInequalityType.HIGHER_THAN,
          },
          {
            label: 'Lower than',
            value: RateAlertInequalityType.LOWER_THAN,
          },
        ],
        {
          fieldWrapperClass: 'col col-4',
        }
      ),
      textField('rate', '', {
        titleTooltip:
          'The desired rate which when reached will trigger the notification.  Please be aware that the notifcation will be sent when the desired rate inclusive of our trade margin is reached.  Rate alerts are for information only and we are not obligated to honour a notification rate in the form of a quote.',
        fieldType: 'number',
        fieldWrapperClass: 'col col-4',
        inputClass: 'form-control text-right',
        required: true,
        min: 0.001,
        max: 20.0,
        step: 0.001,
        valueToInputFn(value: number) {
          if (typeof value === 'number' && !isNaN(value)) {
            return value.toFixed(4);
          }
          return value;
        },
      }),
      nullField('rateDescription', {
        fieldWrapperClass: 'col col-4',
      }),
    ],
  });

@Component({
  components: { CurrencySelectInput, RateAlertChart },
})
export default class RateAlertSettingsEditModal extends Vue {
  $refs!: {
    modal: BModal;
  };

  @Prop({ required: true }) rateAlert!: RateAlertSetting;

  editedRateAlert: RateAlertSetting | null = null;

  rateAlertForm = rateAlertFM();

  @Watch('rateAlert', { immediate: true })
  resetData() {
    this.editedRateAlert = cloneDeep(this.rateAlert);
    this.rateAlertForm.rate = this.editedRateAlert.rate;
    this.rateAlertForm.inequality = this.editedRateAlert.inequality;
  }

  onCurrencyChange(currency: 'ccy1' | 'ccy2', value: string) {
    if (this.editedRateAlert) {
      const ccy1 = currency === 'ccy1' ? value : this.ccy1 === value ? this.ccy2! : this.ccy1!;
      const ccy2 = currency === 'ccy2' ? value : this.ccy2 === value ? this.ccy1! : this.ccy2!;
      this.editedRateAlert.currencyPair = ccy1 + ccy2;
    }
  }

  swapCurrencies() {
    if (this.editedRateAlert) {
      this.editedRateAlert.currencyPair = this.ccy2! + this.ccy1!;
    }
  }

  get isNewAlert() {
    return isTempUUID(this.rateAlert.id);
  }

  get modalTitle() {
    return this.isNewAlert ? 'Add rate alert' : 'Edit rate alert';
  }

  get invertedRate() {
    if (typeof this.rateAlertForm.rate === 'number' && !isNaN(this.rateAlertForm.rate)) {
      return (1 / this.rateAlertForm.rate).toFixed(3);
    }
    return 0;
  }

  get ccy1() {
    return this.editedRateAlert?.currencyPair.substr(0, 3);
  }

  get ccy2() {
    return this.editedRateAlert?.currencyPair.substr(3);
  }

  show() {
    this.$refs.modal.show();
    this.resetData();
  }

  save() {
    this.$emit('update:rateAlert', {
      ...this.editedRateAlert,
      rate: this.rateAlertForm.rate,
      inequality: this.rateAlertForm.inequality,
    });
    this.$refs.modal.hide();
  }

  removeAlert() {
    this.$emit('delete-rate-alert', this.rateAlert);
    this.$refs.modal.hide();
  }

  cancel() {
    this.$refs.modal.hide();
  }
}
</script>
<style lang="scss" scoped>
.rate-description {
  padding: 7px;
}

.col-trade-direction {
  flex-basis: 40px;
  flex-grow: 0;
  text-align: center;
  padding-top: 4px;
}

.trade-direction {
  cursor: pointer;
  background: getColorHSLA($color-primary, 60%, 0.4);
  height: 1.5em;
  width: 1.5em;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  line-height: 1.5em;

  svg {
    width: 70%;
    height: 70%;
    margin: 15%;
    display: block;
  }
}

.button.remove-alert {
  position: absolute;
  top: 0;
  right: $padded-space * 2;
}

.chart-box {
  min-height: 28rem;
}
</style>
