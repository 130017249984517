<script setup lang="ts">
import { BModal } from 'bootstrap-vue/src/components/modal';
import { Trade } from 'ah-api-gateways';
import PaymentTradeDetails from 'ah-payments/src/components/PaymentTradeDetails.vue';
import { reactive, ref } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { usePaymentState } from 'ah-payments';

const props = defineProps({
  tradeId: {
    type: String,
    required: true,
    default: () => '',
  },
  tradeComposedReferenceNumber: {
    type: String,
    required: true,
    default: () => '',
  },
});
const paymentState = usePaymentState();
const requestManager = useRequestManager().manager;
const paymentsTradeDetailsModal = ref<InstanceType<typeof BModal> | null>(null);

const state = reactive({
  trade: null as Trade | null,
});

function show() {
  if (props.tradeId) {
    requestManager
      .sameOrCancelAndNew('getAdminTrade', paymentState.services.trade.getTrade(props.tradeId))
      .subscribe((r) => (state.trade = r));
  }
  paymentsTradeDetailsModal.value?.show();
}

function hide() {
  state.trade = null;
  paymentsTradeDetailsModal.value?.hide();
}
</script>
<template>
  <span>
    <BModal title="Trade Details" modal-class="side-modal" ref="paymentsTradeDetailsModal" hide-footer>
      <div class="destination-form">
        <div class="card-block">
          <label class="text-normal">Original Trade Details</label>
          <PaymentTradeDetails v-if="state.trade" :trade="state.trade" />
        </div>
        <div class="mb-3">
          <VButton class="mr-2" @click="hide"> Close </VButton>
        </div>
      </div>
    </BModal>
    <slot v-bind="{ show }">
      <a blurOnClick @click="show" class="link">{{ tradeComposedReferenceNumber }} </a>
    </slot>
  </span>
</template>
<style lang="scss" scoped>
::v-deep .modal-dialog {
  min-width: 46rem;
  .modal-title,
  .modal-body {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .modal-body {
    .card-block {
      margin-bottom: 1rem;
    }
    .destination-form * {
      font-size: $font-size-sm;
    }
    label {
      font-weight: $font-weight-semibold;

      &.text-normal {
        font-size: $font-size-baseline;
      }
    }
    .field-group-custom.title .field-group-field-label {
      font-weight: $font-weight-semibold;
    }
    .btn {
      width: 6rem;
    }
  }
}
::v-deep .link {
  text-decoration: underline;
}
</style>
