<script lang="ts" setup>
import BeneficiaryListing from './BeneficiaryListing.vue';
import { selectableBeneficiaryTableFields } from '../models/beneficiaryTableFields';
import BeneficiaryEditModal from './BeneficiaryEditModal.vue';
import { AuthorityType, Beneficiary, BeneficiaryCategory, BeneficiaryStatus } from 'ah-api-gateways';
import { computed, reactive, ref, watch } from 'vue';
import { useBeneficiariesState } from 'ah-beneficiaries';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const beneficiaryListing = ref<InstanceType<typeof BeneficiaryListing> | null>(null);

const emit = defineEmits<{
  /**
   * - 'update:beneficiary': payload with beneficiary
   */
  (e: 'update:beneficiary', value: Beneficiary | null | undefined): void;
  /**
   * 'update:beneficiaryQuery' for prop update (and handling query externally)
   */
  (e: 'update:beneficiaryQuery', value: string): void;
}>();

const onBehalfOfClient = useOnBehalfOf();

const props = withDefaults(
  defineProps<{
    /**
     * Possible list of allowed currencies for account selection
     *
     * If unset, any currency is selectable
     */
    allowedCurrencies?: string[];
    /**
     * Possible list of allowed banking schemes for account selection
     *
     * If unset, any banking schemes is selectable
     */
    allowedBankingSchemes?: string[];
    /**
     * Possible list of allowed statuses for account selection
     *
     * If unset, any currency is selectable
     */
    allowedStatuses?: BeneficiaryStatus[];
    /**
     * Selected beneficiary
     *
     * Can be synchronized via .sync or 'update:beneficiary'
     */
    beneficiary?: Beneficiary | null;
    /**
     * Query for filtering Beneficiaries
     *
     * Can be synchronized to set the search value via .sync or 'update:beneficiaryQuery'
     */
    beneficiaryQuery?: string;
  }>(),
  {
    allowedStatuses: () => [BeneficiaryStatus.APPROVED],
    beneficiaryQuery: '',
  }
);

const state = reactive({
  searchQuery: '',
  selectedBeneficiary: null as Beneficiary | null,
});

const beneficiarieState = useBeneficiariesState();

const canManageBeneficiaries = computed(() => {
  return (
    onBehalfOfClient.value || beneficiarieState.store.useAuthStore().hasAuthorities(AuthorityType.MANAGE_BENEFICIARIES)
  );
});

const isClient = computed(() => {
  return beneficiarieState.store.useAuthStore().isClientUser || !!onBehalfOfClient.value;
});

const filter = computed(() => {
  return {
    query: state.searchQuery,
    category: isClient.value ? BeneficiaryCategory.CLIENT_3RD_PARTY : BeneficiaryCategory.PARTNER_3RD_PARTY,
    status: props.allowedStatuses,
  };
});

const beneficiaryTableConfig = computed(() => {
  return {
    tableFields: selectableBeneficiaryTableFields,
  };
});

const beneficiaryId = computed(() => {
  return state.selectedBeneficiary?.id ?? undefined;
});

function onBeneficiaryAdded(beneficiary?: Beneficiary) {
  beneficiaryListing.value?.loadData().then((data) => {
    if (beneficiary) {
      const item = data.list.find((i) => i.id === beneficiary.id);
      if (item) {
        beneficiaryListing.value!.openItem(item);
      }
    }
  });
}

function selectBeneficiary(beneficiary?: Beneficiary | null) {
  emit('update:beneficiary', beneficiary);
}

function rowClicked(beneficiary?: Beneficiary | null) {
  state.selectedBeneficiary = beneficiary ?? null;
  selectBeneficiary(beneficiary);
}

watch(
  () => props.beneficiary,
  () => {
    rowClicked(props.beneficiary);
  },
  { immediate: true }
);

watch(
  () => props.beneficiaryQuery,
  () => {
    if (props.beneficiaryQuery !== state.searchQuery) {
      state.searchQuery = props.beneficiaryQuery;
    }
  },
  { immediate: true }
);

watch(
  () => props.allowedCurrencies,
  () => {
    if (props.allowedCurrencies && props.beneficiary && !props.allowedCurrencies.includes(props.beneficiary.currency)) {
      selectBeneficiary(null);
    }
  },
  { immediate: true }
);

watch(
  () => props.allowedBankingSchemes,
  () => {
    if (
      props.allowedBankingSchemes &&
      props.beneficiary &&
      !props.allowedBankingSchemes.includes(props.beneficiary.currency)
    ) {
      selectBeneficiary(null);
    }
  },
  { immediate: true }
);

defineExpose({ onBeneficiaryAdded });
</script>

<template>
  <div>
    <div class="d-sm-block d-md-flex justify-content-between">
      <SearchInput
        class="search-input mb-3"
        :search.sync="state.searchQuery"
        @update:search="$emit('update:beneficiaryQuery', $event)"
        stroked
      />
      <div v-if="!onBehalfOfClient && canManageBeneficiaries" class="text-sm-center mb-sm-3">
        <BeneficiaryEditModal
          class="w-100 row my-sm-2 my-md-0 justify-content-center"
          v-slot="{ showModal }"
          @update:beneficiary="onBeneficiaryAdded"
          v-bind="$attrs"
        >
          <VButton @click="showModal" class="add-beneficiary-button">Add beneficiary</VButton>
        </BeneficiaryEditModal>
      </div>
    </div>
    <BeneficiaryListing
      ref="beneficiaryListing"
      :filter="filter"
      :selectedItems="beneficiaryId ? [beneficiaryId] : []"
      :config="beneficiaryTableConfig"
      :allowedCurrencies="allowedCurrencies"
      :allowedBankingSchemes="allowedBankingSchemes"
      @row-clicked="rowClicked"
      singleRowDetails
    />
  </div>
</template>

<style lang="scss" scoped>
.search-input {
  flex: auto;
  max-width: 63%;
}
.add-beneficiary-button {
  white-space: nowrap;
}
</style>
