<script setup lang="ts">
import { getChildModel, getState, makeCompoundValidation, setState, updateModel } from 'ah-common-lib/src/form/helpers';
import {
  BaseFormModel,
  CompoundValidation,
  FieldOptionObj,
  FormDefinition,
  FormEvent,
  FormValidation,
} from 'ah-common-lib/src/form/interfaces';
import { PropType, computed, onBeforeMount, reactive, ref, watch } from 'vue';
import { AddressHistoryItem, checkAddressHistoryValidity, getAddressHistoryAge } from 'ah-api-gateways';
import { addressHistoryFM, residingFromFM } from '@/app/helpers/registration/forms';
import { ValidatedForm } from 'ah-common-lib/src/form/components';
import { toDataModel } from 'ah-common-lib/src/form/helpers';
import { useSettingsStore } from '@/app/store/settingsModule';
import { generateUUID } from 'ah-common-lib/src/helpers/uuid';
import RemoveButton from './RemoveButton.vue';
import { countryHasStates, countryNameFromCC } from 'ah-common-lib/src/helpers/countries';

const props = defineProps({
  currentAddressResidenceDate: {
    type: String,
  },
  previousAddresses: {
    type: Array as PropType<Partial<AddressHistoryItem>[]>,
  },
  showErrorMessage: {
    type: Boolean,
    required: true,
  },
  isSecondarySignatoryLabel: {
    type: Boolean,
  },
  isUboLabel: {
    type: Boolean,
  },
});

const emit = defineEmits({
  'update:validation': (_payload: CompoundValidation) => true,
  'update:previousAddresses': (_payload: Partial<AddressHistoryItem>[]) => true,
  'update:currentAddressResidenceDate': (_payload: string) => true,
  'toggle-error-message': (_show: boolean) => true,
});

const settingsStore = useSettingsStore();

// We add an id to the FormDef objects to allow proper re-rendering when removing or adding items
const addressesForms = ref<(FormDefinition & { id: string })[]>([]);

const residingFromFormDef = reactive<FormDefinition>({
  form: residingFromFM(props.isSecondarySignatoryLabel, props.isUboLabel),
  validation: null,
});

const hasSufficientAddressHistory = computed(() => {
  const addressHistoryAge = getAddressHistoryAge({
    currentAddress: { residingFrom: props.currentAddressResidenceDate },
    previousAddresses: props.previousAddresses,
  });

  return addressHistoryAge >= 3;
});

const validation = computed(() =>
  makeCompoundValidation<BaseFormModel>(
    [residingFromFormDef.validation, ...addressesForms.value.map((formDef) => formDef.validation)].filter(
      (i) => !!i
    ) as FormValidation[]
  )
);

const addressHistoryValidity = computed(() =>
  checkAddressHistoryValidity({
    currentAddress: {
      residingFrom: props.currentAddressResidenceDate,
    },
    previousAddresses: props.previousAddresses,
  })
);

function makePreviousAddressFormDef(): FormDefinition & { id: string } {
  const formDef: FormDefinition = {
    form: addressHistoryFM(),
    validation: null,
  };
  const countryCodeField = getChildModel(formDef.form, 'countryCode')!;

  setState(
    countryCodeField,
    'options',
    settingsStore.allCountries.map((country) => ({
      value: country.cc,
      label: country.name,
    }))
  );

  return { ...formDef, id: generateUUID() };
}

function addPreviousAddress() {
  // FIXME Typescript complains with Validation typing
  addressesForms.value.push(makePreviousAddressFormDef() as any);
  emit('toggle-error-message', false);
}

function removePreviousAddress(index: number) {
  addressesForms.value.splice(index, 1);
  emit(
    'update:previousAddresses',
    addressesForms.value.map((formDef) => toDataModel(formDef.form))
  );
}

function onUserFormEvent(event: FormEvent) {
  if (event.event === 'form-field-set-value') {
    emit(
      'update:previousAddresses',
      addressesForms.value.map((formDef) => toDataModel(formDef.form))
    );
  }
}

function onResidingFormEvent(event: FormEvent) {
  if (event.event === 'form-field-set-value') {
    emit('update:currentAddressResidenceDate', residingFromFormDef.form.residingFrom);
  }
}

onBeforeMount(() => {
  settingsStore.loadCountries(true).then(() => {
    addressesForms.value.forEach((formDef) => {
      const countryCodeField = getChildModel(formDef.form, 'countryCode')!;

      setState(
        countryCodeField,
        'options',
        settingsStore.allCountries.map((country) => ({
          value: country.cc,
          label: country.name,
        }))
      );
    });
  });
});

watch(validation, () => emit('update:validation', validation.value), { immediate: true });

watch(
  () => props.currentAddressResidenceDate,
  () => {
    updateModel(residingFromFormDef.form, { residingFrom: props.currentAddressResidenceDate });
  },
  { immediate: true }
);

watch(
  () => props.previousAddresses,
  () => {
    const newFormDefs: FormDefinition[] = [];
    props.previousAddresses?.forEach((previousAddressesItem, index) => {
      const formDef = addressesForms.value[index] ?? makePreviousAddressFormDef();
      updateModel(formDef.form, previousAddressesItem, undefined, true);
      setupAddressForm(previousAddressesItem, formDef);
      newFormDefs.push(formDef);
    });

    addressesForms.value = newFormDefs as any;
  },
  { immediate: true }
);

function setupAddressForm(address: Partial<AddressHistoryItem>, formDef: FormDefinition) {
  const stateOrProvinceField = getChildModel(formDef.form, 'stateOrProvince')!;
  const countryCode = address?.countryCode || '';

  const countryName = countryNameFromCC(countryCode);
  if (countryName && formDef.form.countryName !== countryName) {
    formDef.form.countryName = countryName;
  }

  if (countryHasStates(countryCode)) {
    setState(stateOrProvinceField, 'fieldType', 'select');
    settingsStore.loadISOCodes({ countryCode }).then((states) => {
      // Try to keep the value of State, searching by state code OR state name, among the available options
      if (formDef.form.stateOrProvince) {
        const state = states.find(
          (item) => item.subdivisionName === formDef.form.stateOrProvince || item.code === formDef.form.stateOrProvince
        );
        formDef.form.stateOrProvince = state ? state.code : null;
      }
      setState(
        stateOrProvinceField,
        'options',
        states.map((s) => ({
          value: s.code,
          label: s.subdivisionName,
        }))
      );
    });
  } else if (getState(stateOrProvinceField, 'fieldType') !== 'text') {
    // Try to keep the value of selected State, if any, converting to label for freetext
    const options = getState(stateOrProvinceField, 'options', []) as FieldOptionObj[];
    const selected = options.find((option) => option.value === formDef.form.stateOrProvince);
    formDef.form.stateOrProvince = selected ? selected.label : null;
    setState(stateOrProvinceField, 'fieldType', 'text');
  }
}
</script>

<template>
  <div>
    <ValidatedForm
      :fm="residingFromFormDef.form"
      :validation.sync="residingFromFormDef.validation"
      @form-event="onResidingFormEvent"
    />
    <div v-if="previousAddresses?.length || !hasSufficientAddressHistory" class="card-block mb-4">
      <div class="previous-address">
        <h3>Previous Address(es)</h3>
        <div class="mb-3">
          <p>
            <IconInfoCircle class="info-icon mr-2" />
            <span>Please provide all addresses you have lived at in the past 3 years</span>
          </p>

          <div v-for="(formDef, index) in addressesForms" :key="formDef.id" class="address-form">
            <div class="d-flex justify-content-between my-4">
              <h4 class="my-0 mt-1">Previous Address N&deg;{{ index + 1 }}</h4>
              <RemoveButton :removeButtonText="'Remove Address'" @remove="removePreviousAddress(index)" />
            </div>

            <ValidatedForm :fm="formDef.form" :validation.sync="formDef.validation" @form-event="onUserFormEvent">
              <template #addressHistory.residingFrom:before>
                <span>When did you reside at this address?</span>
              </template>
            </ValidatedForm>
          </div>

          <div class="text-center mt-2">
            <VButton
              @click="addPreviousAddress"
              :class="[
                'add-address-btn',
                { 'error-styling': showErrorMessage && (!addressHistoryValidity.age || !addressHistoryValidity.gaps) },
              ]"
              >Add Previous Address</VButton
            >
            <template v-if="showErrorMessage">
              <div class="error-message mt-2" v-if="!addressHistoryValidity.age">
                Please provide addresses spanning back at least 3 years
              </div>
              <div class="error-message mt-2" v-if="!addressHistoryValidity.gaps">
                Please ensure address dates contain no gaps
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.previous-address {
  h3 {
    font-size: $base-font-size;
    @include themedTextColor($color-primary);
  }

  h4 {
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
    @include themedTextColor($color-text-black);
  }

  .info-icon {
    @include themedTextColor($color-orange-highlight);
  }

  .residing-date {
    margin-top: 1.8em;
  }

  .error-message {
    @include themedTextColor($color-danger);
    font-size: $font-size-base;
  }

  .error-styling {
    border: 1px solid getColor($color-danger);
  }

  .btn {
    border-radius: 0.375em;
    padding: 0.375em 10.125em;
  }

  .add-address-btn {
    background: $white;
    @include themedTextColor($color-text-black);
    box-shadow: 0px 1px 2px 1px rgba(3, 7, 18, 0.12);

    &:hover,
    &:focus {
      background: getColor($color-main-bg) !important;
    }
  }
}
</style>
