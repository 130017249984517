<template>
  <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.1673 10.1665H50.834C53.6298 10.1665 55.9173 12.454 55.9173 15.2498V45.7498C55.9173 48.5457 53.6298 50.8332 50.834 50.8332H10.1673C7.37148 50.8332 5.08398 48.5457 5.08398 45.7498V15.2498C5.08398 12.454 7.37148 10.1665 10.1673 10.1665Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M55.9173 15.25L30.5007 33.0417L5.08398 15.25"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
