import { render, staticRenderFns } from "./TradingDetailsReview.vue?vue&type=template&id=537de96e&scoped=true"
import script from "./TradingDetailsReview.vue?vue&type=script&setup=true&lang=ts"
export * from "./TradingDetailsReview.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TradingDetailsReview.vue?vue&type=style&index=0&id=537de96e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "537de96e",
  null
  
)

export default component.exports