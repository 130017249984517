<script setup lang="ts">
import FormattedCurrency from './FormattedCurrency.vue';
import SimpleDoughnutChart from 'ah-reports/src/components/SimpleDoughnutChart.vue';
import { BModal } from 'bootstrap-vue';
import { ClientRiskLimitsProfile } from 'ah-api-gateways';
import { VButton, VCol, VRow } from 'ah-common-lib/src/common/components';
import { computed, PropType, ref } from 'vue';
import { formatNumberValue } from 'ah-common-lib/src/helpers/numbers';
import { getChartColorsForProfileUsageLevel } from '../../helpers/chartConfig';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useRouter } from 'vue-router/composables';
import { useTradeState } from 'ah-trades';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const props = defineProps({
  clientId: {
    type: String,
  },
  isActiveMarginCall: {
    type: Boolean,
    required: true,
  },
  limitsProfileData: {
    type: Object as PropType<ClientRiskLimitsProfile>,
  },
});

const router = useRouter();

const requestManager = useRequestManager().manager;

const tradeState = useTradeState();

const onBehalfOfClient = useOnBehalfOf();

const ownerId = computed(
  () => props.clientId ?? onBehalfOfClient.value?.id ?? tradeState.store.useAuthStore().loggedInIdentity?.client?.id
);

const isAHUser = computed(() => tradeState.store.useAuthStore().isAHUser);

const confirmNotifyUsModal = ref<InstanceType<typeof BModal> | null>(null);

const thankYouNotifyUsModal = ref<InstanceType<typeof BModal> | null>(null);

const spotsInfo = computed(() =>
  props.limitsProfileData
    ? [
        { label: 'Total Limit', value: props.limitsProfileData.notionalLimitSpots },
        { label: 'Utilised Limit', value: props.limitsProfileData.notionalLimitSpotsInUse },
        { label: 'Remaining Limit', value: props.limitsProfileData.notionalLimitSpotsRemaining },
      ]
    : []
);

const forwardsAndOptionsInfo = computed(() =>
  props.limitsProfileData
    ? [
        { label: 'Total Limit', value: props.limitsProfileData.notionalLimitForwardsOptions },
        { label: 'Utilised Limit', value: props.limitsProfileData.notionalLimitForwardsOptionsInUse },
        { label: 'Remaining Limit', value: props.limitsProfileData.notionalLimitForwardsOptionsRemaining },
      ]
    : []
);

function confirmNotifyUs() {
  confirmNotifyUsModal.value?.show();
}

function onConfirmNotify() {
  requestManager.currentOrNew('requestNotify', tradeState.services.limits.notifyUsLimit()).subscribe(() => {
    thankYouNotifyUsModal.value?.show();
  });
}

function goToCreditAndMTMPage() {
  if (isAHUser.value && onBehalfOfClient.value?.id) {
    router.push(`/dashboard/trader-obo/${onBehalfOfClient.value.id}/credit-mtm`);
  } else if (props.clientId || onBehalfOfClient.value?.id) {
    router.push(`/admin/activity/clients/${onBehalfOfClient.value?.id ? 'obo/' : ''}${ownerId.value}/credit-mtm`);
  } else {
    router.push('/dashboard/trades/credit-mtm');
  }
}
</script>

<template>
  <div class="trading-limits-section">
    <template v-if="limitsProfileData">
      <VRow>
        <VCol lg="6" sm="12">
          <div class="d-flex chart-section mb-5">
            <SimpleDoughnutChart
              :colors="getChartColorsForProfileUsageLevel(limitsProfileData.notionalLimitSpotsUsage)"
              :fractions="[limitsProfileData.notionalLimitSpotsInUse, limitsProfileData.notionalLimitSpotsRemaining]"
              secondaryLabel="Utilised"
            >
              <template #mainLabel>
                <div>{{ formatNumberValue(limitsProfileData.notionalLimitSpotsUsage * 100, 2) }}</div>
                <div style="font-size: medium" class="mt-2">%</div>
              </template>
            </SimpleDoughnutChart>
            <div class="chart-info">
              <h1>Spots</h1>
              <p v-for="item in spotsInfo" :key="item.label">
                {{ item.label }}:
                <strong>
                  {{ limitsProfileData.currency }}
                  <FormattedCurrency :value="item.value" :mainFontSize="16" :decimalFontSize="14" />
                </strong>
              </p>
            </div>
          </div>
        </VCol>
        <VCol lg="6" sm="12">
          <div class="d-flex chart-section mb-5">
            <SimpleDoughnutChart
              :colors="getChartColorsForProfileUsageLevel(limitsProfileData.notionalLimitForwardsOptionsUsage)"
              :fractions="[
                limitsProfileData.notionalLimitForwardsOptionsInUse,
                limitsProfileData.notionalLimitForwardsOptionsRemaining,
              ]"
              secondaryLabel="Utilised"
            >
              <template #mainLabel>
                <div>{{ formatNumberValue(limitsProfileData.notionalLimitForwardsOptionsUsage * 100, 2) }}</div>
                <div style="font-size: medium" class="mt-2">%</div>
              </template>
            </SimpleDoughnutChart>
            <div class="chart-info">
              <h1>Forwards + Options</h1>
              <p v-for="item in forwardsAndOptionsInfo" :key="item.label">
                {{ item.label }}:
                <strong>
                  {{ limitsProfileData.currency }}
                  <FormattedCurrency :value="item.value" :mainFontSize="16" :decimalFontSize="14" />
                </strong>
              </p>
            </div>
          </div>
        </VCol>
      </VRow>
      <VRow class="increase-trading-limit-info" :alignH="isActiveMarginCall ? 'end' : 'between'" alignV="center">
        <span v-if="!isActiveMarginCall"
          >If you would like to increase your trading limit, please
          <a class="plain-color-link" href="#" @click="confirmNotifyUs()">notify us</a> and we'll be in touch to
          discuss</span
        >
        <VButton
          class="new-xhedge-styles"
          label="View Credit Limits and Mark-to-Market"
          @click="goToCreditAndMTMPage"
          roundedBorder="true"
        />
      </VRow>
    </template>
    <template v-else>
      <p>No Limits Profile has been provided for you yet, so trading is still not possible.</p>
      <p>
        Please contact our trading desk on <strong>+44 20 3695 1338</strong> or
        <a href="mailto:tradingdesk@alt21.com">tradingdesk@alt21.com</a>
      </p>
    </template>
    <BModal ref="confirmNotifyUsModal" :centered="true" ok-title="Yes" cancel-title="No" @ok="onConfirmNotify">
      <div class="text-center">
        <p>Are you sure you wish to request an increase to your open position limits?</p>
      </div>
    </BModal>
    <BModal ref="thankYouNotifyUsModal" :centered="true" hide-footer>
      <div class="text-center">
        <p>
          Thank you. A member of our trading team will be in touch shortly to discuss your request and requirements.
        </p>
      </div>
    </BModal>
  </div>
</template>

<style lang="scss" scoped>
.trading-limits-section {
  padding: 1rem;

  .chart-section {
    h1 {
      margin-bottom: 1rem;
    }
    .chart-info {
      margin-left: 2rem;
      p {
        margin-bottom: 0.5rem;
      }
    }
  }

  ::v-deep .doughnut-chart {
    width: 160px;
    height: 160px;
  }

  .increase-trading-limit-info {
    margin: 0;
  }

  @include upToResolution(1464px) {
    .increase-trading-limit-info span {
      margin-bottom: 1rem;
    }
  }
}
</style>
