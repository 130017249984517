<script lang="ts" setup>
import { BModal } from 'bootstrap-vue';
import { Trade, getCurrencyPair, NonTradeableDays, IncurCostsType, incurCostsTypeLabels } from 'ah-api-gateways';
import { catchError, mergeMap } from 'rxjs/operators';
import { waitForEntityChange } from 'ah-requests';
import { radioField, dateField } from 'ah-common-lib/src/form/models';
import { makeFormModel, setState } from 'ah-common-lib/src/form/helpers';
import { MINUTE } from 'ah-common-lib/src/constants/time';
import { FormDefinition, FormEvent } from 'ah-common-lib/src/form/interfaces';
import { required } from '@vuelidate/validators';
import { minDate, ifTest, date, disallowStateDates } from 'ah-common-lib/src/form/validators';
import { format } from 'date-fns';
import { from, of } from 'rxjs';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { ref, reactive, computed, watch, getCurrentInstance } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useTradeState } from '../..';
import RouteProtectorModal from 'ah-common-lib/src/common/components/route/RouteProtectorModal.vue';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const toast = getCurrentInstance()?.proxy.$toast;
const tradeState = useTradeState();
const rollForwardModal = ref<BModal | null>(null);

const onBehalfOfClient = useOnBehalfOf();

const emit = defineEmits<{
  (e: 'roll-forward-executed'): void;
}>();

const props = defineProps<{
  trade: Trade;
}>();

const state = reactive({
  rollingForwardCost: null as number | null,
  lpRollForwardPnlAmount: null as number | null,
  lpRollForwardPnlAmountCurrency: null as string | null,
  modalShown: false as boolean,
  tradeSettlementDate: new Date() as Date,
  refreshComponentTimeout: null as number | null,
  holidays: [] as string[],
  weekends: [] as string[],
});

const requestManager = useRequestManager({
  exposeToParent: ['rollForwardTradeRequest'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'rollForwardTradeRequest') {
      continueRollForward();
    }
  },
});

const rollForwardDateForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'dateForm',
    fieldType: 'form',
    fields: [
      dateField(
        'rollForwardDate',
        'Date to roll forward to:',
        {
          required: true,
          clearable: false,
          errorMessages: {
            required: 'Date is required',
            minDate: () => 'Must be after ' + format(state.tradeSettlementDate, 'dd-MM-yyyy'),
            holidays: 'Date cannot be on a holiday',
            weekend: 'Date cannot be on a weekend',
          },
        },
        {
          date: ifTest(date, (val) => val instanceof Date),
          required: ifTest(required, (a) => !(a instanceof Date)),
          minDate: minDate(() => state.tradeSettlementDate),
          holidays: disallowStateDates('rollForwardDate', 'holidays'),
          weekend: disallowStateDates('rollForwardDate', 'weekends'),
        }
      ),
    ],
  }),
  validation: null,
});

const incurTypeOptions = computed(() => {
  return Object.keys(incurCostsTypeLabels).map((k) => {
    return {
      value: k,
      label: incurCostsTypeLabels[k as IncurCostsType],
    };
  });
});

const incurCostsForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'incurCostsForm',
    fieldType: 'form',
    fields: [
      radioField('incurCost', '', incurTypeOptions.value, {
        required: true,
        clearValue: '',
        defaultValue: IncurCostsType.AH,
        fieldWrapperClass: 'radio-form-field',
      }),
    ],
  }),
  validation: null,
});

const isDateInvalid = computed(() => {
  if (!rollForwardDateForm.validation) {
    return true;
  }

  return rollForwardDateForm.validation?.$invalid !== false;
});

function onDisallowedDatesChange() {
  if (rollForwardDateForm.form) {
    const targetDate = rollForwardDateForm.form.$fields.find((f) => f.$name === 'rollForwardDate');
    if (state.holidays) {
      setState(targetDate!, 'holidays', state.holidays);
    }
    if (state.weekends) {
      const targetDate = rollForwardDateForm.form.$fields.find((f) => f.$name === 'rollForwardDate');
      setState(targetDate!, 'weekends', state.weekends);
    }
  }
}

watch(
  () => [state.holidays, state.weekends],
  () => {
    onDisallowedDatesChange();
  }
);

function loadNonTradeableDays() {
  tradeState.services.pricingEngine
    .getNonTradeableDays(props.trade.hedgingProduct, getCurrencyPair(props.trade))
    .subscribe((result: NonTradeableDays) => {
      state.holidays = result.holidays.filter((d) => !result.weekend.includes(d));
      state.weekends = result.weekend;
    });
}

function onTradeChange() {
  if (props.trade.settlementDate && new Date(props.trade.settlementDate) > state.tradeSettlementDate) {
    state.tradeSettlementDate = new Date(props.trade.settlementDate);
  }

  loadNonTradeableDays();
}

watch(
  () => props.trade,
  () => {
    onTradeChange();
  },
  { immediate: true }
);

const rollForwardDate = computed(() => rollForwardDateForm.form?.rollForwardDate);

function loadTradeRollingForwardCosts() {
  const rollForwardDateAux = format(new Date(rollForwardDate.value), 'yyyy-MM-dd');

  return requestManager.manager
    .sameOrCancelAndNew(
      'getTradeRollingForwardCosts',
      from(new Promise((resolve) => window.setTimeout(resolve, 1000))).pipe(
        mergeMap(() =>
          tradeState.services.trade.getTradeCostsOfRollingForward(props.trade.id, rollForwardDateAux, {
            errors: { silent: true },
          })
        )
      ),
      rollForwardDateAux
    )
    .toPromise()
    .then(
      (cost) => {
        state.lpRollForwardPnlAmountCurrency = cost.currency;
        state.lpRollForwardPnlAmount = cost.lpRollForwardPnlAmount;
        state.rollingForwardCost = cost.rollForwardFeeGbpAmount;
      },
      (e) => {
        if (e.response?.status !== 502) {
          toast?.error('An unexpected problem has occurred. Please try again later.', {
            id: 'rollForwardError',
          });
        }
        if (e.response?.status === 502) {
          toast?.error(e.response?.data.message, {
            id: 'rollForwardError',
          });
        }
        throw e;
      }
    );
}

function hide() {
  state.modalShown = false;
}

function refreshComponent() {
  state.refreshComponentTimeout = window.setTimeout(() => {
    if (state.modalShown) {
      loadTradeRollingForwardCosts()
        .catch(() => {
          hide();
        })
        .finally(() => {
          if (state.modalShown) {
            refreshComponent();
          }
        });
    }
  }, MINUTE);
}

function onFormEvent(event: FormEvent) {
  if (event.event === 'form-field-set-value' && rollForwardDateForm.form!.rollForwardDate && !isDateInvalid.value) {
    loadTradeRollingForwardCosts().then(() => {
      refreshComponent();
    });
  }
}

/**
 * Display rolling Forward modal if costs are available
 */
function tryDisplayRollForwardModal() {
  if (!state.modalShown) {
    state.modalShown = true;
  }
}

function clearComponentRefresh() {
  if (state.refreshComponentTimeout !== null) {
    clearTimeout(state.refreshComponentTimeout);
    state.refreshComponentTimeout = null;
  }
}

function onModalHidden() {
  state.rollingForwardCost = null;
  rollForwardDateForm.form!.rollForwardDate = null;
  state.lpRollForwardPnlAmount = null;
  state.lpRollForwardPnlAmountCurrency = null;
  clearComponentRefresh();
}

const isAHUser = computed(() => tradeState.store.useAuthStore().isAHUser);

const incurCost = computed(() => incurCostsForm.form?.incurCost);

const protectorModal = ref<InstanceType<typeof RouteProtectorModal> | null>(null);

function continueRollForward() {
  if (!onBehalfOfClient.value || !isAHUser.value) {
    throw 'Trade rolling forward is an admin action only';
  }
  clearComponentRefresh();

  const newSettlementDate = format(new Date(rollForwardDate.value), 'yyyy-MM-dd');

  requestManager.manager
    .cancelAndNew(
      'rollForwardTradeRequest',
      tradeState.services.trade
        .rollForwardTrade(
          props.trade.id,
          onBehalfOfClient.value!.id,
          isAHUser.value,
          newSettlementDate,
          incurCost.value
        )
        .pipe(
          mergeMap(() =>
            waitForEntityChange(
              () => tradeState.services.trade.getTrade(props.trade.id, { errors: { silent: true } }),
              (response) => response.settlementDate !== props.trade.settlementDate,
              {
                restartPromise: () => {
                  return protectorModal
                    .value!.$bvModal.msgBoxConfirm(
                      'Our system may take some time to execute. Would you like to keep waiting?',
                      {
                        title: 'Continue loading?',
                        okTitle: 'Continue',
                        centered: true,
                      }
                    )
                    .then((value) => !!value);
                },
              }
            ).pipe(
              catchError(() => {
                return of(null);
              })
            )
          )
        )
    )
    .subscribe(
      (response) => {
        if (response) {
          tradeState.toast.success('Trade rolled forward successfully');
          emit('roll-forward-executed');
        }
        hide();
      },
      () => refreshComponent()
    );
}
</script>

<template>
  <span>
    <BModal
      title="Roll Forward"
      modal-class="side-modal"
      ref="rollForwardModal"
      hide-footer
      v-model="state.modalShown"
      @hidden="onModalHidden"
    >
      <div class="buttons text-sm-center text-md-left">
        <VRow class="mb-2 pl-2">
          <ValidatedForm
            :fm="rollForwardDateForm.form"
            @form-event="onFormEvent"
            :validation.sync="rollForwardDateForm.validation"
          />
        </VRow>
        <p><b>Roll forward costs</b></p>
        <p class="mb-4 text-small">You’ll need to pay a fee for the trade to be rolled forward.</p>
        <p class="text-small mb-2">
          <b>Liquidity provider profit and loss: </b>
          <span class="text-small ml-2">
            <LoadingIcon
              v-if="requestManager.manager.requestStates.getTradeRollingForwardCosts === 'pending'"
              class="svg"
            /><span v-else>
              {{ state.lpRollForwardPnlAmountCurrency }} {{ formatCurrencyValue(state.lpRollForwardPnlAmount) }}
            </span>
          </span>
        </p>
        <span class="text-small"
          ><b>Cost of rolling forward: </b>
          <span class="text-small ml-2"
            >GBP
            <LoadingIcon
              v-if="requestManager.manager.requestStates.getTradeRollingForwardCosts === 'pending'"
              class="svg"
            /><span v-else>
              {{ formatCurrencyValue(state.rollingForwardCost) }}
            </span>
          </span>
        </span>
        <VRow class="mt-4">
          <VCol cols="12"
            ><span class="text-small"><b>Who will incur the costs?</b></span></VCol
          >
          <VCol cols="12"><ValidatedForm :fm="incurCostsForm.form" /></VCol>
        </VRow>
        <p class="mt-3 mb-4">Are you sure you wish to roll forward this trade?</p>
        <VButton class="btn-secondary mr-2" @click="hide">Cancel</VButton>
        <VButton
          class="btn-success"
          :loading="requestManager.manager.anyPending"
          @click="continueRollForward"
          :disabled="isDateInvalid"
          >Confirm</VButton
        >
      </div>
    </BModal>
    <slot>
      <VButton
        blurOnClick
        class="button-success"
        :loading="requestManager.manager.anyPending"
        @click="tryDisplayRollForwardModal()"
        >Roll Forward</VButton
      >
    </slot>
  </span>
</template>

<style lang="scss" scoped>
::v-deep .radio-form-field {
  padding: 0 7.5px;
  .field-group-field {
    flex-grow: 1;
    .custom-control {
      margin-right: 5rem;
      margin-bottom: 0.7rem;
    }
  }
}
</style>
