<template>
  <div class="maintenance-screen">
    <div class="maintenance-screen-inner">
      <h1 class="title">X-Hedge is currently down for maintenance</h1>
      <p class="main-text">
        Our team is currently performing scheduled maintenance and updates.
        <br />We apologise for any inconvenience caused. <br />X-Hedge will be available again shortly.
      </p>
      <p class="contacts-text">
        If you need to contact us urgently call +44 20 3695 1338 or email tradingdesk@alt21.com
      </p>
      <IconMaintenance class="maintenance-icon" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MaintenanceWarningScreen extends Vue {}
</script>
<style lang="scss" scoped>
.maintenance-screen {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, getColor($color-primary), getColor($color-primary-light));
}
.maintenance-screen-inner {
  display: grid;
  max-width: 1600px;
  margin: auto;
  padding: 3rem;
  height: 100%;

  grid-template-areas: 'title' 'icon' 'text' 'contacts';
  grid-template-rows: min-content min-content min-content 1fr;

  .title {
    grid-area: title;
    color: white;
    font-weight: 600;
  }

  .main-text {
    grid-area: text;
    color: white;
    font-weight: 600;
    padding-bottom: 2em;
  }

  .contacts-text {
    grid-area: contacts;
    color: white;
    font-size: 0.8em;
  }

  .maintenance-icon {
    grid-area: icon;
    width: 100%;
    height: auto;
    max-height: 300px;
    color: getColor($color-primary);
    padding-bottom: 2rem;
  }

  @include tablet {
    grid-template-areas: 'a a' 'title icon' 'text icon' 'contacts icon' 'b b';
    grid-template-columns: auto auto;
    grid-template-rows: 1fr min-content min-content 1fr 1fr;

    .title {
      font-size: 3em;
      margin-bottom: 0.5em;
    }

    .main-text {
      margin-bottom: 4em;
    }

    .maintenance-icon {
      height: 30rem;
      padding-left: 5rem;
      color: getColor($color-primary-light);
      max-height: none;
    }
  }
}
</style>
