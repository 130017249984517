<script setup lang="ts">
import { computed, PropType } from 'vue';
import {
  ExportListType,
  ClientOnboardingReport,
  ClientOnboardingReportFilters,
  PaginatedQuery,
  ComplianceStatus,
  complianceStatusNote,
  FileCategory,
} from 'ah-api-gateways';
import { clientOnboardingTableFields } from '@/app/components/onboarding/report/tableFields/onboardingTableFields';
import { useSettingsStore } from '@/app/store/settingsModule';
import { formatDate, SIMPLE_DATE_FORMAT } from 'ah-common-lib/src/helpers/time';
import { defineUseManagedListingProps, useManagedListing, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { getServices } from '@/app/services';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import ClientNameCell from '@/app/components/clients/ClientNameCell.vue';
import OnboardingStatusPill from './OnboardingStatusPill.vue';
import { capitalize, startCase } from 'lodash';

const props = defineProps({
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    required: false,
    default: () => ({ tableFields: clientOnboardingTableFields }),
  },
  ...defineUseManagedListingProps<ClientOnboardingReport>(),
});

useSettingsStore().loadCountries();

const services = getServices();

const requestManager = useRequestManager();

interface OnboardingListEmits extends UseManagedListingEmits<ClientOnboardingReport> {}

const emit = defineEmits<OnboardingListEmits>();

const managedListing = useManagedListing<ClientOnboardingReport>({
  loadDataRequest(query: PaginatedQuery<ClientOnboardingReportFilters>) {
    /**
     * loadDataRequest will override sort if it is set. Otherwise it defaults to docCreatedAt DESC
     */
    return services.client.listClientOnboardingReport({ ...query });
  },
  downloadDataRequest(type: string, query: { [key: string]: any }) {
    return services.client.downloadClientOnboardingReport(query, type as ExportListType);
  },
  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager: requestManager.manager,
});

const tooltipObject = computed(() => {
  return {
    html: true,
    customClass: 'info-tooltip',
    placement: 'right',
  };
});

function getNoteText(client: ClientOnboardingReport) {
  if (client.status === 'SUSPENDED') {
    return 'Outstanding information required';
  }
  if (client.status === 'DEACTIVATED') {
    return 'Client Deactivated';
  }
  return complianceStatusNote[client.status];
}

function getDocuments(documents: FileCategory[]) {
  return documents!.map((d) => startCase(d.replaceAll('_', ' ').toLowerCase())).join(', ');
}

function getApplicant(client: ClientOnboardingReport) {
  return { name: `${client.owner.firstName} ${client.owner.lastName}`, email: client.owner.email };
}

function getNoteTooltipText(client: ClientOnboardingReport) {
  switch (client.status) {
    case ComplianceStatus.APPROVED:
      return 'The client application has been Approved after review from the compliance department.';
    case ComplianceStatus.DOCUMENTS_REQUIRED:
      const missingDocs = client.missingDocuments && client.missingDocuments.length > 0;
      const missingsUboDocs = client.uboMissingDocuments && client.uboMissingDocuments.length > 0;
      let out = `The questionnaire has been completed but `;

      if (missingDocs) {
        out += `the applicant still needs to upload the following documents: <b>
          ${getDocuments(client.missingDocuments!)}</b>.`;
      }

      if (missingsUboDocs) {
        if (missingDocs) out += `<br /> In addition, `;
        out += `the following documents are missing for one or more UBOs: <b>
          ${getDocuments(client.uboMissingDocuments!)} </b>.`;
      }

      if (!missingDocs && !missingsUboDocs) {
        // This should never occur
        out += ' there are still documents missing.';
      }
      return out;
    case ComplianceStatus.REJECTED:
      return 'The client application has been Rejected after review from the compliance department.';
    case ComplianceStatus.SUBMITTED:
      return 'The client has provided all the requested details and the application is being reviewed by the compliance department.';
    case ComplianceStatus.TERMS_AND_CONDITIONS:
      return `${getApplicant(client).name} (${getApplicant(client).email}),
        the appointed signatory, was sent an email to review the application and the T&Cs.
        The T&Cs need to be accepted for the onboarding to proceed`;
    case ComplianceStatus.UPDATED_TERMS_AND_CONDITIONS:
      return `${getApplicant(client).name} (${getApplicant(client).email}),
        the appointed signatory, was sent an email to review and confirm the updated T&Cs.
        The updated T&Cs need to be accepted for trading to resume.`;
    case ComplianceStatus.PENDING:
    case ComplianceStatus.SUSPENDED:
      return 'Outstanding information required for this account – Please contact <a href="mailto:  compliance@alt21.com">compliance@alt21.com</a>.';
    case ComplianceStatus.DEACTIVATED:
      return '';
    default:
      return 'User Details Registered';
  }
}

defineExpose({ loadData: managedListing.loadData, downloadData: managedListing.listeners.download });
</script>

<template>
  <PaginatedTable
    hover
    animateCols
    with-flex-scroll
    items-label="clients"
    v-on="{ ...managedListing.listeners, ...$listeners }"
    v-bind="{ ...managedListing.bindings, ...$attrs }"
  >
    <template #cell(name)="{ item }">
      <ClientNameCell :clientId="item.id" :clientName="item.name" baseUrl="/admin/activity/clients/" />
    </template>
    <template #cell(type)="{ item }">
      {{ capitalize(item.type) }}
    </template>
    <template #cell(createdAt)="{ item }">
      {{ formatDate(item.createdAt, SIMPLE_DATE_FORMAT, '') }}
    </template>
    <template #cell(termsAndConditionsDate)="{ item }">
      {{ formatDate(item.termsAndConditionsDate, SIMPLE_DATE_FORMAT, '') }}
    </template>
    <template #cell(caseClosedAt)="{ item }">
      {{ formatDate(item.caseClosedAt, SIMPLE_DATE_FORMAT, '') }}
    </template>
    <template #cell(statusLabel)="{ item }">
      <OnboardingStatusPill :status="item" />
    </template>
    <template #cell(notes)="{ item }">
      <div v-if="item.status === ComplianceStatus.PENDING">
        {{ getNoteText(item) }}
      </div>
      <div v-b-tooltip.hover="tooltipObject" :title="getNoteTooltipText(item)" v-else>
        {{ getNoteText(item) }}
      </div>
    </template>
  </PaginatedTable>
</template>

<style lang="scss" scoped>
::v-deep table {
  @include phablet {
    min-width: 70rem;
  }
  @include upToResolution($tabletResolution) {
    min-width: 100%;
  }
}
</style>
