<script lang="ts" setup>
import { bankAccountsTableFields } from '../../models/bankAccountsTableFields';
import BankAccountInfo from './BankAccountInfo.vue';
import { TableFieldDefinition } from 'ah-common-lib/src/models';
import { lastIbanDigits } from 'ah-common-lib/src/helpers/iban';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    fields?: TableFieldDefinition[];
    allowDelete?: boolean | string;
    selectable?: boolean | string;
  }>(),
  {
    fields: () => bankAccountsTableFields,
    allowDelete: false,
    selectable: false,
  }
);

const config = computed(() => {
  if (isSelectable.value) {
    return [{ header: '', key: 'selectRadio' }, ...props.fields];
  }
  return props.fields;
});

const isSelectable = computed(() => {
  return props.selectable !== false;
});

function onDeleteClick(event: MouseEvent, showModal: Function) {
  showModal();
  event.stopPropagation();
  event.stopImmediatePropagation();
}
</script>
<style lang="scss" scoped>
.delete-icon {
  cursor: pointer;
  @include upToResolution($tabletResolution) {
    margin-left: 68% !important;
  }

  &:hover {
    color: getColor($color-danger);
  }
}
</style>

<template>
  <PaginatedTable
    :stacked="$ahBeneficiariesState.mediaQuery.is('smDown')"
    hover
    class="bank-accounts-table"
    items-label="bank accounts"
    :fields="config"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <template #cell(currency)="{ item }"> {{ item.currency }} </template>

    <template #cell(bankName)="{ item }">
      <span v-if="item.bankName">
        {{ item.bankName }}
      </span>
      <span v-else-if="item.iban"> IBAN: {{ lastIbanDigits(item.iban) }} </span>
      <span v-else-if="item.accountNumber"> Account Number: {{ item.accountNumber }} </span>
      <span class="text-secondary" v-else> N/A </span>
    </template>

    <template #cell(_delete)="{ item }">
      <ConfirmModal
        modalText="Delete this bank account?"
        @ok="$emit('bank-account-remove', item)"
        v-if="allowDelete !== false"
        v-slot="{ showModal }"
      >
        <span @click="onDeleteClick($event, showModal)"
          ><IconTrash v-b-tooltip.hover="'Delete'" class="delete-icon ml-2"
        /></span>
      </ConfirmModal>
    </template>

    <!-- ROW DETAILS -->
    <template #row-details="row">
      <Transition name="slide-and-fade" appear>
        <BankAccountInfo :beneficiary="row.item" class="px-2" />
      </Transition>
    </template>
  </PaginatedTable>
</template>
