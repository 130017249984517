import { render, staticRenderFns } from "./MaintenanceWarningScreen.vue?vue&type=template&id=606d37ac&scoped=true"
import script from "./MaintenanceWarningScreen.vue?vue&type=script&lang=ts"
export * from "./MaintenanceWarningScreen.vue?vue&type=script&lang=ts"
import style0 from "./MaintenanceWarningScreen.vue?vue&type=style&index=0&id=606d37ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "606d37ac",
  null
  
)

export default component.exports