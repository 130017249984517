<template>
  <ValidatedForm :fm="targetSelectForm" @form-event="onFormEvent">
    <template #targetSelect.target:list-header>
      <li
        v-if="options.length > 0 && requestManager.requestStates.searchUserTimeout === 'pending'"
        class="text-secondary text-center loading-text"
      >
        Loading results...
      </li>
    </template>
    <template #targetSelect.target:search="{ selectScope }">
      <IconSearch class="search-icon" />
      <input class="vs__search" v-bind="selectScope.attributes" v-on="selectScope.events" />
    </template>
    <template #targetSelect.target:no-options>
      <li
        class="text-secondary text-center loading-text"
        v-if="requestManager.requestStates.searchUserTimeout !== 'pending'"
      >
        No users found<span v-if="query"> for "{{ query }}"</span>
      </li>
      <li v-else class="text-secondary text-center loading-text">Loading results...</li>
    </template>
    <template #targetSelect.target:option="{ selectScope }">
      <span :class="['option', { 'option-header': selectScope.header }]">{{ selectScope.label }}</span>
    </template>
  </ValidatedForm>
</template>

<script lang="ts">
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { makeFormModel, makeFormValidation, setState, getChildModel } from 'ah-common-lib/src/form/helpers';
import { selectField } from 'ah-common-lib/src/form/models';
import { FormEvent, FormSelectFieldSearchEvent } from 'ah-common-lib/src/form/interfaces';
import { catchError, flatMap, take, map } from 'rxjs/operators';
import { timer, combineLatest, Observable } from 'rxjs';
import { SECOND } from 'ah-common-lib/src/constants/time';
import { TargetSelection, GroupTargetSelection, IndividualTargetSelection, targetSelectionName } from 'ah-api-gateways';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { cleanNumber, getPhoneNumber, isValidPhoneNumber } from 'ah-common-lib/src/helpers/calls';
import { useAuthStore } from '@/app/store/authStore';
import { isValidEmail } from 'ah-common-lib/src/helpers/email';

const targetSelectFM = () =>
  makeFormModel({
    name: 'targetSelect',
    fieldType: 'form',
    fields: [
      selectField('target', '', [], {
        inputClass: 'search-box',
        filterable: false,
        placeholder: 'Search by name or email',
        selectable: (option: any) => {
          return !option.header;
        },
      }),
    ],
    state: {
      hideErrors: true,
    },
  });

const USER_SEARCH_DEBOUNCE_TIME = SECOND * 3;

interface RecipientOption {
  label: string;
  value: TargetSelection | false;
  header?: boolean;
}

type searchType = 'individual' | 'phone' | 'email' | 'group';

type SearchResults = Partial<{
  group: GroupTargetSelection[];
  individual: IndividualTargetSelection[];
}>;

export type searchTypeOptions = {
  [key in searchType]: {
    filters?: any;
    disallowed?: string[];
  };
};

@Component({
  validations: {
    targetSelectForm: makeFormValidation(targetSelectFM()),
  },
})
export default class TargetSearchSelect extends Mixins(WithRequestManager) {
  @Prop({ default: '' }) baseCountryCode!: string;

  @Prop({ required: true }) types!: searchTypeOptions;

  @Prop({ default: () => {} }) filters!: any;

  requestManagerConfig = {
    exposeToParent: true,
  };

  private targetSelectForm = targetSelectFM();

  private query = '';

  private individualOptions: RecipientOption[] = [];

  private groupOptions: RecipientOption[] = [];

  created() {
    this.searchTargets('');
  }

  isSearchAllowed(type: searchType) {
    return !!this.types[type];
  }

  filterOptions(options: RecipientOption[], key: searchType) {
    return options.filter((option) => {
      if (this.types[key]) {
        return !(this.types[key].disallowed ?? []).includes((option.value as any)[key].id);
      }
    });
  }

  get filteredIndividualOptions() {
    return this.filterOptions(this.individualOptions, 'individual');
  }

  get filteredGroupOptions() {
    return this.filterOptions(this.groupOptions, 'group');
  }

  get options() {
    const options: RecipientOption[] = [];

    if (this.isSearchAllowed('phone')) {
      if (isValidPhoneNumber(this.query, (this.baseCountryCode || undefined) as any)) {
        const value = cleanNumber(this.query, (this.baseCountryCode || undefined) as any);
        const disallowedPhoneNumbers = this.types.phone.disallowed ?? [];
        if (!disallowedPhoneNumbers.includes(value)) {
          options.push(
            {
              label: `Add phone number`,
              value: false,
              header: true,
            },
            {
              label: getPhoneNumber(this.query, (this.baseCountryCode || undefined) as any),
              value: {
                phoneNumber: value,
              },
            }
          );
        }
      }
    }
    if (this.isSearchAllowed('email')) {
      const disallowedEmails = this.types.phone.disallowed ?? [];
      if (isValidEmail(this.query) && !disallowedEmails.includes(this.query)) {
        options.push(
          {
            label: `Add email`,
            value: false,
            header: true,
          },
          {
            label: this.query,
            value: { email: this.query },
          }
        );
      }
    }

    if (this.isSearchAllowed('group') && this.filteredGroupOptions.length) {
      options.push(
        {
          label: `Groups`,
          value: false,
          header: true,
        },
        ...this.filteredGroupOptions
      );
    }

    if (this.isSearchAllowed('individual') && this.filteredIndividualOptions.length) {
      options.push(
        {
          label: `Users`,
          value: false,
          header: true,
        },
        ...this.filteredIndividualOptions
      );
    }

    return options;
  }

  @Watch('options')
  onOptionsChanged() {
    const formModel = getChildModel(this.targetSelectForm, 'target');

    formModel && setState(formModel, 'options', this.options);
  }

  @Watch('targetSelectForm.target')
  onUserSelected() {
    if (this.targetSelectForm.target) {
      this.$emit('user-selected', this.targetSelectForm.target);
    }
  }

  searchTargets(query: string) {
    this.query = query;
    this.requestManager
      .cancelAndNew(
        'searchUserTimeout',
        timer(USER_SEARCH_DEBOUNCE_TIME).pipe(
          take(1),
          flatMap(() => this.requestManager.cancelAndNew('searchUser', this.searchRequest(query)))
        )
      )
      .pipe(
        catchError((e) => {
          throw e;
        })
      )
      .subscribe((response) => {
        this.groupOptions =
          response.group?.map((target) => ({
            label: targetSelectionName(target),
            value: target,
          })) ?? [];

        this.individualOptions =
          response.individual?.map((target) => ({
            label: targetSelectionName(target),
            value: target,
          })) ?? [];
      });
  }

  searchRequest(query: string): Observable<SearchResults> {
    const requests: Observable<SearchResults>[] = [];

    if (this.isSearchAllowed('individual')) {
      requests.push(
        this.$services.individual
          .listIndividuals({
            sort: 'name',
            query,
            ...this.types.individual?.filters,
          })
          .pipe(
            map((r) => ({
              individual: r.list.map((i) => ({ individual: i })),
            }))
          )
      );
    }
    if (this.isSearchAllowed('group')) {
      if (this.types.group?.filters.clientId) {
        requests.push(
          this.groupService
            .listGroups(
              {
                sort: 'name',
                query,
                ...this.types.group?.filters,
              },
              this.authStore.isClientUser ? this.types.group?.filters.clientId : this.types.group?.filters.partnerId
            )
            .pipe(
              map((r) => ({
                group: r.list.map((g) => ({ group: g })),
              }))
            )
        );
      }
    }

    return combineLatest(requests).pipe(
      map((r) => {
        const out = {} as SearchResults;
        r.forEach((i) => {
          Object.assign(out, i);
        });
        return out;
      })
    );
  }

  get authStore() {
    return useAuthStore();
  }

  get groupService() {
    if (this.authStore.isClientUser) {
      return this.$services.clientGroup;
    } else {
      return this.$services.partnerGroup;
    }
  }

  onFormEvent(event: FormEvent) {
    if (event.event === 'form-field-select-search') {
      let searchEvent = event as FormSelectFieldSearchEvent;
      this.searchTargets(searchEvent.search);
    } else if (event.event === 'form-field-set-value') {
      this.$emit('target-selected', this.targetSelectForm.target);
      this.targetSelectForm.target = null;
    }
  }
}
</script>
<style lang="scss" scoped>
.option {
  padding-left: 32px;

  &.option-header {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    @include themedTextColor($color-dark-text, $color-dark-text);
  }
}

::v-deep .vs__dropdown-option--disabled {
  @include themedBackgroundColor($color-primary, $color-dark-primary);
}

.search-icon {
  height: 24px;
  width: 24px;
  margin-top: 6px;
  margin-left: 6px;
}
</style>
