import { render, staticRenderFns } from "./ManageCollateralEditor.vue?vue&type=template&id=ca81b486&scoped=true"
import script from "./ManageCollateralEditor.vue?vue&type=script&setup=true&lang=ts"
export * from "./ManageCollateralEditor.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ManageCollateralEditor.vue?vue&type=style&index=0&id=ca81b486&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca81b486",
  null
  
)

export default component.exports