<script lang="ts" setup>
import { fillDates } from '../helpers/fillDates';
import { TradeCommissionsReport } from 'ah-api-gateways';
import VChart from './VChart.vue';
import { makeTimeLineChartOptions, makeTimeLineChartDataset } from '../helpers/chartConfig';
import { differenceInDays, differenceInMonths, startOfDay } from 'date-fns';
import { dataColorPrimary, linesColorPrimary } from '../helpers/graphColors';
import { ScaleOptions } from 'chart.js';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, reactive, watch } from 'vue';
import { useReportsState } from 'ah-reports';

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: onRetryFromParentManager,
});

function onRetryFromParentManager(k: string) {
  if (k === 'loadData') {
    loadDates();
  }
}

const props = withDefaults(
  defineProps<{
    fromDate: Date;
    toDate: Date;
    currency?: string;
    partnerId?: string;
  }>(),
  {
    currency: 'GBP',
  }
);

const state = reactive({
  report: null as TradeCommissionsReport | null,
  options: makeTimeLineChartOptions({ color: linesColorPrimary }),
  data: makeTimeLineChartDataset({ label: 'Transaction Volume', color: dataColorPrimary }),
});

const callbacks = state.options.plugins?.tooltip?.callbacks;

if (callbacks) {
  callbacks.label = (item) => {
    return `${props.currency} ${item.parsed.y}`;
  };
}

const dates = computed(() => ({
  from: props.fromDate,
  to: props.toDate,
}));

const reportsState = useReportsState();

function loadDates() {
  requestManager.manager
    .cancelAndNew(
      'loadData',
      reportsState.services.trade.getTradeCommissionsReport(props.fromDate, props.toDate, props.partnerId)
    )
    .subscribe((response) => {
      state.data.datasets![0].data! = fillDates(
        response.dailyReports.map((i) => ({
          x: startOfDay(new Date(i.date)),
          y: i.tradedAmount,
        })),
        startOfDay(props.fromDate),
        startOfDay(props.toDate)
      );
      const timeRange = differenceInDays(props.toDate, props.fromDate);
      let unit: 'day' | 'month' | 'week' = 'day';
      if (timeRange > 10) {
        unit = timeRange > 60 ? 'month' : 'week';
      }
      const xScale: ScaleOptions<'time'> = state.options.scales?.x as any;
      if (xScale && xScale.time) {
        xScale.time.unit = unit;

        xScale.ticks = {
          minRotation: differenceInMonths(props.fromDate, props.toDate) === -1 ? 13 : 0,
        };
      }

      // Force update by replacing prop reference entirely
      state.options = { ...state.options };
      state.data = { ...state.data };

      state.report = response;
    });
}

watch(dates, loadDates, { immediate: true });
</script>

<template>
  <div>
    <template v-if="state.report && !requestManager.manager.anyPending">
      <VChart class="chart" type="line" :options="state.options" :data="state.data" />
      <div v-if="state.report" class="trade-count text-center">
        <h4 class="count">{{ formatCurrencyValue(state.report.tradedAmount) }}</h4>
        <p class="count-label mb-0 pb-0">{{ state.report.currency }} notional</p>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 100%;
  padding-bottom: 33%;
}

.trade-count {
  @include phablet {
    text-align: left !important;
    font-size: 0.5rem;
  }
  @include tablet {
    text-align: center !important;
    font-size: 0.5rem;
  }
  @include desktop {
    font-size: 0.9rem;
  }
  @include hd {
    font-size: 1rem;
  }
  .count {
    font-size: 4em;
    @include themedTextColor($color-primary);
  }

  .count-label {
    font-size: 1.5em;
  }
}

.buttons {
  @include phablet {
    margin-top: -3rem;
  }
  @include tablet {
    margin-top: -2rem;
  }
  @include desktop {
    margin-top: 0;
  }
}
</style>
