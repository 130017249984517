<script setup lang="ts">
import { ref, computed } from 'vue';
import { BModal } from 'bootstrap-vue';
import { useNotificationState } from '../..';
import NotificationsList from './NotificationsList.vue';

const modal = ref<InstanceType<typeof BModal> | null>(null);

const notificationState = useNotificationState();

const anyUnread = computed(() => notificationState.store.useNotificationsStore().unreadCount > 0);

function show() {
  modal.value?.show();
}

function hide() {
  modal.value?.hide();
}

function markAllAsRead() {
  notificationState.store.useNotificationsStore().markAllAsRead();
}
</script>

<template>
  <span>
    <BModal modal-class="side-modal" ref="modal" hide-footer>
      <div class="d-md-flex d-sm-block justify-content-between mb-md-5 mb-sm-2">
        <span class="modal-title" v-on-intersect>Notifications</span>
        <VButton blurOnClick v-if="anyUnread" @click="markAllAsRead()" class="transparent all-read text-small">
          MARK ALL AS READ
        </VButton>
      </div>
      <NotificationsList class="notifications-list">
        <template #notification-actions="{ item }">
          <slot name="notification-actions" v-bind="{ item, hide }" />
        </template>
      </NotificationsList>
    </BModal>
    <slot v-bind="{ show }"> </slot>
  </span>
</template>

<style lang="scss" scoped>
.loading-icon {
  width: 1.5em;
  height: 1.5em;
}

.modal-title {
  font-size: 1.5em;
  padding-right: 1.5em;
  font-weight: 600;
}

.all-read {
  @include themedTextColor($color-primary-light);
  font-size: 0.7em;
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  @include upToResolution($tabletResolution) {
    margin-left: -12px;
  }
}

.date-label {
  font-size: 0.7em;
  font-weight: 700;
}

.notifications-list {
  width: 330px;
}
</style>
