<script setup lang="ts">
import { toDataModel, updateModel, setState, getChildModel, getState } from 'ah-common-lib/src/form/helpers';
import { FieldOptionObj, FormDefinition, FormEvent } from 'ah-common-lib/src/form/interfaces';
import { FormValidation } from 'ah-common-lib/src/form/interfaces';
import { useSettingsStore } from '@/app/store/settingsModule';
import { countryHasStates, countryNameFromCC } from 'ah-common-lib/src/helpers/countries';
import { registrationAddressFM } from '@/app/helpers/registration/forms';
import { PropType, computed, onBeforeMount, reactive, watch } from 'vue';
import { Address } from 'ah-api-gateways';
import { isEqual, sortBy } from 'lodash';

const props = defineProps({
  address: {
    type: Object as PropType<Partial<Address>>,
    required: false,
  },
  isCountryEditable: {
    type: Boolean,
    required: true,
  },
  includeNationality: {
    type: [Boolean, String],
    default: false,
  },
});

const emit = defineEmits<{
  (e: 'update:address', value: Partial<Address>): void;
  (e: 'update:validation', value: FormValidation<Partial<Address>>): void;
}>();

const addressForm = reactive<FormDefinition>({
  form: registrationAddressFM(props.isCountryEditable !== false, props.includeNationality !== false),
  validation: null,
});

const settingsStore = useSettingsStore();

const validation = computed(() => {
  return {
    ...addressForm.validation,
    $model: addressForm.form,
    $invalid: !!addressForm.validation?.$invalid,
    $dirty: !!addressForm.validation?.$dirty,
  } as FormValidation<any>;
});

function getModel(fieldName: string) {
  return getChildModel(addressForm.form, fieldName);
}

onBeforeMount(() => {
  const nationalityField = getChildModel(addressForm.form, 'nationality');
  if (nationalityField) {
    setState(
      nationalityField,
      'options',
      sortBy(
        settingsStore.allCountries.map((c) => ({
          value: c.cc,
          label: c.nationality,
        })),
        (i) => i.label
      )
    );
  }
});

watch(
  () => props.address,
  () => updateModel(addressForm.form, props.address ?? {}),

  { immediate: true }
);

watch(
  () => props.address?.countryCode,
  () => {
    const stateOrProvinceCode = getChildModel(addressForm.form, 'stateOrProvince')!;
    const currentState = getState(stateOrProvinceCode, 'fieldType');
    const countryCode = props.address?.countryCode || '';

    const countryName = countryNameFromCC(countryCode);
    if (countryName && addressForm.form.countryName !== countryName) {
      addressForm.form.countryName = countryName;
    }

    if (countryHasStates(countryCode)) {
      setState(stateOrProvinceCode, 'fieldType', 'select');
      settingsStore.loadISOCodes({ countryCode }).then((states) => {
        // Try to keep the value of State, searching by state code OR state name, among the available options
        if (addressForm.form.stateOrProvince) {
          const state = states.find(
            (item) =>
              item.subdivisionName === addressForm.form.stateOrProvince ||
              item.code === addressForm.form.stateOrProvince
          );
          addressForm.form.stateOrProvince = state ? state.code : null;
        }
        setState(
          stateOrProvinceCode,
          'options',
          states.map((s) => ({
            value: s.code,
            label: s.subdivisionName,
          }))
        );
      });
    } else if (currentState !== 'text') {
      // Try to keep the value of selected State, if any, converting to label for freetext
      const options = getState(stateOrProvinceCode, 'options', []) as FieldOptionObj[];
      const selected = options.find((option) => option.value === addressForm.form.stateOrProvince);
      addressForm.form.stateOrProvince = selected ? selected.label : null;
      setState(stateOrProvinceCode, 'fieldType', 'text');
    }
  },
  { immediate: true }
);

function onFormEvent(event: FormEvent) {
  if (event.event === 'form-field-set-value') {
    emit('update:address', { ...props.address, ...toDataModel(addressForm.form) });
  }
}

watch(
  validation,
  (newVal, oldVal) => {
    if (!isEqual(newVal, oldVal)) {
      emit('update:validation', validation.value);
    }
  },
  { immediate: true }
);

defineExpose({ getModel });

const countryCodeField = getChildModel(addressForm.form, 'countryCode')!;
settingsStore.loadCountries(true).then(() => {
  const countries = settingsStore.allCountries.map((country) => ({
    value: country.cc,
    label: country.name,
  }));

  setState(countryCodeField, 'options', countries);
});
</script>

<template>
  <ValidatedForm :fm="addressForm.form" :validation.sync="addressForm.validation" @form-event="onFormEvent" />
</template>
