<template>
  <BoxGrid alignH="stretch" alignV="start">
    <BoxGridBlock cols="12">
      <slot name="filters" v-bind="{ setFilters }" />
    </BoxGridBlock>
    <VRow cols="12" class="mx-0 w-100">
      <VCol>
        <slot name="sorter" v-bind="{ setSortAndPageParams }" />
      </VCol>
    </VRow>
    <BoxGridBlock cols="12">
      <ClientListing
        fixed
        :config="clientsConfig"
        :dataDownloadState.sync="dataDownloadState"
        ref="clientListing"
        :filter="filter"
        :sortAndPageParams.sync="sortAndPageParams"
        v-on="$listeners"
        v-bind="$attrs"
      >
        <template #row-details="row">
          <slot name="row-details" v-bind="row" />
        </template>
      </ClientListing>
    </BoxGridBlock>

    <BoxGridItem cols="12">
      <ListDownloadButton
        v-if="showExport !== false"
        :loading="dataDownloadState === 'pending'"
        class="download-button"
        @download="downloadList"
      />
    </BoxGridItem>
  </BoxGrid>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import ClientListing from '@/app/components/clients/ClientListing.vue';
import debounce from 'lodash/debounce';
import { clientTableFields } from './tableFields/clientTableFields';
import ListDownloadButton from 'ah-common-lib/src/common/components/listing/ListDownloadButton.vue';
import { ExportListType, ListSortParams } from 'ah-api-gateways';

@Component({
  components: {
    ClientListing,
    ListDownloadButton,
  },
})
export default class SearchableClientList extends Vue {
  $refs!: {
    clientListing: ClientListing;
  };

  @Prop({ default: () => ({}) }) commonFilters!: any;

  @Prop({ default: false }) showExport!: boolean | string;

  // Automatically syncronised via .sync
  private dataDownloadState = 'idle';

  @Prop({
    default: () => clientTableFields,
  })
  tableFields!: any;

  get clientsConfig() {
    return {
      tableFields: this.tableFields,
    };
  }

  private userFilters: any = {};

  private sortAndPageParams: Partial<ListSortParams> = {};

  private filter = {};

  private debouncedFilterChange!: Function;

  created() {
    this.filter = { ...this.commonFilters, ...this.userFilters };

    this.debouncedFilterChange = debounce(() => {
      this.filter = { ...this.commonFilters, ...this.userFilters };
    }, 1500);
  }

  downloadList(format: ExportListType) {
    this.$refs.clientListing.downloadData(format);
  }

  @Watch('userFilters')
  onFiltersChange() {
    this.debouncedFilterChange();
  }

  setFilters(filters: any) {
    this.userFilters = filters;
  }

  setSortAndPageParams(params: Partial<ListSortParams>) {
    this.sortAndPageParams = params;
  }

  onSuccess() {
    this.$refs.clientListing.loadData();
  }
}
</script>
<style lang="scss" scoped>
.trade-details {
  padding: 0 math.div($padded-space, 2);
}

.dates-filter-holder {
  flex-basis: 26em;
  flex-shrink: 0;
  flex-grow: 0;
}

::v-deep {
  .date-inputs-wrapper {
    .field-group-field-input {
      margin-right: 0.3rem !important;
    }

    .field-group-field-input.month,
    .field-group-field-input.day {
      width: 3rem !important;
    }

    .field-group-field-input.year {
      width: 4rem !important;
    }
  }
}
</style>
