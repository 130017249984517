<script lang="ts" setup>
import { getHSLA } from 'ah-theme';
import VChart from './VChart.vue';
import { makeBarChartOptions, makeThemedChartDataset } from '../helpers/chartConfig';
import { linesColorPrimary } from '../helpers/graphColors';
import take from 'lodash/take';
import uniqBy from 'lodash/uniqBy';
import { format, isAfter, startOfMonth, addMonths, isBefore, addDays } from 'date-fns';
import {
  PaginatedResponse,
  ClientMonthlyTradeCountReportEntry,
  ClientMonthlyTradeVolumeReportEntry,
} from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { ChartData } from 'chart.js';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, reactive, watch } from 'vue';
import { useReportsState } from 'ah-reports';

const TOP_DISPLAY = 100;

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: onRetryFromParentManager,
});

function onRetryFromParentManager(k: string) {
  if (k === 'loadData') {
    loadDates();
  }
}

const reportsState = useReportsState();

const props = withDefaults(
  defineProps<{
    fromDate: Date;
    toDate: Date;
    clientId?: string;
    /**
     * Whether to show the full requested date range,
     * regardless of whether or not the data returned does not cover all of it
     *
     * Defaults to true
     */
    showFullDateRange?: boolean;
    serviceName?: 'numberTrades' | 'volumeTrades';
  }>(),
  {
    showFullDateRange: true,
    serviceName: 'numberTrades',
  }
);

const state = reactive({
  options: makeBarChartOptions(
    { horizontal: false, gridColor: linesColorPrimary },
    {
      plugins: {
        legend: {
          display: true,
        },
        tooltip: {
          callbacks: {
            title(items) {
              if (items[0]) {
                return items[0].dataset.label || '';
              }
              return '';
            },
            label: (item) => {
              if (props.serviceName === 'numberTrades') {
                return item.parsed.y + ' Trades';
              } else {
                return `GBP ${formatCurrencyValue(item.parsed.y)}`;
              }
            },
          },
        },
      },
      scales: {
        y: {
          min: 0,
          ticks: {
            autoSkip: false,
            precision: props.serviceName === 'numberTrades' ? 0 : 2,
          },
        },
      },
    }
  ),
  data: makeThemedChartDataset({
    labels: [],
    colorNames: ['widgets-green'],
  }),
});

const dates = computed(() => ({
  from: props.fromDate,
  to: props.toDate,
}));

function loadDates() {
  switch (props.serviceName) {
    case 'numberTrades':
      requestManager.manager
        .cancelAndNew(
          'loadData',
          reportsState.services.trade.getClientTopNumberTradesMonthlyReport(
            props.fromDate,
            props.toDate,
            props.clientId
          )
        )
        .subscribe((response) => {
          handleReportResponse(response);
        });
      break;
    case 'volumeTrades':
      requestManager.manager
        .cancelAndNew(
          'loadData',
          reportsState.services.trade.getClientTopVolumeTradesMonthlyReport(
            props.fromDate,
            props.toDate,
            props.clientId
          )
        )
        .subscribe((response) => {
          handleReportResponse(response);
        });
      break;
  }
}

watch(dates, loadDates, { immediate: true });

function handleReportResponse(
  response: PaginatedResponse<ClientMonthlyTradeCountReportEntry | ClientMonthlyTradeVolumeReportEntry>
) {
  const accounts = take(response.list, TOP_DISPLAY);

  // Using uniqBy to get all dates rendered, accounting for possible months where only SOME of the products have entries
  const labelledDates = uniqBy(accounts, (i) => i.date)
    .map((i) => new Date(i.date))
    .sort((a, b) => (a.valueOf() > b.valueOf() ? 1 : -1));

  if (props.showFullDateRange) {
    const firstMonth = addDays(startOfMonth(props.fromDate), -1);
    const lastMonth = addDays(startOfMonth(props.toDate), 1);
    if (labelledDates.length) {
      let earliestDataEntry = addMonths(startOfMonth(labelledDates[0]), -1);
      let latestDataEntry = addMonths(startOfMonth(labelledDates[labelledDates.length - 1]), 1);

      while (isAfter(earliestDataEntry, firstMonth)) {
        labelledDates.unshift(earliestDataEntry);
        earliestDataEntry = addMonths(earliestDataEntry, -1);
      }

      while (isBefore(latestDataEntry, lastMonth)) {
        labelledDates.push(latestDataEntry);
        latestDataEntry = addMonths(latestDataEntry, 1);
      }
    } else {
      let month = firstMonth;
      while (isBefore(month, lastMonth)) {
        labelledDates.push(month);
        month = addMonths(month, 1);
      }
      labelledDates.push(month);
    }
  }

  const crossesNewYear =
    labelledDates.length && labelledDates[0].getFullYear() !== labelledDates[labelledDates.length - 1].getFullYear();

  const monthsFiltered = labelledDates.map((date) => format(date, crossesNewYear ? 'LLLL yyyy' : 'LLLL').toUpperCase());

  const fxSpotData = labelledDates.map(
    (date) =>
      accounts.find((a) => a.hedgingProduct.includes('FX_SPOT') && new Date(a.date).valueOf() === date.valueOf()) ||
      null
  );

  const forwardData = labelledDates.map(
    (date) =>
      accounts.find((a) => a.hedgingProduct.includes('FORWARD') && new Date(a.date).valueOf() === date.valueOf()) ||
      null
  );

  const optionsData = labelledDates.map(
    (date) =>
      accounts.find((a) => a.hedgingProduct.includes('OPTIONS') && new Date(a.date).valueOf() === date.valueOf()) ||
      null
  );

  state.data!.datasets = [
    {
      label: 'Spots',
      data: fxSpotData.map((e) => mapReportEntry(e)),
      backgroundColor: getHSLA('widgets-red'),
    },
    {
      label: 'Forwards',
      data: forwardData.map((e) => mapReportEntry(e)),
      backgroundColor: getHSLA('primary'),
    },
    {
      label: 'Options',
      data: optionsData.map((e) => mapReportEntry(e)),
      backgroundColor: getHSLA('widgets-blue'),
    },
  ];

  state.data!.labels = monthsFiltered;

  // Force update by replacing prop reference entirely
  state.options = { ...state.options };
  state.data = { ...state.data } as ChartData;
}

function mapReportEntry(entry: ClientMonthlyTradeCountReportEntry | ClientMonthlyTradeVolumeReportEntry | null) {
  if (entry === null) {
    return 0;
  }
  if (props.serviceName === 'volumeTrades') {
    return (entry as ClientMonthlyTradeVolumeReportEntry).tradedAmount;
  }
  return entry.count;
}
</script>

<template>
  <div class="wrapper">
    <LoadingOverlay
      noWrap
      :state="requestManager.manager.currentStates"
      show-retry
      @retry="loadDates"
      loadingText="Loading Info"
    />
    <VChart
      class="chart-wrapper"
      v-if="!requestManager.manager.anyPending"
      type="bar"
      :options="state.options"
      :data="state.data"
    />
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  height: 278px;
  .chart-wrapper {
    width: 100%;
    height: 100%;
  }
  ::v-deep .chart-container {
    height: 278px;
  }
}
</style>
