<script lang="ts" setup>
import { computed, reactive, onBeforeMount, onBeforeUnmount, watch } from 'vue';
import { getFixedSideCcy, getSellCcy, getBuyCcy, Trade } from 'ah-api-gateways';
import { TradeDestination } from 'ah-trades/src/components/forms/tradeDestination';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import TradeInfo from './TradeInfo.vue';
import TradeBeneficiaryInfo from './TradeBeneficiaryInfo.vue';
import { useTradeState } from '../..';
import { isToday, isTomorrow } from 'date-fns';
import { TradeFundsDestination } from 'ah-trades/src/models/trade';
import { DrawdownQuotePriceResponse, getCcyByCurrency } from 'ah-api-gateways/models';
import { isPriceExpired } from 'ah-api-gateways/models';
import UpdatedDate from 'ah-common-lib/src/common/components/time/UpdatedDate.vue';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { scrollToElement } from 'ah-common-lib/src/form/helpers';
import { DrawdownDetails } from 'ah-api-gateways/models';
import { useAggregatedPaymentLimitChecker } from '../../composables/aggregatedPaymentLimitChecker';
import AggregatedLimitErrorMessage from '../../../../ah-payments/src/components/AggregatedLimitErrorMessage.vue';

const emit = defineEmits<{
  (e: 'update:drawdownPrice', value: DrawdownQuotePriceResponse | null): void;
  (e: 'update:pricesInvalid', value: boolean | null): void;
  (e: 'update:responseTimeOffset', value: number | null): void;
}>();

const props = defineProps<{
  trade: Trade;
  drawdownPrice: DrawdownQuotePriceResponse;
  drawdownDetails: DrawdownDetails;
  drawdownDestination: TradeDestination;
  pricesInvalid: boolean;
  responseTimeOffset: number;
}>();

const state = reactive<{
  needsPriceRefresh: boolean;
  recheckRefreshTimeout: number | null;
  offset?: number;
}>({
  needsPriceRefresh: false,
  recheckRefreshTimeout: null,
});

function goToElement() {
  const elem = document.getElementById('reloadPrice');
  if (elem) {
    scrollToElement(elem);
  }
}

watch(
  () => state.needsPriceRefresh,
  () => {
    if (state.needsPriceRefresh) {
      // WAIT FOR TRANSACTION
      window.setTimeout(() => goToElement(), 200);
      emit('update:pricesInvalid', true);
    } else {
      emit('update:pricesInvalid', false);
    }
  },
  { immediate: true }
);

onBeforeMount(recheckRefresh);

onBeforeUnmount(() => {
  if (state.recheckRefreshTimeout) {
    clearTimeout(state.recheckRefreshTimeout);
  }
});

function recheckRefresh() {
  state.needsPriceRefresh = !!(props.drawdownPrice && isPriceExpired(props.drawdownPrice, props.responseTimeOffset));
  state.recheckRefreshTimeout = window.setTimeout(() => recheckRefresh(), 1000);
}

const tradeState = useTradeState();

const sellCcy = computed(() => getSellCcy(props.drawdownPrice));

const buyCcy = computed(() => getBuyCcy(props.drawdownPrice));

const fixedSideCcy = computed(() => getFixedSideCcy(props.drawdownPrice));

const isSend = computed(() => props.drawdownDestination.destination === TradeFundsDestination.SEND);

const onBehalfOfClient = useOnBehalfOf();

const settlementLabel = computed(() => {
  if (isToday(new Date(props.drawdownPrice.settlementDate!))) {
    return 'today';
  } else if (isTomorrow(new Date(props.drawdownPrice.settlementDate!))) {
    return 'tomorrow';
  }
  return 'the day of settlement';
});

const newTrade = computed(() => {
  return {
    ...props.trade,
    ccy1: {
      ...props.trade.ccy1,
      clientAmount: props.trade.ccy1.remainingClientAmount,
      remainingClientAmount:
        props.trade.ccy1.remainingClientAmount -
        getCcyByCurrency(props.drawdownPrice, props.trade.ccy1.currency).clientAmount,
    },
    ccy2: {
      ...props.trade.ccy2,
      clientAmount: props.trade.ccy2.remainingClientAmount,
      remainingClientAmount:
        props.trade.ccy2.remainingClientAmount -
        getCcyByCurrency(props.drawdownPrice, props.trade.ccy2.currency).clientAmount,
    },
    hasDrawdowns: true,
  };
});

const tradeSellCcy = computed(() => getSellCcy(newTrade.value));

const tradeBuyCcy = computed(() => getBuyCcy(newTrade.value));

const clientId = computed(() => onBehalfOfClient.value?.id ?? props.trade.clientId);

const oboClientId = computed(() => onBehalfOfClient.value?.id);

const requestManager = useRequestManager();

const { aggregatedLimitForCurrency, aggregatedPaymentReviewRoute, isAggregatedLimitReached, loadAggregatedLimit } =
  useAggregatedPaymentLimitChecker({
    requestManager: requestManager.manager,
    services: tradeState.services,
    data: {
      clientId,
      oboClientId,
      paymentCurrency: computed(() => tradeBuyCcy.value.currency),
      paymentAmount: computed(() => props.drawdownPrice.ccy1.clientAmount),
    },
  });

function refreshPricing() {
  let date = new Date();

  if (isSend.value) {
    loadAggregatedLimit();
  }

  requestManager.manager
    .sameOrCancelAndNew(
      'getPrices',
      tradeState.services.pricingEngine.createDrawdownQuote(
        props.trade.id,
        props.drawdownDetails.amount,
        props.drawdownDetails.amountType,
        onBehalfOfClient.value?.id
      )
    )
    .subscribe((res) => {
      emit('update:responseTimeOffset', date.getTime() - new Date(res.priceRequestedTimestamp).getTime());
      emit('update:drawdownPrice', res);
    });
}
</script>

<template>
  <BoxGrid>
    <div class="update-content">
      <UpdatedDate
        v-if="props.drawdownPrice"
        :date="props.drawdownPrice.priceRequestedTimestamp"
        :loading="requestManager.manager.requestStates.getPrices === 'pending'"
        dateStyle="short"
        class="trade-updated-date"
        @refresh="refreshPricing"
        account-for-clock-delay
      />
    </div>
    <BoxGridBlock cols="12">
      <h3>Original Trade Details</h3>
      <TradeInfo :trade="newTrade" v-bind="$attrs">
        <template #notionals>
          <DataRow class="mb-2 trade-sell-currency" label="Current Sell amount" cols="6">
            {{ tradeSellCcy.currency }}
            {{ formatCurrencyValue(tradeSellCcy.clientAmount) }}
            <LockIcon v-if="tradeSellCcy.isFixedSide" />
          </DataRow>
          <DataRow class="mb-2 trade-buy-currency" cols="6" label="Current Buy amount">
            {{ tradeBuyCcy.currency }}
            {{ formatCurrencyValue(tradeBuyCcy.clientAmount) }}
            <LockIcon v-if="tradeBuyCcy.isFixedSide" />
          </DataRow>
          <DataRow class="mb-2 trade-sell-currency" label="Remaining Sell amount" cols="6">
            {{ tradeSellCcy.currency }}
            {{ formatCurrencyValue(tradeSellCcy.remainingClientAmount) }}
            <LockIcon v-if="tradeSellCcy.isFixedSide" />
          </DataRow>
          <DataRow class="mb-2 trade-buy-currency" cols="6" label="Remaining Buy amount">
            {{ tradeBuyCcy.currency }}
            {{ formatCurrencyValue(tradeBuyCcy.remainingClientAmount) }}
            <LockIcon v-if="tradeBuyCcy.isFixedSide" />
          </DataRow>
        </template>
      </TradeInfo>
    </BoxGridBlock>
    <BoxGridBlock cols="12">
      <h3>Drawdown Details</h3>
      <AggregatedLimitErrorMessage
        v-if="isAggregatedLimitReached && aggregatedLimitForCurrency"
        :aggregatedLimitForCurrency="aggregatedLimitForCurrency"
      >
        <template #route>
          <span v-if="oboClientId">please go to the payments page</span>
          <RouterLink v-else :to="aggregatedPaymentReviewRoute">please click here</RouterLink>
        </template>
      </AggregatedLimitErrorMessage>
      <div>
        <div :class="{ 'needs-price-refresh': state.needsPriceRefresh }">
          <DataRow class="mb-2" cols="6" label="Selling">
            {{ sellCcy.currency }} {{ formatCurrencyValue(sellCcy.clientAmount) }}
          </DataRow>
          <DataRow class="mb-2" cols="6" label="Buying">
            {{ buyCcy.currency }} {{ formatCurrencyValue(buyCcy.clientAmount) }}
          </DataRow>
          <DataRow class="mb-2" cols="6" label="Booked Rate">
            {{ tradeState.store.useSettingsStore().displayRate(fixedSideCcy.clientRate) }}
          </DataRow>
          <DataRow class="mb-2" cols="6" label="Drawdown date">
            {{ formatDate(drawdownPrice.settlementDate, 'dd-MM-yyyy') }}
          </DataRow>
        </div>
        <ExpandTransition appear>
          <div class="trade-refresh" v-if="state.needsPriceRefresh" id="reloadPrice">
            <p>This drawdown prices are no longer up to date. Please refresh it in order to continue.</p>
            <VButton
              @click="refreshPricing"
              :loading="requestManager.manager.requestStates.getPrices === 'pending'"
              class="btn-success"
            >
              <IconRefresh />
              Refresh price
            </VButton>
          </div>
        </ExpandTransition>

        <p class="information-text">
          It is advisable that you have the selling amount in your wallet prior to executing your drawdown to ensure you
          are able to settle the trade in time. <br />
          Funds must be received before {{ settlementLabel }}.<br />
          Your Margin requirements will be recalculated once the drawdown is settled to reflect the remaining position.
        </p>
      </div>

      <div class="beneficiary-info" v-if="isSend">
        <h3>Beneficiary</h3>
        <TradeBeneficiaryInfo :small="false" cols="6" :tradeDestination="drawdownDestination" />
      </div>
    </BoxGridBlock>
  </BoxGrid>
</template>

<style lang="scss" scoped>
.aggregated-limit-error-message {
  color: $red;
}
.update-content {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1em;
}
.trade-updated-date {
  @include themedTextColor($color-text-secondary);
}

.needs-price-refresh > * {
  opacity: 0.4;
}

.trade-refresh {
  text-align: center;
}

::v-deep .label-col {
  text-align: end;
}

.information-text {
  margin-top: 1em;
  margin-bottom: 1em;
  @include themedTextColor($color-text-secondary, $color-dark-text-secondary);
}

.beneficiary-info {
  padding-top: 1em;
  border-top: 1px solid;
  @include themedBorderColor($color-text-secondary, $color-dark-text-secondary);
}
</style>
