<script lang="ts" setup>
import { BModal } from 'bootstrap-vue';
import { makeFormModel, batchSetState } from 'ah-common-lib/src/form/helpers';
import { selectField, radioField } from 'ah-common-lib/src/form/models';
import { subYears, startOfYear, getDaysInMonth, endOfDay, startOfDay } from 'date-fns';
import { ExportListType, PaginatedQuery } from 'ah-api-gateways';
import { CommissionDisplayType } from 'ah-api-gateways';
import { reactive, ref, computed, onBeforeMount } from 'vue';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useTradeState } from '../..';

const tradeState = useTradeState();
const requestManager = useRequestManager().manager;

const downloadStatementFormModal = ref<BModal | null>(null);

const apportionedForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'apportionedForm',
    fieldType: 'form',
    fields: [
      radioField(
        'isApportioned',
        '',
        [
          { value: CommissionDisplayType.ORIGINAL, label: 'Original' },
          { value: CommissionDisplayType.APPORTIONED, label: 'Apportioned' },
        ],
        {
          required: true,
          clearValue: '',
          defaultValue: CommissionDisplayType.ORIGINAL,
          inline: true,
        }
      ),
    ],
  }),
  validation: null,
});

const monthForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'monthForm',
    fieldType: 'form',
    fields: [
      selectField(
        'month',
        '',
        [
          { value: 0, label: 'January' },
          { value: 1, label: 'February' },
          { value: 2, label: 'March' },
          { value: 3, label: 'April' },
          { value: 4, label: 'May' },
          { value: 5, label: 'June' },
          { value: 6, label: 'July' },
          { value: 7, label: 'August' },
          { value: 8, label: 'September' },
          { value: 9, label: 'October' },
          { value: 10, label: 'November' },
          { value: 11, label: 'December' },
        ],
        {
          required: true,
          clearValue: '',
          placeholder: 'Month',
        }
      ),
    ],
  }),
  validation: null,
});

const yearForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'year',
    fieldType: 'form',
    fields: [
      selectField('year', '', [], {
        required: true,
        clearValue: '',
        placeholder: 'Year',
      }),
    ],
  }),
  validation: null,
});

const isApportioned = computed(() => apportionedForm.form.isApportioned === CommissionDisplayType.APPORTIONED);

onBeforeMount(() => {
  const startDate = startOfYear(subYears(new Date(), 5));
  const years = [];
  let currentYear = new Date().getFullYear();

  while (currentYear >= startDate.getFullYear()) {
    years.push({ value: currentYear, label: currentYear.toString() });
    currentYear -= 1;
  }

  batchSetState(yearForm.form, 'options', {
    year: years,
  });
});

function showModal() {
  downloadStatementFormModal.value?.show();
}

function download(type: ExportListType) {
  if (!monthForm.validation?.$invalid && !yearForm.validation?.$invalid) {
    const monthDays = getDaysInMonth(new Date(yearForm.form.year, monthForm.form.month));
    const startDate = new Date(yearForm.form.year, monthForm.form.month, 1);
    const endDate = new Date(yearForm.form.year, monthForm.form.month, monthDays);

    const query = {
      tradeDateFrom: startOfDay(startDate),
      tradeDateTo: endOfDay(endDate),
      apportioned: isApportioned.value,
    } as PaginatedQuery;

    requestManager
      .cancelAndNew(
        `exportingStatement${type}`,
        tradeState.services.financial.downloadPartnerCommissionReportStatements({ ...query }, type as ExportListType)
      )
      .subscribe((doc) => tradeState.store.useNotificationsStore().triggerFileExportRequestNotification(doc));
  }

  if (yearForm.validation?.$invalid) {
    yearForm.validation.$touch();
  }
  if (monthForm.validation?.$invalid) {
    monthForm.validation.$touch();
  }
}

function cancel() {
  downloadStatementFormModal.value?.hide();
}

function isLoading(type: string) {
  return requestManager.requestStates[`exportingStatement${type}`] === 'pending';
}
</script>

<template>
  <div>
    <span>
      <BModal
        title="Download Commission Statement"
        modal-class="side-modal withdraw-modal"
        ref="downloadStatementFormModal"
        hide-footer
        ><div class="card-block">
          <VRow>
            <VCol cols="12"><h3>Select date range</h3></VCol>

            <VCol cols="6"> <ValidatedForm :fm="monthForm.form" :validation.sync="monthForm.validation" /> </VCol>
            <VCol cols="6"> <ValidatedForm :fm="yearForm.form" :validation.sync="yearForm.validation" /> </VCol
          ></VRow>
        </div>
        <div class="card-block mt-3">
          <VRow>
            <VCol cols="12"><h3>Commission Display Type</h3></VCol>
            <VCol cols="12"><ValidatedForm :fm="apportionedForm.form" /></VCol>
          </VRow>
        </div>
        <BoxGrid class="mt-3">
          <BoxGridItem class="field-group-wrapper">
            <VRow>
              <VCol cols="12" class="mt-3">
                <VButton :disabled="isLoading('CSV')" :loading="isLoading('PDF')" @click="download(ExportListType.PDF)"
                  >Download PDF</VButton
                > </VCol
              ><VCol cols="12" class="mt-3">
                <VButton :disabled="isLoading('PDF')" :loading="isLoading('CSV')" @click="download(ExportListType.CSV)"
                  >Download CSV</VButton
                ></VCol
              ><VCol cols="12" class="mt-3">
                <VButton class="btn btn-secondary" @click="cancel">Cancel</VButton></VCol
              ></VRow
            >
          </BoxGridItem>
        </BoxGrid>
      </BModal>
    </span>

    <slot v-bind="{ showModal }">
      <VButton @click="showModal()" />
    </slot>
  </div>
</template>

<style lang="scss">
.withdraw-modal .modal-dialog {
  width: 38em;
}
</style>
