<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 10C11 9.44772 11.4477 9 12 9C12.5523 9 13 9.44772 13 10V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V10Z"
      fill="currentColor"
    />
    <rect x="11" y="5" width="2" height="2" rx="1" fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
