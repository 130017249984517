import { VersionedObject, CreatableObject, UpdatableObject } from './rest';

export enum CreditLimitRequestStatus {
  OPEN = 'OPEN',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum CreditLimitStatus {
  OPEN = 'OPEN',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  ACTIVE = 'ACTIVE',
  AMENDED = 'AMENDED',
  EXPIRED = 'EXPIRED',
}

export enum McaStatus {
  PENDING = 'PENDING',
  SIGNED = 'SIGNED',
  PARTIAL_SIGNED = 'PARTIAL_SIGNED',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
}

export interface CreditData {
  partnerId: string;
  clientId: string;
  marginCreditLimitSpots: number;
  marginCreditLimitForwards: number;
  marginCreditLimitOptions: number;
  marginCreditLimitTotal: number;
  imccEnabled: boolean;
  spotsIm: boolean;
  currency: string;
  expirationDate: string;
}

export interface CreditProfile extends VersionedObject, CreatableObject, UpdatableObject {
  partnerId: string;
  partnerName: string;
  clientId: string;
  clientName: string;
  marginCreditLimitTotal: number;
  marginCreditLimitTotalInUse: number;
  marginCreditLimitTotalUsage: number;
  marginCreditLimitTotalRemaining: number;
  imccEnabled: boolean;
  spotsIm: boolean;
  currency: string;
  expirationDate: string;
}

export interface SignatoryData {
  individualId: string;
  position?: string;
}
export interface CreditMarginCallAgreement {
  clientId: string;
  signatories: SignatoryData[];
}

export interface MarginCreditUsageReport {
  marginCreditLimitTotal: number;
  marginCreditLimitTotalInUse: number;
  marginCreditLimitTotalUsage: number;
  clientType: string;
  countryCode: string;
  currency: string;
}

export interface MarginCreditAndLimitsListReport {
  limitProfileCurrency: string;
  limitProfileNotionalLimitSpots: number;
  limitProfileNotionalLimitSpotsInUse: number;
  limitProfileNotionalLimitSpotsUsage: number;
  limitProfileNotionalLimitSpotsRemaining: number;
  limitProfileNotionalLimitForwardsOptions: number;
  limitProfileNotionalLimitForwardsOptionsInUse: number;
  limitProfileNotionalLimitForwardsOptionsUsage: number;
  limitProfileNotionalLimitForwardsOptionsRemaining: number;
  limitProfileTenorLimitForwards: string;
  limitProfileTenorLimitOptions: string;
  limitProfileExpirationDate: string;
  creditProfileCurrency: string;
  creditProfileImccEnabled: boolean;
  creditProfileSpotsIm: boolean;
  creditProfileMarginCreditLimitTotal: number;
  creditProfileMarginCreditLimitTotalInUse: number;
  creditProfileMarginCreditLimitTotalUsage: number;
  creditProfileMarginCreditLimitTotalRemaining: number;
  creditProfileExpirationDate: string;
  collateralProfileVariationMarginPercentage: number;
  collateralProfileVariationMarginIncrementPercentage: number;
  mcaState: McaStatus;
}

export interface ClientMarginCreditAndLimitsListReport extends MarginCreditAndLimitsListReport {
  clientId: string;
  clientName: string;
  partnerId: string;
  partnerName: string;
  clientType: string;
}

export interface ClientMarginCreditUsageReport extends MarginCreditUsageReport {
  clientId: string;
  clientName: string;
  partnerId: string;
  partnerName: string;
}

export interface CreditProfileAudit extends CreditProfile {
  amendmentReason: string;
  amendedAt: string;
  amendedBy: string;
}

export interface MarginCreditAudit {
  id: string;
  clientId: string;
  partnerId: string;
  currency: string;
  marginCreditLimitTotal: number;
  profile: CreditProfileAudit;
  status: CreditLimitStatus;
}

export interface MarginCreditFilters {
  partnerName?: string;
  clientName?: string;
  clientType?: string;
  countryCode?: string;
  query?: string;
  status?: CreditLimitStatus;
  currency?: string;
  spotsIm?: boolean;
}

export const mcaStatusBadges: Record<McaStatus, { label: string; class: string }> = {
  [McaStatus.SIGNED]: {
    label: 'Accepted',
    class: 'green',
  },
  [McaStatus.PARTIAL_SIGNED]: {
    label: 'Partially Accepted',
    class: 'light-green',
  },
  [McaStatus.PENDING]: {
    label: 'Pending',
    class: 'light-orange',
  },
  [McaStatus.CANCELLED]: {
    label: 'Cancelled',
    class: 'light-red',
  },
  [McaStatus.EXPIRED]: {
    label: 'Expired',
    class: 'red',
  },
};
