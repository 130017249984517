<script lang="ts" setup>
import {
  FeatureFlag,
  FundingType,
  Trade,
  activeMarginTradeStates,
  closedTradeStatuses,
  formatLiquidityProviders,
} from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { computed } from 'vue';
import FundingTypeCell from '../tableCells/FundingTypeCell.vue';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { useTradeState } from '../..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useFeatureFlag } from 'ah-common-stores/src/stores/featureFlagStore';

const props = defineProps<{
  trade: Trade;
}>();

const tradeState = useTradeState();

const { isActive: isVariationMarginFeatureActive } = useFeatureFlag({
  featureFlag: FeatureFlag.VARIATION_MARGIN_AND_LIMITS_FEATURE,
});

const isClosed = computed(() => {
  return closedTradeStatuses.includes(props.trade.status);
});

const gbpInitialMarginValue = computed(() => {
  return props.trade.gbpInitialMarginAmount ?? 0;
});

const gbpVariationMargin = computed(() => {
  return props.trade.gbpVmm ?? 0;
});

const tradeMarginIsActive = computed(() => {
  return activeMarginTradeStates.includes(props.trade.state);
});

const gbpInUseMarginValue = computed(() => {
  if (!tradeMarginIsActive.value) {
    return 0;
  }
  return gbpVariationMargin.value + gbpInitialMarginValue.value;
});

const isAdminUser = computed(() => tradeState.store.useAuthStore().isAHUser);

const areIMDataRowsShown = computed(
  () => !isVariationMarginFeatureActive || props.trade.initialMarginFundingType !== FundingType.CREDIT
);

const onBehalfOfClient = useOnBehalfOf();
</script>

<template>
  <div>
    <p class="text-secondary pb-2" v-if="isClosed">
      These margins are no longer applicable as this trade is closed. Shown here for reference only:
    </p>
    <VRow>
      <VCol :cols="isAdminUser && !onBehalfOfClient ? 4 : 12">
        <DataRow v-if="areIMDataRowsShown" cols="6" lg="5" label="Initial Margin" class="trade-initial-margin mb-2">
          GBP
          {{ formatCurrencyValue(gbpInitialMarginValue) }}
        </DataRow>
        <DataRow
          v-if="areIMDataRowsShown"
          cols="6"
          lg="5"
          label="IM Funded with"
          class="trade-initial-margin-fund-type mb-2"
        >
          <FundingTypeCell :trade="trade" />
        </DataRow>
        <DataRow
          v-if="!isVariationMarginFeatureActive"
          cols="6"
          lg="5"
          label="Variation Margin"
          class="trade-variation-margin mb-2"
        >
          GBP
          {{ formatCurrencyValue(gbpVariationMargin) }}
        </DataRow>
        <DataRow cols="6" lg="5" label="Total Margin (in use)" class="trade-margin-in-use mb-2">
          GBP
          {{ formatCurrencyValue(gbpInUseMarginValue) }}
        </DataRow>
        <DataRow
          v-if="isAdminUser && onBehalfOfClient && trade.liquidityProvider"
          cols="6"
          lg="5"
          label="Liquidity Provider"
          class="mb-2"
        >
          {{ formatLiquidityProviders(trade.liquidityProvider) }}
        </DataRow>
      </VCol>
      <VCol cols="4" v-if="isAdminUser && !onBehalfOfClient">
        <DataRow cols="6" lg="5" label="Original Settlement Date" class="mb-2">
          {{ formatDate(props.trade.originalSettlementDate, 'dd MMM yyyy') }}
        </DataRow>
        <DataRow v-if="trade.liquidityProvider" cols="6" lg="5" label="Liquidity Provider" class="mb-2">
          {{ formatLiquidityProviders(trade.liquidityProvider) }}
        </DataRow>
      </VCol>
    </VRow>
  </div>
</template>
