<template>
  <BoxGrid alignH="start">
    <BoxGridItem sm="12" lg="6" xl="3" class="field-group-wrapper">
      <SearchInput
        class="search-input mb-4 mb-xl-0 mock-label-padding"
        placeholder="Search by client"
        :search.sync="search"
      />
    </BoxGridItem>
    <BoxGridItem sm="12" offset-xl="1" lg="6" xl="2" class="field-group-wrapper state">
      <label>
        State
        <a v-if="clientStatuses.length > 0" class="field-group-clear-link" @click="clientStatuses = []"> clear </a>
      </label>
      <TagMultiSelect
        :maxFulltextLabels="4"
        :options="clientStatusOptions"
        :value.sync="clientStatuses"
        itemsCountLabel="types"
      />
    </BoxGridItem>
    <BoxGridItem sm="12" lg="4" xl="2">
      <ValidatedForm :fm="dateTypeForm" />
    </BoxGridItem>
    <BoxGridItem sm="12" lg="8" xl="4" class="date-selector">
      <InputDateSelector
        title="Date Range"
        :date.sync="selectedDate"
        :defaultDate="defaultDate"
        hide-choices
        clearable
        is-ranged
      />
    </BoxGridItem>
  </BoxGrid>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { ComplianceStatus } from 'ah-api-gateways';
import { selectField } from 'ah-common-lib/src/form/models';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { FieldOptionObj } from 'ah-common-lib/src/form/interfaces';
import InputDateSelector, { DateRange } from 'ah-common-lib/src/common/components/InputDateSelector.vue';
import ListFilters from 'ah-common-lib/src/common/components/listing/ListFilters.vue';
import { startOfToday, endOfToday } from 'date-fns';

@Component({ components: { InputDateSelector } })
export default class OnboardingClientsListFilters extends ListFilters {
  selectedDate: DateRange | null = null;

  dateTypeOptions: FieldOptionObj[] = [
    { label: 'Registration', value: 'createdAt' },
    { label: 'T&Cs Accepted', value: 'termsAndConditionsDate' },
    { label: 'Approved/Rejected', value: 'caseClosedAt' },
  ];

  get defaultDate() {
    return { start: startOfToday(), end: endOfToday() };
  }

  private get clientStatusOptions() {
    return [
      {
        label: 'Pending Questionnaire',
        value: ComplianceStatus.PENDING,
      },
      {
        label: 'Incomplete',
        value: ComplianceStatus.DOCUMENTS_REQUIRED,
      },
      {
        label: 'In Review',
        value: ComplianceStatus.SUBMITTED,
      },
      {
        label: 'Approved',
        value: ComplianceStatus.APPROVED,
      },
      {
        label: 'T&Cs Updated',
        value: ComplianceStatus.UPDATED_TERMS_AND_CONDITIONS,
      },
      {
        label: 'Rejected',
        value: ComplianceStatus.REJECTED,
      },
      {
        label: 'Suspended',
        value: ComplianceStatus.SUSPENDED,
      },
      {
        label: 'Deactivated',
        value: ComplianceStatus.DEACTIVATED,
      },
    ];
  }

  private dateTypeForm = makeFormModel({
    name: 'dateTypeForm',
    fieldType: 'form',
    fields: [
      selectField('type', 'Date Range Type', this.dateTypeOptions, {
        required: false,
        defaultValue: 'createdAt',
        titleClass: 'text-nowrap',
      }),
    ],
  });

  private search = '';

  private clientStatuses: ComplianceStatus[] = [];

  get knownFilters() {
    return [
      {
        key: 'query',
        getter: () => (this.search.length && this.search) || undefined,
        setter: (value: any) => (this.search = value || ''),
      },
      {
        key: 'queryBy',
        getter: () => {
          if (this.search.length && this.search) {
            return ['name'];
          }
        },
        setter: () => {},
      },
      {
        key: 'status',
        getter: () => {
          if (this.clientStatuses.length > 0) {
            const status = [...this.clientStatuses];
            if (this.clientStatuses.includes(ComplianceStatus.DOCUMENTS_REQUIRED)) {
              status.push(ComplianceStatus.TERMS_AND_CONDITIONS);
            }
            return status;
          }
        },
        setter: (value: ComplianceStatus[]) => {
          this.clientStatuses = value.filter((c) => c !== ComplianceStatus.TERMS_AND_CONDITIONS);
        },
      },
      ...this.dateTypeOptions.map((o) => ({
        key: `${o.value}From`,
        getter: () => {
          if (this.selectedDate && this.dateTypeForm.type === o.value) {
            const start = new Date(this.selectedDate.start);
            if (!isNaN(start.valueOf())) {
              return start;
            }
          }
        },
        setter: (value: any) => {
          if (this.selectedDate) {
            this.selectedDate.start = value;
            this.dateTypeForm.type = o.value;
          }
        },
      })),
      ...this.dateTypeOptions.map((o) => ({
        key: `${o.value}To`,
        getter: () => {
          if (this.selectedDate && this.dateTypeForm.type === o.value) {
            const end = new Date(this.selectedDate.end);
            if (!isNaN(end.valueOf())) {
              return end;
            }
          }
        },
        setter: (value: any) => {
          if (this.selectedDate) {
            this.selectedDate.end = value;
          }
        },
      })),
    ];
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  @media only screen and (max-width: 1600px) {
    .state {
      margin-left: 0 !important;
    }
    .date-selector {
      flex-grow: 1;
      max-width: 100%;
    }
  }
  .date-inputs-wrapper {
    .field-group-field-input {
      margin-right: 0.3rem !important;
    }
  }
}
</style>
