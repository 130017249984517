<template>
  <div class="card-block">
    <h2>Onboarding Steps</h2>
    <VRow v-for="step in steps" :key="step.title">
      <VCol cols="0">
        <span class="dot"> &#9679; </span>
      </VCol>
      <VCol>
        <h3>{{ step.title }}</h3>
        <div class="text-description">
          {{ step.description }}
        </div>
      </VCol>
    </VRow>
  </div>
</template>

<script setup lang="ts">
import { complianceStatusLabels } from 'ah-api-gateways';

const steps: { title: string; description: string }[] = [
  {
    title: 'Client Registered',
    description: `The client applicant submits the requested details and is registered in the platform with its state updated to ${complianceStatusLabels.PENDING}.`,
  },
  {
    title: 'Questionnaire Submitted',
    description: `After validating their email, the client applicant logins to the platform and fills in the onboarding questionnaire, which update its state to Incomplete, if T&Cs or Documents are pending, or in-review otherwise.`,
  },
  {
    title: 'T&Cs Accepted',
    description: `The onboarding questionnaire has been completed and the Terms & Conditions read and accepted. The onboarding state is updated to in-review if all documents have been submitted or remains in incomplete otherwise.`,
  },
  {
    title: 'Documents Uploaded',
    description: `All requested documents have been successfully uploaded to the platform and the state is updated to in-review if the T&Cs have been accepted or remains in incomplete otherwise.`,
  },
  {
    title: 'Application in Review',
    description: `All the details have been provided by the client and the application is now being reviewed by the compliance department.`,
  },
  {
    title: 'Approval / Rejection',
    description: `The compliance department will approve or reject a client application based on the provided details.`,
  },
];
</script>

<style lang="scss" scoped>
.row {
  margin-bottom: 1.5rem;

  h3 {
    font-weight: $font-weight-semibold;
  }

  .text-description {
    @include themedTextColor($color-text-secondary, $color-dark-text-secondary);
    font-size: $font-size-sm;
  }

  .dot {
    @include themedTextColor($color-primary-light);
    font-size: 1.6em;
    line-height: 0.8em;
  }
}
</style>
