import { DeletableObject, UpdatableObject, VersionedObject } from '..';
import { AuditData } from '../auditData';

export enum CashTransactionType {
  BANKING_FEE = 'BANKING_FEE',
  FUND_WALLET = 'FUND_WALLET',
  GENERAL_PAYMENT = 'GENERAL_PAYMENT',
  INITIAL_MARGIN = 'INITIAL_MARGIN',
  MARGIN_CALL = 'MARGIN_CALL',
  MARGIN_RETURN = 'MARGIN_RETURN',
  SETTLE_FROM_MARGIN = 'SETTLE_FROM_MARGIN',
  SETTLE_FROM_WALLET = 'SETTLE_FROM_WALLET',
  WITHDRAW_FROM_WALLET = 'WITHDRAW_FROM_WALLET',
  MIFID_BANKING_FEE = 'MIFID_BANKING_FEE',
  MIFID_CONVERT_PF_TO_100_PERCENT = 'MIFID_CONVERT_PF_TO_100_PERCENT',
  MIFID_FUND_WALLET = 'MIFID_FUND_WALLET',
  MIFID_GENERAL_PAYMENT = 'MIFID_GENERAL_PAYMENT',
  MIFID_INITIAL_MARGIN = 'MIFID_INITIAL_MARGIN',
  MIFID_MARGIN_CALL = 'MIFID_MARGIN_CALL',
  MIFID_MARGIN_RETURN = 'MIFID_MARGIN_RETURN',
  MIFID_SETTLE_FROM_MARGIN = 'MIFID_SETTLE_FROM_MARGIN',
  MIFID_OPTION_PREMIUM = 'MIFID_OPTION_PREMIUM',
  MIFID_SETTLE_FROM_WALLET = 'MIFID_SETTLE_FROM_WALLET',
  MIFID_SETTLED_PREMIUM_BANK_CHARGE = 'MIFID_SETTLED_PREMIUM_BANK_CHARGE',
  MIFID_WITHDRAW_FROM_WALLET = 'MIFID_WITHDRAW_FROM_WALLET',
}

export enum CashTransactionAuditActions {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export const transactionActionsToHuman: Record<CashTransactionAuditActions, string> = {
  CREATE: 'Created',
  UPDATE: 'Updated',
  DELETE: 'Deleted',
};

export const cashTransactionLabels: Record<CashTransactionType, string> = {
  [CashTransactionType.MARGIN_CALL]: 'Margin Call',
  [CashTransactionType.MARGIN_RETURN]: 'Margin Return',
  [CashTransactionType.INITIAL_MARGIN]: 'Initial Margin',
  [CashTransactionType.FUND_WALLET]: 'Fund Wallet',
  [CashTransactionType.SETTLE_FROM_WALLET]: 'Settle from Wallet',
  [CashTransactionType.WITHDRAW_FROM_WALLET]: 'Withdraw from Wallet',
  [CashTransactionType.SETTLE_FROM_MARGIN]: 'Settle from Margin',
  [CashTransactionType.GENERAL_PAYMENT]: 'General Payment',
  [CashTransactionType.BANKING_FEE]: 'Banking Fee',

  [CashTransactionType.MIFID_BANKING_FEE]: 'MiFID Banking Fee',
  [CashTransactionType.MIFID_CONVERT_PF_TO_100_PERCENT]: 'MiFID Convert PF to 100%',
  [CashTransactionType.MIFID_FUND_WALLET]: 'MiFID Fund Wallet',
  [CashTransactionType.MIFID_GENERAL_PAYMENT]: 'MiFID General Payment',
  [CashTransactionType.MIFID_INITIAL_MARGIN]: 'MiFID Initial Margin',
  [CashTransactionType.MIFID_MARGIN_CALL]: 'MiFID Margin Call',
  [CashTransactionType.MIFID_MARGIN_RETURN]: 'MiFID Margin Return',
  [CashTransactionType.MIFID_SETTLE_FROM_MARGIN]: 'MiFID Settle from Margin',
  [CashTransactionType.MIFID_OPTION_PREMIUM]: 'MiFID Option Premium',
  [CashTransactionType.MIFID_SETTLE_FROM_WALLET]: 'MiFID Settle from Wallet',
  [CashTransactionType.MIFID_SETTLED_PREMIUM_BANK_CHARGE]: 'MiFID Settled Premium Bank Charge',
  [CashTransactionType.MIFID_WITHDRAW_FROM_WALLET]: 'MiFID Withdraw from Wallet',
};

export enum CashTransactionState {
  SCHEDULED = 'SCHEDULED',
  REGISTERED = 'REGISTERED',
  CONFIRMED = 'CONFIRMED',
}

export const cashTransactionStateLabels: Record<CashTransactionState, string> = {
  [CashTransactionState.SCHEDULED]: 'Scheduled',
  [CashTransactionState.REGISTERED]: 'Registered',
  [CashTransactionState.CONFIRMED]: 'Confirmed',
};

export enum CashTransactionDirection {
  RECEIVING = 'RECEIVING',
  PAYING = 'PAYING',
}

export const cashTransactionDirectionLabels: Record<CashTransactionDirection, string> = {
  [CashTransactionDirection.RECEIVING]: 'Receiving',
  [CashTransactionDirection.PAYING]: 'Paying',
};

export enum FlowType {
  EXPECTED = 'EXPECTED',
  CONFIRMED = 'CONFIRMED',
}

export const flowTypeLabels: Record<FlowType, string> = {
  [FlowType.EXPECTED]: 'Expected',
  [FlowType.CONFIRMED]: 'Confirmed',
};

export enum ConfirmationEmailState {
  SENT = 'SENT',
  NOT_SENT = 'NOT_SENT',
  SENDING = 'SENDING',
  FAILED = 'FAILED',
}

export const transactionConfirmationEmailToHuman: Record<ConfirmationEmailState, string> = {
  [ConfirmationEmailState.SENT]: 'Payment Confirmation',
  [ConfirmationEmailState.NOT_SENT]: 'Not Sent',
  [ConfirmationEmailState.SENDING]: 'Sending',
  [ConfirmationEmailState.FAILED]: 'Failed',
};

export interface CashTransaction extends VersionedObject, UpdatableObject {
  referenceNumber: number;

  partnerId: string;
  partnerName: string;
  clientId: string;
  clientName: string;

  state: CashTransactionState;
  transactionType?: CashTransactionType;
  transactionDirection?: CashTransactionDirection;

  payingThirdParty: boolean;

  amount: number;
  currency: string;
  cashFlowDate: string;
  valueDate?: string;

  optixExternalId?: string;

  clientAccountName?: string;
  receivingClientAccountId?: string;
  receivingClientAccountName?: string;
  payingClientAccountId?: string;
  payingClientAccountName?: string;

  confirmationEmailState: ConfirmationEmailState;
  voiceTradeId?: string;
  voiceTradeComposedReferenceNumber?: string;
  beneficiaryId?: string;
  beneficiaryName?: string;

  notes?: string | null;
}

export interface TransactionAuditData
  extends CashTransaction,
    DeletableObject,
    AuditData<CashTransactionAuditActions> {}
