import { Observable } from 'rxjs';
import { HttpRequestOptions } from '..';

export interface UploadedFileUpdateBase {
  bytesLoaded: number;
  bytesTotal: number;
  finished: boolean;
}

export interface UploadedFileUpdatePending extends UploadedFileUpdateBase {
  finished: false;
}

export interface UploadedFileUpdateFinished<T> extends UploadedFileUpdateBase {
  finished: true;
  file: T;
}

export type UploadedFileUpdate<T> = UploadedFileUpdatePending | UploadedFileUpdateFinished<T>;

export function uploadFileWithUpdates<T>(
  config: HttpRequestOptions<T>,
  request: (config: HttpRequestOptions<T>) => Observable<T>
): Observable<UploadedFileUpdate<T>> {
  return new Observable<UploadedFileUpdate<T>>((sub) => {
    let bytesLoaded = 0;
    let bytesTotal = Infinity;

    config.axiosConfig.headers = {
      ...config.axiosConfig.headers,
      'content-type': 'multipart/form-data',
    };

    config.axiosConfig.onUploadProgress = (progressEvent: ProgressEvent) => {
      bytesLoaded = progressEvent.loaded;
      bytesTotal = progressEvent.total;
      sub.next({
        bytesLoaded,
        bytesTotal,
        finished: false,
      });
    };

    sub.next({
      bytesLoaded,
      bytesTotal,
      finished: false,
    });

    const uploadSub = request(config).subscribe(
      (file) => {
        sub.next({
          bytesLoaded,
          bytesTotal,
          finished: true,
          file,
        });
        sub.complete();
      },
      (e) => {
        sub.error(e);
        sub.complete();
      }
    );

    return () => {
      uploadSub.unsubscribe();
    };
  });
}
