<script setup lang="ts">
import { reactive, computed, watch, onBeforeUnmount } from 'vue';
import {
  BaseFormModel,
  FieldOptions,
  FormDefinition,
  FormEvent,
  ValidationRuleSet,
} from 'ah-common-lib/src/form/interfaces';
import { getChildModel, makeFormModel, setState } from 'ah-common-lib/src/form/helpers';
import { financialAmountField } from 'ah-common-lib/src/form/models';
import { useTradeState } from '../../';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { FundingType, QuotePriceResponse, TradeFundingDetails } from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { ValidatedForm } from 'ah-common-lib/src/form/components';
import { debounce } from 'lodash';
import { required } from '@vuelidate/validators';
import CollateralTransactionExecutor from 'ah-trades/src/components/collaterals/CollateralTransactionExecutor.vue';
import { compareState } from 'ah-common-lib/src/form/validators';

const props = defineProps<{
  tradePriceResponse: QuotePriceResponse;
  tradeFundingDetails?: Partial<TradeFundingDetails>;
}>();

const emit = defineEmits<{
  (e: 'update:tradeFundingDetails', value: Partial<TradeFundingDetails>): void;
}>();

const tradeState = useTradeState();

const onBehalfOf = useOnBehalfOf();

const collaterals = computed(() => {
  const clientId = onBehalfOf.value
    ? onBehalfOf.value.id
    : tradeState.store.useAuthStore().loggedInIdentity?.client?.id;

  if (clientId) {
    return tradeState.store.useWalletsStore().getCollaterals(clientId);
  }
  return null;
});

const areCollateralsLoading = computed(() =>
  tradeState.store.useWalletsStore().areCollateralsLoading(onBehalfOf.value?.id)
);

const sufficientCollateral = computed(() => {
  if (!collaterals.value || areCollateralsLoading.value) {
    return false;
  }

  return (
    props.tradePriceResponse.homeCurrencyInitialMarginAmount <= 0 ||
    collaterals.value.marginCollateralAvailable >= props.tradePriceResponse.homeCurrencyInitialMarginAmount
  );
});

const shouldBeAbleToPost = computed(() => {
  if (!collaterals.value || areCollateralsLoading.value) {
    return false;
  }
  return (
    !sufficientCollateral.value ||
    collaterals.value.initialMarginCollateralAvailable + collaterals.value.initialMarginCreditAvailable <
      props.tradePriceResponse.homeCurrencyInitialMarginAmount
  );
});

const sufficientInitialCollateral = computed(() => {
  if (!collaterals.value || areCollateralsLoading.value) {
    return false;
  }
  return (
    sufficientCollateral.value &&
    collaterals.value.initialMarginCollateralAvailable >= (forms.collateral.form.value ?? 0)
  );
});

const allCollateralUsed = computed(() => {
  if (
    !collaterals.value ||
    areCollateralsLoading.value ||
    creditAtZero.value ||
    forms.collateral.form.value === undefined
  ) {
    return false;
  }
  return collaterals.value.initialMarginCollateralAvailable === forms.collateral.form.value;
});

const creditAtZero = computed(() => {
  if (!collaterals.value || areCollateralsLoading.value) {
    return false;
  }

  return collaterals.value?.initialMarginCreditAvailable === 0;
});

const sufficientInitialCredit = computed(() => {
  if (!collaterals.value || areCollateralsLoading.value || creditAtZero.value) {
    return false;
  }
  return sufficientCollateral.value && collaterals.value.initialMarginCreditAvailable >= (forms.credit.form.value ?? 0);
});

const initialMarginCreditAvailable = computed(() => collaterals.value?.initialMarginCreditAvailable ?? 0);

const initialMarginCollateralAvailable = computed(() => collaterals.value?.initialMarginCollateralAvailable ?? 0);

const marginRequirements = computed(() => props.tradePriceResponse.homeCurrencyInitialMarginAmount ?? 0);

const currency = computed(() => props.tradePriceResponse.homeCurrency);

const areFormsValid = computed(() => !forms.collateral.validation?.$invalid && !forms.credit.validation?.$invalid);

const isValid = computed(
  () => areFormsValid.value && sufficientInitialCollateral.value && sufficientInitialCredit.value
);

const formState: FieldOptions = {
  inputClass: 'form-control bordered',
  errorMessages: {
    minValue: 'The amount must be more than GBP 0.00.',
    sameValue: 'The amount cannot be the entire value of initial margin requirements.',
    maxValue: 'The amount cannot exceed the value of initial margin requirements.',
  },
};

const formValidations: ValidationRuleSet = {
  required,
  minValue: compareState('collateral', (val) => typeof val !== 'number' || val > 0),
  sameValue: compareState('collateral', (val) => typeof val !== 'number' || val !== marginRequirements.value),
  maxValue: compareState('collateral', (val) => typeof val !== 'number' || val <= marginRequirements.value),
};

const forms = {
  collateral: reactive<FormDefinition>({
    form: makeFormModel({
      name: 'collateralContributionForm',
      fieldType: 'form',
      fields: [
        financialAmountField('value', '', formState, {
          ...formValidations,
          maxValue: compareState(
            'collateral',
            (val) => typeof val !== 'number' || val <= initialMarginCollateralAvailable.value
          ),
        }),
      ],
    }),
    validation: null,
  }),
  credit: reactive<FormDefinition>({
    form: makeFormModel({
      name: 'creditContributionForm',
      fieldType: 'form',
      fields: [
        financialAmountField('value', '', formState, {
          ...formValidations,
          maxValue: compareState(
            'collateral',
            (val) => typeof val !== 'number' || val <= initialMarginCreditAvailable.value
          ),
        }),
      ],
    }),
    validation: null,
  }),
};

tradeState.store.useWalletsStore().loadCollaterals({ force: false, clientId: onBehalfOf.value?.id });

function emitValues() {
  emit('update:tradeFundingDetails', {
    initialMarginCollateralFundingAmount: forms.collateral.form.value ?? 0,
    initialMarginCreditFundingAmount: forms.credit.form.value ?? 0,
    initialMarginFundingCurrency: currency.value,
    initialMarginFundingType: FundingType.COLLATERAL_AND_CREDIT,
    valid: isValid.value,
  });
}

const debouncedCalc = debounce((changed: FormDefinition, change: FormDefinition) => {
  change.form.value = marginRequirements.value - changed.form.value;
  change.validation?.$touch();
  emitValues();
}, 500);

function setFormPlaceholder(field: BaseFormModel, placeholder = 'Calculating...') {
  setState(field, 'placeholder', placeholder);
}

function onFormEvent(event: FormEvent, formKey: 'credit' | 'collateral' = 'collateral') {
  if (event.event === 'form-field-set-value') {
    const changedForm = forms[formKey];
    const reactingForm = forms[formKey === 'collateral' ? 'credit' : 'collateral'];
    const reactingFormModel = getChildModel(reactingForm.form, 'value')!;
    reactingForm.form.value = '';
    reactingForm.validation?.$reset();

    if (!changedForm.validation?.$invalid) {
      setFormPlaceholder(reactingFormModel);
      debouncedCalc(changedForm, reactingForm);
    } else {
      setFormPlaceholder(reactingFormModel, '');
    }
  }
}

watch(
  () => props.tradeFundingDetails,
  () => {
    if (props.tradeFundingDetails) {
      forms.collateral.form.value = props.tradeFundingDetails.initialMarginCollateralFundingAmount;
      forms.credit.form.value = props.tradeFundingDetails.initialMarginCreditFundingAmount;
    }
  },
  { immediate: true }
);

watch(
  creditAtZero,
  () => {
    setState(getChildModel(forms.collateral.form, 'value')!, 'readonly', creditAtZero.value);
    setState(getChildModel(forms.credit.form, 'value')!, 'readonly', creditAtZero.value);
  },
  { immediate: true }
);

onBeforeUnmount(() => {
  debouncedCalc.cancel();
});

defineExpose({ isValid });
</script>

<template>
  <VRow>
    <VCol cols="8">
      <h3>Collateral Contribution</h3>
      <DataRow :label="`${currency} Available Posted Collateral`" class="mt-4" cols="8">
        {{ formatCurrencyValue(collaterals?.initialMarginCollateralAvailable ?? 0) }}
      </DataRow>
    </VCol>
    <VCol cols="4">
      <h3>Amount to Use</h3>
      <ValidatedForm
        :fm="forms.collateral.form"
        :validation.sync="forms.collateral.validation"
        @form-event="onFormEvent($event)"
      >
        <template #collateralContributionForm.value:prepend>
          <div class="currency-prepend">{{ currency }}</div>
        </template>
        <template #collateralContributionForm.value:errors-prepend>
          <div class="form-error-wrapper small">
            <div>
              <IconAlertCircle class="icon text-danger" />
            </div>
            <div class="text-danger font-weight-bold">Invalid amount entered.</div>
          </div>
        </template>
      </ValidatedForm>
    </VCol>

    <ExpandTransition appear>
      <VCol cols="12" v-if="shouldBeAbleToPost">
        <div class="form-error-wrapper">
          <div>
            <IconAlertCircle class="icon text-danger" />
          </div>
          <div class="text-secondary">
            <template v-if="!allCollateralUsed"> You need to post collateral to execute this trade. </template>
            <template v-else>
              You are using all of your available collateral. Post more collateral if you don't want to overextend your
              credit.
            </template>
          </div>
        </div>
        <div class="post-collateral-block">
          <h2>Post collateral</h2>
          <CollateralTransactionExecutor btnTitle="Post Collateral" mode="post" />
        </div>
      </VCol>
    </ExpandTransition>

    <VCol cols="8">
      <h3>Credit Contribution</h3>
      <DataRow :label="`${currency} Available Credit`" class="mt-4" cols="8">
        {{ formatCurrencyValue(collaterals?.initialMarginCreditAvailable ?? 0) }}
      </DataRow>
    </VCol>
    <VCol cols="4">
      <h3>Amount to Use</h3>
      <ValidatedForm
        :fm="forms.credit.form"
        :validation.sync="forms.credit.validation"
        @form-event="onFormEvent($event, 'credit')"
      >
        <template #creditContributionForm.value:prepend>
          <div class="currency-prepend">{{ currency }}</div>
        </template>
        <template #creditContributionForm.value:errors-prepend>
          <div class="form-error-wrapper small">
            <div>
              <IconAlertCircle class="icon text-danger" />
            </div>
            <div class="text-danger font-weight-bold">Invalid amount entered.</div>
          </div>
        </template>
      </ValidatedForm>
    </VCol>

    <ExpandTransition appear v-if="creditAtZero">
      <VCol cols="12">
        <div class="form-error-wrapper">
          <div>
            <IconAlertCircle class="icon text-danger" />
          </div>
          <div class="text-secondary">You do not have Credit to contribute to this trade.</div>
        </div>
      </VCol>
    </ExpandTransition>
  </VRow>
</template>

<style lang="scss" scoped>
.post-collateral-block {
  border: 1px solid;
  @include themedBorderColor($color-primary, $color-dark-primary);
  padding: $padded-space;
  margin-bottom: 1em;
}

.currency-prepend {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.form-error-wrapper {
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  margin: 0 0 1em 1em;
  .icon {
    width: 2rem;
    height: auto;
    margin-right: 1rem;
  }

  &.small {
    margin-left: 0;
    .icon {
      width: 1.2rem;
      margin-right: 0.2rem;
    }
  }
}
</style>
