<script lang="ts" setup>
import CreateTradeModal from 'ah-trades/src/components/modals/CreateTradeModal.vue';
import AddMoneyModal from 'ah-wallets/src/components/AddMoneyModal.vue';
import { AuthorityType } from 'ah-api-gateways';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, reactive } from 'vue';
import { useTradeState } from '../..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

withDefaults(
  defineProps<{
    totalEquivalent?: number;
  }>(),
  {
    totalEquivalent: 0,
  }
);

const requestManager = useRequestManager({
  exposeToParent: ['loadWallets'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadWallets') {
      loadData();
    }
  },
});

const tradeState = useTradeState();

const onBehalfOfClient = useOnBehalfOf();

const state = reactive<{
  allowedSellCurrencies: string[];
  currencies: string[];
}>({
  allowedSellCurrencies: [],
  currencies: ['GBP', 'EUR', 'USD'],
});

const openTradeAllowed = computed(
  () => onBehalfOfClient.value || tradeState.store.useAuthStore().hasAuthorities(AuthorityType.OPEN_TRADE)
);

const requestPricesAllowed = computed(
  () => onBehalfOfClient.value || tradeState.store.useAuthStore().hasAuthorities(AuthorityType.REQUEST_PRICES)
);

const canSeeFundingDetails = computed(
  () => onBehalfOfClient.value || tradeState.store.useAuthStore().hasAuthorities(AuthorityType.VIEW_FUNDING_DETAILS)
);

const collaterals = computed(() => {
  const clientId = onBehalfOfClient.value
    ? onBehalfOfClient.value.id
    : tradeState.store.useAuthStore().loggedInIdentity?.client?.id;

  if (clientId) {
    return tradeState.store.useWalletsStore().getCollaterals(clientId);
  }
  return null;
});
function loadData() {
  requestManager.manager.newPromise(
    'loadWallets',
    Promise.all(
      state.currencies.map((currency) =>
        tradeState.store.useWalletsStore().loadCurrencyWallet({
          currency,
          owner: onBehalfOfClient.value ? { isPartner: false, id: onBehalfOfClient.value.id } : undefined,
        })
      )
    )
  );

  tradeState.store
    .useSettingsStore()
    .loadTradeableCurrencies()
    .then((currencies) => {
      state.allowedSellCurrencies = currencies
        .map((c) => c.currency)
        .filter((c) => {
          return !['USD', 'EUR', 'GBP'].includes(c);
        });
    });
}

loadData();
</script>

<template>
  <div class="wallet-actions" v-if="!requestManager.manager.anyPending">
    <p>
      You do not have sufficient balance in your wallets to post the required collateral. Please add funds to your GBP,
      EUR or USD wallets and then post collateral.
    </p>
    <div class="actions mt-5">
      <slot name="actions" />
      <AddMoneyModal
        :allowedCurrencies="['GBP', 'EUR', 'USD']"
        v-if="canSeeFundingDetails"
        v-bind="$attrs"
        v-slot="{ show }"
      >
        <VButton @click="show" class="action block">Add funds to a GBP/EUR/USD wallet</VButton>
      </AddMoneyModal>
      <CreateTradeModal
        title="Convert from another currency wallet"
        v-slot="{ show }"
        :allowedBuyCurrencies="['GBP']"
        :allowedSellCurrencies="state.allowedSellCurrencies"
        forceKeep
        forceImmediateSpot
        syncTradeFunds
        v-if="openTradeAllowed && requestPricesAllowed"
        :collaterals="collaterals"
        v-bind="$attrs"
      >
        <VButton @click="show" class="action block">Convert from another currency wallet</VButton>
      </CreateTradeModal>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.action {
  min-width: 70%;
  margin: 1em auto;
}
</style>
