<script setup lang="ts">
import { AmountType, CreateVanillaOptionsPriceRequest, VanillaPriceResponse } from 'ah-api-gateways';
import OptionSolutionDetailsForm from './OptionSolutionDetailsForm.vue';
import { cloneDeep, isEqual } from 'lodash';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useTradeState } from '../..';
import { reactive, watch } from 'vue';
import { Uuid } from 'ah-common-lib/src/helpers';
import { ApiError, GenericErrorCodes, HttpError, PricingEngineErrorCodes } from 'ah-requests';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useToast } from 'ah-common-lib/src/toast';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

enum OptionsCreatorSteps {
  OPTIONS_CREATOR = 'optionCreator',
  OPTIONS_REVIEW = 'optionsReview',
  OPTIONS_BOOKED = 'optionsBooked',
}

const emit = defineEmits<{
  (e: 'remove:solution', value: VanillaPriceResponse): void;
  (e: 'update:solutions', value: VanillaPriceResponse[]): void;
  (e: 'update:stage', value: OptionsCreatorSteps): void;
  (e: 'update:selectedSolution', value: VanillaPriceResponse): void;
  (e: 'update:price', value: CreateVanillaOptionsPriceRequest, solution: VanillaPriceResponse): void;
  (e: 'update:errors', value: ApiError): void;
  (e: 'update:validations', value: any[]): void;
}>();

const props = withDefaults(
  defineProps<{
    model: CreateVanillaOptionsPriceRequest;
    showAddSolutionButton: boolean;
    solutions: VanillaPriceResponse[];
    clientId?: string;
    validations: any[];
  }>(),
  {
    showAddSolutionButton: false,
  }
);

const state = reactive<{
  solutions: Partial<VanillaPriceResponse>[];
  apiErrors: { solutionId: string; apiError: ApiError }[];
}>({
  solutions: [],
  apiErrors: [],
});

const requestManager = useRequestManager();

const tradeState = useTradeState();

const onBehalfOfClient = useOnBehalfOf();

const toast = useToast();

function loadingText(solution: Partial<VanillaPriceResponse>) {
  return isValidSolution(solution) ? 'Refreshing solution' : 'Adding Solution';
}

function isValidSolution(solution: Partial<VanillaPriceResponse>): solution is VanillaPriceResponse {
  return !solution.id?.startsWith('$temp-') ?? false;
}

watch(
  () => props.solutions,
  () => {
    state.solutions = cloneDeep(props.solutions);
  },
  { immediate: true }
);

function requestSolution(model: CreateVanillaOptionsPriceRequest, solutionId: string, options?: any) {
  return requestManager.manager.sameOrCancelAndNew(
    'createVanillaOptions' + solutionId,
    tradeState.services.pricingEngine.createVanillaOptionsQuote(model, onBehalfOfClient.value?.id, options).pipe(
      catchError((e) => {
        const error = e.response?.data;
        if (error && error.code === GenericErrorCodes.VALIDATION_ERROR) {
          if (error.subErrors && error.subErrors.length) {
            for (let i = 0; i < error.subErrors.length; i++) {
              const subError = error.subErrors[i];
              if (subError.category === 'VALIDATION' && subError.code === PricingEngineErrorCodes.EXPIRY_DATE_INVALID) {
                state.apiErrors === null ? [state.apiErrors] : state.apiErrors.push({ solutionId, apiError: subError });
                return of(null);
              }
            }
          }
        }
        throw e;
      })
    )
  );
}

function requestNewPrices() {
  const tempSolution = { id: Uuid.generateTempUUID() };
  state.solutions.unshift(tempSolution);

  requestSolution(
    {
      ...props.model,
      clientId: props.clientId,
    },
    tempSolution.id,
    undefined
  ).subscribe(
    (price) => {
      if (price) {
        const tempIndex = state.solutions.findIndex((sol) => sol.id === tempSolution.id);

        const premiumCurrency = price.ccy1.amountType === AmountType.SELL ? price.ccy1.currency : price.ccy2.currency;
        state.solutions[tempIndex] = { ...price, premiumCurrency };

        emit('update:solutions', state.solutions as VanillaPriceResponse[]);
      }
    },
    (error: HttpError) => {
      const tempIndex = state.solutions.findIndex((sol) => sol.id === tempSolution.id);
      state.solutions.splice(tempIndex, 1);
      if (error.response) {
        emit('update:errors', error.response.data);
      }
    }
  );
}

function reloadPrices(request: CreateVanillaOptionsPriceRequest, solutionId: string) {
  requestSolution(
    {
      ...request,
      clientId: props.clientId,
    },
    solutionId,
    {
      errors: { silent: true },
    }
  ).subscribe(
    (price) => {
      if (price) {
        const index = props.solutions.findIndex((sol) => sol.id === solutionId);
        const solutions = cloneDeep(props.solutions);

        const validations = props.validations.filter((val) => val.id === solutionId);

        let premiumCurrency;
        if (solutions[index].premiumCurrency) {
          premiumCurrency = solutions[index].premiumCurrency;
        } else {
          premiumCurrency = price.ccy1.amountType === AmountType.SELL ? price.ccy1.currency : price.ccy2.currency;
        }
        solutions[index] = { ...price, premiumCurrency };

        emit('update:solutions', solutions);
        emit('update:validations', validations);

        state.apiErrors = state.apiErrors.filter((apiError) => !isEqual(apiError.solutionId, solutionId));
      }
    },
    (error) => {
      if (error.response && error.response.status) {
        if ([409, 400].includes(error.response.status)) {
          toast.info(error.response.data.message);
          return;
        } else if (error.response.status === 422) {
          toast.error(error.response.data.message);
          return;
        }
      }
      toast.error('An unexpected problem has occurred. Please try again later.');
    }
  );
}

function retryReloadPrices(solution: Partial<VanillaPriceResponse>) {
  if (solution.id) {
    reloadPrices(props.model, solution.id);
  }
}

function sortSolution(delta: number, solution: VanillaPriceResponse) {
  const index = state.solutions.indexOf(solution);
  const newPosition = index + delta;

  if (newPosition >= 0 && newPosition <= state.solutions.length) {
    let solutionAux = state.solutions.filter((sol) => !isEqual(sol, solution)).slice(0, newPosition);
    let solutionAux1 = state.solutions.filter((sol) => !isEqual(sol, solution)).slice(newPosition);

    solutionAux.length === 0 ? (solutionAux = [solution]) : solutionAux.push(solution);
    solutionAux = solutionAux.concat(solutionAux1);

    emit('update:solutions', solutionAux as VanillaPriceResponse[]);
  }
}

function deleteSolution(solution: VanillaPriceResponse) {
  state.solutions = state.solutions.filter((sol) => !isEqual(sol, solution));
  emit('update:solutions', state.solutions as VanillaPriceResponse[]);
}

function isFirst(index: number) {
  return index === 0;
}

function isLast(index: number) {
  return index === props.solutions.length - 1;
}

function selectedSolution(solution: VanillaPriceResponse) {
  emit('update:stage', OptionsCreatorSteps.OPTIONS_REVIEW);
  emit('update:selectedSolution', solution);
}

function updateSolution(solution: VanillaPriceResponse, id: string) {
  const index = props.solutions.findIndex((sol) => sol.id === id);
  const solutions = cloneDeep(props.solutions);

  solutions[index] = solution;
  emit('update:solutions', solutions);
}

function apiErrors(solutionId: string) {
  return state.apiErrors.find((e) => e.solutionId === solutionId)?.apiError;
}

function updateValidation(isValid: boolean, solutionId: string) {
  let validations = cloneDeep(props.validations);
  const index = props.validations.findIndex((validation) => validation.solutionId === solutionId);

  if (index >= 0) {
    validations[index].validation = isValid;
  } else {
    validations.length === 0
      ? (validations = [{ solutionId, validation: isValid }])
      : validations.push({ solutionId, validation: isValid });
  }

  emit('update:validations', validations);
}

function isInvalid(solutionId: string) {
  const index = props.validations.findIndex((validation) => validation.solutionId === solutionId);

  return index >= 0 ? props.validations[index].validation : false;
}

defineExpose({ reloadPrices, requestNewPrices });
</script>

<template>
  <TransitionGroup name="solutions-list" tag="div" class="solutions">
    <BoxGridItem md="6" sm="12" class="mb-5" v-for="(solution, index) in state.solutions" :key="solution.id">
      <BoxGridBlock
        :loadingOverlayProps="{
          state: requestManager.manager.requestStates[`createVanillaOptions${solution.id}`],
          loadingText: loadingText(solution),
          class: 'loading-solution',
          opacity: '0',
          hideOnLoading: true,
          showRetry: true,
        }"
        useLoadingOverlay
        overlayType="simple"
        cols="12"
        align-self="stretch"
        class="solutions-list-item"
        @retry="retryReloadPrices(solution)"
      >
        <div v-if="isValidSolution(solution)">
          <OptionSolutionDetailsForm
            :solution="solution"
            :isFirst="isFirst(index)"
            :isLast="isLast(index)"
            :index="index"
            :key="solution.id"
            :apiError="apiErrors(solution.id)"
            @sort:solutions="sortSolution($event, solution)"
            @remove:solution="deleteSolution(solution)"
            @update:price="reloadPrices($event, solution.id)"
            @update:solution="updateSolution($event, solution.id)"
            @update:validation="updateValidation($event, solution.id)"
          />
        </div>
      </BoxGridBlock>
      <VButton
        @click="selectedSolution(solution)"
        v-if="isValidSolution(solution)"
        :loading="requestManager.manager.requestStates[`createVanillaOptions${solution.id}`] === 'pending'"
        :disabled="
          requestManager.manager.requestStates[`createVanillaOptions${solution.id}`] === 'error' ||
          apiErrors(solution.id) ||
          isInvalid(solution.id)
        "
      >
        Select Trade
      </VButton>
    </BoxGridItem>
  </TransitionGroup>
</template>

<style lang="scss" scoped>
.solutions {
  display: flex;
  flex-wrap: wrap;
}

.solutions-list-leave-active {
  position: absolute;
}

.solutions-list-enter-active,
.solutions-list-leave-active {
  transition: all 0.25s;
}

.solutions-list-enter-move {
  transition: all 0.25s;
}

.solutions-list-move {
  transition: all 0.25s;
}

.solutions-list-enter,
.solutions-list-leave-to {
  opacity: 0;
}
.solutions-list-item {
  ::v-deep .box-grid-item {
    min-height: 50rem;
  }
}
</style>
