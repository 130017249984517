<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="currentColor"
          d="M24,14.14V8.08h0V3.44a2,2,0,0,0-2-2H21A2,2,0,0,0,19.13,0H5.05a2,2,0,0,0-2,2V3.77H2.54a2.54,2.54,0,0,0,0,5.08h.53v4.6H2.54a2.54,2.54,0,0,0,0,5.08h.53v2.7a2,2,0,0,0,2,2H19.13a2,2,0,0,0,2-2v-.44h1a2,2,0,0,0,2-2V14.15h0ZM21.1,8.63h1.81v5H21.1Zm1.81-5.19V7.51H21.1V2.58h1A.85.85,0,0,1,22.91,3.44ZM2.54,7.73a1.42,1.42,0,0,1,0-2.84h.53V7.73ZM4.19,4.89H5.91a.56.56,0,0,0,0-1.12H4.19V2a.86.86,0,0,1,.86-.86H19.13A.85.85,0,0,1,20,2V19.67H4.19v-5.1H5.91a.56.56,0,0,0,0-1.12H4.19ZM2.54,17.41a1.42,1.42,0,0,1,0-2.84h.53v2.84ZM20,21.23a.85.85,0,0,1-.85.86H5.05a.86.86,0,0,1-.86-.86v-.44H20Zm2.08-1.56h-1v-5h1.81v4.1A.85.85,0,0,1,22.06,19.67Z"
        />
        <path
          fill="currentColor"
          d="M12.23,14.76a7.79,7.79,0,0,0,3.82-1,.56.56,0,0,0,.27-.51A4.06,4.06,0,0,0,13.93,9.7a2.56,2.56,0,0,0,.83-1.86A2.53,2.53,0,1,0,10.53,9.7a4.08,4.08,0,0,0-2.4,3.58.57.57,0,0,0,.28.51A7.77,7.77,0,0,0,12.23,14.76Zm0-8.33a1.41,1.41,0,1,1-1.41,1.41A1.41,1.41,0,0,1,12.23,6.43Zm0,4A3,3,0,0,1,15.17,13a7.12,7.12,0,0,1-5.89,0A3,3,0,0,1,12.23,10.44Z"
        />
      </g>
    </g>
  </svg>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
