<script setup lang="ts">
import AddMoneyModal from './AddMoneyModal.vue';
import CreateTradeModal from 'ah-trades/src/components/modals/CreateTradeModal.vue';
import DownloadStatementModal from 'ah-wallets/src/components/transactions/DownloadStatementModal.vue';
import WalletInfoTitle from './WalletInfoTitle.vue';
import WithdrawFromWalletModal from './WithdrawFromWalletModal.vue';
import { AuthorityType, Client } from 'ah-api-gateways';
import { useWalletState } from '..';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, inject, reactive, watch } from 'vue';
import { ON_BEHALF_OF_CLIENT_INJECT_KEY } from 'ah-common-lib/src/onBehalfOf/constants';

const walletState = useWalletState();

const requestManager = useRequestManager({
  exposeToParent: ['loadWallet'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadWallet') {
      loadWallet();
    }
  },
});

const props = withDefaults(
  defineProps<{
    walletId: string;
    readonly?: boolean | string;
  }>(),
  {
    readonly: false,
  }
);

const state = reactive<{
  onBehalfOfClient: Client | null;
  tabIndex: number;
}>({
  onBehalfOfClient: inject(ON_BEHALF_OF_CLIENT_INJECT_KEY, null),
  tabIndex: 0,
});

const wallet = computed(() => walletState.store.useWalletsStore().getWalletById(props.walletId));

const emit = defineEmits<{
  /**
   * when the back button is pressed
   */
  (e: 'back', value: void): void;
  /**
   * when a withdraw or a payment is made
   */
  (e: 'wallet-changed', value: void): void;
  /**
   * when a trade is finished
   */
  (e: 'trade-completed', value: void): void;
}>();

const canSeeFundingDetails = computed(() =>
  walletState.store.useAuthStore().hasAuthorities(AuthorityType.VIEW_FUNDING_DETAILS)
);

const openTradeAllowed = computed(
  () => state.onBehalfOfClient || walletState.store.useAuthStore().hasAuthorities(AuthorityType.OPEN_TRADE)
);

const requestPricesAllowed = computed(
  () => state.onBehalfOfClient || walletState.store.useAuthStore().hasAuthorities(AuthorityType.REQUEST_PRICES)
);

const withdrawAllowed = computed(() => !isReadonly.value && !state.onBehalfOfClient);

const sendMoneyAllowed = computed(
  () =>
    state.onBehalfOfClient ||
    (walletState.store.useAuthStore().hasAuthorities(AuthorityType.MAKE_PAYMENTS) && !isReadonly.value)
);

const isReadonly = computed(() => props.readonly !== false);

const selectedCurrency = computed(() => wallet.value?.currency ?? undefined);

const isClient = computed(() => walletState.store.useAuthStore().isClientUser);

function loadWallet() {
  requestManager.manager.newPromise(
    'loadWallet',
    walletState.store.useWalletsStore().loadWallet({ id: props.walletId, force: true })
  );
}

watch(() => props.walletId, loadWallet);

function onWithdraw() {
  emit('wallet-changed');
}

function onPaymentMade() {
  emit('wallet-changed');
}

function onTradeCompleted() {
  emit('trade-completed');
}

function goBack() {
  emit('back');
}
</script>

<template>
  <div class="wallet-header" v-if="wallet">
    <div class="menu-wrapper">
      <BDropdown variant="primary" no-caret inline toggle-class="menu-button" right>
        <template #button-content><IconDots /></template>
        <WithdrawFromWalletModal
          :wallet="wallet"
          selfBeneficiary
          v-slot="{ show }"
          @withdraw-succeded="onWithdraw"
          v-if="withdrawAllowed"
        >
          <BDropdownItem @click="show" class="mb-3"><IconDocument /> Withdraw Money</BDropdownItem>
        </WithdrawFromWalletModal>

        <WithdrawFromWalletModal
          :wallet="wallet"
          v-slot="{ show }"
          @payment-succeded="onPaymentMade"
          v-if="sendMoneyAllowed"
        >
          <BDropdownItem @click="show" class="mb-3"><IconDocument /> Make Payment</BDropdownItem>
        </WithdrawFromWalletModal>

        <DownloadStatementModal :wallet="wallet" v-slot="{ show }">
          <BDropdownItem @click="show" class="mb-3"><IconDocument /> Statement</BDropdownItem>
        </DownloadStatementModal>

        <CreateTradeModal
          v-slot="{ show }"
          @flow-completed="onTradeCompleted"
          v-if="openTradeAllowed && requestPricesAllowed && !isReadonly"
          forceImmediateSpot
          syncTradeFunds
          forceKeep
        >
          <BDropdownItem @click="show" class="mb-3"><IconDocument /> Exchange</BDropdownItem>
        </CreateTradeModal>

        <AddMoneyModal
          :currency="selectedCurrency"
          v-if="!isReadonly && isClient && canSeeFundingDetails"
          v-slot="{ show }"
        >
          <BDropdownItem @click="show"><IconDocument /> Fund Account</BDropdownItem>
        </AddMoneyModal>
      </BDropdown>
    </div>
    <BackButton label="go back" class="d-block px-0 py-0 mb-3" @click="goBack()" />
    <WalletInfoTitle :wallet="wallet" titleTag="p" />
  </div>
</template>

<style lang="scss" scoped>
.wallet-header {
  position: relative;

  ::v-deep .back-button > .label > * {
    font-size: 10px;
  }

  .menu-wrapper {
    position: absolute;
    right: 0;
    ::v-deep .menu-button {
      padding-right: 0.5em;
      padding-left: 0.5em;

      svg {
        width: 1.4em;
      }
    }
    ::v-deep .dropdown {
      button {
        @include themedBackgroundColor($color-nav-list-link, $color-dark-primary, '', ' !important');
      }
      .dropdown-menu {
        @include themedBackgroundColor($color-box-bg, $color-dark-box-bg, '', ' !important');
        a,
        path {
          @include themedPropColor('fill', $color-text, $color-dark-text);
          @include themedTextColor($color-text, $color-dark-text);
        }
        a:hover {
          path {
            @include themedPropColor('fill', $color-dark-text, $color-dark-text);
          }
          @include themedTextColor($color-dark-text, $color-dark-text);
          @include themedBackgroundColor($color-nav-list-link, $color-dark-primary, '', ' !important');
        }
      }
    }
  }
}
</style>
