<script setup lang="ts">
import WalletsDashboardWidget from 'ah-wallets/src/components/WalletsDashboardWidget.vue';
import NotificationsDashboardWidget from '@/app/components/notifications/NotificationsDashboardWidget.vue';
import OpenPositionsDashboardWidget from '@/app/components/trades/widgets/OpenPositionsDashboardWidget.vue';
import CollateralStatusDashboardWidget from 'ah-trades/src/components/collaterals/CollateralStatusDashboardWidget.vue';
import LimitsAndCollateralDashboardWidget from 'ah-trades/src/components/limitsAndCollateral/LimitsAndCollateralDashboardWidget.vue';
// import LimitsDashboardWidget from 'ah-trades/src/components/collaterals/LimitsDashboardWidget.vue';
import PaymentsDashboardWidget from '@/app/components/payments/PaymentsDashboardWidget.vue';
import { AuthorityType } from 'ah-api-gateways';
import { useAuthStore } from '@/app/store/authStore';
import { FeatureFlag } from 'ah-api-gateways';
import { useFeatureFlag } from 'ah-common-stores/src/stores/featureFlagStore';

const authStore = useAuthStore();

const { isActive: isVariationMarginFeatureActive } = useFeatureFlag({
  featureFlag: FeatureFlag.VARIATION_MARGIN_AND_LIMITS_FEATURE,
});

function canViewTrades() {
  authStore.hasAuthorities(AuthorityType.VIEW_TRADES);
}
</script>

<template>
  <div class="dashboard-view padded-space mt-3" id="dashboard-view">
    <BoxGrid align-h="start">
      <BoxGridItem cols="12">
        <BoxGrid align-h="start">
          <BoxGridItem align-self="stretch" cols="12" lg="8">
            <NotificationsDashboardWidget class="mb-3" />
          </BoxGridItem>
          <BoxGridBlock align-self="stretch" cols="12" lg="4" title="Upcoming payments">
            <PaymentsDashboardWidget />
          </BoxGridBlock>
          <BoxGridBlock align-self="stretch" v-if="canViewTrades" cols="12" title="Open FX Positions">
            <OpenPositionsDashboardWidget class="open-positions-widget" />
          </BoxGridBlock>
          <BoxGridBlock
            align-self="stretch"
            class="pr-3"
            cols="12"
            md="4"
            title="Wallets"
            :loadingOverlayProps="{ showRetry: true }"
            useLoadingOverlay
          >
            <WalletsDashboardWidget redirect-to="dashboard/wallets" />
          </BoxGridBlock>
          <BoxGridBlock
            v-if="isVariationMarginFeatureActive"
            cols="12"
            md="4"
            class="px-3"
            title="Trading Limits and Collateral"
            align-self="stretch"
            :loadingOverlayProps="{ showRetry: true }"
            useLoadingOverlay
          >
            <LimitsAndCollateralDashboardWidget redirect-to="dashboard/trades/limits-collateral" />
          </BoxGridBlock>
          <BoxGridBlock
            v-else
            cols="12"
            md="4"
            class="px-3"
            title="Margin Status"
            align-self="stretch"
            :loadingOverlayProps="{ showRetry: true }"
            useLoadingOverlay
          >
            <CollateralStatusDashboardWidget redirect-to="dashboard/trades/collateral-management" />
          </BoxGridBlock>
          <!-- TODO EC: this will be analyzed to determine what to do to this, but for now we need to remove it
            because the endpoint properties were changed.
          -->
          <!-- <BoxGridBlock
            cols="12"
            class="pl-3"
            md="4"
            title="Position Limits"
            align-self="stretch"
            :loadingOverlayProps="{ showRetry: true }"
            useLoadingOverlay
          >
            <LimitsDashboardWidget redirect-to="dashboard/trades/position-limits" />
          </BoxGridBlock> -->
        </BoxGrid>
      </BoxGridItem>
    </BoxGrid>
  </div>
</template>
