<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.3875 1.68359C12.1206 1.68359 12.8294 1.79625 13.4965 2.0058C13.2246 2.42994 13.0027 2.88931 12.8393 3.37553C12.3752 3.24777 11.8872 3.17969 11.3844 3.17969C8.55286 3.17969 6.18854 5.33882 5.93219 8.15871L5.41491 13.8487C5.31501 14.9476 4.65365 15.9166 3.66671 16.4101C3.24696 16.6199 2.98438 17.0448 2.98438 17.5095V17.9521C2.98438 18.432 3.37342 18.8211 3.85334 18.8211H18.9154C19.3953 18.8211 19.7844 18.432 19.7844 17.9521V17.5095C19.7844 17.0448 19.5218 16.6199 19.1061 16.4121C18.1151 15.9166 17.4537 14.9476 17.3538 13.8487L17.1278 11.3621C17.6329 11.4967 18.1633 11.5692 18.7103 11.5711L18.8976 13.6306C18.9418 14.1178 19.235 14.5473 19.6726 14.7661L19.6765 14.7681C20.6562 15.2579 21.275 16.2592 21.275 17.3545V17.7861C21.275 19.1903 20.1367 20.3286 18.7325 20.3286H14.4941V20.8925C14.4941 22.6087 13.1029 24 11.3866 24C9.67041 24 8.27914 22.6087 8.27914 20.8925V20.3286H4.0425C2.63832 20.3286 1.5 19.1903 1.5 17.7861V17.3545C1.5 16.2592 2.11883 15.2579 3.09849 14.7681L3.10245 14.7661C3.53998 14.5473 3.83317 14.1178 3.87746 13.6306L4.38196 8.08117C4.71135 4.45787 7.74927 1.68359 11.3875 1.68359ZM9.89924 20.3286V20.8925C9.89924 21.6726 10.6065 22.425 11.3866 22.425C12.1667 22.425 12.8542 21.6726 12.8542 20.8925V20.3286H9.89924ZM14.8831 4.4435C15.0053 3.92698 15.2289 3.44963 15.5315 3.03358C17.1153 4.18932 18.2034 5.99444 18.3931 8.08117L18.5044 9.30642C17.9282 9.27376 17.385 9.11772 16.9007 8.86432L16.8366 8.15871C16.7001 6.6578 15.9665 5.34409 14.8831 4.4435Z"
      fill="currentColor"
    />
    <path
      d="M18.7323 9.30844C16.5481 9.30844 14.7773 7.53773 14.7773 5.35344C14.7773 3.16915 16.5481 1.39844 18.7323 1.39844C20.9166 1.39844 22.6873 3.16915 22.6873 5.35344C22.6873 7.53773 20.9166 9.30844 18.7323 9.30844Z"
      fill="#EF6172"
    />
  </svg>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
