import { DocumentExport, DocumentExportOptions } from '../models';
import { HttpBasedService, HttpService, downloadFile } from 'ah-requests';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export class TermsheetService extends HttpBasedService {
  constructor(http: HttpService, protected baseUrl: string, group = 'termsheetService') {
    super(http, {
      options: {
        errors: { messageDefaults: { group } },
      },
    });
  }

  public exportVanillaOptionsTermsheet(
    priceId: string,
    premiumCurrency: string,
    exportOptions: DocumentExportOptions
  ): Observable<DocumentExport> {
    const { fileFormat, documentTitle, backgroundExport } = exportOptions;
    const url = `${this.baseUrl}termsheets/vanilla-option/price/${priceId}/export`;

    return this.downloadList(url, premiumCurrency, {
      fileFormat,
      documentTitle,
      backgroundExport,
    });
  }

  public downloadSyncVanillaOptionsTermsheet(tradeId: string): Observable<void> {
    return this.rawRequest<any>({
      axiosConfig: {
        method: 'get',
        responseType: 'arraybuffer',
        url: `${this.baseUrl}termsheets/vanilla-option/trade/${tradeId}/download`,
      },
    }).pipe(
      map((response) => {
        downloadFile(response);
      })
    );
  }

  private downloadList(
    url: string,
    premiumCurrency: string,
    exportOptions: DocumentExportOptions
  ): Observable<DocumentExport> {
    const { fileFormat, documentTitle, backgroundExport } = exportOptions;

    return this.get<DocumentExport>(url, {
      axiosConfig: {
        params: {
          premiumCurrency,
          fileFormat,
          documentTitle,
          backgroundExport,
        },
      },
    });
  }
}
