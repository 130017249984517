<script lang="ts" setup>
import { Trade, tradeStatusBadges, TradeStatus, tradeStateBadges, TradeState } from 'ah-api-gateways';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    item: Trade;
    showIcon?: boolean | string;
  }>(),
  {
    showIcon: true,
  }
);

const isTradeRejectedOrCanceled = computed(() => {
  return (
    [TradeStatus.REJECTED, TradeStatus.CANCELED].includes(props.item.status) ||
    [TradeState.CLOSED_REJECTED, TradeState.CLOSED_CANCELED].includes(props.item.state)
  );
});

const tradeStatusClass = computed(() => tradeStatusBadges[props.item.status]?.class);

const tradeStatus = computed(() => tradeStatusBadges[props.item.status]?.label);

const tradeStateClass = computed(() => tradeStateBadges[props.item.state]?.class);

const tradeState = computed(() => tradeStateBadges[props.item.state]?.label);

const status = computed(() => {
  return tradeStatus.value || tradeState.value || 'Unknown';
});

const tradeClass = computed(() => {
  return tradeStatusClass.value || tradeStateClass.value;
});
</script>

<template>
  <span :class="['trade-status', tradeClass]">
    <IconAlertCircle class="alert-icon mr-1" v-if="isTradeRejectedOrCanceled && showIcon !== false" />
    {{ status }}
  </span>
</template>

<style lang="scss" scoped>
.alert-icon {
  @include themedTextColor($color-danger, $color-dark-danger);
  font-size: 25px;
}

.trade-status {
  &.black {
    color: $black;
  }

  &.orange,
  &.light-orange {
    color: $orange;
  }

  &.blue,
  &.light-blue {
    color: $skyBlue;
  }

  &.light-green,
  &.green {
    color: $green;
  }

  &.light-grey {
    color: $lightGrey;
  }

  &.light-red {
    color: $red;
  }

  &.red {
    font-weight: $font-weight-bold;
    color: $red;
  }

  &.purple,
  &.light-purple {
    color: $purple;
  }
}
</style>
