import {
  ExportListType,
  ListQuery,
  PaginatedQuery,
  PaginatedResponse,
  standardiseQuerySort,
  TradeReport,
  SettlementReport,
  PnLTradeReport,
  PnLTotal,
  DocumentExport,
  EomReport,
  EomClientReport,
  OpenPosition,
  OpenPositionTotal,
  MarginUtility,
  MarginUtilityClient,
  TradeMarginUtility,
  DailyStatementReport,
  DailyStatementClientReport,
  DailyStatementClientTotals,
  MarginUtilityClientTotals,
  DailyStatementOpenPositionsReport,
  DailyStatementReportTotals,
  OpenPositionSettlementDate,
  OpenPositionTrade,
  SettlementWatch,
  SettlementWatchReportFilters,
  SettlementWatchByClient,
  SettlementWatchByBankAccount,
  SettlementWatchByTrade,
  SettlementWatchByDate,
  OpenPositionsParentTrade,
} from '../models';
import { HttpBasedService, HttpOptions, HttpService } from 'ah-requests';

export class TradeReportsService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'tradeReportsService' } },
      },
    });
  }

  public listTrades(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<TradeReport>>(`${this.baseUrl}trading-reports/trades-list`, {
      axiosConfig: { params },
    });
  }

  public downloadTradeList(query: ListQuery, fileFormat: ExportListType, documentTitle = 'Trading Report') {
    query = standardiseQuerySort(query);
    return this.downloadList(`${this.baseUrl}trading-reports/trades-list/export`, query, fileFormat, documentTitle);
  }

  public listUpcomingSettlements(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<SettlementReport>>(`${this.baseUrl}trading-reports/upcoming-settlement`, {
      axiosConfig: { params },
    });
  }

  public downloadUpcomingSettlements(
    query: ListQuery,
    fileFormat: ExportListType,
    documentTitle = 'Upcoming Settlements Report'
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(
      `${this.baseUrl}trading-reports/upcoming-settlement/export`,
      query,
      fileFormat,
      documentTitle
    );
  }

  public listPnLReport(
    params: PaginatedQuery,
    listingMode: 'partners' | 'clients' | 'products' | 'trades' = 'partners',
    options?: Partial<HttpOptions<any>>
  ) {
    params = standardiseQuerySort(params);

    return this.get<PaginatedResponse<PnLTradeReport>>(
      `${this.baseUrl}trading-reports/profit-and-losses/${listingMode}`,
      {
        options,
        axiosConfig: { params },
      }
    );
  }

  public listPnLReportTotals(params: PaginatedQuery, options?: Partial<HttpOptions<any>>) {
    params = standardiseQuerySort(params);

    return this.get<PaginatedResponse<PnLTotal>>(`${this.baseUrl}trading-reports/profit-and-losses/total`, {
      options,
      axiosConfig: { params },
    });
  }

  public listEomReport(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<EomReport>>(`${this.baseUrl}eom/voice-trades-client/wrapped`, {
      axiosConfig: { params },
    });
  }

  public downloadEomReport(
    query: ListQuery,
    fileFormat: ExportListType,
    documentTitle = 'End of Month report',
    fileName = 'vtd-eom-statement'
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(
      `${this.baseUrl}eom/voice-trades-client/wrapped/export`,
      query,
      fileFormat,
      documentTitle,
      fileName
    );
  }

  public listClientEomReport(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<EomClientReport>>(`${this.baseUrl}eom/voice-trades-client`, {
      axiosConfig: { params },
    });
  }

  public eomReportTotals(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<number>(`${this.baseUrl}eom/voice-trades-client/totals`, {
      axiosConfig: { params },
    });
  }

  public downloadClientEomReport(
    query: ListQuery,
    fileFormat: ExportListType,
    documentTitle = 'Trade End of Month report',
    fileName?: string
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(
      `${this.baseUrl}eom/voice-trades-client/export`,
      query,
      fileFormat,
      documentTitle,
      fileName
    );
  }

  public downloadPnLTradesReport(
    query: ListQuery,
    fileFormat: ExportListType,
    documentTitle = 'Profit and Loss Grouped by Trade'
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(
      `${this.baseUrl}trading-reports/profit-and-losses/trades/export`,
      query,
      fileFormat,
      documentTitle
    );
  }

  public downloadEOMStatement(query: ListQuery, documentTitle = 'End of Month statement') {
    query = standardiseQuerySort(query);
    return this.downloadList(
      `${this.baseUrl}trading-reports/eom/statement/export`,
      /**
       * Expects eomStatementMonth to be a one based index for the month of the year
       */
      query,
      ExportListType.PDF,
      documentTitle
    );
  }

  public listMaginUtility(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<MarginUtility>>(`${this.baseUrl}voice-trading/margin-utility/grouped`, {
      axiosConfig: { params },
    });
  }

  public listMaginUtilityClient(params: PaginatedQuery, clientId: string) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<MarginUtilityClient>>(`${this.baseUrl}voice-trading/margin-utility/${clientId}`, {
      axiosConfig: { params },
    });
  }

  public getMarginUtilityClientTotals(params: PaginatedQuery, clientId: string) {
    params = standardiseQuerySort(params);
    return this.get<MarginUtilityClientTotals>(`${this.baseUrl}voice-trading/margin-utility/${clientId}/totals`, {
      axiosConfig: { params },
    });
  }

  public listMaginUtilityClientTrade(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<TradeMarginUtility>>(`${this.baseUrl}voice-trading/margin-utility`, {
      axiosConfig: { params },
    });
  }

  public downloadMarginUtility(
    query: ListQuery,
    fileFormat?: ExportListType,
    documentTitle = 'Margin Utility',
    fileName = 'vtd-margin-utility'
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(
      `${this.baseUrl}voice-trading/margin-utility/grouped/export`,
      query,
      fileFormat,
      documentTitle,
      fileName
    );
  }

  public listOpenPositions(params: PaginatedQuery, options?: Partial<HttpOptions<any>>) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<OpenPosition>>(`${this.baseUrl}voice-trading/open-positions`, {
      options,
      axiosConfig: {
        params: {
          ...params,
        },
      },
    });
  }

  public openPositionsTotals(params: PaginatedQuery, options?: Partial<HttpOptions<any>>) {
    params = standardiseQuerySort(params);
    return this.get<OpenPositionTotal>(`${this.baseUrl}voice-trading/open-positions/totals`, {
      options,
      axiosConfig: {
        params: {
          ...params,
        },
      },
    });
  }

  public downloadOpenPositions(
    query: ListQuery,
    fileFormat?: ExportListType,
    documentTitle = 'Open Positions',
    fileName = 'vtd-open-positions'
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(
      `${this.baseUrl}voice-trading/open-positions/export`,
      query,
      fileFormat,
      documentTitle,
      fileName
    );
  }

  public clientOpenPositionsSettlementDateTotals(
    params: PaginatedQuery,
    clientId: string,
    options?: Partial<HttpOptions<any>>
  ) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<OpenPositionSettlementDate>>(
      `${this.baseUrl}voice-trading/open-positions/${clientId}/trades/settlement-date-totals`,
      {
        options,
        axiosConfig: {
          params: {
            ...params,
          },
        },
      }
    );
  }

  public listParentOpenTrades(
    params: PaginatedQuery,
    clientId: string,
    settlementDate: string,
    options?: Partial<HttpOptions<any>>
  ) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<OpenPositionsParentTrade>>(
      `${this.baseUrl}voice-trading/open-positions/${clientId}/trades/${settlementDate}/parent-trades-totals`,
      {
        options,
        axiosConfig: {
          params: {
            ...params,
          },
        },
      }
    );
  }

  public listClientOpenTrades(
    params: PaginatedQuery,
    clientId: string,
    parentTradeId: string,
    options?: Partial<HttpOptions<any>>
  ) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<OpenPositionTrade>>(
      `${this.baseUrl}voice-trading/open-positions/${clientId}/trades/${parentTradeId}`,
      {
        options,
        axiosConfig: {
          params: {
            ...params,
          },
        },
      }
    );
  }

  public clientOpenPositionsTradesTotals(
    params: PaginatedQuery,
    clientId: string,
    options?: Partial<HttpOptions<any>>
  ) {
    params = standardiseQuerySort(params);
    return this.get<Partial<OpenPositionSettlementDate>>(
      `${this.baseUrl}voice-trading/open-positions/${clientId}/trades/totals`,
      {
        options,
        axiosConfig: {
          params: {
            ...params,
          },
        },
      }
    );
  }

  public downloadOpenPositionsTrades(
    query: ListQuery,
    clientId: string,
    fileFormat?: ExportListType,
    documentTitle = 'Open Positions - Trades',
    fileName = 'vtd-open-positions'
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(
      `${this.baseUrl}voice-trading/open-positions/${clientId}/trades/export`,
      query,
      fileFormat,
      documentTitle,
      fileName
    );
  }

  public downloadMarginUtilityClient(
    query: ListQuery,
    clientId: string,
    fileFormat?: ExportListType,
    documentTitle = 'Client Margin Utility',
    fileName = 'vtd-margin-utility'
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(
      `${this.baseUrl}voice-trading/margin-utility/${clientId}/export`,
      query,
      fileFormat,
      documentTitle,
      fileName
    );
  }

  public listDailyStatement(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<DailyStatementReport>>(`${this.baseUrl}voice-trading/daily-statement`, {
      axiosConfig: { params },
    });
  }

  public downloadDailyStatement(
    query: ListQuery,
    fileFormat?: ExportListType,
    documentTitle = 'Client Daily Statement',
    fileName = 'vtd-daily-statement'
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(
      `${this.baseUrl}voice-trading/daily-statement/export`,
      query,
      fileFormat,
      documentTitle,
      fileName
    );
  }

  public listClientDailyStatement(params: PaginatedQuery, clientId: string) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<DailyStatementClientReport>>(
      `${this.baseUrl}voice-trading/daily-statement/${clientId}`,
      {
        axiosConfig: { params },
      }
    );
  }

  public clientDailyStatementTotals(params: PaginatedQuery, clientId: string) {
    params = standardiseQuerySort(params);
    return this.get<DailyStatementClientTotals>(`${this.baseUrl}voice-trading/daily-statement/${clientId}/totals`, {
      axiosConfig: { params },
    });
  }

  public downloadClientDailyStatement(
    query: ListQuery,
    clientId: string,
    fileFormat?: ExportListType,
    documentTitle = 'Client Daily Statement',
    fileName?: string
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(
      `${this.baseUrl}voice-trading/daily-statement/${clientId}/export`,
      query,
      fileFormat,
      documentTitle,
      fileName
    );
  }

  public listOpenPositionsDailyStatement(params: PaginatedQuery, clientId: string) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<DailyStatementOpenPositionsReport>>(
      `${this.baseUrl}voice-trading/daily-statement/${clientId}/open-positions`,
      {
        axiosConfig: { params },
      }
    );
  }

  public openPositionsDailyStatementTotals(params: PaginatedQuery, clientId: string) {
    params = standardiseQuerySort(params);
    return this.get<DailyStatementReportTotals>(
      `${this.baseUrl}voice-trading/daily-statement/${clientId}/open-positions/totals`,
      {
        axiosConfig: { params },
      }
    );
  }

  public listSettlementWatchTotals(params: PaginatedQuery<SettlementWatchReportFilters>) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<SettlementWatch>>(`${this.baseUrl}voice-trading/settlement-watch/totals`, {
      axiosConfig: { params },
    });
  }

  public listSettlementWatchByDates(params: PaginatedQuery<SettlementWatchReportFilters>) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<SettlementWatchByDate>>(
      `${this.baseUrl}voice-trading/settlement-watch/cashflow-dates`,
      {
        axiosConfig: { params },
      }
    );
  }

  public listSettlementWatchByBankAccounts(params: PaginatedQuery<SettlementWatchReportFilters>) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<SettlementWatchByBankAccount>>(
      `${this.baseUrl}voice-trading/settlement-watch/bank-accounts`,
      {
        axiosConfig: { params },
      }
    );
  }

  public listSettlementWatchByClients(params: PaginatedQuery<SettlementWatchReportFilters>) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<SettlementWatchByClient>>(
      `${this.baseUrl}voice-trading/settlement-watch/clients`,
      {
        axiosConfig: { params },
      }
    );
  }

  public listSettlementWatchByTrades(params: PaginatedQuery<SettlementWatchReportFilters>) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<SettlementWatchByTrade>>(`${this.baseUrl}voice-trading/settlement-watch/trades`, {
      axiosConfig: { params },
    });
  }

  public downloadSettlementWatch(
    query: ListQuery<SettlementWatch>,
    fileFormat?: ExportListType,
    documentTitle = 'Settlement Watch Report',
    fileName = 'vtd-settlements-watch'
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(
      `${this.baseUrl}voice-trading/settlement-watch/export`,
      query,
      fileFormat,
      documentTitle,
      fileName
    );
  }

  private downloadList(
    url: string,
    query: ListQuery,
    fileFormat?: ExportListType,
    documentTitle?: string,
    fileName?: string
  ) {
    return this.get<DocumentExport>(url, {
      axiosConfig: {
        params: {
          ...query,
          fileFormat,
          documentTitle,
          fileName,
        },
      },
    });
  }
}
