<script lang="ts" setup>
import { getHSLA } from 'ah-theme';
import { CommissionSettlementSchedule } from 'ah-api-gateways';
import { ScriptableContext, ChartDataset } from 'chart.js';
import format from 'date-fns/format/index';
import { makeBarChartDataset, makeBarChartOptions } from '../helpers/chartConfig';
import VChart from './VChart.vue';
import { linesColorPrimary } from '../helpers/graphColors';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { addMonths, endOfMonth, isAfter, startOfMonth } from 'date-fns';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, reactive, watch } from 'vue';
import { useReportsState } from 'ah-reports';

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadCommissionScheduleData') {
      loadCommissionScheduleData();
    }
  },
});

const reportsState = useReportsState();

const isStackedView = computed(() => reportsState.mediaQuery.is('smDown'));

const state = reactive({
  options: makeBarChartOptions(
    { horizontal: isStackedView.value, gridColor: linesColorPrimary },
    {
      plugins: {
        tooltip: {
          callbacks: {
            title(items) {
              if (items[0]) {
                return items[0].dataset.label || '';
              }
              return '';
            },
            label(item) {
              return `GBP ${formatCurrencyValue(item.parsed.y)}`;
            },
          },
        },
      },
    }
  ),
  data: makeBarChartDataset(),
  commissionSettlementScheduleData: null as CommissionSettlementSchedule | null,
});

const isDataAvailable = computed(
  () =>
    state.commissionSettlementScheduleData && state.commissionSettlementScheduleData.futureCommissionsPerWeek.length > 0
);

function loadCommissionScheduleData() {
  requestManager.manager
    .sameOrCancelAndNew('loadCommissionScheduleData', reportsState.services.trade.getSettlementScheduleReport())
    .subscribe((response) => {
      state.commissionSettlementScheduleData = response;
      setupChart();
    });
}

loadCommissionScheduleData();

/**
 * makeData, creates new 4 json objects, each representing 1 week,
 * this is done because, each section of the graph is divided into 4 sub sections, each section is a week
 * First empty json is created so that all the 4 json have same keys,
 * then all the keys are assigned values as per the conditon of that week.
 *
 * varName: is a mm-yy key combination, as to represent the x-axis labels on the graph
 */
function makeBaseData(response: CommissionSettlementSchedule) {
  const weeks: { [key: string]: number }[] = [{}, {}, {}, {}];
  const today = response.partnerProfit;
  let maxDate = new Date();
  response.futureCommissionsPerWeek.forEach((element) => {
    const date = endOfMonth(new Date(element.year, element.month - 1));
    const varName = format(date, 'MM-yyyy');
    weeks[element.week % 4][varName] = element.partnerProfit;
    maxDate = isAfter(date, maxDate) ? date : maxDate;
  });

  let currentMonth = startOfMonth(new Date());
  while (isAfter(maxDate, currentMonth)) {
    const varName = format(currentMonth, 'MM-yyyy');
    weeks.forEach((week) => (week[varName] = week[varName] || 0));
    currentMonth = addMonths(currentMonth, 1);
  }

  return { weeks, today, maxDate };
}

function makeOptions() {
  state.options = makeBarChartOptions(
    { horizontal: isStackedView.value, gridColor: linesColorPrimary },
    {
      plugins: {
        tooltip: {
          callbacks: {
            title(items) {
              if (items[0]) {
                return items[0].dataset.label || '';
              }
              return '';
            },
            label(item) {
              return `GBP ${formatCurrencyValue(item.parsed.y)}`;
            },
          },
        },
      },
    }
  );
}

function makeData() {
  if (state.commissionSettlementScheduleData) {
    const data = makeBaseData(state.commissionSettlementScheduleData);

    state.data = makeBarChartDataset();

    state.data.datasets = [
      {
        label: 'Today',
        grouped: false,
        barPercentage: 0.5,
        data: [
          {
            x: isStackedView.value ? data.today : 'Today',
            y: isStackedView.value ? 'Today' : data.today,
          },
        ],
      },
      ...data.weeks.map((week, index) => ({
        label: `Week ${index + 1}`,
        data: Object.keys(week).map((k) => ({
          x: isStackedView.value ? week[k] : k,
          y: isStackedView.value ? k : week[k],
        })) as any,
      })),
    ] as ChartDataset<'bar'>[];
    state.data.labels = ['Today'];
    let currentMonth = startOfMonth(new Date());
    while (isAfter(data.maxDate, currentMonth)) {
      state.data.labels.push(format(currentMonth, 'MM-yyyy'));
      currentMonth = addMonths(currentMonth, 1);
    }

    state.options!.indexAxis = isStackedView.value ? 'y' : 'x';
    state.data.datasets.forEach((data) => {
      data.backgroundColor = (context: ScriptableContext<'bar'>) => {
        var index = context.dataIndex;
        var value: { x: number | string; y: string | number } | undefined = context.dataset.data[index] as any;
        if (value) {
          const x = (isStackedView.value ? value.x : value.y) as number;
          const y = (isStackedView.value ? value.y : value.x) as string;
          return x < 0
            ? getHSLA('danger') // draw negative values in red
            : y === 'Today'
            ? getHSLA('primary') // blue for today
            : getHSLA('widgets-green'); // green for positive
        }
        return 'danger';
      };
    });
  }
}

function setupChart() {
  makeOptions();
  makeData();
}

watch(isStackedView, setupChart, { immediate: true });
</script>

<template>
  <div>
    <LoadingIcon v-if="requestManager.manager.requestStates.loadCommissionScheduleData === 'pending'" class="svg" />
    <div v-else-if="isDataAvailable">
      <VChart class="chart" type="bar" :options="state.options" :data="state.data" />
      <span>Future commission payouts</span>
    </div>
    <div v-else>No data found</div>
  </div>
</template>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 100%;
  padding-bottom: 33%;
  @include upToResolution($tabletResolution) {
    min-height: 20rem;
  }
}
</style>
