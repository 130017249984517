<script lang="ts" setup>
import { ToastProps } from 'ah-common-lib/src/toast';
import { useToast } from './Toast';

const toast = useToast();

defineProps<{
  toastData: ToastProps[];
  toastComponent: Vue;
}>();

function closeToast(id: string) {
  toast.remove(id);
}
</script>
<template>
  <div id="toast" class="toast-class">
    <div class="toast-content">
      <div class="b-toast" v-for="toast in toastData" :key="toast.id">
        <div class="toast toast-custom-toast">
          <Component
            :is="toastComponent"
            class="toast-body"
            v-bind="{ ...toast }"
            @close="toast.id && closeToast(toast.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.toast-class {
  position: fixed;
  top: 0 !important;
  left: 0.5rem;
  right: 0.5rem;
  margin: 0;
  padding: 0;
  height: 0;
  overflow: visible;
  z-index: 1100;
}

.toast-content {
  margin-left: auto;
  position: absolute;
  max-width: 350px;
  width: 100%;
  right: 0;
  padding: 0;
  margin: 0;
  top: 70px !important;
  right: 30px !important;
}

.toast-body {
  padding: 0 !important;
}
</style>
