import { render, staticRenderFns } from "./CollateralActions.vue?vue&type=template&id=49eb3b52&scoped=true"
import script from "./CollateralActions.vue?vue&type=script&setup=true&lang=ts"
export * from "./CollateralActions.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CollateralActions.vue?vue&type=style&index=0&id=49eb3b52&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49eb3b52",
  null
  
)

export default component.exports