<template>
  <VRow>
    <VCol class="switch-col email-col" v-if="isConfigurable(NotificationChannelType.EMAIL) !== null">
      <h4 v-if="titlesShown" class="col-inner-title">Email notification</h4>
      <BFormCheckbox
        v-if="isConfigurable(NotificationChannelType.EMAIL)"
        :checked="isTargettingEmail"
        name="check-button"
        switch
        @change="onTargetChange(NotificationChannelType.EMAIL, $event)"
      />
      <div class="mr-2" v-else-if="defaultValue(NotificationChannelType.EMAIL)">
        <IconCircleCheck class="check" />
      </div>
    </VCol>
    <VCol class="switch-col in-app-col" v-if="isConfigurable(NotificationChannelType.IN_APP) !== null">
      <h4 v-if="titlesShown" class="col-inner-title">In-app notification</h4>
      <BFormCheckbox
        v-if="isConfigurable(NotificationChannelType.IN_APP)"
        :checked="isTargettingInApp"
        name="check-button"
        switch
        @change="onTargetChange(NotificationChannelType.IN_APP, $event)"
      />
      <div class="mr-2" v-else-if="defaultValue(NotificationChannelType.IN_APP)">
        <IconCircleCheck class="check" />
      </div>
    </VCol>
    <VCol class="switch-col sms-col" v-if="isConfigurable(NotificationChannelType.SMS) !== null">
      <h4 v-if="titlesShown" class="col-inner-title">SMS notification</h4>
      <BFormCheckbox
        v-if="isConfigurable(NotificationChannelType.SMS)"
        :checked="isTargettingSms"
        name="check-button"
        switch
        @change="onTargetChange(NotificationChannelType.SMS, $event)"
      />
      <div class="mr-2" v-if="defaultValue(NotificationChannelType.SMS)">
        <IconCircleCheck class="check" />
      </div>
    </VCol>
  </VRow>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NotificationSetting, NotificationChannelType, NotificationChannelSetting } from 'ah-api-gateways';
import cloneDeep from 'lodash/cloneDeep';

@Component
export default class SingleNotificationChannelEditForm extends Vue {
  @Prop({ default: false }) showTitles!: boolean | string;

  @Prop({ required: true }) notification!: NotificationSetting;

  @Prop({ required: true }) channels!: NotificationChannelSetting[];

  get titlesShown() {
    return this.showTitles !== false;
  }

  get NotificationChannelType() {
    return NotificationChannelType;
  }

  get isTargettingEmail() {
    return !!this.notification?.channels?.find((e) => e.type === NotificationChannelType.EMAIL && e.enabled);
  }

  get isTargettingInApp() {
    return !!this.notification?.channels?.find((e) => e.type === NotificationChannelType.IN_APP && e.enabled);
  }

  get isTargettingSms() {
    return !!this.notification?.channels?.find((e) => e.type === NotificationChannelType.SMS && e.enabled);
  }

  get isConfigurable() {
    return (channelType: NotificationChannelType) => {
      const channel = this.channels.filter((channel) => channel.channelType === channelType)![0];
      if (!channel.editable) return null;
      return channel.configurable;
    };
  }

  get defaultValue() {
    return (channelType: NotificationChannelType) => {
      const channel = this.channels.filter((channel) => channel.channelType === channelType)![0];
      return channel.defaultEnabledValue;
    };
  }

  onTargetChange(type: NotificationChannelType, enabled: boolean) {
    const channels = cloneDeep(this.notification?.channels || []);
    const index = channels.findIndex((i) => i.type === type);
    if (index === -1) {
      channels.push({ type, enabled });
    } else {
      channels[index].enabled = enabled;
    }
    this.$emit('update:notification', { ...this.notification, channels });
  }
}
</script>
<style lang="scss" scoped>
.check {
  @include themedTextColor($color-success);
  width: 29px;
  height: 29px;
}
</style>
