<script setup lang="ts">
import { PaginatedQuery, Trade, openTradeStates, getCurrencyPair, formatHedgingInstrument } from 'ah-api-gateways';
import { tradeReportTableFieldsFull } from 'ah-trades/src/models/tradeTableFields';
import TradeNotionalValueCell from 'ah-trades/src/components/tableCells/TradeNotionalValueCell.vue';
import TradeFxRateCell from 'ah-trades/src/components/tableCells/TradeFxRateCell.vue';
import { formatDate, SIMPLE_DATE_FORMAT } from 'ah-common-lib/src/helpers/time';
import { defineUseManagedListingProps, useManagedListing, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { PropType, computed } from 'vue';
import { useTradeState } from '../..';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

const props = defineProps({
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    required: false,
    default: () => ({ tableFields: tradeReportTableFieldsFull }),
  },
  hidePagination: {
    type: [Boolean, String],
    required: false,
    default: false,
  },
  ...defineUseManagedListingProps<Trade>(),
});

interface TradeEmits extends UseManagedListingEmits<Trade> {}

const emit = defineEmits<TradeEmits>();

const tradeState = useTradeState();

const managedListing = useManagedListing<Trade>({
  loadDataRequest(query: PaginatedQuery) {
    return tradeState.services.trade.listTrades({ ...query, state: openTradeStates });
  },
  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager: useRequestManager().manager,
});
</script>

<template>
  <PaginatedTable
    hover
    animateCols
    :class="['trades-table', { 'hide-pagination': hidePagination !== false }]"
    items-label="trades"
    v-on="{ ...managedListing.listeners, ...$listeners }"
    v-bind="{ ...managedListing.bindings, ...$attrs }"
    :stacked="$ahTradesState.mediaQuery.is('smDown')"
    :per-page="4"
  >
    <template #cell(createdAt)="{ item }">
      {{ formatDate(item.createdAt, SIMPLE_DATE_FORMAT) }}
    </template>
    <template #cell(notionalValues)="{ item }">
      <TradeNotionalValueCell :item="item" />
    </template>
    <template #cell(status)="{ item }">
      <TradeStatusCell :item="item" />
    </template>
    <template #cell(currencyPair)="{ item }">
      {{ getCurrencyPair(item) }}
    </template>
    <template #cell(hedgingProduct)="{ item }">
      {{ formatHedgingInstrument(item.hedgingProduct) }}
    </template>
    <template #cell(fxRate)="{ item }">
      <TradeFxRateCell :item="item" />
    </template>
    <template #cell(settlementDate)="{ item }">
      {{ formatDate(item.settlementDate, SIMPLE_DATE_FORMAT) }}
    </template>
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="scope">
      <slot :name="name" v-bind="scope" />
    </template>
  </PaginatedTable>
</template>

<style lang="scss" scoped>
.details-icon {
  float: right;
  cursor: pointer;
}

.small-currency {
  font-size: 0.8em;
}

.trades-table {
  &.hide-pagination ::v-deep {
    .table-actions {
      display: none;
    }
  }
}
</style>
