<script setup lang="ts">
import WalletCollateralText from './WalletCollateralText.vue';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { useTradeState } from '../..';
import { computed } from 'vue';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const tradeState = useTradeState();

const onBehalfOfClient = useOnBehalfOf();

tradeState.store.useWalletsStore().loadCollaterals({ force: false, clientId: onBehalfOfClient.value?.id });

const collaterals = computed(() => {
  const clientId = onBehalfOfClient.value
    ? onBehalfOfClient.value.id
    : tradeState.store.useAuthStore().loggedInIdentity?.client?.id;

  if (clientId) {
    return tradeState.store.useWalletsStore().getCollaterals(clientId);
  }
  return null;
});
</script>

<template>
  <div v-if="collaterals">
    <DataRow cols="8" class="mb-3 main-row margin-credit-facility">
      <template #label>
        Margin Credit Facility:
        <InfoTooltip :text="$ahTradesState.i18n.t('tooltips.marginCreditFacility')" />
      </template>
      {{ collaterals.currency }} {{ formatCurrencyValue(collaterals.marginCreditFacility) }}
    </DataRow>
    <DataRow cols="8" class="mb-3 posted-collateral">
      <template #label>
        <span class="main-row">
          Posted Collateral:
          <InfoTooltip :text="$ahTradesState.i18n.t('tooltips.postedCollateral')" />
        </span>
      </template>
      <div class="mb-1"><WalletCollateralText currency="GBP" /></div>
      <div class="mb-1"><WalletCollateralText currency="EUR" /></div>
      <div class="mb-1"><WalletCollateralText currency="USD" /></div>
    </DataRow>
    <DataRow cols="8" class="mb-3 main-row effective-collateral">
      <template #label>
        Effective Collateral ({{ collaterals.currency }} equiv.):
        <InfoTooltip :text="$ahTradesState.i18n.t('tooltips.effectiveCollateral')" />
      </template>
      {{ collaterals.currency }} {{ formatCurrencyValue(collaterals.effectiveCollateral) }}
    </DataRow>
  </div>
</template>

<style lang="scss" scoped>
.info-circle {
  font-size: 1.2em;
  margin-right: 0.6em;
  @include themedTextColor($color-primary-light);
  vertical-align: sub;
}

.main-row {
  @include phablet {
    font-size: $base-font-size * 0.95; // 15px
  }
  @include tablet {
    font-size: 18px;
  }
}
</style>
