import { getServices } from '@/app/services';
import { useAuthStore } from '@/app/store/authStore';
import {
  Client,
  PublicComplianceCase,
  CompanyRegistrationModel,
  AccountAccess,
  ClientType,
  UserCreationRequest,
  UnsubmittedUbo,
  BaseIndividual,
  PaginatedResponse,
  OnboardingIndividualInfo,
} from 'ah-api-gateways';
import { scrollToValidation, setErrorMessage } from 'ah-common-lib/src/form/helpers';
import { FormModel, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { AuthErrorCodes, CMSErrorCodes, RequestManager, waitForEntityChange, waitForEntityCreation } from 'ah-requests';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, defaultIfEmpty, map, mergeMap, switchMap } from 'rxjs/operators';

const services = getServices();

interface DataFlowModel {
  model: Partial<CompanyRegistrationModel>;
  client?: Client;
  complianceCase?: PublicComplianceCase;
  individuals?: OnboardingIndividualInfo[];
  ubos?: UnsubmittedUbo[];
}

/**
 * Load ubo information and corresponding documents
 */
function loadUbosRequest(clientId: string): Observable<UnsubmittedUbo[]> {
  return services.compliance.listUbos(clientId).pipe(
    mergeMap((ubos: PaginatedResponse<BaseIndividual>) =>
      forkJoin(
        ubos.list.map((ubo) =>
          forkJoin([
            services.compliance.getUboDocuments(clientId, ubo.id!),
            services.compliance.listAddressHistoryItems({
              uboId: ubo.id!,
              pageSize: 30,
            }),
          ]).pipe(
            catchError(() => of([])),
            map(
              ([
                documents,
                {
                  list: [currentAddress, ...previousAddresses],
                },
              ]) => ({ ...ubo, documents, currentAddress, previousAddresses } as UnsubmittedUbo)
            )
          )
        )
      ).pipe(defaultIfEmpty([] as UnsubmittedUbo[]))
    )
  );
}

function loadIndividualsRequest(clientId: string): Observable<OnboardingIndividualInfo[]> {
  return waitForEntityChange(
    () => services.individual.listIndividuals({ clientId: clientId }, { errors: { silent: true } }),
    (list) => list.total > 0,
    {
      ignoreErrors: () => true,
    }
  ).pipe(
    mergeMap((response) =>
      forkJoin(
        response.list.map((individual) =>
          services.compliance
            .listAddressHistoryItems({
              individualId: individual.id!,
              pageSize: 30,
            })
            .pipe(
              catchError(() => of({ list: [] })),
              map(({ list: [currentAddress, ...previousAddresses] }) => ({
                ...individual,
                currentAddress,
                previousAddresses,
              }))
            )
        )
      )
    )
  );
}

/**
 * Load information function to support registration flow.
 * Will:
 *  - load all information of client if there's a client the current session
 *  - load the account access information if user wasn't still been verified
 */
export function loadFlowData(
  model: Partial<CompanyRegistrationModel>,
  reqManager: RequestManager
): Promise<DataFlowModel> {
  const authStore = useAuthStore();
  const sessionUser = authStore.userData!;

  const out: DataFlowModel = { model };

  out.model = {
    ...model,
    applicant: {
      ...model.applicant!,
      id: sessionUser.id,
      phoneNumber: sessionUser.phoneNumber,
      email: sessionUser.email,
    },
  };

  return new Promise((resolve) => {
    const clientId = authStore.loggedInIdentity?.client?.id;
    const isCompany = authStore.isCompanyClient;

    if (authStore.isUserVerified && clientId) {
      return reqManager
        .sameOrCancelAndNew(
          'loadClientInformation',
          forkJoin([
            waitForEntityCreation(() => services.client.getClient(clientId, false, { errors: { silent: true } }), {
              ignoreErrors: () => true,
            }),
            waitForEntityCreation(
              () => services.compliance.getClientComplianceCase(clientId, { options: { errors: { silent: true } } }),
              {
                ignoreErrors: () => true,
              }
            ),
            loadIndividualsRequest(clientId),
            isCompany ? loadUbosRequest(clientId) : of(undefined),
          ])
        )
        .subscribe(([client, complianceCase, individuals, ubos]) => {
          out.client = client;
          out.complianceCase = complianceCase;
          out.individuals = individuals;
          out.ubos = ubos;

          out.model.uboUsers = ubos;
          out.model.individuals = individuals;
          out.model.questionnaire = complianceCase.questionnaire;
          out.model.companyDetails = client.companyDetails;
          resolve(out);
        }, handleLoadRegistrationError);
    } else {
      const sessionUser = authStore.userData!;
      return reqManager
        .sameOrCancelAndNew('loadUserInformation', services.registration.getRegistrationUser(sessionUser.id))
        .subscribe((user) => {
          out.model = {
            ...model,
            address: {
              city: user.city,
              // countryCode is stored as either `country` or `incorporationCountry`, and the two are mutually exclusive
              countryCode: user.country || user.incorporationCountry,
              addressLine: user.addressLine,
              postalCode: user.postalCode,
              stateOrProvince: user.stateOrProvince,
            },
            applicant: {
              id: sessionUser.id,
              phoneNumber: sessionUser.phoneNumber,
              email: sessionUser.email,
              title: user.title,
              firstName: user.firstName,
              lastName: user.lastName,
            } as AccountAccess,
            nationality: user.nationality,
            reference: user.questionnaireAnswer,
            reverseSolicitation: true,
          };
          resolve(out);
        }, handleLoadRegistrationError);
    }
    resolve(out);
  });
}

export function handleLoadRegistrationError(e: any) {
  const authStore = useAuthStore();
  authStore.logout({
    redirect: authStore.isUserVerified ? '/login' : '/register',
    message: 'There was an error trying to retrieve existing onboarding. Try again later.',
    messageOptions: { toastType: 'danger', title: 'Error' },
  });
  throw e;
}

export function submitUser(clientType: ClientType, model: UserCreationRequest, form?: FormModel) {
  const authStore = useAuthStore();
  return services.registration.registerUser(clientType, model).pipe(
    switchMap((res) => from(authStore.startUpSession({ session: res }))),
    catchError((e) => {
      if (form) {
        handleApiRegistrationError(e, form);
      }
      throw e;
    })
  );
}

export function handleApiRegistrationError(e: any, form: FormModel, validation?: FormValidation) {
  const code = e.response?.data?.code;
  if (code === AuthErrorCodes.CLIENT_EMAIL_CONFLICT) {
    setErrorMessage(form, 'email', e.response.data.message);
  }
  if (code === CMSErrorCodes.CLIENT_REGISTRATION_NUMBER_CONFLICT) {
    setErrorMessage(form, 'registrationNumber', e.response.data.message);
  }

  if (validation) {
    scrollToValidation(validation);
  }
}
