<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import { RawLocation, Route } from 'vue-router';

@Component
export default class ModalProtectedRoute extends Vue {
  protected routeLeaveAllowed = false;

  protected routeLeaveTest = () => false;

  protected allowSubpaths = true;

  protected allowQueryChange = true;

  private currentPath = '';

  private beforeEachUnsubscriber!: Function;

  beforeUnloadListener = (event: BeforeUnloadEvent) => {
    if (!this.canLeave()) {
      event.preventDefault();
      event.returnValue = '';
    }
  };

  @Watch('allowSubpaths', { immediate: true })
  onAllowSubpathsChange() {
    if (this.allowSubpaths) {
      /**
       * Retrieves the closest match that rendered this or a direct parent (via <RouterView>)
       * This is necessary to allow ModalProtectedRoute behavior to be consistent
       * for both View and non-View components alike
       */
      const route = [...this.$route.matched].reverse().find((r) =>
        Object.keys(r.instances).find((k) => {
          let currComponent: Vue | null = this as Vue;
          while (currComponent) {
            if (r.instances[k] === currComponent) {
              return true;
            }
            currComponent = currComponent.$parent;
          }
          return false;
        })
      );
      this.currentPath = route?.path || '';
    } else {
      this.currentPath = '';
    }
  }

  beforeMount() {
    this.beforeEachUnsubscriber = this.$router.beforeResolve((to, from, next) => {
      const component = this as ModalProtectedRoute;
      if (this.allowSubpaths && to.matched.some((i) => i.path === this.currentPath)) {
        next();
      } else if (this.allowQueryChange && to.path === from.path) {
        next();
      } else {
        component.checkRouteLeave(to, from, next);
      }
      return undefined;
    });
    window.addEventListener('beforeunload', this.beforeUnloadListener);
  }

  beforeDestroy() {
    this.beforeEachUnsubscriber();
    window.removeEventListener('beforeunload', this.beforeUnloadListener);
  }

  protected checkRouteLeave(to: Route, from: Route, next: Function) {
    if (this.canLeave()) {
      return next();
    }
    const modal = this.$refs.routeLeaveConfirmModal as BModal;
    const confirm = () => {
      this.forcePush(to.fullPath);
    };

    modal.show();
    modal.$once('ok', confirm);
    modal.$once('hidden', () => {
      modal.$off('ok', confirm);
    });
    next(false);
    return undefined;
  }

  private canLeave() {
    const modal = this.$refs.routeLeaveConfirmModal as BModal;
    if (this.routeLeaveAllowed || this.routeLeaveTest() || !modal) {
      return true;
    }
  }

  protected forcePush(location: RawLocation) {
    this.routeLeaveAllowed = true;
    this.$router.push(location);
  }

  protected forceReplace(location: RawLocation) {
    this.routeLeaveAllowed = true;
    this.$router.replace(location);
  }
}
</script>
