<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="currentColor"
          d="M8.83,8.94H2A2,2,0,0,1,0,7V2A2,2,0,0,1,2,0H8.83a2,2,0,0,1,2,2V7A2,2,0,0,1,8.83,8.94ZM2,1.12A.86.86,0,0,0,1.13,2V7A.86.86,0,0,0,2,7.81H8.83A.86.86,0,0,0,9.69,7V2a.86.86,0,0,0-.86-.86Z"
        />
        <path
          fill="currentColor"
          d="M20.74,15H13.9a2,2,0,0,1-2-2V2a2,2,0,0,1,2-2h6.84a2,2,0,0,1,2,2V13A2,2,0,0,1,20.74,15ZM13.9,1.12A.86.86,0,0,0,13,2V13a.86.86,0,0,0,.86.85h6.84A.85.85,0,0,0,21.6,13V2a.85.85,0,0,0-.86-.86Z"
        />
        <path
          fill="currentColor"
          d="M20.74,22.74H13.9a2,2,0,0,1-2-2v-2.7a2,2,0,0,1,2-2h6.84a2,2,0,0,1,2,2v2.7A2,2,0,0,1,20.74,22.74ZM13.9,17.22a.85.85,0,0,0-.85.85v2.7a.85.85,0,0,0,.85.85h6.84a.85.85,0,0,0,.86-.85v-2.7a.85.85,0,0,0-.86-.85Z"
        />
        <path
          fill="currentColor"
          d="M8.82,22.76H2a2,2,0,0,1-2-2V12.07a2,2,0,0,1,2-2H8.82a2,2,0,0,1,2,2v8.71A2,2,0,0,1,8.82,22.76ZM2,11.21a.85.85,0,0,0-.86.86v8.71a.86.86,0,0,0,.86.86H8.82a.87.87,0,0,0,.86-.86V12.07a.86.86,0,0,0-.86-.86Z"
        />
      </g>
    </g>
  </svg>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
