import { render, staticRenderFns } from "./ManageCollateral.vue?vue&type=template&id=325e5773&scoped=true"
import script from "./ManageCollateral.vue?vue&type=script&setup=true&lang=ts"
export * from "./ManageCollateral.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ManageCollateral.vue?vue&type=style&index=0&id=325e5773&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "325e5773",
  null
  
)

export default component.exports