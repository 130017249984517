<template>
  <VRow class="align-items-center single-rate-alert-settings">
    <VCol class="description-col">
      <template v-if="config.type !== 'RATE_ALERT'">
        <span v-if="deletable !== false" @click="deleteNotification"><IconTrash class="remove-icon" /></span>
        <template v-if="!config.timed">
          <h3 class="title" v-if="config.name">{{ config.name }}</h3>
          <p class="sub-title text-secondary" v-if="config.description">{{ config.description }}</p>
        </template>
        <SingleNotificationTimeEditForm
          v-else
          :readonly="readonly"
          :config="config"
          :notification="editedNotification"
          @update:notification="onNotificationUpdate"
        />
      </template>
      <SingleNotificationRateAlertSettingEditForm
        :rateAlert="editedNotification"
        :readonly="readonly"
        @update:rateAlert="onNotificationUpdate"
        @delete-rate-alert="deleteNotification"
        v-else
      />
    </VCol>
    <VCol class="notify-col text-md-left text-sm-center" v-if="hideTargets === false">
      <SingleNotificationTargetEditForm
        :readonly="readonly"
        :showTitles="showTitles"
        :config="config"
        :notification="editedNotification"
        @update:notification="onNotificationUpdate"
      />
    </VCol>
    <VCol class="switches-col">
      <SingleNotificationChannelEditForm
        :channels="config.channels"
        :showTitles="showTitles"
        :notification="editedNotification"
        @update:notification="onNotificationUpdate"
      />
    </VCol>
  </VRow>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { NotificationSetting, RateAlertSetting, NotificationSettingConfig } from 'ah-api-gateways';
import cloneDeep from 'lodash/cloneDeep';
import SingleNotificationChannelEditForm from '@/app/components/settings/notifications/SingleNotificationChannelEditForm.vue';
import SingleNotificationTargetEditForm from '@/app/components/settings/notifications/SingleNotificationTargetEditForm.vue';
import SingleNotificationTimeEditForm from '@/app/components/settings/notifications/SingleNotificationTimeEditForm.vue';
import SingleNotificationRateAlertSettingEditForm from '@/app/components/settings/notifications/SingleNotificationRateAlertSettingEditForm.vue';

@Component({
  components: {
    SingleNotificationChannelEditForm,
    SingleNotificationTargetEditForm,
    SingleNotificationTimeEditForm,
    SingleNotificationRateAlertSettingEditForm,
  },
})
export default class SingleNotificationSettingEditForm extends Vue {
  @Prop({ required: true }) config!: NotificationSettingConfig;

  @Prop({ required: true }) notification!: NotificationSetting | RateAlertSetting;

  @Prop({ default: false }) showTitles!: boolean | string;

  @Prop({ default: false }) deletable!: boolean | string;

  @Prop({ default: false }) hideTargets!: string | boolean;

  @Prop({ default: false }) readonly!: string | boolean;

  private editedNotification: NotificationSetting | RateAlertSetting | null = null;

  @Watch('notification', { immediate: true })
  onNotificationChange() {
    this.editedNotification = cloneDeep(this.notification);
  }

  onNotificationUpdate(notification: NotificationSetting) {
    this.editedNotification = notification;
    this.$emit('update:notification', this.editedNotification);
  }

  deleteNotification() {
    this.$emit('delete-notification', this.notification);
  }
}
</script>
<style lang="scss" scoped>
.remove-icon {
  cursor: pointer;
  @include themedTextColor($color-text-secondary);

  &:hover {
    @include themedTextColor($color-danger);
  }
}
</style>
