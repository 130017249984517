<script setup lang="ts">
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { useTradeState } from '../..';
import { computed } from 'vue';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const tradeState = useTradeState();

const onBehalfOfClient = useOnBehalfOf();

tradeState.store.useWalletsStore().loadCollaterals({ force: false, clientId: onBehalfOfClient.value?.id });

const collaterals = computed(() => {
  const clientId = onBehalfOfClient.value
    ? onBehalfOfClient.value.id
    : tradeState.store.useAuthStore().loggedInIdentity?.client?.id;

  if (clientId) {
    return tradeState.store.useWalletsStore().getCollaterals(clientId);
  }
  return null;
});

const effectiveCollateralUtilizationPercentage = computed(
  () => collaterals.value && (collaterals.value.effectiveCollateralUtilization * 100).toFixed(1)
);

const cappedCollateralUtilizationPercentage = computed(
  () => collaterals.value && (Math.min(collaterals.value.effectiveCollateralUtilization, 1) * 100).toFixed(1)
);

const effectiveCollateralClass = computed(() => {
  if (collaterals.value && collaterals.value.effectiveCollateralUtilization < 0.7) {
    return 'success';
  }
  if (collaterals.value && collaterals.value.effectiveCollateralUtilization < 0.9) {
    return 'warning';
  }
  return 'danger';
});
</script>

<template>
  <div v-if="collaterals">
    <DataRow cols="8" class="mb-3 main-row total-margin-requirement">
      <template #label>
        Total Margin Requirement (in use):
        <InfoTooltip :text="$ahTradesState.i18n.t('tooltips.totalMarginRequirement')" />
      </template>
      {{ collaterals.currency }} {{ formatCurrencyValue(collaterals.totalMarginRequirement) }}
    </DataRow>

    <hr class="separator my-4" />

    <DataRow cols="8" class="mb-2 main-row initial-margin-requirement">
      <template #label> Initial Margin Requirement: </template>
      {{ collaterals.currency }} {{ formatCurrencyValue(collaterals.totalInitialMargin) }}
    </DataRow>
    <DataRow cols="8" class="mb-2 initial-margin-requirement">
      <template #label>
        Margin Credit Contribution:
        <InfoTooltip :text="$ahTradesState.i18n.t('tooltips.marginCreditContribution')" />
      </template>
      {{ collaterals.currency }} {{ formatCurrencyValue(collaterals.totalInitialMarginCreditFunding) }}
    </DataRow>
    <DataRow cols="8" class="mb-3 initial-margin-requirement">
      <template #label>
        Posted Collateral Contribution:
        <InfoTooltip :text="$ahTradesState.i18n.t('tooltips.postedCollateralContribution')" />
      </template>
      {{ collaterals.currency }} {{ formatCurrencyValue(collaterals.totalInitialMarginCollateralFunding) }}
    </DataRow>

    <hr class="separator my-4" />

    <DataRow cols="8" class="mb-3 main-row variable-margin-requirement">
      <template #label>
        Variable Margin Maintenance:
        <InfoTooltip :text="$ahTradesState.i18n.t('tooltips.variationMarginMaintenance')" />
      </template>
      {{ collaterals.currency }} {{ formatCurrencyValue(collaterals.totalVariationMarginMaintenance) }}
    </DataRow>

    <hr class="separator my-4" />

    <DataRow cols="8" class="mb-2 main-row margin-collateral-available">
      <template #label>
        Margin Collateral Available (to trade):
        <InfoTooltip :text="$ahTradesState.i18n.t('tooltips.marginCollateralAvailable')" />
      </template>
      {{ collaterals.currency }} {{ formatCurrencyValue(collaterals.marginCollateralAvailable) }}
    </DataRow>

    <DataRow cols="8" class="mb-2 variable-margin-requirement">
      <template #label>
        Margin Credit Available:
        <InfoTooltip :text="$ahTradesState.i18n.t('tooltips.marginCreditAvailable')" />
      </template>
      {{ collaterals.currency }} {{ formatCurrencyValue(collaterals.initialMarginCreditAvailable) }}
    </DataRow>
    <DataRow cols="8" class="mb-3 variable-margin-requirement">
      <template #label>
        Posted Collateral Available:
        <InfoTooltip :text="$ahTradesState.i18n.t('tooltips.postedCollateralAvailable')" />
      </template>
      {{ collaterals.currency }} {{ formatCurrencyValue(collaterals.initialMarginCollateralAvailable) }}
    </DataRow>

    <hr class="separator my-4" />

    <DataRow cols="8" class="mb-3 main-row effective-collateral-utilization">
      <template #label-icon>
        <IconAlertCircle :class="['label-icon', effectiveCollateralClass]" />
      </template>
      <template #label>
        Effective Collateral Utilisation
        <InfoTooltip :text="$ahTradesState.i18n.t('tooltips.effectiveCollateralUtilisation')" />
      </template>
      <span :class="['value', effectiveCollateralClass]">{{ effectiveCollateralUtilizationPercentage }}%</span>
    </DataRow>
    <div class="utilization-bar-holder">
      <div class="utilization-bar-holder-inner">
        <div class="utilization-marker" :style="{ left: `${cappedCollateralUtilizationPercentage}%` }">
          <span class="utilization-marker-label"> {{ effectiveCollateralUtilizationPercentage }}% </span>
        </div>
        <div class="utilization-bar">
          <div class="utilization-item normal-utilization"></div>
          <div class="utilization-item warning-utilization">
            <span class="utilization-label">70%</span>
          </div>
          <div class="utilization-item danger-utilization">
            <span class="utilization-label">90%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
::v-deep .data-row:not(.main-row) {
  font-size: $font-size-sm;

  &,
  .label-col {
    font-weight: $font-weight-regular;
  }
}

.main-row {
  font-weight: $font-weight-semibold;
  @include phablet {
    font-size: $base-font-size * 0.95; // 15px
  }
  @include tablet {
    font-size: 18px;
  }
}

.value {
  &.success {
    @include themedTextColor($color-success);
  }

  &.warning {
    @include themedTextColor($color-yellow-highlight);
  }

  &.danger {
    @include themedTextColor($color-danger);
  }
}

.label-icon {
  font-size: 1.2em;
  margin-right: 0.6em;
  @include themedTextColor($color-primary-light);
  vertical-align: sub;

  &.success {
    @include themedTextColor($color-success);
  }

  &.warning {
    @include themedTextColor($color-yellow-highlight);
  }

  &.danger {
    @include themedTextColor($color-danger);
  }
}

.sub-row {
  margin-left: 2.3em;
}

.separator {
  border-width: 1px;
  border-bottom-width: 0px;

  @include themedBorderColor($color-input-border);
}

.utilization-bar-holder {
  position: relative;
  padding-left: 2.3em;
  padding-top: 5em;

  .utilization-bar-holder-inner {
    position: relative;
  }

  .utilization-marker {
    position: absolute;
    height: 3em;
    top: -3em;
    border-right-width: 1px;
    border-right-style: solid;
    @include themedBorderColor($color-text-secondary);
    width: 0;

    .utilization-marker-label {
      position: absolute;
      right: 0.5em;
      top: -0.3em;
      font-size: 0.8em;
    }
  }

  .utilization-bar {
    display: grid;
    grid-template-columns: 70% 20% 10%;
    margin-bottom: 2em;

    .utilization-item {
      height: 4px;
      position: relative;

      .utilization-label {
        position: absolute;
        left: 0;
        margin-left: -0.9em;
        top: 1.2em;
        font-size: 0.8em;
        @include themedTextColor($color-text-secondary);
      }
    }

    .normal-utilization {
      @include themedBackgroundColor($color-success);
    }

    .warning-utilization {
      @include themedBackgroundColor($color-yellow-highlight);
    }

    .danger-utilization {
      @include themedBackgroundColor($color-danger);
    }
  }
}
</style>
