import { render, staticRenderFns } from "./InfoBlock.vue?vue&type=template&id=7c172dfc&scoped=true"
import script from "./InfoBlock.vue?vue&type=script&lang=ts"
export * from "./InfoBlock.vue?vue&type=script&lang=ts"
import style0 from "./InfoBlock.vue?vue&type=style&index=0&id=7c172dfc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c172dfc",
  null
  
)

export default component.exports