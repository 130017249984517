<script lang="ts" setup>
import FeesListing from 'ah-trades/src/components/fees/FeesListing.vue';
import { LoadingIcon } from 'ah-common-lib/src/icons/components';
import AddMoneyModal from 'ah-wallets/src/components/AddMoneyModal.vue';
import FeeSettleModal from './FeeSettleModal.vue';
import CreateTradeModal from 'ah-trades/src/components/modals/CreateTradeModal.vue';
import { VButton } from 'ah-common-lib/src/common/components';
import { AuthorityType, PaginatedParams } from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, ref } from 'vue';
import { useTradeState } from '../..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

defineProps<{
  referenceWalletId: string;
}>();

const emit = defineEmits<{
  (e: 'fees-changed', value: void): void;
}>();

const onBehalfOfClient = useOnBehalfOf();

const feesListing = ref<InstanceType<typeof FeesListing> | null>(null);

const feesSortAndPageParams = ref<Partial<PaginatedParams>>({ sort: 'createdAt' });

const tradeState = useTradeState();

const requestManager = useRequestManager({
  exposeToParent: ['loadFeesInfo'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadFeesInfo') {
      created();
    }
  },
});

const canSeeFundingDetails = computed(() =>
  tradeState.store.useAuthStore().hasAuthorities(AuthorityType.VIEW_FUNDING_DETAILS)
);

const isPartnerUser = computed(() => !onBehalfOfClient.value?.id && !tradeState.store.useAuthStore().isClientUser);

const owner = computed(() => {
  return {
    isPartner: isPartnerUser.value && !onBehalfOfClient.value,
    id: onBehalfOfClient.value?.id || tradeState.store.useAuthStore().loggedInIdentity?.client?.id,
  };
});

const feesInfo = computed(() => {
  return isPartnerUser.value
    ? tradeState.store.useWalletsStore().getPartnerFeesInfo()
    : tradeState.store.useWalletsStore().getClientFeesInfo(feesClientId.value!);
});

const debtCurrency = computed(() => feesInfo.value?.debtCurrency || 'GBP');

const wallet = computed(() => tradeState.store.useWalletsStore().getWallet(debtCurrency.value, owner.value));

const hasEnoughBalance = computed(() => (wallet.value?.availableBalance || 0) >= (feesInfo.value?.debtCumulative || 0));

const withOutstandingFees = computed(() => feesInfo.value && feesInfo.value.debtCumulative > 0);

const openTradeAllowed = computed(
  () => onBehalfOfClient.value || tradeState.store.useAuthStore().hasAuthorities(AuthorityType.OPEN_TRADE)
);

const requestPricesAllowed = computed(
  () => onBehalfOfClient.value || tradeState.store.useAuthStore().hasAuthorities(AuthorityType.REQUEST_PRICES)
);

const debtCumulative = computed(() => feesInfo.value?.debtCumulative || 'GBP');

const feesClientId = computed(
  () => onBehalfOfClient.value?.id ?? tradeState.store.useAuthStore().loggedInIdentity!.client?.id
);

const canSettleFees = computed(
  () => onBehalfOfClient.value ?? tradeState.store.useAuthStore().hasAuthorities(AuthorityType.MANAGE_WALLETS)
);

function loadFees() {
  if (tradeState.store.useAuthStore().isLoggedIn) {
    return requestManager.manager.newPromise(
      'loadFeesInfo',
      isPartnerUser.value
        ? tradeState.store.useWalletsStore().loadPartnerFeesInfo({ force: true })
        : tradeState.store.useWalletsStore().loadClientFeesInfo({ force: true, clientId: feesClientId.value! })
    );
  }
}

function onFeesChanged() {
  loadFees()?.then(() => {
    emit('fees-changed');
    feesListing.value?.loadData();
  });
}
function created() {
  tradeState.store.useWalletsStore().loadCurrencyWallet({ currency: debtCurrency.value, owner: owner.value });
  loadFees();
}

created();
</script>

<template>
  <div class="fees-info">
    <LoadingIcon v-if="requestManager.manager.anyPending" />
    <VRow v-else-if="withOutstandingFees" class="mb-3" align-h="between">
      <template v-if="!isPartnerUser">
        <VCol cols="9">
          <p class="mb-1">
            You have <b>{{ debtCurrency }} {{ formatCurrencyValue(debtCumulative) }}</b> in outstanding fees.
          </p>
          <p class="text-secondary text-small">
            <template v-if="hasEnoughBalance">
              Your fees are paid from your {{ debtCurrency }} Wallet. If you do not have enough {{ debtCurrency }} in
              your account, you can
              <CreateTradeModal
                v-slot="{ show }"
                :allowedBuyCurrencies="[debtCurrency]"
                forceKeep
                forceImmediateSpot
                syncTradeFunds
                v-if="openTradeAllowed && requestPricesAllowed"
              >
                <a @click="show" class="action-link">Exchange</a>
              </CreateTradeModal>
              from another currency
            </template>
            <template v-else>
              You will need to ensure you have enough funds in your {{ debtCurrency }} wallet for these fees to be paid.
            </template>
          </p>
        </VCol>
        <VCol cols="2" v-if="canSettleFees">
          <FeeSettleModal @fees-settled="onFeesChanged" v-slot="{ show }" v-if="feesInfo" :feesInfo="feesInfo">
            <VButton @click="show" :disabled="!hasEnoughBalance">Settle fees</VButton>
          </FeeSettleModal>
        </VCol>
      </template>

      <template v-else>
        <div class="text-content">
          <IconAlertCircle class="status-icon danger" />
          <p class="mb-1">
            You have <b>{{ debtCurrency }} {{ formatCurrencyValue(debtCumulative) }}</b> in outstanding fees.
          </p>
          <p class="text-secondary">
            You will need to ensure you have enough funds in your GBP wallet for these fees to be automatically paid.
          </p>
        </div>
        <div class="actions">
          <AddMoneyModal
            :clientId="feesClientId"
            :allowedCurrencies="[debtCurrency]"
            v-slot="{ show }"
            class="action"
            v-if="canSeeFundingDetails"
          >
            <VButton @click="show" class="action block">Add funds to your {{ debtCurrency }} wallet</VButton>
          </AddMoneyModal>
          <CreateTradeModal
            class="action"
            title="Convert from another currency wallet"
            v-slot="{ show }"
            :allowedBuyCurrencies="[debtCurrency]"
            forceKeep
            forceImmediateSpot
            syncTradeFunds
            v-if="openTradeAllowed"
          >
            <VButton @click="show" class="action block">Convert from another currency wallet</VButton>
          </CreateTradeModal>
        </div>
      </template>
    </VRow>

    <div class="fee-state-info" v-else>
      <div class="text-content">
        <IconInfoCircle class="status-icon success" />
        <p>{{ onBehalfOfClient ? 'Client has' : 'You have' }} no current outstanding fees.</p>
      </div>
    </div>
    <FeesListing
      ref="feesListing"
      :sortAndPageParams.sync="feesSortAndPageParams"
      :referenceWalletId="referenceWalletId"
    />
  </div>
</template>

<style lang="scss" scoped>
.fee-state-info {
  margin-bottom: 1em;
}

.text-content {
  padding-left: 3em;
  position: relative;
  padding-top: 1em;

  .status-icon {
    position: absolute;
    left: 0.2em;
    top: 0.4em;
    font-size: 2em;

    &.success {
      @include themedTextColor($color-success);
    }

    &.danger {
      @include themedTextColor($color-danger);
    }
  }
}

::v-deep .actions {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 1em;

  .action {
    margin-right: 0.5em;
    margin-left: 0.5em;
  }
}

.action-link:hover {
  text-decoration: underline;
}
</style>
