import { CreatableObject } from 'ah-api-gateways';

export enum ClientFileCategories {
  INCORPORATED_DOCUMENT = 'INCORPORATED_DOCUMENT',
  SAMPLE_INVOICE = 'SAMPLE_INVOICE',
  FINANCIAL_STATEMENT = 'FINANCIAL_STATEMENT',
  COMPANY_LOGO = 'COMPANY_LOGO',
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  SECONDARY_OWNER_PROOF_OF_ADDRESS = 'SECONDARY_OWNER_PROOF_OF_ADDRESS',
  PROOF_OF_FUNDS = 'PROOF_OF_FUNDS',
  SECONDARY_OWNER_PHOTO_ID = 'SECONDARY_OWNER_PHOTO_ID',
  PHOTO_ID = 'PHOTO_ID',
  SELFIE = 'SELFIE',
  RESOLUTION_LETTER = 'RESOLUTION_LETTER',
}

export const ClientFileCategoriesToHuman: Record<ClientFileCategories, string> = {
  [ClientFileCategories.INCORPORATED_DOCUMENT]: 'Certificate of Incorporation',
  [ClientFileCategories.SAMPLE_INVOICE]: 'Sample Invoice/Proof of Business Activity',
  [ClientFileCategories.FINANCIAL_STATEMENT]: 'Audited Financial Statement/Latest Accounts',
  [ClientFileCategories.COMPANY_LOGO]: 'Company Logo',
  [ClientFileCategories.PROOF_OF_ADDRESS]: 'Proof of Address',
  [ClientFileCategories.SECONDARY_OWNER_PROOF_OF_ADDRESS]: 'Proof of Address',
  [ClientFileCategories.PROOF_OF_FUNDS]: 'Proof of Funds',
  [ClientFileCategories.PHOTO_ID]: 'Photo Identification',
  [ClientFileCategories.SECONDARY_OWNER_PHOTO_ID]: 'Photo Identification',
  [ClientFileCategories.SELFIE]: 'Photo Portrait',
  [ClientFileCategories.RESOLUTION_LETTER]: 'Proof of Authorised Signatory Document',
};

export const ClientFileCategoryDescriptions: Record<ClientFileCategories, string> = {
  [ClientFileCategories.INCORPORATED_DOCUMENT]:
    'Legal document/licence relating to the formation of a company or corporation issued by state government or, in some jurisdictions, by non-governmental entity/corporation',
  [ClientFileCategories.SAMPLE_INVOICE]: 'Sample invoice(s) required to prove the company is actively trading',
  [ClientFileCategories.FINANCIAL_STATEMENT]: 'Audited financial statements/3 months itemised company bank statements',
  [ClientFileCategories.COMPANY_LOGO]: '',
  [ClientFileCategories.PROOF_OF_ADDRESS]:
    'Clear, colour copy of a utility bill (must be dated within the last 3 months)',
  [ClientFileCategories.SECONDARY_OWNER_PROOF_OF_ADDRESS]:
    'Clear, colour copy of a utility bill (must be dated within the last 3 months)',
  [ClientFileCategories.PROOF_OF_FUNDS]:
    'Itemized bank statements, to prove source of wealth (must be dated within 3 months)',
  [ClientFileCategories.PHOTO_ID]:
    'Valid, clear, colour copy of passport, or driving licence (all text data must be in frame, be visible and legible)',
  [ClientFileCategories.SECONDARY_OWNER_PHOTO_ID]:
    'Valid, clear, colour copy of passport, or driving licence (all text data must be in frame, be visible and legible)',
  [ClientFileCategories.SELFIE]: '',
  [ClientFileCategories.RESOLUTION_LETTER]:
    'Please provide us with the document to verify the authorised users for the account. i.e. A letter headed letter which has been signed and dated',
};

export enum IndividualFileCategories {
  PROFILE_PICTURE = 'PROFILE_PICTURE',
}

export type FileCategory = ClientFileCategories | IndividualFileCategories;

export enum LegalFileType {
  TOS = 'TOS',
  TOS_PAYMENT_SERVICE = 'TOS_PAYMENT_SERVICE',
  LPOA = 'LPOA',
  MCA = 'MCA',
}

export interface FileUploadRequest {
  name: string;
  category: FileCategory;
}

export interface UploadedFile extends FileUploadRequest, CreatableObject {
  id: string;
  link: string;
}
