<script lang="ts" setup>
import VChart from './VChart.vue';
import take from 'lodash/take';
import { makeThemedChartDataset } from '../helpers/chartConfig';
import { makeBarChartOptions, useReportsState } from 'ah-reports';
import { linesColorPrimary } from '../helpers/graphColors';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { HedgingInstruments, TradeCommissionsReport } from 'ah-api-gateways';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, reactive, watch } from 'vue';

const TOP_DISPLAY = 5;

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: onRetryFromParentManager,
});

function onRetryFromParentManager(k: string) {
  if (k === 'loadData') {
    loadData();
  }
}

const props = withDefaults(
  defineProps<{
    fromDate: Date;
    toDate: Date;
    /**
     * Type of report to produce
     */
    reportType?:
      | ''
      | 'numberTrades'
      | 'volumeTrades'
      | 'commissionsEarned'
      | 'approvedClients'
      | 'numberTradesPartners'
      | 'volumeTradesPartner';
    /**
     * Possible array of hedging products to filter results by. Only applicable to Trade related reports.
     */
    hedgingProducts?: HedgingInstruments[];
  }>(),
  {
    reportType: '',
  }
);

const state = reactive({
  options: makeBarChartOptions(
    { horizontal: true, gridColor: linesColorPrimary },
    {
      plugins: {
        tooltip: {
          intersect: false,
          callbacks: {
            label: (item) => {
              if (['numberTradesPartners', 'numberTrades'].includes(props.reportType)) {
                return `${item.parsed.x || 0} Trade${item.parsed.x !== 1 ? 's' : ''}`;
              }
              if (['approvedClients'].includes(props.reportType)) {
                return `${item.parsed.x || 0} Client${item.parsed.x !== 1 ? 's' : ''}`;
              }
              return `GBP ${formatCurrencyValue(item.parsed.x || 0)}`;
            },
          },
        },
      },
      scales: {
        y: {
          ticks: {
            autoSkip: false,
          },
        },
        x: {
          ticks: {
            precision: ['numberTrades', 'approvedClients', 'numberTradesPartners'].includes(props.reportType) ? 0 : 2,
          },
        },
      },
    }
  ),
  data: makeThemedChartDataset({
    labels: [],
    colorNames: ['widgets-green'],
  }),
  report: null as TradeCommissionsReport | null,
});

const query = computed(() => ({
  from: props.fromDate,
  to: props.toDate,
  reportType: props.reportType,
  hedgingProducts: props.hedgingProducts,
}));

const reportsState = useReportsState();

function loadData() {
  let request!: Observable<any>;

  switch (props.reportType) {
    case 'numberTrades':
      request = requestManager.manager
        .cancelAndNew(
          'loadData',
          reportsState.services.trade.getClientTopNumberTradesReport(
            props.fromDate,
            props.toDate,
            props.hedgingProducts
          )
        )
        .pipe(
          tap((response) => {
            const accountsClient = take(response.list, TOP_DISPLAY);
            state.data.datasets![0].data! = accountsClient.map((i) => i.tradesNumber);
            state.data.labels = accountsClient.map((i) => i.clientName);
          })
        );
      break;
    case 'volumeTrades':
      request = requestManager.manager
        .cancelAndNew(
          'loadData',
          reportsState.services.trade.getClientTopVolumeTradesReport(
            props.fromDate,
            props.toDate,
            props.hedgingProducts
          )
        )
        .pipe(
          tap((response) => {
            const accountsClient = take(response.list, TOP_DISPLAY);
            state.data.datasets![0].data! = accountsClient.map((i) => i.tradedAmount);
            state.data.labels = accountsClient.map((i) => i.clientName);
          })
        );
      break;
    case 'approvedClients':
      if (reportsState.services.partner) {
        request = requestManager.manager
          .cancelAndNew(
            'loadData',
            reportsState.services.partner.listPartners({
              createdAtFrom: props.fromDate.toISOString(),
              createdAtTo: props.toDate.toISOString(),
              // TODO need to check wiuth API
              sort: 'approvedClientCounter,desc',
            })
          )
          .pipe(
            tap((response) => {
              const approvedClients = take(response.list, TOP_DISPLAY);
              state.data.datasets![0].data! = approvedClients.map((i) => i.approvedClientCounter);
              state.data.labels = approvedClients.map((i) => i.name);
            })
          );
        break;
      } else {
        throw 'Partner service not available to retrieve report data';
      }
    case 'commissionsEarned':
      request = requestManager.manager
        .cancelAndNew(
          'loadData',
          reportsState.services.trade.getPartnerCommissionsEarnedReport(props.fromDate, props.toDate)
        )
        .pipe(
          tap((response) => {
            const accountsPartner = take(response.list, TOP_DISPLAY);
            state.data.datasets![0].data! = accountsPartner.map((i) => i.partnerProfit);
            state.data.labels = accountsPartner.map((i) => i.partnerName);
          })
        );
      break;
    case 'numberTradesPartners':
      request = requestManager.manager
        .cancelAndNew(
          'loadData',
          reportsState.services.trade.getPartnerNumberTradesReport(props.fromDate, props.toDate, props.hedgingProducts)
        )
        .pipe(
          tap((response) => {
            const accountsPartner = take(response.list, TOP_DISPLAY);
            state.data.datasets![0].data! = accountsPartner.map((i) => i.tradesNumber);
            state.data.labels = accountsPartner.map((i) => i.partnerName);
          })
        );
      break;
    case 'volumeTradesPartner':
      request = requestManager.manager
        .cancelAndNew(
          'loadData',
          reportsState.services.trade.getPartnerVolumeTradesReport(props.fromDate, props.toDate, props.hedgingProducts)
        )
        .pipe(
          tap((response) => {
            const accountsPartner = take(response.list, TOP_DISPLAY);
            state.data.datasets![0].data! = accountsPartner.map((i) => i.tradedAmount);
            state.data.labels = accountsPartner.map((i) => i.partnerName);
          })
        );
      break;
  }

  request.subscribe(() => {
    // Force update by replacing prop reference entirely
    state.options = { ...state.options };
    state.data = { ...state.data };
  });
}

watch(query, loadData, { immediate: true });
</script>

<template>
  <VChart class="chart" type="bar" :options="state.options" :data="state.data" />
</template>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 250px;
  padding-bottom: 33%;
}
.top-accounts-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  ol {
    width: 60%;
    list-style: none;
    counter-reset: li;
    li {
      counter-increment: li;
    }
    li::before {
      @include themedTextColor($color-primary);
      content: '.' counter(li);
      display: inline-block;
      width: 1em;
      margin-left: -1.5em;
      margin-right: 0.5em;
      text-align: right;
      direction: rtl;
    }
  }
  .top-accounts-item {
    display: grid;
    grid-template-columns: 10px 50% 50px auto;
    justify-items: start;
    align-items: center;
    .currency {
      justify-self: end;
    }
  }
}

.label {
  @include phablet {
    font-size: 10px;
    @include themedTextColor($color-text-secondary, $color-dark-text-secondary);
    margin-left: 4rem;
  }
  @include tablet {
    margin-left: -2rem;
  }
  @include desktop {
    text-align: left !important;
    margin-left: 10rem;
  }
}
</style>
