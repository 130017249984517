<script setup lang="ts">
import { AmountType, CreateTradeRequest, VanillaPriceResponse } from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, ref, watch } from 'vue';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import OptionTermsheetModal from './modals/OptionTermsheetModal.vue';
import { useTradeState } from '..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { waitForEntityCreation } from 'ah-requests';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { useToast } from 'ah-common-lib/src/toast/Toast';

enum OptionsCreatorSteps {
  OPTIONS_CREATOR = 'optionCreator',
  OPTIONS_REVIEW = 'optionsReview',
  OPTIONS_BOOKED = 'optionsBooked',
}

const optionTermsheetModal = ref<InstanceType<typeof OptionTermsheetModal> | null>(null);

const requestManager = useRequestManager();

const toast = useToast();

const props = defineProps<{ solution: VanillaPriceResponse; loading: boolean; error: boolean; pause: boolean }>();

const tradeState = useTradeState();

const emit = defineEmits<{
  (e: 'update:stage', value: OptionsCreatorSteps): void;
  (e: 'remove-selected-solution'): void;
  (e: 'update:createdTrade', value: string): void;
  (e: 'reload-prices'): void;
  (e: 'update:createLoading', value: boolean): void;
  (e: 'update:pause', value: boolean): void;
}>();

const agentLoading = computed(() => requestManager.manager.requestStates.createTrade === 'pending');

watch(agentLoading, () => emit('update:createLoading', agentLoading.value), { immediate: true });

function goBack() {
  emit('update:stage', OptionsCreatorSteps.OPTIONS_CREATOR);
  emit('remove-selected-solution');
}

const isPremiumCcy = computed(() => {
  if (props.solution.ccy1.currency === props.solution.premiumCurrency) {
    return props.solution.ccy1;
  }
  return props.solution.ccy2;
});

const isPrimaryRate = computed(() => {
  if (props.solution.ccy1.isPrimaryRate === true) {
    return props.solution.ccy1;
  }
  return props.solution.ccy2;
});

const alt21Costs = computed(() => formatCurrencyValue(isPremiumCcy.value.optionsCurrencyData.lpPremium) ?? '-');

const alt21Markup = computed(() => formatCurrencyValue(isPremiumCcy.value.optionsCurrencyData.markupPremium) ?? '-');

const alt21Credit = computed(() => formatCurrencyValue(isPremiumCcy.value.optionsCurrencyData.creditPremium) ?? '-');

const totalCosts = computed(() => formatCurrencyValue(isPremiumCcy.value.optionsCurrencyData.totalPremium) ?? '-');

const sellAmount = computed(() => {
  if (props.solution.ccy1.amountType === AmountType.SELL) {
    return formatCurrencyValue(props.solution.ccy1.clientAmount);
  }

  return formatCurrencyValue(props.solution.ccy2.clientAmount);
});

const onBehalfOfClient = useOnBehalfOf();

const sellCurrency = computed(() => {
  if (props.solution.ccy1.amountType === AmountType.SELL) {
    return props.solution.ccy1.currency;
  }

  return props.solution.ccy2.currency;
});

const buyAmount = computed(() => {
  if (props.solution.ccy1.amountType === AmountType.BUY) {
    return formatCurrencyValue(props.solution.ccy1.clientAmount);
  }

  return formatCurrencyValue(props.solution.ccy2.clientAmount);
});

const buyCurrency = computed(() => {
  if (props.solution.ccy1.amountType === AmountType.BUY) {
    return props.solution.ccy1.currency;
  }

  return props.solution.ccy2.currency;
});

function viewTermsheet() {
  optionTermsheetModal.value!.show();
}

function createTrade() {
  let createTradeRequest: CreateTradeRequest = {
    priceId: props.solution.id,
    hedgingProduct: props.solution.hedgingProduct,
    syncPendingFunds: false,
    premiumCurrency: props.solution.premiumCurrency,
  };

  requestManager.manager
    .sameOrCancelAndNew(
      'createTrade',
      tradeState.services.trade.createTrade(createTradeRequest, onBehalfOfClient.value?.id).pipe(
        mergeMap((idEntity) =>
          waitForEntityCreation(() =>
            tradeState.services.trade.getTrade(idEntity.id, { errors: { silent: true } })
          ).pipe(
            mergeMap((response) => of(response.referenceNumber)),
            catchError(() => of(null))
          )
        )
      ),
      createTradeRequest
    )
    .subscribe((response) => {
      if (response !== null) {
        emit('update:createdTrade', response);
        toast.success('Trade created successfully');
        emit('update:stage', OptionsCreatorSteps.OPTIONS_BOOKED);
      }
    });
}
</script>

<template>
  <div class="section">
    <!-- main blocks -->
    <BoxGrid>
      <!-- trade details -->

      <BoxGridBlock
        cols="12"
        :loadingOverlayProps="{
          loading: loading,
          error: error,
          loadingText: 'Refreshing',
          class: 'loading-solution',
          hideOnLoading: true,
          showRetry: true,
        }"
        useLoadingOverlay
        overlayType="simple"
        @retry="emit('reload-prices')"
      >
        <BoxGrid class="box-row">
          <BoxGridItem lg="4" md="12" sm="12" class="m-0 pl-md-3 px-sm-1 py-4">
            <h2 class="title">COST TRANSPARENCY <InfoTooltip :text="$t('tooltips.costsTransparency')" /></h2>
            <div class="costs">
              <VCol cols="12"><span class="text-secondary">+ ALT21 Costs</span></VCol>
              <VCol>
                <span> {{ isPremiumCcy.currency }} </span> <span class="h3-font">{{ alt21Costs }}</span>
              </VCol>
            </div>
            <div class="costs">
              <VCol cols="12"><span class="text-secondary">+ ALT21 Mark-up</span></VCol>
              <VCol>
                <span> {{ isPremiumCcy.currency }} </span><span class="h3-font">{{ alt21Markup }}</span>
              </VCol>
            </div>
            <div class="costs">
              <VCol cols="12"><span class="text-secondary">+ ALT21 Credit Charge</span></VCol>
              <VCol>
                <span> {{ isPremiumCcy.currency }} </span><span class="h3-font">{{ alt21Credit }}</span></VCol
              >
            </div>
            <div class="costs">
              <VCol cols="12"><span class="text-secondary">= Total Cost to You</span></VCol>
              <VCol>
                <span> {{ isPremiumCcy.currency }} </span><span class="h3-font">{{ totalCosts }}</span>
              </VCol>
            </div>
          </BoxGridItem>
          <BoxGridItem
            lg="8"
            md="12"
            sm="12"
            class="box pl-md-3 pl-md-1 py-4"
            :class="$mediaQuery.is('mdDown') ? 'border-top' : 'border-left'"
          >
            <div class="d-flex justify-content-between mb-2">
              <h2 class="title">TERMSHEET</h2>
              <VButton class="btn btn-primary" :loading="requestManager.manager.anyPending" @click="viewTermsheet">
                Preview termsheet
              </VButton>
            </div>
            <div :class="$mediaQuery.is('smDown') ? 'px-0' : 'px-5'">
              <h2 class="title mt-sm-3">1. Vanilla Option</h2>
              <VRow>
                <VCol md="3" sm="6"
                  ><div class="costs">
                    <VCol cols="12"><span class="text-secondary">Strike rate</span></VCol>
                    <VCol>
                      <span class="h3-font">{{ isPrimaryRate.optionsCurrencyData.selectedStrikeRate }}</span>
                    </VCol>
                  </div>
                </VCol>
                <VCol md="7" sm="6">
                  <div class="costs">
                    <VCol cols="12"><span class="text-secondary">Costs of Trade</span></VCol>
                    <VCol>
                      <span> {{ isPremiumCcy.currency }} </span>
                      <span class="h3-font">{{
                        formatCurrencyValue(isPremiumCcy.optionsCurrencyData.totalPremium)
                      }}</span>
                      <span class="text-small"> Pay now </span></VCol
                    >
                  </div></VCol
                >
              </VRow>
              <VRow>
                <VCol cols="12"
                  ><div class="costs">
                    <VCol>
                      <VRow>
                        <div>
                          <VCol><span class="text-secondary">Converting from</span></VCol>

                          <VCol
                            ><label
                              ><span> {{ sellCurrency }} </span> <span class="h3-font">{{ sellAmount }}</span></label
                            ></VCol
                          >
                        </div>

                        <div class="d-flex align-self-center mx-md-3">
                          <IconArrowRight class="arrow" />
                        </div>

                        <div>
                          <VCol><span class="text-secondary">To</span></VCol>
                          <VCol
                            ><label
                              ><span> {{ buyCurrency }} </span> <span class="h3-font">{{ buyAmount }}</span></label
                            ></VCol
                          >
                        </div>
                      </VRow>
                    </VCol>
                  </div>
                </VCol>
              </VRow>
              <VRow>
                <VCol cols="12" class="mb-2">
                  <h4>Product definition</h4>
                </VCol>
                <VCol cols="12">
                  <span class="text-small">
                    A Vanilla Option is a foreign exchange contract between two parties that grants the purchaser of the
                    option protection, but not obligation to transact, on a Notional amount at the Strike rate on a
                    Settlement date in exchange for a up-front premium.
                  </span>
                </VCol>
                <VCol cols="12" class="px-4 pt-4">
                  <span class="text-small"> Advantages </span>
                  <VRow class="text-small">
                    <ul>
                      <li>
                        Provides full protection on the notional amount against adverse movements beyond the strike rate
                        on the settlement date
                      </li>
                      <li>Ability to benefit from favourable movements beyond the strike rate on settlement date</li>
                      <li>
                        Ability to select a desired strike rate, notional amount and settlement date to set against your
                        commercial requirement
                      </li>
                      <li>
                        A non-refundable premium is payable on the premium settlement date, typically two days after
                        booking
                      </li>
                    </ul>
                  </VRow>
                </VCol>
                <VCol cols="12" class="px-4">
                  <span class="text-small"> Disadvantages </span>
                  <VRow class="text-small">
                    <ul>
                      <li>Premium must be paid upfront</li>
                    </ul>
                  </VRow>
                </VCol>
                <VCol md="11" sm="12">
                  <VRow class="box-dates" :class="$mediaQuery.is('smDown') ? 'py-1' : 'py-3'">
                    <VCol align-self="end">
                      <div class="costs">
                        <VCol cols="12"><span class="text-small text-secondary">Expiry date</span></VCol>
                        <VCol> {{ formatDate(solution.expiryDate, 'dd-MM-yyyy') }} </VCol>
                      </div>
                    </VCol>
                    <VCol align-self="end">
                      <div class="costs">
                        <VCol cols="12"><span class="text-small text-secondary">Settlement date</span></VCol>
                        <VCol> {{ formatDate(solution.settlementDate, 'dd-MM-yyyy') }} </VCol>
                      </div>
                    </VCol>
                    <VCol align-self="end">
                      <div class="costs">
                        <VCol cols="12"><span class="text-small text-secondary">Premium Settlement date</span></VCol>
                        <VCol> {{ formatDate(solution.premiumDate, 'dd-MM-yyyy') }}</VCol>
                      </div>
                    </VCol>
                  </VRow>
                </VCol>
              </VRow>
            </div>
          </BoxGridItem>
        </BoxGrid>
      </BoxGridBlock>
    </BoxGrid>
    <BoxGrid>
      <BoxGridItem lg="8" md="12" sm="12">
        <VCol cols="12" class="mb-2">
          <h4>Disclaimer</h4>
        </VCol>
        <VCol cols="12">
          <p class="text-small">
            Please read the term sheet carefully and ensure that all the information pertaining to your option is
            accurate. Options carry a high level of risk and may not be suitable for you. You should understand how this
            instrument works and whether it aligns with your risk tolerance.
          </p>
          <p class="text-small">
            By reviewing this information, you acknowledge the risks involved, including the potential for losses as
            well as gains and you accept full responsibility for the results of the trade.
          </p>
          <p class="text-small">
            If necessary, seek guidance from a qualified financial professional, as the content provided here should not
            be regarded as financial advice or a recommendation to trade.
          </p>
        </VCol>
      </BoxGridItem>
      <BoxGridItem lg="4" md="12" sm="12">
        <div class="d-flex justify-content-end">
          <VButton class="btn btn-secondary mr-3" :loading="requestManager.manager.anyPending" @click="goBack()">
            <IconArrowRight class="mr-3 back-arrow" /> Back
          </VButton>

          <VButton
            class="btn btn-primary"
            :loading="loading || requestManager.manager.anyPending"
            :disabled="error"
            @click="createTrade()"
          >
            Book Trade <IconArrowRight class="ml-3arrow-icon"
          /></VButton>
        </div>
      </BoxGridItem>
    </BoxGrid>
    <OptionTermsheetModal
      ref="optionTermsheetModal"
      @update:pause="emit('update:pause', $event)"
      :solution="solution"
    />
  </div>
</template>

<style lang="scss" scoped>
::v-deep .box-grid-item {
  padding: 0;
  padding-left: 0.5rem;
}

.h3-font {
  font-size: $h3-font-size;
}
.box {
  &.border-left {
    @include themedPropColor('border-left', $color-sidebar-border, $color-dark-sidebar-border, '1px solid ');
  }

  &.border-top {
    @include themedPropColor('border-top', $color-sidebar-border, $color-dark-sidebar-border, '1px solid ');
  }
}

.box-dates {
  border-radius: 4px;
  @include themedBackgroundColor($color-highlight-grey);
}

.costs {
  margin-bottom: 1.2rem;
}

.back-arrow {
  transform: rotate(180deg);
}

::v-deep .loading-solution {
  min-height: 50rem;
}
</style>
