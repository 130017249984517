<script setup lang="ts">
import { useAuthStore } from '@/app/store/authStore';
import AppBanner from '@/app/components/common/AppBanner.vue';
import { ref, computed, onBeforeMount, onBeforeUnmount } from 'vue';
import { ComplianceStatus, Individual } from 'ah-api-gateways';
import { getServices } from '@/app/services';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { MINUTE } from 'ah-common-lib/src/constants/time';

const authStore = useAuthStore();

const requestManager = useRequestManager().manager;

function fetchClientOwner() {
  const services = getServices();

  return requestManager
    .cancelAndNew(
      'loadOwner',
      services.individual.listIndividuals(
        {
          clientId: authStore.userData?.individual?.client?.id,
          isOwner: true,
        },
        {
          errors: { silent: true },
        }
      )
    )
    .subscribe((response) => {
      clientOwner.value = response.list[1] || null;
    });
}
/**
 * Check for compliance periodically (while the banner is shown)
 */
authStore.loadComplianceCase();
const loadComplianceInterval = window.setInterval(() => {
  if (authStore.isLoggedIn) {
    authStore.loadComplianceCase({ force: true });
  }
}, MINUTE * 15);

onBeforeUnmount(() => {
  window.clearInterval(loadComplianceInterval);
});

const clientOwner = ref<Individual | null>(null);

const isSuspended = computed(() => authStore.complianceStatus === ComplianceStatus.SUSPENDED);

const isDeactivated = computed(() => authStore.complianceStatus === ComplianceStatus.DEACTIVATED);

const isUpdatedTermsAndConditions = computed(
  () => authStore.complianceStatus === ComplianceStatus.UPDATED_TERMS_AND_CONDITIONS
);

const isIndividualClient = computed(() => {
  return authStore.isClientUser && !authStore.isCompanyClient;
});

const isUserSignatory = computed(() => {
  return isIndividualClient.value || authStore.userData?.individual?.owner;
});

const isBannerShown = computed(() => {
  return !requestManager.anyPending && (isSuspended.value || isDeactivated.value || isUpdatedTermsAndConditions.value);
});

onBeforeMount(() => {
  if (!isUserSignatory.value) {
    fetchClientOwner();
  }
});
</script>

<template>
  <AppBanner v-if="isBannerShown" class="text-center danger">
    <template>
      <p class="mb-0" v-if="isSuspended">
        Your account is currently suspended, you will be unable to use our products and services.<br />
        <b v-if="authStore.complianceStatusReason" class="status-change-reason"
          >{{ authStore.complianceStatusReason }}<br
        /></b>
        For more information, please contact <a href="mailto:  compliance@alt21.com">compliance@alt21.com</a>.
      </p>
      <p class="mb-0" v-else-if="isDeactivated">
        Your account has been deactivated, you will be unable to use our products and services from now on.<br />
        To learn more, please contact
        <a href="mailto:  compliance@alt21.com">compliance@alt21.com</a>.
      </p>
      <p class="mb-0" v-else-if="isUpdatedTermsAndConditions">
        <b>Updated T&Cs outstanding</b><br />
        <span v-if="isUserSignatory">
          In order to proceed, please review the
          <RouterLink to="/terms-and-conditions" class="plain-color-link">terms and conditions</RouterLink> of business,
          and authorize in your capacity as a duly authorized person.
        </span>
        <span v-else>
          In order to proceed,
          {{ clientOwner ? `${clientOwner.firstName} ${clientOwner.lastName}` : 'the authorized signatory' }}
          must review and authorize the updated terms and conditions of business.
        </span>
      </p>
    </template>
  </AppBanner>
</template>
<style lang="scss" scoped>
.status-change-reason {
  white-space: pre-line;
}
</style>
