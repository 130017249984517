<script lang="ts" setup>
import { PartnerReport, TradeState, CommissionDisplayType } from 'ah-api-gateways';
import TradeReportInfoDetails from 'ah-trades/src/components/info/TradeReportInfoDetails.vue';
import TradeReportDetails from 'ah-trades/src/components/info/TradeReportDetails.vue';

withDefaults(
  defineProps<{
    commission: PartnerReport;
    displayType?: CommissionDisplayType;
  }>(),
  {
    displayType: CommissionDisplayType.ORIGINAL,
  }
);

function isTradeRejectedOrCanceled(commission: PartnerReport) {
  return [TradeState.CLOSED_REJECTED, TradeState.CLOSED_CANCELED].includes(commission.state);
}
</script>

<template>
  <VRow>
    <VCol class="mb-sm-4 mb-lg-0" offset-lg="1" xl="6" lg="6" sm="12">
      <TradeReportInfoDetails
        :displayType="displayType"
        :rejectedOrCanceled="isTradeRejectedOrCanceled(commission)"
        :tradeReport="commission"
      />
    </VCol>
    <VCol offset-lg="1" xl="3" lg="3" sm="12">
      <h3 class="mb-3">Trade Details</h3>
      <TradeReportDetails :trade="commission" />
    </VCol>
  </VRow>
</template>
