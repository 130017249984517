<template src="./TextFormField.template.vue" />

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator';
import { getState } from '../../helpers/formHelpers';
import TextFormField from './TextFormField.vue';
import { extractIBAN, friendlyFormatIBAN, electronicFormatIBAN } from 'ibantools';

@Component
export default class IBANFormField extends TextFormField {
  created() {
    this.setValue(this.field.$model);
  }

  setValue(val: string) {
    const iban = extractIBAN(electronicFormatIBAN(val) ?? '');
    this.$set(this.model.$state, 'validIBAN', iban.valid);
    if (iban.valid) {
      this.$emit('set-value', iban.iban, !this.field.$dirty && !this.dirtyOnInput);
    } else {
      this.$emit('set-value', val, !this.field.$dirty && !this.dirtyOnInput);
    }
  }

  @Watch('stringVal')
  onStringValChange() {
    const iban = extractIBAN(electronicFormatIBAN(this.stringVal) ?? '');
    this.$set(this.model.$state, 'validIBAN', iban.valid);
  }

  get ibanAllowedCountries() {
    return getState(this.model, 'ibanAllowedCountries', []);
  }

  get stringVal() {
    return friendlyFormatIBAN(this.field.$model) || this.field.$model;
  }

  get dirtyOnInput() {
    return getState(this.model, 'dirtyOnInput');
  }

  get placeholder() {
    return getState(this.model, 'placeholder');
  }

  get defaultCountry() {
    return getState(this.model, 'defaultCountry', '');
  }

  get dynamicPlaceholder() {
    return getState(this.model, 'dynamicPlaceholder', true);
  }

  get enabledCountryCode() {
    return getState(this.model, 'enabledCountryCode', true);
  }

  get inputOptions() {
    return getState(this.model, 'dynamicPlaceholder', { showDialCode: true, tabindex: 0 });
  }

  get countries() {
    return getState(this.model, 'countries', []);
  }
}
</script>
