<script setup lang="ts">
import {
  Transaction,
  isTrade,
  isDeposit,
  isWithdraw,
  HistoricalTransaction,
  TransactionExternal,
  TransactionState,
  Trade,
  Beneficiary,
  ExportListType,
  beneficiaryName,
  TransactionInternal,
  isSchedule,
  getBuyCcy,
  getSellCcy,
  ScheduledTransaction,
  AmountType,
  getPrimaryCcy,
  BankingScheme,
  getCurrencyPair,
  transactionStateLabels,
  AuthorityType,
  hedgingInstrumentToHuman,
  isPartnerPayment,
} from 'ah-api-gateways';
import WithdrawFromWalletModal from 'ah-wallets/src/components/WithdrawFromWalletModal.vue';
import TransactionReasonComponent from 'ah-wallets/src/components/transactions/TransactionReason.vue';
import { maskedIban } from 'ah-common-lib/src/helpers/iban';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, reactive, ref, watch } from 'vue';
import { useWalletState } from '../..';
import { VButton } from 'ah-common-lib/src/common/components';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useTradeState } from 'ah-trades';

const props = withDefaults(
  defineProps<{
    transaction: Transaction;
    hideState?: boolean | string;
  }>(),
  {
    hideState: true,
  }
);

const requestManager = useRequestManager({
  exposeToParent: ['getTransactionBeneficiary', 'getTransactionTrade'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'getTransactionBeneficiary') {
      loadBeneficiaryDetails();
    } else if (k === 'getTransactionTrade') {
      loadTradeDetails();
    }
  },
});

const state = reactive<{
  transactionTrade: Trade | null;
  beneficiary: Beneficiary | null;
  showTransactionDetailsDownloadPopover: boolean;
}>({
  transactionTrade: null,
  beneficiary: null,
  showTransactionDetailsDownloadPopover: false,
});

const walletState = useWalletState();

const downloadButton = ref<InstanceType<typeof VButton> | null>(null);

const onBehalfOfClient = useOnBehalfOf();

const emit = defineEmits<{
  /*
   * Emits:
   * - transaction-cancel (transaction: Transaction): triggered when cancel payment is clicked
   */
  (e: 'withdraw-succeded', value: void): void;
}>();

walletState.store.useWalletsStore().loadCurrencyWallet({
  currency: (props.transaction as TransactionInternal).currency,
});

walletState.store.useSettingsStore().loadRoutingCodeTypes();

const isFailedTx = computed(() => (props.transaction as HistoricalTransaction).state === TransactionState.FAILED);

const isRejectedTx = computed(() => (props.transaction as HistoricalTransaction).state === TransactionState.REJECTED);

const wallet = computed(() => {
  let wallet = walletState.store
    .useWalletsStore()
    .getWalletById((props.transaction as TransactionInternal).otherWalletId);
  if (!wallet) {
    wallet = walletState.store.useWalletsStore().getWallet(
      (props.transaction as TransactionInternal).currency,
      onBehalfOfClient.value
        ? {
            isPartner: !(onBehalfOfClient.value?.id || walletState.store.useAuthStore().isClientUser),
            id: onBehalfOfClient.value?.id,
          }
        : undefined
    );
  }
  return wallet ?? null;
});

const transactionRoutingCode = computed(() => {
  const routingData = walletState.store
    .useSettingsStore()
    .routingCodes.find((r) => r.routingCodeType === (props.transaction as TransactionExternal).otherRoutingCode);
  if (routingData) {
    return routingData.title;
  }
  return 'Routing Code';
});

const transactionBeneficiaryName = computed(() => (state.beneficiary ? beneficiaryName(state.beneficiary) : ''));

const loadingTrade = computed(() => requestManager.manager.requestStates['getTransactionTrade'] === 'pending');

const loadingBeneficiary = computed(
  () => requestManager.manager.requestStates['getTransactionBeneficiary'] === 'pending'
);

const canDownloadStatement = computed(() =>
  walletState.store.useAuthStore().hasAuthorities(AuthorityType.EXPORT_TRANSACTIONS)
);

/**
 * Partners/Traders can cancel transactions via OBO, as can Client users with the correct authority
 */
const canCancelTransaction = computed(() => {
  if (onBehalfOfClient.value) {
    return true;
  }
  if (props.transaction.tradeId) {
    return walletState.store.useAuthStore().hasAuthorities(AuthorityType.UPDATE_TRADES);
  }
  return true;
});

const isScheduledTransaction = computed(() => isSchedule(props.transaction));

const isPartnerMadePayment = computed(() => isPartnerPayment(props.transaction));

const detailsAvailable = computed(() => {
  return (
    !loadingBeneficiary.value &&
    !loadingTrade.value &&
    (isTransactionDeposit.value ||
      isTransactionWithdraw.value ||
      (isTransactionTrade.value && state.transactionTrade) ||
      isPartnerMadePayment.value)
  );
});

function getMarkupValue(totalMarkupBasisPoints: number) {
  const out = Math.round(totalMarkupBasisPoints);
  if (!isNaN(out)) {
    return out + 'bp';
  } else {
    return '-';
  }
}

function toggleTransactionDetailsDownloadPopover(value?: boolean) {
  setTimeout(() => {
    state.showTransactionDetailsDownloadPopover = value ?? !state.showTransactionDetailsDownloadPopover;
  });
}

const buyData = computed(() => {
  if (state.transactionTrade) {
    return getBuyCcy(state.transactionTrade);
  }
  return '';
});

const sellData = computed(() => {
  if (state.transactionTrade) {
    return getSellCcy(state.transactionTrade);
  }
  return '';
});

const currencyPair = computed(() => {
  if (state.transactionTrade) {
    return getCurrencyPair(state.transactionTrade);
  }
  return '';
});

const fxRate = computed(() => {
  if (state.transactionTrade) {
    return getPrimaryCcy(state.transactionTrade).clientRate;
  }
  return '';
});

const isTransactionTrade = computed(() => isTrade(props.transaction));

const isTransactionDeposit = computed(() => isDeposit(props.transaction));

const isTransactionWithdraw = computed(() => isWithdraw(props.transaction));

const isSwiftWithdraw = computed(
  () => isTransactionWithdraw.value && state.beneficiary?.bankingScheme === BankingScheme.SWIFT
);

const transactionConfirmed = computed(
  () => (props.transaction as HistoricalTransaction).state === TransactionState.CONFIRMED
);

const isOpenPayment = computed(() => !!(props.transaction as ScheduledTransaction).executionDate);

const isRelatedToQuote = computed(() => props.transaction.quoteId);

const targetData = computed(() => {
  if (state.transactionTrade) {
    const primaryCurr = getPrimaryCcy(state.transactionTrade);
    if (primaryCurr.amountType === AmountType.SELL) {
      return sellData.value ?? {};
    }
    return buyData.value ?? {};
  }
  return {};
});

const stateLabel = computed(() => {
  if ((props.transaction as HistoricalTransaction).state) {
    return transactionStateLabels[(props.transaction as HistoricalTransaction).state];
  }
  return '';
});

function loadTradeDetails() {
  if (props.transaction.tradeId) {
    requestManager.manager
      .sameOrCancelAndNew(
        'getTransactionTrade',
        walletState.services.trade.getTrade(props.transaction.tradeId),
        props.transaction.tradeId
      )
      .subscribe((trade) => {
        state.transactionTrade = trade;
      });
  }
}

watch(() => props.transaction, loadTradeDetails, { immediate: true });

function downloadPaymentMt103(type: ExportListType) {
  if (props.transaction.paymentId) {
    requestManager.manager
      .sameOrCancelAndNew(
        'downloadPaymentMt103',
        walletState.services.payments.downloadPaymentMt103(
          props.transaction.paymentId,
          type,
          `MT103 Statement${onBehalfOfClient.value?.name ? ` for client ${onBehalfOfClient.value.name}` : ''}`
        ),
        props.transaction.tradeId
      )
      .subscribe();
  }
}

function redirectToBeneficiary(id: string) {
  return onBehalfOfClient.value
    ? `/admin/activity/clients/${onBehalfOfClient.value.id}/beneficiaries/${id}`
    : `/dashboard/beneficiaries/${id}`;
}

function downloadTransactionDetails(type: ExportListType) {
  state.showTransactionDetailsDownloadPopover = false;
  if (props.transaction && !isScheduledTransaction.value) {
    requestManager.manager
      .sameOrCancelAndNew(
        'downloadTransactionTrade',
        walletState.services.wallet.downloadTransactionDetails(
          (props.transaction as HistoricalTransaction).walletId,
          (props.transaction as HistoricalTransaction).id,
          type
        ),
        (props.transaction as HistoricalTransaction).id
      )
      .subscribe();
  }
}

function loadBeneficiaryDetails() {
  const id = state.transactionTrade?.beneficiaryId || (props.transaction as TransactionExternal).otherBeneficiaryId;
  if (id) {
    requestManager.manager
      .sameOrCancelAndNew('getTransactionBeneficiary', walletState.services.beneficiary.getBeneficiary(id), id)
      .subscribe((beneficiary) => {
        state.beneficiary = beneficiary;
      });
  }
}

watch(() => state.transactionTrade, loadBeneficiaryDetails, { immediate: true });

function onWithdrawSucceeded() {
  emit('withdraw-succeded');
}

const tradeState = useTradeState();

const isClient = computed(() => tradeState.store.useAuthStore().isClientUser);

function routerLink(referenceNumber: string) {
  return isClient.value
    ? `/dashboard/trades/all/` + referenceNumber
    : { path: `/admin/activity/reports`, query: { trade: referenceNumber } };
}
</script>

<template>
  <div class="transaction-details px-sm-0 px-md-4">
    <template v-if="detailsAvailable">
      <p v-if="isFailedTx" class="error">Transaction failed to execute</p>
      <p v-if="isRejectedTx" class="error">Transaction Rejected</p>
      <!-- deposit && withdraws -->
      <VRow v-if="(isTransactionDeposit || isTransactionWithdraw) && !isPartnerMadePayment">
        <VCol md="6" sm="12">
          <DataRow sm="5" md="4" label="Name" class="transaction-target-name" v-if="transaction.otherName">
            {{ transaction.otherName }}
          </DataRow>
          <DataRow sm="5" md="4" label="Date" v-if="transaction.docCreatedAt" class="transaction-doc-created">
            {{ formatDate(transaction.docCreatedAt) }}
          </DataRow>
          <DataRow sm="5" md="4" label="IBAN" v-if="transaction.otherIban" class="transaction-target-iban">
            {{ maskedIban(transaction.otherIban) }}
          </DataRow>
          <DataRow sm="5" md="4" label="SWIFT" v-if="transaction.otherSwift" class="transaction-target-swift">
            {{ transaction.otherSwift }}
          </DataRow>
          <DataRow sm="5" md="4" label="Payment reason" v-if="transaction.description" class="transaction-description">
            {{ transaction.description }}
          </DataRow>
          <DataRow sm="5" md="4" label="Category" v-if="transaction.type">
            <TransactionReasonComponent :transaction="transaction" />
          </DataRow>
          <DataRow
            sm="5"
            md="4"
            :label="transactionRoutingCode"
            v-if="transaction.otherRoutingNumber"
            class="transaction-target-routing-number"
          >
            {{ transaction.otherRoutingNumber }}
          </DataRow>
          <DataRow
            sm="5"
            md="4"
            label="Account Number"
            v-if="transaction.otherAccountNumber"
            class="transaction-target-account-number"
          >
            {{ transaction.otherAccountNumber }}
          </DataRow>
          <DataRow sm="5" md="4" label="Reference" v-if="transaction.reference" class="transaction-target-state">
            {{ transaction.reference }}
          </DataRow>
          <DataRow md="4" sm="6" label="State" v-if="hideState === false">
            {{ stateLabel }}
          </DataRow>
        </VCol>

        <VCol
          md="6"
          sm="12"
          class="right-col text-md-right text-sm-center mt-sm-3 mt-md-0 mb-sm-3 mb-md-0"
          v-if="isOpenPayment && state.beneficiary"
        >
          <div class="mb-3" v-if="wallet">
            <WithdrawFromWalletModal
              v-slot="{ show }"
              :targetBeneficiary="state.beneficiary"
              :wallet="wallet"
              :transaction="transaction"
              @withdraw-succeded="onWithdrawSucceeded"
            >
              <VButton class="button-width" @click="show"> Amend Payment </VButton>
            </WithdrawFromWalletModal>
          </div>
          <div>
            <ConfirmModal
              hide-header
              :modalText="`Are you sure you want to cancel this payment to ${transaction.otherName}?`"
              @ok="$emit('transaction-cancel', transaction)"
              v-slot="{ showModal }"
              v-if="transaction.paymentId && !isRelatedToQuote && canCancelTransaction"
            >
              <VButton class="button-width" @click="showModal"> Cancel Payment </VButton>
            </ConfirmModal>
          </div>
        </VCol>
      </VRow>

      <!-- trade transactions -->
      <VRow
        v-else-if="
          (isTransactionTrade || isPartnerMadePayment) && !loadingTrade && state.transactionTrade && sellData && buyData
        "
      >
        <VCol md="6" sm="12">
          <DataRow md="4" sm="6" label="Conversion" class="trade-conversion">
            {{ sellData.currency }}
            {{ formatCurrencyValue(sellData.clientAmount) }}
            <IconArrowRight class="mx-2" />
            <strong>
              {{ buyData.currency }}
              {{ formatCurrencyValue(buyData.clientAmount) }}
            </strong>
          </DataRow>
          <template v-if="!isPartnerMadePayment">
            <DataRow md="4" sm="6" label="FX Rate" class="trade-fx-rate">{{ fxRate }} </DataRow>
            <DataRow md="4" sm="6" label="Currency Pair" class="trade-currency-pair">
              {{ currencyPair }}
            </DataRow>
          </template>
          <DataRow md="4" sm="6" label="Trade ID" class="trade-id">
            <RouterLink
              v-if="!onBehalfOfClient"
              class="plain-color-link"
              :to="routerLink(state.transactionTrade.referenceNumber)"
            >
              {{ state.transactionTrade.referenceNumber }}
            </RouterLink>
            <span v-else> {{ state.transactionTrade.referenceNumber }} </span>
          </DataRow>
          <DataRow md="4" sm="6" label="Trade Date" class="trade-settlement-date">
            {{ formatDate(state.transactionTrade.createdAt) }}
          </DataRow>

          <template v-if="isPartnerMadePayment">
            <DataRow md="4" sm="6" label="Commission Rate" class="commission-rate">
              {{ (state.transactionTrade.partnerCommissionPercentage ?? 0) * 100 }}%
            </DataRow>
            <DataRow md="4" sm="6" label="Mark Up (bp)" class="mark-up">
              {{ getMarkupValue(state.transactionTrade.partnerMarkupBasisPoints) }}
            </DataRow>
          </template>
          <template v-else>
            <DataRow md="4" sm="6" label="Beneficiary" v-if="state.beneficiary" class="trade-beneficiary">
              <RouterLink class="plain-color-link" :to="redirectToBeneficiary(state.beneficiary.id)">
                {{ transactionBeneficiaryName }}
              </RouterLink>
            </DataRow>
            <DataRow
              sm="5"
              md="4"
              label="Payment reason"
              v-if="state.beneficiary && transaction.description"
              class="transaction-description"
            >
              {{ transaction.description }}
            </DataRow>
          </template>
        </VCol>

        <VCol md="6" sm="12" class="right-col">
          <template v-if="!isPartnerMadePayment">
            <DataRow sm="6" label="IM requirement" v-if="targetData" class="trade-initial-margin pt-3">
              {{ formatCurrencyValue(state.transactionTrade.gbpInitialMarginAmount) }}
              GBP
            </DataRow>
            <DataRow sm="6" label="VM requirement" class="trade-variable-margin">
              {{ formatCurrencyValue(state.transactionTrade.gbpVmm) }}
              GBP
            </DataRow>
          </template>
          <template v-else>
            <DataRow label="Client Name">
              {{ state.transactionTrade.clientName }}
            </DataRow>
            <DataRow label="Instrument">
              {{ hedgingInstrumentToHuman[state.transactionTrade.hedgingProduct] }}
            </DataRow>
            <DataRow label="Currency Pair">
              {{ currencyPair }}
            </DataRow>
          </template>
        </VCol>
      </VRow>

      <div class="row mt-3" v-if="!isScheduledTransaction && canDownloadStatement">
        <div class="d-flex justify-content-md-end justify-content-sm-center col-12 mb-3 mt-n5">
          <VButton
            v-if="isSwiftWithdraw && transactionConfirmed"
            class="mr-3"
            @click="downloadPaymentMt103(ExportListType.PDF)"
            :loading="requestManager.manager.requestStates.downloadPaymentMt103 === 'pending'"
          >
            <IconDownload /> Download MT103
          </VButton>

          <VButton
            ref="downloadButton"
            @click="toggleTransactionDetailsDownloadPopover(true)"
            :loading="requestManager.manager.requestStates.downloadTransactionTrade === 'pending'"
          >
            <IconDownload /> Transaction details
          </VButton>
          <BPopover
            :show.sync="state.showTransactionDetailsDownloadPopover"
            placement="bottomright"
            custom-class="arrowless paddingless"
            :target="() => downloadButton && downloadButton.$el"
            triggers="click blur"
          >
            <div class="popover-container dropdown-menu-block">
              <div class="popover-section">
                <ul>
                  <li @click="downloadTransactionDetails(ExportListType.PDF)">PDF</li>
                  <li @click="downloadTransactionDetails(ExportListType.CSV)">CSV</li>
                  <li @click="downloadTransactionDetails(ExportListType.XLSX)">Excel</li>
                </ul>
              </div>
            </div>
          </BPopover>
        </div>
      </div>
    </template>

    <!-- loading -->
    <div class="loading-div" v-else-if="loadingTrade || loadingBeneficiary">
      <Transition name="fade" appear>
        <LoadingIcon class="loading-icon" />
      </Transition>
    </div>

    <!-- no details -->
    <div v-else>Transaction details not found</div>
  </div>
</template>

<style lang="scss" scoped>
.button-width {
  min-width: 12rem;
}
.data-row {
  white-space: normal;
}
.loading-div {
  line-height: 1em;
  text-align: center;
  font-size: 3em;
}

.error {
  @include themedTextColor($color-danger);
}

.transaction-details {
  @include upToResolution($tabletResolution) {
    font-size: 13px;
  }
}

.right-col {
  border-left: 1px solid;
  padding-left: 1rem;
  @include themedBorderColor($color-primary-light);
}
</style>
