<template>
  <div class="group-member">
    <div class="member-data">
      <h4 class="title">{{ individual.firstName }} {{ individual.lastName }}</h4>
      <div class="individual-info">
        <p class="email">{{ individual.email }}</p>
        <p class="phone-number">{{ getPhoneNumber(individual.phoneNumber) }}</p>
      </div>
    </div>
    <div class="side-icons">
      <div class="delete-icon" @click="$emit('delete')">
        <IconTrash />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Individual } from 'ah-api-gateways';
import { getPhoneNumber } from 'ah-common-lib/src/helpers/calls';

@Component
export default class GroupMemberBlock extends Vue {
  @Prop({ required: true }) individual!: Individual;

  getPhoneNumber = getPhoneNumber;
}
</script>
<style lang="scss" scoped>
.group-member {
  display: inline-block;
  position: relative;
  border-radius: $box-border-radius;
  padding: math.div($padded-space, 3);

  &:hover {
    @include themedBackgroundColor($color-highlight-grey);
    .side-icons {
      opacity: 1;
    }
  }

  .member-data {
    padding-right: 3em;

    h4.title {
      font-size: 14px;
      font-weight: 400;
    }

    .individual-info {
      p {
        margin-bottom: 0;
        font-size: 10px;
        line-height: 13px;
        min-height: 13px;
        @include themedTextColor($color-text-secondary);
      }
    }
  }

  .side-icons {
    position: absolute;
    right: 0.5em;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    opacity: 0;

    .delete-icon {
      width: 2em;
      height: 2em;
      cursor: pointer;
      border-radius: 2rem;
      @include themedTextColor($color-text-secondary);

      &:hover {
        @include themedBackgroundColor($color-primary);
        @include themedProp('color', white, white);
      }

      svg {
        height: 60%;
        width: 60%;
        margin: 20%;
      }
    }
  }
}
</style>
