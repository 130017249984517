<script lang="ts" setup>
import { BModal } from 'bootstrap-vue';
import { Trade } from 'ah-trades/../ah-api-gateways/models';
import TradeStateBadge from 'ah-common-lib/src/common/components/trade/TradeStateBadge.vue';
import TradeMarginInfo from 'ah-trades/src/components/info/TradeMarginInfo.vue';
import TradeProfitInfo from 'ah-trades/src/components/info/TradeProfitInfo.vue';
import TradeDrawdownInfo from 'ah-trades/src/components/info/TradeDrawdownInfo.vue';
import TradeInfo from 'ah-trades/src/components/info/TradeInfo.vue';
import { computed, reactive, ref } from 'vue';
import { useTradeState } from '../..';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { AdminTrade } from 'ah-api-gateways/models/trade';

const tradeState = useTradeState();

const props = withDefaults(
  defineProps<{
    /**
     * Trade ID of the trade currently showing the details off
     */
    tradeId: string;
    /**
     * Modal title
     */
    title: string;
    /**
     * Whether to load amended trades of trade with `tradeId` instead.
     */
    showAmended?: boolean | string;
    /**
     * Drawdown ID of the trade currently showing the details off
     */
    childrenId?: string;
    hideState?: boolean | string;
  }>(),
  {
    title: 'Trade Details',
    showAmended: false,
    hideState: false,
  }
);

const tradeDetailsModal = ref<InstanceType<typeof BModal> | null>(null);

const requestManager = useRequestManager();

const state = reactive({
  trade: null as Trade | null,
  childTrade: null as Trade | null,
});

const isAHUser = computed(() => {
  return tradeState.store.useAuthStore().isAHUser;
});

const adminTrade = computed(() => {
  if (isAHUser.value && (state.trade as AdminTrade).gbpAhProfit) {
    return state.trade as AdminTrade;
  }
  return null;
});

const anyPending = computed(() => {
  return requestManager.manager.anyPending;
});

const parentTradeReference = computed(() => {
  return state.trade?.referenceNumber ?? null;
});

const shouldHideState = computed(() => {
  return props.hideState !== true;
});

const tradeRequestKey = computed(() => {
  return `getTrade-${props.tradeId}`;
});

const drawdownRequestKey = computed(() => {
  return `getDrawdown-${props.tradeId}`;
});

function show() {
  tradeDetailsModal.value!.show();
}

function hide() {
  requestManager.manager.cancel(tradeRequestKey.value);
  tradeDetailsModal.value!.hide();
}

function loadTrade() {
  if (props.showAmended !== false) {
    // Currently we only allow amending a trade once, thus always getting the first element of
    // the filtered list
    requestManager.manager
      .sameOrCancelAndNew(
        tradeRequestKey.value,
        tradeState.services.trade.listTrades({ amendedTradeId: props.tradeId })
      )
      .subscribe(
        (response) => {
          if (response.list.length > 0) {
            state.trade = response.list[0];
          } else {
            tradeState.toast.error('No amended trades here found.');
            hide();
          }
        },
        () => hide()
      );
  } else {
    requestManager.manager
      .sameOrCancelAndNew(tradeRequestKey.value, tradeState.services.trade.getTrade(props.tradeId))
      .subscribe(
        (response) => (state.trade = response),
        () => hide()
      );
  }

  if (props.childrenId) {
    requestManager.manager
      .sameOrCancelAndNew(drawdownRequestKey.value, tradeState.services.trade.getTrade(props.childrenId))
      .subscribe((response) => (state.childTrade = response));
  }
}

loadTrade();
</script>

<template>
  <span>
    <BModal :title="title" modal-class="side-modal trade-details-modal" ref="tradeDetailsModal" hide-footer>
      <LoadingOverlay class="h-90" :state="requestManager.manager.currentStates" no-wrap />
      <div v-if="state.trade">
        <VRow class="mb-3" v-if="!shouldHideState">
          <VCol> State </VCol>
          <VCol> <TradeStateBadge v-if="state.trade" :state="state.trade.state" /> </VCol>
        </VRow>
        <BoxGrid>
          <BoxGridBlock cols="12">
            <h3>Trade Details</h3>
            <TradeInfo :trade="state.trade" v-bind="$attrs" />
          </BoxGridBlock>
          <BoxGridBlock cols="12">
            <h3>Margin Info</h3>
            <TradeMarginInfo :trade="state.trade" />
          </BoxGridBlock>
          <BoxGridBlock cols="12" v-if="adminTrade">
            <h3>Profit Info</h3>
            <TradeProfitInfo :trade="adminTrade" />
          </BoxGridBlock>
          <BoxGridBlock cols="12" v-if="state.childTrade">
            <h3>Drawdown Info</h3>
            <TradeDrawdownInfo :parentTrade="state.trade" :drawdown="state.childTrade" />
          </BoxGridBlock>
        </BoxGrid>
      </div>
      <VButton blurOnClick @click="hide"> Close </VButton>
    </BModal>
    <slot v-bind="{ show, parentTradeReference, anyPending }">
      <a class="link" @click="show">here</a>
    </slot>
  </span>
</template>

<style lang="scss" scoped>
::v-deep {
  .trade-details-modal .modal-dialog {
    width: 40em;
  }
}
</style>
