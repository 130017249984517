<script lang="ts" setup>
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { usePlaidLink, preloadPlaid } from 'ah-common-lib/src/plaid';
import IntegrationsListing from './IntegrationsListing.vue';
import { getServices } from '@/app/services';
import { VButton } from 'ah-common-lib/src/common/components';
import { useToast } from 'ah-common-lib/src/toast';
import { ref } from 'vue';

const reqManager = useRequestManager().manager;
const services = getServices();
const toast = useToast();

const integrationsListing = ref<InstanceType<typeof IntegrationsListing>>();

preloadPlaid();

function addPlaidIntegration() {
  reqManager.cancelAndNew('getPlaidToken', services.integrations.requestPlaidLinkToken()).subscribe((tokenData) => {
    const plaidLink = usePlaidLink({
      token: tokenData.linkToken,
      onExit: () => {
        plaidLink.destroy();
      },
      onSuccess: (publicToken) => {
        reqManager
          .new('exchengePlaidToken', services.integrations.exchangePlaidPublicToken(publicToken))
          .subscribe(() => {
            toast.success('Plaid integration sucessfully added');
            integrationsListing.value?.loadData();
            plaidLink.destroy();
          });
      },
    });

    plaidLink.open();
  });
}
</script>
<template>
  <div>
    <IntegrationsListing ref="integrationsListing" />
    <p class="text-right">
      <VButton @click="addPlaidIntegration" :loading="reqManager.anyPending">+ Add Plaid Integration</VButton>
    </p>
    <p class="text-right text-secondary text-small font-italic">
      In Plaid Sandbox mode, all mock bank accounts will allow using the username/password <b>user_good/pass_good</b>
    </p>
  </div>
</template>
