<script lang="ts" setup>
import UserIconMenu from '@/app/components/user/UserIconMenu.vue';
import UpdatesModal from '@/app/components/updates/UpdatesModal.vue';
import SideNavMobile from './SideNavMobile.vue';
import NeedHelpButton from '../common/NeedHelpButton.vue';
import { XHedgeEventBusEvents } from '@/app/constants/events';
import { FileExportsModal } from 'ah-notifications';
import { useNotificationsStore } from 'ah-notifications/src/store';
import { computed, ref } from 'vue';
import { useEventBusListener } from 'ah-common-lib/src/eventBus/EventBusPlugin';
import XHedgeNotificationsModal from './XHedgeNotificationsModal.vue';

const updatesModal = ref<InstanceType<typeof UpdatesModal>>();

const notificationsStore = useNotificationsStore();

function showUpdates() {
  updatesModal.value?.show();
}

useEventBusListener(XHedgeEventBusEvents.SHOW_UPDATES, showUpdates);

const hasUnreadFileExports = computed(() => notificationsStore.fileExportUnreadCount > 0);

const hasUnreadUpdates = computed(() => notificationsStore.updateUnreadCount > 0);
</script>
<template>
  <div class="top-nav-holder">
    <div class="nav-links d-md-flex d-sm-none">
      <NeedHelpButton />
    </div>
    <div class="updates-icon">
      <UpdatesModal v-slot="{ show }" ref="updatesModal">
        <span @click="show" v-b-tooltip="'Updates'">
          <IconUpdatesActive v-if="hasUnreadUpdates" />
          <IconUpdates v-else />
        </span>
      </UpdatesModal>
    </div>
    <div class="notification-icon">
      <XHedgeNotificationsModal />
    </div>
    <div class="download-icon">
      <FileExportsModal v-slot="{ show }">
        <span @click="show" v-b-tooltip="'Downloads'">
          <IconDownloadActive v-if="hasUnreadFileExports" />
          <IconDownload v-else />
        </span>
      </FileExportsModal>
    </div>
    <div class="menu-icon">
      <SideNavMobile v-slot="{ show }">
        <button class="menu-button mx-3" @click="show">
          <MenuIcon class="topbar-menu-icon" />
        </button>
      </SideNavMobile>
    </div>
    <UserIconMenu class="user-icon-menu" />
    <slot name="nav-siblings" />
  </div>
</template>
<style lang="scss" scoped>
.topbar-menu-icon,
.topbar-close-icon {
  ::v-deep path {
    @include themedPropColor('fill', $color-dark-main-bg, $color-main-bg);
  }
}

.topbar-close-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.user-icon-menu,
.menu-icon {
  padding-top: 0.2em;
  padding-right: 0.5em;
}

.logo {
  width: $side-nav-width;
  height: $side-nav-width;
  margin-bottom: 1rem;
  position: relative;

  .logo-inner {
    width: $side-nav-width - 16px;
    height: $side-nav-width - 16px;
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

.tooltip {
  margin-top: 5px !important;
}
</style>
