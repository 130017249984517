import { render, staticRenderFns } from "./NotificationSettingsTable.vue?vue&type=template&id=13248423&scoped=true"
import script from "./NotificationSettingsTable.vue?vue&type=script&lang=ts"
export * from "./NotificationSettingsTable.vue?vue&type=script&lang=ts"
import style0 from "./NotificationSettingsTable.vue?vue&type=style&index=0&id=13248423&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13248423",
  null
  
)

export default component.exports