<script lang="ts" setup>
import TradeDetailsForm from './TradeDetailsForm.vue';
import { TimeFrameDate, DrawdownDate } from '../../models/date';
import { TradeDetails } from '../../models/trade';
import UpdatedDate from 'ah-common-lib/src/common/components/time/UpdatedDate.vue';
import { Beneficiary, HedgingInstruments, NonTradeableDays, CutoffTimeModel } from 'ah-api-gateways';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { radioField } from 'ah-common-lib/src/form/models';
import SpotDateForm from './SpotDateForm.vue';
import ForwardDateForm from './ForwardDateForm.vue';
import ForwardOptionsForm from './ForwardOptionsForm.vue';
import { QuotePriceRequest } from '../../requests/pricingRequest';
import { TimeFrames } from 'ah-common-lib/src/constants/timeframes';
import { addMinutes, format, addDays, differenceInDays, startOfDay, startOfToday } from 'date-fns';
import { combineLatest } from 'rxjs';
import { SECOND } from 'ah-common-lib/src/constants/time';
import { ApiError } from 'ah-requests';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, onBeforeUnmount, reactive, ref, watch } from 'vue';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { useTradeState } from '../..';

import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const props = withDefaults(
  defineProps<{
    /**
     * Trade details object (managed by TradeDetailsForm)
     *
     * synchronizable via `update:tradeDetails` or .sync modifier
     */
    tradeDetails?: TradeDetails | null;
    /**
     * Trade price request and response (as received by getting/refreshing  the trade)
     *
     * synchronizable via `update:tradePrice` or .sync modifier
     */
    tradePrice?: QuotePriceRequest | null;
    /**
     * Possible beneficiary target of the trade
     * Will affect UX and options available in child components
     */
    beneficiary?: Beneficiary;
    /**
     * Possible array of allowed buy currencies
     *
     * If set, user will only be able to choose from the currencies listed as a buy currency
     */
    allowedBuyCurrencies?: string[];

    /**
     * Possible array of allowed sell currencies
     *
     * If set, user will only be able to choose from the currencies listed as a sell currency
     */
    allowedSellCurrencies?: string[];
    /**
     * Whether to force a spot.
     *
     * If set, user cannot choose the type of trade
     *
     * A value-less prop (empty string) will be considered as a truthy value
     * (<Component foo /> => <Component :foo=true />)
     */
    forceImmediateSpot?: boolean | string;
    /**
     * Whether to sync trade funds.
     *
     * If set, trades will be synchronized, and user will see an alert to that effect
     *
     * A value-less prop (empty string) will be considered as a truthy value
     * (<Component foo /> => <Component :foo=true />)
     */
    syncTradeFunds?: boolean | string;

    /**
     * Whether to force keeping funds
     *
     * If set, user cannot choose the target of the trade, and will always keep them
     * (i.e. move to another wallet)
     *
     * A value-less prop (empty string) will be considered as a truthy value
     * (<Component foo /> => <Component :foo=true />)
     *
     */
    forceKeep?: boolean;
    /**
     * Whether to verify limits
     *
     * If true:
     * - client will verify limits
     */
    verifyLimits?: string | boolean;
  }>(),
  {
    tradeDetails: null,
    tradePrice: null,
    forceImmediateSpot: false,
    syncTradeFunds: false,
    forceKeep: false,
    verifyLimits: false,
  }
);

const onBehalfOfClient = useOnBehalfOf();

const tradeState = useTradeState();

const tradeDetailsForm = ref<InstanceType<typeof TradeDetailsForm> | null>(null);
const spotDateForm = ref<InstanceType<typeof SpotDateForm> | null>(null);
const forwardDateForm = ref<InstanceType<typeof ForwardDateForm> | null>(null);
const forwardOptionsForm = ref<InstanceType<typeof ForwardOptionsForm> | null>(null);

const requestManager = useRequestManager({
  exposeToParent: ['loadAndSetUpDateForImmediateSpot'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadAndSetUpDateForImmediateSpot') {
      loadAndSetUpDateForImmediateSpot();
    }
  },
});

const tradeTypeForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'tradeTypeForm',
    fieldType: 'form',
    fields: [
      radioField(
        'type',
        '',
        [
          {
            label: 'Spot',
            value: 'FX_SPOT',
          },
          {
            label: 'Forward',
            value: 'FX_FORWARD',
          },
        ],
        {
          inline: true,
          fieldWrapperClass: 'col col-12 mb-0',
        }
      ),
    ],
  }),
  validation: null,
});

const emit = defineEmits<{
  (e: 'update:loadingPrices', value: boolean): void;
  (e: 'update:tradePrice', value: QuotePriceRequest): void;
  (e: 'update:tradeDetails', value: TradeDetails): void;
  (e: 'update:walletBalanceUsageRequired', value: boolean): void;
}>();

const state = reactive<{
  loadingPrices: boolean;
  timeFrame: TimeFrameDate | null;
  drawdownDate: DrawdownDate | null;
  apiError: ApiError | null;
}>({
  drawdownDate: null,
  timeFrame: null,
  loadingPrices: false,
  apiError: null,
});

const dateSetupTimeout = ref<number | null>(null);

const tradeDirection = computed(() => `${props.tradeDetails?.sellCurrency}${props.tradeDetails?.buyCurrency}`);

const isImmediateSpot = computed(() => props.beneficiary || props.forceImmediateSpot !== false);

const immediateSpotDate = computed(() => {
  if (isImmediateSpot.value && state.timeFrame?.targetDate) {
    const date = new Date(state.timeFrame!.targetDate);
    const difference = differenceInDays(startOfDay(date), startOfToday());

    if (difference === 0) {
      return 'today';
    } else if (difference === 1) {
      return 'tomorrow';
    }
    return `on ${format(date, 'MMM dd, yyyy')}`;
  }
  return '';
});

const allowTradeTypeSelection = computed(() => !isImmediateSpot.value && state.apiError === null);

/**
 * Public methods
 */

function refreshPrices() {
  tradeDetailsForm.value?.refreshData();
}

function touchForms() {
  [tradeDetailsForm.value, spotDateForm.value, forwardDateForm.value, forwardOptionsForm.value].forEach((k) => {
    if (k && typeof k.touchForms === 'function') {
      k.touchForms();
    }
  });
}

function areDatesValid() {
  if (tradeTypeForm.form.type === 'FX_SPOT' && spotDateForm.value) {
    return spotDateForm.value.isValid();
  } else if (tradeTypeForm.form.type === 'FX_FORWARD' && forwardDateForm.value && forwardOptionsForm.value) {
    return forwardDateForm.value.isValid() && forwardOptionsForm.value.isValid();
  }
  return true;
}

onBeforeUnmount(() => {
  if (dateSetupTimeout.value) {
    clearTimeout(dateSetupTimeout.value);
  }
});

defineExpose({ refreshPrices, touchForms, areDatesValid });

/**
 * Private methods
 */

watch(
  () => state.loadingPrices,
  () => {
    emit('update:loadingPrices', state.loadingPrices);
  },
  { immediate: true }
);

watch(
  () => props.tradeDetails,
  () => {
    state.apiError = null;
    if (props.tradeDetails?.timeFrame) {
      state.timeFrame = props.tradeDetails.timeFrame;
      tradeTypeForm.form.type = state.timeFrame.isForward ? 'FX_FORWARD' : 'FX_SPOT';
    } else {
      tradeTypeForm.form.type = 'FX_SPOT';
    }
  },
  { immediate: true }
);

watch(
  () => props.tradePrice,
  () => {
    if (props.tradePrice) {
      state.drawdownDate = props.tradePrice.tradeDetails.drawdownDate ?? null;
    }
  },
  { immediate: true }
);

function loadAndSetUpDateForImmediateSpot() {
  state.apiError = null;
  if (tradeDirection.value && isImmediateSpot.value) {
    requestManager.manager
      .sameOrCancelAndNew(
        'loadAndSetUpDateForImmediateSpot',
        combineLatest([
          tradeState.services.pricingEngine.getNonTradeableDays(
            HedgingInstruments.FX_SPOT,
            tradeDirection.value,
            onBehalfOfClient.value?.id
          ),
          tradeState.services.pricingEngine.getCutoffTime(tradeDirection.value, onBehalfOfClient.value?.id),
        ]),
        tradeDirection.value
      )
      .pipe(
        catchError((error) => {
          if (error.response.status === 400) {
            displayError(error.response.data);
            return of(null);
          } else {
            throw error;
          }
        })
      )
      .subscribe((payload: [NonTradeableDays, CutoffTimeModel] | null) => {
        if (!payload) return;
        const nonTradeableDays = payload[0];
        const cuttoffTime = payload[1];
        const disallowedDates = [...nonTradeableDays.holidays, ...nonTradeableDays.weekend];
        let targetDate = addMinutes(new Date(cuttoffTime.firstConversionCutoffDatetime), -5);
        while (disallowedDates.includes(format(targetDate, 'dd-MM-yyyy'))) {
          targetDate = addDays(targetDate, 1);
        }

        const timeoutVal = new Date(cuttoffTime.firstConversionCutoffDatetime).valueOf() - Date.now() - 1 * SECOND;
        if (timeoutVal > 0) {
          dateSetupTimeout.value = window.setTimeout(() => loadAndSetUpDateForImmediateSpot(), timeoutVal);
        }
        state.timeFrame = {
          isForward: false,
          targetTimeFrame: TimeFrames.OTHER,
          targetDate: targetDate.toISOString(),
        };
      });
  }
}

watch(() => [tradeDirection.value, isImmediateSpot.value], loadAndSetUpDateForImmediateSpot, { immediate: true });

function displayError(error: ApiError) {
  state.apiError = error;
  tradeDetailsForm.value?.checkPricingEngineValidation(error);
}
</script>

<template>
  <div>
    <UpdatedDate
      v-if="tradeDetails && tradeDetails.valid && (tradePrice || state.loadingPrices)"
      :date="tradePrice && tradePrice.response && tradePrice.response.priceRequestedTimestamp"
      :offsetMillis="tradePrice && tradePrice.responseTimeOffset"
      dateStyle="short"
      :loading="state.loadingPrices"
      @refresh="refreshPrices"
      class="trade-updated-date"
      account-for-clock-delay
    />
    <div class="inline-flex" v-if="allowTradeTypeSelection">
      <ValidatedForm :fm="tradeTypeForm.form">
        <template #tradeTypeForm.type:append>
          <InfoTooltip
            :text="`<p>${$ahTradesState.i18n.t('tooltips.spot')}</p><p>${$ahTradesState.i18n.t(
              'tooltips.forward'
            )}</p>`"
          />
        </template>
      </ValidatedForm>
    </div>
    <TradeDetailsForm
      ref="tradeDetailsForm"
      :beneficiary="beneficiary"
      :timeFrame="state.timeFrame"
      :tradeDetails="tradeDetails ?? undefined"
      :allowedBuyCurrencies="allowedBuyCurrencies"
      :allowedSellCurrencies="allowedSellCurrencies"
      :loadingPrices.sync="state.loadingPrices"
      @update:tradeDetails="emit('update:tradeDetails', $event)"
      @update:walletBalanceUsageRequired="emit('update:walletBalanceUsageRequired', $event)"
      :tradePrice="tradePrice ?? undefined"
      :drawdownDate="state.drawdownDate"
      :verifyLimits="verifyLimits"
      @update:tradePrice="emit('update:tradePrice', $event)"
    />
    <template v-if="allowTradeTypeSelection && tradeDetails">
      <h3 class="mt-4">Settlement date <InfoTooltip :text="$ahTradesState.i18n.t('tooltips.settlementDate')" /></h3>
      <ManagedLoadingOverlay showRetry variant="box" opacity="0.85">
        <SpotDateForm
          ref="spotDateForm"
          v-if="tradeTypeForm.form.type === 'FX_SPOT'"
          class="time-select"
          :trade-direction="tradeDirection"
          :timeFrame="state.timeFrame || undefined"
          @update:timeFrame="state.timeFrame = $event"
          @validation-error="displayError"
        />
        <template v-else-if="tradeTypeForm.form.type === 'FX_FORWARD'">
          <ForwardDateForm
            ref="forwardDateForm"
            class="time-select"
            :trade-direction="tradeDirection"
            :timeFrame.sync="state.timeFrame"
            @validation-error="displayError"
          />
          <ForwardOptionsForm
            ref="forwardOptionsForm"
            class="mt-3"
            :trade-direction="tradeDirection"
            :timeFrame="state.timeFrame"
            :drawdownDate.sync="state.drawdownDate"
            @validation-error="displayError"
          />
        </template>
      </ManagedLoadingOverlay>
    </template>

    <template v-if="syncTradeFunds !== false && forceImmediateSpot !== false && forceKeep !== false">
      <p class="text-secondary">Your balance will be updated {{ immediateSpotDate }}.</p>
      <p class="text-secondary mb-0">
        Please allow time for funds to update in your wallet balances. Prior to settlement your conversion will appear
        as a scheduled transaction.
      </p>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.card-block {
  @include responsiveGutter('margin-bottom');
}

.trade-updated-date {
  position: absolute;
  top: 24px;
  right: 20px;
  font-size: 14px;
  @include themedTextColor($color-text-secondary);
}
</style>
