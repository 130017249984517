<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24 12.0237L21.6595 9.44277L22.4008 6.03192L19.0811 4.95147L18.0006 1.6317L14.5796 2.37057L12.0013 0.0300293L9.42035 2.37057L5.99937 1.6317L4.91892 4.95147L1.59916 6.03192L2.34054 9.45037L0 12.0313L2.34054 14.6122L1.59916 18.0306L4.91892 19.1111L5.99937 22.4309L9.42035 21.692L12.0013 24.0326L14.5796 21.692L18.0006 22.4309L19.0811 19.1111L22.4008 18.0306L21.6595 14.6122L24 12.0237ZM15.9992 13.0358H13.0007V16.0368H10.9993V13.0232H8.00084V11.0242H10.9993V8.02327H13.0007V11.0242H15.9992V13.0358Z"
      fill="#FFB900"
    />
  </svg>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
