<script setup lang="ts">
import { RawLocation } from 'vue-router';
import { useRouter } from 'vue-router/composables';
import AmountDisplay from '@/app/components/wallets/AmountDisplay.vue';
import { ClientRiskProfileReport } from 'ah-api-gateways';
import { computed, onBeforeMount, ref } from 'vue';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useTradeState } from 'ah-trades';

const props = defineProps<{
  redirectTo: RawLocation;
}>();

const tradeState = useTradeState();

const onBehalfOf = useOnBehalfOf();

const router = useRouter();

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadClientRiskProfileReport') {
      loadClientRiskProfileReport();
    }
  },
}).manager;

const clientRiskProfileReportData = ref<ClientRiskProfileReport>();

const clientId = computed(
  () => onBehalfOf.value?.id ?? tradeState.store.useAuthStore().loggedInIdentity?.client?.id ?? ''
);

const currency = computed(
  () =>
    clientRiskProfileReportData.value?.limitsProfile?.currency ??
    clientRiskProfileReportData.value?.collateralProfile?.currency ??
    clientRiskProfileReportData.value?.creditProfile?.currency ??
    'GBP'
);

function loadClientRiskProfileReport() {
  if (!clientId?.value && !onBehalfOf?.value) {
    throw 'Cannot load client risk profile report with incomplete data';
  }

  requestManager
    .sameOrNew(
      'loadClientRiskProfileReport',
      tradeState.services.risk.getClientRiskProfile({
        clientId: clientId.value,
      })
    )
    .subscribe((response) => {
      clientRiskProfileReportData.value = response;
    });
}

onBeforeMount(loadClientRiskProfileReport);

function goToLimitsAndCollateral() {
  router.push(props.redirectTo);
}
</script>

<template>
  <LoadingOverlay :state="requestManager.currentStates">
    <div v-if="clientRiskProfileReportData">
      <AmountDisplay
        :currency="currency"
        :value="clientRiskProfileReportData.limitsProfile?.notionalLimitSpotsRemaining"
        label="Remaining limit - Spots"
      />
      <AmountDisplay
        :currency="currency"
        :value="clientRiskProfileReportData.limitsProfile?.notionalLimitForwardsOptionsRemaining"
        label="Remaining limit - Forward and Options"
      />
      <AmountDisplay
        :currency="currency"
        :value="clientRiskProfileReportData.collateralProfile?.availableCollateral"
        label="Free Collateral"
      />
      <DashboardViewAllButton @click="goToLimitsAndCollateral" v-if="redirectTo" />
    </div>
  </LoadingOverlay>
</template>
