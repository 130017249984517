<script setup lang="ts">
import { computed, PropType } from 'vue';
import {
  PaymentReport,
  PaymentReportFilters,
  PaginatedQuery,
  ExportListType,
  PaymentType,
  PaymentState,
  paymentTypeToHuman,
  paymentStateToHuman,
  paymentRailToHuman,
} from 'ah-api-gateways';
import { formatDate, SIMPLE_DATE_FORMAT } from 'ah-common-lib/src/helpers/time';
import { defineUseManagedListingProps, useManagedListing, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import PaymentDetails from 'ah-payments/src/components/PaymentDetails.vue';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { usePaymentState } from 'ah-payments';

const props = defineProps({
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    required: false,
    default: () => ({ tableFields: [] }),
  },
  hidePagination: {
    type: Boolean,
    default: false,
  },
  withRowDetails: {
    type: Boolean,
    default: true,
  },
  ...defineUseManagedListingProps<PaymentReport>(),
});

const requestManager = useRequestManager();

const paymentState = usePaymentState();

interface OnboardingListEmits extends UseManagedListingEmits<PaymentReport> {}

const emit = defineEmits<OnboardingListEmits>();

const managedListing = useManagedListing<PaymentReport>({
  loadDataRequest(query: PaginatedQuery<PaymentReportFilters>) {
    /**
     * loadDataRequest will override sort if it is set. Otherwise it defaults to docCreatedAt DESC
     */
    return paymentState.services.payments.listPayments({ ...query });
  },
  downloadDataRequest(type: string, query: { [key: string]: any }, options?: { [key: string]: any }) {
    if (options && options.withDetails) {
      return paymentState.services.payments.downloadListPaymentsWithDetails(query, type as ExportListType);
    }
    return paymentState.services.payments.downloadListPayments(query, type as ExportListType);
  },
  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager: requestManager.manager,
});

const typeTooltip = (type: PaymentType, tradeId: string = '') => {
  if (type === PaymentType.IMMEDIATE) {
    return 'The payment will be released as soon as there are enough available funds in the wallet ';
  }
  if (type === PaymentType.SCHEDULED) {
    return 'The payment will be released when the scheduled date is reached, as long as there are enough available funds in the wallet ';
  }
  if (type === PaymentType.TRADE_SEND_TO_BENEFICIARY) {
    return `The payment will be released as soon as trade ${tradeId} is settled`;
  }
  return '';
};

const stateTooltip = (state: PaymentState) => {
  switch (state) {
    case PaymentState.REGISTERED:
      return "We're checking your payment to make sure everything is in order.";
    case PaymentState.IN_REVIEW:
      return "We're reviewing your payment as part of our routine checks.";
    case PaymentState.REJECTED:
      return "Sorry, your payment didn't pass our review and can't be sent. Contact support for more information.";
    case PaymentState.APPROVED:
      return 'Your payment passed our review and is in progress.';
    case PaymentState.IN_PROCESS:
      return 'Your payment is in progress.';
    case PaymentState.CONFIRMED:
      return 'Your payment has been sent and the money has left your account.';
    case PaymentState.FAILED:
      return 'There was a problem sending your payment. Contact support for more information.';
    case PaymentState.PENDING:
      return ' Your payment has been set up and is in the payment queue.';
    case PaymentState.QUEUED:
      return 'Your payment is awaiting sufficient funds in to be deposited. Payment process will commence when your balance is high enough.';
    default:
      return '';
  }
};

defineExpose({ loadData: managedListing.loadData, downloadData: managedListing.listeners.download });
</script>

<template>
  <PaginatedTable
    fixed
    hover
    animateCols
    items-label="payments"
    v-on="{ ...managedListing.listeners, ...$listeners }"
    v-bind="{ ...managedListing.bindings, ...$attrs }"
    :class="['payments-table']"
    :stacked="paymentState.mediaQuery.is('smDown')"
    :with-simple-scroll="true"
    :hidePagination="hidePagination"
    :withRowDetails="withRowDetails"
  >
    <template #cell(createdAt)="{ item }">
      {{ formatDate(item.createdAt, SIMPLE_DATE_FORMAT, '') }}
    </template>
    <template #cell(executionDate)="{ item }">
      {{ formatDate(item.executionDate, SIMPLE_DATE_FORMAT, '') }}
    </template>
    <template #cell(beneficiaryPaymentRail)="{ item }">
      {{ paymentRailToHuman(item.beneficiaryPaymentRail) }}
    </template>
    <template #cell(state)="{ item }">
      <span class="align-items-center">
        {{ paymentStateToHuman(item.state) ?? '-' }}
        <InfoTooltip v-if="stateTooltip(item.state)" :text="stateTooltip(item.state)" />
      </span>
    </template>
    <template #cell(type)="{ item }">
      <div class="align-items-center">
        {{ paymentTypeToHuman(item.type) }}
        <InfoTooltip v-if="typeTooltip(item.type)" :text="typeTooltip(item.type, item.tradeComposedReferenceNumber)" />
      </div>
    </template>
    <template #cell(amount)="{ item }">
      {{ formatCurrencyValue(item.amount) ?? '-' }}
    </template>
    <template #tableNoResultsState>
      <slot name="tableNoResultsState" />
    </template>
    <!-- row details -->
    <template #row-details="slotScope">
      <slot name="trade-details" v-bind="slotScope">
        <PaymentDetails
          class="trade-details"
          :payment="slotScope.item"
          @cancel-payment="managedListing.loadData"
          @update-payment="managedListing.loadData"
        />
      </slot>
    </template>
    <template #cell(currency)="{ item }">
      <p class="mb-0">
        {{ item.currency }}
      </p>
    </template>
  </PaginatedTable>
</template>

<style lang="scss" scoped>
::v-deep table {
  @include phablet {
    min-width: 70rem;
  }
  @include upToResolution($tabletResolution) {
    min-width: 100%;
  }
}
::v-deep .table-wrapper table th:first-child,
::v-deep .table-wrapper table td:first-child,
::v-deep .table-wrapper table.b-table th:first-child,
::v-deep .table-wrapper table.b-table td:first-child {
  padding-left: 10px;
  flex: 1.3 1 0;
}

::v-deep .nowrap {
  white-space: nowrap;
}
::v-deep svg.icon {
  min-width: 1em;
  min-height: 1em;
  margin-left: 2px;
  @include themedTextColor($color-primary);
}

::v-deep.payment-listing-tooltip {
  .tooltip-inner {
    max-width: 400px;
    @include themedBackgroundColor($color-primary);
  }
  .arrow::before {
    @include themedPropColor('border-top-color', $color-primary);
  }
}

::v-deep .table-wrapper table,
.table-wrapper table.b-table {
  min-width: 100%;
}
</style>
