<script setup lang="ts">
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import LimitsAndCollateralManagement from 'ah-trades/src/components/limitsAndCollateral/LimitsAndCollateralManagement.vue';
import { useRouter } from 'vue-router/composables';

defineProps({
  /**
   * Client ID to display limits and collateral for, if any (LimitsAndCollateralManagement will default to contextual owner id otherwise)
   */
  clientId: {
    type: String,
  },
});

const onBehalfOfClient = useOnBehalfOf();

const router = useRouter();

/**
 * We are using onBehalfOfClient.value?.id instead of props.clientId because the go back button
 * will only show when we are acting on behalf of, thus is to keep the consistency
 */
function goBack() {
  router.push('/admin/activity/clients/obo/' + onBehalfOfClient.value?.id);
}
</script>

<template>
  <div class="padded-space limits-and-collateral-view">
    <h2>Trading Limits and Collateral</h2>
    <BackButton v-if="onBehalfOfClient" label="go back" @click="goBack()" small />
    <LimitsAndCollateralManagement :client-id="clientId" />
  </div>
</template>

<style lang="scss" scoped>
.limits-and-collateral-view {
  font-family: $font-family-inter;

  h2 {
    margin-bottom: 0;
  }
}
</style>
