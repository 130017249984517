<template>
  <div>
    <div v-if="$mediaQuery.is('smOnly')" class="w-100">
      <SortSelector :fields="fields" :sortAndPageParams.sync="sortAndPageParams" class="w-100" />
    </div>
    <OpenPositionsTradeListing
      :config="config"
      :filter="filters"
      :sortAndPageParams.sync="sortAndPageParams"
      :tableData.sync="tableData"
      hidePagination
      class="pb-3"
    >
      <template #tableNoResultsState>
        <div class="no-results-info">
          <div class="chart-icon">
            <LineChartIcon />
          </div>
          There are no open positions
        </div>
      </template>
    </OpenPositionsTradeListing>
    <DashboardViewAllButton @click="goToTrades" v-if="hasTrades" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Client, Trade, PaginatedParams, PaginatedResponse, HedgingInstruments } from 'ah-api-gateways';
import { openPositionsDashboardTradeTableFields } from 'ah-trades/src/models/tradeTableFields';
import OpenPositionsTradeListing from 'ah-trades/src/components/lists/OpenPositionsTradeListing.vue';
import DashboardViewAllButton from '@/app/components/dashboard/DashboardViewAllButton.vue';
import SortSelector from 'ah-common-lib/src/common/components/SortSelector.vue';

@Component({
  components: {
    OpenPositionsTradeListing,
    DashboardViewAllButton,
    SortSelector,
  },
})
export default class OpenPositionsDashboardWidget extends Vue {
  /**
   * Client to show positions for
   *
   * If set, widget will display the information about the client, otherwise all trades will show
   */
  @Prop({ default: null }) client!: Client | null;

  @Prop({ default: () => ({ tableFields: openPositionsDashboardTradeTableFields }) }) config!: { tableFields: [] };

  private tableData: PaginatedResponse<Trade> | null = null;

  private sortAndPageParams: Partial<PaginatedParams> = { pageSize: 5 };

  get fields() {
    return this.config.tableFields;
  }

  get filters() {
    return {
      clientId: this.client?.id,
      hedgingProduct: [
        HedgingInstruments.FX_SPOT,
        HedgingInstruments.FX_FORWARD,
        HedgingInstruments.WINDOW_FORWARD,
        HedgingInstruments.FLEXIBLE_FORWARD,
      ],
    };
  }

  get hasTrades() {
    return (this.tableData?.total ?? 0) > 0;
  }

  goToDrawdown(trade: Trade) {
    if (this.client) {
      this.$router.push({ path: `${this.client.id}/drawdown`, query: { drawdown: trade.referenceNumber } });
    } else {
      this.$router.push({ path: '/dashboard/trades/open-fx', query: { drawdown: trade.referenceNumber } });
    }
  }

  goToTrades() {
    if (this.client) {
      this.$router.push(`${this.client.id}/trades`);
    } else {
      this.$router.push('/dashboard/trades/open-fx');
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .no-results-info {
  margin: 4rem 0;
  @include themedTextColor($color-text-secondary, $color-dark-text-secondary);

  .chart-icon {
    margin: 1rem 0;
    svg {
      width: 3rem;
      height: auto;
      path {
        @include themedPropColor('fill ', $color-text-secondary, $color-dark-text-secondary);
      }
    }
  }
}
</style>
