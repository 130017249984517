<script setup lang="ts">
import { getChildModel, getState, makeFormModel, setState, toDataModel } from 'ah-common-lib/src/form/helpers';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { currencyExchangeField } from 'ah-common-lib/src/form/models';
import { reactive, computed, ref, onBeforeMount, watch } from 'vue';
import OptionSolutionFormGroup from './OptionSolutionFormGroup.vue';
import { AmountType, CreateVanillaOptionsPriceRequest, CurrencyPair, VanillaPriceResponse } from 'ah-api-gateways';
import { useToast } from 'ah-common-lib/src/toast';
import { ApiError, GenericErrorCodes, PricingEngineErrorCodes } from 'ah-requests';
import { useTradeState } from '../..';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

enum OptionsCreatorSteps {
  OPTIONS_CREATOR = 'optionCreator',
  OPTIONS_REVIEW = 'optionsReview',
  OPTIONS_BOOKED = 'optionsBooked',
}

const props = defineProps<{
  stage: OptionsCreatorSteps;
  solutions: VanillaPriceResponse[];
  clientId?: string;
  validations: any[];
}>();

const state = reactive<{
  currencies: CurrencyPair[];
  sellCurrencies: string[];
  buyCurrencies: string[];
}>({
  currencies: [],
  sellCurrencies: [],
  buyCurrencies: [],
});

const emit = defineEmits<{
  (e: 'update:stage', value: OptionsCreatorSteps): void;
  (e: 'update:selectedSolution', value: VanillaPriceResponse): void;
  (e: 'update:solutions', value: VanillaPriceResponse[]): void;
  (e: 'update:validations', value: boolean[]): void;
}>();

const optionSolutionFormGroup = ref<InstanceType<typeof OptionSolutionFormGroup> | null>(null);

const toast = useToast();

const amountFM = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'amountForm',
    title: '',
    fieldType: 'form',
    fields: [
      currencyExchangeField('currency', '', {
        minimumValue: 0,
        maximumValue: Infinity,
        hideErrorMessages: true,
        currencyTitle: 'Buy or Sell',
        amountTitle: 'Amount',
        displaySymbols: false,
        setDefaults: false,
        keepPristine: true,
      }),
    ],
  }),
  validation: null,
});

const tradeState = useTradeState();

const requestManager = useRequestManager();

const onBehalfOfClient = useOnBehalfOf();

const model = computed(() => {
  const modelAux = toDataModel(amountFM.form);
  delete modelAux.tradeDirection;

  return modelAux;
});

function loadCurrencies() {
  requestManager.manager
    .currentOrNew(
      'loadCurrencies',
      tradeState.services.fxReference.listCurrenciesPairs(
        { optionsEnabled: true, pageSize: 100 },
        onBehalfOfClient.value?.id,
        {
          errors: { silent: true },
        }
      )
    )
    .subscribe((currencies) => {
      state.currencies = currencies.list.reduce((acc, curr: CurrencyPair) => {
        const ccy1 = curr.currencyPair.slice(0, 3);
        const ccy2 = curr.currencyPair.slice(3);
        const reverseCurrency = { ...curr, currencyPair: ccy2.concat(ccy1) };

        if (!state.currencies.includes(curr)) {
          acc.push(curr);
        }
        if (!state.currencies.includes(reverseCurrency)) {
          acc.push(reverseCurrency);
        }

        return acc;
      }, [] as CurrencyPair[]);

      state.sellCurrencies = state.currencies.reduce((acc, curr: CurrencyPair) => {
        if (!acc.includes(curr.currencyPair.slice(0, 3))) {
          acc.push(curr.currencyPair.slice(0, 3));
        }
        return acc;
      }, [] as string[]);

      setState(getChildModel(amountFM.form, 'currency')!, 'sellCurrencies', state.sellCurrencies);

      setState(
        getChildModel(amountFM.form, 'currency')!,
        'selectedSellCurrency',
        state.sellCurrencies.includes('GBP') ? 'GBP' : state.sellCurrencies[0]
      );

      buyOptions();
    });
}

// FIXME - this will be replace with a new endpoint
function loadSpotRate(model: Partial<CreateVanillaOptionsPriceRequest>) {
  setState(amountFM.form, 'sellUnprotectedSpotRate', '<loading>');
  setState(amountFM.form, 'buyUnprotectedSpotRate', '<loading>');
  requestManager.manager
    .sameOrCancelAndNew(
      'loadVanillaOptionsPrice',
      tradeState.services.pricingEngine
        .getVanillaOptionsSpotRate(
          {
            ...model,
            clientId: props.clientId,
          },
          onBehalfOfClient.value?.id,
          {
            errors: { silent: true },
          }
        )
        .pipe(
          mergeMap((price) => {
            if (price.ccy1 && price.ccy2) {
              if (price.ccy1.amountType === AmountType.SELL) {
                return of({
                  sellSpotRate: price.ccy1.rate,
                  buySpotRate: price.ccy2.rate,
                });
              }
              return of({
                sellSpotRate: price.ccy2.rate,
                buySpotRate: price.ccy1.rate,
              });
            }
            return of(null);
          })
        )
    )
    .subscribe(
      (amount) => {
        if (amount && amount.sellSpotRate && amount.buySpotRate) {
          setState(amountFM.form, 'sellUnprotectedSpotRate', amount.sellSpotRate.toString());
          setState(amountFM.form, 'buyUnprotectedSpotRate', amount.buySpotRate.toString());
        } else {
          setState(amountFM.form, 'sellUnprotectedSpotRate', ' - ');
          setState(amountFM.form, 'buyUnprotectedSpotRate', ' - ');
        }
      },
      () => {
        setState(amountFM.form, 'sellUnprotectedSpotRate', ' - ');
        setState(amountFM.form, 'buyUnprotectedSpotRate', ' - ');
      }
    );
}

onBeforeMount(() => {
  loadCurrencies();
});

function calculateSpotRate() {
  if (amountFM.form.currency.buyCurrency && amountFM.form.currency.sellCurrency && amountFM.form.currency.amountType) {
    loadSpotRate(toDataModel(model.value));
  } else {
    const sellCurrency = getState(getChildModel(amountFM.form, 'currency')!, 'selectedSellCurrency');
    const buyCurrency = getState(getChildModel(amountFM.form, 'currency')!, 'selectedBuyCurrency');
    const amountType = AmountType.SELL;
    loadSpotRate({ sellCurrency, buyCurrency, amountType });
  }
}

function buyOptions(newVal?: string) {
  const selectedSellCurrency = getState(getChildModel(amountFM.form, 'currency')!, 'selectedSellCurrency');

  const currenciesPairs = state.currencies.filter((currency) =>
    currency.currencyPair.startsWith(newVal ?? selectedSellCurrency)
  );

  state.buyCurrencies = currenciesPairs.reduce((acc, curr: CurrencyPair) => {
    if (!acc.includes(curr.currencyPair.slice(3))) {
      acc.push(curr.currencyPair.slice(3));
    }
    return acc;
  }, [] as string[]);

  setState(getChildModel(amountFM.form, 'currency')!, 'buyCurrencies', state.buyCurrencies);

  if (amountFM.form.currency.buyCurrency) {
    amountFM.form.currency.buyCurrency = state.buyCurrencies.includes('GBP') ? 'GBP' : state.buyCurrencies[0];
  } else {
    setState(
      getChildModel(amountFM.form, 'currency')!,
      'selectedBuyCurrency',
      state.buyCurrencies.includes('GBP') ? 'GBP' : state.buyCurrencies[0]
    );
  }

  calculateSpotRate();
}

watch(
  () => amountFM.form.currency,
  (newVal, oldVal) => {
    if (!oldVal) {
      const sellCurrency = getState(getChildModel(amountFM.form, 'currency')!, 'selectedSellCurrency');
      if (sellCurrency !== newVal.sellCurrency) {
        buyOptions(newVal.sellCurrency);
      }
      calculateSpotRate();
      return;
    } else if (oldVal.sellCurrency !== newVal.sellCurrency) {
      buyOptions(newVal.sellCurrency);
    } else if (oldVal.amounType !== newVal.amountType) {
      calculateSpotRate();
    }
  }
);

const showAddSolutionButton = computed(() => {
  return (amountFM.validation && !amountFM.validation.$invalid) ?? false;
});

function reloadPrices(request: CreateVanillaOptionsPriceRequest, solutionId: string) {
  optionSolutionFormGroup.value?.reloadPrices(request, solutionId);
}

function addSolution() {
  if (props.solutions.length === 6) {
    toast?.info(`Maximum number of solutions is 6.`);
    return;
  }

  optionSolutionFormGroup.value?.requestNewPrices();
}

function cleanErrorMessages() {
  setState(getChildModel(amountFM.form, 'currency')!, 'errors', []);
}

function onFormEvent() {
  if (!amountFM.validation?.$invalid) {
    cleanErrorMessages();
  }
}

function handleSolutionError(error: ApiError) {
  if (error.code === GenericErrorCodes.VALIDATION_ERROR) {
    if (error.subErrors?.length && error.subErrors[0].code === PricingEngineErrorCodes.AMOUNT_OUT_OF_BOUNDS) {
      setState(getChildModel(amountFM.form, 'currency')!, 'errors', [
        {
          name: 'peError',
          error: error.subErrors[0].message,
        },
      ]);
    }
  }
}

defineExpose({ reloadPrices });
</script>

<template>
  <div class="section">
    <!-- main blocks -->
    <BoxGrid>
      <!-- trade details -->
      <BoxGridBlock cols="12" :loadingOverlayProps="{ showRetry: true }">
        <ValidatedForm :fm="amountFM.form" :validation.sync="amountFM.validation" @form-event="onFormEvent" />
      </BoxGridBlock>
      <BoxGridItem cols="12">
        <div :class="['d-flex', solutions.length > 0 ? 'justify-content-between' : 'justify-content-end']">
          <h2 class="title ml-3 mb-1 solutions-title" v-if="solutions.length > 0">Solutions</h2>
          <VButton @click="addSolution" v-if="showAddSolutionButton"> Add Solution </VButton>
        </div>
      </BoxGridItem>
      <!-- solutions details -->
      <BoxGridItem cols="12" class="mt-5">
        <OptionSolutionFormGroup
          :model="model"
          :solutions="solutions"
          :showAddSolutionButton="showAddSolutionButton"
          :clientId="clientId"
          :validations="props.validations"
          ref="optionSolutionFormGroup"
          @update:stage="emit('update:stage', $event)"
          @update:selectedSolution="emit('update:selectedSolution', $event)"
          @update:solutions="emit('update:solutions', $event)"
          @update:errors="handleSolutionError($event)"
          @update:validations="emit('update:validations', $event)"
        />
      </BoxGridItem>
    </BoxGrid>
  </div>
</template>

<style lang="scss" scoped></style>
