<template>
  <ValidatedForm :fm="periodForm" />
</template>
<script lang="ts">
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { selectField } from 'ah-common-lib/src/form/models';
import { format } from 'date-fns';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class CommissionPeriodSelector extends Vue {
  private periodForm = makeFormModel({
    name: 'dateForm',
    fieldType: 'form',
    fields: [
      selectField(
        'period',
        'Period:',
        [
          {
            label: '1 week',
            value: 'week',
          },
          {
            label: '1 month',
            value: 'month',
          },
          {
            label: '6 months',
            value: 'month6',
          },
          {
            label: '1 year',
            value: 'year',
          },
          {
            label: 'all time',
            value: 'all_time',
          },
        ],
        {
          required: false,
          defaultValue: 'week',
          clearValue: 'week',
        }
      ),
    ],
  });

  /**
   * function to calculate the date as per the given string time period.
   * given time period is subtracted from current date to make the start date.
   */
  private calculateStartDate(period: String): string {
    let date = new Date();
    switch (period) {
      case 'week':
        date = new Date(date.setDate(date.getDate() - 7));
        break;
      case 'month':
        date = new Date(date.setDate(date.getDate() - 30));
        break;
      case 'month6':
        date = new Date(date.setDate(date.getDate() - 180));
        break;
      case 'year':
        date = new Date(date.setDate(date.getDate() - 365));
        break;
      case 'all_time':
        date = new Date(date.getDate());
        break;
      default:
        date = new Date(date.setDate(date.getDate() - 7));
        break;
    }
    return format(date, 'yyyy-MM-dd');
  }

  @Watch('periodForm.period', { immediate: true })
  /**
   * date is emited as per the time period is selected
   */
  private emitPeriodDate() {
    this.$emit('update:filter', { startDate: this.calculateStartDate(this.periodForm.period) });
  }
}
</script>
