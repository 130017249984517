<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount, watch, PropType } from 'vue';
import CalendarIcon from 'ah-common-lib/src/icons/components/CalendarIcon.vue';
import { isDescendant } from 'ah-common-lib/src/helpers/dom';
import MonthSelector from './MonthSelector.vue';
import { createPopper, Instance as PopperInstance } from '@popperjs/core';
import { format } from 'date-fns';

/**
 * Month selector input component
 *
 * Fully independent input component, managing a popup with month selection capabilities
 */

const props = defineProps({
  value: {
    type: Date,
  },
  placeholder: {
    type: String,
    default: 'Select a date...',
  },
  inputGroupClass: {
    type: [String, Array] as PropType<string | string[]>,
  },
  inputClass: {
    type: [String, Array] as PropType<string | string[]>,
  },
});

const emit = defineEmits({
  'update:value': (_value: Date) => true,
  'dismissed-popup': () => true,
});

const textInput = ref<HTMLInputElement>();
const inputGroup = ref<HTMLDivElement>();
const selectorHolder = ref<HTMLDivElement>();

let popper: PopperInstance | undefined;

const isSelectorShown = ref(false);

function escListener(event: KeyboardEvent) {
  if (isSelectorShown.value && selectorHolder.value && event.key === 'Escape') {
    onCancel();
  }
}

function clickListener(event: any) {
  if (
    isSelectorShown.value &&
    inputGroup.value &&
    selectorHolder.value &&
    !isDescendant([selectorHolder.value, inputGroup.value], event.target) &&
    document.body.contains(event.target)
  ) {
    onCancel();
  }
}

onMounted(() => {
  if (selectorHolder.value && inputGroup.value) {
    popper = createPopper(inputGroup.value, selectorHolder.value, {
      placement: 'bottom',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ],
    });
  }

  window.addEventListener('keyup', escListener);
  window.addEventListener('click', clickListener);
});

onBeforeUnmount(() => {
  popper?.destroy();
  window.removeEventListener('keyup', escListener);
  window.removeEventListener('click', clickListener);
});

function showSelector() {
  isSelectorShown.value = true;
  textInput.value?.blur();
  setTimeout(() => {
    popper?.forceUpdate();
  });
}

function onValueUpdate(value: Date) {
  emit('update:value', value);
  isSelectorShown.value = false;
}

function onCancel() {
  isSelectorShown.value = false;
  emit('dismissed-popup');
}

watch(
  [() => props.value, textInput],
  () => {
    if (textInput.value) {
      textInput.value.value = props.value ? format(props.value, 'MMM yyyy') : '';
    }
  },
  {
    immediate: true,
  }
);
</script>
<template>
  <div class="month-selector-input-component">
    <div :class="['input-group', { 'focus-style': isSelectorShown }, inputGroupClass]" ref="inputGroup">
      <div class="input-group-prepend" @click="isSelectorShown = true">
        <div class="input-group-text">
          <slot name="input-icon">
            <CalendarIcon />
          </slot>
        </div>
      </div>
      <input
        ref="textInput"
        :placeholder="placeholder"
        @focus="showSelector"
        :class="['field-group-field-input form-control', { 'focus-style': isSelectorShown }, inputClass]"
      />
    </div>
    <div class="selector-holder" ref="selectorHolder">
      <MonthSelector :value="value" @cancel="onCancel" @update:value="onValueUpdate" v-if="isSelectorShown" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.selector-holder {
  z-index: 1;
}
</style>
