<script setup lang="ts">
import OnboardingListBlock from '@/app/components/onboarding/report/OnboardingListBlock.vue';
import OnboardingStatuses from '@/app/components/onboarding/report/OnboardingStatuses.vue';
import OnboardingStepsGuideline from '@/app/components/onboarding/report/OnboardingStepsGuideline.vue';
import { useAuthStore } from '@/app/store/authStore';
import { AuthorityType } from 'ah-api-gateways';
import { computed } from 'vue';

const authStore = useAuthStore();

const canExport = computed(() => authStore.hasAuthorities(AuthorityType.EXPORT_CLIENT_REPORTS));
</script>

<template>
  <div class="padded-space" id="activity-onboarding-view">
    <h2>Onboarding</h2>
    <VRow alignH="start">
      <VCol sm="12" md="8" lg="9">
        <OnboardingStatuses class="mb-3 mr-3" />
        <OnboardingListBlock :showExport="canExport" documentsTitle="Onboarding List" />
      </VCol>
      <VCol>
        <OnboardingStepsGuideline />
      </VCol>
    </VRow>
  </div>
</template>
