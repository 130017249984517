import { Validation } from '@vuelidate/core';
import { FieldType } from './fieldType';
import type { FormValidation, ValidationRuleSet } from './validation';
import type { Component } from 'vue';
import { Placement } from '@popperjs/core';

const formType = ['form'] as const;

type FormType = (typeof formType)[number];

type FreeObject = { [key: string]: any };

export type FormDefinition = { form: FormModel; validation: FormValidation | null };

export type FieldOptionObj<T = any> = { label: string; value: T; disabled?: boolean };

export type FieldOption = string | FieldOptionObj;

export const formEventTypes = [
  'form-section-select',
  'form-action',
  'form-field-select-search',
  'form-field-focus',
  'form-field-blur',
  'form-field-submit',
  'form-field-set-value',
  'form-icon-clicked',
] as const;

export type FormEventType = (typeof formEventTypes)[number];

export interface FormActionModel extends FormAction {
  class: any[];
  enabled: boolean;
  loading: boolean;
  allowOnInvalid: boolean;
}

export interface BaseFormModel extends FreeObject {
  $type: string;
  $state: FreeObject;
  $path: string;
  $validators: ValidationRuleSet;
  $name: string;
  $parent?: () => BaseFormModel | undefined;
}

export interface FieldModel extends BaseFormModel {
  $type: FieldType;
  $value: any;
}

export function isFieldModel(model: BaseFormModel): model is FieldModel {
  return model.$type !== 'form';
}

export interface RepeatModel extends BaseFormModel {
  $type: 'form';
  $childForm: Form;
  $constructor: () => FormModel;
  $value: FormModel[];
  $actions: FormActionModel[];
}

export function isRepeatModel(model: BaseFormModel): model is RepeatModel {
  return model.$type === 'form' && !!model.$childForm;
}

export interface FormModel extends BaseFormModel {
  $type: 'form';
  $fields: BaseFormModel[];
  $actions: FormActionModel[];
}

export function isFormModel(model: BaseFormModel): model is FormModel {
  return model.$type === 'form' && !model.$childForm;
}

export interface FormEvent<T = BaseFormModel> {
  event: FormEventType;
  model: FormModel | RepeatModel | FieldModel;
  field: Validation<any, T>;
}

export interface FormSelectFieldSearchEvent<T = BaseFormModel> extends FormEvent<T> {
  event: 'form-field-select-search';
  search: string;
  loading: (val: boolean) => void;
}

export interface FormIconClickEvent<T = BaseFormModel> extends FormEvent<T> {
  event: 'form-icon-clicked';
  name: string;
}

export interface FormActionEvent<T = BaseFormModel> extends FormEvent<T> {
  event: 'form-action';
  action: FormActionModel;
}

export type TreeOptionsBranch = {
  title?: string;
  text?: string;
  callText?: string;
  options: TreeOptionsBranchOption[];
};

export type TreeOptionsBranchOption = {
  label: string;
  value: string;
  meta?: FreeObject;
  next?: TreeOptionsBranch;
};

export type FieldError = { name: string; error: string | null; html?: boolean };

export type FieldErrorMessage<T = any> = string | ((field: Validation<any, T>, model: FieldModel) => string);

export interface FormAction {
  name: string;
  label: string;
  loadingLabel?: string;
  class?: any[];
  enabled?: boolean;
  loading?: boolean;
  allowOnInvalid?: boolean;
}

export interface FormOptions extends FreeObject {
  title?: string;
  /**
   * Whether render title as html
   */
  htmlTitle?: boolean;
  display?: string;
  showRequiredMarkers?: boolean;
  requiredText?: string;
  requiredTextNote?: string;
  dirtyOnInput?: boolean;
  open?: boolean;
  toDataModelFn?: (value: BaseFormModel, dataModel: any) => void;
  fromDataModelFn?: (dataModel: any, formModel: BaseFormModel) => void;
  multipleTitleField?: string | ((model: any) => string);
  multipleTitlePlaceholder?: string;
  showErrorsBeforeSubmit?: boolean;
  submitted?: boolean;
}

export interface Form extends FreeObject {
  name: string;
  fieldType: FormType;
  fields: (Field | Form)[];
  multiple?: boolean;
  length?: number;
  childrenState?: FormOptions;
  state?: FormOptions;
  actions?: FormAction[];
  childrenActions?: FormAction[];
  validators?: ValidationRuleSet;
}

export interface BaseFieldOptions {
  name?: string;
  title?: string;
  required?: boolean | ((model: FieldModel) => boolean);
  hidden?: boolean | ((model: FieldModel) => boolean);
  titleTooltip?: string;
  errorMessages?: { [name: string]: FieldErrorMessage };
  error?: FieldError[];
  readonly?: boolean;
  hideErrors?: boolean;
  firstErrorMessageOnly?: boolean;
  showRequiredMarkers?: boolean;
  requiredText?: string;
  requiredTextNote?: string;
  tooltipComponent?: Component;
}

export interface FileFieldOptions {
  maxFiles?: number;
  maxSize?: number;
  acceptedFileTypes?: string[];
  fileIcon?: 'file' | 'image';
  download?: boolean;
  defaultFileName?: string;
}

export interface AppendToBodyOptions {
  offset?: [number, number];
  placement?: Placement;
}

export interface SelectFieldOptions {
  selectOpenIndicatorComponent?: Component;
  appendToBody?: boolean | AppendToBodyOptions;
}

export interface FieldOptions extends FreeObject, SelectFieldOptions, FileFieldOptions, BaseFieldOptions {
  toDataModelFn?: (value: BaseFormModel, dataModel: any, formModel: BaseFormModel) => void;
  fromDataModelFn?: (dataModel: any, formModel: BaseFormModel) => void;
  inputToValueFn?: (val: any) => any;
  valueToInputFn?: (val: any) => any;
  /**
   * Handles pasting of strings into fields. Must return a transformed string, or false to skip setting value.
   */
  pasteHandler?: (val: string) => string | false;
  valuePropName?: string;
  number?: boolean;
  allowShowPassword?: boolean;
  maxLength?: number;
  showMaxLengthCounter?: boolean;
  showLengthCounter?: boolean;
  defaultValue?: any;
  includeInvalidTags?: boolean;
  tagValidation?: {
    classes: string;
    rule: RegExp | ((v: { text: string }) => boolean);
    disableAdd?: boolean;
  }[];
  dateDisallowedChecker?: (date: Date) => boolean;
  /**
   * In fields where applicable, which input type to use
   * Certain fields may limit choices to allow only viable ones
   */
  inputType?: string;
}

export interface Field extends FreeObject {
  name: string;
  title?: string;
  fieldType: FieldType;
  optional?: boolean;
  state?: FieldOptions;
  options?: FieldOption[] | TreeOptionsBranch;
  validators?: ValidationRuleSet;
}

export function isFormType(type: any): boolean {
  return formType.includes(type);
}

export function isForm(field: Form | Field): field is Form {
  return isFormType(field.fieldType);
}

export function isField(field: Form | Field): field is Field {
  return !isFormType(field.fieldType);
}
