<script lang="ts">
import { Component } from 'vue-property-decorator';
import { getState } from '../../helpers/formHelpers';
import BaseFormField from './BaseFormField.vue';
import InputGroupWrapper from '../common/InputGroupWrapper.vue';
import { FormIconClickEvent } from '../../interfaces';

@Component({
  components: { InputGroupWrapper },
})
export default class TextFormField<T = string> extends BaseFormField<T> {
  onIconClick(name: string) {
    this.emitFormEvent({
      model: this.model,
      field: this.field,
      event: 'form-icon-clicked',
      name,
    } as FormIconClickEvent);
  }

  setValue(value: string | null) {
    this.$emit('set-value', this.inputToValueFn(value), !this.field.$dirty && !this.dirtyOnInput);
    this.$forceUpdate();
  }

  onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.emitFormEvent({
        field: this.field,
        model: this.model,
        event: 'form-field-submit',
      });
    }
  }

  onBlur() {
    this.field.$touch();
    if (this.trimValue && typeof this.field.$model === 'string') {
      const trimmedValue = (this.field.$model as string).trim();
      if ((this.field.$model as string).replace(/\s/g, '') === '') {
        this.setValue(this.emptyValue);
      } else if (trimmedValue !== this.field.$model) {
        this.setValue((this.field.$model as string).trim());
      }
    }
    this.emitFormEvent({
      field: this.field,
      model: this.model,
      event: 'form-field-blur',
    });
  }

  onPaste(event: ClipboardEvent) {
    if (this.pasteHandler) {
      event.preventDefault();
      const data = event.clipboardData?.getData('text');
      const target = event.target as HTMLInputElement;
      if (data) {
        const transformed = this.pasteHandler(data);
        if (transformed) {
          // After cleaning up the pasted content, we need to ensure that only the
          // selected content is replaced and not the full string
          this.setValue(
            target.value.substring(0, target.selectionStart!) +
              transformed +
              target.value.substring(target.selectionEnd!)
          );
        }
      }
    }
  }

  get stringVal() {
    const val = this.valueToInputFn(this.field.$model);

    if (this.number && typeof val === 'number') {
      return Number.isNaN(val) ? this.emptyValue : val.toString();
    }

    return val;
  }

  get dirtyOnInput() {
    return getState(this.model, 'dirtyOnInput');
  }

  get pasteHandler() {
    return getState(this.model, 'pasteHandler');
  }

  get number() {
    return getState(this.model, 'number');
  }

  get placeholder() {
    return getState(this.model, 'placeholder');
  }

  get inputMode() {
    return getState(this.model, 'inputMode', 'text');
  }

  get fieldType() {
    return getState(this.model, 'fieldType');
  }

  get valueToInputFn() {
    return getState(this.model, 'valueToInputFn', (v: any) => v);
  }

  get inputToValueFn() {
    return getState(this.model, 'inputToValueFn', (v: any) => v);
  }

  get trimValue() {
    return getState(this.model, 'trim', true);
  }

  get emptyValue() {
    return getState(this.model, 'emptyValue', '');
  }

  get autocomplete(): string {
    return this.model.$state.autocomplete || 'on';
  }
}
</script>
<template src="./TextFormField.template.vue" />
