import { DrawdownDate, TimeFrameDate } from 'ah-trades/src/models/date';
import { TradeDetails } from '../models/trade';
import { TimeFrames } from 'ah-common-lib/src/constants/timeframes';
import {
  HedgingInstruments,
  AmountType,
  QuotePriceResponse,
  PricingEngineService,
  AmendQuotePriceRequest,
} from 'ah-api-gateways';
import { HttpRequestOptions } from 'ah-requests';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface QuotePriceRequest {
  instrument: HedgingInstruments;
  type: AmountType;
  tradeDetails: TradeDetails;
  response?: QuotePriceResponse;
  loading?: boolean;
  /**
   * Offset in response timing
   *
   * This value compares the datetime at the moment of request and the returned value for `priceRequestedTimestamp`
   * And thus accounts for any issues with clocks being seconds or minutes off
   * If `priceRequestedTimestamp` is AFTER the system clock reported time, this value will be NEGATIVE
   */
  responseTimeOffset?: number;
}

function getHedgingProductData(
  timeFrame?: TimeFrameDate,
  drawdownDate?: DrawdownDate
): { instrument: HedgingInstruments; startDate?: string } {
  if (timeFrame?.isForward) {
    if (!drawdownDate || drawdownDate.type === 'simple') {
      return { instrument: HedgingInstruments.FX_FORWARD };
    } else {
      return {
        instrument:
          drawdownDate.type === 'windowed' ? HedgingInstruments.WINDOW_FORWARD : HedgingInstruments.FLEXIBLE_FORWARD,
        startDate: drawdownDate.type === 'windowed' ? drawdownDate.value! : undefined,
      };
    }
  }
  return { instrument: HedgingInstruments.FX_SPOT };
}

export function pricingRequest(
  tradeDetails: TradeDetails,
  clientId: string,
  pricingEngine: PricingEngineService,
  options?: Partial<HttpRequestOptions<QuotePriceResponse>>
): Observable<QuotePriceRequest> {
  const spotRequestConfig = {
    amountType: tradeDetails.amountType!,
    amount: tradeDetails.amount!,
    tradeDirection: `${tradeDetails.sellCurrency}${tradeDetails.buyCurrency}`,
    clientId: clientId,
    sellCurrency: tradeDetails.sellCurrency,
    buyCurrency: tradeDetails.buyCurrency,
    clientRateMarkup: tradeDetails.clientRateMarkup,
    clientRate: tradeDetails.clientRate,
    targetDate: tradeDetails.timeFrame?.targetDate || '',
    targetTimeFrame: TimeFrames.OTHER,
  };
  let request!: Observable<QuotePriceResponse>;
  const requestDate = new Date();
  const hedgingProductData = getHedgingProductData(tradeDetails.timeFrame, tradeDetails.drawdownDate);

  if (hedgingProductData.instrument === 'FX_FORWARD') {
    request = pricingEngine.createForwardQuote(
      {
        ...spotRequestConfig,
        targetTimeFrame: tradeDetails.timeFrame!.targetTimeFrame,
      },
      options
    );
  } else if (hedgingProductData.instrument === 'WINDOW_FORWARD') {
    request = pricingEngine.createWindowForwardQuote(
      {
        ...spotRequestConfig,
        windowStartDate: hedgingProductData.startDate!,
        targetDate: tradeDetails.timeFrame!.targetDate,
        targetTimeFrame: tradeDetails.timeFrame!.targetTimeFrame,
      },
      options
    );
  } else if (hedgingProductData.instrument === 'FLEXIBLE_FORWARD') {
    request = pricingEngine.createFlexibleForwardQuote(
      {
        ...spotRequestConfig,
        targetDate: tradeDetails.timeFrame!.targetDate,
        targetTimeFrame: tradeDetails.timeFrame!.targetTimeFrame,
      },
      options
    );
  } else {
    request = pricingEngine.createSpotQuote(spotRequestConfig, options);
  }

  return request.pipe(
    map(
      (price) =>
        ({
          response: price,
          instrument: hedgingProductData.instrument,
          type: tradeDetails.amountType!,
          tradeDetails,
          responseTimeOffset: requestDate.getTime() - new Date(price.priceRequestedTimestamp).getTime(),
        } as QuotePriceRequest)
    )
  );
}

export function pricingAmendRequest(
  params: AmendQuotePriceRequest,
  tradeDetails: TradeDetails,
  pricingEngine: PricingEngineService,
  options?: Partial<HttpRequestOptions<QuotePriceResponse>>
): Observable<QuotePriceRequest> {
  const requestDate = new Date();
  return pricingEngine.updateQuote(params, options).pipe(
    map(
      (price) =>
        ({
          response: price,
          instrument: params.hedgingProduct,
          type: tradeDetails.amountType!,
          tradeDetails,
          responseTimeOffset: requestDate.getTime() - new Date(price.priceRequestedTimestamp).getTime(),
        } as QuotePriceRequest)
    )
  );
}
