<script lang="ts">
import type { PropOptions } from 'vue';

export const statusPillProps = {
  label: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    default: 'grey',
  },
} satisfies Record<string, PropOptions>;
</script>
<script lang="ts" setup>
defineProps(statusPillProps);
</script>
<template>
  <span :class="['status-pill', color]"> {{ label }} <slot></slot> </span>
</template>
<style lang="scss" scoped>
.status-pill {
  background: rgba(255, 0, 0, 0.3);
  padding: 0.3em 0.5em;
  border-radius: 2em;

  &.grey {
    @include themedTextColor;
    @include themedProp('background', rgba(0, 0, 0, 0.15), rgba(255, 255, 255, 0.15));
  }

  &.orange {
    @include themedProp(
      'color',
      getColorWithOffsetLightness($color-orange-highlight, -15%),
      getColorWithOffsetLightness($color-orange-highlight, +15%)
    );
    @include themedProp(
      'background',
      getColorWithAlpha($color-orange-highlight, 0.15),
      getColorWithAlpha($color-orange-highlight, 0.15)
    );
  }

  &.primary {
    @include themedProp(
      'color',
      getColorWithOffsetLightness($color-primary, -15%),
      getColorWithOffsetLightness($color-primary, +15%)
    );
    @include themedProp(
      'background',
      getColorWithAlpha($color-primary, 0.15),
      getColorWithAlpha($color-dark-primary, 0.15)
    );
  }

  &.blue {
    @include themedProp(
      'color',
      getColorWithOffsetLightness($color-blue-highlight, -15%),
      getColorWithOffsetLightness($color-blue-highlight, +15%)
    );
    @include themedProp(
      'background',
      getColorWithAlpha($color-blue-highlight, 0.15),
      getColorWithAlpha($color-blue-highlight, 0.15)
    );
  }

  &.success {
    @include themedProp(
      'color',
      getColorWithOffsetLightness($color-success, -15%),
      getColorWithOffsetLightness($color-success, +15%)
    );
    @include themedProp(
      'background',
      getColorWithAlpha($color-success, 0.15),
      getColorWithAlpha($color-dark-success, 0.15)
    );
  }

  &.danger {
    @include themedProp(
      'color',
      getColorWithOffsetLightness($color-danger, -15%),
      getColorWithOffsetLightness($color-danger, +15%)
    );
    @include themedProp(
      'background',
      getColorWithAlpha($color-danger, 0.15),
      getColorWithAlpha($color-dark-danger, 0.15)
    );
  }

  &.warning {
    @include themedProp(
      'color',
      getColorWithOffsetLightness($color-yellow-highlight, -25%),
      getColor($color-yellow-highlight)
    );
    @include themedProp(
      'background',
      getColorWithAlpha($color-yellow-highlight, 0.15),
      getColorWithAlpha($color-dark-yellow-highlight, 0.15)
    );
  }
}
</style>
