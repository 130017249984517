<template>
  <PaginatedTable
    fixed
    hover
    animateCols
    nowrap
    :data-load-state="dataLoadState"
    :data="data"
    :fields="config.tableFields"
    v-on="$listeners"
    v-bind="$attrs"
    @sort="loadData"
  >
    <template #cell(totalPartnerProfit)="{ item }">
      {{ formatCurrencyValue(item.totalPartnerProfit) }}
    </template>
    <template #headInner(totalPartnerProfit)>
      Commission <span class="text-small">({{ currency }} Equivalent)</span>
    </template>
  </PaginatedTable>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import Listing from 'ah-common-lib/src/common/components/listing/Listing.vue';
import { clientCommissionTableFields } from './tableFields/commissionTableFields';
import { ClientCommission, PaginatedQuery } from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';

@Component
export default class ClientCommissionListing extends Listing<ClientCommission> {
  @Prop({ default: () => ({ tableFields: clientCommissionTableFields }) }) config!: { tableFields: any[] };

  formatCurrencyValue = formatCurrencyValue;

  /**
   * service call to get, the list of clients commissions, passing whole query:PaginatedQuery as argument
   */
  protected loadDataRequest(query: PaginatedQuery) {
    return this.$services.trade.listClientCommissions(query);
  }

  get currency() {
    return this.data.list[0]?.currency || 'GBP';
  }
}
</script>
<style lang="scss" scoped></style>
