<script lang="ts">
import { Component, Vue, Inject } from 'vue-property-decorator';
import { Client } from 'ah-api-gateways';
import { ON_BEHALF_OF_CLIENT_INJECT_KEY } from './constants';

@Component
export default class InjectObo extends Vue {
  /**
   * Whether is a on behalf of client trade.
   */
  @Inject({ from: ON_BEHALF_OF_CLIENT_INJECT_KEY, default: null })
  protected onBehalfOfClient!: Client | null;
}
</script>
