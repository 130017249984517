<template>
  <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.6989 24.4972C30.0007 30.878 23.3777 34.6754 16.5079 33.7804C9.6382 32.8855 4.2091 27.518 3.23576 20.6589C2.26242 13.7998 5.98391 7.13388 12.3335 4.36304"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M33.9167 18.4999C33.9167 14.4112 32.2924 10.4899 29.4012 7.59869C26.51 4.7075 22.5888 3.08325 18.5 3.08325V18.4999H33.9167Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
