<template>
  <div class="card-block">
    <h3>Ultimate Beneficial Owner Information</h3>
    <div>
      <ValidatedForm :fm="uboChoiceForm" :validation.sync="uboChoiceValidation" @form-event="onChoiceChange">
        <template #uboChoiceForm.choice:label>
          <label class="field-group-field-label mb-0">
            <span>Are there any UBOs? *</span>
            <p class="text-small text-muted">
              An Ultimate Beneficial Owner (UBO) is somebody who owns 25% or more of the organisation.
            </p>
          </label>
        </template>
        <template #uboChoiceForm.choice:after>
          <p class="text-small text-muted">
            If there are no identified UBO's for the business, please provide us with a Proof of Authorised Signatory
            Document, which will verify any Authorised users for the account.
          </p>
        </template>
      </ValidatedForm>
      <p class="text-secondary"></p>
      <ExpandTransition appear>
        <div v-if="shouldDisplayUbos">
          <p class="text-small text-muted">
            We need an authorised signatory of the company to sponsor the application. this link will be sent to the
            signatory upon completion.
          </p>
          <div v-for="(ubo, index) in uboUsers" :key="`${generateTempId}-${index}`">
            <hr class="mb-5 mt-4" v-if="index !== 0" />
            <div class="user-holder my-3">
              <div class="d-flex justify-content-between mb-4">
                <h3 class="current-account-heading my-0">UBO</h3>
                <RemoveButton :removeButtonText="'Remove UBO'" @remove="remove(index)" />
              </div>
              <ValidatedForm
                :fm="uboUserForms[index]"
                :validation.sync="uboUserValidations[index]"
                @form-event="onFormEvent"
              >
                <template #uboUserForm.email:before>
                  <h3 class="current-account-heading">UBO's Current Address</h3>
                  <RegistrationAddressForm
                    :address="ubo.currentAddress"
                    @update:address="onUpdateUbo(ubo, { currentAddress: $event })"
                    :validation.sync="uboCurrentAddressValidations[index]"
                    :isCountryEditable="true"
                  />
                  <PreviousAddressesForms
                    v-if="isUboAUKResident(ubo)"
                    :previousAddresses="ubo.previousAddresses"
                    @update:previousAddresses="onUpdateUbo(ubo, { previousAddresses: $event })"
                    :validation.sync="uboPreviousAddressesValidations[index]"
                    :currentAddressResidenceDate="ubo.currentAddress.residingFrom"
                    @update:currentAddressResidenceDate="
                      onUpdateUbo(ubo, { currentAddress: { ...ubo.currentAddress, residingFrom: $event } })
                    "
                    :showErrorMessage="showUboAddressHistoryErrors"
                    :isUboLabel="true"
                  />
                </template>
                <template #uboUserForm.phoneNumber:after>
                  <span class="text-muted">
                    <ul class="text-small pl-4 my-1">
                      <li>Must include country code</li>
                    </ul>
                  </span>
                </template>
              </ValidatedForm>
              <FileUploader
                title="Proof of Address"
                :maxSize="10485760"
                description="Clear, colour copy of a utility bill (must be dated within the last 3 months)."
                :accept="defaultAcceptanceFileTypes"
                acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
                :selected="getFile(ubo, ClientFileCategories.PROOF_OF_ADDRESS)"
                @update:selected="onFileChange($event, ubo, ClientFileCategories.PROOF_OF_ADDRESS)"
                uploadRequest="Don't allow"
                :auto-upload="false"
                hide-warning
              />
              <FileUploader
                title="Proof of ID"
                :maxSize="10485760"
                :accept="defaultAcceptanceFileTypes"
                acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
                description="Clear, colour copy of driving license or passport (in date, with all information legible)"
                :selected="getFile(ubo, ClientFileCategories.PHOTO_ID)"
                @update:selected="onFileChange($event, ubo, ClientFileCategories.PHOTO_ID)"
                uploadRequest="Don't allow"
                :auto-upload="false"
                hide-warning
              />
            </div>
          </div>
          <div class="text-center">
            <VButton :disabled="maxAllowedUsersReached" class="btn-stroked" @click="addUser"> Add another UBO </VButton>
          </div>
        </div>
        <div v-else-if="!shouldDisplayUbos">
          <FileUploader
            :title="resolutionLetterTitle"
            :description="resolutionLetterDescription"
            tooltip
            :maxSize="10485760"
            :accept="defaultAcceptanceFileTypes"
            acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
            :uploadRequest="uploadResolutionLetter"
            :downloadRequest="downloadFile"
            :deleteRequest="deleteFile"
            :uploaded="resolutionLetter"
            :loading="requestManager.requestStates.loadDocuments === 'pending'"
            hide-warning
          >
            <template #description-after>
              If you cannot provide this at this stage, please login to your platform to upload this at a later stage.
              It will be required before account approval.
            </template>
          </FileUploader>
        </div>
      </ExpandTransition>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Watch, Mixins, Prop } from 'vue-property-decorator';
import { makeFormModel, toDataModel, updateModel } from 'ah-common-lib/src/form/helpers';
import { radioField, textField, emailField, phoneField } from 'ah-common-lib/src/form/models';
import TermsAndConditionsLink from '@/app/components/common/TermsAndConditionsLink.vue';
import {
  checkAddressHistoryValidity,
  ClientFileCategories,
  ClientFileCategoriesToHuman,
  ClientFileCategoryDescriptions,
  getAddressHistoryAge,
  UnsubmittedUbo,
  UploadedFile,
  VersionedObject,
} from 'ah-api-gateways';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { generateUUID } from 'ah-common-lib/src/helpers/uuid';
import { FormModel, FormEvent, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { cloneDeep, isEqual } from 'lodash';
import { requiredIfStateValue, optional, checkParam, validName } from 'ah-common-lib/src/form/validators';
import FileUploader from 'ah-common-lib/src/common/components/upload/FileUploader.vue';
import { personTitleField } from 'ah-common-lib/src/form/formModels';
import { defaultAcceptanceFileTypes } from 'ah-common-lib/src/helpers/file';
import { map, tap } from 'rxjs/operators';
import { useAuthStore } from '@/app/store/authStore';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { waitForEntityChange } from 'ah-requests';
import { registrationDateField } from '@/app/helpers/registration/forms';
import { useFEFeatureFlag } from 'ah-common-stores';
import RegistrationAddressForm from '../common/RegistrationAddressForm.vue';
import PreviousAddressesForms from '../common/PreviousAddressesForms.vue';
import RemoveButton from '../common/RemoveButton.vue';

const uboUserFM = (useDOB = false) =>
  makeFormModel({
    name: 'uboUserForm',
    fieldType: 'form',
    fields: [
      personTitleField({ fieldWrapperClass: 'col col-2', showRequiredMarkers: true }),
      textField(
        'firstName',
        'Name',
        { fieldWrapperClass: 'col col-5', showRequiredMarkers: true },
        { required: requiredIfStateValue('firstName'), validName }
      ),
      textField(
        'lastName',
        'Surname',
        { fieldWrapperClass: 'col col-5', showRequiredMarkers: true },
        { required: requiredIfStateValue('firstName'), validName }
      ),
      emailField('email', 'Signatory email address', { showRequiredMarkers: true }),
      phoneField(
        'phoneNumber',
        'Mobile Number',
        { fieldWrapperClass: 'col col-6' },
        {
          phone: optional(checkParam('phoneNumber', 'valid')),
        }
      ),
      ...(useDOB ? [registrationDateField({ fieldWrapperClass: 'col col-6', showRequiredMarkers: true })] : []),
    ],
  });

const uboChoiceFM = () =>
  makeFormModel({
    name: 'uboChoiceForm',
    fieldType: 'form',
    fields: [
      radioField(
        'choice',
        'Are there any UBOs?',
        [
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ],
        {
          defaultValue: 'no',
          inline: true,
          fieldWrapperClass: 'mb-0 col col-12',
          showRequiredMarkers: true,
        }
      ),
    ],
  });

const MAX_ALLOWED_UBOS = 4;

/**
 * Ultimate beneficiary owner(s) form
 *
 * The current component will display a form to add (if any) `maxUBO` number
 * of UBO users.
 *
 * Emits:
 * - update:ubos (payload: UnsubmittedUbo[])
 * - update:files (payload: UploadedFile[])
 */
@Component({
  components: { TermsAndConditionsLink, FileUploader, RegistrationAddressForm, PreviousAddressesForms, RemoveButton },
  setup() {
    const dobInOnboardingFeatureActive = useFEFeatureFlag('dobInOnboardingFeatureActive');

    return {
      dobInOnboardingFeatureActive,
    };
  },
})
export default class UBOForm extends Mixins(WithRequestManager) {
  @Prop({ default: () => [] }) ubos!: UnsubmittedUbo[];

  /**
   * Files presented to client
   */
  @Prop({ default: () => [] }) files!: UploadedFile[];

  @Prop({ default: false }) showUboAddressHistoryErrors!: boolean;

  private dobInOnboardingFeatureActive!: boolean;

  private ClientFileCategories = ClientFileCategories;

  private uboUsers: UnsubmittedUbo[] = [];

  private uboChoiceForm = uboChoiceFM();

  private uboChoiceValidation: FormValidation | null = null;

  private uboUserForms: FormModel[] = [];

  private uboUserValidations: (FormValidation | null)[] = [];

  private uboCurrentAddressValidations: (FormValidation | null)[] = [];

  private uboPreviousAddressesValidations: (FormValidation | null)[] = [];

  private defaultAcceptanceFileTypes = defaultAcceptanceFileTypes;

  private innerFiles: UploadedFile[] = [];

  private resolutionLetterTitle = ClientFileCategoriesToHuman[ClientFileCategories.RESOLUTION_LETTER];

  private resolutionLetterDescription = ClientFileCategoryDescriptions[ClientFileCategories.RESOLUTION_LETTER];

  private generateTempId() {
    return generateUUID();
  }

  get authStore() {
    return useAuthStore();
  }

  get individualSettingsStore() {
    return useIndividualSettingsStore();
  }

  isUboAUKResident(ubo: UnsubmittedUbo) {
    return ubo.currentAddress && ubo.currentAddress.countryCode === 'GB';
  }

  uboAddressValidity(ubo: UnsubmittedUbo) {
    return checkAddressHistoryValidity(ubo);
  }

  get resolutionLetter() {
    return this.innerFiles.find((document) => document.category === ClientFileCategories.RESOLUTION_LETTER) || null;
  }

  get shouldDisplayUbos() {
    return this.uboChoiceForm.choice === 'yes';
  }

  get validUploadedFiles() {
    return true;

    // FIXME: documents are no longer required in UBOs (temporary fix)

    // if (!this.shouldDisplayUbos) {
    //   return true;
    // } else {
    //   const anyIncompleteUboFiles = this.uboUsers.find((user) =>
    //     [ClientFileCategories.PROOF_OF_ADDRESS, ClientFileCategories.PHOTO_ID].find(
    //       (cat) =>
    //         !user.documents.find((d) => d.category === cat) &&
    //         !user.readyToUpload?.find((d) => d.category === cat && d.file)
    //     )
    //   );

    //   return !anyIncompleteUboFiles;
    // }
  }

  get validation() {
    return {
      $model: null,
      $invalid:
        this.uboChoiceValidation?.$invalid ||
        !this.validUploadedFiles ||
        !!this.uboUserValidations?.find((val) => val?.$invalid) ||
        !!this.uboCurrentAddressValidations?.find((val) => val?.$invalid) ||
        !!this.uboPreviousAddressesValidations?.find(
          (val, index) => this.isUboAUKResident(this.ubos[index]) && val?.$invalid
        ),
      $dirty:
        !!this.uboChoiceValidation?.$dirty ||
        !!this.uboUserValidations?.find((val) => val?.$dirty) ||
        !!this.uboCurrentAddressValidations?.find((val) => val?.$dirty) ||
        !!this.uboPreviousAddressesValidations?.find(
          (val, index) => this.isUboAUKResident(this.ubos[index]) && val?.$dirty
        ),
    };
  }

  get emptyUser(): UnsubmittedUbo {
    return {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      birthDate: '',
      email: '',
      documents: [],
      currentAddress: {
        addressLine: '',
        city: '',
        postalCode: '',
        stateOrProvince: '',
        countryCode: '',
        residingFrom: '',
      },
      previousAddresses: [],
      readyToUpload: [
        { category: ClientFileCategories.PROOF_OF_ADDRESS, file: null },
        { category: ClientFileCategories.PHOTO_ID, file: null },
      ],
    };
  }

  get maxAllowedUsersReached() {
    return this.uboUsers.length >= MAX_ALLOWED_UBOS;
  }

  get hasSufficientAddressHistory() {
    const addressHistoryAge = getAddressHistoryAge({
      currentAddress: this.uboUsers.find((ubo) => ubo.currentAddress)?.currentAddress || {},
      previousAddresses: this.uboUsers.flatMap((ubo) => ubo.previousAddresses),
    });
    return addressHistoryAge >= 3;
  }

  private onUpdateUbo(ubo: UnsubmittedUbo, update: Partial<UnsubmittedUbo>) {
    Object.assign(ubo, update);
    this.$emit('update:ubos', this.uboUsers);
  }

  private uploadResolutionLetter(file: File) {
    return this.$services.client
      .uploadDocument(this.authStore.loggedInIdentity!.client!.id, ClientFileCategories.RESOLUTION_LETTER, file)
      .pipe(
        tap((update) => {
          if (update.finished) {
            this.waitForDocument(update.file).subscribe((document) => {
              if (document) {
                this.individualSettingsStore.setClientDocument({ document });
                const currFileIndex = this.innerFiles.findIndex((i) => i.category === document.category);

                currFileIndex > -1
                  ? this.innerFiles.splice(currFileIndex, 1, document)
                  : this.innerFiles.push(document);
              }
            });
          }
        })
      );
  }

  private waitForDocument(file: VersionedObject) {
    return waitForEntityChange(
      () => this.$services.client.getDocuments(this.authStore.loggedInIdentity!.client!.id),
      (docs) => {
        return !!docs.find((d) => d.id === file.id);
      }
    ).pipe(map((docs) => docs.find((d) => d.id === file.id)!));
  }

  private deleteFile(file: UploadedFile) {
    return this.$services.client.deleteDocument(this.authStore.loggedInIdentity!.client!.id, file.id).pipe(
      tap(() => {
        const currFileIndex = this.innerFiles.findIndex((i) => i.category === file.category);
        if (currFileIndex > -1) {
          this.innerFiles.splice(currFileIndex, 1);
        }

        this.individualSettingsStore.unsetClientDocument({ category: file.category as ClientFileCategories });
      })
    );
  }

  private downloadFile(file: UploadedFile) {
    return this.$services.client.downloadSyncDocument(this.authStore.loggedInIdentity!.client!.id, file);
  }

  private getFile(user: UnsubmittedUbo, category: ClientFileCategories) {
    return (
      user.readyToUpload?.find((file) => file.category === category)?.file ||
      user.documents?.find((file) => file.category === category)
    );
  }

  private onFileChange(file: File, user: UnsubmittedUbo, category: ClientFileCategories) {
    if (file === null) {
      this.onFileremoved(file, user, category);
    }
    this.$set(user, 'readyToUpload', user.readyToUpload || []);
    let userFile = user.readyToUpload!.find((file) => file.category === category)!;
    if (!userFile) {
      userFile = { category, file: file };
      user.readyToUpload!.push(userFile);
    } else {
      userFile.file = file;
    }
    this.$emit('update:ubos', this.uboUsers);
  }

  private onFileremoved(file: File, user: UnsubmittedUbo, category: ClientFileCategories) {
    user.readyToUpload = user.readyToUpload || [];

    let uploaded = user.documents!.find((file) => file.category === category)!;
    let userFile = user.readyToUpload!.find((file) => file.category === category)!;
    if (uploaded) {
      user.documents.splice(user.documents.indexOf(uploaded), 1);
    }

    if (!userFile) {
      userFile = { category, file: null };
      user.readyToUpload!.push(userFile);
    } else {
      userFile.file = null;
    }

    this.$emit('update:ubos', this.uboUsers);
  }

  private onFormEvent(event: FormEvent) {
    if (event.event === 'form-field-set-value') {
      this.uboUsers = this.uboUsers.map((user, index) => ({
        ...user,
        ...toDataModel(this.uboUserForms[index]),
        documents: user.documents,
        readyToUpload: user.readyToUpload,
      }));
      this.$emit('update:ubos', this.uboUsers);
    }
  }

  private onChoiceChange(event: FormEvent) {
    if (event.event === 'form-field-set-value' && this.shouldDisplayUbos && (!this.ubos || this.ubos.length === 0)) {
      this.addUser();
    } else if (event.event === 'form-field-set-value' && !this.shouldDisplayUbos) {
      this.uboUsers = [];
      this.uboUserForms = [];
      this.uboUserValidations = [];
      this.$emit('update:ubos', this.uboUsers);
    }
  }

  private deleteUbo(uboId?: string) {
    if (uboId) {
      this.requestManager
        .sameOrCancelAndNew(
          `deleteUbo-${uboId}`,
          this.$services.compliance.removeUboUser(this.authStore.loggedInIdentity!.client!.id, uboId)
        )
        .subscribe();
    }
  }

  private remove(index: number) {
    this.deleteUbo(this.uboUsers[index].id);
    if (this.uboUsers.length > 1) {
      this.uboUsers.splice(index, 1);
      this.uboUserValidations.splice(index, 1);
      this.$emit('update:ubos', this.uboUsers);
    } else {
      this.uboUsers = [];
      this.uboUserForms = [];
      this.uboUserValidations = [];
      this.$emit('update:ubos', null);
      this.uboChoiceForm.choice = 'no';
    }
  }

  private addUser(ubo?: UnsubmittedUbo) {
    this.uboUserForms.push(uboUserFM(this.dobInOnboardingFeatureActive));
    this.uboUsers.push(cloneDeep(ubo ?? this.emptyUser));
    if (!ubo) {
      this.$emit('update:ubos', this.uboUsers);
    } else {
      updateModel(this.uboUserForms[this.uboUserForms.length - 1], ubo);
    }
  }

  @Watch('files', { immediate: true })
  onUboFilesChange() {
    if (this.files !== null) {
      this.innerFiles = this.files;
    }
  }

  @Watch('ubos', { immediate: true })
  @Watch('dobInOnboardingFeatureActive')
  onUbosChange() {
    this.uboUserForms = [];
    this.uboUsers = [];
    this.ubos.forEach((ubo) => this.addUser(ubo));
    this.uboChoiceForm.choice = this.uboUsers.length > 0 ? 'yes' : 'no';
  }

  @Watch('innerFiles')
  onFilesChange() {
    if (!isEqual(this.innerFiles, this.files)) {
      this.$emit('update:files', this.innerFiles);
    }
  }

  @Watch('validation', { immediate: true })
  onValidationChange() {
    this.$emit('update:validation', this.validation);
  }
}
</script>
<style lang="scss" scoped>
.user-holder {
  position: relative;
}

.close-button {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  right: 1.3rem;
  top: -0.5rem;
  ::v-deep svg {
    path {
      @include themedPropColor('fill', $color-primary);
    }
  }
}
.current-account-heading {
  font-size: $base-font-size;
  font-weight: $font-weight-semibold;
  @include themedTextColor($color-primary);
}
</style>
