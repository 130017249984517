<template>
  <span>
    <BModal modal-class="side-modal aco-tier-modal" title="ACO tier breakdown" ref="acoTiersFormModal" hide-footer>
      <BoxGridBlock>
        <LoadingIcon v-if="requestManager.requestStates.loadPartnerFees === 'pending'" class="svg" />
        <BTabs class="tabs" content-class="mt-3" lazy v-else>
          <VRow class="table-labels mb-2" align-h="between" cols="12">
            <VCol md="9" sm="7"> Country </VCol>
            <VCol md="3" sm="5"> ISO </VCol>
          </VRow>
          <span v-for="(tier, index) in tierList" :key="index">
            <BTab class="tab" :title="tier.label" :active="index === 0">
              <AcoTiersList :fees="partnerFees.filter((fee) => fee.tier === tier.value)" />
            </BTab>
          </span>
        </BTabs>
      </BoxGridBlock>
    </BModal>
    <slot v-bind="{ showModal }" />
  </span>
</template>

<script lang="ts">
import { Component, Watch, Mixins } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import AcoTiersList from '@/app/components/FeesAndCharges/AcoTiersList.vue';
import { BankingScheme, AcoTier, FeePaymentType, FundingCharge } from 'ah-api-gateways';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';

/**
 * Show ACO Tiers for payment fees
 *
 * Uses Global values as reference
 */
@Component({
  components: { AcoTiersList },
})
export default class AcoTiersModal extends Mixins(WithRequestManager) {
  $refs!: {
    acoTiersFormModal: BModal;
  };

  requestManagerConfig = {
    exposeToParent: true,
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'loadPartnerFees') {
      this.loadPartnerFees();
    }
  }

  private partnerFees: FundingCharge[] = [];

  @Watch('requestManager.anyPending', { immediate: true })
  onSubmitStateChange() {
    this.$emit('update:formBusy', this.requestManager.anyPending);
  }

  private get tierList() {
    return [
      { value: AcoTier.ACO_TIER_1, label: 'Tier 1' },
      { value: AcoTier.ACO_TIER_2, label: 'Tier 2' },
      { value: AcoTier.ACO_TIER_3, label: 'Tier 3' },
      { value: AcoTier.ACO_TIER_4, label: 'Tier 4' },
    ];
  }

  @Watch('partnerId', { immediate: true })
  private loadPartnerFees() {
    this.requestManager
      .currentOrNew(
        'loadPartnerFees',
        this.$services.fees.listAHFees({
          type: FeePaymentType.PAYMENT,
          sort: 'bankingScheme,desc',
          bankingScheme: BankingScheme.SWIFT,
          pageSize: 1000,
        })
      )
      .subscribe((response) => {
        this.partnerFees = response ?? null;
      });
  }

  showModal() {
    this.$refs.acoTiersFormModal.show();
  }
}
</script>
<style lang="scss" scoped>
::v-deep .modal-body {
  width: 45rem;
  @include tablet {
    width: 40rem;
  }
  @include upToResolution($tabletResolution) {
    width: 100vw;
  }
}

.tabs,
.table-labels {
  font-size: 0.9em;
  ::v-deep .nav-item {
    width: 25%;
  }
}

::v-deep .nav-link {
  @include upToResolution($tabletResolution) {
    text-align: center;
  }
}
</style>
