import { render, staticRenderFns } from "./DrawdownCreator.vue?vue&type=template&id=d03fc6a6&scoped=true"
import script from "./DrawdownCreator.vue?vue&type=script&setup=true&lang=ts"
export * from "./DrawdownCreator.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./DrawdownCreator.vue?vue&type=style&index=0&id=d03fc6a6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d03fc6a6",
  null
  
)

export default component.exports