<template>
  <div class="profile-photo-editor">
    <div class="profile-photo-holder">
      <div class="profile-photo">
        <AsyncImage class="profile-photo-inner" v-if="photo" :src="imageSrc" alt="Profile Picture" />
      </div>
    </div>
    <UploadButton
      @files-selected="uploadPhoto"
      :loading="requestManager.anyPending"
      buttonClass="btn-stroked"
      accept="image/*"
    >
      {{ photo ? 'Re-upload' : 'Upload' }} <IconUpload />
    </UploadButton>
    <p class="text-secondary small-text mt-3">
      Max size: 10 MB<br />
      Supported formats:<br />
      .BMP, .JPEG, .GIF, .TIF, .PNG
    </p>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { IndividualFileCategories } from 'ah-api-gateways';
import AsyncImage from '@/app/components/common/AsyncImage.vue';
import UploadButton from 'ah-common-lib/src/common/components/upload/UploadButton.vue';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { useAuthStore } from '@/app/store/authStore';

@Component({
  components: {
    UploadButton,
    AsyncImage,
  },
})
export default class ProfilePhotoEditor extends Mixins(WithRequestManager) {
  imageSrc = '';
  requestManagerConfig = {
    exposeToParent: false,
  };

  @Watch('photo.id', { immediate: true })
  onPhotoIdChange() {
    if (this.photo) {
      this.$services.individual
        .getDocumentAsBlob(this.authStore.loggedInIdentity!.id, this.photo!)
        .subscribe((blob) => {
          if (this.imageSrc) {
            URL.revokeObjectURL(this.imageSrc);
          }
          this.imageSrc = URL.createObjectURL(blob!);
        });
    }
  }

  beforeDestroy() {
    if (this.imageSrc) {
      URL.revokeObjectURL(this.imageSrc);
    }
  }

  created() {
    this.individualSettingsStore.loadIndividualDocuments(true);
  }

  get individualSettingsStore() {
    return useIndividualSettingsStore();
  }

  get photo() {
    return this.individualSettingsStore.getIndividualDocument(IndividualFileCategories.PROFILE_PICTURE);
  }

  get authStore() {
    return useAuthStore();
  }

  uploadPhoto(files: File[]) {
    const file = files[0];

    if (file) {
      if (file.size > 10485760) {
        this.$toast.error(`File size exceeds maximum allowed of 10 MB`);
      } else if (!file.type.startsWith('image')) {
        this.$toast.error(`File must be an image (.BMP, .JPEG, .GIF, .TIF, .PNG)`);
      } else {
        this.requestManager
          .currentOrNew(
            'uploadPhoto',
            this.individualSettingsStore.uploadIndividualDocument(file, IndividualFileCategories.PROFILE_PICTURE)
          )
          .subscribe();
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.profile-photo-editor {
  text-align: center;

  .profile-photo-holder {
    width: 70%;
    max-width: 200px;
    position: relative;
    margin: auto;
    margin-bottom: 1em;
    border-radius: 100%;
    overflow: hidden;
    @include themedBackgroundColor($color-dark-main-bg, $color-main-bg);

    .profile-photo {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      .profile-photo-inner {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }
}

.small-text {
  font-size: 0.8em;
}
</style>
