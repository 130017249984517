<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.71758 3.64491L1.36508 14.2499C1.09856 14.7115 1.09697 15.2798 1.3609 15.7428C1.62483 16.2058 2.11464 16.4941 2.64758 16.4999H15.3526C15.8855 16.4941 16.3753 16.2058 16.6393 15.7428C16.9032 15.2798 16.9016 14.7115 16.6351 14.2499L10.2826 3.64491C10.0107 3.19662 9.52439 2.92285 9.00008 2.92285C8.47576 2.92285 7.9895 3.19662 7.71758 3.64491Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M9 7.5V10.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
    <circle cx="9" cy="13.5" r="0.75" fill="currentColor" />
  </svg>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
