<script setup lang="ts">
import { makeFormModel, updateModel, toDataModel, getChildModel, setState } from 'ah-common-lib/src/form/helpers';
import { addressForm } from 'ah-common-lib/src/form/formModels';
import { Address, BeneficiaryType, BankingScheme } from 'ah-api-gateways';
import { CountryInfo } from 'ah-api-gateways';
import { computed, reactive, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useBeneficiariesState } from 'ah-beneficiaries';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';

const requestManager = useRequestManager({
  exposeToParent: ['loadCountries'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadCountries') {
      loadCountries();
    }
  },
});

const beneficiarieState = useBeneficiariesState();

const props = withDefaults(
  defineProps<{
    model: Partial<Address>;
    beneficiaryType: BeneficiaryType;
    countryCode?: string;
    /**
     * Currency for validation purposes
     *
     * Will override countryCode and form.countryCode for determining which country to use for validating fields
     */
    validationCurrency?: string;
    currency?: string;
    /**
     * Which banking scheme to load fields for. If allSchemesValidFields is truthy, this prop is ignored
     */
    bankingScheme?: BankingScheme;
    noCountryCode?: string | boolean;
    /**
     * Whether to show all valid fields for both schemes
     *
     * If set as truthy, bankingScheme is ignored
     */
    allSchemesValidFields?: string | boolean;
  }>(),
  {
    bankingScheme: BankingScheme.LOCAL,
    noCountryCode: false,
    allSchemesValidFields: false,
  }
);

const emit = defineEmits<{
  (e: 'update:loading', value: boolean | null): void;
  (e: 'update:model', value: Address | null): void;
}>();

const state = reactive({
  countriesList: [] as CountryInfo[],
});

const addressFormDef = reactive<FormDefinition>({
  form: makeFormModel(addressForm('addressForm', '')),
  validation: null,
});

const showCountryCode = computed(() => {
  return props.noCountryCode === false;
});

const currencyFieldParameters = computed(() => ({
  currency: formCurrency.value!,
  beneficiaryType: props.beneficiaryType,
  bankingScheme: props.bankingScheme ?? undefined,
}));

const formCurrency = computed(() => {
  if (props.validationCurrency) {
    return props.validationCurrency;
  }
  if (showCountryCode.value && addressFormDef.form.countryCode) {
    return beneficiarieState.store.useSettingsStore().getCountryCurrency(addressFormDef.form.countryCode);
  }
  if (props.currency) {
    return props.currency;
  }
  if (props.countryCode) {
    return beneficiarieState.store.useSettingsStore().getCountryCurrency(props.countryCode);
  }
  return addressFormDef.form.countryCode
    ? beneficiarieState.store.useSettingsStore().getCountryCurrency(addressFormDef.form.countryCode)
    : '';
});

function loadCountries() {
  requestManager.manager.newPromise(
    'loadCountries',
    beneficiarieState.store
      .useSettingsStore()
      .loadCountries()
      .then((countries) => {
        state.countriesList = countries.filter((c) => c.enabled);
      })
  );
}

loadCountries();

watch(
  () => props.model,
  () => {
    if (addressFormDef.form) {
      updateModel(addressFormDef.form, props.model);
    }
  },
  { immediate: true }
);

function updateCountriesList() {
  if (addressFormDef.form) {
    const countryCodeField = getChildModel(addressFormDef.form, 'countryCode');
    if (countryCodeField) {
      setState(
        countryCodeField,
        'options',
        state.countriesList.map((i) => ({ label: i.name, value: i.cc }))
      );
    }
  }
}

watch(
  () => state.countriesList,
  () => {
    updateCountriesList();
  },
  { immediate: true }
);

watch(
  () => [props.countryCode, showCountryCode],
  () => {
    const addressFormConfig = addressForm('addressForm', '', { state: { showRequiredMarkers: true } });
    if (!showCountryCode.value) {
      const index = addressFormConfig.fields.findIndex((f) => f.name === 'countryCode');
      if (index > -1) {
        addressFormConfig.fields.splice(index, 1);
      }
    }

    addressFormDef.form = makeFormModel(addressFormConfig);
    updateModel(addressFormDef.form, props.model);

    if (props.countryCode) {
      updateCountriesList();
      addressFormDef.form.countryCode = props.countryCode;
      if (showCountryCode.value) {
        const countryCodeField = getChildModel(addressFormDef.form, 'countryCode');
        if (countryCodeField) {
          setState(countryCodeField, 'readonly', true);
        }
      }
    }
  },
  { immediate: true }
);

function loadFormFields() {
  if (formCurrency.value && props.beneficiaryType) {
    return requestManager.manager.sameOrNewPromise(
      'loadCountryAddressFields',
      () =>
        props.allSchemesValidFields === false
          ? beneficiarieState.store
              .useSettingsStore()
              .loadCurrencyAddressFields({ params: currencyFieldParameters.value })
          : Promise.all([
              beneficiarieState.store.useSettingsStore().loadCurrencyAddressFields({
                params: { ...currencyFieldParameters.value, bankingScheme: BankingScheme.LOCAL },
              }),
              beneficiarieState.store.useSettingsStore().loadCurrencyAddressFields({
                params: { ...currencyFieldParameters.value, bankingScheme: BankingScheme.SWIFT },
              }),
            ]).then((defArray) => {
              return defArray[0].map((definition) => {
                const swiftDefinition = defArray[1].find((d) => d.fieldName === definition.fieldName);
                if (swiftDefinition) {
                  definition.mandatory = definition.mandatory && swiftDefinition.mandatory;
                  definition.visible = definition.visible || swiftDefinition.visible;
                }
                return definition;
              });
            }),
      props.countryCode
    );
  }
  return Promise.reject();
}

watch(
  currencyFieldParameters,
  () => {
    loadFormFields().then((fieldDefinitions) => {
      addressFormDef.form.$fields.forEach((field) => {
        const definition = fieldDefinitions.find((f) => f.fieldName === field.$name);
        if (definition) {
          setState(field, 'hidden', !definition.visible);
          setState(field, 'required', definition.visible && definition.mandatory);
          if (definition.title) {
            setState(field, 'title', definition.title);
          }

          // EntityType is currently an exception: handled by loadEntityTypes
          if (definition.options?.length || definition.fieldName === 'entityType') {
            setState(field, 'clearable', !definition.mandatory);
            if (definition.options?.length && definition.fieldName !== 'entityType') {
              // If there is only one option, set value and hide field
              setState(field, 'options', definition.options);
              if (!definition.options.find((o) => o.value === addressFormDef.form[field.$name])) {
                addressFormDef.form[field.$name] = null;
              }
              if (definition.options.length === 1) {
                addressFormDef.form[field.$name] = definition.options[0].value;
                setState(field, 'hidden', true);
              }
            }
          }
        }
      });
      onFormEvent();
    });
  },
  { immediate: true }
);

watch(
  () => [requestManager.manager.anyPending],
  () => {
    emit('update:loading', requestManager.manager.anyPending);

    if (addressFormDef.form) {
      setState(addressFormDef.form, 'readonly', requestManager.manager.anyPending);
    }
  },
  { immediate: true }
);

function onFormEvent() {
  emit('update:model', {
    ...props.model,
    ...toDataModel(addressFormDef.form),
  });
}

function setErrorMessage(fieldName: string, message: string) {
  let field = getChildModel(addressFormDef.form, fieldName);

  if (field) {
    setState(field, 'errors', [
      {
        name: 'customErrorMessage',
        error: message,
      },
    ]);
  }
}

function clearErrorMessages() {
  setState(addressFormDef.form, 'errors', [], true);
}

defineExpose({ setErrorMessage, clearErrorMessages });
</script>

<template>
  <ValidatedForm :fm="addressFormDef.form" v-on="$listeners" @form-event="onFormEvent" />
</template>
