import { defaultFormatter, getLocaleSeparators } from './currency';

export function formatNumberValue(value: string | number, maxDecimalPlaces?: number, formatter = defaultFormatter) {
  const stringNumber = typeof value === 'string' ? value : value.toString();
  const separators = getLocaleSeparators(formatter);
  const decimalSeparatorRegexp = new RegExp(`[\\${separators.decimalSeparator}]+`, 'g');
  let out = stringNumber.replace(/[^\d,.]/g, '').replace(decimalSeparatorRegexp, '.');
  const dupeDecimalIndex = out.indexOf(separators.decimalSeparator, out.indexOf(separators.decimalSeparator) + 1);
  if (dupeDecimalIndex > -1) {
    out = out.substr(0, dupeDecimalIndex);
  }
  if (maxDecimalPlaces !== undefined && getNumberOfDecimalPlaces(out) > maxDecimalPlaces) {
    out = parseFloat(out).toFixed(maxDecimalPlaces);
  }
  return out;
}

export function getNumberOfDecimalPlaces(stringNumber: string) {
  if (stringNumber.includes('.')) return stringNumber.split('.')[1].length || 0;
  return 0;
}
