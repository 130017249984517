<script lang="ts" setup>
import { Payment, PaymentReport } from 'ah-api-gateways';
import { BModal, BvModalEvent } from 'bootstrap-vue';
import PaymentEditor from 'ah-payments/src/components/PaymentEditor.vue';
import RouteProtectorModal from 'ah-common-lib/src/common/components/route/RouteProtectorModal.vue';
import { computed, reactive, ref } from 'vue';
import { FormValidation } from 'ah-common-lib/src/form/interfaces';

const paymentFormModal = ref<InstanceType<typeof BModal> | null>(null);

const protectorModal = ref<InstanceType<typeof RouteProtectorModal> | null>(null);

const emit = defineEmits<{
  (e: 'update-payment', value: Payment): void;
}>();

const props = defineProps<{
  payment?: PaymentReport;
}>();

const state = reactive({
  validations: [] as FormValidation[],
  formTitle: '' as string,
});

const formTitle = computed(() => {
  if (state.formTitle) return state.formTitle;
  return props.payment ? 'Amend Payment' : 'New Payment';
});

function onHide(event: BvModalEvent) {
  if (event.trigger && anyDirty.value) {
    event.preventDefault();
    protectorModal.value!.$refs.modal.show();
  }
}

const anyDirty = computed(() => {
  return !!state.validations.find((f) => !f || f.$anyDirty);
});

function showModal() {
  state.formTitle = '';
  state.validations = [];
  paymentFormModal.value!.show();
}

function hideModal() {
  state.validations = [];
  paymentFormModal.value!.hide();
}

function onPaymentSaved(payment: Payment) {
  // setTimeout is used to ensure reactions happens after the validations have been cleared and route protector is off
  setTimeout(() => {
    emit('update-payment', payment);
  });
  hideModal();
}

function setFormTitle(title: string) {
  state.formTitle = title;
}
</script>

<template>
  <span class="mt-auto d-block btn">
    <BModal modal-class="side-modal payment-modal" :title="formTitle" ref="paymentFormModal" hide-footer @hide="onHide">
      <PaymentEditor
        :payment="payment"
        :validations.sync="state.validations"
        @update-payment="onPaymentSaved"
        @cancel="hideModal()"
        @update-title="setFormTitle($event)"
        v-bind="$attrs"
      />
      <RouteProtectorModal
        ref="protectorModal"
        :allowChange="!anyDirty"
        :allowSubpaths="false"
        :allowQueryChange="false"
        class="exit-protected-route"
        @ok="hideModal()"
        centered
        title="Changes not saved"
      >
        <p>There are unsaved changes. Are you sure you want to continue?</p>
      </RouteProtectorModal>
    </BModal>
    <slot v-bind="{ showModal }">
      <VButton class="btn btn-primary" @click="showModal()">{{ formTitle }}</VButton>
    </slot>
  </span>
</template>

<style lang="scss" scoped>
::v-deep .payment-modal .modal-dialog {
  width: 50em;
}

::v-deep .obo-approval-modal {
  .modal-content {
    padding: 2em 1em 1em 1em;
    .status-icon-pending {
      width: 25px;
      height: 25px;
      @include themedTextColor($color-yellow-highlight);
    }
    .btn {
      width: 104px;
    }
  }
  .modal-dialog {
    min-width: 35em;
  }
}

@media (min-width: 900px) {
  ::v-deep .payment-modal .modal-dialog {
    max-width: 50em;
  }
}
</style>
