<template>
  <div :class="['nav-view-grid', { ['no-sidebar']: !isSidebarShown || $mediaQuery.is('smDown') }]">
    <TopNav class="header" />
    <BoxGrid alignV="start">
      <template v-if="isSidebarShown">
        <div class="d-flex flex-column col-12">
          <OnboardingStatusBanner v-if="isClientInOnboarding" />
          <AccountStatusBanner v-else />
          <ActiveMarginCallBanner />
          <AppUpdaterBanner />
          <FeeWarningBanner />
        </div>
      </template>
      <BoxGridItem align-self="stretch" cols="12">
        <RouterView name="banner" />
      </BoxGridItem>
    </BoxGrid>
    <SideNav class="sidebar" v-if="isSidebarShown" />
    <div class="main-area">
      <div
        :class="[
          'main-area-inner',
          {
            fullscreen: isFullscreenView,
            'with-sidebar': isSidebarShown,
            'with-sidebar-menu': isSidebarShown && hasSidebarView,
          },
        ]"
      >
        <RouterView />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import TopNav from '@/app/components/nav/TopNav.vue';
import SideNav from '@/app/components/nav/SideNav.vue';
import OnboardingStatusBanner from '@/app/components/onboarding/OnboardingStatusBanner.vue';
import FeeWarningBanner from '../../banner/FeeWarningBanner.vue';
import ActiveMarginCallBanner from '../../banner/ActiveMarginCallBanner.vue';
import AccountStatusBanner from '../../banner/AccountStatusBanner.vue';
import AppUpdaterBanner from '@/app/components/common/AppUpdaterBanner.vue';
import LimitsProfileWarningBanner from 'ah-trades/src/components/warningBanner/LimitsProfileWarningBanner.vue';
import { inheritedMeta } from 'ah-common-lib/src/helpers/router';
import { useAuthStore } from '@/app/store/authStore';
import { onboardingStatuses } from 'ah-api-gateways';

@Component({
  components: {
    TopNav,
    SideNav,
    OnboardingStatusBanner,
    FeeWarningBanner,
    AppUpdaterBanner,
    LimitsProfileWarningBanner,
    AccountStatusBanner,
    ActiveMarginCallBanner,
  },
})
export default class NavViewGrid extends Vue {
  @Prop({ default: false }) hideSidebar!: boolean;

  get authStore() {
    return useAuthStore();
  }

  get isSidebarShown() {
    return !this.hideSidebar && this.authStore.isLoggedIn;
  }

  get hasSidebarView() {
    return !!this.$route.matched.find((r) => !!r.components.sidebar);
  }

  get isFullscreenView() {
    return inheritedMeta(this.$route).fullscreen;
  }

  get isClientInOnboarding() {
    return this.authStore.complianceStatus && onboardingStatuses.includes(this.authStore.complianceStatus);
  }
}
</script>
<style lang="scss" scoped>
.nav-view-grid {
  height: 100%;
  display: grid;
  grid-template-columns: max-content auto;
  grid-template-rows: $top-nav-height min-content 1fr;
  grid-template-areas:
    'sidebar header'
    'sidebar subheader'
    'sidebar main';

  &.no-sidebar {
    grid-template-columns: auto;
    grid-template-areas:
      'header'
      'subheader'
      'main';

    .sidebar {
      display: none;
    }
  }

  @include tablet {
    grid-template-rows: $top-nav-height min-content 1fr;
  }
  @include desktop {
    grid-template-rows: $top-nav-height min-content 1fr;
  }

  > .main-area {
    grid-area: main;
    overflow: auto;
    align-self: stretch;
    position: relative;

    .main-area-inner {
      height: 100%;

      &.with-sidebar:not(.fullscreen) {
        max-width: $max-main-content-width;

        &.with-sidebar-menu {
          max-width: $max-main-content-width - $side-inner-nav-width;
        }
      }
    }
  }

  > .header {
    grid-area: header;
  }

  > .subheader {
    grid-area: subheader;
  }

  > .sidebar {
    grid-area: sidebar;
  }
}
</style>
