<script lang="ts" setup>
import {
  QuotePriceResponse,
  Wallet,
  getBuyCcy,
  getSellCcy,
  getPrimaryCcy,
  getSecondaryCcy,
  formatHedgingInstrument,
} from 'ah-api-gateways';
import { isToday, parseISO } from 'date-fns';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { FULL_DATE_FORMAT } from 'ah-common-lib/src/helpers/time';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useTradeState } from '../..';
import { formatInTimeZone } from 'date-fns-tz';
import { computed, reactive, watch } from 'vue';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const tradeState = useTradeState();

const onBehalfOfClient = useOnBehalfOf();

const props = defineProps<{
  /**
   * Trade price response object
   */
  tradePriceResponse: QuotePriceResponse;
}>();

const requestManager = useRequestManager({
  exposeToParent: ['loadWallet'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadWallet') {
      onPriceChange();
    }
  },
});

const state = reactive({
  wallet: null as Wallet | null,
});

const buyCcy = computed(() => {
  return getBuyCcy(props.tradePriceResponse);
});

const sellCcy = computed(() => {
  return getSellCcy(props.tradePriceResponse);
});

const primaryRate = computed(() => {
  return getPrimaryCcy(props.tradePriceResponse);
});

const secondaryRate = computed(() => {
  return getSecondaryCcy(props.tradePriceResponse);
});

const isOverdraft = computed(() => {
  return state.wallet && sellCcy.value.clientAmount > state.wallet.balance;
});

const isSettleToday = computed(() => {
  return isToday(parseISO(props.tradePriceResponse.settlementDate));
});

const isPartnerUser = computed(() => {
  return !onBehalfOfClient.value && !tradeState.store.useAuthStore().isClientUser;
});

const ownerId = computed(() => {
  if (onBehalfOfClient.value) {
    return onBehalfOfClient.value.id;
  }
  return (
    tradeState.store.useAuthStore().loggedInIdentity?.client?.id ||
    tradeState.store.useAuthStore().loggedInIdentity?.partner?.id
  );
});

function getSettlementDate(trade: QuotePriceResponse, dateFormat: string) {
  if (trade.settlementDate) {
    return formatInTimeZone(new Date(trade.settlementDate), 'GMT', dateFormat);
  }
  return '-';
}

function onPriceChange() {
  requestManager.manager
    .newPromise(
      'loadWallet',
      tradeState.store.useWalletsStore().loadCurrencyWallet({
        currency: getSellCcy(props.tradePriceResponse).currency,
        owner: { isPartner: isPartnerUser.value, id: ownerId.value },
      })
    )
    .then(
      (wallet) => {
        state.wallet = wallet || null;
      },
      () => {
        state.wallet = null;
      }
    );
}

watch(
  () => props.tradePriceResponse,
  () => {
    onPriceChange();
  },
  { immediate: true }
);
</script>

<template>
  <div v-if="tradePriceResponse">
    <DataRow class="mb-2 trade-currency-pair" label="Currency pair">
      {{ primaryRate.currency }}{{ secondaryRate.currency }}
    </DataRow>
    <DataRow class="mb-2 trade-instrument" label="Instrument">
      {{ formatHedgingInstrument(tradePriceResponse.hedgingProduct) }}
    </DataRow>
    <DataRow class="mb-2 trade-sell-currency" label="Selling">
      {{ formatCurrencyValue(sellCcy.clientAmount) }} {{ sellCcy.currency }}
    </DataRow>
    <DataRow class="mb-2 trade-buy-currency" label="Buying">
      <strong>
        {{ formatCurrencyValue(buyCcy.clientAmount) }}
        {{ buyCcy.currency }}
      </strong>
    </DataRow>
    <DataRow class="mb-2 trade-rate" label="Rate">
      {{ primaryRate.clientRate }} <span class="text-secondary">(inverse {{ secondaryRate.clientRate }})</span>
    </DataRow>
    <DataRow class="mb-4 trade-settlement-date" label="Settlement date">
      {{ getSettlementDate(tradePriceResponse, `${FULL_DATE_FORMAT} HH:mm`) }} GMT
    </DataRow>
    <p class="text-secondary mt-2 trade-overdraft-warning" v-if="!isOverdraft">
      Please have sufficient funds in your wallet by the settlement date. Late settlement may incur penalties and delays
      in payment.
    </p>
    <div class="action-text action-danger" v-else>
      <IconAlertCircle :class="{ danger: isSettleToday }" class="info-circle actions-required" />
      <p :class="{ 'text-danger': isSettleToday }" class="mt-2">
        You are able to proceed but please note that your current wallet balance does not cover the settlement amount
        for this trade.
      </p>
      <p :class="{ 'text-danger': isSettleToday }" class="mt-2">
        Please ensure your ({{ sellCcy.currency }}) wallet has the necessary funds ahead of the settlement date.
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.action-text {
  position: relative;
  padding-left: 2em;

  .info-circle {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1.5em;
    margin-right: 0.1em;
    &.danger {
      @include themedTextColor($color-danger);
    }
    bottom: 0.05em;
  }
}
</style>
