<template>
  <FormFlowWrapper>
    <SimpleCenteredPage :narrow="isSmallScreen" fullHeight padded>
      <VRow class="registration-type-selection-wrapper">
        <VCol xl="6" lg="6" sm="12" class="text-center">
          <img class="registration-partner-logo" :src="splashLogoLink" alt="" v-if="splashLogoLink" />
        </VCol>
        <VCol xl="4" lg="4" sm="12">
          <div class="registration-type-selection">
            <h2 class="text-md-left text-sm-center">Which type of account do you need?</h2>
            <div class="selection-buttons">
              <VButton
                blurOnClick
                @click="select('personal')"
                :class="['selection-button', selection === 'personal' ? 'primary' : 'secondary']"
              >
                <h2><PeopleWavingIcon /> Individual Account</h2>
                <ExpandTransition appear>
                  <div class="selected-type-info" v-if="selection === 'personal'">
                    <p>To create an individual account we will require the following information during sign-up</p>
                    <div class="my-2 selected-type-info-list">
                      <div>Your personal details</div>
                      <div>Details of your expected account usage</div>
                      <div>Proof of identity</div>
                      <div>Proof of address</div>
                    </div>
                  </div>
                </ExpandTransition>
              </VButton>

              <VButton
                blurOnClick
                @click="select('company')"
                :class="['selection-button', selection === 'company' ? 'primary' : 'secondary']"
              >
                <h2><HeadquartersIcon class="mr-2" />Business Account</h2>
                <ExpandTransition appear>
                  <div class="selected-type-info" v-if="selection === 'company'">
                    <p>To open a business account we will require the following information during sign-up</p>
                    <div class="my-2 selected-type-info-list">
                      <div>Your Business registration details</div>
                      <div>Details of directors and/or board members</div>
                      <div>Details of your expected account usage</div>
                      <div>Proof of business activity</div>
                      <div>Proof of address</div>
                    </div>
                  </div>
                </ExpandTransition>
              </VButton>
            </div>
          </div>
          <div class="registration-type-footer">
            <div class="ml-3">
              Already have an account?
              <RouterLink to="/"> Login </RouterLink>
            </div>
            <VButton class="ml-auto" @click="$emit('update:selected', selection)" :disabled="!selection">
              Proceed
            </VButton>
          </div>
        </VCol>
      </VRow>
    </SimpleCenteredPage>
  </FormFlowWrapper>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import FormFlowWrapper from '../common/form/FormFlowWrapper.vue';
import { BrandingImageType } from 'ah-api-gateways';
import PeopleWavingIcon from 'ah-common-lib/src/icons/components/PeopleWavingIcon.vue';
import HeadquartersIcon from 'ah-common-lib/src/icons/components/HeadquartersIcon.vue';

/**
 * Client self registration type selection
 *
 * Will display 'Individual' and 'Company' options
 *
 * Emits:
 *
 * - update:selected (payload: string): emmited when user chooses one of the possible options
 */
@Component({
  components: {
    FormFlowWrapper,
    PeopleWavingIcon,
    HeadquartersIcon,
  },
})
export default class RegistrationTypeSelection extends Vue {
  @Prop({ default: '' }) selected!: 'company' | 'personal' | '';

  private selection: 'company' | 'personal' | '' = '';

  @Watch('selected', { immediate: true })
  onSelectedChange() {
    this.selection = this.selected;
  }

  select(selection: 'company' | 'personal') {
    this.selection = this.selection === selection ? '' : selection;
  }

  get splashLogoLink() {
    let out = this.$theme.val?.logos.find((l) => l.type === BrandingImageType.SPLASH_IMAGE)?.link;
    return out;
  }

  get isSmallScreen() {
    return this.$mediaQuery.is('mdDown');
  }
}
</script>
<style lang="scss" scoped>
.registration-type-selection-wrapper {
  @include upToResolution($desktopResolution) {
    * {
      font-size: $font-size-sm;
    }
  }

  .registration-partner-logo {
    max-height: 10rem;
    width: 60%;
    align-items: center;
    object-fit: contain;

    @include phablet {
      margin-top: 0rem;
      margin-bottom: 2rem;
    }
    @include tablet {
      margin-top: 4rem;
      margin-bottom: 5rem;
    }
  }

  .registration-type-selection {
    @include themedTextColor($color-primary);
    fill: $color-primary !important;
  }

  .selection-button {
    width: 100%;
    margin-bottom: 2em;
    min-height: 3.5rem;
    border-radius: 13px;
    border-width: 3px;

    &:focus,
    &:hover {
      border-color: getColorHSLA($color-primary, 95%) !important;
      background-color: getColor($color-primary) !important;
      ::v-deep {
        h2,
        path {
          color: white;
          fill: $white !important;
        }
      }
    }

    h2 {
      margin-bottom: 0;
    }

    ::v-deep {
      &.secondary {
        path {
          fill: getColor($color-primary);
        }
      }
      &.primary {
        border: solid 3px;
        border-color: getColorHSLA($color-primary, 85%) !important;

        h2,
        path {
          color: white;
          fill: white;
        }
      }

      .label {
        text-align: left;
      }
    }

    .selected-type-info {
      margin-top: 1em;
      @include themedTextColor($color-dark-text);

      .selected-type-info-list {
        text-transform: uppercase;
      }
    }
  }

  .registration-type-footer {
    width: 100%;
    margin-top: 1em;
    display: inline-flex;
    align-items: center;
  }
}
</style>
