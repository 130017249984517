<template>
  <div class="app-banner">
    <slot />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AppBanner extends Vue {}
</script>
<style lang="scss" scoped>
.app-banner {
  background: rgba($green, 0.1);
  padding: 0.8em;
  border-bottom: 4px solid $green;

  &.success {
    @include themedProp(
      'background',
      getColorWithAlpha($color-success, 0.15),
      getColorWithAlpha($color-dark-success, 0.15)
    );
    @include themedPropColor('border-bottom-color', $color-success);
  }

  &.info {
    @include themedProp(
      'background',
      getColorWithAlpha($color-primary-light, 0.15),
      getColorWithAlpha($color-dark-primary-light, 0.15)
    );
    @include themedPropColor('border-bottom-color', $color-primary-light);
  }

  &.warning {
    @include themedProp(
      'background',
      getColorWithAlpha($color-yellow-highlight, 0.15),
      getColorWithAlpha($color-dark-yellow-highlight, 0.15)
    );
    @include themedPropColor('border-bottom-color', $color-yellow-highlight);
  }

  &.danger {
    @include themedProp(
      'background',
      getColorWithAlpha($color-danger, 0.15),
      getColorWithAlpha($color-dark-danger, 0.15)
    );
    @include themedPropColor('border-bottom-color', $color-danger);
  }
}
</style>
