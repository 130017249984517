<script setup lang="ts">
import { toDataModel, updateModel, setState, getChildModel } from 'ah-common-lib/src/form/helpers';
import { FormDefinition, FormEvent, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { useSettingsStore } from '@/app/store/settingsModule';
import { residenceFM } from '@/app/helpers/registration/forms';
import { computed, onBeforeMount, reactive, watch, PropType } from 'vue';
import { BaseRegistrationModel, ClientType, CompanyRegistrationModel } from 'ah-api-gateways';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { emailField, selectField, textField } from 'ah-common-lib/src/form/models';
import { requiredIfStateValue, validName } from 'ah-common-lib/src/form/validators';

const activeCountries = [
  { value: 'GB', label: 'United Kingdom' },
  { value: 'IE', label: 'Ireland' },
  { value: 'BE', label: 'Belgium' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'EE', label: 'Estonia' },
  { value: 'PT', label: 'Portugal' },
  { value: 'JE', label: 'Jersey' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'IM', label: 'Isle of Man' },
  { value: 'DE', label: 'Germany' },
  { value: 'MT', label: 'Malta' },
  { value: 'notListed', label: 'My country is not listed' },
];

const props = defineProps({
  model: {
    type: Object as PropType<BaseRegistrationModel | CompanyRegistrationModel>,
    default: null,
  },
  clientType: {
    type: String as PropType<ClientType>,
    required: true,
  },
  heading: {
    type: String,
    required: true,
  },
});

const emit = defineEmits<{
  (e: 'update:validation', value: FormValidation<BaseRegistrationModel> | null): void;
  (e: 'update:model', value: BaseRegistrationModel): void;
  (e: 'proceed', value: void): void;
}>();

function makeRequestToBeNotifiedFM(clientType: boolean, countries: { value: string; label: string }[]) {
  return makeFormModel({
    name: 'requestedToBeNotifiedForm',
    fieldType: 'form',
    fields: [
      selectField('countryCode', 'Country', countries, {
        required: true,
        placeholder: 'Select Country',
      }),
      textField(
        'firstName',
        'Name',
        { fieldWrapperClass: 'col col-6', placeholder: 'Name' },
        { required: requiredIfStateValue('firstName'), validName }
      ),
      textField(
        'lastName',
        'Surname',
        { fieldWrapperClass: 'col col-6', placeholder: 'Surname' },
        { required: requiredIfStateValue('lastName'), validName }
      ),

      ...(clientType ? [textField('companyName', 'Company Name', { required: true })] : []),
      emailField('email', 'Email address', {
        maxLength: 100,
      }),
    ],
  });
}

const requestManager = useRequestManager().manager;

const settingsStore = useSettingsStore();

const countryFormDef = reactive<FormDefinition>({
  form: residenceFM(),
  validation: null,
});

const requestToBeNotifiedFormDef = reactive<FormDefinition>({
  form: makeRequestToBeNotifiedFM(props.clientType === ClientType.COMPANY, []),
  validation: null,
});

const showRequestToBeNotifiedForm = computed(() => {
  // Only show if 'My country is not listed' is selected
  return countryFormDef.form.countryCode === 'notListed';
});

const shouldDisplayReverseSolicitation = computed(
  () => countryFormDef.form.countryCode && countryFormDef.form.countryCode !== 'GB'
);

const calculatedModel = computed(() => {
  const { reverseSolicitation, ...address } = toDataModel(countryFormDef.form);
  return {
    ...props.model,
    address: {
      ...props.model?.address,
      ...address,
    },
    reverseSolicitation,
  } as BaseRegistrationModel;
});

const validation = computed(() => {
  return {
    $model: calculatedModel.value,
    $invalid: !!countryFormDef.validation?.$invalid || !!requestToBeNotifiedFormDef.validation?.$invalid,
    $dirty: !!countryFormDef.validation?.$dirty || !!requestToBeNotifiedFormDef.validation?.$dirty,
  } as FormValidation<BaseRegistrationModel>;
});

function onCountryFormEvent(event: FormEvent) {
  if (event.event === 'form-field-set-value') {
    emit('update:model', calculatedModel.value);
  }
}

onBeforeMount(() => {
  const countryCodeField = getChildModel(countryFormDef.form, 'countryCode')!;

  // Temporary added hardcoded list of countries required for account creation.
  setState(countryCodeField, 'options', activeCountries);
});

onBeforeMount(() => {
  const countryCodeField = getChildModel(requestToBeNotifiedFormDef.form, 'countryCode')!;

  settingsStore.loadCountries(true).then(() => {
    const activeCountriesCode = activeCountries.map((country) => country.value);

    const countries = settingsStore.allCountries
      .filter((country) => !activeCountriesCode.includes(country.cc))
      .map((country) => ({
        value: country.cc,
        label: country.name,
      }));

    setState(countryCodeField, 'options', countries);
  });
});

watch(
  () => props.clientType,
  (newClientType) => {
    requestToBeNotifiedFormDef.form = makeRequestToBeNotifiedFM(newClientType === ClientType.COMPANY, []);
  }
);

watch(
  () => countryFormDef.form.countryCode,
  (val) => {
    const field = getChildModel(countryFormDef.form, 'reverseSolicitation')!;
    setState(field, 'hidden', !shouldDisplayReverseSolicitation.value);
    setState(field, 'required', shouldDisplayReverseSolicitation.value);
    if (val !== 'notListed') {
      requestToBeNotifiedFormDef.validation = null;
    }
  },
  { immediate: true }
);

watch(
  validation,
  () => {
    emit('update:validation', validation.value);
  },
  { immediate: true }
);

watch(
  () => props.model,
  () => {
    updateModel(countryFormDef.form, {
      ...props.model?.address,
      reverseSolicitation: props.model?.reverseSolicitation,
    });
  },
  { immediate: true }
);
</script>

<template>
  <div x-test-name="account-country-form">
    <h2>{{ showRequestToBeNotifiedForm ? 'X-hedge is currently unavailable in your region' : heading }}</h2>
    <ValidatedForm
      :fm="countryFormDef.form"
      :validation.sync="countryFormDef.validation"
      @form-event="onCountryFormEvent"
    />
    <div v-if="showRequestToBeNotifiedForm" class="card-block">
      <p class="mt-3">I wish to be informed if and when X-Hedge becomes available for my region.</p>
      <ValidatedForm :fm="requestToBeNotifiedFormDef.form" :validation.sync="requestToBeNotifiedFormDef.validation" />
    </div>
    <div class="buttons-holder">
      <RouterLink v-if="showRequestToBeNotifiedForm" to="/registered-successfully">
        <VButton :loading="requestManager.anyPending" :disabled="validation && validation.$invalid">Continue</VButton>
      </RouterLink>
      <VButton
        v-else
        @click="emit('proceed')"
        :loading="requestManager.anyPending"
        :disabled="validation && validation.$invalid"
      >
        Continue
      </VButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-block {
  padding: 0.88em 1.75em;
  margin-top: 2em;
}

.buttons-holder {
  margin: 2rem 0 5rem;
  padding: 10em 3em;

  .btn {
    border-radius: 6px;
    width: 100%;
    border: 1px solid getColor($color-primary);
    &:disabled {
      cursor: not-allowed;
      background: getColor($color-box-bg);
      color: getColor($color-primary);
    }
  }
}
</style>
