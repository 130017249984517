<script setup lang="ts">
import CreditAndMarkToMarketManagement from 'ah-trades/src/components/creditAndMarkToMarket/CreditAndMarkToMarketManagement.vue';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useRouter } from 'vue-router/composables';

defineProps({
  /**
   * Client ID to display credit and mark-to-market for, if any (CreditAndMarkToMarketManagement will default to contextual owner id otherwise)
   */
  clientId: {
    type: String,
  },
});

const onBehalfOfClient = useOnBehalfOf();

const router = useRouter();

/**
 * We are using onBehalfOfClient.value?.id instead of props.clientId because the go back button
 * will only show when we are acting on behalf of, thus is to keep the consistency
 */
function goBack() {
  router.push('/admin/activity/clients/obo/' + onBehalfOfClient.value?.id);
}
</script>

<template>
  <div class="padded-space credit-and-mtm-view">
    <h2>Credit Limits and Mark-to-Market</h2>
    <BackButton v-if="onBehalfOfClient" label="go back" @click="goBack()" small />
    <CreditAndMarkToMarketManagement :client-id="clientId" />
  </div>
</template>

<style lang="scss" scoped>
.credit-and-mtm-view {
  font-family: $font-family-inter;

  h2 {
    margin-bottom: 0;
  }
}
</style>
