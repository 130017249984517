<script lang="ts">
import { Component, Watch } from 'vue-property-decorator';
import TextFormField from './TextFormField.vue';
import { formatNumberValue } from 'ah-common-lib/src/helpers/numbers';

@Component
export default class BasisPointsFormField extends TextFormField<number> {
  $refs!: {
    textInput: HTMLInputElement;
  };

  private readonly percentageToBasisConversion = 100;

  savedStringVal = '';

  setValue(value: string) {
    let val: number | undefined = undefined;

    if (!isNaN(this.stringToVal(value))) {
      val = this.stringToVal(value.toString());
    }
    this.savedStringVal = value;
    setTimeout(() => {
      const typedVal = val !== undefined && val > -1 ? this.valToString(val) : '';
      if (this.stringToVal(value.toString(), false) !== this.stringToVal(typedVal, false)) {
        this.$refs.textInput.value = formatNumberValue(typedVal, this.maxDecimalPlaces);
      } else {
        this.$refs.textInput.value = formatNumberValue(value, this.maxDecimalPlaces);
      }
    });

    if (val !== this.field.$model) {
      this.$emit('set-value', val, !this.field.$dirty && !this.dirtyOnInput);
    }
  }

  // Used by textformfield
  get stringVal() {
    return formatNumberValue(this.savedStringVal, this.maxDecimalPlaces);
  }

  // Max decimal places allowed
  get maxDecimalPlaces() {
    return this.model.$state.unit === 'percentage' ? 2 : 0;
  }

  /**
   * We always do the percentage to basis conversation and only convert to basis value to
   * ensure the 2 decimal places maximum
   */
  private stringToVal(value: string, capValue = true) {
    try {
      let val = parseFloat(value) * this.percentageToBasisConversion;
      if (capValue) {
        val = Math.floor(val);
      }
      if (this.model.$state.unit !== 'percentage') {
        val /= this.percentageToBasisConversion;
      }
      return val;
    } catch (e) {
      return NaN;
    }
  }

  private valToString(value: number) {
    if (this.model.$state.unit === 'percentage') {
      return (value / this.percentageToBasisConversion).toFixed(this.maxDecimalPlaces);
    }
    return value.toFixed(this.maxDecimalPlaces);
  }

  @Watch('field.$model', { immediate: true })
  @Watch('model.$state.unit', { immediate: true })
  calcStringVal() {
    const val = this.field.$model;
    const savedVal = this.stringToVal(this.savedStringVal);

    if ((!Number.isNaN(val) && val !== savedVal) || val === 0) {
      this.savedStringVal = this.valToString(val);
    }
  }
}
</script>
<template src="./TextFormField.template.vue" />
