<script setup lang="ts">
import { computed, PropType, ref } from 'vue';
import InfoBlock, { InformationBlock } from 'ah-common-lib/src/common/components/InfoBlock.vue';
import { FormEvent } from 'ah-common-lib/src/form/interfaces';
import { setState, toDataModel } from 'ah-common-lib/src/form/helpers';
import { IndividualType, OnboardingIndividualInfo } from 'ah-api-gateways';
import ProposedUserPermissionsForm from '@/app/components/registration/forms/company/ProposedUserPermissionsForm.vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import {
  AdditionalUserFormObj,
  useRegistrationIndividuals,
} from '../../registration/forms/company/useRegistrationIndividuals';
import { useToast } from 'ah-common-lib/src/toast';
import { useTheme } from 'ah-theme';
import { helpers } from '@vuelidate/validators';
import { makeMemberForm } from './reviewForms';

const theme = useTheme();

const props = defineProps({
  editable: {
    type: Boolean,
    default: true,
  },
  model: {
    type: Array as PropType<OnboardingIndividualInfo[]>,
    required: true,
  },
});

const emit = defineEmits<{ (e: 'update:model', value: OnboardingIndividualInfo[]): void }>();

const requestManager = useRequestManager().manager;

const individuals = computed(() => props.model);

const toast = useToast();

const differentFromIndividuals = helpers.withParams(
  { type: 'differentFromIndividuals' },
  (val: any) => !registrationIndividuals.isDuplicatedEmail(val)
);

const differentFromApplicant = helpers.withParams(
  { type: 'differentFromApplicant' },
  (val: any) => registrationIndividuals.applicantEmail.value !== val
);

const differentFromOwner = helpers.withParams(
  { type: 'differentFromOwner' },
  (val: any) => registrationIndividuals.ownerEmail.value !== val
);

const differentFromSecondaryOwner = helpers.withParams(
  { type: 'differentFromSecondaryOwner' },
  (val: any) => registrationIndividuals.secondaryOwnerEmail.value !== val
);

function ownerEmailValidations() {
  return { differentFromApplicant, differentFromIndividuals, differentFromSecondaryOwner };
}

function secondaryOwnerEmailValidations() {
  return { differentFromApplicant, differentFromIndividuals, differentFromOwner };
}

function otherEmailValidations() {
  return { differentFromApplicant, differentFromIndividuals, differentFromSecondaryOwner, differentFromOwner };
}

const registrationIndividuals = useRegistrationIndividuals({
  requestManager,
  individuals,
  userFormFactory: (individual) => {
    if (individual?.owner) {
      return makeMemberForm(true, ownerEmailValidations());
    } else if (individual?.secondaryOwner) {
      return makeMemberForm(true, secondaryOwnerEmailValidations());
    }
    return makeMemberForm(false, otherEmailValidations());
  },
});

const editing = ref(false);

const partnerName = computed(() => theme?.val?.name || '');

const applicantIsLabel = computed(() =>
  registrationIndividuals.isApplicantOwner.value ? 'Authorised Signatory' : 'Employee'
);

const representativeBlock = ref<InformationBlock[]>([
  {
    label: `${registrationIndividuals.applicant.value?.individual.firstName ?? 'Applicant'} is...`,
    key: 'is',
    value: applicantIsLabel.value,
    cols: 4,
  },
  {
    label: 'Requested Permissions',
    key: 'permissions',
    value:
      registrationIndividuals.applicant.value?.individual.type === IndividualType.CLIENT_ADMIN ? 'Superuser' : 'Custom',
    cols: 4,
  },
]);

const userBlock = ref<InformationBlock[]>([
  { label: 'Title', key: 'title', cols: 4 },
  { label: 'Name', key: 'firstName', cols: 4 },
  { label: 'Surname', key: 'lastName', cols: 4 },
  { label: 'Email Address', key: 'email', cols: 4 },
  { label: 'Mobile Number', key: 'phoneNumber', cols: 4 },
  { label: 'Requested Permissions', key: 'permissions', cols: 4 },
]);

const isEditable = computed(() => props.editable !== false);

function save() {
  const valid = registrationIndividuals.submitForms(registrationIndividuals.additionalUsers.value);
  if (valid) {
    registrationIndividuals.saveEntries().subscribe(() => {
      editing.value = false;
      emit(
        'update:model',
        registrationIndividuals.individualEntries.value.map((i) => i.individual)
      );
    });
  } else {
    toast.error('Form contains errors! Please review and resubmit.');
  }
}

function hasAnyPermissionsSet(individual: OnboardingIndividualInfo) {
  return (individual.proposedPermissions?.findIndex((p) => p.allow) ?? -1) >= 0;
}

function toggleEditing() {
  editing.value = !editing.value;

  if (!editing.value) {
    registrationIndividuals.resetEntries();
  }
}

function onUserFormEvent(event: FormEvent, userForm: AdditionalUserFormObj) {
  if (event.event === 'form-field-set-value') {
    setState(userForm.form, 'errors', [], true);
    registrationIndividuals.updateEntry(
      {
        ...userForm.individual,
        ...toDataModel(userForm.form),
      },
      userForm
    );
  }
}
</script>

<template>
  <div class="card-block" x-test-name="client-members-review">
    <div class="card-review-header">
      <h2>Authorised Signatory & Users</h2>
      <div class="button-holder" v-if="isEditable">
        <VButton blurOnClick @click="toggleEditing" class="btn-stroked">
          {{ editing ? 'Cancel' : 'Edit' }}
        </VButton>
        <VButton blurOnClick @click="save" v-if="editing" :loading="requestManager.anyPending" class="ml-3">
          Save
        </VButton>
      </div>
    </div>
    <InfoBlock emptyValue="-" :model="individuals" :info="representativeBlock" />
    <template v-if="!editing">
      <h3 class="my-3">Authorised Signatory</h3>
      <InfoBlock
        emptyValue="-"
        :model="registrationIndividuals.owner.value.individual"
        :info="userBlock"
        v-if="registrationIndividuals.owner.value"
      >
        <template #permissions-value> Superuser </template>
      </InfoBlock>
      <template v-if="registrationIndividuals.secondaryOwner.value">
        <h3 class="my-3">Secondary Authorised Signatory</h3>
        <InfoBlock
          emptyValue="-"
          :model="registrationIndividuals.secondaryOwner.value.individual"
          :info="userBlock"
          v-if="registrationIndividuals.secondaryOwner.value"
        >
          <template #permissions-value> Superuser </template>
        </InfoBlock>
      </template>
      <h3 class="my-3">Proposed Additional Users</h3>
      <div v-for="(entry, index) in registrationIndividuals.additionalUsers.value" :key="index">
        <InfoBlock emptyValue="-" :model="entry.individual" :info="userBlock">
          <template #permissions-value>
            <template v-if="entry.individual.type === IndividualType.CLIENT_ADMIN"> Superuser </template>
            <template v-else-if="hasAnyPermissionsSet(entry.individual)"> Custom </template>
            <template v-else> None </template>
          </template>
        </InfoBlock>
      </div>
      <div v-if="registrationIndividuals.additionalUsers.value.length === 0">No additional users added.</div>
    </template>
    <template v-else>
      <h3 class="my-3">Secondary Authorised Signatory</h3>
      <template v-if="registrationIndividuals.secondaryOwner.value">
        <ValidatedForm
          :fm="registrationIndividuals.secondaryOwner.value.form"
          :validation.sync="registrationIndividuals.secondaryOwner.value.validation"
          @form-event="onUserFormEvent($event, registrationIndividuals.secondaryOwner.value)"
        />
        <div class="buttons-holder px-0 pt-3 text-center">
          <VButton
            @click="
              registrationIndividuals.secondaryOwner.value.individual.id &&
                registrationIndividuals.removeEntry(registrationIndividuals.secondaryOwner.value.individual.id)
            "
            class="btn-danger-secondary mb-3"
            blurOnClick
          >
            Remove Secondary Authorised Signatory
          </VButton>
        </div>
      </template>
      <template v-else>
        <p class="text-center font-italic text-secondary">No Secondary Authorised Signatory added.</p>
        <div class="buttons-holder px-0 pt-3 text-center">
          <VButton
            @click="registrationIndividuals.addEntry({ secondaryOwner: true })"
            class="btn-stroked mb-3"
            blurOnClick
          >
            Add Secondary Authorised Signatory
          </VButton>
        </div>
      </template>
      <h3 class="my-3">Proposed Additional Users</h3>

      <div
        v-for="(entry, index) in registrationIndividuals.additionalUsers.value"
        :key="`${entry.individual.id}-${index}`"
        class="additional-user-form"
      >
        <ValidatedForm
          :fm="entry.form"
          :validation.sync="entry.validation"
          @form-event="onUserFormEvent($event, entry)"
        >
          <template #memberForm.permissions>
            <ProposedUserPermissionsForm
              :individual="entry.individual"
              @update:individual="registrationIndividuals.updateEntry($event, entry)"
              :authorities="registrationIndividuals.authorities.value"
            />
          </template>
        </ValidatedForm>
        <div class="buttons-holder px-0 pt-3 text-center">
          <VButton
            @click="entry.individual.id && registrationIndividuals.removeEntry(entry.individual.id)"
            class="btn-danger-secondary mb-3"
            blurOnClick
          >
            Remove User
          </VButton>
        </div>
      </div>

      <div class="buttons-holder px-0 pt-3 text-center">
        <VButton
          @click="registrationIndividuals.addEntry"
          :disabled="registrationIndividuals.maxUsersAllowed.value"
          class="btn-stroked"
          blurOnClick
        >
          Add another User
        </VButton>

        <div v-if="registrationIndividuals.maxUsersAllowed.value" class="info-text text-muted text-small mt-2">
          *Only three proposed additional users can be created using this form. To add more users please contact
          {{ partnerName ? `the ${partnerName}` : 'our' }} support team.
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.card-review-header {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;

  h2 {
    font-size: $h3-font-size;
    margin-bottom: 0;
  }

  .button-holder {
    margin-left: auto;
    display: inline-flex;
  }
  .btn {
    min-width: 7rem;
  }
}

h3 {
  font-weight: $font-weight-semibold;
}

.additional-user-form {
  @include themedBorderColor($color-box-border);
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
</style>
