<script setup lang="ts">
import { computed, ref } from 'vue';
import { BModal } from 'bootstrap-vue';
import FileExportsList from './FileExportsList.vue';
import { useNotificationState } from '../..';

const modal = ref<InstanceType<typeof BModal> | null>(null);

const notificationState = useNotificationState();

const anyUnread = computed(() => notificationState.store.useNotificationsStore().fileExportUnreadCount > 0);

function show() {
  modal.value?.show();
}

function hide() {
  modal.value?.hide();
}

function markAllAsRead() {
  notificationState.store.useNotificationsStore().markAllAsRead('fileExports');
}
</script>

<template>
  <span>
    <BModal modal-class="side-modal" ref="modal" hide-footer hide-header>
      <div class="files-wrapper">
        <div class="list-header">
          <span class="modal-title">Downloads</span>
          <span class="modal-close-icon" @click="hide">
            <CloseIcon />
          </span>
        </div>
        <div class="list-wrapper">
          <FileExportsList class="file-exports-list" />
        </div>
        <div v-if="anyUnread" class="list-footer">
          <VButton blurOnClick @click="markAllAsRead()" class="mark-as-read btn-stroked"> Mark all as read </VButton>
        </div>
      </div>
    </BModal>
    <slot v-bind="{ show }"> </slot>
  </span>
</template>

<style lang="scss" scoped>
::v-deep .modal.side-modal .modal-dialog .modal-content .modal-body {
  padding-right: 1em;
  padding-left: 1em;
}

.files-wrapper {
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  height: 100%;

  .list-header {
    position: relative;
    @include themedPropColor('border-bottom', $color-primary, $color-dark-primary, '1px solid ');
    margin-bottom: 4px;

    .modal-title {
      @include themedTextColor($color-primary);
    }

    .modal-close-icon {
      position: absolute;
      bottom: 0.6em;
      right: 0;
      cursor: pointer;

      &:hover ::v-deep path {
        fill: getColor($color-text);
      }

      ::v-deep path {
        fill: getColor($color-text-secondary);
      }
    }
  }

  .list-wrapper {
    overflow: auto;
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .list-footer {
    @include themedPropColor('border-top', $color-primary, $color-dark-primary, '1px solid ');
    margin-top: 4px;
    text-align: center;
    padding: 1em;
  }
}

.modal-title {
  font-size: 1.5em;
  padding-right: 1.5em;
  font-weight: 600;
}

.all-read {
  @include themedTextColor($color-primary-light);
  font-size: 0.7em;
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  @include upToResolution($tabletResolution) {
    margin-left: -12px;
  }
}

.date-label {
  font-size: 0.7em;
  font-weight: 700;
}

.file-exports-list {
  width: 400px;
}
</style>
