<template>
  <a target="_blank" href="https://www.alt21.com/wp-content/uploads/2021/06/ALT21_Terms_and_Conditions_June_2023.pdf">
    Terms and Conditions
  </a>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class TermsAndConditionsLink extends Vue {}
</script>
