<script setup lang="ts">
import { computed } from 'vue';
import { Notification, NotificationType } from 'ah-api-gateways';
import FileExportCard from './FileExportCard.vue';
import { formatDistance, isSameDay, isToday, isYesterday } from 'date-fns';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useNotificationState } from '../..';

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: onRetryFromParentManager,
});

const notificationState = useNotificationState();

const notifications = computed(() => notificationState.store.useNotificationsStore().fileExportNotifications);

const hasMoreNotifications = computed(
  () => notificationState.store.useNotificationsStore().fileCount > notifications.value.length
);

const noNotifications = computed(() => !notifications.value || notifications.value.length === 0);

function onRetryFromParentManager(k: string) {
  if (k === 'loadNotifications') {
    loadMoreItems();
  }
}

function onFileDownload(item: Notification) {
  requestManager.manager
    .currentOrNew(
      `download-${item.id}`,
      notificationState.services.documents.downloadSyncDocument(item.resourceOrigin!, item.resource!)
    )
    .subscribe();
}

function onInfiniteLoad(intersection: IntersectionObserverEntry) {
  if (intersection.isIntersecting) {
    loadMoreItems();
  }
}

function isItemDownloading(item: Notification) {
  return requestManager.manager.requestStates[`download-${item.id}`] === 'pending';
}

function isSameDayAs(a: Notification, b: Notification) {
  return isSameDay(new Date(a.createdAt), new Date(b.createdAt));
}

function loadMoreItems() {
  requestManager.manager.newPromise(
    'loadNotifications',
    notificationState.store.useNotificationsStore().loadFileNotifications()
  );
}

function markAsRead(notification: Notification) {
  notificationState.store.useNotificationsStore().markAsRead(notification);
}

function formatDate(date: string) {
  if (isToday(new Date(date))) {
    return 'TODAY';
  } else if (isYesterday(new Date(date))) {
    return 'YESTERDAY';
  }

  return formatDistance(new Date(date), new Date(), { addSuffix: true });
}

if (notifications.value.length === 0) {
  notificationState.store.useNotificationsStore().loadFileNotifications();
}
</script>

<template>
  <div class="notifications-list">
    <div v-for="(item, index) in notifications" :key="item.id">
      <p v-if="index === 0 || !isSameDayAs(notifications[index - 1], item)" class="date-label text-secondary d-block">
        {{ formatDate(item.createdAt) }}
      </p>
      <FileExportCard
        :notification="item"
        @mark-as-read="markAsRead(item)"
        @open-resource="onFileDownload"
        :loading="isItemDownloading(item)"
        :showOpenResource="item.type === NotificationType.DOCUMENT_STATUS_EXPORTED"
      >
        <template #open-resource-text> <IconDownload /> Download file </template>
      </FileExportCard>
    </div>
    <div
      v-if="!noNotifications && hasMoreNotifications"
      v-on-intersect="onInfiniteLoad"
      class="loader d-flex justify-content-center"
    >
      <LoadingIcon v-if="requestManager.manager.anyPending" class="loading-icon" />
    </div>
    <LoadingIcon v-if="noNotifications && requestManager.manager.anyPending" class="loading-icon" />
    <div v-else-if="noNotifications" class="no-notifications text-center">No documents found.</div>
  </div>
</template>

<style lang="scss" scoped>
.loading-icon {
  width: 1.5em;
  height: 1.5em;
}

.date-label {
  font-size: 0.7em;
  font-weight: 700;
}
</style>
