<template>
  <div class="group-member">
    <div class="member-data">
      <div class="row align-v-center">
        <div class="col-md-10 col-sm-7">
          <h4 class="title">{{ assigneeRef.individual.firstName }} {{ assigneeRef.individual.lastName }}</h4>
        </div>
        <div v-if="!$mediaQuery.is('smDown')" class="col-md-2 side-icons">
          <BDropdown variant="transparent" no-caret inline toggle-class="menu-button primary" right>
            <template #button-content><IconDots /></template>
            <div class="popover-container dropdown-menu-block">
              <div class="popover-section">
                <ul>
                  <li v-if="assigneeRef.primary === false" @click="$emit('set-primary')" class="dropdown-font">
                    <IconUser /> MAKE PRIMARY ASSIGNEE
                  </li>
                  <li v-if="assigneeRef.primary === true" @click="$emit('remove-primary')" class="dropdown-font">
                    <IconUser /> REMOVE PRIMARY ASSIGNEE
                  </li>
                  <li @click="$emit('delete')" class="dropdown-font"><IconTrash /> DELETE</li>
                </ul>
              </div>
            </div>
          </BDropdown>
        </div>
      </div>
      <div v-if="$mediaQuery.is('smDown')" class="mobile-icons">
        <span @click="$emit('delete')" class="icon px-2 py-1 mr-2"> <IconTrash /> </span>
        <span v-if="assigneeRef.primary === false" @click="$emit('set-primary')" class="icon p-1">
          <IconUser /> <ArrowTopIcon class="arrow" />
        </span>
        <span v-if="assigneeRef.primary === true" @click="$emit('remove-primary')" class="icon p-1">
          <IconUser /> <ArrowTopIcon class="arrow inverted" />
        </span>
      </div>
      <div class="individual-info">
        <p class="email" v-if="assigneeRef.individual.email">{{ assigneeRef.individual.email }}</p>
        <p class="phone-number" v-if="assigneeRef.individual.phoneNumber">
          {{ getPhoneNumber(assigneeRef.individual.phoneNumber) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { AssigneeReference } from 'ah-api-gateways';
import { getPhoneNumber } from 'ah-common-lib/src/helpers/calls';

@Component
export default class ClientAssigneeBlock extends Vue {
  @Prop({ required: true }) assigneeRef!: AssigneeReference;

  getPhoneNumber = getPhoneNumber;
}
</script>
<style lang="scss" scoped>
.group-member {
  display: inline-block;
  position: relative;
  border-radius: $box-border-radius;
  padding: math.div($padded-space, 3);

  &:hover {
    @include themedBackgroundColor($color-highlight-grey);
    .side-icons {
      opacity: 1;
    }
  }

  .member-data {
    h4.title {
      font-size: 14px;
      font-weight: 400;
    }

    .individual-info {
      p {
        margin-bottom: 0;
        font-size: 10px;
        line-height: 13px;
        min-height: 13px;
        @include themedTextColor($color-text-secondary);
      }
    }
  }

  .side-icons {
    opacity: 0;
  }
}

::v-deep .menu-button {
  @include themedTextColor($color-text-secondary);
  @include themedBackgroundColor($color-primary);
  padding: 0 0.3em;
  svg {
    height: 0.3em;
    width: 1em;
  }
}
.dropdown-font {
  font-size: 0.65em;
}

::v-deep .dropdown-menu {
  background: transparent;
  padding: 0;
}

::v-deep .dropdown-menu-block ul li {
  padding: 11px 9px;
}

.popover-container {
  float: left;
  position: relative;
  top: 0em;
  border-radius: $user-menu-radius;
  padding: 0px;
  @include themedTextColor($color-text-secondary);
}

.mobile-icons {
  position: absolute;
  right: 0;
  top: 1rem;
  .icon {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    @include themedPropColor($color-text, $color-dark-text);
    @include themedBackgroundColor($color-dark-primary, $color-primary);
    ::v-deep .arrow {
      margin-left: -10px;
      margin-right: -5px;
      font-size: 24px;
      fill: white;
      &.inverted {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
