<script setup lang="ts">
import WalletListing from 'ah-wallets/src/components/WalletListing.vue';
import WalletInfo from 'ah-wallets/src/components/WalletInfo.vue';
import CreateTradeModal from 'ah-trades/src/components/modals/CreateTradeModal.vue';
import AddMoneyModal from 'ah-wallets/src/components/AddMoneyModal.vue';
import { AnimatedListingWithSubViewState } from '@/app/components/common/animation/AnimatedListingWithSubView.vue';
import InfoTooltip from '@/app/components/common/InfoTooltip.vue';
import { AuthorityType, Wallet, Client } from 'ah-api-gateways';
import OnBehalfOf from 'ah-common-lib/src/onBehalfOf/OnBehalfOf';
import { TableFieldDefinition } from 'ah-common-lib/src/models';
import { DEFAULT_INACTIVITY_TIMEOUT } from 'ah-common-lib/src/constants/time';
import { useAuthStore } from '@/app/store/authStore';
import { useNotificationsStore } from 'ah-notifications/src/store';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { getServices } from '@/app/services';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

const walletListTableFields: TableFieldDefinition[] = [
  {
    header: 'Currency',
    key: 'currency',
  },
  {
    header: '',
    key: 'inTransit',
  },
  {
    header: 'Balance',
    key: 'balance',
  },
  {
    header: 'Fee Warning',
    key: 'feeWarning',
    colStyle: { width: '2em' },
    class: 'fee-warning',
  },
];

const walletListNarrowTableFields: TableFieldDefinition[] = [
  {
    header: 'Currency',
    key: 'currency',
  },
  {
    header: '',
    key: 'inTransit',
  },
  {
    header: 'Fee Warning',
    key: 'feeWarning',
    colStyle: { width: '2em' },
  },
];

const router = useRouter();

const route = useRoute();

const services = getServices();

const requestManager = useRequestManager().manager;

const authStore = useAuthStore();

const props = defineProps({
  clientId: { type: String, required: false },
  walletId: { type: String, required: false },
});

const onBehalfOfClient = ref<Client | null>(null);

const animatedState = ref<AnimatedListingWithSubViewState | null>(null);

const selectedWallet = ref<Wallet | null>(null);

const walletListing = ref<InstanceType<typeof WalletListing>>();

const walletInfo = ref<InstanceType<typeof WalletInfo>>();

const refreshComponentTimeout = ref<number | null>(null);

onMounted(() => refreshComponent());

function refreshComponent() {
  refreshComponentTimeout.value = window.setInterval(() => {
    walletListing.value!.loadData();
  }, DEFAULT_INACTIVITY_TIMEOUT);
}

function onClientIdChange() {
  if (props.clientId) {
    requestManager
      .sameOrCancelAndNew(`getClient-${props.clientId}`, services.client.getClient(props.clientId))
      .subscribe((response) => (onBehalfOfClient.value = response));
  }
}

watch(() => props.clientId, onClientIdChange, { immediate: true });

function onTradeCompleted() {
  if (walletInfo.value) {
    walletInfo.value.refresh();
  }
}

const narrowWalletListing = computed(() => !animatedState.value!.wideList);
const tableFields = computed(() => (narrowWalletListing.value ? walletListNarrowTableFields : walletListTableFields));
const isAgent = computed(() => authStore.isAgent);
const openTradeAllowed = computed(() => authStore.hasAuthorities(AuthorityType.OPEN_TRADE));
const requestPricesAllowed = computed(() => authStore.hasAuthorities(AuthorityType.REQUEST_PRICES));
const selectedCurrency = computed(() => (selectedWallet.value ? selectedWallet.value.currency : undefined));
const readonly = computed(() => route.meta?.readonly || false);

function onWalletChanged() {
  if (walletListing.value) {
    walletListing.value.loadData();
  }
}

function goToWallet(wallet?: Wallet) {
  const baseUrl = isAgent.value
    ? props.clientId
      ? `/admin/activity/clients/${props.clientId}/`
      : '/admin/'
    : '/dashboard/';

  if (!wallet || props.walletId === wallet.id) {
    router.push({ path: `${baseUrl}wallets` });
    selectedWallet.value = null;
  } else {
    router.push({ path: `${baseUrl}wallets/${wallet.id}` });
    selectedWallet.value = wallet;
  }
}

function downloadBalanceCertificate() {
  requestManager
    .cancelAndNew('downloadBalanceCerticate', services.wallet.downloadBalanceCertificate(props.clientId))
    .subscribe((doc) => useNotificationsStore().triggerFileExportRequestNotification(doc));
}

function openModal(show: Function) {
  authStore.checkComplianceApproval({ showModal: true, client: onBehalfOfClient.value! }).then(() => {
    show();
  });
}

onUnmounted(() => {
  if (refreshComponentTimeout.value !== null) clearInterval(refreshComponentTimeout.value);
});
</script>

<template>
  <OnBehalfOf :clientId="clientId">
    <div class="padded-space" id="wallets-view">
      <!-- TITLE -->
      <div class="d-flex">
        <div class="w-100">
          <h2>
            <span class="content">Wallets</span>
            <InfoTooltip :text="$t('tooltips.wallets')" />
            <div v-if="!$mediaQuery.is('smDown')" class="d-md-inline float-md-right float-lg-none">
              <CreateTradeModal
                v-slot="{ show }"
                @flow-completed="onTradeCompleted"
                v-if="openTradeAllowed && requestPricesAllowed && !isAgent"
                forceImmediateSpot
                syncTradeFunds
                forceKeep
                title="Exchange between wallets"
              >
                <VButton @click="show" class="title-button ml-2">Exchange</VButton>
              </CreateTradeModal>
              <AddMoneyModal
                v-if="clientId || !isAgent"
                :clientId="clientId"
                :currency="selectedCurrency"
                v-slot="{ show }"
              >
                <VButton @click="openModal(show)" class="title-button ml-2">Fund Account</VButton>
              </AddMoneyModal>
            </div>
            <div class="title-button float-right" v-if="!readonly">
              <VButton
                :loading="requestManager.requestStates.downloadBalanceCerticate === 'pending'"
                @click="downloadBalanceCertificate"
                >Download Balance Certificate</VButton
              >
            </div>
          </h2>
        </div>
      </div>

      <AnimatedListingWithSubView
        :wideListBlockProps="{ cols: 6, sm: 12, md: 6, lg: 4 }"
        :narrowListBlockProps="{ cols: 3, lg: 4 }"
        :infoBlockProps="{ cols: 9, sm: 12, lg: 8 }"
        :showInfo="!!walletId"
        :animatedState.sync="animatedState"
        :listingBlockClass="animatedState && !animatedState.wideList ? 'd-sm-none d-lg-block' : 'd-block'"
      >
        <template #listing>
          <WalletListing
            ref="walletListing"
            withRowSubView
            :sortAndPageParams="{ sort: 'currency', sortDirection: 'ASC' }"
            :narrow="narrowWalletListing && $mediaQuery.is('mdDown')"
            :config="{ tableFields }"
            @row-clicked="goToWallet"
            :selectedItems="props.walletId ? [props.walletId] : []"
          />
        </template>
        <template #sub-view>
          <WalletInfo
            v-if="props.walletId"
            ref="walletInfo"
            :walletId="props.walletId"
            @wallet-changed="onWalletChanged"
            @back-to-list="goToWallet()"
            :readonly="readonly"
          />
        </template>
      </AnimatedListingWithSubView>
    </div>
  </OnBehalfOf>
</template>

<style lang="scss" scoped>
.title-button {
  font-size: 1rem;

  @include upToResolution($tabletResolution) {
    font-size: $font-size-sm;
    margin-bottom: 1rem;
  }
}
</style>
