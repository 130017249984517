import {
  DocumentExport,
  ExportListType,
  ListQuery,
  PaginatedParams,
  PaginatedQuery,
  PaginatedResponse,
} from 'ah-api-gateways';
import { HttpError, RequestManager, RequestState } from 'ah-requests';
import { Ref, PropType, ExtractPropTypes, ToRefs, PropOptions } from 'vue';
import { Observable } from 'rxjs';
import { EditTableMode, TableFieldDefinition } from '../models';
import { QueryChangeMethod } from '../helpers/useQueryParam';
import { ManagedComposableOptions, ManagedComposableProps } from '../helpers/managedComposable';

export interface DataTransforms<F extends Object = {}> {
  /**
   * Filter transformation function - this will transform filters before they are saved, and will affect things link url-based persistence.
   * May be overwritten in components extending Listing - by default, does no transformation to the filter;
   */
  filterTransform?: (filterIn: F) => F;

  /**
   * Query transformation function - this will transform the query prior to making the request, and will NOT affect things link url-based persistence.
   * May be overwritten in components extending Listing - by default, does no transformation to the query;
   */
  queryTransform?: (query: PaginatedQuery, forDownload: boolean) => ListQuery;
}

/**
 * Prop definition object builder for managedListing props
 *
 * Use the object builder (or the helper function computeExposedProps) to expose props
 * in any component that uses this composable:
 *
 *   import { defineUseManagedListingProps } from 'ah-common-lib/src/listing';
 *   const props = defineProps({
 *     ...defineUseManagedListingProps<User>(),
 *   });
 *
 * Returns ComponentObjectPropsOptions
 */
export function defineUseManagedListingProps<T, F extends object = {}>(): ManagedComposableProps<{
  filter: F | undefined;
  sortAndPageParams: Partial<PaginatedParams> | undefined;
  tableData: Partial<PaginatedResponse<T>> | undefined;
  filterQueryParam: string | undefined;
  paginationQueryParam: string | undefined;
  queryChangeMethod: QueryChangeMethod | undefined;
  hideDownloadButton: boolean | undefined;
  selectedItems: string[] | undefined;
  dataTransforms: DataTransforms<F> | undefined;
  primaryKey: string;
  editMode: EditTableMode;
  editedRows: T[];
}> {
  return {
    filter: { type: Object as PropType<F>, required: false },
    sortAndPageParams: { type: Object as PropType<Partial<PaginatedParams>>, required: false },
    tableData: { type: Object as PropType<Partial<PaginatedResponse<T>>>, required: false },
    filterQueryParam: { type: String, required: false },
    paginationQueryParam: { type: String, required: false },
    queryChangeMethod: { type: Object as PropType<QueryChangeMethod>, required: false },
    primaryKey: { type: String, default: 'id' },
    hideDownloadButton: { type: Boolean, required: false },
    selectedItems: { type: Array as PropType<string[]>, required: false },
    dataTransforms: { type: Object as PropType<DataTransforms<F>>, required: false },
    editMode: { type: String as PropType<EditTableMode>, default: EditTableMode.OFF },
    editedRows: { type: Array as PropType<T[]>, required: false },
  } satisfies Record<string, PropOptions>;
}

type UseManagedListingProps<T, F extends object = {}> = ReturnType<typeof defineUseManagedListingProps<T, F>>;

type UseManagedListingPropTypes<T, F extends object = {}> = ExtractPropTypes<UseManagedListingProps<T, F>>;

type UseManagedListingRefs<T, F extends object = {}> = ToRefs<UseManagedListingPropTypes<T, F>>;

export interface UseManagedListingOptions<T, F extends object = {}>
  extends ManagedComposableOptions<UseManagedListingProps<T, F>> {
  reqManager: RequestManager;
  fields: Ref<TableFieldDefinition[]>;
  emit?: UseManagedListingEmits<T, F>;

  /**
   * Props object, to map to refs
   *
   * If adding props:
   *    - `emit` is mandatory
   *    - props will be mapped to VModels
   *    - any refs directly defined in `refs` will overwrite this
   *    - all required refs must be defined, between `props` and `refs` objects
   */
  props?: Partial<UseManagedListingPropTypes<T, F>>;

  /**
   * Refs object
   *
   * If adding refs:
   *    - any refs defined will overwrite the equivalent `props` property, if set
   *    - all required refs must be defined, between `props` and `refs` objects
   */
  refs?: Partial<UseManagedListingRefs<T, F>>;

  /**
   * Request for downloading data
   *
   * If set and hideDownloadButton is falsy, download button will be shown
   *
   * FIXME move type to an export enum
   */
  downloadDataRequest?: (
    type: string,
    query: ListQuery,
    options?: { [key: string]: any }
  ) => Observable<DocumentExport | void>;
  /**
   * Request for loading data
   */
  loadDataRequest: (query: PaginatedQuery) => Observable<PaginatedResponse<T>>;
  saveEditedRowRequest?: (updatedEntry: T) => Observable<T>;
}

/**
 * Emits Interface
 *
 * NOTE
 * This interface needs to be extended to allow Type inference (unlike defineProps, which CANNOT use an imported interface, as it needs it to generate props),
 * a limitation relating to https://vuejs.org/guide/typescript/composition-api.html#typing-component-emits
 *
 * Example:
 * // DOES NOT WORK
 * import { UseManagedListingEmits } from 'ah-common-lib/src/listing';
 * const emit = defineEmits<UseManagedListingEmits>();
 *
 * // WORKS
 * import { UseManagedListingEmits } from 'ah-common-lib/src/listing';
 * interface UsersEmit extends UseManagedListingEmits {}
 * const emit = defineEmits<UsersEmit>();
 */
export interface UseManagedListingEmits<T, F extends object = {}> {
  /**
   * Emitted when data download request finishes. In async downloads, response is a Document object
   */
  (e: 'download-requested', value: DocumentExport): void;
  /**
   * Emitted when download request fails
   */
  (e: 'download-request-error', error: any): void;
  /**
   * Emitted when data finishes loading
   */
  (e: 'data-loaded'): void;
  /**
   * Emitted when data fails to load
   */
  (e: 'data-load-error', error: any): void;
  /**
   * Emited when data loading state changes. Can be used with .sync (prop is ignored)
   */
  (e: 'update:dataLoadState', value: RequestState): void;
  /**
   * Emmited when data downloading state changes. Can be used with .sync (prop is ignored)
   */
  (e: 'update:dataDownloadState', value: RequestState): void;
  /**
   * Emmited when `tableData` changes. Can be used with .sync
   */
  (e: 'update:tableData', value: Partial<PaginatedResponse<T>>): void;
  /**
   * Emmited when `filter` changes. Can be used with .sync
   */
  (e: 'update:filter', value: F): void;
  /**
   * Emmited when `sortAndPageParams` changes. Can be used with .sync
   */
  (e: 'update:sortAndPageParams', value: Partial<PaginatedParams>): void;
  /**
   * Emitted when a row cell is edited
   */
  (e: 'row-edited', value: T): void;
  /**
   * Emitted when the details of a row is selected
   */
  (e: 'row-details', value: T): void;
  /**
   * Emitted when a row cell is edited
   */
  (e: 'disable-save', value: boolean): void;
  /**
   * Emitted on load, exposing object with load and download trigger methods, as well as native listing request (for deep selection purposes). Can be used with .sync
   */
  (e: 'update:triggers', value: ListingTriggers<T>): void;
}

export type DownloadDataTrigger = (type: ExportListType, options?: { [key: string]: any }) => void;

export interface EditedRowsSaveProcess<T> {
  pendingItems: T[];
  savedItems: T[];
  erroredItems: { item: T; error: HttpError }[];
  state: 'idle' | 'pending' | 'done' | 'cancelled';
}

export interface ListingTriggers<T> {
  downloadData: DownloadDataTrigger;
  loadData: () => void;
  loadDataRequest: (query: PaginatedQuery) => Observable<PaginatedResponse<T>>;
  cancelLoadRequest: (key: 'loadData' | 'downloadData') => void;
  saveEditedRows: () => Observable<EditedRowsSaveProcess<T>>;
}
