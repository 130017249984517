<template>
  <BoxGrid alignH="start" class="change-contacts-form">
    <BoxGridBlock cols="12">
      <ValidatedForm :fm="companyDetailsFM" :validation.sync="companyDetailsValidation" />
    </BoxGridBlock>
    <BoxGridBlock cols="12">
      <h3>Registered Address</h3>
      <ValidatedForm :fm="addressDetailsFM" :validation.sync="addressDetailsValidation" />
    </BoxGridBlock>
    <BoxGridBlock cols="12">
      <h3>Trading Address</h3>
      <span class="edit-icon" v-if="canEditTradingAddress" @click="updating = true"> <IconPencil /> </span>
      <ValidatedForm :fm="tradingDetailsFM" :validation.sync="tradingDetailsValidation" />
      <template v-if="updating">
        <div>
          <VButton :disable="requestManager.anyPending" blurOnClick @click="cancel" class="btn btn-stroked mb-2">
            Cancel
          </VButton>
          <VButton :loading="requestManager.anyPending" blurOnClick @click="saveModel" class="btn"> Save </VButton>
        </div>
      </template>
    </BoxGridBlock>
  </BoxGrid>
</template>

<script lang="ts">
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { makeFormModel, toDataModel, updateModel } from 'ah-common-lib/src/form/helpers';
import { AuthorityType, Client } from 'ah-api-gateways';
import { companyDetailsReadForm, addressReadForm } from 'ah-common-lib/src/form/formModels';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { mergeMap, tap, catchError } from 'rxjs/operators';
import { waitForCQRSEntityChange } from 'ah-requests';
import { of } from 'rxjs';
import { FormValidation } from 'ah-common-lib/src/form/interfaces';
import { useSettingsStore } from '@/app/store/settingsModule';
import { countryNameFromCC } from 'ah-common-lib/src/helpers/countries';
import { useAuthStore } from '@/app/store/authStore';

const companyDetailsFormModel = () => makeFormModel(companyDetailsReadForm());

const addressDetailsFormModel = () => makeFormModel(addressReadForm());

const tradingDetailsFormModel = (editable = false) => makeFormModel(addressReadForm(editable));

/**
 * Client company details
 *
 * Emits:
 * - update:client (payload: Client): emmited when client trading details are updated
 */
@Component
export default class ClientCompanyDetailsEditor extends Mixins(WithRequestManager) {
  @Prop({ required: true }) client!: Client;

  private companyDetailsFM = companyDetailsFormModel();

  private addressDetailsFM = addressDetailsFormModel();

  private tradingDetailsFM = tradingDetailsFormModel();

  private companyDetailsValidation: FormValidation | null = null;

  private addressDetailsValidation: FormValidation | null = null;

  private tradingDetailsValidation: FormValidation | null = null;

  private updating = false;

  get settingsStore() {
    return useSettingsStore();
  }

  get authStore() {
    return useAuthStore();
  }

  beforeMount() {
    this.settingsStore.loadEntityTypes().then(() => this.updateFormModel());
  }

  get canEditClient() {
    return this.authStore.hasAuthorities(AuthorityType.UPDATE_CLIENT);
  }

  get canEditTradingAddress() {
    return !this.updating && this.canEditClient;
  }

  get calculatedModel() {
    const tradingAddress = {
      ...this.client?.companyDetails.tradingAddress,
      ...toDataModel(this.tradingDetailsFM),
    };

    return {
      tradingAddress,
    };
  }

  private cancel() {
    this.updateFormModel();
    this.updating = false;
  }

  private updateFormModel() {
    const entity = this.settingsStore.entityTypeList?.find(
      (item) => item.id === this.client.companyDetails.entityType
    )?.legalForm;

    updateModel(this.companyDetailsFM, {
      ...this.client.companyDetails,
      name: this.client.name,
      entityType: entity,
      reportingCurrency: this.client.reportingCurrency,
      countryCode: countryNameFromCC(this.client.companyDetails.address.countryCode),
    });
    updateModel(this.addressDetailsFM, this.client?.companyDetails.address || {});
    updateModel(this.tradingDetailsFM, this.client?.companyDetails.tradingAddress || {});
  }

  saveModel() {
    this.requestManager
      .sameOrCancelAndNew(
        'saveClient',
        this.$services.client.updateClient(this.client!.id, this.calculatedModel).pipe(
          mergeMap((idEntity) =>
            waitForCQRSEntityChange(idEntity, () =>
              this.$services.client.getClient(idEntity.id, false, { errors: { silent: true } })
            ).pipe(catchError(() => of(this.client)))
          ),
          tap((client) => {
            this.$emit('update:client', client);
            this.updating = false;
          })
        )
      )
      .subscribe();
  }

  @Watch('updating', { immediate: true })
  onUpdateChange() {
    this.tradingDetailsFM = tradingDetailsFormModel(this.updating);
    this.updateFormModel();
  }
}
</script>
<style lang="scss" scoped>
::v-deep .readonly-field {
  input {
    background: transparent !important;
    border: none;
    margin-left: -0.4rem;
    text-overflow: ellipsis;
  }
}

.btn {
  min-width: 6rem;
  margin-right: 1rem;
}

.edit-icon {
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 2rem;
}
</style>
