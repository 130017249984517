<script setup lang="ts">
import { computed, reactive } from 'vue';
import { ComplianceStatus, OnboardingStatusReport } from 'ah-api-gateways';
import { sum } from 'lodash';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadCounts') {
      loadCounts();
    }
  },
});

const state = reactive<{
  counts: OnboardingStatusReport[] | null;
}>({
  counts: null,
});

function loadCounts() {
  requestManager.manager.new('loadCounts', getServices().client.getClientOnboardingReportStatus()).subscribe((r) => {
    state.counts = r.summary;
  });
}

const total = computed(() => {
  if (!state.counts) return 0;
  return sum(state.counts.filter((c) => !!c.status).map((c) => c.total));
});

function findCount(status: ComplianceStatus) {
  if (!state.counts) return 0;
  return state.counts.find((c) => c.status === status)?.total || 0;
}

loadCounts();
</script>

<template>
  <div class="card-block">
    <div class="onboarding-statuses-wrapper">
      <LoadingOverlay
        variant="box"
        :state="requestManager.manager.requestStates.loadCounts"
        show-retry
        @retry="loadCounts"
      >
        <VRow class="onboarding-statuses text-nowrap" v-if="state.counts">
          <VCol md="1" sm="12" class="total status">
            <span class="status-label">Total:</span>
            {{ total }}
          </VCol>
          <VCol md="2" sm="6" class="pending status">
            <span class="status-dot" />
            <span class="status-label">Pending Questionnaire:</span>
            {{ findCount(ComplianceStatus.PENDING) }}
          </VCol>
          <VCol md="2" sm="6" class="incomplete status">
            <span class="status-dot" />
            <span class="status-label">Incomplete:</span>
            {{ findCount(ComplianceStatus.TERMS_AND_CONDITIONS) + findCount(ComplianceStatus.DOCUMENTS_REQUIRED) }}
          </VCol>
          <VCol md="2" sm="6" class="in-review status">
            <span class="status-dot" />
            <span class="status-label">In Review:</span>
            {{ findCount(ComplianceStatus.SUBMITTED) }}
          </VCol>
          <VCol md="2" sm="6" class="verified status">
            <span class="status-dot" />
            <span class="status-label">Approved:</span>
            {{ findCount(ComplianceStatus.APPROVED) }}
          </VCol>
          <VCol md="2" sm="6" class="rejected status">
            <span class="status-dot" />
            <span class="status-label">Rejected:</span>
            {{ findCount(ComplianceStatus.REJECTED) }}
          </VCol>
          <VCol md="2" sm="6" class="suspended status">
            <span class="status-dot" />
            <span class="status-label">Suspended:</span>
            {{ findCount(ComplianceStatus.SUSPENDED) }}
          </VCol>
          <VCol md="2" sm="6" class="deactivated status">
            <span class="status-dot" />
            <span class="status-label">Deactivated:</span>
            {{ findCount(ComplianceStatus.DEACTIVATED) }}
          </VCol>
          <VCol md="2" sm="6" class="tc-updated status">
            <span class="status-dot" />
            <span class="status-label">Updated T&C Outstanding:</span>
            {{ findCount(ComplianceStatus.UPDATED_TERMS_AND_CONDITIONS) }}
          </VCol>
        </VRow>
      </LoadingOverlay>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.onboarding-statuses-wrapper {
  .onboarding-statuses {
    display: flex;

    .status {
      @include tablet {
        &:not(.total) {
          text-align: center;
        }
      }
      padding: 0.5em 1em;
      @include tablet {
        border-right-style: solid;
        border-right-width: 1px;
        @include themedPropColor('border-right-color', $color-highlight-grey);
      }

      &:last-child {
        border-right: none;
      }

      .status-dot {
        background: white;
        width: 0.5em;
        height: 0.5em;
        display: inline-block;
        border-radius: 0.5em;
        margin-right: 0.3em;
      }

      .status-label {
        font-weight: 600;
      }

      &.total {
        @include tablet {
          min-width: 6.3rem;
        }
      }

      &.pending {
        @include tablet {
          min-width: 16rem;
        }
        .status-dot {
          @include themedBackgroundColor($color-blue-highlight);
        }
      }

      &.incomplete {
        @include tablet {
          min-width: 10rem;
        }
        .status-dot {
          @include themedBackgroundColor($color-orange-highlight);
        }
      }

      &.in-review {
        @include tablet {
          min-width: 9.5rem;
        }
        .status-dot {
          @include themedBackgroundColor($color-yellow-highlight);
        }
      }

      &.verified {
        @include tablet {
          min-width: 9rem;
        }
        .status-dot {
          @include themedBackgroundColor($color-green-highlight);
        }
      }

      &.rejected {
        @include tablet {
          min-width: 9rem;
        }
        .status-dot {
          @include themedBackgroundColor($color-danger);
        }
      }

      &.deactivated {
        @include tablet {
          min-width: 8rem;
        }
        .status-dot {
          @include themedBackgroundColor($color-lavender-highlight);
        }
      }

      &.suspended {
        @include tablet {
          min-width: 8rem;
        }
        .status-dot {
          @include themedBackgroundColor($color-pink-highlight);
        }
      }

      &.tc-updated {
        @include tablet {
          min-width: 9.5rem;
        }
        .status-dot {
          @include themedBackgroundColor($color-lavender-highlight-light);
        }
      }
    }
  }
}
</style>
