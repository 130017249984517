<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 2L2 14" stroke="currentColor" stroke-width="2.08696" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M2 2L14 14" stroke="currentColor" stroke-width="2.08696" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
