import { AssigneeReference, IndividualReference } from '../individual';
import { PartnerTrade, TradeState } from '.';
import { HedgingInstruments } from '../quote';
import { DeletableObject } from '../rest';

export enum DeltaDealState {
  AWAITING_FUNDS = 'AWAITING_FUNDS',
  FUNDS_ARRIVED = 'FUNDS_ARRIVED',
  TRADE_SETTLED = 'TRADE_SETTLED',
  CLOSED = 'CLOSED',
}

export interface TradeReport extends DeletableObject {
  id: string;
  referenceNumber: string;
  createdAt: string;
  partnerId: string;
  partnerName: string;
  clientId: string;
  clientName: string;
  hedgingProduct: HedgingInstruments;
  sellCurrency: string;
  sellAmount: number;
  buyCurrency: string;
  buyAmount: number;
  currencyPairDirection: string;
  currencyPair: string;
  state: TradeState;
  purposeType: string;
  bookedFxRate: number;
  settlementDate: string;
  markUpBasisPoints: number;
  commissionRatePercentage: number;
  gbpTradeRevenue: number;
  gbpTradeCosts: number;
  gbpImcc: number;
  gbpNetTradePnl: number;
  gbpInitialMargin: number;
  gbpVmm: number;
  gbpMarginInUse: number;

  premiumDate?: string;
  premiumCurrency?: string;
  premiumAmount?: number;
  isPremiumPaid?: boolean;
  spotRef?: number;
  claimType?: string;
  deltaDealId?: string;
  deltaDealState?: DeltaDealState;
  expiryDate?: string;
}

export interface TradeCommissionsReport {
  currency: string;
  tradedAmount: number;
  totalPartnerProfit: number;
  partnerProfit: number;
  partnerSwapPnl: number;
  partnerDrawdownPnl: number;
  ahProfit: number;
  dailyReports: {
    currency: string;
    tradedAmount: number;
    totalPartnerProfit: number;
    partnerProfit: number;
    partnerSwapPnl: number;
    partnerDrawdownPnl: number;
    date: string;
    ahProfit: number;
  }[];
}

export interface TradeCountReport {
  count: number;
  dailyReports: {
    date: string;
    count: number;
  }[];
}

export interface ClientMonthlyTradeCountReportEntry {
  date: string;
  count: number;
  hedgingProduct: string;
}

export interface ClientMonthlyTradeVolumeReportEntry {
  date: string;
  count: number;
  hedgingProduct: string;
  ahDrawdownPnl: number;
  ahProfit: number;
  ahSwapPnl: number;
  currency: string;
  partnerDrawdownPnl: number;
  partnerProfit: number;
  partnerSwapPnl: number;
  totalAhProfit: number;
  totalPartnerProfit: number;
  tradedAmount: number;
}

export interface TradeWithCommissionReport {
  id: string;
  trade: PartnerTrade;
  state: TradeState;
  currency: string;
  tradedAmount: number;
  totalPartnerProfit: number;
  partnerProfit: number;
  partnerSwapPnl: number;
  partnerDrawdownPnl: number;
  assignees: AssigneeReference[];
  agents: IndividualReference[];
  ahProfit: number;
  tradeId: string;
  homeCurrency: string;
}

export interface PartnerReport extends PartnerTrade {
  state: TradeState;
  currency: string;
  tradedAmount: number;
  totalPartnerProfit: number;
  partnerProfit: number;
  partnerSwapPnl: number;
  partnerDrawdownPnl: number;
  assignees: AssigneeReference[];
  agents: IndividualReference[];
  ahProfit: number;
  tradeId: string;
  homeCurrency: string;
}

export interface TopClientAccountsReportEntry {
  name: string;
  clientId: string;
  clientName: string;
  tradesNumber: number;
  currency: string;
  tradedAmount: number;
  totalPartnerProfit: number;
  partnerProfit: number;
  partnerSwapPnl: number;
  partnerDrawdownPnl: number;
}

export interface TopPartnerAccountsReportEntry {
  partnerId: string;
  partnerName: string;
  tradesNumber: number;
  currency: string;
  tradedAmount: number;
  totalAhProfit: number;
  totalPartnerProfit: number;
  ahProfit: number;
  partnerProfit: number;
  ahSwapPnl: number;
  partnerSwapPnl: number;
  ahDrawdownPnl: number;
  partnerDrawdownPnl: number;
}

export enum CommissionDisplayType {
  ORIGINAL = 'ORIGINAL',
  APPORTIONED = 'APPORTIONED',
}
