<template>
  <svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.46779 7.39158L21.3549 3.9077V3.91986L22.2361 3.7633C22.3604 3.74121 22.4882 3.74669 22.6102 3.77936C22.7322 3.81204 22.8456 3.8711 22.9423 3.95238C23.039 4.03365 23.1167 4.13518 23.1699 4.2498C23.223 4.3644 23.2504 4.48927 23.25 4.6156V4.61787V20.2208V20.2219C23.2502 20.3482 23.2227 20.4729 23.1694 20.5873C23.1162 20.7018 23.0385 20.8031 22.9418 20.8843C22.8451 20.9654 22.7318 21.0243 22.6099 21.0569C22.4879 21.0895 22.3603 21.095 22.2361 21.0729L22.2355 21.0728L9.95842 18.9005L9.1531 18.758L9.08069 19.5727C8.96367 20.8891 8.98986 22.2144 9.1588 23.5252L9.15872 23.5252L9.16026 23.5359C9.16972 23.6018 9.15849 23.6689 9.12811 23.7282C9.09775 23.7873 9.04974 23.8357 8.99073 23.8664C8.93169 23.8971 8.8646 23.9088 8.7987 23.8997C8.73278 23.8907 8.6713 23.8614 8.62275 23.8159L8.62278 23.8159L8.61689 23.8105C6.52056 21.8874 4.94873 19.5072 4.10162 18.1135L3.92586 17.8243L3.59273 17.7647L1.47039 17.3852L1.4704 17.3851L1.46218 17.3838C1.26205 17.3503 1.08042 17.2465 0.949894 17.0912C0.819369 16.9358 0.748504 16.739 0.750024 16.5361H0.750045V16.5305V8.24584H0.750066L0.750024 8.24022C0.748504 8.03731 0.819373 7.84049 0.949894 7.68513C1.08041 7.52978 1.26203 7.42604 1.46218 7.39254L1.46218 7.39256L1.46779 7.39158Z"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
