<script setup lang="ts">
import { TradeDestination } from 'ah-trades/src/components/forms/tradeDestination';
import { computed, reactive, ref, watch } from 'vue';
import {
  BankingScheme,
  Beneficiary,
  DrawdownTradeRequest,
  FeePaymentType,
  Trade,
  VersionedObject,
} from 'ah-api-gateways';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useTradeState } from '..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { PaymentErrorCodes, waitForCQRSEntityChange, waitForEntityChange } from 'ah-requests';
import { of } from 'rxjs';
import DrawdownForm from './forms/DrawdownForm.vue';
import TradeDestinationForm from 'ah-trades/src/components/forms/TradeDestinationForm.vue';
import ChargeTypeForm from 'ah-wallets/src/components/ChargeTypeForm.vue';
import PaymentReferenceForm from 'ah-trades/src/components/forms/PaymentReferenceForm.vue';
import CurrencyFundingFee from 'ah-trades/src/components/fees/CurrencyFundingFee.vue';
import PaymentReasonForm from 'ah-wallets/src/components/PaymentReasonForm.vue';
import DrawdownReview from './info/DrawdownReview.vue';
import { TradeFundsDestination } from 'ah-trades/src/models/trade';
import { DrawdownDetails } from 'ah-api-gateways/models/quote/pricing';
import { DrawdownQuotePriceResponse, getBuyCcy } from 'ah-api-gateways/models';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { useRouter } from 'vue-router/composables';
import { aggregatedPaymentReviewRoute } from '../composables/aggregatedPaymentLimitChecker';

enum DrawdownFlowStep {
  CREATE = 'CREATE',
  REVIEW = 'REVIEW',
}

const props = defineProps<{
  trade: Trade;
}>();

const emit = defineEmits<{
  (e: 'cancel', value: void): void;
  (e: 'drawdown-executed'): void;
  (e: 'update:trade', value: Trade): void;
}>();

const tradeState = useTradeState();

const router = useRouter();

const onBehalfOfClient = useOnBehalfOf();

const drawdownForm = ref<InstanceType<typeof DrawdownForm> | null>(null);

const destinationForm = ref<InstanceType<typeof TradeDestinationForm> | null>(null);

const paymentReasonForm = ref<InstanceType<typeof PaymentReasonForm> | null>(null);

const paymentReferenceForm = ref<InstanceType<typeof PaymentReferenceForm> | null>(null);

const chargeTypeForm = ref<InstanceType<typeof ChargeTypeForm> | null>(null);

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'drawdownPayment') {
      drawdownPayment();
    } else if (k === 'drawdownTrade') {
      drawdownTrade();
    }
  },
});

const state = reactive<{
  beneficiary?: Beneficiary;
  drawdownDestination: TradeDestination | null;
  drawdownDetails: DrawdownDetails | null;
  drawdownPrice: DrawdownQuotePriceResponse | null;
  isPaymentAggregatedLimitReached: boolean;
  pricesInvalid: boolean;
  responseTimeOffset?: number;
  step: DrawdownFlowStep;
  tradeHasLinkedPayments: boolean;
}>({
  drawdownDestination: null,
  drawdownDetails: null,
  drawdownPrice: null,
  isPaymentAggregatedLimitReached: false,
  pricesInvalid: false,
  step: DrawdownFlowStep.CREATE,
  tradeHasLinkedPayments: false,
});

const isPayment = computed(() => state.drawdownDestination?.destination === TradeFundsDestination.SEND);

const isDrawdownInvalid = computed(() => drawdownForm.value?.valid === false || !state.drawdownDestination?.valid);

const useChargeType = computed(() => state.drawdownDestination?.beneficiary?.bankingScheme === BankingScheme.SWIFT);

const isPerformingDrawdown = computed(() => requestManager.manager.requestStates.drawdownTrade === 'pending');

const isCreatingPayment = computed(() => requestManager.manager.requestStates.drawdownPayment === 'pending');

const isPerformingPayment = computed(() => requestManager.manager.requestStates.setupPayment === 'pending');

const isPaymentInvalid = computed(
  () =>
    (useChargeType.value && chargeTypeForm.value?.valid === false) ||
    paymentReasonForm.value?.valid === false ||
    paymentReferenceForm.value?.valid === false ||
    destinationForm.value?.isPaymentInvalid === true
);

function drawdownPayment() {
  let params: DrawdownTradeRequest = { priceId: state.drawdownPrice!.id };

  requestManager.manager
    .sameOrCancelAndNew(
      'drawdownPayment',
      tradeState.services.trade
        .drawdownTrade(props.trade.id, params, onBehalfOfClient.value?.id, {
          errors: { silent: true },
        })
        .pipe(
          mergeMap((idEntity) =>
            waitForCQRSEntityChange(idEntity, () =>
              tradeState.services.trade.getTrade(props.trade.id, {
                errors: { silent: true },
              })
            ).pipe(
              mergeMap(() => {
                return of(idEntity);
              }),
              catchError(() => of(idEntity))
            )
          )
        )
    )
    .subscribe(
      (idEntity) => {
        requestManager.manager.newPromise(
          'setupPayment',
          scheduleTradePayment(idEntity)
            .then(
              () => {
                tradeState.toast.success('Drawdown and Payment created successfully');
              },
              (error) => {
                const buyCurrency = getBuyCcy(state.drawdownPrice!);

                if (error.response?.data.code === PaymentErrorCodes.AGGREGATED_LIMIT_REACHED) {
                  tradeState.toast.show(
                    'Drawdown has been created successfully. There was a problem creating your payment. Please go to payments page to review and set up the payment again',
                    {
                      title: 'Review',
                      toastType: 'warning',
                      group: 'aggregatedPaymentLimit',
                      actions: [
                        {
                          title: 'Go to payments',
                          method: () => {
                            router.push(aggregatedPaymentReviewRoute);
                            tradeState.toast.clear('aggregatedPaymentLimit');
                          },
                          class: 'btn-primary',
                        },
                      ],
                    },
                    { noAutoHide: true }
                  );
                } else {
                  tradeState.toast.info(
                    `Drawdown succeeded, but payment creation has failed. Drawdown funds added to your ${buyCurrency.currency} wallet`
                  );
                }
              }
            )
            .then(() => {
              return waitForEntityChange(
                () =>
                  tradeState.services.trade.getTrade(props.trade.id, {
                    errors: { silent: true },
                  }),
                (trade) => trade.ccy1.remainingClientAmount !== props.trade.ccy1.remainingClientAmount
              )
                .toPromise()
                .catch(() => {});
            })
            .then(() => {
              emit('drawdown-executed');
              emit('update:trade', props.trade);
            })
        );
      },
      (e) => {
        const error = e.response?.data;
        const errorStatus = e.response?.status;
        if (error && errorStatus === 422 && error.message) {
          state.step = DrawdownFlowStep.CREATE;
          drawdownForm.value?.setErrorMessage(error.message);
          return;
        }
        tradeState.toast.error('An unexpected problem has occurred. Please try again later.');
      }
    );
}

function drawdownTrade() {
  let params: DrawdownTradeRequest = { priceId: state.drawdownPrice!.id };

  requestManager.manager
    .sameOrCancelAndNew(
      'drawdownTrade',
      tradeState.services.trade
        .drawdownTrade(props.trade.id, params, onBehalfOfClient.value?.id, {
          errors: { silent: true },
        })
        .pipe(
          tap(() => {
            tradeState.toast.success('Drawdown confirmed successfully');
            emit('drawdown-executed');
          }),
          mergeMap((idEntity) =>
            waitForCQRSEntityChange(idEntity, () =>
              tradeState.services.trade.getTrade(props.trade.id, {
                errors: { silent: true },
              })
            ).pipe(catchError(() => of(props.trade)))
          )
        )
    )
    .subscribe(
      (updatedTrade) => {
        emit('update:trade', updatedTrade);
      },
      (e) => {
        const error = e.response?.data;
        const errorStatus = e.response?.status;
        if (error && errorStatus === 422 && error.message) {
          state.step = DrawdownFlowStep.CREATE;
          drawdownForm.value?.setErrorMessage(error.message);
          return;
        }
        tradeState.toast.error('An unexpected problem has occurred. Please try again later.');
      }
    );
}

function scheduleTradePayment(tradeVersionedObject: VersionedObject) {
  const buyCurrency = getBuyCcy(state.drawdownPrice!);

  let payload = {
    beneficiaryId: state.drawdownDestination?.beneficiary?.id,
    chargeType: state.drawdownDestination?.chargeType,
    description: state.drawdownDestination?.reason,
    reference: state.drawdownDestination?.reference,
    amount: buyCurrency.clientAmount,
  };

  if (state.drawdownDestination!.executionDate) {
    payload.executionDate = formatDate(state.drawdownDestination!.executionDate, 'yyyy-MM-dd');
  } else {
    payload.tradeId = tradeVersionedObject.id;
  }

  return requestManager.manager.newPromise(
    'schedulePayment',
    tradeState.store
      .useWalletsStore()
      .loadCurrencyWallet({
        currency: buyCurrency.currency,
        owner: onBehalfOfClient.value ? { id: onBehalfOfClient.value.id, isPartner: false } : undefined,
      })
      .then((buyWallet) => {
        if (buyWallet) {
          return tradeState.services.payments
            .createScheduledPayment(
              {
                ...payload,
                walletId: buyWallet!.id,
              },
              onBehalfOfClient.value?.id,
              {
                errors: { silent: true },
              }
            )
            .toPromise();
        }
        throw 'No wallet found for client';
      })
  );
}

function submit() {
  drawdownForm.value?.touchForms();
  paymentReasonForm.value?.touchForms();
  paymentReferenceForm.value?.touchForms();
  chargeTypeForm.value?.touchForms();

  if (isDrawdownInvalid.value) {
    tradeState.toast.clear('tradeDetailsInfo');
    tradeState.toast.info('Please review the drawdown details', {
      group: 'tradeDetailsInfo',
    });
    return;
  }

  if (!state.drawdownDestination || isPayment.value) {
    if (!state.drawdownDestination?.beneficiary) {
      tradeState.toast.clear('tradeDetailsInfo');
      tradeState.toast.info('Please select a destination bank account', {
        group: 'tradeDetailsInfo',
      });
      return;
    } else if (isPaymentInvalid.value) {
      tradeState.toast.clear('tradeDetailsInfo');
      tradeState.toast.info('Please review the drawdown payment details', {
        group: 'tradeDetailsInfo',
      });
      return;
    }
  }

  if (state.step === DrawdownFlowStep.CREATE) {
    state.step = DrawdownFlowStep.REVIEW;
  } else {
    if (isPayment.value) {
      if (state.drawdownPrice) {
        drawdownPayment();
      }
    } else {
      drawdownTrade();
    }
  }
}

function back() {
  if (state.step === DrawdownFlowStep.REVIEW) {
    state.step = DrawdownFlowStep.CREATE;
    state.pricesInvalid = false;
  } else {
    emit('cancel');
  }
}

watch(
  useChargeType,
  () => {
    if (state.drawdownDestination && !useChargeType.value) {
      state.drawdownDestination.chargeType = undefined;
    }
  },
  { immediate: true }
);

watch(
  () => props.trade,
  () => {
    if (props.trade.beneficiaryId) {
      requestManager.manager
        .sameOrCancelAndNew(
          'getTradeBeneficiary',
          tradeState.services.beneficiary.getBeneficiary(props.trade.beneficiaryId)
        )
        .subscribe(
          (res) =>
            (state.drawdownDestination = {
              ...state.drawdownDestination,
              destination: TradeFundsDestination.SEND,
              reference: state.drawdownDestination?.reference || props.trade.reference || '',
              beneficiary: res,
              dirty: false,
              valid: false,
            })
        );
    }
    if (props.trade.referenceNumber) {
      requestManager.manager
        .sameOrCancelAndNew(
          'getLinkedPayments',
          tradeState.services.payments.listPayments({
            queryBy: 'tradeComposedReferenceNumber',
            query: props.trade.referenceNumber,
          })
        )
        .subscribe((res) => {
          state.tradeHasLinkedPayments = res.total > 0 ? true : false;
        });
    }
  },
  { immediate: true }
);
</script>

<template>
  <div>
    <BoxGrid alignV="start" v-if="state.step === DrawdownFlowStep.CREATE">
      <BoxGridBlock cols="12">
        <DrawdownForm
          ref="drawdownForm"
          :trade="trade"
          :drawdownDetails.sync="state.drawdownDetails"
          :drawdownPrice.sync="state.drawdownPrice"
          :responseTimeOffset.sync="state.responseTimeOffset"
          auto-set-max
        />
      </BoxGridBlock>

      <BoxGridBlock cols="12">
        <TradeDestinationForm
          ref="destinationForm"
          :tradePrice="state.drawdownPrice"
          :tradeDetails="state.drawdownDetails"
          :tradeDestination.sync="state.drawdownDestination"
        />
      </BoxGridBlock>
      <Transition name="slide-and-fade" appear>
        <div class="w-100" v-if="state.drawdownDestination && state.drawdownDestination.beneficiary">
          <BoxGridBlock cols="12">
            <PaymentReasonForm
              ref="paymentReasonForm"
              :reason.sync="state.drawdownDestination.reason"
              :beneficiary="state.drawdownDestination.beneficiary"
              v-bind="$attrs"
            />
          </BoxGridBlock>
          <BoxGridBlock cols="12">
            <PaymentReferenceForm
              ref="paymentReferenceForm"
              :beneficiary="state.drawdownDestination.beneficiary"
              :reference.sync="state.drawdownDestination.reference"
            />
          </BoxGridBlock>
          <BoxGridBlock cols="12">
            <ChargeTypeForm
              ref="chargeTypeForm"
              :chargeType.sync="state.drawdownDestination.chargeType"
              v-if="useChargeType"
            />
            <CurrencyFundingFee
              :paymentCurrency="state.drawdownDestination.beneficiary.currency"
              :bankingScheme="state.drawdownDestination.beneficiary.bankingScheme"
              :chargeType="state.drawdownDestination.chargeType"
              :beneficiary="state.drawdownDestination.beneficiary"
              :feePaymentType="FeePaymentType.PAYMENT"
            />
          </BoxGridBlock>
        </div>
      </Transition>

      <BoxGridItem class="text-muted" sm="12">
        By continuing below, the settlement date for the drawdown amount will become available today, or the next
        available day if you have not met today's cut off time. <br />
        Your Margin requirements will be recalculated once the drawdown is settled to reflect the remaining position.
      </BoxGridItem>

      <BoxGridItem v-if="state.tradeHasLinkedPayments" class="mt-3" sm="12">
        Scheduled payment(s) linked to this trade will now be cancelled
      </BoxGridItem>
    </BoxGrid>

    <DrawdownReview
      :trade="trade"
      :drawdown-price.sync="state.drawdownPrice"
      :drawdown-destination="state.drawdownDestination"
      :drawdown-details="state.drawdownDetails"
      :pricesInvalid.sync="state.pricesInvalid"
      :responseTimeOffset.sync="state.responseTimeOffset"
      hedgingProductLabel="Instrument"
      v-else-if="state.drawdownDestination && state.drawdownPrice && state.drawdownDetails && state.responseTimeOffset"
    />

    <div class="my-5">
      <VButton blurOnClick @click="back" :disable="isPerformingDrawdown" class="btn-stroked mr-3">
        {{ state.step === DrawdownFlowStep.CREATE ? 'Cancel' : 'Back' }}
      </VButton>
      <VButton
        blurOnClick
        @click="submit"
        :loading="isPerformingDrawdown || isCreatingPayment || isPerformingPayment"
        :disabled="
          isDrawdownInvalid || isPaymentInvalid || state.pricesInvalid || state.isPaymentAggregatedLimitReached
        "
      >
        {{ state.step === DrawdownFlowStep.CREATE ? 'Continue' : 'Confirm' }}
      </VButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn {
  min-width: 7rem;
}
</style>
