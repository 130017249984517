<script setup lang="ts">
import { PaginatedQuery, ExportListType } from 'ah-api-gateways';
import { outstandingFeesTableFields } from 'ah-trades/src/components/fees/tableFields/outstandingFeeTableFields';
import { OutstadingFee, feeStatusBadge } from 'ah-api-gateways/models/feesAndCharges/fees';
import { FeeStatus } from 'ah-api-gateways/models/feesAndCharges/fees';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { PropType, computed } from 'vue';
import { defineUseManagedListingProps, useManagedListing, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useTradeState } from '../..';
import { useRouter } from 'vue-router/composables';

const props = defineProps({
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    required: false,
    default: () => ({ tableFields: outstandingFeesTableFields }),
  },
  highlight: {
    type: String as PropType<'none' | 'left' | 'right' | 'both'>,
    required: false,
    default: 'right',
  },
  ...defineUseManagedListingProps<OutstadingFee>(),
});

const tradeState = useTradeState();

const requestManager = useRequestManager();

const router = useRouter();

interface OutstadingFeeEmits extends UseManagedListingEmits<OutstadingFee> {}

const emit = defineEmits<OutstadingFeeEmits>();

const managedListing = useManagedListing<OutstadingFee>({
  loadDataRequest(query: PaginatedQuery) {
    return tradeState.services.fees.listOutstandingFees({ ...query });
  },
  downloadDataRequest(type: string, query: { [key: string]: any }) {
    let documentsTitle: string | undefined = undefined;
    if (managedListing.bindings.filter.type === 'PARTNERS') {
      documentsTitle = 'Partners Outstanding Fees';
    }
    return tradeState.services.fees.downloadOutsandingFeesList(query, type as ExportListType, documentsTitle);
  },
  emit,
  props,
  fields: computed(() => computedTableFields.value),
  reqManager: requestManager.manager,
});

const computedTableFields = computed(() => {
  if (managedListing.bindings.filter.type === 'PARTNERS') {
    return props.config.tableFields.filter((column: any) => column.key !== 'clientName');
  }
  return props.config.tableFields;
});

const showReminder = computed(() => {
  return (fee: OutstadingFee) => {
    return [FeeStatus.DUE, FeeStatus.OVERDUE].includes(fee.feeStatus);
  };
});

function onRowClicked(fee: OutstadingFee) {
  if (managedListing.bindings.filter.type === 'PARTNERS') {
    router.push({ path: `partner/${fee.partnerId}`, append: true });
  } else {
    router.push({ path: `client/${fee.clientId}`, append: true });
  }
}

function sendReminder(fee: OutstadingFee) {
  requestManager.manager
    .cancelAndNew(
      `sendReminder-${fee.clientId}`,
      tradeState.services.notification.sentOutstandingFeeReminder(fee.clientId)
    )
    .subscribe(() => tradeState.toast.success('Reminder sent successfully.'));
}

function rowClass(fee?: OutstadingFee) {
  let out = '';
  if (fee?.feeStatus) {
    out += `status-${feeStatusBadge[fee.feeStatus].class} `;
  }
  if ((props.highlight === 'left' || props.highlight === 'both') && fee?.feeStatus === FeeStatus.OVERDUE) {
    out += 'highlight-left ';
  }
  if (props.highlight === 'right' || props.highlight === 'both') {
    out += 'highlight-right ';
  }
  return out;
}

function statusLabel(fee?: OutstadingFee) {
  if (fee?.feeStatus) {
    return feeStatusBadge[fee.feeStatus].label;
  }
}

defineExpose({
  downloadData: managedListing.downloadData,
});
</script>

<template>
  <PaginatedTable
    hover
    class="outstanding-fees-table"
    items-label="outstanding fees"
    :rowClass="rowClass"
    @row-clicked="onRowClicked"
    v-on="{ ...managedListing.listeners, ...$listeners }"
    v-bind="{ ...managedListing.bindings, ...$attrs }"
    :stacked="$ahTradesState.mediaQuery.is('smDown')"
    breakpoint="1200px"
  >
    <template #cell(feesAccrued)="{ item }">
      {{ formatCurrencyValue(item.feesAccrued) }}
    </template>
    <template #cell(feesPaid)="{ item }">
      {{ formatCurrencyValue(item.feesPaid) }}
    </template>
    <template #cell(feesOutstanding)="{ item }">
      {{ formatCurrencyValue(item.feesOutstanding) }}
    </template>
    <template #cell(feeStatus)="{ item }">
      <span :class="`label label-${rowClass(item)}`">{{ statusLabel(item) }}</span>
    </template>
    <template #cell(reminder)="{ item }">
      <VButton
        v-if="showReminder(item)"
        :loading="requestManager.manager.requestStates[`sendReminder-${item.clientId}`] === 'pending'"
        class="btn btn-stroked"
        @click.stop="sendReminder(item)"
      >
        Send Reminder
      </VButton>
    </template>
  </PaginatedTable>
</template>

<style lang="scss" scoped>
::v-deep tr:not(.b-table-bottom-row) {
  position: relative;
  cursor: pointer;
  td:first-child,
  th:first-child {
    padding-left: 1.5rem !important;
  }

  td:last-child {
    @include phablet {
      height: unset;
      text-align: center;
    }
  }

  &.highlight-right td:last-child:before {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    border-right: 8px solid transparent;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &.highlight-left td:first-child:before {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-left: 8px solid transparent;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &.b-table-has-details {
    td:last-child:before,
    td:first-child:before {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  &.b-table-details,
  &.p-datatable-row-expansion {
    td:last-child:before,
    td:first-child:before {
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
    }
  }

  .label-status {
    &-green {
      color: $green;
    }
    &-orange {
      color: $orange;
    }
    &-red {
      color: $red;
    }
  }

  .status-wrapper {
    @include tablet {
      text-align: end;
    }
  }

  &.status {
    &-green td:last-child:before {
      border-color: $green;
    }
    &-orange td:last-child:before {
      border-color: $orange;
    }
    &-red td:last-child:before,
    &-red td:first-child:before {
      border-color: $red;
    }
  }
}
</style>
