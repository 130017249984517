<template>
  <div>
    <span v-if="!isStackedView">
      <VRow class="row mt-4">
        <VCol>
          <label> Rail </label>
        </VCol>
        <VCol> <label> Currency </label> </VCol>
        <VCol v-if="paymentType === feePaymentType.PAYMENT">
          <label> ACO/SHA </label> <InfoTooltip :text="toolTipText" />
        </VCol>
        <VCol>
          <label> Fees Charged to you </label>
        </VCol>
        <VCol>
          <label> Fees Charged to client by you </label>
        </VCol>
      </VRow>
      <hr />
    </span>
    <template v-if="combinedFeesArr.length && requestManager.requestStates.loadDefaultFees !== 'pending'">
      <template v-if="isStackedView">
        <BoxGrid>
          <BoxGridBlock>
            <h3 v-if="clientName !== null">{{ clientName }} {{ typeLabel }} Fee Settings</h3>
            <h3 v-else>Global Client {{ typeLabel }} Fee Settings</h3>
            <AcoTiersModal v-if="bankingScheme === 'SWIFT'" v-slot="{ showModal }">
              <div class="text-sm-center">
                <VButton @click="showModal" class="mr-2 btn-stroked">Check ACO tiers</VButton>
              </div>
            </AcoTiersModal>

            <div class="mobile-info-row pt-3" v-for="fee in combinedFeesArr" :key="fee.id">
              <DataRow cols="7" label="Rail" class="fee-rail">
                <label class="pl-4"> {{ fee.rail }} </label>
              </DataRow>
              <DataRow cols="7" label="Currency" class="fee-currency">
                <label class="pl-4"> {{ fee.currency }}</label>
                <label class="pl-4" v-if="fee.currencyRail !== '' && fee.currencyRail !== null">
                  -{{ fee.currencyRail }}
                </label>
              </DataRow>
              <DataRow cols="7" label="Charge" class="fee-charge-type">
                <span class="pl-4" v-if="paymentType === feePaymentType.PAYMENT">
                  <label> {{ fee.chargeType }} </label>
                </span>
              </DataRow>
              <DataRow cols="7" label="Fees Charged to you" class="fee-partner-charge">
                <label class="pl-4"> {{ fee.defaultFee }} </label>
              </DataRow>
              <DataRow cols="7" label="Fees Charged to client by you" class="fee-client-charge">
                <label v-if="updateFeeFormModels[fee.id]">
                  <ValidatedForm
                    class="fee-form px-3 text-center"
                    :fm="updateFeeFormModels[fee.id]"
                    :validation.sync="updateFeeFormModelValidations[fee.id]"
                    @form-event="onFeeFormEvent($event, fee)"
                  />
                </label>
              </DataRow>
            </div>
            <div class="text-center mt-4">
              <VButton @click="cancel" class="btn-secondary mr-2" :disabled="requestManager.anyPending"
                >Discard Changes</VButton
              >
              <VButton
                @click="submit"
                :disabled="requestManager.anyPending"
                :loading="requestManager.requestStates.submit === 'pending'"
                >Save</VButton
              >
            </div>
          </BoxGridBlock>
        </BoxGrid>
      </template>
      <template v-else>
        <VRow class="row" v-for="fee in combinedFeesArr" :key="fee.id">
          <VCol>
            <label> {{ fee.rail }} </label>
          </VCol>
          <VCol>
            <label> {{ fee.currency }}</label>
            <label v-if="fee.currencyRail !== '' && fee.currencyRail !== null"> -{{ fee.currencyRail }} </label>
          </VCol>
          <VCol v-if="paymentType === feePaymentType.PAYMENT">
            <label> {{ fee.chargeType }} </label>
          </VCol>
          <VCol>
            <label> {{ fee.defaultFee }} </label>
          </VCol>
          <VCol>
            <label v-if="updateFeeFormModels[fee.id]">
              <ValidatedForm
                class="fee-form"
                :fm="updateFeeFormModels[fee.id]"
                :validation.sync="updateFeeFormModelValidations[fee.id]"
                @form-event="onFeeFormEvent($event, fee)"
              />
            </label>
          </VCol>
        </VRow>
        <div class="text-left mt-4">
          <VButton @click="cancel" class="btn-secondary mr-2" :disabled="requestManager.anyPending"
            >Discard Changes</VButton
          >
          <VButton
            @click="submit"
            :disabled="requestManager.anyPending"
            :loading="requestManager.requestStates.submit === 'pending'"
            >Save</VButton
          >
        </div>
      </template>
    </template>
    <LoadingIcon v-else-if="requestManager.requestStates.loadDefaultFees === 'pending'" class="svg" />
    <RouteProtectorModal
      :allowChange="areAllFormsPristine"
      :allowSubpaths="false"
      :allowQueryChange="false"
      class="exit-protected-route"
      centered
      title="Changes not saved"
    >
      <p>There are unsaved changes. Are you sure you want to continue?</p>
    </RouteProtectorModal>
    <BModal ref="checkBelowValues" :centered="true" ok-title="Yes" ok-variant="success" @hide="handleBellowValuesHide">
      <div class="text-center">
        <p>By setting client fees to below yours you will still incur fees</p>
        <p>but are choosing not to pass these fully onto the clients.</p>
        <p>Are you sure you want to proceed?</p>
      </div>
    </BModal>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { numberField } from 'ah-common-lib/src/form/models';
import { makeFormModel, resetForm, setState } from 'ah-common-lib/src/form/helpers';
import { FormValidation, FormEvent, FormModel } from 'ah-common-lib/src/form/interfaces';
import { Observable, combineLatest } from 'rxjs';
import RouteProtectorModal from 'ah-common-lib/src/common/components/route/RouteProtectorModal.vue';
import { BModal, BvModalEvent } from 'bootstrap-vue';
import InfoTooltip from '../common/InfoTooltip.vue';
import { BankingScheme, FundingCharge, FeePaymentType, AcoTier } from 'ah-api-gateways';
import AcoTiersModal from './AcoTiersModal.vue';
import { getChildModel } from 'ah-common-lib/src/form/helpers';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';

export interface CombinedDisplayFee {
  id: string;
  rail: string;
  currency: string;
  chargeType: string;
  currencyRail: string;
  defaultFee: number;
  partnerFee: number;
}

/**
 * Fees Editor component
 * Displays fees and allows editing (and saving)
 *
 * Emits:
 * - 'cancel' when cancel button is clicked and fields are reset to their initial values
 * - 'update:formBusy' whenever the requestManager state changes, for .sync prop consumption
 */
@Component({
  components: {
    RouteProtectorModal,
    InfoTooltip,
    AcoTiersModal,
  },
})
export default class FeesEditor extends Mixins(WithRequestManager) {
  $refs!: {
    checkBelowValues: BModal;
  };

  @Prop({ default: null }) clientId!: string;

  @Prop({ required: true }) paymentType!: FeePaymentType;

  @Prop({ required: false }) bankingScheme?: BankingScheme;

  @Prop({ required: false }) clientName?: string;

  private partnerFees: FundingCharge[] = [];

  private defaultFees: FundingCharge[] = [];

  private combinedFeesArr: CombinedDisplayFee[] = [];

  private updateFeeFormModelValidations: { [key: string]: FormValidation | null } = {};

  private updateFeeFormModels: { [key: string]: FormModel } = {};

  private savedLastValue: CombinedDisplayFee | null = null;

  requestManagerConfig = {
    exposeToParent: true,
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'submit') {
      this.submit();
    }
    if (k === 'loadFees') {
      this.loadFees();
    }
    if (k === 'loadDefaultFees') {
      this.loadDefaultFees();
    }
  }

  private get toolTipText() {
    return '<b>What is the difference between ‘Shared’ and ‘Ours’ charge type?</b> <br><br> &#x2022 <b> Shared (SHA):</b> The intermediary bank charges are deducted from the payment amount. As a result, the payment amount received in the beneficiary bank account may be less than the full amount expected.<br> &#x2022 <b>Ours (ACO):</b> The intermediary bank charges are covered by the payer and not deducted from the payment amount. The beneficiary receives the full payment amount but the payer will be charged a higher payment fee to cover the intermediary bank charges';
  }

  /**
   * Create key-value object containing a form for each fee
   *
   * Validation object is created with keys pre-set to null, to ensure reactivity
   */
  private makeFormModels() {
    const formModels: { [key: string]: FormModel } = {};
    const formValidations: { [key: string]: FormValidation | null } = {};

    this.combinedFeesArr.forEach((fee) => {
      const model = makeFormModel({
        name: `feeForm-${fee.id}`,
        fieldType: 'form',
        fields: [
          numberField('value', '', {
            required: true,
            inputClass: `form-control ${fee.defaultFee > fee.partnerFee ? 'below-value' : 'above-value'}`,
          }),
        ],
      });

      model.value = fee.partnerFee;

      formModels[fee.id] = model;
      formValidations[fee.id] = null;
    });

    this.updateFeeFormModels = formModels;
    this.updateFeeFormModelValidations = formValidations;
  }

  paintAllFees() {
    this.combinedFeesArr.forEach((fee) => {
      if (this.updateFeeFormModels[fee.id]) {
        const formField = getChildModel(this.updateFeeFormModels[fee.id], 'value');
        if (formField) {
          setState(
            formField,
            'inputClass',
            `form-control ${fee.defaultFee > this.updateFeeFormModels[fee.id].value ? 'below-value' : 'above-value'}`
          );
        }
      }
    });
  }

  onFeeFormEvent(event: FormEvent<number>, fee: CombinedDisplayFee) {
    if (event.event === 'form-field-set-value') {
      setState(
        event.model,
        'inputClass',
        `form-control ${fee.defaultFee > event.field.$model ? 'below-value' : 'above-value'}`
      );
      if (!this.savedLastValue && fee.defaultFee > event.field.$model) {
        this.savedLastValue = fee;
        this.$refs.checkBelowValues.show();
      }
    }
  }

  cancel() {
    this.resetFeesToInitial();
    Object.values(this.updateFeeFormModelValidations).forEach((validation) => {
      if (validation) {
        resetForm(validation);
      }
    });
    this.$emit('cancel');
  }

  handleBellowValuesHide(event: BvModalEvent) {
    if (event.trigger !== 'ok') {
      this.cancelFeeChange();
    }
  }

  cancelFeeChange() {
    const model = this.updateFeeFormModels[this.savedLastValue!.id];
    model.value = this.savedLastValue!.partnerFee;
    setState(
      model,
      'inputClass',
      `form-control ${this.savedLastValue!.defaultFee > model.value ? 'below-value' : 'above-value'}`
    );
    this.paintAllFees();
  }

  @Watch('requestManager.anyPending', { immediate: true })
  onSubmitStateChange() {
    this.$emit('update:formBusy', this.requestManager.anyPending);
    Object.values(this.updateFeeFormModels).forEach((model) => {
      setState(model, 'readonly', this.requestManager.anyPending);
    });
  }

  private fundingFeesRequest() {
    const fundingFeeDetails = {
      type: this.paymentType,
      bankingScheme: this.bankingScheme,
      sort: 'bankingScheme,desc',
    };
    if (this.clientId) {
      return this.$services.fees.listClientFees({ ...fundingFeeDetails, clientId: this.clientId });
    }
    return this.$services.fees.listPartnerFees({ ...fundingFeeDetails });
  }

  get typeLabel() {
    return this.paymentType === 'RECEIPT' ? 'Funding' : 'Payment';
  }

  private get areAllFormsValid() {
    const invalidIndex = Object.keys(this.updateFeeFormModelValidations).findIndex((key) => {
      const validation = this.updateFeeFormModelValidations[key];
      return !validation || validation.$invalid;
    });

    return invalidIndex === -1;
  }

  private get areAllFormsPristine() {
    const invalidIndex = Object.keys(this.updateFeeFormModelValidations).findIndex((key) => {
      const validation = this.updateFeeFormModelValidations[key];
      return validation?.$dirty;
    });

    return invalidIndex === -1;
  }

  get feePaymentType() {
    return FeePaymentType;
  }

  private get acoTier() {
    return AcoTier;
  }

  private get isStackedView() {
    return this.$mediaQuery.is('smDown');
  }

  private paymentRailtoDisplay(tier: AcoTier | null) {
    switch (tier) {
      case this.acoTier.ACO_TIER_1:
        return 'Tier 1';
      case this.acoTier.ACO_TIER_2:
        return 'Tier 2';
      case this.acoTier.ACO_TIER_3:
        return 'Tier 3';
      case this.acoTier.ACO_TIER_4:
        return 'Tier 4';
      default:
        return '';
    }
  }

  private submit() {
    if (!this.areAllFormsValid) {
      return;
    }

    const changedFees: CombinedDisplayFee[] = this.combinedFeesArr.filter(
      (fee) => fee.partnerFee != this.updateFeeFormModels[fee.id].value
    );

    const actions: Observable<FundingCharge[]>[] = changedFees.map((fee) =>
      this.$services.fees.updateFees(fee.id, this.updateFeeFormModels[fee.id].value)
    );

    this.requestManager.currentOrNew('submit', combineLatest(actions)).subscribe(() => {
      Object.values(this.updateFeeFormModelValidations).forEach((validation) => {
        if (validation) {
          resetForm(validation);
        }
      });
      this.loadFees();
      this.$toast.success('Fees Updated Successfully.');
    });
  }

  resetFeesToInitial() {
    this.combinedFeesArr.forEach((fee) => {
      this.updateFeeFormModels[fee.id].value = fee.partnerFee;
    });
    this.paintAllFees();
  }

  // Method called by parent component
  setFeesToZero() {
    this.combinedFeesArr.forEach((fee) => {
      this.updateFeeFormModels[fee.id].value = 0;
    });
    this.paintAllFees();
    this.submit();
  }

  // Method called by parent component
  setFeesToDefault() {
    this.combinedFeesArr.forEach((fee) => {
      this.updateFeeFormModels[fee.id].value = fee.defaultFee;
    });
    this.paintAllFees();
    this.submit();
  }

  @Watch('paymentType', { immediate: true })
  private loadFees() {
    this.requestManager.cancelAndNew('loadFees', this.fundingFeesRequest()).subscribe((response) => {
      this.partnerFees =
        response.filter((fee, index, self) =>
          fee.tier ? index === self.findIndex((t) => t.tier === fee.tier) : true
        ) ?? null;
      this.loadDefaultFees();
    });
  }

  private loadDefaultFees() {
    this.requestManager
      .cancelAndNew(
        'loadDefaultFees',
        this.$services.fees.listAHFees({
          type: this.paymentType,
          bankingScheme: this.bankingScheme,
          sort: 'bankingScheme,desc',
        })
      )
      .subscribe((response) => {
        this.defaultFees =
          response.filter((fee, index, self) =>
            fee.tier ? index === self.findIndex((t) => t.tier === fee.tier) : true
          ) ?? null;
        this.mapPartnerFeesToDefaultFees();
        this.makeFormModels();
        this.paintAllFees();
      });
  }

  private mapPartnerFeesToDefaultFees() {
    this.combinedFeesArr = [];
    this.defaultFees.forEach((defaultFee) => {
      const partnerFee = this.partnerFees.find(
        (f) =>
          f.paymentRail === defaultFee.paymentRail &&
          f.tier === defaultFee.tier &&
          f.paymentCurrency === defaultFee.paymentCurrency
      );

      /**
       * Partner fee is assumed to exist as the API only allows editing, not creation
       *
       * Any fee for which there isn't a partner fee is not added to the list
       *
       * any currency which is repeated, paymentRail is added for it to differentiate repeatedness
       */

      if (partnerFee) {
        this.combinedFeesArr.push({
          id: partnerFee.id,
          rail: partnerFee.bankingScheme,
          currency: partnerFee.paymentCurrency || this.paymentRailtoDisplay(defaultFee.tier) || 'All',
          currencyRail:
            this.defaultFees.filter((fee) => fee.paymentCurrency === defaultFee.paymentCurrency).length === 1
              ? ''
              : defaultFee.paymentRail,
          chargeType: defaultFee.chargeType,
          defaultFee: defaultFee.fee,
          partnerFee: partnerFee.fee,
        });
      }
    });
  }
}
</script>
<style lang="scss" scoped>
hr {
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

::v-deep .svg {
  width: 2em;
  height: 4em;
}

.fee-form {
  position: relative;
  top: -0.45em;
}

::v-deep .form-control.above-value {
  @include themedBorderColor($color-profit, $color-dark-profit);
}
::v-deep .form-control.below-value {
  @include themedBorderColor($color-loss, $color-dark-loss);
}

.mobile-info-row {
  border-bottom: 1px solid;
  @include themedBorderColor($color-primary, $color-dark-primary);
}
</style>
