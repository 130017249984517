<script lang="ts" setup>
import { FeeEntry, HistoricalTransaction, ExportListType, AuthorityType, feeReasonLabels } from 'ah-api-gateways';
import BankAccountRail from 'ah-beneficiaries/src/components/bankAccounts/BankAccountRail.vue';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { isDescendant } from 'ah-common-lib/src/helpers/dom';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, ref, onBeforeUnmount, onMounted, watch } from 'vue';
import { useTradeState } from '../..';
import { VButton } from 'ah-common-lib/src/common/components';

const props = defineProps<{
  fee: FeeEntry;
  customFeeReasonDescription: string;
  /**
   * Wallet ID for wallet outgoing/incoming reference
   *
   * As all fees come from or go to clients/partners GBP wallet, a single walletId can be used to determine
   * whether these fees are outgoing or incoming, using the fromWalletId property
   */
  referenceWalletId: string;
}>();

const tradeState = useTradeState();

const showTransactionDetailsDownloadPopover = ref(false);

const tx = ref<HistoricalTransaction | null>(null);

const feeReasonLabel = computed(() => feeReasonLabels[props.fee.reason]);

const requestManager = useRequestManager({
  exposeToParent: ['loadFeeTX'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadFeeTX') {
      onFeeChange();
    }
  },
});

const canDownloadStatement = computed(() =>
  tradeState.store.useAuthStore().hasAuthorities(AuthorityType.EXPORT_TRANSACTIONS)
);

// TODO composition API
// const isSmallScreen = computed(() => $mediaQuery.is('smDown'));
const isSmallScreen = computed(() => false);

const isPayedFee = computed(() => props.fee.fromWalletId === props.referenceWalletId);

const amount = computed(() => (isPayedFee.value ? -props.fee.amount : props.fee.amount));

function onFeeChange() {
  if (props.fee.feeTransactionId && isPayedFee.value) {
    requestManager.manager
      .sameOrCancelAndNew(
        'loadFeeTX',
        tradeState.services.wallet.getWalletTransaction(props.fee.feeTransactionId),
        props.fee.feeTransactionId
      )
      .subscribe((res) => (tx.value = res));
  }
}
watch(
  () => props.fee,
  () => {
    onFeeChange();
  },
  { immediate: true }
);

function toggleTransactionDetailsDownloadPopover() {
  setTimeout(() => {
    showTransactionDetailsDownloadPopover.value = !showTransactionDetailsDownloadPopover.value;
  });
}

function downloadTransactionDetails(type: ExportListType) {
  showTransactionDetailsDownloadPopover.value = false;
  if (tx.value) {
    requestManager.manager
      .sameOrCancelAndNew(
        'downloadTransactionTrade',
        tradeState.services.wallet.downloadFeeTransactionDetails(props.fee.id, type),
        tx.value.tradeId
      )
      .subscribe();
  }
}

let clickListener: (event: MouseEvent) => void;
const transactionDetailsPopover = ref<HTMLElement>();

onMounted(() => {
  clickListener = (event) => {
    if (
      showTransactionDetailsDownloadPopover.value &&
      (!transactionDetailsPopover.value ||
        !isDescendant(transactionDetailsPopover.value, event.target as Element, true))
    ) {
      showTransactionDetailsDownloadPopover.value = false;
    }
  };

  window.addEventListener('click', clickListener);
});

onBeforeUnmount(() => {
  window.removeEventListener('click', clickListener);
});
</script>

<template>
  <div class="px-md-3 text-wrap">
    <h3>Transaction details</h3>
    <LoadingOverlay variant="box" opacity="0.5" :state="requestManager.manager.requestStates.loadFeeTX">
      <VRow class="fee-details">
        <VCol class="left-info" :cols="isSmallScreen ? 12 : 6">
          <DataRow v-if="fee.fromClientName" :cols="6" label="Associated Client"> {{ fee.fromClientName }} </DataRow>
          <DataRow :cols="6" label="Fee Type"> {{ feeReasonLabel }} </DataRow>
          <DataRow v-if="customFeeReasonDescription" :cols="6" label="Custom Fee Reason">{{
            customFeeReasonDescription
          }}</DataRow>
        </VCol>
        <VCol class="right-info" :cols="isSmallScreen ? 12 : 6">
          <DataRow :cols="6" label="Method">
            <BankAccountRail :currency="fee.currency" :bankingScheme="fee.bankingScheme" />
          </DataRow>
          <DataRow v-if="fee.chargeType" :cols="6" label="Swift Fee Type"> {{ fee.chargeType }} </DataRow>
          <DataRow :cols="6" label="Transaction Amount"> {{ fee.currency }} {{ formatCurrencyValue(amount) }} </DataRow>
          <DataRow :cols="6" label="Fee Event Date"> {{ formatDate(fee.createdAt) }} </DataRow>
        </VCol>
      </VRow>
      <VRow class="mb-2" alignH="end">
        <VButton
          v-if="tx && canDownloadStatement"
          ref="downloadButton"
          id="transaction-details-download-button"
          class="mt-4"
          @click="toggleTransactionDetailsDownloadPopover"
          :loading="requestManager.manager.requestStates.downloadTransactionTrade === 'pending'"
        >
          <IconDownload /> Transaction details
        </VButton>
        <BPopover
          :show.sync="showTransactionDetailsDownloadPopover"
          placement="bottomright"
          custom-class="arrowless paddingless"
          target="transaction-details-download-button"
          triggers="click blur"
        >
          <div ref="transactionDetailsPopover" class="popover-container dropdown-menu-block">
            <div class="popover-section">
              <ul>
                <li @click="downloadTransactionDetails(ExportListType.PDF)">PDF</li>
                <li @click="downloadTransactionDetails(ExportListType.CSV)">CSV</li>
                <li @click="downloadTransactionDetails(ExportListType.XLSX)">Excel</li>
              </ul>
            </div>
          </div>
        </BPopover>
      </VRow>
    </LoadingOverlay>
  </div>
</template>

<style lang="scss" scoped>
.fee-details {
  .left-info {
    padding-right: 2rem;
  }
  .right-info {
    border-left: 2px solid red;
    @include themedBorderColor($color-text-secondary, $color-dark-text-secondary);
    padding-left: 2rem;
  }
  margin-bottom: 1rem;
}
::v-deep .label-col {
  margin-bottom: 0.2rem;
}
</style>
