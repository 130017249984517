<script setup lang="ts">
import SearchableTradeList from 'ah-trades/src/components/lists/SearchableTradeList.vue';
import CollateralInfoBlock from 'ah-trades/src/components/collaterals/CollateralInfoBlock.vue';
import TradeListFilters from 'ah-trades/src/components/lists/TradeListFilters.vue';
import { Subscription } from 'rxjs';
import {
  NotificationEventType,
  NotificationType,
  Client,
  clientTradeStates,
  clientTradeStatus,
  NotificationEvent,
  fxHedgingInstruments,
} from 'ah-api-gateways';
import OnBehalfOf from 'ah-common-lib/src/onBehalfOf/OnBehalfOf';
import { useWalletsStore } from '@/app/store/walletsModule';
import { useNotificationsStore } from 'ah-notifications/src/store';
import { computed, onBeforeMount, onUnmounted, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { useRouter } from 'vue-router/composables';

const tradeEvents = [
  NotificationType.TRADE_CONFIRMED,
  NotificationType.TRADE_REJECTED,
  NotificationType.POSITION_STATUS_OPEN,
  NotificationType.POSITION_STATUS_CLOSE,
];

const services = getServices();

const requestManager = useRequestManager().manager;

const walletStore = useWalletsStore();

const notificationsStore = useNotificationsStore();

const router = useRouter();

const tradeList = ref<InstanceType<typeof SearchableTradeList>>();

const eventsSubscription = ref<Subscription>();

const client = ref<Client>();

const props = defineProps({
  clientId: { type: String, required: false },
  tradeId: { type: String, required: false },
});

onBeforeMount(() => {
  if (!props.clientId) {
    eventsSubscription.value = notificationsStore.notificationEvents.subscribe((event: NotificationEvent) => {
      if (
        event.eventType === NotificationEventType.NOTIFICATION_CREATED &&
        tradeEvents.includes(event.payload?.type!)
      ) {
        tradeList.value!.reload();
      }
    });
    walletStore.loadCollaterals({ force: true });
  }
});

const possibleTradeStates = computed(() => clientTradeStates);
const possibleTradeStatuses = computed(() => clientTradeStatus);
const possibleHedgingInstruments = computed(() => fxHedgingInstruments);

function getOnBehalfOfClient() {
  if (props.clientId) {
    requestManager
      .currentOrNew('getClient', services.client.getClient(props.clientId))
      .subscribe((response: Client) => {
        client.value = response;
      });
  }
}

watch(() => props.clientId, getOnBehalfOfClient, { immediate: true });

function goBack() {
  router.push('./');
}

onUnmounted(() => eventsSubscription.value?.unsubscribe());
</script>

<template>
  <OnBehalfOf :client="client">
    <div class="padded-space" id="all-trades-view">
      <div class="inner">
        <LoadingOverlay :state="requestManager.currentStates" show-retry @retry="getOnBehalfOfClient">
          <VRow class="mx-0" align-v="center">
            <h2>All Spots and Forwards</h2>
          </VRow>
          <BackButton class="mb-3" v-if="client" label="go back" @click="goBack()" small />
          <CollateralInfoBlock v-if="!clientId" class="mb-4 info-block" />
          <SearchableTradeList
            ref="tradeList"
            withRowDetails
            showExport
            :openTrade="tradeId"
            :commonFilters="{ clientId: clientId }"
            paginationQueryParam="tradesSort"
            filterQueryParam="tradesFilter"
            documentTitle="All Trades List"
          >
            <template #filters="{ onFilterChange, filter }">
              <TradeListFilters
                showDates
                showSearch
                showStatuses
                :filters="filter"
                :possibleStatuses="possibleTradeStatuses"
                :possibleStates="possibleTradeStates"
                :possibleInstruments="possibleHedgingInstruments"
                @update:filters="onFilterChange"
                :openTrade="tradeId"
              />
            </template>
          </SearchableTradeList>
        </LoadingOverlay>
      </div>
    </div>
  </OnBehalfOf>
</template>

<style lang="scss" scoped>
.info-block {
  max-width: 600px;
}

.client-name,
.back-button {
  @include themedTextColor($color-dark-primary, $color-primary);
}
</style>
