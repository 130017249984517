<script lang="ts">
import { PropOptions } from 'vue';
import { ChartOptions, ChartData } from 'chart.js';

export const doughnutChartProps = {
  options: {
    type: Object as PropType<ChartOptions<'doughnut'>>,
    required: true,
  },
  data: {
    type: Object as PropType<ChartData>,
    required: true,
  },
  mainLabel: {
    type: String,
    default: '',
  },
  secondaryLabel: {
    type: String,
    default: '',
  },
} satisfies Record<string, PropOptions>;
</script>

<script setup lang="ts">
import VChart from 'ah-reports/src/components/VChart.vue';
import { PropType, computed } from 'vue';

const options = defineProps(doughnutChartProps);

// Typing Doughnut options as the more generic ChartOptions to make Typescript happy
const doughnutOptions = computed(() => options.options as ChartOptions);
</script>

<template>
  <VChart class="doughnut-chart" type="doughnut" :options="doughnutOptions" :data="data">
    <div class="doughnut-center text-center">
      <div class="upper-label d-flex justify-content-center align-items-center">
        <slot name="mainLabel">
          {{ mainLabel }}
        </slot>
      </div>
      <div class="lower-label">
        <slot name="secondaryLabel">
          {{ secondaryLabel }}
        </slot>
      </div>
    </div>
  </VChart>
</template>

<style lang="scss" scoped>
.doughnut-chart {
  width: 200px;
  height: 200px;
  flex-shrink: 0;

  .doughnut-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    .upper-label {
      font-size: 1.5em;
      @include themedTextColor($color-text);
    }

    .lower-label {
      font-size: 1em;
      @include upToResolution($tabletResolution) {
        font-size: 0.8em;
      }
    }
  }
}
</style>
