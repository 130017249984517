import {
  DocumentExport,
  ExportListType,
  ListQuery,
  PaginatedQuery,
  PaginatedResponse,
  PartnerCommissionReport,
  standardiseQuerySort,
  ClientWalletTotalReport,
  ClientWalletReport,
  CobBalanceOwner,
  CobBalancesWalletReport,
  CobTotalBalancesWalletReport,
  ClientMarginCollateralReport,
  ClientMarginCollateralTotalsReport,
  SettledCommissionsReport,
  PartnerReport,
  ClientMoney,
  ClientMoneyDetails,
  CashBalance,
  CashBalanceClient,
} from '../models';
import { HttpBasedService, HttpOptions, HttpService } from 'ah-requests';
export class FinancialReportsService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'financialReportsService' } },
      },
    });
  }

  private downloadList(
    url: string,
    query: ListQuery,
    fileFormat: ExportListType,
    documentTitle: string,
    fileName?: string
  ) {
    return this.get<DocumentExport>(url, {
      axiosConfig: {
        params: {
          ...query,
          fileFormat,
          documentTitle,
          fileName,
        },
      },
    });
  }

  /**
   * Admin endpoints
   */

  public getCobWalletReport(params: PaginatedQuery, options?: Partial<HttpOptions<any>>) {
    const { owner, ...standardiseParams } = standardiseQuerySort(params);
    const suffix = 'cob-balances/' + (owner ?? CobBalanceOwner.CLIENTS).toLowerCase();

    return this.get<PaginatedResponse<CobBalancesWalletReport> | CobBalancesWalletReport>(this.baseUrl + suffix, {
      options,
      axiosConfig: {
        params: standardiseParams,
      },
    });
  }

  public getCobWalletTotalsReport(params: PaginatedQuery, options?: Partial<HttpOptions<any>>) {
    const { owner, ...standardiseParams } = standardiseQuerySort(params);
    const suffix = 'cob-balances/' + (owner ?? CobBalanceOwner.CLIENTS).toLowerCase();

    return this.get<CobTotalBalancesWalletReport>(`${this.baseUrl + suffix}/totals`, {
      options,
      axiosConfig: {
        params: standardiseParams,
      },
    });
  }

  public downloadCobWalletsBalances(
    query: ListQuery,
    fileFormat: ExportListType,
    documentTitle = 'Clients Money Balance List Report'
  ) {
    const { owner, ...standardiseParams } = standardiseQuerySort(query);
    const suffix = 'cob-balances/' + (owner ?? CobBalanceOwner.CLIENTS).toLowerCase();

    return this.get<DocumentExport>(`${this.baseUrl + suffix}/export`, {
      axiosConfig: {
        params: {
          ...standardiseParams,
          fileFormat,
          documentTitle,
        },
      },
    });
  }

  /**
   * Settled commissions (Admin)
   */
  public getSettledCommissionReportList(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<SettledCommissionsReport>>(`${this.baseUrl}settled-commissions`, {
      axiosConfig: {
        params: params,
      },
    });
  }

  public downloadSettledCommissionReportList(
    query: ListQuery,
    fileFormat: ExportListType,
    documentTitle = 'Settled Commission List Report'
  ) {
    return this.downloadList(`${this.baseUrl}settled-commissions/export`, query, fileFormat, documentTitle);
  }

  public getSettledPartnerCommissionReportList(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<PartnerCommissionReport>>(`${this.baseUrl}partners/commissions`, {
      axiosConfig: {
        params: params,
      },
    });
  }

  public downloadSettledPartnerCommissionReportList(
    query: ListQuery,
    fileFormat: ExportListType,
    documentTitle = 'Partner Settled Commission List Report'
  ) {
    return this.downloadList(`${this.baseUrl}partners/commissions/export`, query, fileFormat, documentTitle);
  }

  public getClientMarginCollateralReport(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<ClientMarginCollateralReport>>(
      `${this.baseUrl}voice-trades/client-margin-collateral-reports`,
      {
        axiosConfig: {
          params: params,
        },
      }
    );
  }

  public getClientMarginCollateralTotalsReport(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<ClientMarginCollateralTotalsReport>(
      `${this.baseUrl}voice-trades/client-margin-collateral-reports/totals`,
      {
        axiosConfig: {
          params: params,
        },
      }
    );
  }

  public downloadClientMarginCollateralReport(
    query: ListQuery,
    fileFormat: ExportListType,
    documentTitle = 'Client Margin Collateral Report',
    fileName = 'vtd-margin-collateral'
  ) {
    return this.downloadList(
      `${this.baseUrl}voice-trades/client-margin-collateral-reports/export`,
      query,
      fileFormat,
      documentTitle,
      fileName
    );
  }

  /**
   * Open endpoint
   */

  public getPartnerCommissionReportList(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<PartnerReport>>(`${this.baseUrl}partner-commissions`, {
      axiosConfig: {
        params: params,
      },
    });
  }

  public downloadPartnerCommissionReportList(
    query: ListQuery,
    fileFormat: ExportListType,
    documentTitle = 'Partner Commission List Reports'
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(`${this.baseUrl}partner-commissions/export`, query, fileFormat, documentTitle);
  }

  public downloadPartnerCommissionReportStatements(
    query: ListQuery,
    fileFormat: ExportListType,
    documentTitle = 'Partner Commissions Statement'
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(`${this.baseUrl}partner-commissions/statements/export`, query, fileFormat, documentTitle);
  }

  public listClientWallets(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<ClientWalletReport>>(`${this.baseUrl}voice-trades/client-wallet-reports`, {
      axiosConfig: { params },
    });
  }

  public listClientWalletTotals(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<ClientWalletTotalReport>(`${this.baseUrl}voice-trades/client-wallet-reports/totals`, {
      axiosConfig: { params },
    });
  }

  public downloadClientWalletReport(
    query: ListQuery,
    fileFormat: ExportListType,
    documentTitle = 'Client Wallets Report',
    fileName = 'vtd-wallet-balances'
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(
      `${this.baseUrl}voice-trades/client-wallet-reports/export`,
      query,
      fileFormat,
      documentTitle,
      fileName
    );
  }

  public listCashBalance(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<CashBalance>>(`${this.baseUrl}voice-trades/client-ledger/clients`, {
      axiosConfig: { params },
    });
  }

  public downloadCashBalance(
    query: ListQuery,
    fileFormat: ExportListType,
    documentTitle = 'Cash Balance',
    fileName = 'vtd-client-ledger'
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(
      `${this.baseUrl}voice-trades/client-ledger/clients/export`,
      query,
      fileFormat,
      documentTitle,
      fileName
    );
  }

  public listClientCashBalance(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<CashBalanceClient>>(`${this.baseUrl}voice-trades/client-ledger/transactions`, {
      axiosConfig: { params },
    });
  }

  public downloadClientCashBalance(
    query: ListQuery,
    fileFormat: ExportListType,
    documentTitle = 'Cash Balance',
    fileName?: string
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(
      `${this.baseUrl}voice-trades/client-ledger/transactions/export`,
      query,
      fileFormat,
      documentTitle,
      fileName
    );
  }

  public listClientMoney(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<ClientMoneyDetails>>(`${this.baseUrl}voice-trades/client-money`, {
      axiosConfig: { params },
    });
  }

  public downloadClientMoney(
    query: ListQuery,
    fileFormat: ExportListType,
    documentTitle = 'Client Money Details Report',
    fileName?: string
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(
      `${this.baseUrl}voice-trades/client-money/export`,
      query,
      fileFormat,
      documentTitle,
      fileName
    );
  }

  public listClientMoneyBankAccount(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<ClientMoney>>(`${this.baseUrl}voice-trades/client-money/bank-account`, {
      axiosConfig: { params },
    });
  }

  public downloadClientMoneyBankAccount(
    query: ListQuery,
    fileFormat: ExportListType,
    documentTitle = 'Client Money Report',
    fileName = 'vtd-client-money'
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(
      `${this.baseUrl}voice-trades/client-money/bank-account/export`,
      query,
      fileFormat,
      documentTitle,
      fileName
    );
  }
}
