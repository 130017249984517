<script setup lang="ts">
import { Client } from 'ah-trades/../ah-api-gateways/models';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { computed, watch } from 'vue';
import { useTradeState } from '../..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const onBehalfOfClient = useOnBehalfOf();

const tradeState = useTradeState();

tradeState.store.useWalletsStore().loadCollaterals({ force: false, clientId: onBehalfOfClient.value?.id });

const collaterals = computed(() => {
  const clientId = onBehalfOfClient.value
    ? onBehalfOfClient.value.id
    : tradeState.store.useAuthStore().loggedInIdentity?.client?.id;

  if (clientId) {
    return tradeState.store.useWalletsStore().getCollaterals(clientId);
  }

  return undefined;
});

watch(onBehalfOfClient, (current: Client | null, previous: Client | null) => {
  if (current && current.id !== previous?.id) {
    tradeState.store.useWalletsStore().loadCollaterals({ force: false, clientId: onBehalfOfClient.value?.id });
  }
});
</script>

<template>
  <div class="card-block">
    <DataRow cols="8" class="main-row margin-in-use">
      <template #label>
        Margin (in use)
        <InfoTooltip :text="$ahTradesState.i18n.t('tooltips.totalMarginRequirement')" />
      </template>
      <span v-if="collaterals && collaterals.currency && collaterals.totalMarginRequirement">
        {{ collaterals.currency }} {{ formatCurrencyValue(collaterals.totalMarginRequirement) }}
      </span>
    </DataRow>
  </div>
</template>

<style lang="scss" scoped>
.main-row {
  font-size: 18px;
}
</style>
