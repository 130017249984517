<script lang="ts" setup>
import { ClientActivityReport, ClientActivityReportRequest } from 'ah-api-gateways';
import VChart from './VChart.vue';
import { makeThemedChartDataset, makeDoughnutChartOptions } from '../helpers/chartConfig';
import { linesColorGreen, linesColorGrey } from '../helpers/graphColors';
import { computed, reactive, watch } from 'vue';
import { useReportsState } from 'ah-reports';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { ChartOptions } from 'chart.js';

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: onRetryFromParentManager,
});

function onRetryFromParentManager(k: string) {
  if (k === 'loadData') {
    loadDates();
  }
}

const props = withDefaults(
  defineProps<{
    fromDate: Date;
    toDate: Date;
    /**
     * Partner ID to filter results by. May be left unset (for use in non-admin setting)
     */
    partnerId?: string;
  }>(),
  {
    partnerId: '',
  }
);

const state = reactive({
  report: null as ClientActivityReport | null,
  options: makeDoughnutChartOptions() as ChartOptions,
  data: makeThemedChartDataset({
    labels: ['Active Accounts', 'Inactive Accounts'],
    colorNames: ['widgets-green', 'widgets-grey'],
  }),
  green: linesColorGreen,
  grey: linesColorGrey,
});

const reportsState = useReportsState();

const dates = computed(() => ({
  from: props.fromDate,
  to: props.toDate,
}));

function loadDates() {
  const filter: ClientActivityReportRequest = {
    from: props.fromDate,
    to: props.toDate,
  };

  if (props.partnerId) {
    filter.partnerId = [props.partnerId];
  }

  requestManager.manager
    .cancelAndNew('loadData', reportsState.services.client.getClientActivityReport(filter))
    .subscribe((response) => {
      state.data.datasets![0].data! = [response.active, response.inactive];

      // Force update by replacing prop reference entirely
      state.options = { ...state.options };
      state.data = { ...state.data };

      state.report = response;
    });
}

watch(
  () => dates,
  () => {
    loadDates();
  },
  {
    immediate: true,
    deep: true,
  }
);

const activePercentage = computed(() => {
  if (state.report) {
    if (state.report.total === 0) {
      return state.report.total.toFixed(1);
    }
    return ((state.report.active / state.report.total) * 100).toFixed(1);
  }
  return 0;
});
</script>

<template>
  <div class="my-accounts-container" v-if="state.report && !requestManager.manager.anyPending">
    <div class="my-accounts-column w-100 h-100">
      <div v-if="state.report" class="account-count text-center mb-lg-3 mb-sm-5">
        <h4 class="count">{{ state.report.total }}</h4>
        <p class="count-label mb-0 pb-0">Total Live Accounts</p>
      </div>
      <div class="legend" v-if="state.report">
        <div class="legend-container">
          <div class="big-dot green-color">&bull;</div>
          <div class="count-label mr-lg-0 mr-sm-2">Active Accounts:</div>
          <div class="count-label">{{ state.report.active }}</div>
        </div>
        <div class="legend-container mt-n3">
          <div class="big-dot grey-color">&bull;</div>
          <div class="count-label mr-lg-0 mr-sm-2">Inactive Accounts:</div>
          <div class="count-label">{{ state.report.inactive }}</div>
        </div>
      </div>
    </div>
    <div class="my-accounts-column">
      <VChart class="chart" type="doughnut" :options="state.options" :data="state.data">
        <div v-if="state.report" class="doughnut-center text-center">
          <h5 class="count">{{ activePercentage }}%</h5>
          <p class="count-label px-1">ACTIVE ACCOUNTS</p>
        </div>
      </VChart>
    </div>
  </div>
</template>

<style lang="scss" scoped>
h3 {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 1em;
}

a {
  padding: 10px;
  &:hover {
    @include themedBackgroundColor($color-highlight-grey);
  }
  span {
    @include themedTextColor($color-primary);
  }
  .arrow-icon {
    width: 0.8em;
    ::v-deep path {
      @include themedPropColor('fill', $color-primary);
    }
  }
}

.my-accounts-container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  height: 100%;
}

.legend {
  @include upToResolution($desktopResolution) {
    font-size: 0.8em;
    white-space: nowrap;
    position: absolute;
    bottom: 8px;
    left: 12px;
  }
  .legend-container {
    display: grid;
    grid-template-columns: 25px auto 30px;
    justify-items: start;
    align-items: center;
  }
}

.chart {
  width: 200px;
  height: 200px;
  @include upToResolution($tabletResolution) {
    width: 170px;
    height: 170px;
    margin-right: -30px;
    transform: translate(-15px, -40px);
  }
}

.account-count {
  @include upToResolution($desktopResolution) {
    width: 100%;
    text-align: left !important;
    font-size: 0.5rem;
  }
  @include desktop {
    font-size: 1rem;
  }
  .count {
    font-size: 4em;
    @include themedTextColor($color-primary);
    font-weight: bold;
  }

  .count-label {
    font-size: 1.5em;
  }
  .count-subtitle {
    font-size: 1em;
    color: $grey;
  }
}

.doughnut-center {
  width: 100%;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 0;
  line-height: 19px;
  text-align: center;
  margin-top: -20px;
  .count {
    font-size: 2em;
    @include themedTextColor($color-text);
  }

  .count-label {
    margin-top: 0.5em;
    font-size: 0.8em;
    @include upToResolution($desktopResolution) {
      font-size: 10px;
    }
  }
}

.big-dot {
  font-size: 2em;
}
.green-color {
  @include themedTextColor($color-widgets-green);
}
.grey-color {
  @include themedTextColor($color-widgets-grey);
}
</style>
