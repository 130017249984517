<template>
  <span>
    <BModal title="Users to notify" modal-class="side-modal modal-lg" ref="modal" hide-footer>
      <p class="text-secondary mt-n3">Select members to be notified of the following:</p>
      <div class="card-block">
        <template v-if="!isRateAlert && config.name">
          <h3 class="mb-0">{{ config.name }}</h3>
          <p class="text-secondary mb-0">{{ config.description }}</p>
        </template>
        <template v-else>
          {{ notification.currencyPair }} rate is {{ rateAlertInequalityTypeStrings[notification.inequality] }}
          {{ notificationRate }}
        </template>
      </div>
      <ValidatedForm :fm="allUsersForm" class="mt-3" />
      <template v-if="!allUsersForm.allUsers">
        <TargetSearchSelect
          class="mb-3"
          :types="targetTypes"
          :baseCountryCode="baseCountryCode"
          @target-selected="onTargetSelected"
        />
        <div class="card-block recipients-list mb-3" v-if="hasRecipients">
          <template v-if="individualRecipients.length">
            <h3 class="recipients-list-title">Users</h3>
            <BoxGrid align-content="start" align-h="start" class="notification-targets-holder">
              <BoxGridItem
                class="notification-target-item-holder"
                :key="target.individual.id"
                v-for="target in individualRecipients"
              >
                <NotificationTargetBlock
                  class="notification-target-item"
                  :target="target"
                  @delete="deleteTarget(target)"
                />
              </BoxGridItem>
            </BoxGrid>
          </template>
          <template v-if="groupRecipients.length">
            <h3 class="recipients-list-title">Groups</h3>
            <BoxGrid align-content="start" align-h="start" class="notification-targets-holder">
              <BoxGridItem
                class="notification-target-item-holder"
                :key="target.group.id"
                v-for="target in groupRecipients"
              >
                <NotificationTargetBlock
                  class="notification-target-item"
                  :target="target"
                  @delete="deleteTarget(target)"
                />
              </BoxGridItem>
            </BoxGrid>
          </template>
          <template v-if="emailRecipients.length">
            <h3 class="recipients-list-title">External Emails</h3>
            <BoxGrid align-content="start" align-h="start" class="notification-targets-holder">
              <BoxGridItem
                class="notification-target-item-holder"
                :key="target.email"
                v-for="target in emailRecipients"
              >
                <NotificationTargetBlock
                  class="notification-target-item"
                  :target="target"
                  @delete="deleteTarget(target)"
                />
              </BoxGridItem>
            </BoxGrid>
          </template>
          <template v-if="phoneRecipients.length">
            <h3 class="recipients-list-title">External phone numbers</h3>
            <BoxGrid align-content="start" align-h="start" class="notification-targets-holder">
              <BoxGridItem
                class="notification-target-item-holder"
                :key="target.phoneNumber"
                v-for="target in phoneRecipients"
              >
                <NotificationTargetBlock
                  class="notification-target-item"
                  :target="target"
                  @delete="deleteTarget(target)"
                />
              </BoxGridItem>
            </BoxGrid>
          </template>
        </div>
      </template>
      <div class="buttons">
        <VButton class="btn-secondary mr-2" @click="cancel">Cancel</VButton>
        <VButton class="done-btn" @click="save">Done</VButton>
      </div>
    </BModal>
    <slot v-bind="{ show }">
      <VButton blurOnClick @click="show">Select Target</VButton>
    </slot>
  </span>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { radioField } from 'ah-common-lib/src/form/models';
import {
  TargetSelection,
  GroupTargetSelection,
  IndividualTargetSelection,
  EmailTargetSelection,
  matchesRecipient,
  PhoneTargetSelection,
  NotificationSetting,
  RateAlertSetting,
  RegistrationStatus,
  NotificationSettingConfig,
  IndividualType,
} from 'ah-api-gateways';
import TargetSearchSelect from '@/app/components/user/TargetSearchSelect.vue';
import NotificationTargetBlock from '@/app/components/settings/notifications/NotificationTargetBlock.vue';
import { rateAlertInequalityTypeStrings } from 'ah-api-gateways';
import { useAuthStore } from '@/app/store/authStore';

const allUsersFM = () =>
  makeFormModel({
    name: 'allUsersForm',
    fieldType: 'form',
    fields: [
      radioField(
        'allUsers',
        '',
        [
          { label: 'All users', value: true },
          { label: 'Choose users', value: false },
        ],
        {
          defaultValue: true,
          inline: true,
        }
      ),
    ],
  });

@Component({
  components: { TargetSearchSelect, NotificationTargetBlock },
})
export default class NotificationTargetEditModal extends Vue {
  $refs!: {
    modal: BModal;
  };

  @Prop({ required: true }) config!: NotificationSettingConfig;

  @Prop({ required: true }) notification!: NotificationSetting | RateAlertSetting;

  notificationRecipients: TargetSelection[] = [];

  allUsersForm = allUsersFM();

  get authStore() {
    return useAuthStore();
  }

  get isRateAlert() {
    return !!(this.notification as RateAlertSetting).inequality;
  }

  get baseCountryCode() {
    return this.authStore.loggedInIdentity!.address?.countryCode;
  }

  @Watch('notification', { immediate: true })
  resetData() {
    this.allUsersForm.allUsers = this.notification.to.length === 0;

    this.notificationRecipients = [...this.notification.to];
  }

  get rateAlertInequalityTypeStrings() {
    return rateAlertInequalityTypeStrings;
  }

  get notificationRate() {
    if ((this.notification as RateAlertSetting).rate) {
      return (this.notification as RateAlertSetting).rate.toFixed(3);
    }

    return '';
  }

  get clientId() {
    return this.authStore.loggedInIdentity?.client?.id;
  }

  get partnerId() {
    return this.authStore.loggedInIdentity?.partner?.id;
  }

  get hasRecipients() {
    return !!(
      this.individualRecipients.length ||
      this.groupRecipients.length ||
      this.phoneRecipients.length ||
      this.emailRecipients.length
    );
  }

  get individualRecipients() {
    return this.notificationRecipients.filter(
      (i) => (i as IndividualTargetSelection).individual
    ) as IndividualTargetSelection[];
  }

  get groupRecipients() {
    return this.notificationRecipients.filter((i) => (i as GroupTargetSelection).group) as GroupTargetSelection[];
  }

  get phoneRecipients() {
    return this.notificationRecipients.filter((i) => (i as PhoneTargetSelection).phoneNumber) as PhoneTargetSelection[];
  }

  get emailRecipients() {
    return this.notificationRecipients.filter((i) => (i as EmailTargetSelection).email) as EmailTargetSelection[];
  }

  get targetTypes() {
    const targetTypes: any = {
      phone: {
        disallowed: this.phoneRecipients.map((r) => r.phoneNumber),
      },
      email: {
        disallowed: this.emailRecipients.map((r) => r.email),
      },
    };

    const statusFilters = [RegistrationStatus.PENDING, RegistrationStatus.APPROVED, RegistrationStatus.REJECTED];

    const peopleFilters = this.clientId
      ? {
          clientId: this.clientId,
          type: [IndividualType.CLIENT_ADMIN, IndividualType.CLIENT_INDIVIDUAL],
          status: statusFilters,
        }
      : {
          partnerId: this.partnerId,
          type: [IndividualType.PARTNER_ADMIN, IndividualType.PARTNER_AGENT],
          status: statusFilters,
        };

    targetTypes.individual = {
      disallowed: this.individualRecipients.map((r) => r.individual.id),
      filters: peopleFilters,
    };

    targetTypes.group = {
      disallowed: this.groupRecipients.map((r) => r.group.id),
      filters: peopleFilters,
    };

    return targetTypes;
  }

  isInRecipientsList(target: TargetSelection) {
    return !!this.notificationRecipients.find((i) => matchesRecipient(i, target));
  }

  onTargetSelected(target: TargetSelection) {
    if (!this.isInRecipientsList(target)) {
      this.notificationRecipients.push(target);
    }
  }

  deleteTarget(target: TargetSelection) {
    const index = this.notificationRecipients.findIndex((i) => matchesRecipient(i, target));
    if (index > -1) {
      this.notificationRecipients.splice(index, 1);
    }
  }

  show() {
    this.$refs.modal.show();
    this.resetData();
  }

  save() {
    this.$emit('update:notification', {
      ...this.notification,
      to: this.allUsersForm.allUsers
        ? []
        : this.notificationRecipients.filter((i) => !(i as any).client && !(i as any).partner),
    });
    this.$refs.modal.hide();
  }

  cancel() {
    this.$refs.modal.hide();
  }
}
</script>
<style lang="scss">
.recipients-list-title {
  margin-bottom: math.div($padded-space, 3);
}

.notification-targets-holder {
  display: flex;
  margin-bottom: math.div($padded-space, 2);

  &:last-child {
    margin-bottom: 0;
  }

  .notification-target-item-holder {
    flex-basis: 33%;
    flex-grow: 0;
    min-width: 200px;

    .notification-target-item {
      width: 100%;
    }
  }
}
</style>
