<script lang="ts" setup>
import { PaymentReport } from 'ah-api-gateways';
import { BModal } from 'bootstrap-vue';
import { ref, PropType, computed } from 'vue';
import { usePaymentState } from 'ah-payments';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const paymentCancelModal = ref<InstanceType<typeof BModal> | null>(null);

const paymentState = usePaymentState();
const requestManager = useRequestManager({
  exposeToParent: true,
}).manager;

const onBehalfOfClient = useOnBehalfOf();

const emit = defineEmits<{
  (e: 'cancel-payment'): void;
}>();

const props = defineProps({
  payment: {
    type: Object as PropType<PaymentReport>,
    required: true,
  },
});

function showModal() {
  paymentCancelModal.value!.show();
}

function hideModal() {
  paymentCancelModal.value!.hide();
}

function deletePayment() {
  requestManager
    .cancelAndNew(
      'cancelPayment',
      paymentState.services.payments.cancelPayment(props.payment?.id, onBehalfOfClient.value?.id).pipe()
    )
    .subscribe(
      () => {
        paymentState.toast.success(`Payment ${props.payment?.referenceNumber} cancelled successfully`);
        emit('cancel-payment');
        hideModal();
      },
      () => {
        paymentState.toast.error('An unexpected problem has occurred. Please try again later.');
        hideModal();
      }
    );
}

const isCancelling = computed(() => {
  return requestManager.requestStates.cancelPayment === 'pending';
});
</script>

<template>
  <span class="ml-auto mr-auto mt-auto d-block btn">
    <BModal centered modal-class="payment-modal" ref="paymentCancelModal" hide-footer hide-header-close>
      <p class="text-center my-b">
        Are you sure you want to cancel this payment to {{ props.payment?.beneficiaryName }}
      </p>
      <div class="d-flex align-items-center justify-content-end">
        <VButton :loading="isCancelling" class="btn btn-secondary" @click="hideModal()">Cancel</VButton>
        <VButton :loading="isCancelling" class="ml-4 btn btn-danger" @click="deletePayment()">Confirm</VButton>
      </div>
    </BModal>

    <slot v-bind="{ showModal }">
      <VButton class="ml-4 btn btn-secondary" @click="showModal()"></VButton>
    </slot>
  </span>
</template>

<style lang="scss" scoped>
::v-deep .payment-modal .modal-dialog {
  min-width: 40em;
}

::v-deep .obo-approval-modal {
  .modal-content {
    padding: 2em 1em 1em 1em;
    .status-icon-pending {
      width: 25px;
      height: 25px;
      @include themedTextColor($color-yellow-highlight);
    }
    .btn {
      width: 104px;
    }
    .btn-outline {
      @include themedBackgroundColor($color-secondary);
      @include themedTextColor($color-secondary);
    }
  }
  .modal-dialog {
    min-width: 35em;
  }
}
</style>
