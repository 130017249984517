<script lang="ts" setup>
import { TimeFrames } from 'ah-common-lib/src/constants/timeframes';
import { makeFormModel, getChildModel, setState, submitForm } from 'ah-common-lib/src/form/helpers';
import { radioField } from 'ah-common-lib/src/form/models';
import { NonTradeableDays, HedgingInstruments, CutoffTimeModel } from 'ah-api-gateways';
import { FormEvent, FieldOptionObj, FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { TimeFrameDate } from 'ah-trades/src/models/date';
import { startOfToday, format, addDays, differenceInCalendarDays, subMinutes } from 'date-fns';
import { combineLatest } from 'rxjs';
import { stripZoneOffset } from 'ah-common-lib/src/helpers/time';
import { SECOND, MINUTE } from 'ah-common-lib/src/constants/time';
import { catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { onBeforeUnmount, reactive, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useTradeState } from '../..';

import { ApiError } from 'ah-requests';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const props = defineProps<{
  /**
   * Trade direction
   * May be null to account for synchronous actions, but is expected for proper behaviour
   *
   * When set, date options for a Spot settlement date are updated
   */
  tradeDirection?: string;
  /**
   * TimeFrameDate object
   *
   * synchronizable via `update:timeFrame` or .sync modifier
   */
  timeFrame?: TimeFrameDate;
}>();

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadDateConstraints') {
      setForwardDates();
    }
  },
});

/**
 * Cutoff time and date used for the form values
 *
 * Will be included as part of the timeframe object exported, so as to allow determining the validity of the values
 * (After the cutoff time, form should reload, and values may not be valid anymore)
 */
const cutoffDate = ref<CutoffTimeModel | null>(null);

const updateDatesTimeout = ref<number | null>(null);

const onBehalfOfClient = useOnBehalfOf();

const tradeState = useTradeState();

const emit = defineEmits<{
  /**
   * Emits 'update:timeFrame' with a TimeFrameDate object
   */
  (e: 'update:timeFrame', value: TimeFrameDate): void;
  /**
   * Emits 'validation-error' with the API error object
   */
  (e: 'validation-error', value: ApiError): void;
}>();

const settlementDateForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'settlementDateForm',
    title: '',
    fieldType: 'form',
    fields: [radioField('dateOption', '', [])],
  }),
  validation: null,
});

function touchForms() {
  if (settlementDateForm.validation) {
    submitForm(settlementDateForm.validation);
  }
}

function isValid() {
  return !settlementDateForm.validation?.$invalid;
}

watch(
  () => props.timeFrame,
  () => {
    if (props.timeFrame) {
      settlementDateForm.form.dateOption = props.timeFrame.targetDate;
    }
  },
  { immediate: true }
);

function formatDate(dateString: string) {
  const date = new Date(dateString);
  const diff = differenceInCalendarDays(date, stripZoneOffset(startOfToday()));

  if (diff === 0) {
    return 'Today';
  }
  if (diff === 1) {
    return 'Tomorrow';
  }
  return format(date, 'iiii do MMMM');
}

function loadCutoffTime() {
  if (props.tradeDirection) {
    return tradeState.services.pricingEngine.getCutoffTime(props.tradeDirection, onBehalfOfClient.value?.id);
  }
  return throwError(`Can't load without trade direction`);
}

function emitDate() {
  emit('update:timeFrame', {
    isForward: false,
    targetDate: settlementDateForm.form.dateOption,
    targetTimeFrame: TimeFrames.OTHER,
    cutoffDateTime: cutoffDate.value?.firstConversionCutoffDatetime,
  } as TimeFrameDate);
}

function setForwardDates() {
  if (props.tradeDirection) {
    requestManager.manager
      .sameOrCancelAndNew(
        'loadDateConstraints',
        combineLatest([
          tradeState.services.pricingEngine.getNonTradeableDays(
            HedgingInstruments.FX_SPOT,
            props.tradeDirection,
            onBehalfOfClient.value?.id
          ),
          loadCutoffTime(),
        ]),
        props.tradeDirection
      )
      .pipe(
        catchError((error) => {
          if (error.response.status === 400) {
            emit('validation-error', error.response.data);
            return of(null);
          } else {
            throw error;
          }
        })
      )
      .subscribe((payload: [NonTradeableDays, CutoffTimeModel] | null) => {
        if (!payload) return;
        const nonTradeableDays = payload[0];
        cutoffDate.value = payload[1];
        const targetDate = getChildModel(settlementDateForm.form, 'dateOption')!;
        const disallowedDates = [...nonTradeableDays.holidays, ...nonTradeableDays.weekend];
        const dateOptions: FieldOptionObj[] = [];

        let date = startOfToday();

        // In order to protect against cutoff time issues, we:
        // - Only allow T+0 up to 10 minutes before firstConversionCutoffDatetime, even if still within the trading day
        // - Recalculate cutoffTimes on the firstConversionCutoffDatetime, or after 5 minutes

        dateOptions.push({
          value: stripZoneOffset(date).toISOString(),
          label: `T+0`,
          disabled:
            disallowedDates.includes(format(date, 'dd-MM-yyyy')) ||
            stripZoneOffset(date) < new Date(cutoffDate.value.firstConversionDate) ||
            Date.now() > subMinutes(new Date(cutoffDate.value.firstConversionCutoffDatetime), 10).valueOf(),
        });

        updateDatesTimeout.value = window.setTimeout(
          setForwardDates,
          Math.max(
            new Date(cutoffDate.value.firstConversionCutoffDatetime).valueOf() - Date.now() - 1 * SECOND,
            5 * MINUTE
          )
        );

        date = addDays(date, 1);

        while (dateOptions.length < 3) {
          if (!disallowedDates.includes(format(date, 'dd-MM-yyyy'))) {
            dateOptions.push({
              value: stripZoneOffset(date).toISOString(),
              label: `T+${dateOptions.length}`,
            });
          }
          date = addDays(date, 1);
        }
        setState(targetDate, 'options', dateOptions);

        const currentOption = dateOptions.find(
          (o) =>
            !o.disabled &&
            differenceInCalendarDays(new Date(settlementDateForm.form.dateOption), new Date((o as any).value)) === 0
        );

        if (currentOption) {
          settlementDateForm.form.dateOption = (currentOption as any).value;
        } else {
          settlementDateForm.form.dateOption = dateOptions.find((o) => !o.disabled)?.value;
        }
        emitDate();
      });
  }
}

watch(() => props.tradeDirection, setForwardDates, { immediate: true });

function onDateChange(formEvent: FormEvent) {
  if (formEvent.event === 'form-field-set-value' && !settlementDateForm.validation?.$invalid) {
    emitDate();
  }
}

onBeforeUnmount(() => {
  if (updateDatesTimeout.value) {
    clearTimeout(updateDatesTimeout.value);
  }
});

defineExpose({ touchForms, isValid });

emitDate();

if (settlementDateForm.validation) {
  submitForm(settlementDateForm.validation);
}
</script>

<template>
  <ValidatedForm
    :fm="settlementDateForm.form"
    @form-event="onDateChange"
    :validation.sync="settlementDateForm.validation"
  >
    <template #settlementDateForm.dateOption:option="{ option }">
      {{ option.label }} - {{ formatDate(option.value) }} <span class="fee">(+ GBP 0.00)</span>
    </template>
  </ValidatedForm>
</template>

<style lang="scss" scoped>
.fee {
  display: inline-block;
  font-size: 0.8em;
  @include themedTextColor($color-text-secondary);
}
</style>
