<template>
  <div :class="['accordion-box', toggleable ? 'toggleable' : '']">
    <div class="header" @click="toggleOpen()">
      <slot name="title" v-bind="{ toggleOpen }">{{ title }}</slot>
      <div v-if="toggleable">
        <IconChevronDown :class="['toggle-icon', { open: isOpen }]" />
      </div>
    </div>
    <ExpandTransition appear>
      <div class="body" v-if="isOpen || lazy === false" v-show="isOpen">
        <slot />
      </div>
    </ExpandTransition>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class AccordionBox extends Vue {
  @Prop({ default: '' }) title!: string;

  @Prop({ default: undefined }) open?: boolean | string;

  @Prop({ default: false }) lazy?: boolean | string;

  @Prop({ default: true }) toggleable?: boolean;

  private isOpen: boolean = false;

  @Watch('open', { immediate: true })
  onOpenChange() {
    this.isOpen = this.open !== false;
  }

  toggleOpen(open?: boolean) {
    if (this.toggleable !== false) {
      const value = open !== undefined ? open : !this.isOpen;
      this.isOpen = value;
      this.$emit('update:open', value);
    }
  }
}
</script>
<style lang="scss" scoped>
.accordion-box {
  &.toggleable .header {
    cursor: pointer;
  }
  .header {
    position: relative;

    .toggle-icon {
      position: absolute;
      font-size: 1.6rem;
      right: 0;
      top: 0;
      cursor: pointer;

      &.open {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
