<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import Listing from 'ah-common-lib/src/common/components/listing/Listing.vue';
import { PaginatedQuery, ExportListType, Address, ClientListResponse } from 'ah-api-gateways';
import { clientTableFields } from '@/app/components/clients/tableFields/clientTableFields';
import ClientOnboardingStatusPill from '@/app/components/clients/ClientOnboardingStatusPill.vue';
import ClientAssigneesList from './ClientAssigneesList.vue';
import ClientIndividualsList from './ClientIndividualsList.vue';
import { useSettingsStore } from '@/app/store/settingsModule';
import { countryNameFromCC } from 'ah-common-lib/src/helpers/countries';
import { formatDate, SIMPLE_DATE_FORMAT } from 'ah-common-lib/src/helpers/time';

@Component({
  components: {
    ClientOnboardingStatusPill,
    ClientAssigneesList,
    ClientIndividualsList,
  },
})
export default class ClientListing extends Listing<ClientListResponse> {
  @Prop({ default: () => ({ tableFields: clientTableFields }) }) config!: { tableFields: any[] };

  @Prop({ default: false }) detailess!: boolean | string;

  @Prop({ required: false }) documentsTitle?: string;

  formatDate = formatDate;

  SIMPLE_DATE_FORMAT = SIMPLE_DATE_FORMAT;

  created() {
    this.settingsStore.loadCountries();
  }

  get settingsStore() {
    return useSettingsStore();
  }

  protected loadDataRequest(query: PaginatedQuery) {
    return this.$services.client.listClients(query);
  }

  protected downloadDataRequest(type: string, query: { [key: string]: any }) {
    return this.$services.client.downloadClientList(query, type as ExportListType, this.documentsTitle);
  }

  get countryName() {
    return (address?: Address) => {
      return address?.countryCode ? this.countryNameFromCC(address.countryCode) : '';
    };
  }

  get isStackedView() {
    return this.$mediaQuery.is('smDown');
  }

  countryNameFromCC(value: string) {
    return countryNameFromCC(value, this.settingsStore.allCountries) || '';
  }
}
</script>
<template>
  <PaginatedTable
    fixed
    hover
    animateCols
    :nowrap="!isStackedView"
    :class="{ 'no-details': detailess !== false }"
    class="clients-table"
    items-label="clients"
    :data-load-state="dataLoadState"
    :data-download-state="dataDownloadState"
    :data="data"
    :fields="config.tableFields"
    :selectedItems="selectedItems"
    v-on="$listeners"
    v-bind="$attrs"
    @sort="loadData"
  >
    <template #cell(individuals)="{ item }">
      <span v-if="item.type === 'INDIVIDUAL'"> - </span>
      <ClientIndividualsList v-else :client="item" :maxItems="3" />
    </template>
    <template #cell(createdAt)="{ item }">
      {{ formatDate(item.createdAt, SIMPLE_DATE_FORMAT) }}
    </template>
    <template #cell(assignees)="{ item }">
      <ClientAssigneesList :client="item" />
    </template>
    <template #cell(email)="{ item }">
      <span v-if="item.type === 'INDIVIDUAL'">
        {{ item.individualDetails ? item.individualDetails.email : '-' }}
      </span>
    </template>
    <template #cell(country)="{ item }">
      <span v-if="item.type === 'INDIVIDUAL'">
        {{ countryName(item.individualDetails.address) }}
      </span>
      <span v-else class="country-name">
        {{ countryName(item.companyDetails.address) }}
      </span>
    </template>
    <template #cell(type)="{ item }">
      {{ item.type === 'INDIVIDUAL' ? 'Individual' : 'Business' }}
    </template>
    <template #cell(status)="{ item }">
      <ClientOnboardingStatusPill :client="item" />
    </template>
    <!-- collapse row details -->
    <template #row-details="row">
      <slot name="row-details" v-bind="row" />
    </template>
  </PaginatedTable>
</template>
<style lang="scss" scoped>
.edit-icon-block {
  cursor: pointer;

  .edit-icon {
    @include themedTextColor($color-text-secondary);

    &:not(.disabled):hover {
      @include themedTextColor($color-primary);
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}

.country-name {
  opacity: 0.5;
}
</style>
