<script lang="ts" setup>
import { AmountType, Trade, getCcyByAmountType } from 'ah-api-gateways';
import { computed } from 'vue';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';

const props = defineProps<{
  item: Trade;
}>();

const buyCCY = computed(() => getCcyByAmountType(props.item, AmountType.BUY));

const sellCCY = computed(() => getCcyByAmountType(props.item, AmountType.SELL));
</script>

<template>
  <div class="some-class">
    <div>
      Selling:
      {{ sellCCY.currency }}
      {{ formatCurrencyValue(item.hasDrawdowns ? sellCCY.remainingClientAmount : sellCCY.clientAmount) }}
      <template v-if="item.hasDrawdowns">
        <br />
        <span class="text-secondary">
          Original:
          <span class="small-currency">{{ sellCCY.currency }}</span>
          {{ formatCurrencyValue(sellCCY.clientAmount) }}
        </span>
      </template>
    </div>
    <div>
      Buying:
      {{ buyCCY.currency }}
      {{ formatCurrencyValue(item.hasDrawdowns ? buyCCY.remainingClientAmount : buyCCY.clientAmount) }}
      <template v-if="item.hasDrawdowns">
        <br />
        <span class="text-secondary">
          Original:
          <span class="small-currency">{{ buyCCY.currency }}</span>
          {{ formatCurrencyValue(buyCCY.clientAmount) }}
        </span>
      </template>
    </div>
  </div>
</template>
