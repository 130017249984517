import {
  BankingScheme,
  BeneficiaryFieldStatusData,
  BeneficiaryType,
  CurrencyData,
  FeePaymentType,
  LocalizedBeneficiaryFieldData,
  PaymentLimitData,
  PaymentRailData,
  PurposeCodeResponse,
  RoutingCodeData,
} from '../models';
import { HttpBasedService, HttpOptions, HttpService, deterministicJSON } from 'ah-requests';

export class BankingReferenceService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'bankingReferenceService' } },
      },
    });
  }

  public listBeneficiariesFields(
    params: {
      currency: string;
      bankingScheme?: BankingScheme;
      beneficiaryType: BeneficiaryType;
    },
    options?: Omit<HttpOptions<LocalizedBeneficiaryFieldData[]>, 'cache'>
  ) {
    return this.get<LocalizedBeneficiaryFieldData[]>(`${this.baseUrl}beneficiary-fields`, {
      axiosConfig: {
        params,
      },
      options: {
        ...options,
        cache: {
          type: 'use',
          cacheKey: 'beneficiaryfields',
          itemKey: deterministicJSON(params),
        },
      },
    });
  }

  public updateBeneficiariesFields(
    params: BeneficiaryFieldStatusData[],
    options?: HttpOptions<LocalizedBeneficiaryFieldData[]>
  ) {
    return this.put<LocalizedBeneficiaryFieldData[]>(`${this.baseUrl}beneficiary-fields`, params, {
      options: { ...options },
    });
  }

  public listCurrencies(options?: Omit<HttpOptions<CurrencyData[]>, 'cache'>) {
    return this.get<CurrencyData[]>(`${this.baseUrl}currencies`, {
      axiosConfig: {
        params: {
          enabled: true,
        },
      },
      options: {
        ...options,
        cache: {
          type: 'use',
          cacheKey: 'bankaccountsfields',
          itemKey: 'currencies',
        },
      },
    });
  }

  public listRoutingCodes(options?: Omit<HttpOptions<RoutingCodeData[]>, 'cache'>) {
    return this.get<RoutingCodeData[]>(`${this.baseUrl}routing-code-types`, {
      options: {
        ...options,
        cache: {
          type: 'use',
          cacheKey: 'routingcodetypes',
          itemKey: 'routingcodetypes',
        },
      },
    });
  }

  public listPurposeCodes(
    params: {
      currency: string;
      bankAccountCountry: string;
      beneficiaryType: BeneficiaryType;
    },
    options?: Omit<HttpOptions<PurposeCodeResponse>, 'cache'>
  ) {
    return this.get<PurposeCodeResponse>(`${this.baseUrl}payment-purpose-codes`, {
      axiosConfig: {
        params: {
          ...params,
          // FIXME remove once API renames query string
          entityType: params.beneficiaryType,
        },
      },
      options: {
        ...options,
        cache: {
          type: 'use',
          cacheKey: 'paymentpurposecodes',
          itemKey: deterministicJSON(params),
        },
      },
    });
  }

  public listPaymentRails(
    params: {
      currency?: string;
      bankingScheme?: BankingScheme;
      type?: FeePaymentType;
    },
    options?: Omit<HttpOptions<PaymentRailData>, 'cache'>
  ) {
    return this.get<PaymentRailData[]>(`${this.baseUrl}payment-rails`, {
      options,
      axiosConfig: {
        params: {
          ...params,
        },
      },
    });
  }

  public getPaymentLimit(
    params: {
      beneficiaryBankingScheme: string;
      beneficiaryCurrency: string;
    },
    options?: HttpOptions<PaymentLimitData>
  ) {
    return this.get<PaymentLimitData>(`${this.baseUrl}payment-limit`, {
      options,
      axiosConfig: { params },
    });
  }
}
