<template>
  <div class="update-card my-2" :class="{ 'no-bg': $mediaQuery.is('smDown') }">
    <div class="mb-1">
      <span class="d-flex">
        <IconImprovements class="icon" v-if="update.type === UpdateType.IMPROVEMENTS" />
        <IconNewFeature class="icon" v-if="update.type === UpdateType.NEW_FEATURES" />
        <IconBugFix class="icon" v-if="update.type === UpdateType.BUG_FIXES" />
        <IconSuccess class="icon" v-if="update.type === UpdateType.HINTS" />
        &nbsp;
        <h4>{{ UpdateTypeDescriptions[update.type] }}</h4>
      </span>
      <p class="mt-1">{{ update.description }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { UpdateEntry, UpdateType, UpdateTypeDescriptions } from 'ah-api-gateways';

@Component
export default class UpdateEntryCard extends Vue {
  @Prop({ required: true }) update!: UpdateEntry;

  get UpdateType() {
    return UpdateType;
  }

  get UpdateTypeDescriptions() {
    return UpdateTypeDescriptions;
  }
}
</script>
<style lang="scss" scoped>
.update-card {
  padding: 0.8rem;

  .update-dot {
    @include themedTextColor($color-primary-light);
    font-size: 1.6em;
    line-height: 0.8em;
    cursor: pointer;
  }

  .icon {
    width: 1em;
    align-self: center;
  }
}
</style>
