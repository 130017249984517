<script lang="ts" setup>
import { ref } from 'vue';
import CloseIcon from '../icons/components/CloseIcon.vue';
import ToastSuccessIcon from './ToastSuccessIcon.vue';
import ToastDangerIcon from './ToastDangerIcon.vue';
import ToastWarningIcon from './ToastWarningIcon.vue';
import ToastInfoIcon from './ToastInfoIcon.vue';
import { VButton } from '../common/components';
import { defineToastComponentProps } from './toastInterfaces';

const emit = defineEmits<{
  (m: 'close'): void;
}>();

const props = defineProps({ ...defineToastComponentProps() });

const pendingActions = ref<boolean[]>([]);

function triggerAction(index: number) {
  if (props.actions[index]) {
    pendingActions.value[index] = true;
    Promise.resolve(props.actions[index].method()).finally(() => {
      pendingActions.value[index] = false;
    });
  }
}

function close() {
  emit('close');
}
</script>
<template>
  <div class="toast-wrapper">
    <div :class="`v-toast toast-${toastType} toast-${position} show`">
      <div class="toast-icon">
        <ToastSuccessIcon v-if="toastType === 'success'" class="toast-svg" />
        <ToastDangerIcon v-else-if="toastType === 'danger'" class="toast-svg" />
        <ToastWarningIcon v-else-if="toastType === 'warning'" class="toast-svg" />
        <ToastInfoIcon v-else-if="toastType === 'info'" class="toast-svg" />
      </div>
      <div class="toast-content">
        <h4 class="header">
          {{ title }}
        </h4>
        <div v-if="isHtml" v-html="message" />
        <p class="toast-message" v-else>
          {{ message }}
        </p>
        <div class="toast-actions text-right" v-if="actions && actions.length">
          <VButton
            v-for="(action, index) in actions"
            :key="index"
            :class="action.class"
            :label="action.title"
            :loading="pendingActions[index] === true"
            @click="triggerAction(index)"
          />
        </div>
      </div>
      <div v-if="dismiss" class="toast-close" @click="close()">
        <CloseIcon class="toast-svg" />
      </div>
    </div>
  </div>
</template>
