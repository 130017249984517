<script lang="ts" setup>
import { FeeDirection, FeeReason, FeeState, PaginatedQuery, feeStateLabels, feeDirectionLabels } from 'ah-api-gateways';
import { feeAdjustmentTableFields } from 'ah-trades/src/models/feesTableFields';
import { FeeType, SingleClientOutstadingFee } from 'ah-api-gateways/models/feesAndCharges/fees';
import BankAccountRail from 'ah-beneficiaries/src/components/bankAccounts/BankAccountRail.vue';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { PropType, computed, onBeforeMount, ref, useAttrs, useListeners } from 'vue';
import { defineUseManagedListingProps, useManagedListing, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { useTradeState } from '../..';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

const props = defineProps({
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    required: false,
    default: () => ({ tableFields: feeAdjustmentTableFields }),
  },
  clientOrPartnerId: {
    type: String,
    required: true,
  },
  userType: {
    type: String as PropType<'client' | 'partner'>,
    required: true,
    default: 'client',
  },
  ...defineUseManagedListingProps<SingleClientOutstadingFee>(),
});

const tradeState = useTradeState();

const attrs = useAttrs();

const listeners = useListeners();

const requestManager = useRequestManager().manager;

const feeTypesOptions = ref<FeeType[]>([]);

interface SingleClientOutstadingFeeEmits extends UseManagedListingEmits<SingleClientOutstadingFee> {}

const emit = defineEmits<SingleClientOutstadingFeeEmits>();

const managedListing = useManagedListing<SingleClientOutstadingFee>({
  loadDataRequest: (query: PaginatedQuery) =>
    tradeState.services.fees.listOwnerOutstandingFees({
      ...query,
      [props.userType === 'client' ? 'clientId' : 'partnerId']: props.clientOrPartnerId,
      ...(props.userType !== 'client' && { excludeClient: true }),
    }),
  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager: requestManager,
});

function stateLabel(fee: SingleClientOutstadingFee) {
  return feeStateLabels[fee.state as any as FeeState];
}

function feeDirectionDescription(feeDirection: FeeDirection) {
  return feeDirectionLabels[feeDirection];
}

function feeTypeDescription(feeType: FeeReason) {
  return feeTypesOptions.value?.find((type) => type.value === feeType)?.description;
}

function getFeeTypes() {
  requestManager
    .sameOrCancelAndNew('getFeeTypes', tradeState.services.fees.getFeeTypes())
    .subscribe((getFeeTypesResponse: FeeType[]) => {
      feeTypesOptions.value = getFeeTypesResponse;
    });
}

onBeforeMount(() => {
  getFeeTypes();
});

defineExpose({ loadData: managedListing.loadData });
</script>

<template>
  <PaginatedTable
    fixed
    hover
    animateCols
    items-label="fees"
    v-on="{ ...managedListing.listeners, ...listeners }"
    v-bind="{ ...managedListing.bindings, ...attrs }"
  >
    <template v-slot:cell(createdAt)="{ item }">
      {{ formatDate(item.createdAt) }}
    </template>
    <template v-slot:cell(feeDirection)="{ item }">
      {{ feeDirectionDescription(item.direction) }}
    </template>
    <template v-slot:cell(feeType)="{ item }">
      {{ feeTypeDescription(item.reason) }}
    </template>
    <template v-slot:cell(bankingScheme)="{ item }">
      <BankAccountRail :currency="item.currency" :bankingScheme="item.bankingScheme" />
    </template>
    <template v-slot:cell(chargeType)="{ item }">
      {{ item.chargeType || '-' }}
    </template>
    <template v-slot:cell(amount)="{ item }">
      {{ formatCurrencyValue(item.amount) }}
    </template>
    <template v-slot:cell(state)="{ item }">
      {{ stateLabel(item) }}
    </template>
  </PaginatedTable>
</template>

<style lang="scss" scoped>
::v-deep {
  td,
  th:not(:first-child) * {
    text-align: right;
  }
}
</style>
