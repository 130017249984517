var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"currency-exchange form"},[_c('div',{staticClass:"currency-selection"},[_c('div',{staticClass:"from"},[_c('label',{staticClass:"field-group-field-label currency-label"},[_vm._v(" Converting from: ")]),_c('VueSelect',{ref:"sell CurrencySelect",class:[
          'field-group-field-input hide-error currency-select',
          _vm.inputClass,
          { [_vm.inputErrorClass]: _vm.fieldErrorsShown },
        ],attrs:{"value":_vm.sellCurrency,"clearable":false,"options":_vm.sellCurrencies,"disabled":_vm.readonly},on:{"input":_vm.onSellCurrencyChange},scopedSlots:_vm._u([_vm._l((['option', 'selected-option']),function(optionName){return {key:optionName,fn:function(option){return [(option)?_c('span',{key:optionName,staticClass:"currency-select-option"},[_vm._v(" "+_vm._s(option.label)+" "),(_vm.displaySymbols)?_c('span',{staticClass:"symbol"},[_vm._v(" "+_vm._s(_vm.getCurrencyObj(option.label).symbol)+" ")]):_vm._e()]):_vm._e()]}}})],null,true)})],1),_c('div',{staticClass:"change-currencies"},[_c('div',{staticClass:"change-currencies-button",on:{"click":_vm.switchCurrencies}},[_c('ExchangeIcon')],1)]),_c('div',{staticClass:"to"},[_c('label',{staticClass:"field-group-field-label currency-label"},[_vm._v(" To: ")]),_c('VueSelect',{class:[
          'field-group-field-input hide-error currency-select',
          _vm.inputClass,
          { [_vm.inputErrorClass]: _vm.fieldErrorsShown },
        ],attrs:{"value":_vm.buyCurrency,"clearable":false,"options":_vm.buyCurrencies,"disabled":_vm.readonly},on:{"input":_vm.onBuyCurrencyChange,"search:blur":_vm.showBlurError},scopedSlots:_vm._u([_vm._l((['option', 'selected-option']),function(optionName){return {key:optionName,fn:function(option){return [(option.label)?_c('span',{key:optionName,staticClass:"currency-select-option"},[_vm._v(" "+_vm._s(option.label)+" "),(_vm.displaySymbols)?_c('span',{staticClass:"symbol"},[_vm._v(" "+_vm._s(_vm.getCurrencyObj(option.label).symbol)+" ")]):_vm._e()]):_vm._e()]}}})],null,true)})],1),_c('InfoTooltipIcon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:(_vm.supportText),expression:"supportText"}],staticClass:"tooltip-icon"}),_c('div',{staticClass:"trade-direction"},[_c('div',[(_vm.displaySymbols)?_c('span',{staticClass:"trade-direction-number"},[_vm._v(" 1 "+_vm._s(_vm.getCurrencyObj(_vm.fromCurrency).symbol)+" ")]):_c('span',{staticClass:"trade-direction-number"},[_vm._v(" "+_vm._s(_vm.fromCurrency)+" 1 ")])]),_c('div',{staticClass:"change-currencies-button",on:{"click":function($event){_vm.isTradeDirectionSellBuy = !_vm.isTradeDirectionSellBuy}}},[_c('ExchangeIcon')],1),_c('div',[(_vm.displaySymbols)?_c('span',{staticClass:"trade-direction-number"},[_vm._v(" "+_vm._s(_vm.tradeDirectionRate)+" "+_vm._s(_vm.getCurrencyObj(_vm.toCurrency).symbol)+" ")]):_c('span',{staticClass:"trade-direction-number"},[_vm._v(" "+_vm._s(_vm.toCurrency)+" "+_vm._s(_vm.tradeDirectionRate)+" ")])])])],1),_c('div',{staticClass:"amount-selection"},[_c('div',[_c('label',{staticClass:"field-group-field-label currency-label"},[_vm._v(" "+_vm._s(_vm.currencyTitle)+" ")]),_c('div',{staticClass:"amount-convert"},[_c('div',{staticClass:"amount-type"},[_c('VueSelect',{class:[
              'field-group-field-input hide-error currency-select',
              _vm.inputClass,
              { [_vm.inputErrorClass]: _vm.fieldErrorsShown },
            ],attrs:{"clearable":false,"searchable":false,"disabled":_vm.readonly,"reduce":(option) => option.value,"options":[
              { value: 'SELL', label: 'from' },
              { value: 'BUY', label: 'to' },
            ]},scopedSlots:_vm._u([_vm._l((['option', 'selected-option']),function(optionName){return {key:optionName,fn:function(option){return [(option)?_c('span',{key:optionName,staticClass:"currency-select-option"},[(_vm.displaySymbols)?_c('span',{staticClass:"symbol"},[_vm._v(" "+_vm._s(option.value === 'SELL' ? _vm.sellCurrencyObj.symbol : _vm.buyCurrencyObj.symbol)+" ")]):_vm._e(),_vm._v(" "+_vm._s(option.value === 'SELL' ? _vm.sellCurrency : _vm.buyCurrency)+" ")]):_vm._e()]}}})],null,true),model:{value:(_vm.amountType),callback:function ($$v) {_vm.amountType=$$v},expression:"amountType"}})],1)])]),_c('div',{staticClass:"currency-field"},[(_vm.amountTitle)?_c('label',{staticClass:"field-group-field-label currency-label"},[_vm._v(" "+_vm._s(_vm.amountTitle)+" ")]):_vm._e(),_c('div',{staticClass:"input-holder",class:{ 'mock-label-padding': !_vm.amountTitle }},[_c('input',{ref:"currencyInput",class:[
            'field-group-field-input currency-field-input',
            _vm.inputClass,
            {
              [_vm.inputErrorClass]: _vm.fieldErrorsShown,
              'hide-error': !_vm.showAsDirty,
              'is-dirty': _vm.showAsDirty,
            },
          ],attrs:{"disabled":_vm.readonly,"inputmode":"decimal"},domProps:{"value":_vm.inputText},on:{"input":function($event){return _vm.setAmount($event.target.value, true)},"blur":_vm.onBlur}}),(_vm.displaySymbols)?_c('div',{staticClass:"amount-currency"},[_vm._v(" "+_vm._s(_vm.currencyObj.symbol)+" ")]):_vm._e()]),(_vm.showAsDirty)?_c('FormFieldErrors',{attrs:{"field":_vm.field,"model":_vm.model}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }