<template>
  <span>
    <BModal :title="group ? 'Manage group' : 'Add group'" modal-class="side-modal modal-lg" ref="modal" hide-footer>
      <ValidatedForm :fm="groupNameForm" :validation.sync="groupNameFV" />
      <label class="mt-4">Add users to group</label>
      <TargetSearchSelect
        class="mt-3 mb-3"
        @target-selected="onTargetSelected"
        :types="{
          individual: {
            filters: individualFilters,
            disallowed: disallowedIndividualIds,
          },
        }"
      />
      <div class="card-block recipients-list mb-3" v-if="hasRecipients">
        <template v-if="groupMembers.length">
          <h3 class="recipients-list-title">Users</h3>
          <BoxGrid align-content="start" align-h="start" class="group-targets-holder">
            <BoxGridItem class="group-target-item-holder" :key="individual.id" v-for="individual in groupMembers">
              <GroupMemberBlock class="group-target-item" :individual="individual" @delete="deleteTarget(individual)" />
            </BoxGridItem>
          </BoxGrid>
        </template>
      </div>
      <div class="buttons">
        <VButton class="btn-secondary mr-2" @click="cancel">Cancel</VButton>
        <VButton class="done-btn btn-success" :loading="requestManager.anyPending" @click="save">Save Changes</VButton>
      </div>
    </BModal>
    <slot v-bind="{ show }" />
  </span>
</template>

<script lang="ts">
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import { IndividualGroup, IndividualTargetSelection, IndividualReference, RegistrationStatus } from 'ah-api-gateways';
import TargetSearchSelect from '@/app/components/user/TargetSearchSelect.vue';
import { makeFormModel, submitForm } from 'ah-common-lib/src/form/helpers';
import { textField } from 'ah-common-lib/src/form/models';
import { Observable } from 'rxjs';
import GroupMemberBlock from '@/app/components/groups/GroupMemberBlock.vue';
import { mergeMap } from 'rxjs/operators';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { FormValidation } from 'ah-common-lib/src/form/interfaces';
import { useAuthStore } from '@/app/store/authStore';

const groupNameFM = () =>
  makeFormModel({
    name: 'groupNameForm',
    fieldType: 'form',
    fields: [textField('name', 'Group Name')],
  });

@Component({
  components: { TargetSearchSelect, GroupMemberBlock },
})
export default class GroupEditModal extends Mixins(WithRequestManager) {
  $refs!: {
    modal: BModal;
  };

  @Prop({ default: () => null }) group!: IndividualGroup | null;

  groupMembers: IndividualReference[] = [];

  groupNameForm = groupNameFM();

  groupNameFV: FormValidation | null = null;

  requestManagerConfig = {
    exposeToParent: true,
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'saveGroup') {
      this.save();
    }
  }

  get authStore() {
    return useAuthStore();
  }

  @Watch('group', { immediate: true })
  resetData() {
    this.groupNameForm.name = this.group?.name || '';
    this.groupMembers = [...(this.group?.individuals || [])];
  }

  get groupService() {
    if (this.authStore.isClientUser) {
      return this.$services.clientGroup;
    } else {
      return this.$services.partnerGroup;
    }
  }

  get groupParentId() {
    if (this.authStore.isClientUser) {
      return this.authStore.loggedInIdentity!.client!.id;
    } else {
      // Partner group endpoints do not use id in path, when called from a partner user
      return undefined;
    }
  }

  get hasRecipients() {
    return !!this.groupMembers.length;
  }

  get disallowedIndividualIds() {
    return this.groupMembers.map((r) => r.id);
  }

  get individualFilters() {
    return {
      status: [RegistrationStatus.PENDING, RegistrationStatus.APPROVED, RegistrationStatus.REJECTED],
    };
  }

  isInRecipientsList(target: IndividualReference) {
    return !!this.groupMembers.find((i) => i.id === target.id);
  }

  onTargetSelected(target: IndividualTargetSelection) {
    if (!this.isInRecipientsList(target.individual)) {
      this.groupMembers.push(target.individual);
    }
  }

  deleteTarget(target: IndividualReference) {
    const index = this.groupMembers.findIndex((i) => i.id === target.id);
    if (index > -1) {
      this.groupMembers.splice(index, 1);
    }
  }

  show() {
    this.$refs.modal.show();
    this.resetData();
  }

  save() {
    if (this.groupNameFV) {
      submitForm(this.groupNameFV);
      if (this.groupNameFV.$invalid) {
        return;
      }

      let request!: Observable<IndividualGroup>;

      if (this.group) {
        request = this.groupService.editGroup(
          {
            ...this.group,
            name: this.groupNameForm.name,
            individuals: this.groupMembers.map((m) => m.id),
          },
          this.groupParentId
        );
      } else {
        request = this.groupService.createGroup(
          {
            name: this.groupNameForm.name,
            individuals: this.groupMembers.map((m) => m.id),
          },
          this.groupParentId
        );
      }

      this.requestManager
        .new('saveGroup', request)
        .pipe(mergeMap((response) => this.groupService.getGroup(response.id, this.groupParentId)))
        .subscribe((group) => {
          this.$toast.success(`Group has been successfully ${this.group ? 'updated' : 'created'}.`);
          this.$emit('update:group', group);
          this.$refs.modal.hide();
        });
    }
  }

  cancel() {
    this.$refs.modal.hide();
  }
}
</script>
<style lang="scss">
.recipients-list-title {
  margin-bottom: math.div($padded-space, 3);
}

.group-targets-holder {
  display: flex;
  margin-bottom: math.div($padded-space, 2);

  &:last-child {
    margin-bottom: 0;
  }

  .group-target-item-holder {
    flex-basis: 33%;
    flex-grow: 0;
    min-width: 200px;

    .group-target-item {
      width: 100%;
    }
  }
}
</style>
