<script setup lang="ts">
import WalletBalance from './WalletBalance.vue';
import { FormFieldErrors } from 'ah-common-lib/src/form/components';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useWalletState } from '..';
import { computed, watch } from 'vue';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { BaseFormModel, FieldModel, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { getFieldErrorList } from 'ah-common-lib/src/form/helpers';

const props = withDefaults(
  defineProps<{
    model: FieldModel | BaseFormModel;
    field: FormValidation<any> | null;
    walletCurrency: string;
    amountToWithdraw?: number | string;
    loading?: boolean | string;
    forceLoading?: boolean;
    hideOverdraft?: boolean | string;
    /**
     * Whether to show balance or available balance. Passed directly to <WalletBalance>
     */
    showAvailable?: boolean | string;
    /**
     * Message displayed when there is overdraft
     */
    overdraftMessage?: string;
    /**
     * Whether to display the overdraft message as a warning
     */
    showOverdraftAsWarning?: boolean | string;
    /**
     * Whether to display the field error message before the available amount.
     *
     * Default set to false
     */
    errorBeforeAmount?: boolean | string;
    /*
     * Whether to display the wallet of a client.
     */
    clientId?: string;
  }>(),
  {
    amountToWithdraw: 0,
    loading: false,
    forceLoading: false,
    hideOverdraft: false,
    showAvailable: false,
    overdraftMessage: 'Insufficient funds to settle this trade.',
    showOverdraftAsWarning: true,
    errorBeforeAmount: false,
  }
);

const requestManager = useRequestManager();

const walletState = useWalletState();

const onBehalfOfClient = useOnBehalfOf();

const isLoading = computed(() => loadingWallet.value || props.loading);

const wallet = computed(() => {
  return walletState.store.useWalletsStore().getWallet(props.walletCurrency, {
    isPartner: isPartnerUser.value,
    id: ownerId.value,
  });
});

const isErrorOnTop = computed(() => props.errorBeforeAmount !== false);

const displayOverdraftAsError = computed(() => props.showOverdraftAsWarning === false);

const isOverdraft = computed(() => wallet.value && withdrawAmount.value && withdrawAmount.value > wallet.value.balance);

const shouldShowOverdraft = computed(() => isOverdraft.value && props.hideOverdraft === false);

const withdrawAmount = computed(() =>
  typeof props.amountToWithdraw === 'string' ? parseFloat(props.amountToWithdraw) : props.amountToWithdraw
);

const loadingWallet = computed(() => requestManager.manager.requestStates.loadWallet === 'pending');

const hasErrors = computed(() => props.field && getFieldErrorList(props.field, props.model as FieldModel).length > 0);

const isPartnerUser = computed(() => !onBehalfOfClient.value && !walletState.store.useAuthStore().isClientUser);

const ownerId = computed(() => {
  if (onBehalfOfClient.value) {
    return onBehalfOfClient.value.id;
  } else if (props.clientId) {
    return props.clientId;
  }
  return (
    walletState.store.useAuthStore().loggedInIdentity?.client?.id ||
    walletState.store.useAuthStore().loggedInIdentity?.partner?.id
  );
});

watch(
  () => props.walletCurrency,
  () => {
    if (props.walletCurrency) {
      walletState.store.useWalletsStore().loadCurrencyWallet({
        currency: props.walletCurrency,
        force: props.forceLoading,
        owner: { isPartner: isPartnerUser.value, id: ownerId.value },
      });
    }
  },
  { immediate: true }
);
</script>

<template>
  <div class="amount-subcopy field-group">
    <div class="field-info-wrapper">
      <div class="field-group-errors" v-if="field && field.walletMaximum === false">
        You don't have enough funds to perform this operation.
        <span v-if="!loadingWallet && wallet">
          <br />
          (<WalletBalance :showAvailable="showAvailable" :currency="walletCurrency" />)
        </span>
      </div>
      <div class="text-secondary" v-else-if="isLoading">Loading...</div>
      <div
        :class="['font-weight-light text-secondary', { 'text-danger': shouldShowOverdraft && displayOverdraftAsError }]"
        v-else
      >
        <WalletBalance :showAvailable="showAvailable" :currency="walletCurrency" />
        <span class="overdraft-warning" v-if="shouldShowOverdraft"> {{ overdraftMessage }} </span>
      </div>
    </div>
    <FormFieldErrors
      class="form-field-errors"
      :class="{ top: isErrorOnTop }"
      :field="field"
      :showAvailable="showAvailable"
      :model="model"
      v-if="hasErrors && field"
    />
  </div>
</template>

<style lang="scss" scoped>
.overdraft-warning {
  display: block;
  font-size: 0.8em;
}

.amount-subcopy {
  min-height: 1em;
  margin-bottom: 0.6em;
}

.field-group {
  display: flex;
  flex-flow: column;
  .field-info-wrapper {
    order: 2;
  }
  .form-field-errors {
    order: 3;
    &.top {
      order: 0;
      margin-top: -0.5rem;
    }
  }
}
</style>
