<script lang="ts" setup>
import { computed } from 'vue';
import { TableFieldDefinition } from 'ah-common-lib/src/models';
import { useFeatureFlagStore } from '../stores';
import { ListResponse } from 'ah-api-gateways/models/rest/pagination';
import { CachedItem } from 'ah-common-lib/src/helpers';
import { FeatureFlag, FeatureFlagResponse } from 'ah-api-gateways';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

const featureFlagStore = useFeatureFlagStore();
const requestManager = useRequestManager().manager;

const fields: TableFieldDefinition[] = [
  { key: 'featureFlag', header: 'Name', sortable: false },
  { key: 'loadDate', header: 'Last Loaded', sortable: false },
  { key: 'treatment', header: 'Treatment', sortable: false },
  { key: 'actions', header: '', class: 'text-right', sortable: false },
];

const flags = computed<ListResponse<CachedItem<FeatureFlagResponse>>>(() => ({
  list: Object.values(featureFlagStore.featureFlags),
}));

function reloadFlag(key: string) {
  requestManager.newPromise(
    key,
    featureFlagStore.loadFeatureFlag({
      featureFlag: key as FeatureFlag,
      force: true,
    })
  );
}

function clearFlag(key: string) {
  featureFlagStore.featureFlags[key] && CachedItem.clearCachedItem(featureFlagStore.featureFlags[key]);
}
</script>
<template>
  <div>
    <p class="font-italic mb-3">
      These are flags defined in split.IO that have been loaded by the app. Navigating to a page where a flag is checked
      will update this list.
    </p>
    <DynamicTable :fields="fields" :data="flags">
      <template #cell(featureFlag)="{ item }">
        {{ item.cacheKey }}
      </template>
      <template #cell(treatment)="{ item }">
        {{ item.item ? item.item.treatment : '-' }}
      </template>
      <template #cell(actions)="{ item }">
        <VButton
          class="btn-primary mr-3"
          :loading="requestManager.requestStates[item.cacheKey] === 'pending'"
          @click="reloadFlag(item.cacheKey)"
          >Reload</VButton
        >
        <VButton
          class="btn-danger"
          :disabled="requestManager.requestStates[item.cacheKey] === 'pending'"
          @click="clearFlag(item.cacheKey)"
          >Clear</VButton
        >
      </template>
    </DynamicTable>
  </div>
</template>
