<script setup lang="ts">
import { PropType, computed } from 'vue';
import {
  Trade,
  activeMarginTradeStates,
  getPrimaryCcy,
  getSellCcy,
  getBuyCcy,
  formatHedgingInstrument,
} from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { formatDate, SIMPLE_DATE_FORMAT } from 'ah-common-lib/src/helpers/time';

const props = defineProps({
  trade: {
    type: Object as PropType<Trade>,
    required: true,
    default: () => ({}),
  },
  sellingLabel: {
    type: String,
    required: false,
    default: () => 'Selling',
  },
  buyingLabel: {
    type: String,
    required: false,
    default: () => 'Buying',
  },
});

const gbpInitialMarginValue = computed(() => {
  return props.trade.gbpInitialMarginAmount ?? 0;
});

const gbpVariationMargin = computed(() => {
  return props.trade.gbpVmm ?? 0;
});
const tradeMarginIsActive = computed(() => {
  return activeMarginTradeStates.includes(props.trade.state);
});
const gbpInUseMarginValue = computed(() => {
  if (!tradeMarginIsActive.value) {
    return 0;
  }
  return gbpVariationMargin.value + gbpInitialMarginValue.value;
});
const clientRate = computed(() => {
  return getPrimaryCcy(props.trade).clientRate;
});
const sellCcy = computed(() => {
  return getSellCcy(props.trade);
});

const buyCcy = computed(() => {
  return getBuyCcy(props.trade);
});
</script>

<template>
  <div>
    <VRow>
      <VCol>
        <DataRow label="Instrument"> {{ formatHedgingInstrument(trade.hedgingProduct) }} </DataRow>
        <DataRow class="mb-2 trade-sell-currency" label="Sell amount">
          {{ sellCcy.currency }}
          {{ formatCurrencyValue(trade.hasDrawdowns ? sellCcy.remainingClientAmount : sellCcy.clientAmount) }}
          <LockIcon v-if="sellCcy.isFixedSide" />
        </DataRow>
        <DataRow class="mb-2 trade-buy-currency" label="Buy amount">
          {{ buyCcy.currency }}
          {{ formatCurrencyValue(trade.hasDrawdowns ? buyCcy.remainingClientAmount : buyCcy.clientAmount) }}
          <LockIcon v-if="buyCcy.isFixedSide" />
        </DataRow>
        <DataRow label="Trade Date">{{ formatDate(props.trade.createdAt, SIMPLE_DATE_FORMAT) }}</DataRow>
        <DataRow label="Settlement Date">{{ formatDate(props.trade.settlementDate, SIMPLE_DATE_FORMAT) }}</DataRow>
        <DataRow label="Trade ID">{{ trade.referenceNumber || '-' }}</DataRow>
        <DataRow label="Type">{{ trade.hedgingProduct || '-' }}</DataRow>
        <DataRow label="Client Rate"> {{ clientRate }} </DataRow>
        <DataRow label="Booked Rate"> {{ clientRate }} </DataRow>
        <DataRow v-if="trade.oboCreatedByEmail" label="Trade executed by">{{ trade.oboCreatedByEmail || '-' }}</DataRow>
        <DataRow v-else label="Trade executed by">{{ trade.createdByName || '-' }}</DataRow>

        <DataRow label="Initial Margin"> {{ formatCurrencyValue(gbpInitialMarginValue) }} </DataRow>
        <DataRow label="Variation Margin"> {{ formatCurrencyValue(gbpVariationMargin) }}</DataRow>
        <DataRow label="Total Margin (in use)"> {{ formatCurrencyValue(gbpInUseMarginValue) }} </DataRow>
      </VCol>
    </VRow>
    <slot name="after" />
  </div>
</template>
<style lang="scss" scoped>
::v-deep {
  .data-row {
    margin-bottom: 0.5em;
  }
}
</style>
